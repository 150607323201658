import React from "react";
import { createUseStyles } from "react-jss";
import { variables } from "../../variables";

const CmsStyles = createUseStyles({
  Cms: {
    display: "grid",
    overflow: "hidden",
    placeContent: "center",
  },
  title: {
    display: "grid",
    textAlign: "center",
    height: "100%",
    alignItems: "end",
    padding: variables.normal_gap,
  },
});
const CMS = () => {
  const classes = CmsStyles();
  return (
    <div className={classes.Cms}>
      <div className={classes.title}>
        <h1>CMS</h1>
      </div>
    </div>
  );
};
export default CMS;
