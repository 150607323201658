import { createUseStyles } from "react-jss";
import EntityAutocomplete from "../../../../../components/EntityAutocomplete";
import {
  cardStyles,
  modalGenericStyles,
  tableStyles,
  variables,
} from "../../../../../variables";
import { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import MembersAutocomplete from "./MembersAutocomplete";
import { CustomButton } from "../../../../../components/CustomButton";

const memberStyles = createUseStyles({
  ...modalGenericStyles,
  Member: {
    ...cardStyles.card,
  },
  card: cardStyles.card,
  cardHeader: { ...cardStyles.header, fontSize: "large" },
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  sourceEntityArea: {
    display: "grid",
    gridTemplateRows: "auto auto auto",
    justifySelf: "center",
  },
  sourceEntity: {
    display: "grid",
    gridTemplateRows: " auto auto",
    textAlign: "center",
  },
  cardActions: {
    padding: variables.half_gap,
    width: "2rem",
    justifySelf: "center",
  },
  table: { ...tableStyles.table, padding: variables.normal_gap },
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: {
    ...tableStyles.head.cell,
    justifyContent: "center",
    padding: variables.normal_gap,
    width: "7%",
  },
  tableCell: { ...tableStyles.body.cell, border: tableStyles.head.cell },
  actionsButton: {
    ...tableStyles.head.cell,
    justifyContent: "center",
    width: "1%",
  },
});

const Member = ({ text, loading, sourceEntityArea, setSourceEntityArea }) => {
  const classes = memberStyles();
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [autoCompleteInfos, setAutoCompleteInfos] = useState("");

  const handleChange = (text) => {
    if (text.length === 0) {
      setAutoCompleteValue("");
      return;
    }
    const entityInfos = text.split("---");
    const entityName = entityInfos[2];
    const entityId = entityInfos[1];
    const newAutoCompleteInfos = `${entityId}___${entityName}`;

    const isDuplicate = sourceEntityArea.some(
      (value) => value === newAutoCompleteInfos
    );
    if (isDuplicate) {
      toast.error("Cannot choose the same entity twice");
      return;
    }

    setAutoCompleteInfos(newAutoCompleteInfos);
  };

  return (
    <div className={classes.Member}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>{text}</div>
        <div className={classes.cardBody}>
          <div className={classes.sourceEntityArea}>
            <div className={classes.sourceEntity}>
              <label>Source Entity</label>
              <MembersAutocomplete
                name="Member"
                value={autoCompleteValue}
                setValue={setAutoCompleteValue}
                onChange={handleChange}
              />
            </div>
            <div className={classes.cardActions}>
              {autoCompleteInfos && (
                <CustomButton
                  appearance="ghost"
                  onClick={(e) => {
                    e.preventDefault();
                    setSourceEntityArea([
                      ...sourceEntityArea,
                      autoCompleteInfos,
                    ]);
                    setAutoCompleteValue("");
                    setAutoCompleteInfos("");
                  }}>
                  <strong>Add</strong>
                </CustomButton>
              )}
            </div>
          </div>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>Name</th>

                <th className={classes.actionsButton}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sourceEntityArea.map((value, idx) => (
                <tr key={idx}>
                  <td className={classes.tableCell}>
                    {value.split("___")[1] || "N/A"}
                  </td>
                  <td className={classes.cardActions}>
                    <CustomButton
                      appearance="ghost"
                      onClick={(e) => {
                        e.preventDefault();
                        const newSourceEntityArea = sourceEntityArea.filter(
                          (sE, index) => index !== idx
                        );
                        setSourceEntityArea([...newSourceEntityArea]);
                      }}>
                      <strong>Delete</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
Member.propTypes = {
  text: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  sourceEntityArea: PropTypes.array.isRequired,
  setSourceEntityArea: PropTypes.func.isRequired,
};
export default Member;
