import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../../../components/cards/GenericBasicInfo";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../../variables";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../../../../components/Loader";
import { genericRequestErrorHandler } from "../../../../../../api";
import { retrieveFERVendors } from "../../../../../../api/Vendor/Ferries";
import _ from "lodash";
import { DateTime } from "luxon";
import VendorsDocuments from "../../../components/VendorsDocuments";
import ListPaymentTerms from "../../../components/paymentsTerms/ListPaymentTerms";
import TabStrip from "../../../../../../components/TabStrip";
import { CustomButton } from "../../../../../../components/CustomButton";
import VendorFERConfigLogsTab from "./VendorFERConfigLogsTab";
import DepositStatments from "../../../../CommonCRMModals/DepositStatmentss/DepositStatment";
import ProformaStatments from "../../../../CommonCRMModals/Proforma/List";
import { Price } from "../../../../../../components/Price";

const trueFalseMapping = {
  true: "Yes",
  false: "No",
};

const statusOptions = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};
const styles = createUseStyles({
  ...modalGenericStyles,
  DetailsFerriesVendorsModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    display: "grid",
    padding: variables.double_gap,
    minHeight: "30vh",
    maxHeight: "70vh",
    overflow: "auto",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  row: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardBody: { justifyContent: "center" },
    row: {
      gridTemplateColumns: "2fr 1fr",
    },
    tabs: { gridAutoFlow: "column", justifyContent: "center" },
    tabBtns: {
      fontSize: "small",
    },
  },
});
const DetailsFerriesVendorsModal = ({ id, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicData");

  const menuTabs = {
    basicData: "Basic Data",
    paymentTerms: "Payment Terms",
    proforma: "Proforma Statment",
    creditStatments: "Credit Statements",
    documents: "Documents",
    logs: "Logs",
  };

  const { data, isLoading: retrieveLoading } = useQuery(
    ["DetailsFerriesVendorsModal", id],
    async () => {
      return await retrieveFERVendors({
        id: id,
      });
    },
    {
      onError: (error) => genericRequestErrorHandler(error),
    }
  );

  const VendorDataCpl = [
    ["Vendor", null, _.get(data, "data.vendor", "").split("fer__")[1] || "N/A"],
    ["Client Id", null, _.get(data, "data.vendor_data.client_id")],
    ["Client Secret", null, _.get(data, "data.vendor_data.client_secret", "")],
    ["Currency", null, _.get(data, "data.vendor_data.currency")],
    [" Partner Code", null, _.get(data, "data.vendor_data.partner_code")],
  ];
  const basicDataCpl = [
    ["Id", "id"],
    ["Member", "member"],
    ["Market Type", "market_type"],
    [
      "Notify Amount",
      null,
      <Price
        currency={data?.data?.vendor_data?.currency}
        price={data?.data?.notify_amount}
      />,
    ],
    ["Config uid", null, _.get(data, "data.config_uid[0]", "") || "N/A"],
    ["Notes", "notes"],
    [
      "Payment Validation",
      null,
      _.get(trueFalseMapping, _.get(data, "data.payment_validation")),
    ],
    ["CXL Strategy Time", "cxl_strategy_time"],
    [
      "CXL Strategy Daysahead",
      null,
      _.get(data, "data.cxl_strategy_daysahead", []) === 0
        ? "0"
        : _.get(data, "data.cxl_strategy_daysahead"),
    ],
    [
      "Block Non Refundable",
      null,
      _.get(trueFalseMapping, _.get(data, "data.block_nonrefundable")),
    ],
    [
      "Status",
      null,
      _.get(statusOptions, _.get(data, "data.status", "") || "N/A"),
    ],
  ];

  const creatorData = [
    ["Creator", "creator"],
    [
      "Created",
      null,
      DateTime.fromISO(_.get(data, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];
  const editorData = [
    ["Last Editor", "last_editor"],
    [
      "Edited",
      null,
      DateTime.fromISO(_.get(data, "data.edited")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];
  const vendorContactData = [
    ["Title", "title"],
    ["First Name", "first_name"],
    ["Last Name", "last_name"],
    ["Email", "email"],
  ];
  return (
    <div
      id="DetailVendorFERModal"
      className={classes.DetailsFerriesVendorsModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Details Ferries Vendor</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {retrieveLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicData" && (
                <React.Fragment>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Basic Data"}
                        cardInfo={basicDataCpl}
                        objectData={_.get(data, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Creator Data"}
                        cardInfo={creatorData}
                        objectData={_.get(data, "data", {})}
                      />
                      <GenericBasicInfo
                        header={"Editor Data"}
                        cardInfo={editorData}
                        objectData={_.get(data, "data", {})}
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Vendor Data"}
                        cardInfo={VendorDataCpl}
                        objectData={_.get(data, "data.vendor_data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Vendor Contact Data"}
                        cardInfo={vendorContactData}
                        objectData={_.get(
                          data,
                          "data.vendor_data.contact_details",
                          {}
                        )}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activeTab === "paymentTerms" && (
                <ListPaymentTerms
                  showAddButton={true}
                  vendorId={_.get(data, "data.id")}
                />
              )}
              {activeTab === "proforma" && (
                <ProformaStatments
                  invalidationKey="DetailsSubAgentModal"
                  entityType="vendor"
                  entityData={_.get(data, "data", {})}
                />
              )}
              {activeTab === "creditStatments" && (
                <DepositStatments
                  invalidationKey="DetailsFerriesVendorsModal"
                  entityType="vendor"
                  entityData={_.get(data, "data", {})}
                />
              )}
              {activeTab === "documents" && (
                <VendorsDocuments
                  title="Documents"
                  queryKey={`vendor_ferry_document`}
                  tags={[`vendor__${_.get(data, "data.id")}`]}
                  entityData={_.get(data, "data", {})}
                />
              )}
              {activeTab === "logs" && <VendorFERConfigLogsTab />}
            </React.Fragment>
          )}
        </div>
        <div className={classes.cardActions}>
          <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailsFerriesVendorsModal.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DetailsFerriesVendorsModal;
