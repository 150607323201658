import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { countries } from "../../../../../helpers";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import { retrieveAirport } from "../../../../../api/FlightEngine/Airports";
import { genericRequestErrorHandler } from "../../../../../api";
import Loader from "../../../../../components/Loader";
import { CustomButton } from "../../../../../components/CustomButton";
import AirportLogsTab from "./AirportLogsTab";
import TabStrip from "../../../../../components/TabStrip";
import { useAuthentication } from "../../../../../hooks";

const airportTypeMapping = {
  "": "-----",
  IN: "International",
  DO: "Domestic",
  OT: "Other",
};

const statusMapping = {
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  DetailAirportModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    alignContent: "start",
  },
  row: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    row: {
      gridTemplateColumns: "2fr 1fr",
    },
  },
});

const DetailAirportModal = ({ id, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicData");

  const menuTabs = {
    basicData: "Basic Data",
    logs: "Logs",
  };

  const { data: airport, isLoading: retrieveLoading } = useQuery({
    queryKey: ["AirportDetail", id],
    queryFn: () => {
      return retrieveAirport({ id });
    },
    onError: genericRequestErrorHandler,
    refetchOnWindowFocus: false,
  });

  const basicDataCpl = [
    ["Id", "id"],
    ["Name en", null, _.capitalize(_.get(airport, "data.name_en", ""))],
    [
      "Country",
      null,
      _.get(countries, _.get(airport, "data.country_code", "")),
    ],
    ["Email", "email"],
    [
      "Airport Type",
      null,
      _.get(airportTypeMapping, _.get(airport, "data.airport_type")),
    ],
    [
      "Score",
      null,
      _.get(airport, "data.score", 0) === 0
        ? "0"
        : _.get(airport, "data.score"),
    ],
    ["Iata", "iata"],
    [
      "Altitude",
      null,
      _.get(airport, "data.altitude", 0) === 0
        ? "0"
        : _.get(airport, "data.altitude", ""),
    ],
    ["Main Cms Destination", "main_cms_destination"],
    ["Radius", "radius"],
    ["Url", "url"],
    ["Nearest", null, _.get(airport, "data.nearest", []).join(",")],
    ["Main CMS Destination", "main_cms_destination"],
    [
      "UTC Offset",
      null,
      _.get(airport, "data.utc_offset", 0) === 0
        ? "0"
        : _.get(airport, "data.utc_offset"),
    ],

    ["Status", null, _.get(statusMapping, _.get(airport, "data.status"))],
  ];
  const creatorDataCpl = [
    [
      "Created",
      null,
      DateTime.fromISO(_.get(airport, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    ["Creator", "creator"],
  ];
  const editorData = [
    [
      "Edited",
      null,
      DateTime.fromISO(_.get(airport, "data.edited")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ) || "N/A",
    ],
  ];
  const coordinatesCpl = [
    [
      "Lat",
      null,
      _.get(airport, "data.lat", 0) === 0 ? "0" : _.get(airport, "data.lat"),
    ],
    [
      "Lng",
      null,
      _.get(airport, "data.lng", 0) === 0 ? "0" : _.get(airport, "data.lng"),
    ],
  ];
  return (
    <div className={classes.DetailAirportModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Airport Details</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {retrieveLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicData" && (
                <React.Fragment>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Basic Data"}
                        cardInfo={basicDataCpl}
                        objectData={_.get(airport, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Creator Data"}
                        cardInfo={creatorDataCpl}
                        objectData={_.get(airport, "data", {})}
                      />
                      <GenericBasicInfo
                        header={"Editor Data"}
                        cardInfo={editorData}
                        objectData={_.get(airport, "data", {})}
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header="Coordinates Data"
                        cardInfo={coordinatesCpl}
                        objectData={_.get(airport, "data", {})}
                        customStyles={{
                          GenericBasicInfo: { width: "max-content" },
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activeTab === "logs" && <AirportLogsTab />}
            </React.Fragment>
          )}
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton appearance="ghost" onClick={() => onClose()}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailAirportModal.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DetailAirportModal;
