import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { fetchMembersAutocomplete } from "../../../../../../api/EntityAutocomplete/MembersAutocomplete";
import PropTypes from "prop-types";
import { variables } from "../../../../../../variables";
import _ from "lodash";
var fetchMembers = async (name) => {
  const response = await fetchMembersAutocomplete({ params: { name } });
  if (!response) {
    return [];
  }
  return response;
};
fetchMembers = _.debounce(fetchMembers, 1000, { leading: true });
const defaultSuggestions = {
  member: [],
};
const styles = createUseStyles({
  MembersAutocomplete: {
    display: "grid",
    position: "relative",
  },
  suggestions: {
    display: "grid",
    position: "absolute",
    overflow: "auto",
    maxHeight: "25vh",
    justifyContent: "center",
    cursor: "pointer",
    border: "1px solid black",
  },
  suggestion: {
    cursor: "pointer",
    border: "1px solid rgba(0,0,0,0.5)",
    background: "white",
    padding: variables.half_gap,
    textAlign: "left",
    "&:hover": {
      backgroundColor: "grey",
      color: "white",
    },
  },
});
const MembersAutocomplete = ({ value, setValue, onChange }) => {
  const classes = styles();
  const [suggestions, setSuggestions] = useState(defaultSuggestions);
  const onChangeHandler = async (text) => {
    if (text.length > 2) {
      const member = await fetchMembers(text);
      if (member) {
        setSuggestions({ member });
      }
    }
    if (text.length === 0) {
      onChange("");
      setSuggestions(defaultSuggestions);
    }
  };
  const onSuggestSelect = (entity, entityType) => {
    onChange(`${entityType}---${entity.id}---${entity.name}---${entity.email}`);
    setValue(`${entity.name} (${entityType})`);
    setSuggestions(defaultSuggestions);
  };
  return (
    <div classes={classes.MembersAutocomplete}>
      <input
        type="text"
        onChange={(e) => {
          setValue(e.target.value);
          onChangeHandler(e.target.value);
        }}
        value={value}
        onBlur={() => setTimeout(() => setSuggestions(defaultSuggestions), 100)}
      />
      {_.flatten(Object.values(suggestions)).length > 0 && (
        <div className={classes.suggestions}>
          {_.flatten(
            Object.entries(suggestions).map(([entityType, entities]) =>
              entities.map((entity) => [entityType, entity])
            )
          ).map(([entityType, entity], idx) => (
            <div
              key={`${entityType}__${idx}`}
              className={classes.suggestion}
              onClick={() => onSuggestSelect(entity, entityType)}
            >
              {entity.name}, {entity.id} ({_.startCase(entityType)})
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
MembersAutocomplete.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default MembersAutocomplete;
