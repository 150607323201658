import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  cardStyles,
  modalGenericStyles,
  tableStyles,
  variables,
} from "../../../../../variables";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import PropTypes from "prop-types";
import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getCandidates,
  patchMappingHotels,
} from "../../../../../api/accommodator/Mapper";
import Loader from "../../../../../components/Loader";
import { genericRequestErrorHandler } from "../../../../../api";
import { toast } from "react-toastify";
import PrimaryHotelModal from "../PrimaryHotelModal";
import { useEffect } from "react";
import RemoveMappingModal from "../RemoveMappingModal";
import Fuse from "fuse.js";
import { retrieveHotel } from "../../../../../api/accommodator/Hotels/Hotels";
import { CustomButton } from "../../../../../components/CustomButton";

const fuseCfg = {
  shouldSort: false,
  threshold: 0.5,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["name", "address", "uid"],
};

const mappingModalStyles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  MappingModal: { ...modalGenericStyles.modal },
  modalCard: {
    ...modalGenericStyles.card,
    minWidth: "",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    // gridTemplateRows: "repeat(1, max-content)",
    gridTemplateColumns: "repeat (2, max-content)",
    gap: variables.normal_gap,
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: {
    ...cardStyles.card,
    padding: [`calc(${variables.normal_gap} * 0)`, 0],
  },
  cardHeader: {
    ...cardStyles.header,
    paddingBottom: variables.normal_gap,
    padding: "2.45rem 0.75rem 2.45rem 0.75rem",
  },
  cardBody: {
    ...cardStyles.body,
    display: "grid",
    gridTemplateRows: "repeat(3, max-content)",
    gap: variables.normal_gap,
  },
  HotelscardBody1Col: {
    display: "grid",
    gap: variables.normal_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  HotelscardBody2Col: {
    display: "grid",
    gap: variables.normal_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  mappedHotelCardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    padding: [`calc(${variables.normal_gap} * 2.2)`, 0],
  },
  tableCardBody: {
    ...modalGenericStyles.cardBody,
    maxHeight: "16rem",
    padding: "",
  },
  cardActions: {
    ...cardStyles.actions,
    // gridColumn: "2 span",
  },
  candidates: {
    ...cardStyles.card,
    display: "grid",
    gridTemplateRows: "max-content max-content",
    justifyContent: "center",
  },
  inputGroup: {
    ...modalGenericStyles.inputGroup,
    padding: variables.normal_gap,
  },
  tableContainer: {
    ...genericIndexesStyles.tableContainer.mobile,
    position: "relative",
  },
  table: tableStyles.table,
  thead: { ...tableStyles.head, position: "sticky", top: 0 },
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  tableCell: { ...tableStyles.body.cell, textAlign: "left", maxWidth: "10rem" },
  greenScore: {
    color: "green",
  },
  blueScore: {
    color: "blue",
  },
  redScore: {
    color: "red",
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCardBody: {
      gridTemplateColumns: "1fr 3fr",
    },
  },
});
const MappingModal = ({ pk, onClose }) => {
  const classes = mappingModalStyles();
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [showPrimaryHotelModal, setShowPrimaryHotelModal] = useState(false);
  const [showRemoveMappingModal, setShowRemoveMappingModal] = useState(false);

  const [filterByName, setFilterByName] = useState("");

  const queryClient = useQueryClient();

  const { data: targetedHotel, isLoading: targetedHotelLoading } = useQuery({
    queryKey: ["hotelMapperModal", pk],
    queryFn: () => {
      return retrieveHotel({
        pk: pk,
      });
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const {
    data,
    isLoading: candidatesLoading,
    isFetching,
  } = useQuery({
    queryKey: ["candidatesHotels", _.get(targetedHotel, "data.pk")],
    queryFn: async () =>
      await getCandidates({
        params: {
          uid: _.get(targetedHotel, "data.uid"),
          supplier: _.get(targetedHotel, "data.supplier"),
        },
      }),
    refetchOnWindowFocus: false,
  });

  const { data: mappedHotel, isLoading: mappedHotelLoading } = useQuery({
    queryKey: [
      "MappedhotelMapperModal",
      targetedHotel?.data?.mapped_uid__uid,
      selectedCandidate,
    ],
    queryFn: () => {
      return retrieveHotel({
        pk: _.get(targetedHotel, "data.mapped_uid__pk"),
      });
    },
    onError: (error) => genericRequestErrorHandler(error),
    enabled: !!_.get(targetedHotel, "data.mapped_uid__pk"),
  });

  var filteredCandidates = data?.data?.candidates ?? [];
  useEffect(() => {
    if (isFetching) return;
    const filteredSelectedCandidate = (data?.data?.candidates ?? []).find(
      (c) => _.get(targetedHotel, "data.mapped_uid__pk") === c.pk
    );
    if (filteredSelectedCandidate) {
      setSelectedCandidate(filteredSelectedCandidate);
    }
  }, [isFetching]);

  const mutation = useMutation({
    mutationFn: ({
      uid,
      supplier,
      primary_uid,
      manually_mapped = true,
      mapping_score,
    }) => {
      return patchMappingHotels({
        payload: {
          uid,
          supplier,
          primary_uid,
          manually_mapped,
          mapping_score,
        },
      });
    },
    onSuccess: () => {
      toast.success("Hotels mapped");
      queryClient.invalidateQueries("Mapper");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = ({
    uid,
    supplier,
    primary_uid,
    manually_mapped,
    mapping_score,
  }) => {
    mutation.mutate({
      uid,
      supplier,
      primary_uid,
      manually_mapped,
      mapping_score,
    });
  };

  const hotelDataCpl = [
    ["Uid", "uid"],
    ["Name", "name"],
    ["Supplier", "supplier"],
    ["Address", "address"],
  ];

  const mappedHotelDataCpl = [
    ["Uid", "uid"],
    ["Name", "name"],
    ["Supplier", "supplier"],
    ["Address", "address"],
    [
      "Score",
      null,
      _.get(selectedCandidate, "score", 0) === 0
        ? "0"
        : _.get(selectedCandidate, "score"),
      <span
        className={
          _.get(selectedCandidate, "score") < 0.7
            ? classes.redScore
            : _.get(selectedCandidate, "score") >= 0.7 &&
              _.get(selectedCandidate, "score") <= 0.85
            ? classes.blueScore
            : classes.greenScore
        }>
        {_.get(selectedCandidate, "score")}
      </span>,
    ],
  ];

  const loading = candidatesLoading || !data || mutation.isLoading;

  // Initialize Fuse with the list of candidates
  const fuse = new Fuse(filteredCandidates, fuseCfg);

  // If the filterByName is empty, show all candidates
  if (filterByName !== "") {
    // Use Fuse to search candidates by name
    filteredCandidates = fuse
      .search(filterByName.toLowerCase())
      .map((c) => c.item);
  }

  return (
    <div className={classes.MappingModal}>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className={classes.modalCard}>
            <div className={classes.modalCardHeader}>Mapping Hotel</div>
            <div className={classes.modalCardBody}>
              {loading && <Loader />}
              {/* <div className={classes.cardBody}> */}
              <div
                className={
                  mappedHotel?.data?.pk
                    ? classes.HotelscardBody2Col
                    : classes.HotelscardBody1Col
                }>
                <GenericBasicInfo
                  header="Hotel Data"
                  cardInfo={hotelDataCpl}
                  objectData={_.get(targetedHotel, "data", {})}
                  actions={[
                    [
                      _.get(targetedHotel, "data.primary") === true
                        ? "Remove Primary"
                        : "Set Primary",
                      () => setShowPrimaryHotelModal(true),
                    ],
                    ["Remove Mapping", () => setShowRemoveMappingModal(true)],
                  ]}
                />
                {targetedHotel?.data?.mapped_uid__pk && (
                  <div className={classes.mappedHotelCardBody}>
                    <GenericBasicInfo
                      header="Mapped Hotel Data"
                      cardInfo={mappedHotelDataCpl}
                      objectData={_.get(mappedHotel, "data", {})}
                    />
                  </div>
                )}
                {/* </div> */}
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Candidates</div>
                <div className={classes.cardBody}>
                  {/* <div className={classes.candidates}> */}
                  <div className={classes.inputGroup}>
                    <input
                      type="text"
                      value={filterByName}
                      onChange={(e) => setFilterByName(e.target.value)}
                      placeholder="Filter by Name"
                    />
                  </div>
                  <div className={classes.tableCardBody}>
                    <div className={classes.tableContainer}>
                      <table className={classes.table}>
                        <thead className={classes.thead}>
                          <tr className={classes.headRow}>
                            <th className={classes.headCell}>Select</th>
                            <th className={classes.headCell}>Name</th>
                            <th className={classes.headCell}>Address</th>
                            <th className={classes.headCell}>Score</th>
                            <th className={classes.headCell}>Uid</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredCandidates.map((candidate, idx) => (
                            <tr key={idx}>
                              <td className={classes.tableCell}>
                                <input
                                  type="radio"
                                  name="selectedCandidate"
                                  checked={
                                    selectedCandidate?.uid === candidate.uid
                                  }
                                  onChange={() => {
                                    setSelectedCandidate(
                                      _.cloneDeep(candidate)
                                    );
                                  }}
                                />
                              </td>
                              <td className={classes.tableCell}>
                                {candidate.name}
                              </td>
                              <td className={classes.tableCell}>
                                {candidate.address}
                              </td>
                              <td className={classes.tableCell}>
                                <span
                                  className={
                                    candidate.score < 0.7
                                      ? classes.redScore
                                      : candidate.score >= 0.7 &&
                                        candidate.score <= 0.85
                                      ? classes.blueScore
                                      : classes.greenScore
                                  }>
                                  {candidate.score}
                                </span>
                              </td>
                              <td className={classes.tableCell}>
                                {candidate.uid}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.modalCardActions}>
              <CustomButton appearance="ghost" onClick={onClose}>
                <strong>Cancel</strong>
              </CustomButton>
              <CustomButton
                appearance="primary"
                isDisabled={_.get(targetedHotel, "data.primary")}
                onClick={() =>
                  onSubmit({
                    uid: _.get(targetedHotel, "data.uid"),
                    supplier: _.get(targetedHotel, "data.supplier"),
                    primary_uid: _.get(selectedCandidate, "uid"),
                    manually_mapped: true,
                    mapping_score: _.get(selectedCandidate, "score"),
                  })
                }>
                <strong>Submit</strong>
              </CustomButton>
            </div>
          </div>
          {showPrimaryHotelModal && (
            <PrimaryHotelModal
              message="Are you sure?"
              primary={_.get(targetedHotel, "data.primary")}
              pk={_.get(targetedHotel, "data.pk")}
              onClose={() => setShowPrimaryHotelModal(false)}
            />
          )}
          {showRemoveMappingModal && (
            <RemoveMappingModal
              message="Are you sure?"
              pk={_.get(targetedHotel, "data.pk")}
              onClose={() => setShowRemoveMappingModal(false)}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};
MappingModal.propTypes = {
  currentMapping: PropTypes.string.isRequired,
  pk: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default MappingModal;
