import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import { statusMapping } from "../../common";
import Loader from "../../../../../components/Loader";
import {
  createWebSiteFAQ,
  patchWebSiteFAQ,
  retrieveWebSiteFAQ,
} from "../../../../../api/DistributionSite/webSites/FAQ";
import _ from "lodash";
import { DateTime } from "luxon";
import QuilEditor from "../../../../../components/QuilEditor";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import * as yup from "yup";
import WebsitesLanguages from "../../Components/WebsitesLanguages";
import TranslateButton from "../../Components/TranslateButton";
import { handleValidationErrors } from "../../../../../helpers";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    // .min(2, "Must be at least 2 characters")
    .max(100, "Must be less than 100 characters")
    .nullable()
    .default(""),
  status: yup
    .mixed()
    .oneOf(["AC", "IN", "CA"], "Must be between Active, Inactive or Candidate")
    .default("AC"),
  description: yup.string(),
  description_cn: yup.string().nullable().default(""),
  description_es: yup.string().nullable().default(""),
  description_en: yup.string().nullable().default(""),
  description_de: yup.string().nullable().default(""),
  description_el: yup.string().nullable().default(""),
  description_fr: yup.string().nullable().default(""),
  description_it: yup.string().nullable().default(""),
  description_nl: yup.string().nullable().default(""),
  description_pt: yup.string().nullable().default(""),
  description_lt: yup.string().nullable().default(""),
  active_from: yup.date().nullable().default(null),
  active_to: yup.date().nullable().default(null),
  questions_cn: yup.array().of(
    yup.object().shape({
      answer: yup.string().nullable().default(""),
      question: yup.string().nullable().default(""),
    })
  ),
  questions_de: yup.array().of(
    yup.object().shape({
      answer: yup.string().nullable().default(""),
      question: yup.string().nullable().default(""),
    })
  ),
  questions_el: yup.array().of(
    yup.object().shape({
      answer: yup.string().nullable().default(""),
      question: yup.string().nullable().default(""),
    })
  ),
  questions_en: yup.array().of(
    yup.object().shape({
      answer: yup.string().nullable().default(""),
      question: yup.string().nullable().default(""),
    })
  ),
  questions_es: yup.array().of(
    yup.object().shape({
      answer: yup.string().nullable().default(""),
      question: yup.string().nullable().default(""),
    })
  ),
  questions_fr: yup.array().of(
    yup.object().shape({
      answer: yup.string().nullable().default(""),
      question: yup.string().nullable().default(""),
    })
  ),
  questions_it: yup.array().of(
    yup.object().shape({
      answer: yup.string().nullable().default(""),
      question: yup.string().nullable().default(""),
    })
  ),
  questions_nl: yup.array().of(
    yup.object().shape({
      answer: yup.string().nullable().default(""),
      question: yup.string().nullable().default(""),
    })
  ),
  questions_pt: yup.array().of(
    yup.object().shape({
      answer: yup.string().nullable().default(""),
      question: yup.string().nullable().default(""),
    })
  ),
  questions_lt: yup.array().of(
    yup.object().shape({
      answer: yup.string().nullable().default(""),
      question: yup.string().nullable().default(""),
    })
  ),
});

const languages = {
  en: "English",
  de: "German",
  el: "Greek",
  es: "Spanish",
  fr: "French",
  it: "Italian",
  ja: "Japanese",
  nl: "Dutch",
  pt: "Portuguese",
  th: "Thai",
  cn: "Chinese",
  lt: "Lithuanian",
};

const initialForm = {
  name: "",
  status: "",
  active_from: null,
  active_to: null,
  description_cn: "",
  description_es: "",
  description_en: "",
  description_de: "",
  description_el: "",
  description_fr: "",
  description_it: "",
  description_nl: "",
  description_pt: "",
  description_lt: "",
  questions_cn: [{ answer: "", question: "" }],
  questions_de: [{ answer: "", question: "" }],
  questions_el: [{ answer: "", question: "" }],
  questions_en: [{ answer: "", question: "" }],
  questions_es: [{ answer: "", question: "" }],
  questions_fr: [{ answer: "", question: "" }],
  questions_it: [{ answer: "", question: "" }],
  questions_nl: [{ answer: "", question: "" }],
  questions_pt: [{ answer: "", question: "" }],
  questions_lt: [{ answer: "", question: "" }],
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditFAQ: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
    overflow: "hidden",
    height: "100vh",
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    display: "grid",
    gridAutoFlow: "row",
    gap: variables.normal_gap,
  },
  languages: {
    display: "grid",
    padding: variables.double_gap,
  },
  languagesButtons: {
    height: "max-content",
    padding: variables.normal_gap,
    overflow: "auto",
  },
  translateButton: {
    padding: variables.normal_gap,
    alignSelf: "center",
  },
  inputGroup: formInputGroup,
  descriptionCardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    display: "grid",
    padding: "",
    paddingBottom: variables.normal_gap,
  },
  description: {
    // display: "grid",
    gap: variables.normal_gap,
    "& label": {
      fontSize: "small",
    },
  },
  languagesButton: {
    padding: variables.normal_gap,
    gap: variables.normal_gap,
    borderRadius: "5px",
    cursor: "pointer",
    fontWeight: "bold",
    transition: "background .s, color .3s",
    "&:hover": {
      background: variables.colors.background.darkHover,
      color: variables.colors.text.light,
    },
  },
  cardBodyFAQ: {
    ...cardStyles.body,
    display: "grid",
    gridTemplateRows: "repeat(3, 1fr)",
    gap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    inputGroup: formInputGroup,
    languages: {
      gridTemplateColumns: "1fr max-content",
      gap: variables.normal_gap,
    },
    cardCol2: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: variables.normal_gap,
    },
    cardCol3: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      gap: variables.normal_gap,
    },
  },
});

const EditFAQ = ({ id, websiteId, onClose }) => {
  const classes = styles();

  const mode = id ? "edit" : "add";

  const [form, setForm] = useState(initialForm);
  // const [form, setForm] = useState(jsonSchema.cast({}));
  const [activeLanguage, setActiveLanguage] = useState("en");

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["RetrieveEditFAQ", id],
    queryFn: () => retrieveWebSiteFAQ({ id: id }),
    onSuccess: (data) => {
      var dataForm = _.get(data, "data", {});
      Object.keys(languages).forEach((lng) => {
        if (_.isEmpty(_.get(dataForm, `questions_${lng}`, []))) {
          dataForm[`questions_${lng}`] = [{ answer: "", question: "" }];
        }
      });

      setForm(dataForm);
      // setForm(jsonSchema.cast(dataForm));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    enabled: mode === "edit",
  });

  const editMutation = useMutation({
    mutationFn: ({ payload, id }) => {
      return patchWebSiteFAQ({
        id,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("FAQ Page Info Updated");
      queryClient.invalidateQueries("FAQModalList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const addMutation = useMutation({
    mutationFn: ({ payload, website }) => {
      return createWebSiteFAQ({
        payload: {
          ...payload,
          website,
        },
      });
    },
    onSuccess: () => {
      toast.success("New FAQ Page Added");
      queryClient.invalidateQueries("FAQModalList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ id, payload, website }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload, website });
      } else {
        editMutation.mutate({ id, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  return (
    <div className={classes.EditFAQ}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>{_.startCase(mode)} FAQ</div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            {" "}
            {_.startCase(mode)} FAQ
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.languages}>
              <div className={classes.languagesButtons}>
                <WebsitesLanguages
                  activeLanguage={activeLanguage}
                  setActiveLanguage={setActiveLanguage}
                  languages={languages}
                />
              </div>
              <div className={classes.translateButton}>
                <TranslateButton
                  targetLang={activeLanguage}
                  fields={["description", "questions"]}
                  subfields={{ questions: ["answer", "question"] }}
                  form={form}
                  setForm={setForm}
                />
              </div>
            </div>
            <form
              onChange={(e) =>
                setForm((p) => ({
                  ...p,
                  [e.target.name]: e.target.value,
                }))
              }>
              <div className={classes.card}>
                <div className={classes.cardHeader}>
                  <h5>Basic Information</h5>
                </div>
                <div className={classes.cardBody}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name" defaultValue={form.name} />
                  </div>

                  <div className={classes.description}>
                    <label htmlFor="description">Description</label>

                    {Object.entries(languages).map(([key, label], idx) =>
                      key === activeLanguage ? (
                        <QuilEditor
                          key={key}
                          value={form[`description_${key}`]}
                          onChange={(value) => {
                            let newForm = {
                              ...form,
                              [`description_${key}`]: value,
                            };
                            setForm(newForm);
                          }}
                        />
                      ) : null
                    )}
                  </div>
                </div>
              </div>

              <div className={classes.card}>
                <div className={classes.cardHeader}>
                  <h5>Active Data</h5>
                </div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="active_from">Active From</label>
                      <input
                        type="date"
                        name="active_from"
                        value={
                          form.active_from
                            ? DateTime.fromISO(form.active_from).toISODate()
                            : "N/A"
                        }
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="active_to">Active To</label>
                      <input
                        type="date"
                        name="active_to"
                        value={
                          form.active_to
                            ? DateTime.fromISO(form.active_to).toISODate()
                            : "N/A"
                        }
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        value={form.status}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(statusMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {_.get(form, `questions_${activeLanguage}`).map((faq, faqIdx) => (
                <div className={classes.card}>
                  <div className={classes.cardHeader}>
                    <label>FAQ{faqIdx + 1}</label>
                  </div>
                  <div className={classes.cardBodyFAQ}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="question">Question</label>
                      <input
                        value={_.get(
                          form,
                          `questions_${activeLanguage}.${faqIdx}.question`
                        )}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            [`questions_${activeLanguage}`]: _.get(
                              form,
                              `questions_${activeLanguage}`
                            ).map((fq, idx) => {
                              if (idx !== faqIdx) {
                                return fq;
                              }
                              return {
                                ...fq,
                                question: e.target.value,
                              };
                            }),
                          });
                        }}
                      />
                    </div>
                    <div className={classes.description}>
                      <label htmlFor="answer">Answer</label>
                      <QuilEditor
                        value={_.get(
                          form,
                          `questions_${activeLanguage}.${faqIdx}.answer`
                        )}
                        onChange={(value) => {
                          setForm({
                            ...form,
                            [`questions_${activeLanguage}`]: _.get(
                              form,
                              `questions_${activeLanguage}`
                            ).map((fq, idx) => {
                              if (idx !== faqIdx) {
                                return fq;
                              }
                              return {
                                ...fq,
                                answer: value,
                              };
                            }),
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.cardActions}>
                    {_.get(form, `questions_${activeLanguage}`).length - 1 ===
                    faqIdx ? (
                      <CustomButton
                        appearance="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({
                            ...form,
                            questions_en: [
                              ...form.questions_en,
                              { answer: "", question: "" },
                            ],
                            questions_cn: [
                              ...form.questions_cn,
                              { answer: "", question: "" },
                            ],
                            questions_de: [
                              ...form.questions_de,
                              { answer: "", question: "" },
                            ],
                            questions_el: [
                              ...form.questions_el,
                              { answer: "", question: "" },
                            ],
                            questions_es: [
                              ...form.questions_es,
                              { answer: "", question: "" },
                            ],
                            questions_fr: [
                              ...form.questions_fr,
                              { answer: "", question: "" },
                            ],
                            questions_it: [
                              ...form.questions_it,
                              { answer: "", question: "" },
                            ],
                            questions_nl: [
                              ...form.questions_nl,
                              { answer: "", question: "" },
                            ],
                            questions_pt: [
                              ...form.questions_pt,
                              { answer: "", question: "" },
                            ],
                          });
                        }}>
                        <strong>Add Extra FAQ</strong>
                      </CustomButton>
                    ) : null}
                    {_.get(form, `questions_${activeLanguage}`).length > 0 &&
                      faqIdx > 0 && (
                        <CustomButton
                          appearance="ghost"
                          onClick={(e) => {
                            e.preventDefault();
                            setForm({
                              ...form,
                              [`questions_${activeLanguage}`]: [
                                ..._.get(
                                  form,
                                  `questions_${activeLanguage}`
                                ).filter((x, indexFaq) => faqIdx !== indexFaq),
                              ],
                            });
                          }}>
                          <strong>Remove FAQ</strong>
                        </CustomButton>
                      )}
                  </div>
                </div>
              ))}
            </form>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  id: id,
                  payload: form,
                  website: websiteId,
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditFAQ.propTypes = {
  id: PropTypes.number,
  websiteId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditFAQ;
