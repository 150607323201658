import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  cardStyles,
  genericIndexesStyles,
  formInputGroup,
  modalGenericStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Loader from "../../../../components/Loader";
import { patchCRSGuest, retrieveCRSGuests } from "../../../../api/CRS/Guests";
import { toast } from "react-toastify";
import { convertStringToBoolean, countries } from "../../../../helpers";
import { useMutation, useQuery } from "@tanstack/react-query";
import AddUsersDocumentsModal from "../UsersDocuments/AddUsersDocumentsModal";
import UserDocuments from "../UsersDocuments/List";
import EntityAutocomplete from "../../../../components/EntityAutocomplete";
import { CustomButton } from "../../../../components/CustomButton";
import { genericRequestErrorHandler } from "../../../../api";
import _ from "lodash";

const titleMapping = {
  "": "-----",
  MR: "Mr",
  MRS: "Mrs",
  MS: "Ms",
  // DR: "Dr",
  // CH: "Ch",
  MISS: "Miss",
};

const initialForm = {
  identification_type: "",
  guest_title: "",
  first_name: "",
  last_name: "",
  gender: "",
  email: "",
  phone: "",
  identification_code: "",
  date_of_birth: "",
  nationality: "",
  group_leader: false,
  guest_type: "",
};

const genderMapping = {
  "": "-----",
  MA: "Male",
  FE: "Female",
};
const guestIdentificationTypeMapping = {
  "": "-----",
  ID: "Identity Card",
  PA: "Passport",
};
const groupLeaderMapping = {
  "": "-----",
  true: "Yes",
  false: "No",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditGuestModal: {
    ...modalGenericStyles.modal,
    zIndex: "900",
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    overflowX: "hidden",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: { ...cardStyles.card, maxWidth: "100%" },
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    maxWidth: "100%",
    overflow: "hidden",
  },
  inputGroup: formInputGroup,
  addDocuments: {
    display: "grid",
    gridColumn: "2 span",
    justifyContent: "right",
  },
  tableContainer: {
    ...genericIndexesStyles.tableContainer.mobile(),
    gridColumn: "2 span",
  },
  entityAutoComplete: {
    ...modalGenericStyles.inputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  addModal: {
    display: "grid",
    gridColumn: "2 span",
  },
  documentList: {
    display: "grid",
    gridColumn: "2 span",
  },
  table: tableStyles.table,
  thead: tableStyles.head,
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
  [`@media ${variables.media.smallscreen}`]: {
    modalCardBody: {
      overflowX: "auto",
    },
    cardCol2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 3fr)",
    },
  },
});

const EditGuestModal = ({
  queryKey,
  sourceEntity,
  reference,
  guestId,
  type,
  onClose,
}) => {
  const classes = styles();

  const [form, setForm] = useState(initialForm);
  const [showAddDocumentModal, setshowAddDocumentModal] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState(sourceEntity);

  const tags = [`${guestId}_guest_document`];

  const { isLoading } = useQuery({
    queryKey: ["EditGuestModal"],
    queryFn: () =>
      retrieveCRSGuests({
        id: guestId,
      }),
    onSuccess: (data) => {
      setForm(_.get(data, "data", {}));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    enabled: !showAddDocumentModal,
  });

  const boolFields = convertStringToBoolean({
    group_leader: form?.group_leader,
  });

  const mutation = useMutation({
    mutationFn: ({ id, payload }) => {
      return patchCRSGuest({
        id,
        payload,
      });
    },

    onSuccess: () => {
      toast.success("Guest Info Updated");
      onClose();
    },
    onError: (error) => toast.error(error.message),
  });
  const onSubmit = ({ id, payload }) => {
    mutation.mutate({ id, payload });
  };

  const loading = isLoading || mutation.isLoading;
  return (
    <div id="editGuestInfoModal" className={classes.EditGuestModal}>
      <div className={classes.modalCard}>
        {loading && <Loader onTop={true} />}
        <div className={classes.modalCardHeader}>
          <span>Edit Guest</span>
        </div>
        <div className={classes.modalCardBody}>
          <form
            onChange={(e) => {
              setForm((p) => ({
                ...p,
                [e.target.name]: e.target.value,
              }));
            }}>
            <div className={classes.card}>
              <div className={classes.cardHeader}>Basic Data</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol3}>
                  <div className={classes.inputGroup}>
                    <label>Title</label>
                    <select
                      id="formGuestTitle"
                      name="guest_title"
                      value={form.guest_title}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(titleMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  {/* <div className={classes.inputGroup}>
                    <label>Title</label>
                    <input
                      id="formGuestTitle"
                      type="text"
                      name="guest_title"
                      defaultValue={form.guest_title}
                    />
                  </div> */}
                  <div className={classes.inputGroup}>
                    <label>First Name</label>
                    <input
                      id="formFirstName"
                      type="text"
                      name="first_name"
                      defaultValue={form.first_name}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Last Name</label>
                    <input
                      id="formLastName"
                      type="text"
                      name="last_name"
                      defaultValue={form.last_name}
                    />
                  </div>
                </div>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="source_entity">Source Entity</label>
                    <div className={classes.entityAutoComplete}>
                      <EntityAutocomplete
                        id="formSourceEntity"
                        subagents={false}
                        independentAgents={false}
                        branches={false}
                        value={autocompleteValue}
                        setValue={setAutocompleteValue}
                        onChange={(text) => {
                          if (text.length === 0) {
                            setForm((p) => ({
                              ...p,
                              source_entity: "",
                            }));
                            return;
                          }
                          const entityInfos = text.split("---");
                          const entityType = entityInfos[0];
                          const entityId = entityInfos[1];
                          setForm((p) => ({
                            ...p,
                            source_entity: `${entityId}___${entityType}`,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Gender</label>
                    <select
                      id="formGender"
                      name="gender"
                      value={form.gender}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(genderMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Guest Type</label>
                    <select
                      id="formGuestType"
                      type="text"
                      name="identification_type"
                      value={form.identification_type}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(guestIdentificationTypeMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Identification Code</label>
                    <input
                      id="formIdentificationCode"
                      type="text"
                      name="identification_code"
                      defaultValue={form.identification_code}
                    />
                  </div>
                </div>
                <div className={classes.cardCol3}>
                  <div className={classes.inputGroup}>
                    <label>Date Of Birth</label>
                    <input
                      id="formDateOfBirth"
                      type="date"
                      name="date_of_birth"
                      defaultValue={form.date_of_birth}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Nationality</label>
                    <select
                      id="formNationality"
                      name="nationality"
                      value={form.nationality}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(countries).map(([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Group Leader</label>
                    <select
                      id="formGroupLeader"
                      name="group_leader"
                      value={form.group_leader}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(groupLeaderMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.cardHeader}>Contact Informations</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label>Phone</label>
                    <input
                      id="formPhone"
                      type="text"
                      name="phone"
                      defaultValue={form.phone}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Email</label>
                    <input
                      id="formEmail"
                      type="email"
                      name="email"
                      defaultValue={form.email}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.cardHeader}>Documents</div>
              <div className={classes.cardBody}>
                <div className={classes.addDocuments}>
                  <CustomButton
                    id="addUserDocumentButton"
                    appearance="ghost"
                    onClick={(e) => {
                      e.preventDefault();
                      setshowAddDocumentModal(true);
                    }}>
                    <strong>Add Document</strong>
                  </CustomButton>
                </div>
              </div>
            </div>
            {showAddDocumentModal && (
              <div className={classes.addModal}>
                <AddUsersDocumentsModal
                  sourceEntity={sourceEntity}
                  queryKey={queryKey}
                  tags={tags}
                  onCloseDocumentModal={() => setshowAddDocumentModal(false)}
                />
              </div>
            )}
            <div className={classes.documentList}>
              <UserDocuments
                tags={tags}
                queryKey={queryKey}
                sourceEntity={sourceEntity}
              />
            </div>
          </form>
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton
            id="cancel"
            appearance="ghost"
            onClick={() => onClose()}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            id="submit"
            appearance="primary"
            onClick={(e) => {
              e.preventDefault();
              onSubmit({
                id: guestId,
                payload: { ...form, ...boolFields },
              });
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
EditGuestModal.propTypes = {
  reference: PropTypes.string.isRequired,
  sourceEntity: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
  guestId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditGuestModal;
