import { VALAQUENTA_API_URL } from "..";
import { authorizedAxios, loggingAxios } from "../../App";

export function getVendorCreditStatment({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/paymentengine/supplier-ledger-entry/`,
    {
      params,
    }
  );
}

export function createVendorCreditStatment({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/paymentengine/supplier-ledger-entry/`,
    payload
  );
}

export function getVendorPoformaStatment({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/paymentengine/proforma-supplier-ledger-entry/`,
    {
      params,
    }
  );
}

export function createVendorPoformaStatment({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/paymentengine/proforma-supplier-ledger-entry/`,
    payload
  );
}
