import React, { useState } from "react";
import { fetchHotels } from "../../../../api/accommodator/Hotels/Hotels";
import _ from "lodash";
import { createUseStyles } from "react-jss";
import {
  variables,
  tableStyles,
  genericIndexesStyles,
} from "../../../../variables";
import { btnStyles } from "../../../../variables";
import Navbar from "./Navbar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ControlStrip from "../../../../components/ControlStrip";
import EditHotelModal from "./HotelModals/EditHotelModal";
import Loader from "../../../../components/Loader";
import { countries, medWeekdayDatetimer } from "../../../../helpers";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";
import { CustomButton } from "../../../../components/CustomButton";

const initialFilters = {
  page_size: 20,
  page: 1,
  ordering: "",
  discending: "",
  supplier: "",
  country_code: "",
  rating: "",
  name: "",
  address: "",
  uid: "",
  created: "",
};
const navbarSchema = yup.object().shape({
  name__icontains: yup
    .string()
    .min(2, "Hotel name must be more than 1 characters")
    .max(100, "Hotel name must be less than 100 characters"),
  address: yup
    .string()
    .min(2, "Hotel address must be more than 1 characters")
    .max(100, "Hotel address must be less than 100 characters"),
  custom_doc_score: yup
    .number()
    .max(9999, "custom_doc_score can not be more than 9999")
    .integer("custom_doc_score must be an integer")
    .positive("custom_doc_score must be a positive number"),

  created__gte: yup.date().max(new Date(), "Date cannot be in the future"),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const hotelStyles = createUseStyles({
  Hotel: genericIndexesStyles.indexes.mobile,
  tableContainer: genericIndexesStyles.tableContainer.mobile,
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: tableStyles.body.cell,
  addresscell: {
    ...tableStyles.body.cell,
    maxWidth: "23rem",
  },
  actionCell: genericIndexesStyles.actionCell.mobile,
  datesCell: { ...tableStyles.body.cell, width: "8rem" },
  mappingCell: {
    whiteSpace: "pre-line",
  },
  btn: btnStyles.btn,
  checkBox: tableStyles.checkBox,
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      gap: variables.normal_gap,
      padding: `calc(${variables.normal_gap} *1.5) 0`,
    },
    cell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
    mappingCell: {},
  },
});
const Hotels = () => {
  const classes = hotelStyles();
  const [filters, setFilters] = useState(initialFilters);
  const [checked, setChecked] = useState([]);
  const [showEditHotelModal, setShowEditHotelModal] = useState(false);
  const [editHotel, setEditHotel] = useState({});

  useListPageSidebar();

  const handleRedirectToMapper = ({ uid, supplier }) => {
    const url = `/accommodator/mapper?uid=${uid}&supplier=${supplier}`;
    window.open(url, "_blank");
  };

  const tableHeaders = {
    Uid: (dt) => dt.uid,
    "Hotel Name": (dt) => dt.name,
    Supplier: (dt) => _.capitalize(dt.supplier),
    Primary: (dt) => (_.get(dt.parimary) === true ? "Yes" : "No"),
    Mapping: (dt) =>
      `Uid: ${dt.mapped_uid__uid || "N/A"}\n Name: ${
        dt.mapped_uid__name || "N/A"
      }\n Address: ${_.capitalize(dt.mapped_uid__supplier) || "N/A"}`,
    Rating: (dt) => dt.rating,
    Address: (dt) => dt.address,
    Country: (hotel) => _.get(countries, _.get(hotel, "country_code")),
    Created: (dt) => dt.created,
  };

  const handleApplyFilters = async (filters) => {
    if (!filters.supplier && !filters.uid) {
      toast.error(
        `You must choose Uid & Supplier or Country & Supplier before applying filters.`
      );
      return;
    }
    try {
      const filteredFilters = Object.keys(filters).reduce((acc, key) => {
        if (filters[key] !== "") {
          acc[key] = filters[key];
        }
        return acc;
      }, {});

      await navbarSchema.validate(filteredFilters, { abortEarly: false });
      setFilters(filteredFilters);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        if (!validationErrors[err.path]) {
          validationErrors[err.path] = err.message;
          toast.error(err.message);
        }
      });
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["Hotels", filters],
    queryFn: async () =>
      await fetchHotels({
        params: filters,
      }),
    enabled: filters.supplier !== "" && filters.uid !== "",
    onError: (error) => genericRequestErrorHandler(error),
    refetchOnWindowFocus: false,
  });
  const hotels = data || [];

  const loading = isLoading && filters.supplier !== "" && filters.uid !== "";
  return (
    <div id="hotelsList" className={classes.Hotel}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyFilters}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.page_size}
        page={filters.page}
        count={_.get(hotels, "data.count", 0)}
        onChangeLimit={(page_size) =>
          setFilters({ ...filters, page_size, page: 1 })
        }
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, order_by: ordering })
        }
      />
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                {/* <th className={classes.headCell}>
                  <input
                    className={classes.checkBox}
                    type="checkbox"
                    checked={
                      hotels.length > 0 && checked.length === hotels.length
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChecked([...hotels.map((hotel) => hotel.uid)]);
                      } else {
                        setChecked([]);
                      }
                    }}></input>
                </th> */}
                <th className={classes.headCell}>#</th>
                {Object.keys(tableHeaders).map((value, idx) => (
                  <th className={classes.headCell} key={idx}>
                    {value}
                  </th>
                ))}
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.get(hotels, "data.results", []).map((dt, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  {/* <td className={classes.cell}>
                    <input
                      className={classes.checkBox}
                      type="checkbox"
                      checked={checked.includes(dt.uid)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setChecked([...checked, dt.uid]);
                        } else {
                          const newChecked = [
                            ...checked.filter((uid) => uid !== dt.uid),
                          ];
                          setChecked(newChecked);
                        }
                      }}
                      disabled={
                        checked.length >= 10 && !checked.includes(dt.uid)
                      }
                    />
                  </td> */}
                  <td className={classes.cell}>{idx + 1}</td>
                  {Object.entries(tableHeaders).map((entry, ridx) => {
                    var cell = null;
                    const fn = entry[1];
                    switch (entry[0].toLowerCase()) {
                      case "mapping":
                        cell = fn(dt) || "N/A";
                        return (
                          <td className={classes.mappingCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      case "created":
                        cell = fn(dt) ? medWeekdayDatetimer(fn(dt)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      default:
                        cell = fn(dt) || "N/A";
                        break;
                    }
                    return (
                      <td className={classes.cell} key={ridx}>
                        {cell}
                      </td>
                    );
                  })}
                  <td className={classes.actionCell}>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        window.open(
                          `/accommodator/hotels/images/${dt.pk}`,
                          "_blank"
                        );
                      }}>
                      <strong>Edit Images</strong>
                    </CustomButton>
                    {/* <Button
                      text="Edit Images"
                      onClick={() => {
                        navigate(`/accommodator/hotels/images/${dt.pk}`, {
                          state: {
                            hotelData:
                              _.get(hotels, "data.results", []).filter(
                                (hotel) => hotel.pk === dt.pk
                              )[0] || {},
                          },
                        });
                      }}
                    /> */}
                    <CustomButton
                      appearance="ghost"
                      onClick={() =>
                        handleRedirectToMapper({
                          uid: dt.uid,
                          supplier: dt.supplier,
                        })
                      }>
                      <strong>Go to Mapper</strong>
                    </CustomButton>
                    <CustomButton
                      id={`editHotelButton_${idx}`}
                      appearance="ghost"
                      onClick={() => {
                        setEditHotel(dt.pk);
                        setShowEditHotelModal(true);
                      }}>
                      <strong>Edit Hotel</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showEditHotelModal && (
        <EditHotelModal
          pk={editHotel}
          onClose={() => {
            setShowEditHotelModal(false);
            setEditHotel(null);
            setChecked([]);
          }}
        />
      )}
    </div>
  );
};

export default Hotels;
