import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export async function fetchDocuments({ params }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/reviewer/entity/document/`,
    {
      params,
    }
  );
}

export async function retrieveDocument({ uid }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/reviewer/entity/document/${uid}/`
  );
}

export async function createDocuments({ payload }) {
  return await loggingAxios.post(
    `${VALAQUENTA_API_URL}/reviewer/entity/document/`,
    payload
  );
}

export async function deleteDocument({ uid }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/reviewer/entity/document/${uid}/`
  );
}
