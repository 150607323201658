import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import Loader from "../../../../../components/Loader";
import _ from "lodash";
import { useMutation, useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import * as yup from "yup";
import {
  convertStringToNumber,
  countries,
  currencies,
  handleValidationErrors,
} from "../../../../../helpers";
import {
  createPaymentTerm,
  patchPaymentTerm,
  retrievePaymentTerms,
} from "../../../../../api/Vendor/PaymentsTerms";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = (vendorId) =>
  yup.object().shape({
    payment_time_buffer: yup.number().default(0),
    payment_method: yup
      .string()
      .oneOf(["BT", "CC"], "Must be between Bank Transfer or Credit Card")
      .required()
      .default(""),
    payment_time_type: yup
      .string()
      .oneOf(
        ["PRE", "CXL", "EXC"],
        "Must be between Pre-payment, On Cancellation Policy or On Service Execution"
      )
      .default(""),
    service_type: yup
      .string()
      .oneOf(
        ["FL", "ACC", "TRF", "MI", "COA", "TRA", "FER", "GEN", "REST", "ACT"],
        "Must be between Flight, Accommodation, Transfers, Addon/Tour/Static Package, Coach, Train, Ferries, General Services, Restaurant or Activities"
      )
      .nullable()
      .default(""),
    status: yup
      .string()
      .oneOf(["AC", "IN"], "Must be between Active, Inactive or Candidate")
      .default(""),
    currency: yup.string().nullable().default(""),
    bank_country: yup.string().nullable().default(""),
    bank_name: yup.string().nullable().default(""),
    bank_address: yup.string().nullable().default(""),
    bank_branch_code: yup.string().nullable().default(""),
    bank_account: yup.string().nullable().default(""),
    swift: yup.string().nullable().default(""),
    iban: yup.string().nullable().default(""),
    vendor: yup.number().default(vendorId),
  });

const travelServiceMapping = {
  "": "-----",
  FL: "Flight",
  ACC: "Accommodation",
  TRF: "Transfers",
  MI: "Addon/Tour/Static Package",
  COA: "Coach",
  TRA: "Train",
  FER: "Ferries",
  GEN: "General Services",
  REST: "Restaurant",
  ACT: "Activities",
};
const paymentTimeTypeMapping = {
  "": "-----",
  PRE: "Pre-payment",
  CXL: "On Cancellation Policy",
  EXC: "On Service Execution",
};

const paymentMethod = {
  "": "-----",
  BT: "Bank Transfer",
  CC: "Credit Card",
};

const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditPaymentTerms: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  modalCard: {
    ...modalGenericStyles.card,
    maxWidth: "65rem",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    padding: variables.normal_gap,
  },
  loader: { width: "100vw" },
  inputGroup: formInputGroup,
  descriptionCardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    display: "grid",
    padding: "",
    paddingBottom: variables.normal_gap,
  },
  description: {
    gap: variables.normal_gap,
    "& label": {
      fontSize: "small",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardCol2: {
      gridTemplateColumns: "1fr 1fr",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    modalCard: {
      minWidth: "65rem",
    },
  },
});

const EditPaymentTerms = ({ vendorId, id, onClose }) => {
  let mode = id ? "edit" : "add";
  const classes = styles();

  // const initialForm = {
  //   payment_time_buffer: 0,
  //   payment_method: "",
  //   payment_time_type: "",
  //   service_type: null,
  //   vendor: vendorId,
  //   status: "",
  //   bank_name: "",
  //   bank_address: "",
  //   bank_branch_code: "",
  //   bank_account: "",
  //   swift: "",
  //   iban: "",
  // };

  // const [form, setForm] = useState(initialForm);
  const [form, setForm] = useState(jsonSchema(vendorId).cast({}));

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["retrievePaymentTerms"],
    queryFn: async () =>
      await retrievePaymentTerms({
        id: id,
      }),
    onSuccess: (data) => {
      setForm(
        jsonSchema(vendorId).cast(_.get(data, "data", {}), {
          stripUnknown: true,
        })
      );
    },
    onError: (error) => genericRequestErrorHandler(error),
    enabled: mode === "edit",
  });

  const addMutation = useMutation({
    mutationFn: ({ payload }) => {
      return createPaymentTerm({
        payload,
      });
    },
    onSuccess: () => {
      toast.success("Payment Term Added");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ payload, id }) => {
      return patchPaymentTerm({
        id,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("Payment Term Updated");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const numericFields = ["payment_time_buffer"];
  const updatedNumericFields = convertStringToNumber(form, numericFields);

  const onSubmit = async ({ id, payload, vendorId }) => {
    try {
      await jsonSchema(vendorId).validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload });
      } else {
        editMutation.mutate({ id, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  const handleInputChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  return (
    <div id="addEditPaymentTermsModal" className={classes.EditPaymentTerms}>
      {loading && <Loader onTop={true} />}
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          {_.startCase(mode)} Payment Term
        </div>
        <div className={classes.modalCardBody}>
          <form onChange={handleInputChange}>
            <div className={classes.card}>
              <div className={classes.cardHeader}>Basic Data</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="service_type">Service Type</label>
                    <select
                      id="service_type"
                      name="service_type"
                      value={form.service_type}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(travelServiceMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="currency">Currency</label>
                    <select
                      id="currency"
                      name="currency"
                      value={form.currency}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(currencies).map(([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="status">Status</label>
                    <select
                      id="status"
                      name="status"
                      value={form.status}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(statusMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Payments</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="payment_time_type">
                        Payment Time Type
                      </label>
                      <select
                        id="payment_time_type"
                        name="payment_time_type"
                        value={form.payment_time_type}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(paymentTimeTypeMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="payment_method">Payment Method</label>
                      <select
                        id="payment_method"
                        name="payment_method"
                        value={form.payment_method}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(paymentMethod).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="payment_time_buffer">
                        Payment Time Buffer
                      </label>
                      <input
                        id="payment_time_buffer"
                        type="number"
                        name="payment_time_buffer"
                        value={form.payment_time_buffer}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Bank Data</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="bank_name">Bank Name</label>
                      <input
                        id="bank_name"
                        type="text"
                        name="bank_name"
                        value={form.bank_name}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="bank_address">Bank Address</label>
                      <input
                        id="bank_address"
                        type="text"
                        name="bank_address"
                        value={form.bank_address}
                      />
                    </div>

                    <div className={classes.inputGroup}>
                      <label htmlFor="bank_country">Bank Country</label>
                      <select
                        id="bank_country"
                        name="bank_country"
                        value={form.bank_country}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(countries).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    <div className={classes.inputGroup}>
                      <label htmlFor="bank_branch_code">Bank Branch Code</label>
                      <input
                        id="bank_branch_code"
                        type="text"
                        name="bank_branch_code"
                        value={form.bank_branch_code}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="bank_account">Bank Account</label>
                      <input
                        id="bank_account"
                        type="text"
                        name="bank_account"
                        value={form.bank_account}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="swift">SWIFT</label>
                      <input
                        id="swift"
                        type="text"
                        name="swift"
                        value={form.swift}
                      />
                    </div>
                  </div>
                  <div className={classes.cardCol1}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="iban">IBAN</label>
                      <input
                        id="iban"
                        type="text"
                        name="iban"
                        value={form.iban}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            id="submit"
            appearance="primary"
            onClick={(e) => {
              e.preventDefault();
              onSubmit({
                id: id,
                payload: { ...form, ...updatedNumericFields },
                vendorId: vendorId,
              });
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
EditPaymentTerms.propTypes = {
  id: PropTypes.number,
  vendorId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditPaymentTerms;
