import React from "react";
import { createUseStyles } from "react-jss";
import {
  btnStyles,
  cardStyles,
  modalGenericStyles,
  tableStyles,
  variables,
} from "../../../../../../variables";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import _ from "lodash";
import { CustomButton } from "../../../../../../components/CustomButton";

const accountingEntryMapping = {
  CR: "Credit",
  DB: "Debit",
};

const dater = (dt) =>
  dt ? new Date(dt).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) : "N/A";

const getBalances = (entries) => {
  const result = [];
  const reversedEntries = _.reverse([...entries]);

  reversedEntries.forEach((entry, idx) => {
    if (idx === 0) {
      const amount = 0 + parseFloat(entry.amount);
      result.push(amount);
      return;
    }
    const amount = parseFloat(result[idx - 1]) + parseFloat(entry.amount);
    result.push(amount);
  });
  return _.reverse(result);
};

const styles = createUseStyles({
  ...modalGenericStyles,
  FinancialTable: {
    ...cardStyles.card,
    justifyContent: "center",
  },
  stats: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "repeat(3, 1fr)",
    padding: variables.normal_gap,
    // padding: variables.half_gap,
    //   paddingRight: variables.normal_gap,
  },
  cardBody: {
    ...modalGenericStyles.cardBody,
    // justifySelf: "center",
    textAlign: "-webkit-center",
  },
  loader: { width: "100vw" },
  cardActions: {
    ...modalGenericStyles.cardActions,
  },
  tableContainer: {
    overflow: "auto",
  },
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: tableStyles.body.cell,
  btn: btnStyles.btn,
  add: {
    padding: "0.75rem",
    textAlign: "end",
  },
  addBtn: { ...btnStyles.btn, width: "4rem" },
  [`@media ${variables.media.smallscreen}`]: {
    loader: { width: "100%" },
  },
});

const FinancialTable = ({ data, setUid, setShowEditCreditDebitModal }) => {
  const classes = styles();

  const creditEntries = data.filter((dt) => dt.entry_type === "CR");
  const depositEntries = data.filter((dt) => dt.entry_type === "DB");
  const totalCredit = creditEntries.reduce(
    (acc, cr) => acc + parseFloat(cr.amount),
    0
  );
  const totalDebit = depositEntries.reduce(
    (acc, entry) => acc + parseFloat(entry.amount),
    0
  );
  const balance = totalCredit + totalDebit;
  const balances = getBalances(data);

  return (
    <div className={classes.FinancialTable}>
      <div className={classes.card}>
        <div className={classes.stats}>
          <div className={classes.totalCredit}>
            Total Credit:
            {totalCredit}
          </div>
          <div className={classes.totalDebit}>
            <span>Total Debit:</span>
            {totalDebit}
          </div>
          <div className={classes.balance}>
            Balance:
            {balance}
          </div>
        </div>
        <div className={classes.cardBody}>
          <div className={classes.tableContainer}>
            <table className={classes.table}>
              <thead className={classes.thead}>
                <tr className={classes.headRow}>
                  <th className={classes.headCell}>#</th>
                  <th className={classes.headCell}>Description</th>
                  <th className={classes.headCell}>Product UID</th>
                  <th className={classes.headCell}>Type</th>
                  <th className={classes.headCell}>Amount</th>
                  <th className={classes.headCell}>Balance</th>
                  <th className={classes.headCell}>Tags</th>
                  <th className={classes.headCell}>Created</th>
                  <th className={classes.headCell}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((dt, idx) => (
                  <tr key={idx} className={classes.bodyRow}>
                    <td className={classes.cell}>{idx + 1}</td>
                    <td className={classes.cell}>{dt.description || "N/A"}</td>
                    <td className={classes.cell}>{dt.product_uid || "N/A"}</td>
                    <td className={classes.cell}>
                      {_.get(accountingEntryMapping, _.get(dt, "entry_type")) ||
                        "N/A"}
                    </td>
                    <td className={classes.cell}>
                      {dt.currency} {parseFloat(dt.amount).toFixed(2)}
                    </td>
                    <td className={classes.cell}>
                      {dt.currency} {balances[idx].toFixed(2)}
                    </td>
                    <td className={classes.cell}>
                      {_.get(dt, "tags", []).join(", ") || "N/A"}
                    </td>
                    <td className={classes.cell}>{dater(dt.created)}</td>
                    <td className={classes.cell}>
                      <div>
                        <CustomButton
                          appearance="ghost"
                          onClick={() => {
                            setUid(dt.uid);
                            setShowEditCreditDebitModal(true);
                          }}>
                          <strong>Edit</strong>
                        </CustomButton>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
FinancialTable.propTypes = {
  data: PropTypes.array.isRequired,
  setUid: PropTypes.func.isRequired,
  setShowEditCreditDebitModal: PropTypes.func.isRequired,
};
export default FinancialTable;
