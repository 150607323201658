import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import ControlStrip from "../../../../components/ControlStrip";
import Loader from "../../../../components/Loader";
import {
  countries,
  currencies,
  handleApplyFilters,
  medWeekdayDatetimer,
} from "../../../../helpers";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Navbar from "./Navbar";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import {
  cloneAviateApiConfiguration,
  fetchAviateAPIConfigurations,
} from "../../../../api/FlightEngine/AviateApiConfiguration";
import DetailAviateAPIConfigurationsModal from "../AviateModals/DetailsAviateModal";
import EditAviateAPIConfigurationModal from "../AviateModals/EditAviateModal";
import { toast } from "react-toastify";
import DeleteAviateModal from "../AviateModals/DeleteAviatemodal";
import { genericRequestErrorHandler } from "../../../../api";
import { DateTime } from "luxon";
import { flightEngineTrueFalseMapping } from "../../common";
import { CustomButton } from "../../../../components/CustomButton";
import VerifyModal from "../../../../components/VerifyModal";
import ReservationsStatusTag from "../../../../components/Tags/ReservationsStatusTag";

const gdsMapping = {
  AV: "Aviate",
};

const enviromentOptions = {
  STAG: "Staging",
  PROD: "Production",
};

const initialFilters = {
  limit: 20,
  page: 1,
  ordering: "",
  discending: "",
  user__icontains: "",
  name__icontains: "",
  country_code: "",
  force_source_type: "",
  created__lte: "",
  created__gte: "",
  source_entity: "",
};
const navbarSchema = yup.object().shape({
  name__icontains: yup
    .string()
    .min(2, "Name must be more than 1 characters")
    .max(100, "Name must be less than 100 characters"),
  created__gte: yup.date().max(new Date(), "Date cannot be in the future"),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const styles = createUseStyles({
  APIConfiguration: genericIndexesStyles.indexes.mobile(),
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile(),
  checkBox: tableStyles.checkBox,
  datesCell: { ...tableStyles.body.cell, width: "9rem", minWidth: "9rem" },
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      gridTemplateColumns: "repeat(2,1fr) ",
      padding: [
        `${variables.double_gap} ${variables.normal_gap}`,
        `${variables.double_gap} ${variables.normal_gap}`,
      ],
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const AviateApiConfiguration = () => {
  const classes = styles();

  const queryClient = useQueryClient();
  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [apiConfigurationId, setApiConfigurationId] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [aviateApi, setAviateApi] = useState({});

  const tableHeaders = {
    Name: (val) => _.capitalize(val.name),
    "Sourse Entity": (val) => val.source_entity,
    Currency: (val) => _.get(currencies, _.get(val, "currency")),
    Country: (val) => _.get(countries, _.get(val, "country_code")),
    "Market Type": (val) => val.market_type,
    "Is Own": (val) =>
      _.get(flightEngineTrueFalseMapping, _.get(val, "is_own")),
    "Is Global": (val) =>
      _.get(flightEngineTrueFalseMapping, _.get(val, "is_global")),
    "Received From": (val) => _.capitalize(val.received_from),
    PCC: (val) => val.pcc,
    GDS: (val) => _.get(gdsMapping, _.get(val, "gds")),
    Searches: (val) => (_.get(val, "searches") === 0 ? "0" : val.searches),
    "Max Searches": (val) =>
      _.get(val, "max_searches") === 0 ? "0" : val.max_searches,
    Bookings: (val) => (_.get(val, "bookings") === 0 ? "0" : val.bookings),
    "Max Bookings": (val) =>
      _.get(val, "max_bookings") === 0 ? "0" : val.max_bookings,
    Environent: (val) =>
      _.get(enviromentOptions, _.get(val, "environment", "")),
    Created: (val) => val.created,
    Status: (val) => <ReservationsStatusTag status={val.status} />,
  };
  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data: aviate, isLoading } = useQuery({
    queryKey: ["AviateAPIConfigurations", filters],
    queryFn: () => {
      let correctPayload = { ...filters };
      if (filters.created__lte) {
        correctPayload["created__lte"] = DateTime.fromISO(
          filters.created__lte
        ).toISO();
      }
      if (filters.created__gte) {
        correctPayload["created__gte"] = DateTime.fromISO(
          filters.created__gte
        ).toISO();
      }
      return fetchAviateAPIConfigurations({
        params: correctPayload,
      });
    },
    enabled: !showDeleteModal && !showEditModal && !showDetailModal,
    onError: (error) => genericRequestErrorHandler(error),
  });

  const cloneAviateApiMutation = useMutation({
    mutationFn: (uid) => {
      return cloneAviateApiConfiguration({ uid });
    },
    onSuccess: () => {
      toast.success("Cloned Aviate Api Configuration added");
      queryClient.invalidateQueries("AviateAPIConfigurations");
      setShowVerifyModal(false);
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });
  const onClone = (uid) => {
    setApiConfigurationId(uid);
  };

  return (
    <div
      id="AviateFlAPIConfigurationsListPage"
      className={classes.APIConfiguration}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setShowEditModal(true);
          setApiConfigurationId(null);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.limit}
        page={filters.page}
        count={_.get(aviate, "data.count", 0)}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th key={idx} className={classes.headCell}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.get(aviate, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "created":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      return (
                        <td className={classes.datesCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    default:
                      cell = fn(val) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.tableCell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setApiConfigurationId(val.uid);
                      setShowDetailModal(true);
                    }}>
                    <strong>Details</strong>
                  </CustomButton>
                  <CustomButton
                    id={`EditAviateFlButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setApiConfigurationId(val.uid);
                      setShowEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      onClone(val.uid);
                      setShowVerifyModal(true);
                    }}>
                    <strong>Clone</strong>
                  </CustomButton>
                  <CustomButton
                    id={`DeleteAviateFlButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setAviateApi(val);
                      setApiConfigurationId(val.uid);
                      setShowDeleteModal(true);
                    }}>
                    <strong>Delete</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDetailModal && (
        <DetailAviateAPIConfigurationsModal
          uid={apiConfigurationId}
          onClose={() => {
            setShowDetailModal(false);
            setApiConfigurationId(null);
          }}
        />
      )}
      {showEditModal && (
        <EditAviateAPIConfigurationModal
          data={aviateApi}
          apiConfigurationId={apiConfigurationId}
          onClose={() => {
            setShowEditModal(false);
            setApiConfigurationId(null);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteAviateModal
          data={aviateApi}
          deletedUid={apiConfigurationId}
          onClose={() => {
            setShowDeleteModal(false);
          }}
        />
      )}
      {showVerifyModal && (
        <VerifyModal
          // title=""
          // message=""
          isLoading={cloneAviateApiMutation.isLoading}
          onConfirm={() => {
            cloneAviateApiMutation.mutate(apiConfigurationId);
          }}
          onClose={() => setShowVerifyModal(false)}
        />
      )}
    </div>
  );
};
export default AviateApiConfiguration;
