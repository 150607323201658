import React from "react";
import Logs from "../../../../../components/Logs";
import { VALAQUENTA_API_URL } from "../../../../../api";

const EmailConfigLogsTab = (WrappedComponent) => {
  const Comp = (props) => {
    const actionsMapping = {
      edit: {
        label: "Edit",
        url: `${VALAQUENTA_API_URL}/crs/internal/email-config/`,
        method: "PATCH",
      },
      add: {
        label: "Add",
        url: `${VALAQUENTA_API_URL}/crs/internal/email-config/`,
        method: "POST",
      },
      addDefault: {
        label: "Add Default",
        url: `${VALAQUENTA_API_URL}crs/internal/email-config/default_configs/`,
        method: "POST",
      },
    };

    return <WrappedComponent {...props} actionsMapping={actionsMapping} />;
  };
  return Comp;
};

export default EmailConfigLogsTab(Logs);
