import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Loader from "../../../../components/Loader";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Navbar from "./Navbar";
import _ from "lodash";
import ControlStrip from "../../../../components/ControlStrip";
import { handleApplyFilters, medWeekdayDatetimer } from "../../../../helpers";
import DetailTrainModal from "../TrainsModals/DetailTrainModal";
import {
  fetchTrainReservations,
  getTrainReservationStats,
} from "../../../../api/CRS/Trains";
import ReservationStats from "../../CommonCRSModals/ReservationStats/ReservationStats";
import { commonTableHeaders } from "../../helpers";
import { useQueries } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { genericRequestErrorHandler } from "../../../../api";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import { DateTime } from "luxon";
import { CustomButton } from "../../../../components/CustomButton";

const initialFilters = {
  limit: 20,
  page: 1,
  ordering: "",
  discending: "",
  company__icontains: "",
  destination__icontains: "",
  departure__gte: "",
  arrival__lte: "",
  created__gte: "",
  created__lte: "",
  external_reference__icontains: "",
  reference: "",
  source_entity: "",
  network_search: "",
  status: "",
  market_type: "",
};
const navbarSchema = yup.object().shape({
  company__icontains: yup
    .string()
    .min(2, "Company Name must be more than 1 characters")
    .max(100, "Company Name must be less than 100 characters"),
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});
const trainStyles = createUseStyles({
  Trains: {
    ...genericIndexesStyles.indexes.mobile(),
    gridTemplateRows: "auto auto auto  1fr",
  },
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tablecell: tableStyles.body.cell,
  actionCell: {
    ...tableStyles.body.cell,
    // display: "grid",
    gap: variables.half_gap,
  },
  datesCell: { ...tableStyles.body.cell, width: "9rem", minWidth: "9rem" },
  [`@media ${variables.media.smallscreen}`]: {
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});
const Trains = () => {
  const classes = trainStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [reference, setReference] = useState(null);

  const tableHeaders = commonTableHeaders({
    Company: (val) => val.company,
    Tags: (val) => _.get(val, "tags", []).join(","),
    "Origin Station": (val) => val.origin_station,
    "Destination Station": (val) => val.destination_station,
    Destination: (val) => val.destination,
    Adults: (val) => val.adults,
    Children: (val) => val.children || "0",
    Departure: (val) => val.departure,
    Arrival: (val) => val.arrival,
    "Ticket Class": (val) => val.ticket_class,
    "Payment Mode": (val) => val.payment_mode_display,
    "Market Type": (val) => val.market_type,
  });
  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const results = useQueries({
    queries: [
      {
        queryKey: ["trains", filters],
        queryFn: async () => {
          let correctPayload = { ...filters };
          if (filters.created__lte) {
            correctPayload["created__lte"] = DateTime.fromISO(
              filters.created__lte
            ).toISO();
          }
          if (filters.created__gte) {
            correctPayload["created__gte"] = DateTime.fromISO(
              filters.created__gte
            ).toISO();
          }
          if (filters.arrival__lte) {
            correctPayload["arrival__lte"] = DateTime.fromISO(
              filters.arrival__lte
            ).toISO();
          }
          if (filters.departure__gte) {
            correctPayload["departure__gte"] = DateTime.fromISO(
              filters.departure__gte
            ).toISO();
          }
          return await fetchTrainReservations({
            params: correctPayload,
          });
        },
        enabled: !showDetailModal,
        onError: (error) => genericRequestErrorHandler(error),
      },
      {
        queryKey: ["trainsStats", filters],
        queryFn: async () => {
          let correctPayload = { ...filters };
          if (filters.created__lte) {
            correctPayload["created__lte"] = DateTime.fromISO(
              filters.created__lte
            ).toISO();
          }
          if (filters.created__gte) {
            correctPayload["created__gte"] = DateTime.fromISO(
              filters.created__gte
            ).toISO();
          }
          if (filters.reservation_date) {
            correctPayload["reservation_date"] = DateTime.fromISO(
              filters.reservation_date
            ).toISO();
          }
          return await getTrainReservationStats({
            params: correctPayload,
          });
        },
        enabled: !showDetailModal,
      },
    ],
  });
  return (
    <div id="TRAListPage" className={classes.Trains}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
      />
      <HamburgerMenu />
      {!_.get(results, "1.isLoading", true) &&
        _.get(results, "1.data", null) && (
          <ReservationStats
            title="Reservation Stats"
            stats={_.get(results, "1.data.data.stats_per_currency")}
          />
        )}
      <ControlStrip
        limit={_.get(filters, "limit")}
        page={filters.page}
        count={_.get(results, "0.data.data.count")}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {_.get(results, "0.isLoading", false) ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                {Object.keys(tableHeaders).map((value, idx) => (
                  <th className={classes.headCell} key={idx}>
                    {value}
                  </th>
                ))}
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.get(results, "0.data.data.results", []).map((val, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.tablecell}>{idx + 1}</td>
                  {Object.entries(tableHeaders).map((entry, ridx) => {
                    var cell = null;
                    const fn = entry[1];
                    switch (entry[0].toLowerCase()) {
                      case "arrival":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      case "departure":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      case "created":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      default:
                        cell = fn(val) || "N/A";
                        break;
                    }
                    return (
                      <td className={classes.tablecell} key={ridx}>
                        {cell}
                      </td>
                    );
                  })}
                  <td className={classes.actionCell}>
                    <CustomButton
                      id={`detailTRAButton_${idx}`}
                      appearance="ghost"
                      onClick={() => {
                        setShowDetailModal(true);
                        setReference(val.reference);
                      }}>
                      <strong>Details</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showDetailModal && (
        <DetailTrainModal
          onClose={() => setShowDetailModal(false)}
          reference={reference}
        />
      )}
    </div>
  );
};
export default Trains;
