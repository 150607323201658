import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { cardStyles, tableStyles, variables } from "../../../../../variables";
import _ from "lodash";

const feeTypeMap = {
  NUM: "Numerical",
  PERC: "Percentage",
};

const getErrors = (productFees, groups_display) => {
  const hasB2CGroup = groups_display.includes("has_b2c");

  var error = null;
  const hasB2BFees = productFees.some((fee) => fee.market_type === "B2B");
  const hasB2CFees = productFees.some((fee) => fee.market_type === "B2C");
  const isB2CAndHasNoB2CFees = hasB2CGroup && !hasB2CFees;

  if (!hasB2BFees) {
    error = "(The B2B Product Fee is missing.)";
  }
  if (hasB2BFees && isB2CAndHasNoB2CFees) {
    error = "(The B2C Product Fee is missing.)";
  }
  if (!hasB2BFees && isB2CAndHasNoB2CFees) {
    error =
      "(There are missing Product Fees for both B2B and B2C market types.)";
  }
  return error;
};

const travelServiceMap = {
  FL: "Flight",
  ACC: "Accommodation",
  TRF: "Transfers",
  MI: "Addon/Tour/Static Package",
  COA: "Coach",
  TRA: "Train",
  FER: "Ferries",
  GEN: "General Services",
  REST: "Restaurant",
  ACT: "Activities",
};

const styles = createUseStyles({
  ...cardStyles,
  ProductFees: { ...cardStyles.card },
  header: {
    ...cardStyles.header,
    fontSize: "1.2rem",
    padding: variables.normal_gap,
    marginTop: "1px",
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  tableContainer: {
    display: "block",
    maxHeight: "20rem",
    overflow: "auto",
    width: "100%",
  },
  table: {
    width: "100%",
  },
  thead: {
    position: "sticky",
    top: 0,
  },
  emptyProductFees: {
    color: "red",
    textAlign: "center",
    minHeight: "10rem",
    lineHeight: "10rem",
  },
  red: { color: "red" },
});
const ProductFees = ({ productFees, groups_display }) => {
  const classes = styles();

  const warnings = getErrors(productFees, groups_display);

  return (
    <div className={classes.ProductFees}>
      <div className={classes.header}>
        <span>
          Product Fees{" "}
          {warnings && <span className={classes.red}>{warnings}</span>}
        </span>
      </div>
      <div className={classes.tableContainer}>
        {productFees.length === 0 ? (
          <div className={classes.emptyProductFees}>
            <strong>No Product Fees</strong>
          </div>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Market Type</th>
                <th className={classes.headCell}>Product Type</th>
                <th className={classes.headCell}>Fee</th>
                <th className={classes.headCell}>Fee Type</th>
              </tr>
            </thead>
            <tbody>
              {productFees.map((fee, idx) => (
                <tr key={fee.id}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  <td className={classes.tableCell}>
                    {_.get(fee, "market_type") || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(travelServiceMap, _.get(fee, "product_type")) ||
                      "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(fee, "fee") === 0 ? "0" : _.get(fee, "fee") || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(feeTypeMap, _.get(fee, "fee_type")) || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
ProductFees.propTypes = {
  productFees: PropTypes.array.isRequired,
  groups_display: PropTypes.array.isRequired,
};
export default ProductFees;
