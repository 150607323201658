import _, { set } from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import ControlStrip from "../../../components/ControlStrip";
import Loader from "../../../components/Loader";
import { handleApplyFilters, medWeekdayDatetimer } from "../../../helpers";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../variables";
import Navbar from "./navbar";
import {
  cloneVesselApiConfiguration,
  fetchVesselAPIConfigurations,
} from "../../../api/VesselEngine/ApiConfiguration/ApiConfiguration";
import DetailVesselEngineModal from "../VesselEngineModals/DetailVesselEngineModal";
import EditVesselEngineModal from "../VesselEngineModals/EditVesselEngineModal";
import DeleteVesselAPIConfigurationModal from "../VesselEngineModals/DeleteVesselEngineModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HamburgerMenu from "../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../hooks";
import * as yup from "yup";
import { genericRequestErrorHandler } from "../../../api";
import { vesselEngineTrueFalseMApping } from "../common";
import { CustomButton } from "../../../components/CustomButton";
import VerifyModal from "../../../components/VerifyModal";
import ReservationsStatusTag from "../../../components/Tags/ReservationsStatusTag";

const initialFilters = {
  page_size: 20,
  page: 1,
  ordering: "",
  discending: "",
  name__icontains: "",
  created__gte: "",
  created__lte: "",
  environment: "",
  is_global: "",
  is_own: "",
  market_type: "",
  source_entity: "",
};
const navbarSchema = yup.object().shape({
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const enviromentOptions = {
  "": "-----",
  STAGING: "Staging",
  PRODUCTION: "Production",
};

const ApiConfigurationStyles = createUseStyles({
  VesselEngineAPIConfigurations: genericIndexesStyles.indexes.mobile(),
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
  datesCell: { ...tableStyles.body.cell, minWidth: "9rem", width: "9rem" },
  checkBox: tableStyles.checkBox,
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      gridTemplateColumns: "repeat(2,1fr) ",
      padding: `calc(${variables.normal_gap} * 2.5)!important`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const VesselEngineAPIConfigurations = () => {
  const classes = ApiConfigurationStyles();

  const queryClient = useQueryClient();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [apiConfigurationId, setApiConfigurationId] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [vessel, setVessel] = useState({});

  const tableHeaders = {
    Name: (val) => _.capitalize(_.get(val, "name")),
    Supplier: (val) => _.capitalize(_.get(val, "supplier")),
    "Client Id": (val) => val.client_id,
    "Source Entity": (val) => val.source_entity,
    "Market type": (val) => val.market_type,
    "Is Global": (val) =>
      _.get(vesselEngineTrueFalseMApping, _.get(val, "is_global")),
    "is Own": (val) =>
      _.get(vesselEngineTrueFalseMApping, _.get(val, "is_own")),
    "Partner Code": (val) => val.partner_code,
    "Market Type": (val) => val.market_type,
    Environment: (val) => _.get(enviromentOptions, _.get(val, "environment")),
    Created: (val) => val.created,
    Status: (val) => <ReservationsStatusTag status={val.status} />,
  };
  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };
  const { data, isLoading } = useQuery({
    queryKey: ["VesselEngineAPIConfigurations", filters],
    queryFn: () =>
      fetchVesselAPIConfigurations({
        params: {
          ...filters,
          page_size: filters.page_size,
        },
      }),
    enabled: !showDetailModal && !showEditModal && !showDeleteModal,
  });

  const cloneVesselApiMutation = useMutation({
    mutationFn: async (uid) => await cloneVesselApiConfiguration({ uid }),
    onSuccess: () => {
      toast.success("Cloned Vessel Api Configuration added");
      setShowVerifyModal(false);
      queryClient.invalidateQueries("VesselEngineAPIConfigurations");
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });
  const onClone = (uid) => {
    setApiConfigurationId(uid);
  };

  return (
    <div
      id="VesselEngineAPIConfigurationsListPage"
      className={classes.VesselEngineAPIConfigurations}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setApiConfigurationId(null);
          setShowEditModal(true);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.page_size}
        page={filters.page}
        count={_.get(data, "data.count", 0)}
        onChangeLimit={(page_size) =>
          setFilters({ ...filters, page_size, page: 1 })
        }
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th key={idx} className={classes.headCell}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.get(data, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "created":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      return (
                        <td className={classes.datesCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    default:
                      cell = fn(val) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.tableCell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setApiConfigurationId(val.uid);
                      setShowDetailModal(true);
                    }}>
                    <strong>Details</strong>
                  </CustomButton>
                  <CustomButton
                    id={`EditVesselEngineButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setApiConfigurationId(val.uid);
                      setShowEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      onClone(val.uid);
                      setShowVerifyModal(true);
                    }}>
                    <strong>Clone</strong>
                  </CustomButton>
                  <CustomButton
                    id={`DeleteVesselEngineButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setVessel(val);
                      setApiConfigurationId(val.uid);
                      setShowDeleteModal(true);
                    }}>
                    <strong>Delete</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDetailModal && (
        <DetailVesselEngineModal
          uid={apiConfigurationId}
          onClose={() => {
            setShowDetailModal(false);
          }}
        />
      )}
      {showEditModal && (
        <EditVesselEngineModal
          apiConfigurationId={apiConfigurationId}
          onClose={() => {
            setShowEditModal(false);
            setApiConfigurationId(null);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteVesselAPIConfigurationModal
          vessel={vessel}
          deletedUid={apiConfigurationId}
          onClose={() => {
            setShowDeleteModal(false);
          }}
        />
      )}
      {showVerifyModal && (
        <VerifyModal
          // title=""
          // message=""
          isLoading={cloneVesselApiMutation.isLoading}
          onConfirm={() => {
            cloneVesselApiMutation.mutate(apiConfigurationId);
          }}
          onClose={() => setShowVerifyModal(false)}
        />
      )}
    </div>
  );
};
export default VesselEngineAPIConfigurations;
