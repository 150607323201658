import { VALAQUENTA_API_URL } from "..";
import { authorizedAxios, loggingAxios } from "../../App";

export function fetchFlightsAPIConfigurations({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/flightengine/crs-entry/`, {
    params,
  });
}

export function retrieveFlightAPIConfiguration({ uid }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/flightengine/crs-entry/${uid}/`,
    {}
  );
}

export function patchFlight({ uid, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/flightengine/crs-entry/${uid}/`,
    payload
  );
}

export function createNewFlight({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/flightengine/crs-entry/`,
    payload
  );
}

export function cloneFlight({ uid }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/flightengine/crs-entry/clone/${uid}/`
  );
}

export function deleteFlightApiConfiguration({ uid }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/flightengine/crs-entry/${uid}/`
  );
}
