import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  createNewAirport,
  patchAirport,
  retrieveAirport,
} from "../../../../../api/FlightEngine/Airports";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { countries, handleValidationErrors } from "../../../../../helpers";
import { toast } from "react-toastify";
import Loader from "../../../../../components/Loader";
import _ from "lodash";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  name_en: yup
    .string()
    .min(2, "Must be at least 2 characters")
    .max(100, "Must be less than 100 characters")
    .default(""),
  email: yup.string().email("Invalid email address").nullable().default(""),
  score: yup.number().required("It must be a valid integer.").default(0),
  altitude: yup.number().required("It must be a valid integer.").default(0),
  utc_offset: yup.number().required("It must be a valid integer.").default(0),
  icao: yup
    .string()
    .required("This field may not be blanc.")
    .required("This field may not be blanc.")
    .default(""),
  iata: yup
    .string()
    .required("This field may not be blanc.")
    .required("This field may not be blanc.")
    .default(""),
  airport_type: yup
    .string()
    .oneOf(["IN", "DO", "OT"], "Invalid value")
    .required("This field may not be blanc.")
    .default(""),
  country_code: yup.string().nullable().default(""),
  status: yup
    .string()
    .oneOf(["AC", "IN", "CA"], "Must be between Active, Inactive or Candidate")
    .required("This field may not be blanc.")
    .default(""),
  lat: yup
    .number()
    .nullable()
    .min(-90, "must be greater than or equal to -90")
    .max(90, "must be less than or equal to 90")
    .default(0),
  lng: yup
    .number()
    .nullable()
    .min(-180, "must be greater than or equal to -180")
    .max(180, "must be less than or equal to 180")
    .default(0),
});

const airportTypeMapping = {
  "": "-----",
  IN: "International",
  DO: "Domestic",
  OT: "Other",
};

const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditAirportModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridTemplateRows: "repeat(2, max-content)",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: { ...cardStyles.cardBody, padding: variables.normal_gap },
  inputGroup: { ...formInputGroup },
  [`@media ${variables.media.retina}`]: {
    card: {
      minWidth: "50rem",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCardBody: "50rem",
    cardCol2: {
      gridTemplateColumns: "1fr 1fr",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    card: {
      minWidth: "50rem",
    },
    cardCol2: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: variables.normal_gap,
    },
    cardCol3: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      gap: variables.normal_gap,
    },
  },
});
const EditAirportModal = ({ airportId, onClose }) => {
  const classes = styles();
  const mode = airportId ? "edit" : "add";
  const queryClient = useQueryClient();

  const [form, setForm] = useState(jsonSchema.cast({}));

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["EditAirportModal"],
    queryFn: () => {
      return retrieveAirport({ id: airportId });
    },
    onSuccess: (data) => {
      setForm(jsonSchema.cast(_.get(data, "data", {}), { stripUnknown: true }));
    },
    onError: (error) => {
      toast.error(error.message);
      onClose();
    },
    enabled: mode === "edit",
  });

  const editMutation = useMutation({
    mutationFn: ({ id, payload }) => {
      return patchAirport({ id, payload });
    },
    onSuccess: () => {
      toast.success("Airport Info Updated");
      queryClient.invalidateQueries("airportsList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });
  const addMutation = useMutation({
    mutationFn: ({ payload }) => {
      return createNewAirport({ payload });
    },

    onSuccess: () => {
      toast.success("New Airport added");
      queryClient.invalidateQueries("airportsList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ payload, id }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload });
      }
      if (mode === "edit") {
        editMutation.mutate({ id, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };
  const handleInputChange = (e) => {
    e.preventDefault();
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  return (
    <div
      id="addEditAirportAPIConfigurationModal"
      className={classes.EditAirportModal}>
      <div className={classes.modalCard}>
        {loading && <Loader onTop={true} />}
        <div className={classes.modalCardHeader}>
          <span>{_.startCase(mode)} Airport</span>
        </div>
        <div className={classes.modalCardBody}>
          <form onChange={handleInputChange}>
            <div className={classes.card}>
              <div className={classes.cardHeader}>Basic Information</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="name_en">Name</label>
                    <input
                      id="name_en"
                      type="text"
                      name="name_en"
                      value={form.name_en}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="email">Email</label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      value={form.email}
                    />
                  </div>
                </div>
                <div className={classes.cardCol3}>
                  <div className={classes.inputGroup}>
                    <label>Score</label>
                    <input
                      id="score"
                      type="number"
                      name="score"
                      value={form.score}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Altitude</label>
                    <input
                      id="altitude"
                      type="number"
                      name="altitude"
                      value={form.altitude}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Utc Offset</label>
                    <input
                      id="utc_offset"
                      type="number"
                      name="utc_offset"
                      value={form.utc_offset}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="iata">I.A.T.A</label>
                    <input
                      id="iata"
                      type="text"
                      name="iata"
                      value={form.iata}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="icao">I.C.A.O</label>
                    <input
                      id="icao"
                      type="text"
                      name="icao"
                      value={form.icao}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="country_code">Airport Type</label>
                    <select
                      id="airport_type"
                      name="airport_type"
                      value={form.airport_type}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(airportTypeMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="country_code">Country</label>
                    <select
                      id="country_code"
                      name="country_code"
                      value={form.country_code}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(countries).map(([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="status">Status</label>
                    <select
                      id="status"
                      name="status"
                      value={form.status}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(statusMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.cardHeader}>Coordinates Data</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label>Lat</label>
                    <input
                      id="lat"
                      type="number"
                      name="lat"
                      value={_.get(form, "lat")}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Lng</label>
                    <input
                      id="lng"
                      type="number"
                      name="lng"
                      value={_.get(form, "lng")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            id="submit"
            appearance="primary"
            onClick={(e) => {
              e.preventDefault();
              onSubmit({
                id: airportId,
                payload: form,
              });
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
EditAirportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditAirportModal;
