import { VALAQUENTA_API_URL } from "../../..";
import { authorizedAxios, loggingAxios } from "../../../../App";

export function fetchWebSiteTermsAndConditions({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/terms-for-entity/`,
    {
      params,
    }
  );
}

export function retrieveWebSiteTermAndCondition({ id }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/terms-for-entity/${id}/`
  );
}

export function patchWebSiteTermAndCondition({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/canaveral/terms-for-entity/${id}/`,
    payload
  );
}

export function createWebSiteTermAndCondition({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/canaveral/terms-for-entity/`,
    payload
  );
}

export function deleteWebSiteTermAndCondition({ id }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/canaveral/terms-for-entity/${id}/`
  );
}
