import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import {
  cardStyles,
  modalGenericStyles,
  tableStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  patchAccReservation,
  retrieveAccReservation,
} from "../../../../../api/CRS/Accommodations";
import GuestCard from "../../../../../components/cards/GuestInfo";
import useReservationDetails from "../../../../../hooks";
import EditGuestModal from "../../../CommonCRSModals/EditGuestModal/index";
import { DateTime } from "luxon";
import EmailsCard from "../../../../../components/cards/emails";
import Loader from "../../../../../components/Loader";
import EditCRSTagModal from "../../../CommonCRSModals/EditCRSTagModal";
import { toast } from "react-toastify";
import { Price } from "../../../../../components/Price";
import ReservationEventsModal from "../../../ReservationEventsModal";
import { DocumentsCRSCard } from "../../../CommonCRSModals/DocumentsCRS/List";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryAttractorLogs } from "../../../../../api/elasticAttractor";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { InternalLogs } from "../../../CommonCRSModals/InternalLogs";
import SendLogsToVendorModal from "../../../common/SendLogsToVendorModal";
import ReservationsStatusTag from "../../../../../components/Tags/ReservationsStatusTag";
import TabStrip from "../../../../../components/TabStrip";
import { CustomButton } from "../../../../../components/CustomButton";
import ACCLogsTab from "../ACCLogsTab";
import { genericRequestErrorHandler } from "../../../../../api";
import Receivables from "../../../CommonCRSModals/Receivables";
import levenshtein from "fast-levenshtein";

const commonSyntaxHighlighterProps = {
  language: "javascript",
  customStyle: { fontSize: "smaller" },
};

const getTimestampFilter = (resonse) => {
  const responseTimestamp = _.get(resonse, "data.results.0._source.timestamp");
  const responseDateTime = responseTimestamp
    ? DateTime.fromISO(responseTimestamp)
    : null;
  var createdFilter = {};
  if (responseDateTime) {
    const twoHoursAgo = responseDateTime.minus({ hours: 2 });
    const gte = twoHoursAgo.toISO({ includeOffset: false });
    const lte = responseDateTime.toISO({ includeOffset: false });

    createdFilter = {
      filter: {
        range: {
          timestamp: {
            gte: gte,
            lte: lte,
          },
        },
      },
    };
  }
  return createdFilter;
};

const commonLogStyles = createUseStyles({
  CommonLog: {
    maxHeight: "1000vh",
    overflow: "auto",
    height: "100%",
    transition: "max-height 0.5s ease-in-out",
  },
  logHeader: {
    display: "inline-grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: variables.normal_gap,
    alignItems: "center",
  },
  cardHeader: cardStyles.header,
  cardBody: cardStyles.body,
  cardActions: modalGenericStyles.cardActions,
});
const CommonLog = ({ title, index = 1, log = {}, onDownload }) => {
  const [collapsed, setCollapsed] = useState(true);
  const classes = commonLogStyles();

  return (
    <div className={classes.CommonLog}>
      <div className={classes.cardHeader}>
        <h5 className={classes.logHeader}>
          {title} {index}
          {": "}
          {DateTime.fromISO(log?._source?.timestamp).toLocaleString(
            DateTime.DATETIME_FULL
          )}
          <div className={classes.cardActions}>
            <CustomButton
              appearance="ghost"
              onClick={() => setCollapsed((p) => !p)}>
              <strong>{collapsed ? "Expand" : "Collapse"}</strong>
            </CustomButton>
            {typeof onDownload === "function" && (
              <CustomButton appearance="ghost" onClick={onDownload}>
                <strong>Download</strong>
              </CustomButton>
            )}
          </div>
        </h5>
      </div>
      {!collapsed && (
        <div className={classes.cardBody}>
          <div className={classes.CommonLog}>
            <div>
              <h6>URL</h6>
              <SyntaxHighlighter {...commonSyntaxHighlighterProps}>
                {log?._source?.url ?? "N/A"}
              </SyntaxHighlighter>
            </div>
            <div>
              <h6>Duration</h6>
              <SyntaxHighlighter {...commonSyntaxHighlighterProps}>
                {`${log?._source?.elapsed_time.toFixed(2)} Seconds` ?? "N/A"}
              </SyntaxHighlighter>
            </div>
            <div>
              <h6>Headers</h6>
              <SyntaxHighlighter {...commonSyntaxHighlighterProps}>
                {JSON.stringify(log?._source?.request_headers ?? {}, null, 2)}
              </SyntaxHighlighter>
            </div>
            <div>
              <h6>Request</h6>
              <SyntaxHighlighter {...commonSyntaxHighlighterProps}>
                {JSON.stringify(log?._source?.request_params ?? {}, null, 2)}
              </SyntaxHighlighter>
            </div>
            <div>
              <h6>Response</h6>
              <SyntaxHighlighter {...commonSyntaxHighlighterProps}>
                {JSON.stringify(log?._source?.response_body ?? {}, null, 2)}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
CommonLog.defaultProps = {
  log: {},
};
CommonLog.propTypes = {
  title: PropTypes.string.isRequired,
};

function onDownloadLogs(
  content,
  fileName = "logs.json",
  contentType = "text/plain"
) {
  var a = document.createElement("a");
  var file = new Blob([content], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}

const APILogsStyles = createUseStyles({
  APILogs: { width: "100%", padding: variables.normal_gap, maxWidth: "80rem" },
  logHeader: {
    display: "inline-grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: variables.normal_gap,
    alignItems: "center",
  },
  card: cardStyles.card,
  log: (props) => ({
    maxHeight: props.bookingLogsCollapsed ? "0px" : "100%",
    overflow: props.bookingLogsCollapsed ? "hidden" : "auto",
  }),
  pre: {
    maxWidth: "100%",
    whiteSpace: "pre-wrap",
    background: variables.colors.background.secondary.light,
    padding: variables.normal_gap,
    borderRadius: "5px",
  },
});

/*
 * The APILogs component is used to display the API logs for the accommodation
 * The first call must be the book call, and take the book request timestamp as the start of the filter.
 * After that you filter all the other calls by the timestamp of the book call.
 * If the book call is not found, you can use the prebook call as the start of the filter, and filter
 * all the other calls by the timestamp of the prebook call.
 * You do the same for the details call, and the init search call.
 *
 * @param {string} reference - The reference of the accommodation
 * @param {string} source_entity - The source entity of the accommodation
 * @param {array} prebook_tokens - The prebook tokens of the accommodation
 */
const APILogs = ({ reference, source_entity, prebook_tokens }) => {
  const [bookingLogsCollapsed, setBookingLogsCollapsed] = useState(true);
  const [createdFilter, setCreatedFilter] = useState({});
  const classes = APILogsStyles({ bookingLogsCollapsed });

  const envQuery = { match: { environment: "PRODUCTION" } };

  const book_payload = {
    index_name: ["accbook"],
    elastic_query: {
      query: {
        bool: {
          must: [
            envQuery,
            {
              match_phrase: {
                request_params: `${source_entity}___${reference}`,
              },
            },
          ],
        },
      },
    },
  };
  const { data: bookingLogs, isLoading: bookingLogsLoading } = useQuery({
    queryKey: ["booking_api_logs", reference, source_entity],
    queryFn: () => queryAttractorLogs({ payload: book_payload }),
    onSuccess: (res) => {
      setCreatedFilter(getTimestampFilter(res));
    },
    refetchOnWindowFocus: false,
  });

  const prebook_payload = {
    index_name: ["accprebook"],
    elastic_query: {
      query: {
        bool: {
          must: [
            envQuery,
            ..._.flatten(
              prebook_tokens.map(([token, code]) => [
                { match_phrase: { request_params: code } },
                { match_phrase: { response_body: token } },
              ])
            ),
          ],
          ...createdFilter,
        },
      },
    },
  };
  const { data: prebookLogs, isLoading: prebookLogsLoading } = useQuery({
    queryKey: [
      "prebook_api_logs",
      prebook_tokens.join("___"),
      source_entity,
      createdFilter,
    ],
    queryFn: () => queryAttractorLogs({ payload: prebook_payload }),
    onSuccess: (res) => {
      if (!_.isEmpty(createdFilter)) {
        return;
      }
      const newCreatedFilter = getTimestampFilter(res);
      if (_.isEmpty(newCreatedFilter)) {
        return;
      }
      setCreatedFilter(newCreatedFilter);
    },
    refetchOnWindowFocus: false,
    enabled: !bookingLogsLoading,
  });

  const details_payload = {
    index_name: ["accdetails"],
    elastic_query: {
      query: {
        bool: {
          must: [
            envQuery,
            ...prebook_tokens.map(([token, code]) => ({
              match_phrase: { response_body: code },
            })),
          ],
          ...createdFilter,
        },
      },
    },
  };
  const { data: detailsLogs, isLoading: detailsLogsLoading } = useQuery({
    queryKey: [
      "hotel_details_api_logs",
      reference,
      source_entity,
      createdFilter,
    ],
    queryFn: () => queryAttractorLogs({ payload: details_payload }),
    onSuccess: (res) => {
      if (!_.isEmpty(createdFilter)) {
        return;
      }
      const newCreatedFilter = getTimestampFilter(res);
      if (_.isEmpty(newCreatedFilter)) {
        return;
      }
      setCreatedFilter(newCreatedFilter);
    },
    refetchOnWindowFocus: false,
    enabled: !bookingLogsLoading,
  });

  var session_ids = detailsLogs?.data?.results
    ?.map((res) => res?._source?.response_body?.sessionId)
    .filter((v) => v);
  session_ids = [...new Set(session_ids)];

  const init_payload = {
    index_name: ["accinitsearch"],
    elastic_query: {
      query: {
        bool: {
          must: [
            envQuery,
            ...session_ids.map((session_id) => ({
              match_phrase: { response_body: session_id },
            })),
          ],
          ...createdFilter,
        },
      },
    },
  };
  const { data: initSearchLogs, isLoading: initSearchLogsLoading } = useQuery({
    queryKey: [
      "hotel_initsearch_api_logs",
      reference,
      source_entity,
      session_ids.join("___"),
      createdFilter,
    ],
    queryFn: () =>
      session_ids.length === 0
        ? new Promise((resolve) => resolve(null))
        : queryAttractorLogs({ payload: init_payload }),
    refetchOnWindowFocus: false,
    enabled: !bookingLogsLoading,
  });

  const LastInitSearchLog =
    _.sortBy(
      initSearchLogs?.data?.results ?? [],
      (v) => v?._source?.timestamp
    ).pop() || {};

  const lastInitTimestamp = _.get(LastInitSearchLog, "_source.timestamp");
  const lastInitGTE = lastInitTimestamp
    ? DateTime.fromISO(lastInitTimestamp).toISO({ includeOffset: false })
    : null;

  const polling_payload = {
    index_name: ["accpoll"],
    elastic_query: {
      query: {
        bool: {
          must: [
            envQuery,
            ...session_ids.map((session_id) => ({
              match_phrase: { response_body: session_id },
            })),
          ],
          ...(lastInitGTE
            ? {
                filter: {
                  range: {
                    timestamp: {
                      gte: lastInitGTE,
                    },
                  },
                },
              }
            : createdFilter),
        },
      },
    },
  };
  const { data: pollingLogs, isLoading: pollingLogsLoading } = useQuery({
    queryKey: [
      "hotel_polling_api_logs",
      reference,
      source_entity,
      session_ids.join("___"),
      createdFilter,
    ],
    queryFn: () =>
      session_ids.length === 0
        ? new Promise((resolve) => resolve(null))
        : queryAttractorLogs({ payload: polling_payload }),
    refetchOnWindowFocus: false,
    enabled: !bookingLogsLoading,
  });

  return (
    <div id="APILogsList" className={classes.APILogs}>
      <div className={classes.card}>
        {initSearchLogsLoading && <Loader onTop={true} />}
        {/* show the last init search */}
        {!_.isEmpty(LastInitSearchLog) && (
          <CommonLog
            title="Init Search Log"
            log={LastInitSearchLog}
            onDownload={() => {
              onDownloadLogs(
                JSON.stringify(LastInitSearchLog._source, null, 2),
                `initiate_search___${reference}.json`
              );
            }}
          />
        )}
      </div>
      <div>
        {pollingLogsLoading && <Loader onTop={true} />}
        {_.sortBy(
          pollingLogs?.data?.results ?? [],
          (v) => v?._source?.timestamp
        ).map((res, idx) => (
          <CommonLog
            title="Polling Log"
            log={res}
            index={idx + 1}
            onDownload={() => {
              onDownloadLogs(
                JSON.stringify(res._source, null, 2),
                `search_polling___${idx + 1}___${reference}.json`
              );
            }}
          />
        ))}
      </div>
      <div>
        {detailsLogsLoading && <Loader onTop={true} />}
        {_.sortBy(
          detailsLogs?.data?.results ?? [],
          (v) => v?._source?.timestamp
        ).map((res, idx) => (
          <CommonLog
            title="Details Log"
            log={res}
            index={idx + 1}
            onDownload={() => {
              onDownloadLogs(
                JSON.stringify(res._source, null, 2),
                `details___${idx + 1}___${reference}.json`
              );
            }}
          />
        ))}
      </div>
      <div>
        {prebookLogsLoading && <Loader onTop={true} />}
        {_.sortBy(
          prebookLogs?.data?.results ?? [],
          (v) => v?._source?.timestamp
        ).map((res, idx) => (
          <CommonLog
            title="Prebook Log"
            log={res}
            index={idx + 1}
            onDownload={() => {
              onDownloadLogs(
                JSON.stringify(res._source, null, 2),
                `prebook___${idx + 1}___${reference}.json`
              );
            }}
          />
        ))}
      </div>
      <div>
        {bookingLogsLoading && <Loader onTop={true} />}
        {(bookingLogs?.data?.results ?? []).map((res, idx) => (
          <CommonLog
            title="Booking Log"
            log={res}
            index={idx + 1}
            onDownload={() => {
              onDownloadLogs(
                JSON.stringify(res._source, null, 2),
                `book___${idx + 1}___${reference}.json`
              );
            }}
          />
        ))}
      </div>
    </div>
  );
};
APILogs.propTypes = {
  reference: PropTypes.string.isRequired,
  source_entity: PropTypes.string.isRequired,
  prebook_tokens: PropTypes.array.isRequired,
};

const roomsListStyles = createUseStyles({
  ...cardStyles,
  RoomsList: { ...cardStyles.card, maxWidth: "100%" },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: { ...tableStyles.table, maxWidth: "100%", overflow: "hidden" },
  thead: tableStyles.head,
  tbody: tableStyles.body,
  trow: tableStyles.body.row,
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
});

const RoomsList = ({ title, roomList }) => {
  const classes = roomsListStyles();
  return (
    <div className={classes.Roomslist}>
      <div className={classes.header}>
        <span>{title}</span>
      </div>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              <th className={classes.headCell}>Name</th>
              <th className={classes.headCell}>Bedding</th>
              <th className={classes.headCell}>Board</th>
              <th className={classes.headCell}>Cancellation</th>
              <th className={classes.headCell}>Type</th>
            </tr>
          </thead>
          <tbody className={classes.tbody}>
            {roomList.map((room, idx) => (
              <tr key={idx} className={classes.trow}>
                <td className={classes.tableCell}>{idx + 1}</td>
                <td className={classes.tableCell}>
                  {_.startCase(room.name) || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.startCase(room.bedding) || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.startCase(room.board_display) || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.startCase(room.booking_remarks.cancellation) || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.startCase(room.type) || "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
RoomsList.propTypes = {
  title: PropTypes.string.isRequired,
  roomList: PropTypes.array.isRequired,
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailAccommodationsModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    // display: "grid",
    padding: "",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: { ...cardStyles.card, maxWidth: "100%" },
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    maxWidth: "100%",
    // overflow: "auto",
    justifyContent: "start",
  },
  firstRow: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
    padding: variables.normal_gap,
  },
  creatorCardBody: {
    extend: "cardBody",
    padding: [`calc(${variables.normal_gap} * 3.3)`, variables.normal_gap],
  },
  componentRow: {
    extend: "firstRow",
    gridTemplateColumns: "repeat(1, 1fr)",
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCardBody: {
      minWidth: "50rem",
    },
    firstRow: {
      gridTemplateColumns: "1fr 1fr",
      justifyItems: "center",
    },
  },
});
const DetailAccommodationsModal = ({ reference, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicInfo");
  const [showSendLogsModal, setShowSendLogsModal] = useState(false);

  const queryClient = useQueryClient();

  const menuTabs = {
    basicInfo: "Basic Info",
    reservationEvents: "Reservation Events",
    receivables: "Receivables",
    documents: "Documents",
    apiLogs: "API Logs",
    internalLogs: "Internal Logs",
    logs: "User Action Logs",
  };

  const {
    data: accommodation,
    guestEditMode,
    guestEditId,
    editCRSTagMode,
    reservationEventsModal,
    openGuestModal,
    closeGuestModal,
    openEditCRSTagModal,
    closeEditCRSTagModal,
    closeReservationEventsModal,
  } = useReservationDetails({
    reference,
    retrieveReservationData: retrieveAccReservation,
    onCloseDetails: onClose,
  });

  const name = accommodation?.data?.accommodation_meta?.name ?? "";
  const prebookName = accommodation?.data?.prebook_name ?? "";
  const levenshteinScore = levenshtein.get(name, prebookName);

  const basicDataCpl = [
    ["Reference", "reference"],
    ["Name", null, _.get(accommodation, "data.accommodation_meta.name", "")],
    [
      "Prebook Name (Score)",
      null,
      `${prebookName || "N/A"} (${levenshteinScore})`,
    ],
    [
      "City",
      null,
      _.get(accommodation, "data.accommodation_meta.destination_name"),
    ],
    ["Address", null, _.get(accommodation, "data.accommodation_meta.address")],
    ["Supplier Reference", "supplier_reservation_reference"],
    ["Tags", null, _.get(accommodation, "data.tags", []).join(",")],
    ["Rating", null, _.get(accommodation, "data.accommodation_meta.rating")],
    [
      "Check In",
      null,
      DateTime.fromISO(_.get(accommodation, "data.check_in")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    [
      "Check Out",
      null,
      DateTime.fromISO(_.get(accommodation, "data.check_out")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    [
      "Refundable",
      null,
      _.get(accommodation, "data.non_refundable") === true ? "No" : "Yes",
    ],
    [
      "Option Booking",
      null,
      _.get(accommodation, "data.option_booking") === true ? "Yes" : "No",
    ],
    ["Providers", null, _.get(accommodation, "data.providers", []).join(",")],
    ["Market Type", "market_type"],

    ["Source Entity", "source_entity"],
    [
      "Original Service Price",
      null,
      <Price
        currency={_.get(accommodation, "data.currency")}
        price={_.get(accommodation, "data.original_service_price")}
      />,
    ],
    [
      "Supplier Contacted",
      null,
      _.get(accommodation, "data.supplier_contact") ? "Yes" : "No",
    ],
    [
      "Created",
      null,
      DateTime.fromISO(_.get(accommodation, "data.created")).toLocaleString(
        DateTime.DATETIME_MED_WITH_WEEKDAY
      ),
    ],
    [
      "Status",
      null,
      <ReservationsStatusTag status={_.get(accommodation, "data.status")} />,
    ],
  ];

  const creatorDataCpl = [
    ["External Creator", "external_creator"],
    [
      "Creator",
      null,
      _.get(accommodation, "data.guests", []).map((des) =>
        _.get(des, "creator")
      ),
    ],
  ];

  const { mutate: onSubmitTags, isLoading: tagsLoading } = useMutation({
    mutationFn: (values) => {
      return patchAccReservation({ payload: values, reference });
    },
    onSuccess: () => {
      toast.success("Tags updated successfully");
      queryClient.invalidateQueries("accommodations");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const basicDataActions = [["Edit", () => openEditCRSTagModal()]];
  if (accommodation?.data?.status === "FA") {
    basicDataActions.push([
      "Send Logs",
      () => {
        setShowSendLogsModal(true);
      },
    ]);
  }

  return (
    <div
      id="DetailAccommodationsModal"
      className={classes.DetailAccommodationsModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Accommodation Details</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {_.isEmpty(_.get(accommodation, "data")) ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicInfo" && (
                <React.Fragment>
                  <div className={classes.firstRow}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        actions={basicDataActions}
                        header="Basic Data"
                        cardInfo={basicDataCpl}
                        objectData={_.get(accommodation, "data")}
                      />
                    </div>
                    <div className={classes.creatorCardBody}>
                      <GenericBasicInfo
                        header="Creator Data"
                        cardInfo={creatorDataCpl}
                        objectData={_.get(accommodation, "data")}
                      />
                    </div>
                  </div>
                  <div className={classes.componentRow}>
                    <div className={classes.cardBody}>
                      <RoomsList
                        title={"Guest Room Informations"}
                        roomList={_.get(
                          accommodation,
                          "data.prebook_data.rooms"
                        )}
                      />
                      <GuestCard
                        title={"Guest Information"}
                        guests={_.get(accommodation, "data.guests", [])}
                        onEditGuest={(id) => {
                          openGuestModal(id);
                        }}
                        type={_.get(accommodation, "guest_type", "")}
                        onCloseEdit={() => closeGuestModal()}
                      />
                      <EmailsCard
                        title={"Email Informations"}
                        market_type={_.get(
                          accommodation,
                          "data.market_type",
                          ""
                        )}
                        reservation_id={_.get(
                          accommodation,
                          "data.reference",
                          ""
                        )}
                        service_type="ACC"
                        status={_.get(accommodation, "data.status")}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activeTab === "reservationEvents" && (
                <ReservationEventsModal
                  header="Reservation Events"
                  onClose={() => closeReservationEventsModal()}
                  events={_.get(accommodation, "data.reservation_events", [])}
                />
              )}
              {guestEditMode && !reservationEventsModal && !editCRSTagMode && (
                <EditGuestModal
                  onClose={closeGuestModal}
                  guestId={guestEditId}
                  // tags={[`${accommodation.reference}_reservation_user_ACC`]}
                  queryKey={`accommodation_reservation_users_documents`}
                  reference={_.get(accommodation, "data.reference")}
                  sourceEntity={_.get(accommodation, "data.source_entity", "")}
                />
              )}
              {!guestEditMode && !reservationEventsModal && editCRSTagMode && (
                <EditCRSTagModal
                  header={"Accomodation"}
                  tagsLoading={tagsLoading}
                  tags={_.get(accommodation, "data.tags")}
                  onClose={closeEditCRSTagModal}
                  onSubmitTags={onSubmitTags}
                />
              )}
              {activeTab === "receivables" && (
                <Receivables
                  data={_.get(accommodation, "data.payment_links", [])}
                />
              )}
              {activeTab === "documents" && (
                <DocumentsCRSCard
                  title="Documents"
                  queryKey={`accommodation_reservation_document`}
                  showAddButton={true}
                  tags={[
                    `${_.get(accommodation, "data.reference")}_reservation_ACC`,
                  ]}
                  reference={_.get(accommodation, "data.reference")}
                  sourceEntity={_.get(accommodation, "data.source_entity", "")}
                />
              )}
              {activeTab === "apiLogs" && (
                <APILogs
                  reference={reference}
                  source_entity={accommodation?.data?.source_entity}
                  prebook_tokens={(
                    accommodation?.data?.prebook_data?.rooms ?? []
                  ).map((room) => [room.booking_token, room.booking_code])}
                />
              )}
              {activeTab === "internalLogs" && (
                <InternalLogs
                  prebookData={_.get(accommodation, "data.prebook_data", {})}
                  bookData={_.get(accommodation, "data.post_booking_data", {})}
                />
              )}
              {activeTab === "logs" && <ACCLogsTab />}
            </React.Fragment>
          )}
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
      {showSendLogsModal && (
        <SendLogsToVendorModal
          reference={reference}
          serviceType="ACC"
          onClose={() => setShowSendLogsModal(false)}
        />
      )}
    </div>
  );
};
DetailAccommodationsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default DetailAccommodationsModal;
