import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Loader from "../../../../../components/Loader";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../../variables";
import Navbar from "./Navbar";
import ControlStrip from "../../../../../components/ControlStrip";
import { medWeekdayDatetimer, shortDater } from "../../../../../helpers";
import { fetchMasterPolicies } from "../../../../../api/CRM/MasterPolicies";
import MasterPolicyDetailModal from "../MasterPoliciesModals/MasterPoliciesDetailModal";
import MasterPoliciesEditModal from "../MasterPoliciesModals/MasterPoliciesEditModal";
import HamburgerMenu from "../../../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../../../hooks";
import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { genericRequestErrorHandler } from "../../../../../api";
import { fetchTravelServices } from "../../../../../api/CRM/TravelServices";
import { toast } from "react-toastify";
import { CustomButton } from "../../../../../components/CustomButton";

export const policiesStatusMapping = {
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};
const isGlobal = {
  true: "Yes",
  false: "No",
};

const initialFilters = {
  limit: 20,
  page: 1,
  status: "",
  name__icontains: "",
  booking_window_from__gte: "",
  booking_window_to__lte: "",
  service_execution_to__lte: "",
  service_execution_from__gte: "",
  ordering: "",
  discending: "",
  created__gte: "",
  created__lte: "",
  is_global: "",
};

const navbarSchema = yup.object().shape({
  name__icontains: yup
    .string()
    .min(2, "Name must be more than 2 characters")
    .max(100, "Name: Must be less than 100 characters"),
});

const masterPoliciesStyles = createUseStyles({
  MasterPolicies: genericIndexesStyles.indexes.mobile(),
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
  paxCell: { ...tableStyles.body.cell, width: "4rem", minWidth: "4rem" },
  distributionPeriodCell: { ...tableStyles.body.cell, width: "12rem" },
  reservationPeriodCell: { ...tableStyles.body.cell, width: "8rem" },
  roundingDigitsCell: { ...tableStyles.body.cell, width: "0.1rem" },
  datesCell: { ...tableStyles.body.cell, width: "13rem" },
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      // gridTemplateColumns: "repeat(2,1fr) ",
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const MasterPolicies = () => {
  const classes = masterPoliciesStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [masterPolicyId, setMasterPolicyId] = useState(null);

  const tableHeaders = {
    "Policy Name": (val) => _.capitalize(_.get(val, "name")),
    Pax: (val) =>
      `${val.pax_from ? val.pax_from : "N/A"} to ${
        val.pax_to ? val.pax_to : "N/A"
      }`,

    "Distribution Period": (val) => (
      <React.Fragment>
        <span>
          {val.booking_window_from
            ? shortDater(val.booking_window_from)
            : "N/A"}
        </span>
        <br />
        <span>-</span>
        <br />
        <span>
          {val.booking_window_to ? shortDater(val.booking_window_to) : "N/A"}
        </span>
      </React.Fragment>
    ),
    "Reservation Period": (val) => (
      <React.Fragment>
        <span>
          {val.service_execution_from
            ? shortDater(val.service_execution_from)
            : "N/A"}
        </span>
        <br />
        <span>-</span>
        <br />
        <span>
          {val.service_execution_to
            ? shortDater(val.service_execution_to)
            : "N/A"}
        </span>
      </React.Fragment>
    ),
    "Rounding Digits": (val) => val.rounding_digits || "N/A",
    "Is Global": (val) => _.get(isGlobal, _.get(val, "is_global")),
    Created: (val) => val.created,
    Status: (val) => val.status_dispay,
  };

  const handleApplyFilters = async (filters) => {
    try {
      const filteredFilters = Object.keys(filters).reduce((acc, key) => {
        if (filters[key] !== "") {
          acc[key] = filters[key];
        }
        return acc;
      }, {});
      await navbarSchema.validate(filteredFilters, { abortEarly: false });
      setFilters(filteredFilters);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        if (!validationErrors[err.path]) {
          validationErrors[err.path] = err.message;
          toast.error(err.message);
        }
      });
    }
  };

  const { data: masterPolicies, isLoading } = useQuery({
    queryKey: ["MasterPolicies", filters],
    queryFn: () => {
      if (filters.created__lte) {
        filters["created__lte"] = DateTime.fromISO(
          filters.created__lte
        ).toISO();
      }
      if (filters.created__gte) {
        filters["created__gte"] = DateTime.fromISO(
          filters.created__gte
        ).toISO();
      }
      return fetchMasterPolicies({
        params: filters,
      });
    },
    enabled: !showDetailModal && !showEditModal,
    onError: (error) => genericRequestErrorHandler(error),
  });
  const { data: travelServices, isLoading: tServicesLoading } = useQuery({
    queryKey: ["masterPoliciesTrServices"],
    queryFn: async () => await fetchTravelServices({}),
    onError: (error) => genericRequestErrorHandler(error),
  });
  return (
    <div className={classes.MasterPolicies}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyFilters}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.limit}
        page={filters.page}
        count={_.get(masterPolicies, "data.count", 0)}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th className={classes.headCell} key={idx}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.get(masterPolicies, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "last edited":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      return (
                        <td className={classes.datesCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    case "created":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      return (
                        <td className={classes.datesCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    case "rounding digits":
                      cell = fn(val) || "N/A";
                      return (
                        <td className={classes.roundingDigitsCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    case "reservation period":
                      cell = fn(val) || "N/A";
                      return (
                        <td
                          className={classes.reservationPeriodCell}
                          key={ridx}>
                          {cell}
                        </td>
                      );
                    case "distribution period":
                      cell = fn(val) || "N/A";
                      return (
                        <td
                          className={classes.distributionPeriodCell}
                          key={ridx}>
                          {cell}
                        </td>
                      );
                    case "pax":
                      cell = fn(val) || "N/A";
                      return (
                        <td className={classes.paxCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    default:
                      cell = fn(val) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.tableCell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setMasterPolicyId(val.id);
                      setShowDetailModal(true);
                    }}>
                    <strong>Details</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setMasterPolicyId(val.id);
                      setShowEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDetailModal && (
        <MasterPolicyDetailModal
          masterPolicyId={masterPolicyId}
          onClose={() => {
            setShowDetailModal(false);
            setMasterPolicyId(null);
          }}
        />
      )}
      {showEditModal && (
        <MasterPoliciesEditModal
          travelServices={travelServices}
          masterPolicyId={masterPolicyId}
          onClose={() => {
            setShowEditModal(false);
            setMasterPolicyId(null);
          }}
        />
      )}
    </div>
  );
};

export default MasterPolicies;
