import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchCarRentalVendors({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/vendors/`, {
    params,
  });
}

export function retrieveCarRentalVendor({ id }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/vendors/${id}/`);
}

export function patchCarRentalVendor({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crm/vendors/${id}/`,
    payload
  );
}

export function createNewCarRentalVendor({ payload }) {
  return loggingAxios.post(`${VALAQUENTA_API_URL}/crm/vendors/`, payload);
}

export function deleteCarRentalVendor({ id }) {
  return loggingAxios.delete(`${VALAQUENTA_API_URL}/crm/vendors/${id}/`);
}
