import React from "react";
import { createUseStyles } from "react-jss";
import { variables } from "../../variables";

const CarRentalEngineStyles = createUseStyles({
  CRM: {
    display: "grid",
    overflow: "hidden",
    placeContent: "center",
    textAlign: "center",
  },
  title: {
    display: "grid",
    textAlign: "center",
    height: "100%",
    alignItems: "center",
    padding: variables.normal_gap,
  },
});
const CarRentalEngine = () => {
  const classes = CarRentalEngineStyles();
  return (
    <div className={classes.CarRentalEngineStyles}>
      <div className={classes.title}>
        <h1>Car Rental Engine</h1>
      </div>
    </div>
  );
};

export default CarRentalEngine;
