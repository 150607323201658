import { VALAQUENTA_API_URL } from "../../..";
import { authorizedAxios, loggingAxios } from "../../../../App";

export function fetchWebSitePromoLandingPages({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/promo-landing-pages-for-entity/`,
    { params }
  );
}

export function retrieveWebSitePromoLandingPages({ id }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/promo-landing-pages-for-entity/${id}/`
  );
}

export function patchWebSitePromoLandingPage({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/canaveral/promo-landing-pages-for-entity/${id}/`,
    payload
  );
}

export function createWebSitePromoLandingPage({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/canaveral/promo-landing-pages-for-entity/`,
    payload
  );
}

export function deleteWebSitePromoLandingPage({ id }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/canaveral/promo-landing-pages-for-entity/${id}/`
  );
}
