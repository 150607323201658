import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  fetchFerryReservations,
  getFerryReservationStats,
} from "../../../../api/CRS/Ferries";
import Loader from "../../../../components/Loader";
import _ from "lodash";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Navbar from "./Navbar";
import ControlStrip from "../../../../components/ControlStrip";
import { handleApplyFilters, medWeekdayDatetimer } from "../../../../helpers";
import DetailFerryModals from "../FerriesModals/DetailFerryModal";
import ReservationStats from "../../CommonCRSModals/ReservationStats/ReservationStats";
import { commonTableHeaders } from "../../helpers";
import { useQueries } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { genericRequestErrorHandler } from "../../../../api";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import { DateTime } from "luxon";
import { CustomButton } from "../../../../components/CustomButton";

const initialFilters = {
  limit: 20,
  page: 1,
  ordering: "",
  discending: "",
  company__icontains: "",
  destination__icontains: "",
  arrival__gte: "",
  departure__lte: "",
  market_type: "",
  created__gte: "",
  created__lte: "",
  external_reference__icontains: "",
  source_entity: "",
  reference: "",
  network_search: "",
  supplier_reference: "",
  status: "",
};
const navbarSchema = yup.object().shape({
  company__icontains: yup
    .string()
    .min(2, "Name must be more than 2 characters")
    .max(100, "Name: Must be less than 100 characters"),
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const ferrieStyles = createUseStyles({
  Ferries: {
    ...genericIndexesStyles.indexes.mobile(),
    gridTemplateRows: "auto auto auto  1fr",
  },
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tablecell: tableStyles.body.cell,
  actionCell: {
    ...tableStyles.body.cell,
    // display: "grid",
    gap: variables.half_gap,
  },
  dateCell: { ...tableStyles.body.cell, minWidth: "9rem" },
  checkBox: tableStyles.checkBox,
  [`@media ${variables.media.smallscreen}`]: {
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const Ferries = () => {
  const classes = ferrieStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [reference, setReference] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);

  const tableHeaders = commonTableHeaders({
    "Supplier Reference": (val) => val.supplier_reference,
    Company: (val) => val.company,
    Tags: (val) => _.get(val, "tags", []).join(","),
    Destination: (val) => val.destination,
    "Destination Port": (val) => val.destination_port,
    Adults: (val) => val.adults,
    Children: (val) => val.children || "0",
    Departure: (val) => val.departure,
    Arrival: (val) => val.arrival,
    "Payment Mode": (val) => val.payment_mode_display,
    "Ticket Class": (val) => val.ticket_class,
    "Market Type": (val) => val.market_type,
  });
  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };
  const results = useQueries({
    queries: [
      {
        queryKey: ["ferries", filters],
        queryFn: async () => {
          let correctPayload = { ...filters };
          if (filters.created__lte) {
            correctPayload["created__lte"] = DateTime.fromISO(
              filters.created__lte
            ).toISO();
          }
          if (filters.created__gte) {
            correctPayload["created__gte"] = DateTime.fromISO(
              filters.created__gte
            ).toISO();
          }
          if (filters.arrival__gte) {
            correctPayload["arrival__gte"] = DateTime.fromISO(
              filters.arrival__gte
            ).toISO();
          }
          if (filters.departure__lte) {
            correctPayload["departure__lte"] = DateTime.fromISO(
              filters.departure__lte
            ).toISO();
          }
          return await fetchFerryReservations({
            params: correctPayload,
          });
        },
        enabled: !showDetailModal,
        onError: (error) => genericRequestErrorHandler(error),
      },
      {
        queryKey: ["ferriesStats", filters],
        queryFn: async () => {
          let correctPayload = { ...filters };
          if (filters.created__lte) {
            correctPayload["created__lte"] = DateTime.fromISO(
              filters.created__lte
            ).toISO();
          }
          if (filters.created__gte) {
            correctPayload["created__gte"] = DateTime.fromISO(
              filters.created__gte
            ).toISO();
          }
          if (filters.arrival__gte) {
            correctPayload["arrival__gte"] = DateTime.fromISO(
              filters.arrival__gte
            ).toISO();
          }
          if (filters.departure__lte) {
            correctPayload["departure__lte"] = DateTime.fromISO(
              filters.departure__lte
            ).toISO();
          }
          return await getFerryReservationStats({
            params: correctPayload,
          });
        },
        enabled: !showDetailModal,
      },
    ],
  });

  return (
    <div id="FERListPage" className={classes.Ferries}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
      />
      <HamburgerMenu />
      {!_.get(results, "1.isLoading", true) &&
        _.get(results, "1.data", null) && (
          <ReservationStats
            title="Reservation Stats"
            stats={_.get(results, "1.data.data.stats_per_currency")}
          />
        )}
      <ControlStrip
        limit={_.get(filters, "limit")}
        page={filters.page}
        count={_.get(results, "0.data.data.count")}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {_.get(results, "0.isLoading", false) ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                {Object.keys(tableHeaders).map((value, idx) => (
                  <th className={classes.headCell} key={idx}>
                    {value}
                  </th>
                ))}
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.get(results, "0.data.data.results", []).map((val, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.tablecell}>{idx + 1}</td>
                  {Object.entries(tableHeaders).map((entry, ridx) => {
                    var cell = null;
                    const fn = entry[1];
                    switch (entry[0].toLowerCase()) {
                      case "arrival":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.dateCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      case "departure":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.dateCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      case "created":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.dateCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      default:
                        cell = fn(val) || "N/A";
                        break;
                    }
                    return (
                      <td className={classes.tablecell} key={ridx}>
                        {cell}
                      </td>
                    );
                  })}
                  <td className={classes.actionCell}>
                    <CustomButton
                      id={`detailFERButton_${idx}`}
                      appearance="ghost"
                      onClick={() => {
                        setReference(val.reference);
                        setShowDetailModal(true);
                      }}>
                      <strong>Details</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showDetailModal && (
        <DetailFerryModals
          onClose={() => setShowDetailModal(false)}
          reference={reference}
        />
      )}
    </div>
  );
};

export default Ferries;
