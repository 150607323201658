import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  cardStyles,
  tableStyles,
} from "../../../../../variables";
import PropTypes from "prop-types";
import Loader from "../../../../../components/Loader";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { fetchPaymentTerms } from "../../../../../api/Vendor/PaymentsTerms";
import EditPaymentTerms from "./EditPaymentTerms";
import DeletePaymentTerms from "./DeletePaymentTerms";
import { genericRequestErrorHandler } from "../../../../../api";
import { CustomButton } from "../../../../../components/CustomButton";

const travelServiceMapping = {
  "": "-----",
  FL: "Flight",
  ACC: "Accommodation",
  TRF: "Transfers",
  MI: "Addon/Tour/Static Package",
  COA: "Coach",
  TRA: "Train",
  FER: "Ferries",
  GEN: "General Services",
  REST: "Restaurant",
  ACT: "Activities",
};
const paymentTimeTypeMapping = {
  "": "-----",
  PRE: "Pre-payment",
  CXL: "On Cancellation Policy",
  EXC: "On Service Execution",
};

const paymentMethod = {
  "": "-----",
  BT: "Bank Transfer",
  CC: "Credit Card",
};

const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
};

const styles = createUseStyles({
  ...cardStyles,
  ListPaymentTerms: cardStyles.card,
  headCell: { ...tableStyles.head.cell, fontWeight: "bold" },
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
});

const ListPaymentTerms = ({ vendorId }) => {
  const classes = styles();
  const [entity, setEntity] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const queryKey = "PaymentTerms";

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: [queryKey, vendorId],
    queryFn: () =>
      fetchPaymentTerms({
        params: { vendor: vendorId, page: 1, limit: 50 },
      }),
    onError: (error) => genericRequestErrorHandler(error),
    enabled: !showEditModal && !showDeleteModal,
  });

  return (
    <div id="PaymentTermsCard" className={classes.ListPaymentTerms}>
      <React.Fragment>
        {!showEditModal && !showDeleteModal && (
          <React.Fragment>
            {isLoading ? (
              <Loader />
            ) : (
              <React.Fragment>
                <div className={classes.header}>
                  <span>Payment Terms</span>
                  <CustomButton
                    id="addPaymentTermsButton"
                    appearance="ghost"
                    onClick={() => {
                      setShowEditModal(true);
                      setEntity(null);
                    }}>
                    <strong>Add</strong>
                  </CustomButton>
                </div>
                <div className={classes.body}>
                  <table>
                    <thead>
                      <tr className={classes.headRow}>
                        <th className={classes.headCell}>#</th>
                        <td className={classes.headCell}>Service Type</td>
                        <td className={classes.headCell}>Type</td>
                        <td className={classes.headCell}>Time Buffer</td>
                        <td className={classes.headCell}>Payment Method</td>
                        <td className={classes.headCell}>Currency</td>
                        <td className={classes.headCell}>Status</td>
                        <td className={classes.headCell}>Actions</td>
                      </tr>
                    </thead>
                    <tbody>
                      {_.get(data, "data.results", []).map((term, idx) => (
                        <tr key={idx}>
                          <td className={classes.tableCell}>{idx + 1}</td>
                          <td className={classes.tableCell}>
                            {_.get(
                              travelServiceMapping,
                              _.get(term, "service_type")
                            )}
                          </td>
                          <td className={classes.tableCell}>
                            {_.get(
                              paymentTimeTypeMapping,
                              _.get(term, "payment_time_type")
                            )}
                          </td>
                          <td className={classes.tableCell}>
                            {term.payment_time_buffer}
                          </td>
                          <td className={classes.tableCell}>
                            {_.get(
                              paymentMethod,
                              _.get(term, "payment_method")
                            )}
                          </td>
                          <td className={classes.tableCell}>{term.currency}</td>
                          <td className={classes.tableCell}>
                            {_.get(statusMapping, _.get(term, "status"))}
                          </td>
                          <td className={classes.actionCell}>
                            <CustomButton
                              id={`deletePaymentTermsButton_${idx}`}
                              appearance="ghost"
                              onClick={() => {
                                setEntity(term);
                                setShowDeleteModal(true);
                              }}>
                              <strong>Delete</strong>
                            </CustomButton>
                            <CustomButton
                              id={`editPaymentTermsButton_${idx}`}
                              appearance="ghost"
                              onClick={() => {
                                setEntity(term);
                                setShowEditModal(true);
                              }}>
                              <strong>Edit</strong>
                            </CustomButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
      {showEditModal && (
        <EditPaymentTerms
          id={_.get(entity, "id")}
          vendorId={vendorId}
          onClose={() => {
            setShowEditModal(false);
            setEntity(null);
            queryClient.invalidateQueries(`${queryKey}`);
          }}
        />
      )}
      {showDeleteModal && (
        <DeletePaymentTerms
          entity={entity}
          onClose={() => {
            setShowDeleteModal(false);
            setEntity(null);
            queryClient.invalidateQueries(`${queryKey}`);
          }}
        />
      )}
    </div>
  );
};

ListPaymentTerms.propTypes = {
  vendorId: PropTypes.number.isRequired,
};
export default ListPaymentTerms;
