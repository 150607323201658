import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export async function fetchMembers({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/members/`, {
    params,
  });
}

export async function retrieveMember({ id }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/members/${id}/`);
}

export function patchMember({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crm/members/${id}/`,
    payload
  );
}

export function createMember({ payload }) {
  return loggingAxios.post(`${VALAQUENTA_API_URL}/crm/members/`, payload);
}

export function memberInvoice({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/crm/members/invoice/`,
    payload
  );
}

export function getCRMComments({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/reviewer/entity/review/`, {
    params,
  });
}

export function retrieveCRMComments({ id }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/reviewer/entity/review/${id}/`
  );
}
export function patchCRMComments({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/reviewer/entity/review/${id}/`,
    payload
  );
}

export function createCRMComments({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/reviewer/entity/review/`,
    payload
  );
}

// export function getMemberDepositStatments({ params }) {
//   return authorizedAxios.get(
//     `${VALAQUENTA_API_URL}/paymentengine/accounts/member-deposit-ledger/`,
//     {
//       params,
//     }
//   );
// }

export function getRefactoredMemberDepositStatments({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/paymentengine/deposit-ledger-entry/`,
    {
      params,
    }
  );
}

export function getMemberPoformaStatment({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/paymentengine/proforma-ledger-entry/`,
    {
      params,
    }
  );
}

export function createMemberPoformaStatment({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/paymentengine/proforma-ledger-entry/`,
    payload
  );
}
