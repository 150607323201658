import _ from "lodash";
import React, { createContext, useEffect, useState } from "react";
import Sidebar from "./components/Sidebar";
import { createUseStyles } from "react-jss";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CMS from "./pages/CMS";
import AccEngine from "./pages/AccEngine";
import CRS from "./pages/CRS";
import FlightEngine from "./pages/FlightEngine";
import CRM from "./pages/CRM/index";
import Accommodator from "./pages/Accomodator";
import Mapper from "./pages/Accomodator/Mapper/List";
import Hotels from "./pages/Accomodator/Hotel/Hotel";
import Images from "./pages/Accomodator/Images/Images";
import { variables } from "./variables";
import { useAuthentication } from "./hooks";
import { registerToNotifications } from "./service_worker";
import Members from "./pages/CRM/Members/List";
import SubAgents from "./pages/CRM/SubAgents/List";
import Flights from "./pages/CRS/Flights/List";
import Trains from "./pages/CRS/Trains/List";
import Ferries from "./pages/CRS/Ferries/List";
import Coach from "./pages/CRS/Coach/List";
import Transfers from "./pages/CRS/Tranfers/List";
import Accommodations from "./pages/CRS/Accommodations/List";
import Addons from "./pages/CRS/Addons/List";
import Activities from "./pages/CRS/Activities/List";
import Branches from "./pages/CRM/Branches/List";
import GeneralServices from "./pages/CRS/GeneralServise/List";
import Restaurants from "./pages/CRS/Restaurants/List";
import Adhocs from "./pages/CRS/Adhocs/List";
import Coordinators from "./pages/CRS/Coordinators/List";
import PoliciesB2B from "./pages/CRM/Policies/B2B/List/index";
import PoliciesB2C from "./pages/CRM/Policies/B2C/List/";
import Users from "./pages/CRM/Users/List/index.js";
import Accommodation from "./pages/CRM/Vendor/Accommodations/List";
import Flight from "./pages/CRM/Vendor/Flights/List";
import Transfer from "./pages/CRM/Vendor/Transfers/List";
import Destinations from "./pages/CMS/Destinations";
import Airports from "./pages/FlightEngine/Airports/List";
import ActivitiesEngine from "./pages/ActivitiesEngine";
import ActivitiesAPIConfigurations from "./pages/ActivitiesEngine/activitiesAPIConfigurations";
import TransfersEngine from "./pages/TransfersEngine";
import TransfersAPIConfigurations from "./pages/TransfersEngine/List";
import FlightsAPIConfigurations from "./pages/FlightEngine/FlightAPIConfigurations/List";
import DistributionSite from "./pages/DistributionSites";
import WebSites from "./pages/DistributionSites/List";
import CarRentalEngine from "./pages/CarRentalEngine";
import ApiConfigurationCarRental from "./pages/CarRentalEngine/CarRentalApiConfiguration";
import HamburgerMenu from "./components/HamburgerMenu";
import EmailConfiguration from "./pages/CRS/EmailConfiguration/List";
import GuestCRM from "./pages/CRM/GuestCRM/List";
import VesselEngineAPIConfigurations from "./pages/VesselEngine/List";
import VesselEngine from "./pages/VesselEngine";
import MasterPolicies from "./pages/CRM/Policies/MasterPolicies/List";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ApiFerries from "./pages/CRS/ApiFerries/List";
import ApiTrains from "./pages/CRS/ApiTrains/List";
import CarRentals from "./pages/CRS/CarRentals/List";
import AviateApiConfiguration from "./pages/FlightEngine/AviateApiConfiguration/List";
import UnififiApiConfiguration from "./pages/FlightEngine/UnififiApiConfiguration/List";
import ActivitiesVendors from "./pages/CRM/Vendor/Activities/List";
import FerriesVendors from "./pages/CRM/Vendor/Ferries/List";
import AccEngineAPIConfigurations from "./pages/AccEngine/AccEngineAPIConfigurations/index.js";
import VendorsTrains from "./pages/CRM/Vendor/VendorsTrains/List";
import CarRentalsVendors from "./pages/CRM/Vendor/CarRentalVendors/List/index";
import RailEuropeEngine from "./pages/RailEurope";
import RailEuropeApiConfig from "./pages/RailEurope/List";
import Monitoring from "./pages/Monitoring";
import Scenarios from "./pages/Monitoring/Scenarios/List";
import BackUpStatus from "./pages/Monitoring/BackUpStatus/List";
import Live from "./pages/Monitoring/Live/List";
import LoggingAxios from "./api/LoggingAxios.js";
import UserInfoCard from "./components/cards/userInfoCard/userInfoCard.js";
import AuthorizedAxios from "./api/AuthorizeAxios.js";

export const GlobalModalContext = createContext();

export function links() {
  return [
    {
      title: "Acc Engine",
      id: "AccEngine",
      path: "/accEngine",
      element: <AccEngine />,
      inMenu: true,
      links: [
        {
          title: "API Configurations",
          id: "accEngineAPIConfigurations",
          path: "/accEngineAPIConfigurations",
          element: <AccEngineAPIConfigurations />,
          inMenu: true,
        },
      ],
    },
    {
      title: "Accommodator",
      id: "accommodator",
      path: "/accommodator",
      element: <Accommodator />,
      inMenu: true,
      links: [
        {
          title: "Hotels",
          id: "hotels",
          path: "/hotels",
          element: <Hotels />,
          inMenu: true,
        },
        {
          title: "Hotel Images",
          id: "hotel_images",
          path: "/hotels/images/:pk",
          element: <Images />,
          inMenu: false,
        },
        {
          title: "Mapper",
          id: "mapper",
          path: "/mapper",
          element: <Mapper />,
          inMenu: true,
        },
      ],
    },
    {
      title: "Car Rental Engine",
      id: "carRentalEngine",
      path: "/carRentalEngine",
      element: <CarRentalEngine />,
      inMenu: true,
      links: [
        {
          title: "Api Configuration",
          id: "apiConfiguration",
          path: "/apiConfiguration",
          element: <ApiConfigurationCarRental />,
          inMenu: true,
        },
      ],
    },
    {
      title: "Flight Engine",
      id: "FlightEngine",
      path: "/FlightEngine",
      element: <FlightEngine />,
      inMenu: true,
      links: [
        {
          title: "Airports",
          id: "airports",
          path: "/airports",
          element: <Airports />,
          inMenu: true,
        },
        {
          title: "Aviate API Configurations",
          id: "aviateAPIConfigurations",
          path: "/aviateAPIConfigurations",
          element: <AviateApiConfiguration />,
          inMenu: true,
        },
        {
          title: "Unififi API Configurations",
          id: "unififiAPIConfigurations",
          path: "/unififiAPIConfigurations",
          element: <UnififiApiConfiguration />,
          inMenu: true,
        },
        {
          title: "Hitchhiker API Configurations",
          id: "flightsAPIConfigurations",
          path: "/flightsAPIConfigurations",
          element: <FlightsAPIConfigurations />,
          inMenu: true,
        },
      ],
    },
    {
      title: "Activities Engine",
      id: "ActivitiesEngine",
      path: "/ActivitiesEngine",
      element: <ActivitiesEngine />,
      inMenu: true,
      links: [
        {
          title: "API Configurations",
          id: "activitiesAPIConfigurations",
          path: "/activitiesAPIConfigurations",
          element: <ActivitiesAPIConfigurations />,
          inMenu: true,
        },
      ],
    },
    {
      title: "Monitoring",
      id: "monitoring",
      path: "/monitoring",
      element: <Monitoring />,
      inMenu: true,
      links: [
        {
          title: "Back Up Status",
          id: "backUpStatus",
          path: "/backUpStatus",
          element: <BackUpStatus />,
          inMenu: true,
        },
        {
          title: "Scenarios",
          id: "scenarios",
          path: "/scenarios",
          element: <Scenarios />,
          inMenu: true,
        },
        {
          title: "Live",
          id: "live",
          path: "/live",
          element: <Live />,
          inMenu: true,
        },
      ],
    },
    {
      title: "Rail Europe Engine",
      id: "RailEurope",
      path: "/RailEurope",
      element: <RailEuropeEngine />,
      inMenu: true,
      links: [
        {
          title: "API Configurations",
          id: "RailEuropeAPIConfigurations",
          path: "/RailEuropeAPIConfigurations",
          element: <RailEuropeApiConfig />,
          inMenu: true,
        },
      ],
    },
    {
      title: "Transfers Engine",
      id: "TransfersEngine",
      path: "/TransfersEngine",
      element: <TransfersEngine />,
      inMenu: true,
      links: [
        {
          title: "API Configurations",
          id: "transfersAPIConfigurations",
          path: "/transfersAPIConfigurations",
          element: <TransfersAPIConfigurations />,
          inMenu: true,
        },
      ],
    },
    {
      title: "Vessel Engine",
      id: "veselEngine",
      path: "/veselEngine",
      element: <VesselEngine />,
      inMenu: true,
      links: [
        {
          title: "Api Configuration",
          id: "apiConfiguration",
          path: "/apiConfiguration",
          element: <VesselEngineAPIConfigurations />,
          inMenu: true,
        },
      ],
    },
    {
      title: "CMS",
      id: "cms",
      path: "/cms",
      element: <CMS />,
      inMenu: true,
      links: [
        {
          title: "Destinations",
          id: "destinations",
          path: "/destinations",
          element: <Destinations />,
          inMenu: true,
        },
      ],
    },
    {
      title: "AccEngine",
      id: "AccEngine",
      path: "/AccEngine",
      element: <AccEngine />,
    },
    {
      title: "CRM",
      id: "crm",
      path: "/crm",
      element: <CRM />,
      inMenu: true,
      links: [
        {
          title: "Members",
          id: "members",
          path: "/members",
          element: <Members />,
          inMenu: true,
        },
        {
          title: "Branches",
          id: "branches",
          path: "/branches",
          element: <Branches />,
          inMenu: true,
        },
        {
          title: "Sub-Agents",
          id: "subAgents",
          path: "/subAgents",
          element: <SubAgents />,
          inMenu: true,
        },
        {
          title: "Guest CRM",
          id: "guestCRM",
          path: "/guestCRM",
          element: <GuestCRM />,
          inMenu: true,
        },
        {
          title: "Users",
          id: "users",
          path: "/users",
          element: <Users />,
          inMenu: true,
        },
        {
          title: "Master Policies",
          id: "masterPolicies",
          path: "/masterPolicies",
          element: <MasterPolicies />,
          inMenu: true,
        },
        {
          title: "Policies B2B",
          id: "policiesB2B",
          path: "/policiesB2B",
          element: <PoliciesB2B />,
          inMenu: true,
        },
        {
          title: "Policies B2C",
          id: "policiesB2C",
          path: "/policiesB2C",
          element: <PoliciesB2C />,
          inMenu: true,
        },
        {
          title: "Vendors - Accommodations",
          id: "accommodation",
          path: "/accommodation",
          element: <Accommodation />,
          inMenu: true,
        },
        {
          title: "Vendors - Activities",
          id: "activitiesVendors",
          path: "/activitiesVendors",
          element: <ActivitiesVendors />,
          inMenu: true,
        },
        {
          title: "Vendors - Car Rentals",
          id: "carRentalsVendors",
          path: "/carRentalsVendors",
          element: <CarRentalsVendors />,
          inMenu: true,
        },
        {
          title: "Vendors - Ferries",
          id: "ferriesVendors",
          path: "/ferriesVendors",
          element: <FerriesVendors />,
          inMenu: true,
        },
        {
          title: "Vendors - Flights",
          id: "Vendor Flight",
          path: "/flight",
          element: <Flight />,
          inMenu: true,
        },
        {
          title: "Vendors - Trains",
          id: "trains",
          path: "/trains",
          element: <VendorsTrains />,
          inMenu: true,
        },
        {
          title: "Vendors - Transfers",
          id: "transfer",
          path: "/transfer",
          element: <Transfer />,
          inMenu: true,
        },
      ],
    },
    {
      title: "CRS",
      id: "crs",
      path: "/crs",
      element: <CRS />,
      inMenu: true,
      links: [
        {
          title: "Accommodations",
          id: "accommodations",
          path: "/accommodations",
          element: <Accommodations />,
          inMenu: true,
        },
        {
          title: "Activities",
          id: "activities",
          path: "/activities",
          element: <Activities />,
          inMenu: true,
        },
        {
          title: "Addons",
          id: "addons",
          path: "/addons",
          element: <Addons />,
          inMenu: true,
        },
        {
          title: "Adhocs",
          id: "adhocks",
          path: "/addhocks",
          element: <Adhocs />,
          inMenu: true,
        },
        {
          title: "Car Rentals",
          id: "carRental",
          path: "/carRental",
          element: <CarRentals />,
          inMenu: true,
        },
        {
          title: "Coach",
          id: "coach,",
          path: "/coach",
          element: <Coach />,
          inMenu: true,
        },
        {
          title: "Coordinators",
          id: "coordinatorsch,",
          path: "/coordinators",
          element: <Coordinators />,
          inMenu: true,
        },
        {
          title: "Ferries",
          id: "ferries",
          path: "/feries",
          element: <Ferries />,
          inMenu: true,
        },
        {
          title: "Api Ferries",
          id: "apiFerries",
          path: "/apiFerries",
          element: <ApiFerries />,
          inMenu: true,
        },
        {
          title: "Api Trains",
          id: "apiTrains",
          path: "/apiTrains",
          element: <ApiTrains />,
          inMenu: true,
        },
        {
          title: "Email Configuration",
          id: "emailConfiguration",
          path: "/emailConfiguration",
          element: <EmailConfiguration />,
          inMenu: true,
        },
        {
          title: "Flights",
          id: "flights",
          path: "/flights",
          element: <Flights />,
          inMenu: true,
        },
        {
          title: "General Services",
          id: "generalServices",
          path: "/generalServices",
          element: <GeneralServices />,
          inMenu: true,
        },
        {
          title: "Restaurants",
          id: "restaurants",
          path: "/restaurants",
          element: <Restaurants />,
          inMenu: true,
        },
        {
          title: "Trains",
          id: "trains",
          path: "/trains",
          element: <Trains />,
          inMenu: true,
        },
        {
          title: "Transfers",
          id: "transfers",
          path: "/transfers",
          element: <Transfers />,
          inMenu: true,
        },
      ],
    },
    {
      title: "FlightEngine",
      id: "flightEngine",
      path: "/flightEngine",
      element: <FlightEngine />,
    },
    {
      title: "B2C Distribution Network",
      id: "distributionSites",
      path: "/distributionSites",
      element: <DistributionSite />,
      inMenu: true,
      links: [
        {
          title: "Websites",
          id: "webSites",
          path: "/webSites",
          element: <WebSites />,
          inMenu: true,
        },
      ],
    },
    {
      title: "User Information",
      id: "userInfo",
      path: "/userInfo",
      element: <UserInfoCard />,
      inMenu: false,
    },
  ];
}

export const loggingAxios = new LoggingAxios();
export const authorizedAxios = new AuthorizedAxios();

const appStyles = createUseStyles({
  App: (props) => ({
    display: "grid",
    // gap: `calc(${variables.normal_gap} / 3)`,
    gridTemplateColumns: "1fr",
    overflow: "hidden",
    "& .Toastify": {
      zIndex: 9999,
    },
  }),
  mainContainer: () => ({
    display: "grid",
    overflow: "auto",
  }),
  sidebarDesktop: { display: "none" },
  [`@media ${variables.media.smallscreen}`]: {
    App: {
      display: "grid",
      gridTemplateColumns: ({ openSidebar }) =>
        openSidebar ? "max-content 1fr" : "max-content 1fr",
      height: "100vh",
      overflow: "hidden",
      "& .Toastify": {
        zIndex: 9999,
      },
    },
    sidebarMobile: {
      display: "none",
    },
    sidebarDesktop: { display: "block" },
  },
});

const queryClient = new QueryClient();
const App = () => {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(true);

  const classes = appStyles({ openSidebar: openSidebar });

  const { user, isAuthenticated, getJWT } = useAuthentication();

  getJWT().then((jwt) => {
    if (jwt) {
      loggingAxios.setJWT(jwt);
      loggingAxios.setLoggingRequestInterceptor();
      authorizedAxios.setJWT(jwt);
      authorizedAxios.setLoggingRequestInterceptor();
    }
  });

  loggingAxios.setCreator(user?.sub);
  loggingAxios.setDescriptor(window.location.href);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    registerToNotifications({ user_id: user.sub, user_email: user.email });
  }, [isAuthenticated]);

  return !isAuthenticated ? null : (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <GlobalModalContext.Provider
          value={{
            setShowBurgerMenu,
            setOpenSidebar,
            openSidebar,
          }}>
          <div className={classes.App}>
            {showBurgerMenu && (
              <div className={classes.sidebarMobile}>
                <Sidebar />
              </div>
            )}
            <div className={classes.sidebarDesktop}>
              <Sidebar />
            </div>
            <div className={classes.mainContainer}>
              <HamburgerMenu />
              <Routes>
                {links().map((link, idx) => {
                  const subLinks = _.get(link, "links", []);
                  const hasSubLinks = subLinks.length > 0;

                  return (
                    <React.Fragment key={idx}>
                      <Route
                        key={idx}
                        path={link.path}
                        element={link.element}
                      />
                      {hasSubLinks &&
                        subLinks.map((subLink, sidx) => (
                          <React.Fragment key={sidx}>
                            <Route
                              key={sidx}
                              path={`${link.path}${subLink.path}`}
                              element={subLink.element}
                            />
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  );
                })}
              </Routes>
            </div>
          </div>
          <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
        </GlobalModalContext.Provider>
        <ToastContainer />
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
