import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  cardStyles,
  tableStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import _ from "lodash";
import { medWeekdayDatetimer } from "../../../../../helpers";
import { Button } from "../../../../../components/Button";

const getServiceTypes = (
  flights,
  accommodation,
  addons,
  transfers,
  restaurants,
  activities
) => {
  let result = [];
  if (flights) {
    result.push("Flights");
  }
  if (accommodation) {
    result.push("Accommodations");
  }
  if (addons) {
    result.push("Addons");
  }
  if (transfers) {
    result.push("Transfers");
  }
  if (restaurants) {
    result.push("Restaurants");
  }
  if (activities) {
    result.push("Activities");
  }
  return result.join(", ");
};

/**
 * @param {Array} websites
 * @param {Array} emailConfigs
 * @returns {Object} warningsMapping
 * @description
 * 1. Check if there is no Cloudfront
 * 2. Check if there is no S3 Bucket
 * 3. Check if there is no SSL Certificate
 * 4. Check if there is no email configurations
 * 5. Check if there is no VO email configurations
 * 6. Check if there is no CA email configurations
 * 7. Check if a private website has no API configs
 * Return warningsMapping
 * @example
 * {
 * "websiteName": ["warning1", "warning2"]
 * }
 */
const getWarnings = (websites, emailConfigs) => {
  let warningsMapping = {};
  websites.forEach((website) => {
    let warnings = [];
    const website_api_key = _.get(website, "api_key");
    if (!website.has_cloudfront) {
      warnings.push("There is no Cloudfront");
    }
    if (!website.has_s3_bucket) {
      warnings.push("There is no S3 Bucket");
    }

    if (!_.get(website, "ssls.has_ssl")) {
      warnings.push("There is no SSL Certificate");
    }
    if (
      _.get(website, "ssls.has_ssl") &&
      _.get(website, "ssls.ssl_status") !== "SUCCESS"
    ) {
      warnings.push("SSL Certificate is not valid");
    }
    if (!_.get(website, "ssls.has_set_ssls_field")) {
      warnings.push("The website has not set the 'ssls' field");
    }

    if (website.private_website) {
      const website_api_configs = _.get(website, "auth_api_configs", []);
      if (website_api_configs.length === 0) {
        warnings.push("There are no API configurations");
      }
    }
    const website_email_configs = emailConfigs.filter(
      (emailConfig) => emailConfig.website_api_key === website_api_key
    );
    if (website_email_configs.length === 0) {
      warnings.push("There are no email configurations");
    } else {
      const VO_configs = website_email_configs.filter(
        (emailConfig) => emailConfig.email_type === "VO"
      );
      const CA_configs = website_email_configs.filter(
        (emailConfig) => emailConfig.email_type === "CA"
      );
      if (VO_configs.length === 0) {
        warnings.push("There is no VO email configurations");
      }
      if (CA_configs.length === 0) {
        warnings.push("There is no CA email configurations");
      }
    }
    if (warnings.length > 0) {
      warningsMapping[website.name] = warnings;
    }
  });
  return warningsMapping;
};

const styles = createUseStyles({
  ...cardStyles,
  WebsitesSection: { ...cardStyles.card },
  header: {
    ...cardStyles.header,
    padding: variables.normal_gap,
    fontSize: "1.2rem",
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  tableContainer: {
    display: "block",
    maxHeight: "20rem",
    overflow: "auto",
    width: "100%",
  },
  table: {
    width: "100%",
  },
  thead: {
    position: "sticky",
    top: 0,
  },
  emptyWebsites: {
    color: "red",
    textAlign: "center",
    minHeight: "10rem",
    lineHeight: "10rem",
  },
  red: { color: "red" },
  actionCell: genericIndexesStyles.actionCell.mobile,
});
const WebsitesSection = ({ websites, emailConfigs }) => {
  const classes = styles();
  const [showWarnings, setShowWarnings] = useState(false);

  const warnings = getWarnings(websites, emailConfigs);
  const thereAreWarnings = !_.isEmpty(warnings);

  return (
    <div className={classes.WebsitesSection}>
      <div className={classes.header}>
        <span>
          Websites{" "}
          {thereAreWarnings && (
            <span className={classes.red}>{"(There are warnings)"}</span>
          )}
        </span>
        {thereAreWarnings && (
          <Button text="Show Warnings" onClick={() => setShowWarnings(true)} />
        )}
      </div>
      <div className={classes.tableContainer}>
        {websites.length === 0 ? (
          <div className={classes.emptyWebsites}>
            <strong>No Websites</strong>
          </div>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Name</th>
                <th className={classes.headCell}>Is Private</th>
                <th className={classes.headCell}>Has Cloudfront</th>
                <th className={classes.headCell}>Has S3 Bucket</th>
                <th className={classes.headCell}>Has SSL Certificate</th>
                <th className={classes.headCell}>SSL Status</th>
                <th className={classes.headCell}>Service Types</th>
                <th className={classes.headCell}>Payment Supplier</th>
                <th className={classes.headCell}>Status</th>
                <th className={classes.headCell}>Created</th>
                <th className={classes.headCell}>Go to</th>
              </tr>
            </thead>
            <tbody>
              {websites.map((website, idx) => (
                <tr key={website.id}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  <td className={classes.tableCell}>{website.name || "N/A"}</td>
                  <td className={classes.tableCell}>
                    {website.private_website ? (
                      "Yes"
                    ) : (
                      <span className={classes.red}>{"No"}</span>
                    )}
                  </td>
                  <td className={classes.tableCell}>
                    {website.has_cloudfront ? (
                      "Yes"
                    ) : (
                      <span className={classes.red}>{"No"}</span>
                    )}
                  </td>
                  <td className={classes.tableCell}>
                    {website.has_s3_bucket ? (
                      "Yes"
                    ) : (
                      <span className={classes.red}>{"No"}</span>
                    )}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(website, "ssls.has_ssl") ? (
                      "Yes"
                    ) : (
                      <span className={classes.red}>{"No"}</span>
                    )}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(website, "ssls.ssl_status") === "SUCCESS" ? (
                      _.get(website, "ssls.ssl_status_display")
                    ) : (
                      <span className={classes.red}>
                        {_.get(website, "ssls.ssl_status_display")}
                      </span>
                    )}
                  </td>

                  <td className={classes.tableCell}>
                    {getServiceTypes(
                      website.flights,
                      website.accommodation,
                      website.addons,
                      website.transfers,
                      website.restaurants,
                      website.activities
                    ) || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {website.payment_supplier_display || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {website.status_display || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(website, "created")
                      ? medWeekdayDatetimer(_.get(website, "created"))
                      : "N/A"}
                  </td>
                  <td className={classes.actionCell}>
                    {website.production_url && (
                      <Button
                        text="Production"
                        onClick={() => {
                          window.open(website.production_url, "_blank");
                        }}
                      />
                    )}
                    {website.staging_url && (
                      <Button
                        text="Staging"
                        onClick={() => {
                          window.open(website.staging_url, "_blank");
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {showWarnings && (
        <WarningsModal
          warnings={warnings}
          onClose={() => setShowWarnings(false)}
        />
      )}
    </div>
  );
};
WebsitesSection.propTypes = {
  websites: PropTypes.array.isRequired,
  emailConfigs: PropTypes.array.isRequired,
};
export default WebsitesSection;

const warningModalStyles = createUseStyles({
  ...modalGenericStyles,
  WarningsModal: {
    ...modalGenericStyles.modal,
  },
  mainCard: {
    ...modalGenericStyles.card,
    width: "50rem",
  },
  mainCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  mainCardBody: {
    ...modalGenericStyles.card,
    display: "grid",
    maxHeight: "30rem",
    gap: variables.normal_gap,
    overflow: "auto",
    justifyItems: "center",
  },
  card: {
    ...cardStyles.card,
    width: "80%",
  },
  cardBody: {
    ...cardStyles.body,
  },
  cardHeader: {
    ...cardStyles.header,
  },
});
const WarningsModal = ({ warnings, onClose }) => {
  const classes = warningModalStyles();
  return (
    <div className={classes.WarningsModal}>
      <div className={classes.mainCard}>
        <div className={classes.mainCardHeader}>
          <span>WARNINGS</span>
        </div>
        <div className={classes.mainCardBody}>
          {Object.keys(warnings).map((websiteName, idx) => (
            <div key={idx} className={classes.card}>
              <div className={classes.cardHeader}>
                <strong>{websiteName}</strong>
              </div>
              <div className={classes.cardBody}>
                <ul>
                  {warnings[websiteName].map((warning, idx) => (
                    <li key={`${idx}_${websiteName}`}>{warning}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.cardActions}>
          <Button text="Close" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};
WarningsModal.propTypes = {
  warnings: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
