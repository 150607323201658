import React from "react";
import { createUseStyles } from "react-jss";
import { modalGenericStyles, variables } from "../../../../../variables";
import PropTypes from "prop-types";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { genericRequestErrorHandler } from "../../../../../api";
import { patchMappingPrimaryHotels } from "../../../../../api/accommodator/Mapper";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  RemoveMappingModal: {
    ...modalGenericStyles.modal,
  },
  cardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    alignContent: "center",
  },
  [`@media ${variables.media.smallscreen}`]: {
    card: {
      ...modalGenericStyles.card,
      minWidth: "40rem",
      minHeight: "5rem",
    },
    cardBody: {
      minWidth: "5rem",
      minHeight: "5rem",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    card: {
      minWidth: "40rem",
      minHeight: "1rem",
    },
  },
});

const RemoveMappingModal = ({ pk, onClose }) => {
  const classes = styles();

  const mutation = useMutation({
    mutationFn: async ({ hotelPk, payload }) => {
      await patchMappingPrimaryHotels({
        hotelPk,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("Hotel unmapped");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });
  return (
    <div className={classes.RemoveMappingModal}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <span>Remove Mapping</span>
        </div>
        <div className={classes.cardBody}>
          Are you sure you want to remove this Mapping from the hotel?
        </div>
        <div className={classes.cardActions}>
          <CustomButton appearance="ghost" onClick={() => onClose()}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            appearance="primary"
            onClick={() => {
              mutation.mutate({
                hotelPk: pk,
                payload: {
                  mapped_uid: null,
                  manually_mapped: true,
                  mapping_score: 0,
                },
              });
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
RemoveMappingModal.propTypes = {
  message: PropTypes.string.isRequired,
  pk: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default RemoveMappingModal;
