import _ from "lodash";
import React from "react";
import { createUseStyles } from "react-jss";

import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { cardStyles, variables } from "../../../../variables";

const styles = createUseStyles({
  UserPreferences: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    justifyItems: "center",
    columnGap: variables.half_gap,
  },
  section: { ...cardStyles.card, padding: variables.half_gap, width: "100%" },
  sectionHeader: cardStyles.header,
  sectionBody: {
    ...cardStyles.body,
    display: "grid",
    gridGap: variables.half_gap,
  },
  checkboxes: { padding: variables.half_gap },
  checkIcon: {
    fontSize: "medium",
    background: "green",
    borderRadius: "50%",
    padding: `calc(${variables.normal_gap} / 6)`,
    color: "white",
  },
  notCheckIcon: {
    fontSize: "medium",
    background: "red",
    borderRadius: "50%",
    padding: `calc(${variables.normal_gap} / 6)`,
    color: "white",
  },
  category: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gridGap: variables.half_gap,
    alignItems: "center",
  },
  [`@media ${variables.media.smallscreen}`]: {
    UserPreferences: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    // sectionBody: {
    //   gridTemplateRows: "repeat(2, 1fr)",
    // },
  },
});
const UserPreferences = ({ entity }) => {
  const classes = styles();

  const licenses = _.get(entity, "travel_sector", []).map((l) => l[0]);
  const expertises = _.get(entity, "expertise_type", []);

  return (
    <div className={classes.UserPreferences}>
      <div className={classes.section}>
        <div className={classes.sectionHeader}>
          <label>License</label>
        </div>
        <div className={classes.sectionBody}>
          {[
            ["D", "Domestic License "],
            ["I", "International Inbound License"],
            ["O", "International Outbound License"],
          ].map(([key, label], idx) => (
            <div key={idx} className={classes.category}>
              {licenses.includes(key) ? (
                <Icon
                  icon="material-symbols:check"
                  className={classes.checkIcon}
                />
              ) : (
                <Icon
                  icon="ic:baseline-cancel"
                  className={classes.notCheckIcon}
                />
              )}
              <label>{label}</label>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionHeader}>
          <label>Expertise</label>
        </div>
        <div className={classes.sectionBody}>
          {[
            ["FIT", "Free Idependent"],
            ["GIT", "Group Inclusive Traveler"],
            ["MICE", "Meetings Incentives Conferencing Exhibitions"],
            ["CTM", "	Corporate Travel Management"],
          ].map(([key, label], idx) => (
            <div key={idx} className={classes.category}>
              {expertises.includes(key) ? (
                <Icon
                  icon="material-symbols:check"
                  // width="20"
                  // height="20"
                  className={classes.checkIcon}
                />
              ) : (
                <Icon
                  icon="ic:baseline-cancel"
                  // width="20"
                  // height="20"
                  className={classes.notCheckIcon}
                />
              )}
              <label>{label}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
UserPreferences.propTypes = {
  entity: PropTypes.object.isRequired,
};

export default UserPreferences;
