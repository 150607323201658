import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
  cardStyles,
} from "../../../../../variables";
import _ from "lodash";
import PropTypes from "prop-types";
import { genericRequestErrorHandler } from "../../../../../api";
import { useQuery } from "@tanstack/react-query";
import { fetchPoliciesB2BGEOCountries } from "../../../../../api/CRM/PoliciesB2B/PoliciesB2B";

const styles = createUseStyles({
  ...cardStyles,
  GeoTable: {
    display: "grid",
    gridTemplateRows: " 1fr",
    overflow: "hidden",
    position: "relative",
    padding: variables.normal_gap,
    width: "100%",
  },
  header: { ...cardStyles.header },
  tableContainer: {
    ...genericIndexesStyles.tableContainer.mobile(),
    padding: variables.normal_gap,
  },
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
});

const GeoTable = ({ title, data }) => {
  const classes = styles();

  const { data: countries, isLoading } = useQuery({
    queryKey: ["GeoTableCountries"],
    queryFn: () => fetchPoliciesB2BGEOCountries(),

    onError: (error) => genericRequestErrorHandler(error),
  });

  var GeoCountries = {};
  _.get(countries, "data", []).forEach((country) => {
    GeoCountries[country.id] = country.name_en;
  });

  return (
    <div className={classes.GeoTable}>
      <div className={classes.header}>Countries</div>
      <div className={classes.tableContainer}>
        <div className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              <th className={classes.headCell}>Id</th>
              <th className={classes.headCell}>Country</th>
            </tr>
          </thead>
          <tbody>
            {_.get(data, "countries", []).map((countryId, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                <td className={classes.tableCell}>{countryId || "N/A"}</td>
                <td className={classes.tableCell}>
                  {_.capitalize(_.get(GeoCountries, countryId)) || "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </div>
      </div>
    </div>
  );
};
GeoTable.propTypes = {
  data: PropTypes.object.isRequired,
};
export default GeoTable;
