import React from "react";
import { createUseStyles } from "react-jss";
import { variables } from "../../variables";
import ManagingMultipleCharts from "./CommonCRSModals/EChart/ManagingMultipleCharts";

const crsStyles = createUseStyles({
  CRS: {
    display: "grid",
    gridTemplateRows: "8rem 1fr",
    gridTemplateColumns: "1fr",
    gap: variables.normal_gap,
    padding: [0, variables.double_gap],
    placeContent: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  title: {
    display: "grid",
    textAlign: "center",
    height: "100%",
    alignItems: "start",
    padding: variables.normal_gap,
  },
  chart: { diplay: "grid", alignSelf: "center" },
  [`@media ${variables.media.smallscreen}`]: {},
});
const CRS = () => {
  const classes = crsStyles();
  return (
    <div className={classes.CRS}>
      <div className={classes.title}>
        <h1>CRS</h1>
      </div>
      <div className={classes.chart}>
        <ManagingMultipleCharts />
      </div>
    </div>
  );
};

export default CRS;
