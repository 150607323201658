import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { variables } from "../variables";
import _ from "lodash";
import { fetchWebSiteHotelPriorityAutocomplete } from "../api/DistributionSite/webSites/HotelPriorities";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../api";

const styles = createUseStyles({
  HotelsAutocomplete: {
    display: "grid",
    position: "relative",
  },
  suggestions: {
    display: "grid",
    position: "absolute",
    overflow: "auto",
    maxHeight: "25vh",
    justifyContent: "center",
    cursor: "pointer",
    border: "1px solid black",
  },
  suggestion: {
    cursor: "pointer",
    border: "1px solid rgba(0,0,0,0.5)",
    background: "white",
    padding: variables.half_gap,
    textAlign: "left",
    "&:hover": {
      backgroundColor: "grey",
      color: "white",
    },
  },
});
const HotelsAutocomplete = ({
  value,
  setValue,
  autocompleteData,
  setAutocompleteData,
}) => {
  const classes = styles();

  const defaultSuggestions = {
    hotelsNames: [],
  };
  const [suggestions, setSuggestions] = useState(defaultSuggestions);

  const { data: hotelNames } = useQuery({
    queryKey: ["HotelsAutocomplete", value],
    queryFn: () =>
      fetchWebSiteHotelPriorityAutocomplete({
        params: { name: value },
      }),
    onError: (error) => genericRequestErrorHandler(error),
    enabled: value.length > 2 && !value.includes("___"),
    refetchOnWindowFocus: false,
  });

  const onChangeHandler = async (text) => {
    setValue(text);

    if (text.length === 0) {
      setValue("");
      setAutocompleteData({});
      setSuggestions(defaultSuggestions);
    }
  };

  const onSuggestSelect = (hotel) => {
    setValue(`${hotel.name}___${hotel.uid}`);
    setAutocompleteData({
      name: _.get(hotel, "name", "") || "N/A",
      rating: hotel.rating === 0 ? "0" : _.get(hotel, "rating", "") || "N/A",
      country_name: _.get(hotel, "country_name", "") || "N/A",
      destination:
        _.get(hotel, "destination_meta.geodata.name_en", "") || "N/A",
      address: _.get(hotel, "address", "") || "N/A",
    });
    setSuggestions(defaultSuggestions);
  };

  useEffect(() => {
    setSuggestions((p) => ({
      ...p,
      hotelsNames: hotelNames,
    }));
  }, [hotelNames]);

  return (
    <div className={classes.HotelsAutocomplete}>
      <input
        type="text"
        onChange={(e) => {
          onChangeHandler(e.target.value);
        }}
        value={value}
        onBlur={() => setTimeout(() => setSuggestions(defaultSuggestions), 100)}
      />
      {_.flatten(_.get(suggestions, "hotelsNames.data.candidates", [])).length >
        0 && (
        <div className={classes.suggestions}>
          {_.get(suggestions, "hotelsNames.data.candidates", []).map(
            (hotel, idx) => (
              <div
                key={idx}
                className={classes.suggestion}
                onClick={() => onSuggestSelect(hotel)}>
                {_.startCase(hotel.name)}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

HotelsAutocomplete.propTypes = {
  value: PropTypes.string.isRequired,
  autocompleteData: PropTypes.object.isRequired,
  setAutocompleteData: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default HotelsAutocomplete;
