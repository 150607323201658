import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export async function fetchPaymentTerms({ params }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crm/subentity-payment-terms/`,
    {
      params,
    }
  );
}

export async function retrievePaymentTerms({ id }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crm/subentity-payment-terms/${id}`
  );
}
export function createPaymentTerm({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/crm/subentity-payment-terms/`,
    payload
  );
}

export function patchPaymentTerm({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crm/subentity-payment-terms/${id}/`,
    payload
  );
}

export async function deletePaymentTerm({ id }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/crm/subentity-payment-terms/${id}/`
  );
}
