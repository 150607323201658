import { Icon } from "@iconify/react";
import { useContext } from "react";
import { createUseStyles } from "react-jss";
import { GlobalModalContext } from "../../App";
import { variables } from "../../variables";

const openSidebarArrowStyles = createUseStyles({
  OpenSidebarArrow: {
    display: "none",
  },
  button: {},
  [`@media ${variables.media.smallscreen}`]: {
    OpenSidebarArrow: {
      display: "grid",
      justifySelf: "end",
      width: "max-content",
      height: "max-content",
    },
    button: {
      display: "grid",
      placeItems: "center",
      border: "none",
      background: variables.colors.background.dark,
      color: "white",
      position: "absolute",
      right: variables.normal_gap,
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background .3s, color .3s",
      "&:hover": {
        background: variables.colors.background.darkHover,
        color: variables.colors.text.light,
      },
    },
    arrow: {},
  },
});

export const OpenSidebarArrow = () => {
  const classes = openSidebarArrowStyles();
  const { setOpenSidebar } = useContext(GlobalModalContext);

  return (
    <div className={classes.OpenSidebarArrow}>
      <button className={classes.button} onClick={() => setOpenSidebar(true)}>
        <Icon icon="material-symbols:arrow-forward-ios" />
      </button>
    </div>
  );
};

const closeSidebarArrowStyles = createUseStyles({
  CloseSidebarArrow: { display: "none" },
  button: {},
  [`@media ${variables.media.smallscreen}`]: {
    CloseSidebarArrow: {
      display: "grid",
      width: "max-content",
      height: "max-content",
      justifySelf: "end",
    },
    button: {
      display: "grid",
      placeItems: "center",
      border: "none",
      background: variables.colors.background.dark,
      color: "white",
      position: "absolute",
      right: variables.normal_gap,
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background .3s, color .3s",
      "&:hover": {
        background: variables.colors.background.darkHover,
        color: variables.colors.text.light,
      },
    },
    arrow: {
      height: "20px",
      width: "20px",
    },
  },
});

export const CloseSidebarArrow = () => {
  const classes = closeSidebarArrowStyles();
  const { setOpenSidebar } = useContext(GlobalModalContext);

  return (
    <div className={classes.CloseSidebarArrow}>
      <button className={classes.button} onClick={() => setOpenSidebar(false)}>
        <Icon
          icon="material-symbols:arrow-back-ios-new"
          className={classes.arrow}
        />
      </button>
    </div>
  );
};
