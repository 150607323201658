import { VALAQUENTA_API_URL } from "../../..";
import { authorizedAxios, loggingAxios } from "../../../../App";

export async function fetchWebSiteListPages({ params }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/tour-list-for-entity/`,
    { params }
  );
}

export async function retrieveWebSiteListPage({ id }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/tour-list-for-entity/${id}`
  );
}

export async function patchWebSiteListPage({ id, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/canaveral/tour-list-for-entity/${id}/`,
    payload
  );
}

export async function createWebSiteListPage({ payload }) {
  return await loggingAxios.post(
    `${VALAQUENTA_API_URL}/canaveral/tour-list-for-entity/`,
    payload
  );
}

export async function deleteWebSiteListPage({ id }) {
  return await loggingAxios.delete(
    `${VALAQUENTA_API_URL}/canaveral/tour-list-for-entity/${id}/`
  );
}
