import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { modalGenericStyles, variables } from "../../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import Loader from "../../../../../components/Loader/index";
import { deleteWebSiteContactInfo } from "../../../../../api/DistributionSite/webSites/ContactInfo";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import _ from "lodash";
import { CustomButton } from "../../../../../components/CustomButton";

const basicDataCpl = [
  ["Id", "id"],
  ["Name", "name"],
  ["Address", "address"],
];

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteContactInfo: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  // card: { ...modalGenericStyles.card, justifyContent: "center" },
  basicInfo: {
    display: "grid",

    gridGap: variables.normal_gap,
    justifyContent: "center",
  },
});
const DeleteContactInfo = ({ id, data, onClose }) => {
  const classes = styles();

  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: ({ id }) =>
      deleteWebSiteContactInfo({
        id: id,
      }),
    onSuccess: () => {
      toast.success("Contact Info Page Deleted");
      queryClient.invalidateQueries("contactInfoList");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const onDelete = (id) => {
    deleteMutation.mutate({ id });
  };

  return (
    <div className={classes.DeleteContactInfo}>
      {deleteMutation.isLoading ? (
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <span>DELETE Contact</span>
          </div>
          <div className={classes.cardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              <span>DELETE Contact</span>
            </div>
            <div className={classes.cardBody}>
              <div className={classes.basicInfo}>
                <GenericBasicInfo
                  header={"Are you sure you want to delete this?"}
                  cardInfo={basicDataCpl}
                  objectData={data}
                />
              </div>
            </div>
            <div className={classes.cardActions}>
              <CustomButton appearance="ghost" onClick={onClose}>
                <strong>Cancel</strong>
              </CustomButton>
              <CustomButton
                appearance="primary"
                onClick={() => {
                  onDelete(id);
                }}>
                <strong>DELETE</strong>
              </CustomButton>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
DeleteContactInfo.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DeleteContactInfo;
