import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Loader from "../../../../components/Loader";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Navbar from "./Navbar";
import { useQueries, useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import {
  fetchMonitoring,
  fetchScenarioData,
} from "../../../../api/Monitoring/Scenarios";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import DetailMonitor from "../ScenariosModals/DetailsMonitor";
import { toast } from "react-toastify";
import { CustomButton } from "../../../../components/CustomButton";

const scenarioTableStyles = createUseStyles({
  ScenarioTable: genericIndexesStyles.indexes.mobile,
  tableContainer: genericIndexesStyles.tableContainer.mobile,
  noScenariosContainer: {
    display: "grid",
    gridColumn: "2 span",
  },
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
  successCell: {
    color: "green",
  },
  canceledCell: {
    color: "red",
  },
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      // gridTemplateColumns: "repeat(2,1fr) ",
      padding: `calc(${variables.normal_gap} * 2.5) 0!important`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});
const ScenarioTable = ({ scenarios, filters }) => {
  const classes = scenarioTableStyles();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [uid, setUid] = useState(null);
  const [scenarioName, setScenarioName] = useState("");
  const [environament, setEnvironament] = useState("");

  let queryCpls = [];
  scenarios.forEach((scen) => {
    let environments = [];
    if (!filters) {
      environments = ["prod", "staging"];
    } else {
      environments = [filters.env];
    }
    environments.forEach((env) => {
      queryCpls.push([scen, env]);
    });
  });

  const results = useQueries({
    queries: queryCpls.map(([scen, env], idx) => ({
      queryKey: [`ScenarioData${idx}`, scen, env],
      queryFn: async () => {
        const queryDate = new Date(filters.date_from);
        const newFormatDate = DateTime.fromJSDate(queryDate).setZone("utc");
        // .set({
        //   hour: 0,
        //   minute: 0,
        //   second: 0,
        //   millisecond: 0,
        // });

        const data = await fetchScenarioData({
          params: {
            scenario_name: scen,
            env,
            test_status: filters.test_status,
            query_date: newFormatDate.toJSDate(),
          },
        });
        return data;
      },
      onError: (error) => {
        if (_.get(error, "response.status") === 404) {
          return;
        }
        genericRequestErrorHandler(error);
      },
      refetchOnWindowFocus: false,
    })),
  });

  const arrayIsLoading = results.map((res) => res.isLoading);
  const isLoading = arrayIsLoading.includes(true);

  let totalScenarios = [];
  if (!_.isEmpty(results)) {
    results.forEach((res) => {
      const env = _.get(res, "data.data.environment");
      const results = _.get(res, "data.data.results", []);
      if (_.isEmpty(results)) {
        return;
      }
      results.forEach((result) => {
        totalScenarios.push({
          environment: env,
          // test_status: status,
          ...result,
        });
      });
    });
  }
  if (filters.scenario) {
    totalScenarios = totalScenarios.filter(
      (scenario) => scenario.scenario_name === filters.scenario
    );
  }

  return (
    <div className={classes.ScenarioTable}>
      {isLoading && <Loader onTop={true} />}
      {/* {isLoading && <div className={classes.loader}>scenario table</div>} */}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              <th className={classes.headCell}>Scenario Name</th>
              <th className={classes.headCell}>Environment</th>
              <th className={classes.headCell}>Tested</th>
              <th className={classes.headCell}>Status</th>
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.isEmpty(totalScenarios) ? (
              <tr className={classes.bodyRow}>
                <td className={classes.tableCell} colSpan="4">
                  <div className={classes.noScenariosContainer}>
                    <div className={classes.noScenarios}>
                      No Scenarios Found
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              totalScenarios.map((scenario, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  <td className={classes.tableCell}>
                    {_.capitalize(
                      _.get(scenario, "scenario_name", "").replace(/-/g, " ")
                    ) || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(scenario, "environment") === "prod"
                      ? "Production"
                      : _.capitalize(_.get(scenario, "environment", ""))}
                  </td>
                  <td className={classes.tableCell}>
                    {DateTime.fromISO(
                      _.get(scenario, "timestamp", "")
                    ).toLocaleString(DateTime.DATETIME_SHORT) || "N/A"}
                  </td>
                  <td
                    className={
                      classes.tableCell +
                      " " +
                      (scenario.data.status === "success"
                        ? classes.successCell
                        : classes.canceledCell)
                    }>
                    {_.capitalize(_.get(scenario, "data.status", "")) || "N/A"}
                  </td>
                  <td className={classes.actionCell}>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        setEnvironament(_.get(scenario, "environment", ""));
                        setUid(_.get(scenario, "scenario_uuid", ""));
                        setScenarioName(scenario.scenario_name);
                        setShowDetailModal(true);
                      }}>
                      <strong>Details</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {showDetailModal && (
        <DetailMonitor
          scenario_uid={uid}
          environment={environament}
          scenario_name={scenarioName}
          onClose={() => {
            setShowDetailModal(false);
            setUid(null);
          }}
        />
      )}
    </div>
  );
};
ScenarioTable.propTypes = {
  filters: PropTypes.object.isRequired,
  scenarios: PropTypes.array.isRequired,
};

// const today = new Date();
// const todayISO = today.toISOString().slice(0, 10);
// const today = new Date();
// today.setHours(0, 0, 0, 0);

// const todayISO = today.toISOString({
//   includeOffset: false,
// });
const today = DateTime.utc().startOf("day");

const todayISO = today.toISO({
  includeOffset: false,
});

const initialFilters = {
  scenario: "",
  env: "",
  date_from: todayISO,
  test_status: "",
};

const navbarSchema = yup.object().shape({});

const styles = createUseStyles({
  Scenarios: genericIndexesStyles.indexes.mobile,
  tableContainer: genericIndexesStyles.tableContainer.mobile,
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      gridTemplateColumns: "repeat(2,1fr) ",
      paddingTop: `${variables.normal_gap}!important`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});
const Scenarios = () => {
  const classes = styles();
  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);

  const handleApplyLocalFilters = async (filters) => {
    if (!filters.test_status || !filters.env) {
      toast.error(
        "You must choose Environment & Status before applying filters."
      );
      return;
    }
    try {
      const filteredFilters = Object.keys(filters).reduce((acc, key) => {
        if (filters[key] !== "") {
          acc[key] = filters[key];
        }
        return acc;
      }, {});

      await navbarSchema.validate(filteredFilters, { abortEarly: false });
      setFilters(filteredFilters);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        if (!validationErrors[err.path]) {
          validationErrors[err.path] = err.message;
          toast.error(err.message);
        }
      });
    }
  };

  const { data: scenarioData, isLoading: scenarioDataLoading } = useQuery({
    queryKey: ["Monitor", filters],
    queryFn: async () => await fetchMonitoring({ params: { ...filters } }),
    onError: (error) => genericRequestErrorHandler(error),
    enabled: filters.test_status !== "" && filters.env !== "",
    refetchOnWindowFocus: false,
  });
  const loading =
    scenarioDataLoading && filters.test_status !== "" && filters.env !== "";

  return (
    <div className={classes.Scenarios}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        scenarios={_.get(scenarioData, "data.scenarios", [])}
        onApplyFilters={handleApplyLocalFilters}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.tableContainer}>
          {!_.get(scenarioData, "data.scenarios", []).length ? (
            <div></div>
          ) : (
            <ScenarioTable
              filters={filters}
              scenarios={_.get(scenarioData, "data.scenarios", [])}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default Scenarios;
