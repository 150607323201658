import _ from "lodash";
import axios from "axios";
import {
  VALAQUENTA_API_URL,
  genericRequestErrorHandler,
  getAuthHeaders,
} from "../..";
import { SISI_API_URL } from "../..";
import { authorizedAxios } from "../../../App";

export function fetchHotels({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/accommodator/internal/hotels/`,
    {
      // return axios.get(`${VALAQUENTA_API_URL}/accommodator/internal/hotels/`, {
      params,
    }
  );
}

export function retrieveHotel({ pk }) {
  return axios.get(`${VALAQUENTA_API_URL}/accommodator/internal/hotels/${pk}/`);
}

export async function fetchHotelImages({
  hotelUid,
  errorFunc = (error) => genericRequestErrorHandler(error),
}) {
  try {
    const result = await axios.get(
      `${SISI_API_URL}/accommodator/internal/hotels/${hotelUid}/`
    );
    return _.get(result, "data", []);
  } catch (error) {
    errorFunc(error);
    return null;
  }
}

export async function patchHotelImages({
  hotelUid,
  payload,
  errorFunc = (error) => genericRequestErrorHandler(error),
}) {
  try {
    const result = await axios.patch(
      `${SISI_API_URL}/accommodator/internal/hotels/${hotelUid}/`,
      payload
    );
    return result.data;
  } catch (error) {
    errorFunc(error);
    return null;
  }
}

export async function patchHotel({ hotelPk, payload }) {
  payload.custom_doc_score = Number(payload.custom_doc_score);
  payload.rating = Number(payload.rating);
  return await axios.patch(
    `${VALAQUENTA_API_URL}/accommodator/internal/hotels/${hotelPk}/`,
    payload
  );
}

export async function imageQuality({
  jwt_token,
  payload,
  errorFunc = (error) => genericRequestErrorHandler(error),
}) {
  try {
    const result = await axios.post(
      `${SISI_API_URL}/accommodator/internal/quality_filter/`,
      payload,
      { headers: getAuthHeaders({ jwt_token }) }
    );
    return result.data;
  } catch (error) {
    errorFunc(error);
    return null;
  }
}

export async function findToilets({
  jwt_token,
  payload,
  errorFunc = (error) => genericRequestErrorHandler(error),
}) {
  try {
    const result = await axios.post(
      `${SISI_API_URL}/accommodator/internal/quality_filter/`,
      payload,
      { header: getAuthHeaders({ jwt_token }) }
    );
    return result.data;
  } catch (error) {
    errorFunc(error);
    return null;
  }
}

export async function createAccNewImage({
  payload,
  hotelUid,
  errorFunc = (error) => genericRequestErrorHandler(error),
}) {
  try {
    const result = await axios.patch(
      `${SISI_API_URL}/accommodator/upload-images/${hotelUid}`,
      payload
    );
    return result.data;
  } catch (error) {
    errorFunc(error);
    return null;
  }
}
