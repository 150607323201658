import { createUseStyles } from "react-jss";
import { variables } from "../../../../variables";
import propTypes from "prop-types";

const styles = createUseStyles({
  websitesLanguages: {
    display: "grid",
    gridTemplateColumns: "repeat(12, auto)",
    gap: variables.half_gap,
  },
  languagesButton: {
    padding: variables.normal_gap,
    gap: variables.normal_gap,
    borderRadius: "5px",
    cursor: "pointer",
    fontWeight: "bold",
    transition: "background .s, color .3s",
    "&:hover": {
      background: variables.colors.background.darkHover,
      color: variables.colors.text.light,
    },
  },
  choosenButton: {
    extend: "languagesButton",
    backgroundColor: variables.colors.background.darkHover,
    color: variables.colors.text.light,
  },
});

const WebsitesLanguages = ({
  activeLanguage,
  setActiveLanguage,
  languages,
}) => {
  const classes = styles();

  return (
    <div className={classes.websitesLanguages}>
      {/* <div className={classes.languagesButtons}> */}
      {Object.entries(languages).map(([key, label], idx) => (
        <button
          key={idx}
          className={
            key === activeLanguage
              ? classes.choosenButton
              : classes.languagesButton
          }
          onClick={(e) => {
            e.preventDefault();
            setActiveLanguage(key);
          }}>
          {label}
        </button>
      ))}
      {/* </div> */}
    </div>
  );
};
WebsitesLanguages.defaultProps = {
  languages: {
    en: "English",
    de: "German",
    el: "Greek",
    es: "Spanish",
    fr: "French",
    it: "Italian",
    ja: "Japanese",
    nl: "Dutch",
    pt: "Portuguese",
    th: "Thai",
    zh: "Chinese",
    lt: "Lithuanian",
  },
};
WebsitesLanguages.PropsTypes = {
  activeLanguage: propTypes.string.isRequired,
  setActiveLanguage: propTypes.func.isRequired,
  languages: propTypes.object,
};
export default WebsitesLanguages;
