import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { tableStyles, cardStyles, variables } from "../../../../../variables";
import { DateTime } from "luxon";

const styles = createUseStyles({
  ConnectionsTable: {
    ...cardStyles.card,
    maxWidth: "100%",
  },
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.cardBody,
    // justifySelf: "center",
    textAlign: "-webkit-center",
  },
  loader: { width: "100vw" },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: { ...tableStyles.table, maxWidth: "100%", overflow: "hidden" },
  head: tableStyles.head,
  tbody: tableStyles.body,
  trow: tableStyles.body.row,
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  tableCellNoGuest: { ...tableStyles.body.cell, color: "red" },
});

const ConnectionsTable = ({ data }) => {
  const classes = styles();
  return (
    <div className={classes.ConnectionsTable}>
      <div className={classes.cardHeader}>Connections</div>
      <div className={classes.cardBody}>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Date</th>
                <th className={classes.headCell}>IP Address</th>
                <th className={classes.headCell}>Browser</th>
                <th className={classes.headCell}>Log ID</th>
              </tr>
            </thead>
            <tbody className={classes.tbody}>
              {!data ? (
                <tr>
                  <td className={classes.tableCellNoGuest} colSpan="5">
                    There are no connections for this guest
                  </td>
                </tr>
              ) : (
                data.map((dt, idx) => (
                  <tr key={idx} className={classes.trow}>
                    <td className={classes.tableCell}>{idx + 1}</td>
                    <td className={classes.tableCell}>
                      {DateTime.fromISO(dt.date).toLocaleString(
                        DateTime.DATE_MED_WITH_WEEKDAY
                      ) || "N/A"}
                    </td>
                    <td className={classes.tableCell}>{dt.ip || "N/A"}</td>
                    <td className={classes.tableCell}>{dt.browser || "N/A"}</td>
                    <td className={classes.tableCell}>{dt.log_id || "N/A"}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
ConnectionsTable.defaultProps = {
  //   data: [],
};
ConnectionsTable.propTypes = {
  data: PropTypes.array.isRequired,
};
export default ConnectionsTable;
