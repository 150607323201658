import React from "react";
import Logs from "../../../../../../components/Logs";
import { VALAQUENTA_API_URL } from "../../../../../../api";

const MasterPoliciesLogTab = (WrappedComponent) => {
  const Comp = (props) => {
    const actionsMapping = {
      edit: {
        label: "Edit",
        url: `${VALAQUENTA_API_URL}/crm/policies/master/`,
        method: "PATCH",
      },
    };

    return <WrappedComponent {...props} actionsMapping={actionsMapping} />;
  };
  return Comp;
};

export default MasterPoliciesLogTab(Logs);
