import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import Loader from "../../../../../components/Loader/index";
import {
  deleteDocument,
  retrieveDocument,
} from "../../../../../api/CRM/Documents";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CustomButton } from "../../../../../components/CustomButton";

const basicDataCpl = [
  ["Tags", "tags_display"],
  ["Creator Username", "creator_metadata.username"],
  ["Creator First Name", "creator_metadata.first_name"],
  ["Creator Last Name", "creator_metadata.last_name"],
  ["Creator Email", "creator_metadata.email"],
  ["Source Entity", "creator_metadata.source_entity"],
  ["Filename", "filename"],
  ["Path", "path"],
];

const deleteUsersDocuments = createUseStyles({
  ...modalGenericStyles,
  DeleteUserDocument: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    display: "grid",
    gap: variables.normal_gap,
  },
  basicInfo: {
    display: "grid",

    gridGap: variables.normal_gap,
    justifyContent: "center",
  },
});

const DeleteUserDocument = ({ uid, onClose }) => {
  const classes = deleteUsersDocuments();

  const { data, isLoading } = useQuery({
    queryKey: ["DeleteUserDocument"],
    queryFn: () =>
      retrieveDocument({
        uid: uid,
      }),
    onError: (error) => toast.error(error),
  });

  const deleteUserDocumentMutation = useMutation(deleteDocument, {
    onSuccess: () => {
      toast.success("User Document Deleted");
      onClose();
    },
    onError: (error) => toast.error(error.message),
  });

  const onDelete = async (uid) => {
    await deleteUserDocumentMutation.mutateAsync({ uid });
  };

  return (
    <div id="DeleteUserDocumentModal" className={classes.DeleteUserDocument}>
      {isLoading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>DELETE User Document</div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>DELETE User Document</div>
          <div className={classes.modalCardBody}>
            <div className={classes.cardBody}>
              <div className={classes.basicInfo}>
                <GenericBasicInfo
                  header={"Are you sure you want to delete this?"}
                  cardInfo={basicDataCpl}
                  objectData={data.data}
                />
                <div className={classes.cardActions}>
                  <CustomButton id="close" appearance="ghost" onClick={onClose}>
                    <strong>Cancel</strong>
                  </CustomButton>
                  <CustomButton
                    id="submit"
                    appearance="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      onDelete(uid);
                    }}>
                    <strong>DELETE</strong>
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
DeleteUserDocument.propTypes = {
  onClose: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
};
export default DeleteUserDocument;
