import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { cardStyles, modalGenericStyles, variables } from "../variables";
import { CustomButton } from "./CustomButton";
import Loader from "./Loader";

const styles = createUseStyles({
  VerifyModal: modalGenericStyles.modal,
  modalCard: { ...modalGenericStyles.card },
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
    width: "15rem",
    minHeight: "5rem",
    minWidth: "15rem",
  },
  loader: {
    width: "100%",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardBody: { ...cardStyles.cardBody },
});

const VerifyModal = ({ title, message, isLoading, onConfirm, onClose }) => {
  const classes = styles();
  return (
    <div className={classes.VerifyModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>{title}</div>
        {isLoading ? (
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            <div className={classes.modalCardBody}>{message}</div>
            <div className={classes.modalCardActions}>
              <CustomButton appearance="ghost" text="Cancel" onClick={onClose}>
                <strong>Cancel</strong>
              </CustomButton>
              <CustomButton
                appearance="primary"
                text="Confirm"
                onClick={onConfirm}>
                <strong>Confirm</strong>
              </CustomButton>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
VerifyModal.defaultProps = {
  title: "Verify Clone Action",
  message: "You want to clone this entry?",
  isLoading: false,
};
VerifyModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default VerifyModal;
