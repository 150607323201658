import { VALAQUENTA_API_URL } from "../../..";
import { authorizedAxios, loggingAxios } from "../../../../App";

export function fetchWebSiteRestaurantPromos({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/restaurant-promo-for-entity/`,
    { params }
  );
}

export async function retrieveWebSiteRestaurantPromo({ id }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/restaurant-promo-for-entity/${id}/`
  );
}

export async function patchWebSiteRestaurantPromo({ id, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/canaveral/restaurant-promo-for-entity/${id}/`,
    payload
  );
}

export async function createWebSiteRestaurantPromo({ payload }) {
  return await loggingAxios.post(
    `${VALAQUENTA_API_URL}/canaveral/restaurant-promo-for-entity/`,
    payload
  );
}

export async function deleteWebSiteRestaurantPromo({ id }) {
  return await loggingAxios.delete(
    `${VALAQUENTA_API_URL}/canaveral/restaurant-promo-for-entity/${id}/`
  );
}
