import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { cardStyles, tableStyles, variables } from "../../../variables";
import PropTypes from "prop-types";
import {
  fetchEmailsReservations,
  sendReservationEmail,
} from "../../../api/CRS/emails";
import _ from "lodash";
import { DateTime } from "luxon";
import {
  email_type_mapping,
  receiver_type_choices,
  service_type_choices,
} from "../../../pages/CRS/common";
import SendEmailModal from "../../../pages/CRS/CommonCRSModals/SendEmailModal";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import SendCancellationEmail from "../../../pages/CRS/CommonCRSModals/SendCancellationEmail";
import { CustomButton } from "../../CustomButton";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../api";

const emailStyles = createUseStyles({
  ...cardStyles,
  EmailCard: { ...cardStyles.card, maxWidth: "100%" },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: { ...tableStyles.table, maxWidth: "100%", overflow: "hidden" },
  thead: tableStyles.head,
  tbody: tableStyles.body,
  trow: tableStyles.body.row,
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  cardActions: {
    ...cardStyles.actions,
  },
  sendVoucherEmail: {
    display: ({ statusForDisplay }) =>
      statusForDisplay === "CO" ? "grid" : "none",
  },
  cancelEmail: {
    display: ({ statusForDisplay }) =>
      statusForDisplay === "CA" ? "grid" : "none",
  },
});

const EmailsCard = ({
  title,
  reservation_id,
  service_type,
  market_type,
  status,
}) => {
  const [statusForDisplay, setStatusForDisplay] = useState("");
  const classes = emailStyles({ statusForDisplay });
  const [emails, setEmails] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [showSendVoucherModal, setShowSendVoucherModal] = useState(false);
  const [showSendCancellationEmail, setShowSendCancellationEmail] =
    useState(false);

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["fetchEmails", reservation_id],
    queryFn: async () =>
      await fetchEmailsReservations({
        params: {
          reservation_id,
          service_type,
          market_type,
        },
      }),
    onSuccess: (data) => {
      setEmails(_.get(data, "data.results", []));
      setStatusForDisplay(status);
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const sendEmailMutation = useMutation({
    mutationFn: async ({ payload }) => {
      await sendReservationEmail({ payload });
    },
    onSuccess: () => {
      toast.success("Email Sent");
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmitEmail = (email_type) => {
    const payload = {
      reference: reservation_id,
      market_type,
      reservation_type: service_type,
      email_type,
    };
    sendEmailMutation.mutate({ payload });
  };

  return (
    <div className={classes.EmailCard}>
      {retrieveLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className={classes.header}>
            <span>{title}</span>
            <div className={classes.cardActions}>
              <div className={classes.sendVoucherEmail}>
                <CustomButton
                  appearance="ghost"
                  onClick={() => setShowSendVoucherModal(true)}>
                  <strong>Send Voucher</strong>
                </CustomButton>
              </div>
              <div className={classes.cancelEmail}>
                <CustomButton
                  appearance="ghost"
                  onClick={() => setShowSendCancellationEmail(true)}>
                  <strong>Send Cancellation Email</strong>
                </CustomButton>
              </div>
              <CustomButton
                appearance="ghost"
                onClick={() => queryClient.invalidateQueries("fetchEmails")}>
                <strong>Update Email</strong>
              </CustomButton>
            </div>
          </div>
          <div className={classes.tableContainer}>
            <table className={classes.table}>
              <thead className={classes.thead}>
                <tr className={classes.headRow}>
                  <th className={classes.headCell}>#</th>
                  <th className={classes.headCell}>Email Type</th>
                  <th className={classes.headCell}>From</th>
                  <th className={classes.headCell}>To</th>
                  <th className={classes.headCell}>Receiver Type</th>
                  <th className={classes.headCell}>Service Type</th>
                  <th className={classes.headCell}>Send At</th>
                </tr>
              </thead>
              <tbody className={classes.tbody}>
                {(emails || []).map((email, idx) => (
                  <tr key={idx} className={classes.trow}>
                    <td className={classes.tableCell}>{idx + 1}</td>
                    <td className={classes.tableCell}>
                      {_.get(email_type_mapping, email.email_type) || "N/A"}
                    </td>
                    <td className={classes.tableCell}>
                      {email.sender || "N/A"}
                    </td>
                    <td className={classes.tableCell}>
                      {_.get(email, "receivers", []).map((receiver, idx) => (
                        <span key={idx}>{receiver || "N/A"}</span>
                      ))}
                    </td>
                    <td className={classes.tableCell}>
                      {_.get(receiver_type_choices, email.receiver_type) ||
                        "N/A"}
                    </td>
                    <td className={classes.tableCell}>
                      {_.get(service_type_choices, email.service_type) || "N/A"}
                    </td>
                    <td className={classes.tableCell}>
                      {email.created
                        ? DateTime.fromISO(email.created).toLocaleString(
                            DateTime.DATETIME_MED_WITH_WEEKDAY
                          )
                        : "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
      {showSendVoucherModal && (
        <SendEmailModal
          title="Send Voucher Email"
          message="Are you sure?"
          onSubmit={() => onSubmitEmail("VO")}
          onClose={() => setShowSendVoucherModal(false)}
        />
      )}
      {showSendCancellationEmail && (
        <SendCancellationEmail
          title="Send Cancelation Email"
          message="Are you sure?"
          onSubmit={() => onSubmitEmail("RC")}
          onClose={() => setShowSendCancellationEmail(false)}
        />
      )}
    </div>
  );
};
EmailsCard.propTypes = {
  title: PropTypes.string.isRequired,
  service_type: PropTypes.string.isRequired,
  market_type: PropTypes.oneOf(["B2B", "B2C"]).isRequired,
  reservation_id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};
export default EmailsCard;
