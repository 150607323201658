import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { modalGenericStyles, variables } from "../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../components/cards/GenericBasicInfo";
import _ from "lodash";
import { genericRequestErrorHandler } from "../../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTransferApiConfiguration } from "../../../../api/TransfersEngine/TransferAPIConfigurations";
import Loader from "../../../../components/Loader";
import { transferEngineTrueFalseMapping } from "../../common.js";
import { CustomButton } from "../../../../components/CustomButton/index.js";

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteTransferAPIConfigurationsModal: modalGenericStyles.modal,
  cardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    gridTemplateColumns: "repeat(2, max-content)",
  },
});

const DeleteTransferAPIConfigurationsModal = ({
  deletedUid,
  transfer,
  onClose,
}) => {
  const classes = styles();
  const queryClient = useQueryClient();

  const basicDataCpl = [
    ["Uid", "uid"],

    ["Name", null, _.capitalize(_.get(transfer, "name")) || "N/A"],
    [
      "Supplier",
      null,
      _.capitalize(_.get(transfer, "data.supplier", "")) || "N/A",
    ],
    ["Source Entity", "source_entity"],
    ["Market Type", "market_type"],
    ["Curency", "currency"],
    [
      "Is Live",
      null,
      _.get(transferEngineTrueFalseMapping, _.get(transfer, "is_live")) ||
        "N/A",
    ],
    [
      "Is Global",
      null,
      _.get(
        transferEngineTrueFalseMapping,
        _.get(transfer, "global_account")
      ) || "N/A",
    ],
    [
      "is Own",
      null,
      _.get(transferEngineTrueFalseMapping, _.get(transfer, "is_own")) || "N/A",
    ],
  ];
  const deleteTransferApiConfigurationMutation = useMutation(
    deleteTransferApiConfiguration,
    {
      onSuccess: () => {
        toast.success("Transfer Api Configuration Deleted");
        queryClient.invalidateQueries("TransfersAPIConfigurations");
        onClose();
      },
      onError: (error) => genericRequestErrorHandler(error),
    }
  );
  const onDelete = async () => {
    await deleteTransferApiConfigurationMutation.mutateAsync({
      uid: deletedUid,
    });
  };

  return (
    <div
      id="DeleteTRFEngineModal"
      className={classes.DeleteTransferAPIConfigurationsModal}>
      <React.Fragment>
        <div className={classes.card}>
          {deleteTransferApiConfigurationMutation.isLoading && (
            <Loader onTop={true} />
          )}
          <div className={classes.cardHeader}>
            <span>DELETE Transfer Api Configuration</span>
          </div>
          <div className={classes.cardBody}>
            <div className={classes.basicInfo}>
              <GenericBasicInfo
                header={"Are you sure you want to delete this?"}
                cardInfo={basicDataCpl}
                objectData={transfer}
              />
            </div>
            <div className={classes.cardActions}>
              <CustomButton
                id="DeleteTRFEngineModalCloseButton"
                appearance="ghost"
                onClick={onClose}>
                <strong>Cancel</strong>
              </CustomButton>
              <CustomButton
                id="DeleteTRFEngineModalConfirmButton"
                appearance="primary"
                onClick={() => {
                  onDelete();
                }}>
                <strong>DELETE</strong>
              </CustomButton>
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
DeleteTransferAPIConfigurationsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  transfer: PropTypes.object.isRequired,
  deletedUid: PropTypes.string.isRequired,
};
export default DeleteTransferAPIConfigurationsModal;
