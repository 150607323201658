import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  modalGenericStyles,
  tableStyles,
  variables,
  genericIndexesStyles,
} from "../../../../../variables";
import PropTypes from "prop-types";
import EditPromoLandingPage from "../EditPromoLandingPages";
import { DateTime } from "luxon";
import Loader from "../../../../../components/Loader";
import { fetchWebSitePromoLandingPages } from "../../../../../api/DistributionSite/webSites/PromoLandingPages";
import { statusMapping } from "../../common";
import _ from "lodash";
import DeletePromoLandingPage from "../DeletePromoLAndingPage";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  PromoLandingPageModal: {
    ...modalGenericStyles.card,
    justifyContent: "center",
  },
  loader: { width: "100vw" },
  cardActions: {
    ...modalGenericStyles.cardActions,
  },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: tableStyles,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile(),

  [`@media ${variables.media.smallscreen}`]: {},
});

const PromoLandingPages = ({ websiteId }) => {
  const classes = styles();

  const [promoLandingPage, setPromoLandingPage] = useState(null);
  const [editId, setEditId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { data, isLoading } = useQuery({
    queryKey: ["PromoLandingPages"],
    queryFn: async () =>
      await fetchWebSitePromoLandingPages({
        params: { website: websiteId },
        onError: (error) => genericRequestErrorHandler(error),
      }),
  });
  return (
    <div className={classes.PromoLandingPages}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          Promo Landing Pages
          <div className={classes.cardActions}>
            <CustomButton
              appearance="ghost"
              onClick={() => {
                setEditId(null);
                setShowEditModal(true);
              }}>
              <strong>Add</strong>
            </CustomButton>
          </div>
        </div>
        <div className={classes.tableContainer}>
          {isLoading ? (
            <div className={classes.loader}>
              <Loader />
            </div>
          ) : (
            <React.Fragment>
              <table className={classes.table}>
                <thead className={classes.thead}>
                  <tr className={classes.headRow}>
                    <th className={classes.headCell}>#</th>
                    <th className={classes.headCell}>Id</th>
                    <th className={classes.headCell}>Name</th>
                    <th className={classes.headCell}>Title</th>
                    <th className={classes.headCell}>Subtitle</th>
                    <th className={classes.headCell}>Search Form</th>
                    <th className={classes.headCell}>Page Type</th>
                    <th className={classes.headCell}>Active From</th>
                    <th className={classes.headCell}>Active To</th>
                    <th className={classes.headCell}>Status</th>
                    <th className={classes.headCell}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {_.get(data, "data.results", []).map(
                    (promoLandingPage, idx) => (
                      <tr key={idx} className={classes.bodyRow}>
                        <td className={classes.cell}>{idx + 1}</td>
                        <td className={classes.cell}>{promoLandingPage.id}</td>
                        <td className={classes.cell}>
                          {promoLandingPage.name || "N/A"}
                        </td>
                        <td className={classes.cell}>
                          {promoLandingPage.title || "N/A"}
                        </td>
                        <td className={classes.cell}>
                          {promoLandingPage.subtitle || "N/A"}
                        </td>
                        <td className={classes.cell}>
                          {promoLandingPage.has_searchForm
                            ? "Yes"
                            : "No" || "N/A"}
                        </td>
                        <td className={classes.cell}>
                          {promoLandingPage.page_type}
                        </td>
                        <td className={classes.cell}>
                          {promoLandingPage.active_from
                            ? DateTime.fromISO(
                                promoLandingPage.active_from
                              ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                            : "N/A"}
                        </td>
                        <td className={classes.cell}>
                          {promoLandingPage.active_to
                            ? DateTime.fromISO(
                                promoLandingPage.active_to
                              ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                            : "N/A"}
                        </td>
                        <td className={classes.cell}>
                          {_.get(
                            statusMapping,
                            _.get(promoLandingPage, "status")
                          )}
                        </td>
                        <td className={classes.actionCell}>
                          <CustomButton
                            appearance="ghost"
                            onClick={() => {
                              setShowEditModal(true);
                              setEditId(promoLandingPage.id);
                            }}>
                            <strong>Edit</strong>
                          </CustomButton>
                          <CustomButton
                            appearance="ghost"
                            text="Delete"
                            onClick={() => {
                              setDeleteId(promoLandingPage.id);
                              setPromoLandingPage(promoLandingPage);
                              setDeleteModal(true);
                            }}>
                            <strong>Delete</strong>
                          </CustomButton>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </React.Fragment>
          )}
        </div>
      </div>
      {showEditModal && (
        <EditPromoLandingPage
          promoLandingPageId={editId}
          websiteId={websiteId}
          onClose={() => {
            setShowEditModal(false);
            setEditId(null);
          }}
        />
      )}
      {deleteModal && (
        <DeletePromoLandingPage
          id={deleteId}
          data={promoLandingPage}
          onClose={() => {
            setDeleteModal(false);
            setDeleteId(null);
          }}
        />
      )}
    </div>
  );
};
PromoLandingPages.propTypes = {
  websiteId: PropTypes.number.isRequired,
};
export default PromoLandingPages;
