import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  variables,
  btnStyles,
  modalGenericStyles,
  cardStyles,
  formInputGroup,
} from "../../../../../variables";
import {
  createBranch,
  patchBranch,
  retrieveBranch,
} from "../../../../../api/CRM/Branches/Branches";
import Loader from "../../../../../components/Loader";
import _ from "lodash";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import {
  convertStringToNumber,
  currencies,
  handleValidationErrors,
} from "../../../../../helpers";
import { CustomButton } from "../../../../../components/CustomButton";
import EntityAutocomplete from "../../../../../components/EntityAutocomplete";

const b2cVivaCredentialsType = {
  "": "-----",
  LV: "Live",
  DE: "Demo",
};

const currentYear = new Date().getFullYear();

const jsonSchema = yup.object().shape({
  name: yup.string().max(100, "Must be less than 100 characters").default(""),
  legal_title: yup.string().nullable().default(null),
  registration_code: yup.string().nullable().default(""),
  currency: yup.string().nullable().default(""),
  founding_year: yup
    .number()
    .integer("A valid integer is required for founding year")
    // .positive("Must be a positive integer")
    .nullable()
    .max(currentYear, `Cannot be greater than ${currentYear}`)
    .nullable()
    .default(0),
  mobile_phone: yup
    .string()
    .max(20, "Cannot be greater than 20 digits")
    .nullable()
    .default(""),
  phone: yup
    .string()
    .max(20, "Cannot be greater than 20 digits")
    .nullable()
    .default(""),
  email: yup.string().email("Invalid email address").nullable().default(null),
  felloh_organisation: yup.string().nullable().default(""),
  felloh_public_key: yup.string().nullable().default(""),
  felloh_secret_key: yup.string().nullable().default(""),
  b2c_stripe_accepted_coupons: yup.string().nullable().default(""),
  b2c_stripe_api_public_key: yup.string().nullable().default(""),
  b2c_stripe_api_secret_key: yup.string().nullable().default(""),
  b2c_viva_api_key: yup.string().nullable().default(""),
  b2c_viva_credentials_type: yup.string().nullable().default(""),
  b2c_viva_merchant_id: yup.string().nullable().default(""),
  b2c_viva_source_code: yup.string().nullable().default(""),
  barclays_passphrase: yup.string().nullable().default(""),
  barclays_pspid: yup.string().nullable().default(""),
});

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditBranchModal: {
    ...modalGenericStyles.modal,
  },

  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content  1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  inputGroup: formInputGroup,
  entityAutoComplete: {
    ...formInputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  btn: btnStyles.btn,
  [`@media ${variables.media.smallscreen}`]: {
    card: {
      minWidth: "50rem",
    },
    cardBody: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    cardCol2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {},
});

const EditBranchModal = ({ branchId, onClose }) => {
  const classes = styles();
  const mode = branchId ? "edit" : "add";

  const [form, setForm] = useState(jsonSchema.cast({}));
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["EditBranchModal"],
    queryFn: () =>
      retrieveBranch({
        id: branchId,
      }),
    enabled: mode === "edit",
    onSuccess: (data) => {
      setForm(jsonSchema.cast(_.get(data, "data", {})));
      setAutocompleteValue(_.get(data, "data.member"));
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const mutation = useMutation(
    async ({ id, payload }) => {
      if (mode === "add") {
        return createBranch({ payload });
      }
      return patchBranch({
        id,
        payload,
      });
    },
    {
      onSuccess: () => {
        toast.success(mode === "add" ? "Branch added" : "Branch Info Updated");
        queryClient.invalidateQueries("Branches");
        onClose();
      },
      onError: (error) => genericRequestErrorHandler(error),
    }
  );
  const handleInputChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const onSubmit = async ({ id, payload }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      mutation.mutate({ id, payload });
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const numericFields = ["founding_year"];
  const updatedNumericFields = convertStringToNumber(form, numericFields);

  const loading = (mode === "edit" && retrieveLoading) || mutation.isLoading;
  return (
    <div className={classes.EditBranchModal}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} Branch</span>
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div id="addEditBranchModal" className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} Branch</span>
          </div>
          <div className={classes.modalCardBody}>
            <form onChange={handleInputChange}>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="name">Name</label>
                      <input
                        id="name"
                        type="name"
                        name="name"
                        value={_.capitalize(form.name)}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="member">Member</label>
                      <div className={classes.entityAutoComplete}>
                        <EntityAutocomplete
                          id="entityAutocompleteInputId"
                          subagents={false}
                          independentAgents={false}
                          branches={false}
                          value={autocompleteValue}
                          setValue={setAutocompleteValue}
                          onChange={(text) => {
                            if (text.length === 0) {
                              setForm((p) => ({
                                ...p,
                                member: "",
                              }));
                              return;
                            }
                            const entityInfos = text.split("---");
                            const entityId = entityInfos[1];
                            setForm((p) => ({
                              ...p,
                              member: `${entityId}`,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="legal_title">Legal Title</label>
                      <input
                        id="legal_title"
                        type="text"
                        name="legal_title"
                        value={form.legal_title}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="registration_code">
                        Registration Code
                      </label>
                      <input
                        id="registration_code"
                        type="text"
                        name="registration_code"
                        value={form.registration_code}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="currency">Currency</label>
                      <select
                        id="currency"
                        name="currency"
                        value={form.currency}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(currencies).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="founding_year">Founding Year</label>
                      <input
                        id="founding_year"
                        type="number"
                        name="founding_year"
                        value={form.founding_year}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Contact Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="mobile_phone">Mobile Phone</label>
                      <input
                        id="mobile_phone"
                        type="text"
                        name="mobile_phone"
                        value={form.mobile_phone}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="phone">Phone</label>
                      <input
                        id="phone"
                        type="text"
                        name="phone"
                        value={form.phone}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        value={form.email}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>
                  Payment Gateway Credentials
                </div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="felloh_organisation">
                        Felloh Organisation
                      </label>
                      <input
                        id="felloh_organisation"
                        type="text"
                        name="felloh_organisation"
                        value={form.felloh_organisation}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="felloh_public_key">
                        Felloh Public Key
                      </label>
                      <input
                        id="felloh_public_key"
                        type="text"
                        name="felloh_public_key"
                        value={form.felloh_public_key}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="felloh_secret_key">
                        Felloh Secret Key
                      </label>
                      <input
                        id="felloh_secret_key"
                        type="text"
                        name="felloh_secret_key"
                        value={form.felloh_secret_key}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_stripe_accepted_coupons">
                        B2C Stripe Accepted Coupons
                      </label>
                      <input
                        id="b2c_stripe_accepted_coupons"
                        type="text"
                        name="b2c_stripe_accepted_coupons"
                        value={form.b2c_stripe_accepted_coupons}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_stripe_api_public_key">
                        B2C Stripe Api Public Key
                      </label>
                      <input
                        id="b2c_stripe_api_public_key"
                        type="text"
                        name="b2c_stripe_api_public_key"
                        value={form.b2c_stripe_api_public_key}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_stripe_api_secret_key">
                        B2C Stripe Api Secret Key
                      </label>
                      <input
                        id="b2c_stripe_api_secret_key"
                        type="text"
                        name="b2c_stripe_api_secret_key"
                        value={form.b2c_stripe_api_secret_key}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_viva_api_key">B2C Viva Api Key</label>
                      <input
                        id="b2c_viva_api_key"
                        type="text"
                        name="b2c_viva_api_key"
                        value={form.b2c_viva_api_key}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_viva_credentials_type">
                        B2C Viva Credentials Type
                      </label>
                      <select
                        id="b2c_viva_credentials_type"
                        name="b2c_viva_credentials_type"
                        value={form.b2c_viva_credentials_type}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(b2cVivaCredentialsType).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_viva_merchant_id">
                        B2C Viva Merchant Id
                      </label>
                      <input
                        id="b2c_viva_merchant_id"
                        type="text"
                        name="b2c_viva_merchant_id"
                        value={form.b2c_viva_merchant_id}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_viva_source_code">
                        B2C Viva Source Code
                      </label>
                      <input
                        id="b2c_viva_source_code"
                        type="text"
                        name="b2c_viva_source_code"
                        value={form.b2c_viva_source_code}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="barclays_passphrase">
                        Barclays Pass Phrase
                      </label>
                      <input
                        id="barclays_passphrase"
                        type="text"
                        name="barclays_passphrase"
                        value={form.barclays_passphrase}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="barclays_pspid">Barclays PSPID</label>
                      <input
                        id="barclays_pspid"
                        type="text"
                        name="barclays_pspid"
                        value={form.barclays_pspid}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="submit"
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  id: branchId,
                  payload: { ...form, ...updatedNumericFields },
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditBranchModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  branchId: PropTypes.number.isRequired,
};
export default EditBranchModal;
