import React from "react";
import { createUseStyles } from "react-jss";
import { cardStyles, variables } from "../../../../../variables";
import { PropTypes } from "prop-types";
import _ from "lodash";
import Leg from "./Leg";

const lightServiceSpecificationStyles = createUseStyles({
  ...cardStyles,
  LightServiceSpecification: { display: "grid" },
  card: { ...cardStyles.card, maxWidth: "100%" },
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    maxWidth: "100%",
    overflow: "auto",
    justifyContent: "start",
  },
  [`@media ${variables.media.smallscreen}`]: {
    card: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
});

const LightServiceSpecification = ({ objectData }) => {
  const classes = lightServiceSpecificationStyles();
  console.log("objectData", objectData);

  const getLegType = ({ legs, idx }) => {
    if (idx === 0) {
      return `Outbound`;
    }
    const outboundIata = legs?.[0]?.[0]?.segments?.[0]?.origin_airport?.iata;
    const lastLegIdx = legs.length - 1;
    const lastLegLastSegmentIdx = legs?.[lastLegIdx]?.[0]?.segments.length - 1;
    const lastLegLastSegmentIata =
      legs?.[lastLegIdx]?.[0]?.segments[lastLegLastSegmentIdx]?.origin_airport
        ?.iata;
    if (outboundIata === lastLegLastSegmentIata) {
      return `Inbound`;
    }
    return null;
  };

  return (
    <div className={classes.LightServiceSpecification}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>Service Specification</div>
        <div className={classes.cardBody}>
          {_.get(objectData, "prebook_data.flight.legs", []).map(
            (leg, legIdx) => (
              <Leg
                legIdx={legIdx}
                transportation={objectData}
                type={getLegType({
                  legs: objectData?.prebook_data?.fligh?.legs ?? [],
                  idx: legIdx,
                })}
                // type={legIdx === 0 ? "Outbound" : "Inbound"}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};
LightServiceSpecification.defaultProps = {
  objectData: {},
};
LightServiceSpecification.propTypes = {
  objectData: PropTypes.object.isRequired,
};
export default LightServiceSpecification;
