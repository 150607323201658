import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";

const serviceTypeMapping = {
  ACC: "Accommodations",
  MI: "Addons",
  TF: "Transfers",
  TR: "Transportations",
};
const serviceMapping = {
  HOT: "Accommodations",
  MI: "Addons",
  CAR: "Cars",
  BUS: "Buses",
  TR: "Trains",
  FR: "Ferries",
  FL: "Flights",
  ACT: "Activities",
};

export function prepareTargetServices(policy = {}, tServices) {
  let travel_services_policies = {};
  if (policy) {
    _.get(policy, "travel_services_policies", []).forEach(
      (pol) => (travel_services_policies[pol.id] = pol)
    );
  }
  let sortByType = {};
  _.get(tServices, "data.results", []).forEach((service) => {
    const srvType = _.get(serviceTypeMapping, service.service_type, null);
    const srvName = _.get(serviceMapping, service.service_code, null);
    if (srvType && srvName) {
      if (!Object.keys(sortByType).includes(srvType)) {
        sortByType[srvType] = [];
      }
      sortByType[srvType].push({
        id: service.id,
        label: srvName,
        perc:
          _.get(travel_services_policies, `${service.id}.amount_type`) ===
          "perc"
            ? Number(_.get(travel_services_policies, `${service.id}.amount`)) ||
              0
            : 0,
        num:
          _.get(travel_services_policies, `${service.id}.amount_type`) === "num"
            ? Number(_.get(travel_services_policies, `${service.id}.amount`)) ||
              0
            : 0,
      });
    }
  });
  return sortByType;
}

export const prepareTravelServicesPayload = (preparedTravelServices) => {
  let result = { travel_services: [], travel_services_policies: [] };

  Object.keys(preparedTravelServices).forEach((serviceCat) => {
    const data = preparedTravelServices[serviceCat];
    data.forEach((service) => {
      const amount_type = _.get(service, "perc") ? "perc" : "num";
      const amount = amount_type === "perc" ? service.perc : service.num;
      result.travel_services.push(service.id);
      result.travel_services_policies.push({
        id: service.id,
        amount,
        amount_type,
      });
    });
  });
  return result;
};

const travelServicesStyles = createUseStyles({
  ...modalGenericStyles,
  TravelServices: cardStyles.card,
  cardHeader: { ...cardStyles.header, fontSize: "large" },
  sectionHeader: cardStyles.header,
  subHeader: {
    fontSize: "13px",
  },
  sectionServicesBody: {
    ...cardStyles.body,
    gridGap: variables.half_gap,
    gridTemplateRows: "repeat(2, 1fr)",
  },
  serviceCategory: {
    border: `2px solid ${variables.colors.border.baseBorder}`,
    borderRadius: variables.normal_gap,
    padding: variables.normal_gap,
  },
  service: {
    display: "grid",
    gridTemplateColumns: "1fr repeat(2, 4rem)",
    gridGap: variables.normal_gap,
    alignItems: "center",
    "& input": {
      width: "3rem",
    },
  },
  pricing: {
    display: "grid",
    gridTemplateRows: " repeat(2, max-content)",
    gridGap: variables.half_gap,
    alignItems: "center",
  },
  [`@media ${variables.media.smallscreen}`]: {
    sectionServicesBody: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
});

const TravelServices = ({
  preparedTravelServices,
  setPreparedTravelServices,
}) => {
  const classes = travelServicesStyles();
  return (
    <div className={classes.TravelServices}>
      <div className={classes.cardHeader}>Travel Services</div>
      <div className={classes.sectionServicesBody}>
        {Object.entries(preparedTravelServices).map(
          ([serviceCat, services]) => (
            <div key={serviceCat} className={classes.serviceCategory}>
              <div className={classes.subHeader}>
                <h2>{serviceCat}</h2>
              </div>
              {services.map((service, idx) => (
                <div className={classes.service} key={service.id}>
                  <span>
                    <h5>
                      <strong>{service.label}</strong>
                    </h5>
                  </span>
                  <div className={classes.pricing}>
                    <label>Percent</label>
                    <input
                      type="number"
                      value={_.get(
                        preparedTravelServices,
                        `${serviceCat}.${idx}.perc`
                      )}
                      onChange={(e) => {
                        let newPreparedTravelServices = {
                          ...preparedTravelServices,
                        };
                        newPreparedTravelServices[serviceCat][idx]["perc"] =
                          Number(e.target.value);
                        setPreparedTravelServices({
                          ...newPreparedTravelServices,
                        });
                      }}
                    />
                  </div>
                  <div className={classes.pricing}>
                    <label>Number</label>
                    <input
                      type="number"
                      value={_.get(
                        preparedTravelServices,
                        `${serviceCat}.${idx}.num`
                      )}
                      onChange={(e) => {
                        let newPreparedTravelServices = {
                          ...preparedTravelServices,
                        };
                        newPreparedTravelServices[serviceCat][idx]["num"] =
                          Number(e.target.value);
                        setPreparedTravelServices({
                          ...newPreparedTravelServices,
                        });
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};
TravelServices.propTypes = {
  preparedTravelServices: PropTypes.object.isRequired,
  setPreparedTravelServices: PropTypes.func.isRequired,
};
export default TravelServices;
