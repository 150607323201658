import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { countries, onEnterDown } from "../../../helpers";
import {
  GenericNavbarsStyles,
  inputGroup,
  variables,
} from "../../../variables";
import PropTypes from "prop-types";
import { CustomButton } from "../../../components/CustomButton";

const navbarStyles = createUseStyles({
  Navbar: {
    ...GenericNavbarsStyles.navbars.mobile(),
    gridTemplateRows: "max-content max-content max-content max-content ",
  },
  label: { ...inputGroup },
  divider: GenericNavbarsStyles.divider.mobile(),
  filters: { ...GenericNavbarsStyles.filters.mobile() },

  actions: GenericNavbarsStyles.actions.mobile(),
  inputGroup: {
    ...inputGroup,
    width: "100%",
    "& input, select": {
      ...inputGroup["& input, select"],
      width: "100%",
    },
  },
  inputGroupOneLine: {
    ...inputGroup,
    gridColumn: "2 span",
    width: "100%",
  },
  [`@media ${variables.media.smallscreen}`]: {
    Navbar: {
      ...GenericNavbarsStyles.navbars.smallscreen(),
      gridTemplateColumns: "repeat(2, max-content) 1fr max-content",
      gridTemplateRows: "max-content",
    },
    divider: GenericNavbarsStyles.divider.smallscreen(),
    filters: {
      ...GenericNavbarsStyles.filters.smallscreen(),
      width: "15rem",
    },
    actions: GenericNavbarsStyles.actions.smallscreen(),
    inputGroup: {
      ...inputGroup,
      width: "100%",
    },
    inputGroupOneLine: { ...inputGroup.bigscreen, gridColumn: "1 span" },
  },
  [`@media ${variables.media.bigscreen}`]: {
    filters: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
});
const Navbar = ({ filters, initialFilters, onApplyFilters }) => {
  const classes = navbarStyles();

  const [localFilters, setLocalFilters] = useState(filters);

  return (
    <div className={classes.Navbar}>
      <div className={classes.label}>
        <strong>Destinations</strong>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.filters}>
        <div className={classes.inputGroupOneLine}>
          <label>Country</label>
          <select
            value={localFilters.country_code}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                country_code: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(countries).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={classes.actions}>
        <CustomButton
          appearance="ghost"
          onClick={() =>
            setLocalFilters({
              ...initialFilters,
            })
          }>
          <strong>Reset</strong>
        </CustomButton>
        <CustomButton
          appearance="primary"
          onClick={() => onApplyFilters(localFilters)}>
          <strong>Apply Filters</strong>
        </CustomButton>
      </div>
    </div>
  );
};
Navbar.propTypes = {
  filters: PropTypes.object.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
};
export default Navbar;
