import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { cardStyles } from "../../../../../variables";
import _ from "lodash";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";

const styles = createUseStyles({
  ...cardStyles,
  PaymentGatewaysSection: { ...cardStyles.card },
  red: {
    color: "red",
  },
});
const PaymentGatewaysSection = ({ paymentGateways }) => {
  const classes = styles();

  const NO = <span className={classes.red}>No</span>;

  const paymentGatewaysCpls = [
    ["Stripe", null, _.get(paymentGateways, "has_stripe") ? "Yes" : NO],
    ["Felloh", null, _.get(paymentGateways, "has_felloh") ? "Yes" : NO],
    ["Barcleys", null, _.get(paymentGateways, "has_barclays") ? "Yes" : NO],
    ["Flywire", null, _.get(paymentGateways, "has_flywire") ? "Yes" : NO],
    ["Airwallex", null, _.get(paymentGateways, "has_airwallex") ? "Yes" : NO],
  ];
  return (
    <div className={classes.PaymentGatewaysSection}>
      <GenericBasicInfo
        header="Has Payment Gateways"
        cardInfo={paymentGatewaysCpls}
        objectData={paymentGateways}
        costumeStyles={{ header: { fontSize: "1.2rem" } }}
      />
    </div>
  );
};
PaymentGatewaysSection.propTypes = {
  paymentGateways: PropTypes.object.isRequired,
};
export default PaymentGatewaysSection;
