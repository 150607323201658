import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchGuestsCRM({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/guest-crm/`, {
    params,
  });
}

export function retrieveGuestsCRM({ uuid }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/guest-crm/${uuid}/`);
}

export function fetchFinancialData({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/paymentengine/guest-crm-user-entries/`,
    {
      params,
    }
  );
}

export function retrieveFinancialData({ uid }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/paymentengine/guest-crm-user-entries/${uid}/`
  );
}

export function patchFinancialData({ uid, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/paymentengine/guest-crm-user-entries/${uid}/`,
    payload
  );
}

export function createNewPaymentMethod({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/paymentengine/guest-crm-user-entries/`,
    payload
  );
}
