import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Navbar from "./Navbar";
import ControlStrip from "../../../../components/ControlStrip";
import Loader from "../../../../components/Loader";
import {
  currencies,
  handleApplyFilters,
  medWeekdayDatetimer,
} from "../../../../helpers";
import { fetchBranches } from "../../../../api/CRM/Branches/Branches";
import DetailBranchModal from "../BranchesModal/DetailBranchModal";
import EditBranchModal from "../BranchesModal/EditBranchModal";
import { useQuery } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { genericRequestErrorHandler } from "../../../../api";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import { DateTime } from "luxon";
import { CustomButton } from "../../../../components/CustomButton";

const initialFilters = {
  limit: 20,
  page: 1,
  name__icontains: "",
  mobile_phone__contains: "",
  created__gte: "",
  created__lte: "",
  ordering: "",
  discending: "",
  legal_title: "",
  completeness: "",
  currency__icontains: "",
  legal_title__icontains: "",
  email__icontains: "",
  completeness__gte: "",
  id: null,
};

const navbarSchema = yup.object().shape({
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const branchStyles = createUseStyles({
  Branch: { ...genericIndexesStyles.indexes.mobile, zIndex: "900" },
  tableContainer: genericIndexesStyles.tableContainer.mobile,
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  thead: tableStyles.head,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
  checkBox: tableStyles.checkBox,
  dateCell: { ...tableStyles.body.cell, width: "9rem", minWidth: "9rem" },
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      paddingTop: `${variables.normal_gap}!important`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const Branches = () => {
  const classes = branchStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [branchId, setBranchId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const tableHeaders = {
    Name: (val) => _.capitalize(_.get(val, "name")),
    "Source Entity": (val) => `${_.capitalize(_.get(val, "id"))}___branch`,
    "Legal Title": (val) => _.capitalize(_.get(val, "legal_title")),
    Currency: (branch) => _.get(currencies, _.get(branch, "currency")),
    Email: (val) => val.email,
    Completeness: (val) => val.completeness,
    Created: (val) => val.created,
  };

  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };
  const { data, isLoading } = useQuery({
    queryKey: ["Branches", filters],
    queryFn: () => {
      let correctPayload = { ...filters };
      if (filters.created__lte) {
        correctPayload["created__lte"] = DateTime.fromISO(
          filters.created__lte
        ).toISO();
      }
      if (filters.created__gte) {
        correctPayload["created__gte"] = DateTime.fromISO(
          filters.created__gte
        ).toISO();
      }
      return fetchBranches({
        params: correctPayload,
      });
    },
    enabled: !showDetailModal && !showEditModal,
    onError: (error) => genericRequestErrorHandler(error),
  });
  return (
    <div id="branchListPage" className={classes.Branch}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setBranchId(null);
          setShowEditModal(true);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.limit}
        page={filters.page}
        count={_.get(data, "data.count")}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th className={classes.headCell} key={idx}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody className={classes.tbody}>
            {_.get(data, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "created":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      return (
                        <td className={classes.dateCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    default:
                      cell = fn(val) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.tableCell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    id={`DetailBranchButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setBranchId(val.id);
                      setShowDetailModal(true);
                    }}>
                    <strong>Details</strong>
                  </CustomButton>
                  <CustomButton
                    id={`EditBranchButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setBranchId(val.id);
                      setShowEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDetailModal && (
        <DetailBranchModal
          branchId={branchId}
          onClose={() => setShowDetailModal(false)}
        />
      )}
      {showEditModal && (
        <EditBranchModal
          branchId={branchId}
          onClose={() => {
            setBranchId(null);
            setShowEditModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Branches;
