import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../components/cards/GenericBasicInfo";
import _, { get } from "lodash";
import { genericRequestErrorHandler } from "../../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCarEngineAPIConfigurations } from "../../../../api/CarRentalEngine/ApiConfig";
import { DateTime } from "luxon";
import Loader from "../../../../components/Loader";
import { CustomButton } from "../../../../components/CustomButton";
import { useAuthentication } from "../../../../hooks";

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteApiConfigurationCarEngineModal: modalGenericStyles.modal,
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    gridTemplateColumns: "repeat(2, max-content)",
  },
});

const DeleteApiConfigurationCarEngineModal = ({
  deletedUid,
  data,
  onClose,
}) => {
  const classes = styles();
  const { getJWT } = useAuthentication();
  const queryClient = useQueryClient();
  const basicDataCpl = [
    ["Username", "username"],
    ["Name", null, _.capitalize(_.get(data, "name"))],
    ["Market Type", "market_type"],
    ["Supplier", null, _.capitalize(_.get(data, "supplier"))],
    ["Source Entity", "source_entity"],
    ["Application Key", "application_key"],
    ["Access Token", "access_token"],
    [
      "Created",
      null,
      DateTime.fromISO(_.get(data, "created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];

  const DeleteApiConfig = useMutation(deleteCarEngineAPIConfigurations, {
    onSuccess: () => {
      toast.success("Car Rental Engine Api Configuration Deleted");
      queryClient.invalidateQueries("ApiConfigurationCarRental");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });
  const onDelete = (uid) => {
    DeleteApiConfig.mutate({ uid });
  };

  return (
    <div
      id="DeleteCAREngineModal"
      className={classes.DeleteApiConfigurationCarEngineModal}>
      <React.Fragment>
        <div className={classes.modalCard}>
          {DeleteApiConfig.isLoading && <Loader onTop={true} />}
          <div className={classes.modalCardHeader}>
            <span>DELETE Car Rental Engine Api Configuration</span>
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.card}>
              <div className={classes.cardBody}>
                <div className={classes.basicInfo}>
                  <GenericBasicInfo
                    header={"Are you sure you want to delete this?"}
                    cardInfo={basicDataCpl}
                    objectData={data}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton
              id="DeleteCAREngineModalCloseButton"
              appearance="ghost"
              onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="DeleteCAREngineModalConfirmButton"
              appearance="primary"
              onClick={() => {
                onDelete(deletedUid);
              }}>
              <strong>DELETE</strong>
            </CustomButton>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
DeleteApiConfigurationCarEngineModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  deletedUid: PropTypes.string.isRequired,
};
export default DeleteApiConfigurationCarEngineModal;
