import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { fetchDestinations } from "../../../api/CMS/Destinations";
import ControlStrip from "../../../components/ControlStrip";
import Loader from "../../../components/Loader";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../variables";
import Navbar from "./Navbar";
import _ from "lodash";
import { toast } from "react-toastify";
import { countries } from "../../../helpers";
import { useQuery } from "@tanstack/react-query";
import HamburgerMenu from "../../../components/HamburgerMenu";
import { genericRequestErrorHandler } from "../../../api";
import { useAuthentication, useListPageSidebar } from "../../../hooks";

const initialFilters = {
  limit: 20,
  page: 1,
  ordering: "",
  discending: "",
  country_code: "",
  status: "AC",
  created__gte: "",
  created__lte: "",
};

const destinationStyles = createUseStyles({
  Destination: genericIndexesStyles.indexes.mobile,
  tableContainer: genericIndexesStyles.tableContainer.mobile,
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: { ...tableStyles.body.cell },
  actionCell: {
    ...genericIndexesStyles.actionCell.mobile,
  },
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: genericIndexesStyles.actionCell.smallscreen(),
    cell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});
const Destinations = () => {
  const classes = destinationStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);

  const tableHeaders = {
    Id: (val) => val.id,
    "Name en": (val) => _.capitalize(val.name_en),
    Destination: (val) => _.get(val, "label_array", []).join(",  "),
    Code: (val) => val.code,
    "Country Code": (val) => _.get(countries, _.get(val, "country_code")),
    Classification: (val) => val.classification_display,
    Lng: (val) => _.get(val, "geodata.lng", ""),
    Lat: (val) => _.get(val, "geodata.lat", ""),
    Status: (val) => (val.status ? "Active" : "N/A"),
  };

  const { data, isLoading } = useQuery(
    ["destinations", filters],
    async () => {
      return fetchDestinations({
        params: {
          ...filters,
          page_size: filters.limit,
          page_count: filters.count,
        },
      });
    },
    {
      enabled: filters.country_code !== "",
      onError: (error) => genericRequestErrorHandler(error),
    }
  );

  const loading = isLoading && filters.country_code !== "";
  return (
    <div className={classes.Destination}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={(filters) => {
          if (!filters.country_code || !filters.status) {
            toast.error(`You must choose Country Code`);
          }
          setFilters(filters);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.limit}
        page={filters.page}
        count={_.get(data, "data.count", 0)}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                {Object.keys(tableHeaders).map((value, idx) => (
                  <th key={idx} className={classes.headCell}>
                    {value}{" "}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {_.get(data, "data.destinations", []).map((val, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.cell}>{idx + 1}</td>
                  {Object.entries(tableHeaders).map((entry, ridx) => {
                    var cell = null;
                    const fn = entry[1];
                    switch (entry[0].toLowerCase()) {
                      default:
                        cell = fn(val) || "N/A";
                        break;
                    }
                    return (
                      <td className={classes.cell} key={ridx}>
                        {cell}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Destinations;
