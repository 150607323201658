import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { cardStyles, tableStyles, variables } from "../../../../../variables";
import _ from "lodash";

const styles = createUseStyles({
  ...cardStyles,
  PaymentMethods: { ...cardStyles.card },
  header: {
    ...cardStyles.header,
    fontSize: "1.2rem",
    padding: variables.normal_gap,
    marginTop: "1px",
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  tableContainer: {
    display: "block",
    maxHeight: "20rem",
    overflow: "auto",
    width: "100%",
  },
  table: {
    width: "100%",
  },
  thead: {
    position: "sticky",
    top: 0,
  },
  emptyPayments: {
    color: "red",
    textAlign: "center",
    minHeight: "10rem",
    lineHeight: "10rem",
  },
});
const PaymentMethods = ({ paymentMethods }) => {
  const classes = styles();
  return (
    <div className={classes.PaymentMethods}>
      <div className={classes.header}>
        <span>Payment Methods</span>
      </div>
      <div className={classes.tableContainer}>
        {paymentMethods.length === 0 ? (
          <div className={classes.emptyPayments}>
            <strong>No Payment Methods</strong>
          </div>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Method Type</th>
                <th className={classes.headCell}>Brand</th>
                <th className={classes.headCell}>Country</th>
                <th className={classes.headCell}>Last 4</th>
                <th className={classes.headCell}>Exp Month</th>
                <th className={classes.headCell}>Exp Year</th>
                <th className={classes.headCell}>CVC Check</th>
                <th className={classes.headCell}>Name</th>
              </tr>
            </thead>
            <tbody>
              {paymentMethods.map((method, idx) => (
                <tr key={method.id}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  <td className={classes.tableCell}>
                    {_.get(method, "type")
                      ? _.startCase(_.get(method, "type"))
                      : "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(method, "brand")
                      ? _.startCase(_.get(method, "brand"))
                      : "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(method, "country")
                      ? _.upperCase(_.get(method, "country"))
                      : "N/A"}
                  </td>
                  <td className={classes.tableCell}>{method.last4 || "N/A"}</td>
                  <td className={classes.tableCell}>
                    {method.exp_month || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {method.exp_year || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(method, "cvc_check")
                      ? _.upperCase(_.get(method, "cvc_check"))
                      : "N/A"}
                  </td>
                  <td className={classes.tableCell}>{method.name || "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
PaymentMethods.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
};
export default PaymentMethods;
