import React, { useState } from "react";
import {
  GenericNavbarsStyles,
  inputGroup,
  variables,
} from "../../../../variables";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { onEnterDown } from "../../../../helpers";
import { statusMapping } from "../../common";
import EntityAutocomplete from "../../../../components/EntityAutocomplete";
import { networkSearchMapping } from "../../helpers";
import { CustomButton } from "../../../../components/CustomButton";

const marketType = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};

const navbarStyles = createUseStyles({
  Navbar: {
    ...GenericNavbarsStyles.navbars.mobile(),
    gridTemplateRows: "max-content max-content max-content max-content ",
  },
  label: { ...inputGroup },
  divider: GenericNavbarsStyles.divider.mobile(),
  filters: GenericNavbarsStyles.filters.mobile(),
  actions: GenericNavbarsStyles.actions.mobile(),
  inputGroup: {
    ...inputGroup,
    width: "100%",
    "& input, select": {
      ...inputGroup["& input, select"],
      width: "100%",
    },
  },
  inputGroupOneLine: {
    ...inputGroup,
    gridColumn: "2 span",
    width: "100%",
  },
  [`@media ${variables.media.smallscreen}`]: {
    Navbar: {
      ...GenericNavbarsStyles.navbars.smallscreen(),
      gridTemplateColumns: "repeat(2, max-content) 1fr max-content",
      gridTemplateRows: "max-content",
    },
    divider: GenericNavbarsStyles.divider.smallscreen(),
    filters: {
      ...GenericNavbarsStyles.filters.smallscreen(),
      gridTemplateColumns: "repeat(8, 1fr)",
    },
    actions: GenericNavbarsStyles.actions.smallscreen(),
    inputGroup: {
      ...inputGroup,
      width: "100%",
    },
    inputGroupOneLine: { ...inputGroup.bigscreen, gridColumn: "1 span" },
  },
  [`@media ${variables.media.retina}`]: {
    filters: {
      ...GenericNavbarsStyles.filters.smallscreen(),
      gridTemplateColumns: "repeat(6, 1fr)",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    filters: {
      gridTemplateColumns: "repeat(11, 1fr)",
    },
  },
});

const Navbar = ({ filters, initialFilters, onApplyFilters }) => {
  const classes = navbarStyles();

  const [localFilters, setLocalFilters] = useState(filters);
  const [autocompleteValue, setAutocompleteValue] = useState("");

  return (
    <div className={classes.Navbar}>
      <div className={classes.label}>
        <b>General Services</b>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.filters}>
        <div className={classes.inputGroupOneLine}>
          <label>Ref.</label>
          <input
            id="filterReference"
            type="text"
            value={localFilters.reference}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                reference: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>External Reference</label>
          <input
            id="filterExternalReference"
            type="text"
            value={localFilters.external_reference__icontains}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                external_reference__icontains: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Source Entity</label>
          <EntityAutocomplete
            id="filterEntityAutocompleteId"
            independentAgents={false}
            branches={false}
            value={autocompleteValue}
            setValue={setAutocompleteValue}
            onChange={(text) => {
              if (text.length === 0) {
                setLocalFilters((p) => ({
                  ...p,
                  source_entity: "",
                }));
                return;
              }
              const entityInfos = text.split("---");
              const entityType = entityInfos[0];
              const entityId = entityInfos[1];
              setLocalFilters((p) => ({
                ...p,
                source_entity: `${entityId}___${entityType}`,
              }));
            }}
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Name</label>
          <input
            id="filterName"
            type="text"
            value={localFilters.name__icontains}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                name__icontains: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Destination</label>
          <input
            id="filterDestination"
            type="text"
            value={localFilters.destination_name__icontains}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                destination_name__icontains: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>

        <div className={classes.inputGroup}>
          <label>Market Type</label>
          <select
            id="filterMarketType"
            value={localFilters.market_type}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, market_type: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(marketType).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Network Search</label>
          <select
            id="filterNetworkSearch"
            value={localFilters.network_search}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, network_search: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(networkSearchMapping).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Created After</label>
          <input
            id="filterCreatedAfter"
            type="date"
            value={localFilters.created__gte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                created__gte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Created Before</label>
          <input
            id="filterCreatedBefore"
            type="date"
            value={localFilters.created__lte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                created__lte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Status</label>
          <select
            id="filterStatus"
            value={localFilters.status}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, status: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(statusMapping).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={classes.actions}>
        <CustomButton
          id="ResetFiltersButton"
          appearance="ghost"
          onClick={() => {
            setLocalFilters({
              ...initialFilters,
            });
            setAutocompleteValue("");
          }}>
          <strong>Reset</strong>
        </CustomButton>
        <CustomButton
          id="ApplyFiltersButton"
          appearance="primary"
          onClick={() => onApplyFilters(localFilters)}>
          <strong>Apply Filters</strong>
        </CustomButton>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  filters: PropTypes.object.isRequired,
  initialFilters: PropTypes.object.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
};
export default Navbar;
