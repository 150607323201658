import { DateTime } from "luxon";
import { toast } from "react-toastify";
import * as yup from "yup";
import _ from "lodash";

export const websiteLanguages = {
  en: "English",
  de: "German",
  el: "Greek",
  es: "Spanish",
  fr: "French",
  it: "Italian",
  ja: "Japanese",
  nl: "Dutch",
  pt: "Portuguese",
  th: "Thai",
  zh: "Chinese",
  lt: "Lithuanian",
};

export const countries = {
  "": "-----",
  AF: "Afghanistan",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  CV: "Cabo Verde",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CR: "Costa Rica",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Cyprus",
  CZ: "Czechia",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  SZ: "Eswatini",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran",
  IQ: "Iraq",
  IE: "Ireland",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KR: "Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Laos",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MK: "North Macedonia",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PF: "French Polynesia",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "Rwanda",
  BL: "Saint Barthélemy",
  WS: "Samoa",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States of America",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viet Nam",
  VG: "Virgin Islands (British)",
  VI: "Virgin Islands (U.S.)",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};

export const currencies = {
  "": "-----",
  USD: "USD",
  EUR: "EUR",
  AUD: "AUD",
  INR: "INR",
  NOK: "NOK",
  BRL: "BRL",
  CAD: "CAD",
  CLP: "CLP",
  CNY: "CNY",
  NZD: "NZD",
  DKK: "DKK",
  HKD: "HKD",
  ILS: "ILS",
  JPY: "JPY",
  KWD: "KWD",
  ZAR: "ZAR",
  CHF: "CHF",
  MYR: "MYR",
  MXN: "MXN",
  PHP: "PHP",
  PLN: "PLN",
  QAR: "QAR",
  RUB: "RUB",
  SAR: "SAR",
  SGD: "SGD",
  GBP: "GBP",
  SEK: "SEK",
  THB: "THB",
  TRY: "TRY",
  AED: "AED",
  TRV: "TRV",
};

/*
 * yup validation error handling
@param {error}: take the errors from yup validation error 
@returns {validationErrors}: return the errors in a object
if you want to use custom error messages, you need to pass them as a second argument or at the very component without import that function
 */

// export const handleValidationErrors = (error) => {
//   // if (error instanceof yup.ValidationError) {
//   // Collect all the validation errors into a single object
//   const validationErrors = {};
//   error.inner.forEach((err) => {
//     const fieldName = _.startCase(err.path.replace("_", " ")).replace(
//       /(\d+)/g,
//       (match, number) => parseInt(number) + 1
//     );
//     validationErrors[fieldName] = err.message;
//   });
//   // Construct an error message with specific field errors
//   const errorMessage =
//     "Form validation failed. Please check the following fields:";
//   const errorFields = Object.keys(validationErrors);
//   toast.error(
//     <>
//       <p>{errorMessage}</p>
//       <ul>
//         {errorFields.map((field, index) => (
//           <li key={index}>
//             {field}: {validationErrors[field]}
//           </li>
//         ))}
//       </ul>
//     </>
//   );
//   return validationErrors;
// };
export const handleValidationErrors = (error) => {
  if (error instanceof yup.ValidationError) {
    // Collect all the validation errors into a single object
    const validationErrors = {};
    error.inner.forEach((err) => {
      const fieldName = _.startCase(err.path.replace("_", " ")).replace(
        /(\d+)/g,
        (match, number) => parseInt(number) + 1
      );
      validationErrors[fieldName] = err.message;
    });
    // Construct an error message with specific field errors
    const errorMessage =
      "Form validation failed. Please check the following fields:";
    const errorFields = Object.keys(validationErrors);
    toast.error(
      <>
        <p>{errorMessage}</p>
        <ul>
          {errorFields.map((field, index) => (
            <li key={index}>
              {field}: {validationErrors[field]}
            </li>
          ))}
        </ul>
      </>
    );
    return validationErrors;
  }
  // Handle non-yup validation errors if needed
  return {};
};

/* Takes a date in str iso format and returns a DateTime.DATE_SHORT
 * @param {string} {date}: date ISO string
 * @returns {string}: DateTime.DATE_SHORT
 */
export function shortDater(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
}

/**
 * Takes a date in str iso format and returns a DateTime.DATETIME_SHORT
 * @param {string} {date}: date ISO string
 * @returns {string}: DateTime.DATETIME_SHORT
 */
export function shortDatetimer(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);
}

/**
 * Takes a date in str iso format and returns a DateTime.DATETIME_FULL
 * @param {string} {date}: date ISO string
 * @returns {string} DateTime.DATETIME_FULL
 */
export function fullDater(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL);
}

/**
 * Takes a datetime in str iso format and returns a
 *  DateTime.DATETIME_MED_WITH_WEEKDAY
 * @param {string} {date}: date ISO string
 * @returns {string} DateTime.DATETIME_MED_WITH_WEEKDAY
 */
export function medWeekdayDatetimer(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL);
}

/**
 * Takes a date in str iso format and returns a
 *  Date.DATE_MED_WITH_WEEKDAY
 * @param {string} {date}: date ISO string
 * @returns {string} DateTime.DATE_MED_WITH_WEEKDAY
 */
export function medWeekdayDate(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
}

export function onEnterDown(event, fn, payload) {
  if (event.keyCode !== 13) {
    return null;
  }
  return fn(payload);
}

export const handleApplyFilters = async (
  filters,
  setFilters,
  validationSchema
) => {
  try {
    const filteredFilters = Object.keys(filters).reduce((acc, key) => {
      if (filters[key] !== "") {
        acc[key] = filters[key];
      }
      return acc;
    }, {});

    await validationSchema.validate(filteredFilters, { abortEarly: false });
    setFilters(filteredFilters);
  } catch (error) {
    const validationErrors = {};
    error.inner.forEach((err) => {
      if (!validationErrors[err.path]) {
        validationErrors[err.path] = err.message;
        toast.error(err.message);
      }
    });
  }
};

/**
 * Takes true/false as strings ("true" / "false") and returns them as booleans
 * @param {string} {"true"/"false"}:  string
 * @returns {string} boolean
 */
// export const convertStringToBoolean = (data) => {
//   const boolFields = {};

//   Object.keys(data).forEach((key) => {
//     const value = data[key];

//     if (value === "true") {
//       boolFields[key] = true;
//     } else if (value === "false") {
//       boolFields[key] = false;
//     } else {
//       return;
//     }
//   });

//   return boolFields;
// };
export const convertStringToBoolean = (fields) => {
  const boolFields = {};
  Object.keys(fields).forEach((key) => {
    if (fields[key] === "true") {
      boolFields[key] = true;
    } else if (fields[key] === "false") {
      boolFields[key] = false;
    } else {
      boolFields[key] = fields[key];
    }
  });
  return boolFields;
};

/**
 * Takes the values of the numeric input fields, and returns them as numbers(3)
 * @param {array} {["3"]}:  array
 * @returns {string} [3]: number
 */
export const convertStringToNumber = (fields, numericFields) => {
  numericFields = numericFields || [];

  const convertedFields = { ...fields };

  numericFields.forEach((key) => {
    convertedFields[key] = Number(fields[key]);
  });

  return convertedFields;
};

/**
 * Formats an amount to a currency.
 * @param {number} amount The amount to be formatted.
 * @param {string} currency The currency to be formatted to.
 * @returns {string} The formatted amount.
 */
export function formatToCurrency(amount, currency) {
  if (!currency) {
    return `${currency}${amount}`;
  }

  return amount.toLocaleString("en-US", {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export const APIConfigsStatusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
};
