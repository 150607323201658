import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteAviateApiConfiguration } from "../../../../../api/FlightEngine/AviateApiConfiguration";
import { genericRequestErrorHandler } from "../../../../../api";
import { countries, currencies } from "../../../../../helpers";
import _ from "lodash";
import Loader from "../../../../../components/Loader";
import { flightEngineTrueFalseMapping } from "../../../common";
import { CustomButton } from "../../../../../components/CustomButton";

const deleteAviateModal = createUseStyles({
  ...modalGenericStyles,
  DeleteAviateModal: modalGenericStyles.modal,
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardBody: cardStyles.body,
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    gridTemplateColumns: "repeat(2, max-content)",
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCard: { minWidth: "50rem" },
  },
});

const DeleteAviateModal = ({ deletedUid, data, onClose }) => {
  const classes = deleteAviateModal();
  const queryClient = useQueryClient();

  const basicDataCpl = [
    ["Uid", "uid"],
    ["Name", null, _.capitalize(_.get(data, "name", ""))],
    ["Source Entity", "source_entity"],
    ["Market Type", "market_type"],
    ["Country", null, _.get(countries, _.get(data, "country_code"))],
    ["Currency", null, _.get(currencies, _.get(data, "currency"))],
    [
      "Is Global",
      null,
      _.get(flightEngineTrueFalseMapping, _.get(data, "is_global")),
    ],
    [
      "Is Own",
      null,
      _.get(flightEngineTrueFalseMapping, _.get(data, "is_own")),
    ],
    ["PCC", "pcc"],
    ["GDS", "gds"],
    ["Received From", null, _.capitalize(_.get(data, "received_from"))],
  ];

  const deleteAviateApiConfigurationMutation = useMutation(
    deleteAviateApiConfiguration,
    {
      onSuccess: () => {
        toast.success("Aviate Api configuration Deleted");
        queryClient.invalidateQueries("AviateAPIConfigurations");
        onClose();
      },
      onError: (error) => genericRequestErrorHandler(error),
    }
  );
  const onDelete = async (deletedUid) => {
    deleteAviateApiConfigurationMutation.mutate({ uid: deletedUid });
  };
  return (
    <div id="DeleteAviateFlModal" className={classes.DeleteAviateModal}>
      <React.Fragment>
        <div className={classes.modalCard}>
          {deleteAviateApiConfigurationMutation.isLoading && (
            <Loader onTop={true} />
          )}
          <div className={classes.modalCardHeader}>
            <span>DELETE Aviate Api Configuration</span>
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.card}>
              <div className={classes.cardBody}>
                <GenericBasicInfo
                  header={"Are you sure you want to delete this?"}
                  cardInfo={basicDataCpl}
                  objectData={data}
                />
              </div>
            </div>
          </div>
          <div className={classes.cardActions}>
            <CustomButton
              id="DeleteAviateFlModalCloseButton"
              appearance="ghost"
              onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="DeleteAviateFlModalConfirmButton"
              appearance="primary"
              onClick={() => {
                onDelete(deletedUid);
              }}>
              <strong>DELETE</strong>
            </CustomButton>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
DeleteAviateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  deletedUid: PropTypes.string.isRequired,
};
export default DeleteAviateModal;
