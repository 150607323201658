import React from "react";
import Logs from "../../../../../components/Logs";
import { VALAQUENTA_API_URL } from "../../../../../api";

const TRALogsTab = (WrappedComponent) => {
  const Comp = (props) => {
    const actionsMapping = {
      edit: {
        label: "Edit",
        url: `${VALAQUENTA_API_URL}/crs/internal/reservations/train/`,
        method: "PATCH",
      },
      //SAME FOR ALL CRS
      sendResEmail: {
        label: "Send Reservation Email",
        url: `${VALAQUENTA_API_URL}/crs/internal/reservations/send-email/`,
        method: "POST",
      },
      addDocument: {
        label: "Add Document",
        url: `${VALAQUENTA_API_URL}/reviewer/entity/document/`,
        method: "POST",
      },
      deleteDocument: {
        label: "Delete Document",
        url: `${VALAQUENTA_API_URL}/reviewer/entity/document/`,
        method: "DELETE",
      },
    };

    return <WrappedComponent {...props} actionsMapping={actionsMapping} />;
  };
  return Comp;
};

export default TRALogsTab(Logs);
