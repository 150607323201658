import React from "react";
import { createUseStyles } from "react-jss";
import { modalGenericStyles, variables } from "../../../../../variables";
import PropTypes from "prop-types";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { genericRequestErrorHandler } from "../../../../../api";
import { patchMappingPrimaryHotels } from "../../../../../api/accommodator/Mapper";
import Loader from "../../../../../components/Loader";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  PrimaryHotelModal: {
    ...modalGenericStyles.modal,
  },
  cardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    alignContent: "center",
  },
  [`@media ${variables.media.smallscreen}`]: {
    card: {
      ...modalGenericStyles.card,
      minWidth: "40rem",
      minHeight: "5rem",
    },
    cardBody: {
      minWidth: "5rem",
      minHeight: "5rem",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    card: {
      minWidth: "40rem",
      minHeight: "5rem",
    },
  },
});
const PrimaryHotelModal = ({ primary, pk, onClose }) => {
  const classes = styles();

  const mutation = useMutation({
    mutationFn: ({ hotelPk, payload }) => {
      return patchMappingPrimaryHotels({
        hotelPk,
        payload,
      });
    },
    onSuccess: () => {
      toast.success(
        primary === false ? "Hotel set as primary" : "Hotel set as not primary"
      );
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  return (
    <div className={classes.PrimaryHotelModal}>
      <div className={classes.card}>
        {mutation.isLoading && <Loader onTop={true} />}
        <div className={classes.cardHeader}>
          <span>Set primary hotel</span>
        </div>
        <div className={classes.cardBody}>
          Are you sure you want to make this hotel Primary?
        </div>
        <div className={classes.cardActions}>
          <CustomButton appearance="ghost" onClick={() => onClose()}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            appearance="primary"
            onClick={() => {
              mutation.mutate({
                hotelPk: pk,
                payload: { primary: !primary },
              });
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
PrimaryHotelModal.propTypes = {
  message: PropTypes.string.isRequired,
  primary: PropTypes.bool.isRequired,
  pk: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default PrimaryHotelModal;
