import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../../../components/Loader";
import { variables } from "../../../../../variables";
import { getAccommodationReservationStats } from "../../../../../api/CRS/Accommodations";
import { DateTime } from "luxon";
import { getTransferReservationStats } from "../../../../../api/CRS/Transfers";
import { getFlightReservationStats } from "../../../../../api/CRS/Flights";
import { getTrainApiReservationStats } from "../../../../../api/CRS/ApiTrains";

const titleMapping = {
  entities_per_month: {
    ACC: "Accommodations Reservations Entities Per Month ",
    FL: "Flights Reservations Entities Per Month",
    TRF: "Transfers Reservations Entities Per Month",
    TRA: "Trains Reservations Entities Per Month",
  },
};

const tooltipLabelMapping = {
  entities_per_month: "Entities",
};

const srvMapping = {
  FER: "Ferries",
  ACC: "Accommodations",
  RES: "Restaurants",
  ACT: "Activities",
  TRA: "Trains",
};

const chartStyles = createUseStyles({
  BarChart: {
    display: "grid",
    padding: variables.normal_gap,
    width: "50vw",
    height: "30vh",
  },
  title: {
    display: "grid",
    alignContent: "end",
    fontWeight: "bold",
  },
  chart: {
    display: "grid",
    padding: variables.normal_gap,
    width: "50vw",
    height: "27vh",
  },
  [`@media ${variables.media.smallscreen}`]: {},
});
const BarChart = ({
  serviceType,
  chartType,
  currency,
  color,
  localFilters,
}) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const classes = chartStyles();
  const chartRef = useRef(null);

  const funcMapping = {
    ACC: getAccommodationReservationStats,
    FL: getFlightReservationStats,
    TRF: getTransferReservationStats,
    TRA: getTrainApiReservationStats,
  };

  const { data, isLoading } = useQuery({
    queryKey: [`${serviceType}_Chart`, serviceType, localFilters],
    queryFn: async () => {
      var payload = { ...localFilters };
      if (payload.created__gte && ["ACC", "TRA"].includes(serviceType)) {
        payload.created__gte = DateTime.fromISO(payload.created__gte)
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .toISO({ includeOffset: false, suppressMilliseconds: true });

        if (payload.created__lte && ["ACC", "TRA"].includes(serviceType)) {
          payload.created__lte = DateTime.fromISO(payload.created__lte)
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 0,
            })
            .toISO({ includeOffset: false, suppressMilliseconds: true });
        }
      }
      return await funcMapping[serviceType]({ params: { ...payload } });
    },
  });

  const createChartInstance = (data) => {
    const chartInstance = echarts.init(chartRef.current);
    const ent_per_month = _.get(
      data,
      `data.stats_per_currency.${currency}.${chartType}`,
      []
    );
    const finalData = ent_per_month.map(([xAxis, yAxis]) => {
      return {
        name: DateTime.fromISO(xAxis).toLocaleString(
          DateTime.DATE_MED_WITH_WEEKDAY
        ),
        value: [new Date(xAxis).getTime(), yAxis],
      };
    });
    const options = {
      title: {
        text: _.get(titleMapping, `${chartType}.${serviceType}`),
        top: "5%",
        left: "center",
        textStyle: {
          fontWeight: "bold",
          fontSize: windowSize >= 1024 ? 16 : 5,
        },
      },

      tooltip: {
        trigger: "axis",
        position: function (pt) {
          return [pt[0], "10%"];
        },
        formatter: (params) => {
          const name = params[0].name;
          var value = params[0].value[1];

          return `${name}<br/><strong>${value} ${tooltipLabelMapping[chartType]}</strong>`;
        },
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },

      series: [
        {
          name: `${srvMapping[serviceType]} Reservations`,
          type: "bar",
          areaStyle: { color },
          data: finalData,
          itemStyle: {
            color: color,
          },
        },
      ],
    };

    chartInstance.setOption(options);
    return chartInstance;
  };

  const handleResize = () => setWindowSize(window.innerWidth);
  window.removeEventListener("resize", handleResize);

  useEffect(() => {
    if (isLoading) return;
    createChartInstance(data);
    window.addEventListener("resize", handleResize);
  }, [isLoading, data, currency]);

  useEffect(() => {
    const existingChart = echarts.getInstanceByDom(chartRef.current);
    if (existingChart) {
      existingChart.resize();
    }
  }, [windowSize]);

  return (
    <React.Fragment>
      {isLoading && <Loader onTop={true} />}
      <div ref={chartRef} className={classes.BarChart} />
    </React.Fragment>
  );
};
BarChart.propTypes = {
  chartType: PropTypes.oneOf(["entities_per_month", "aa"]).isRequired,
  currency: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  localFilters: PropTypes.object,
};

export default BarChart;
