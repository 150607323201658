import React from "react";
import Logs from "../../../../../components/Logs";
import { VALAQUENTA_API_URL } from "../../../../../api";

const SubAgentLogsTab = (WrappedComponent) => {
  const Comp = (props) => {
    const actionsMapping = {
      add: {
        label: "Add",
        url: `${VALAQUENTA_API_URL}/crm/subagents/`,
        method: "POST",
      },
      edit: {
        label: "Edit",
        url: `${VALAQUENTA_API_URL}/crm/subagents/`,
        method: "PATCH",
      },
      editCRMComments: {
        label: "Edit Comments",
        url: `${VALAQUENTA_API_URL}/reviewer/entity/review/`,
        method: "PATCH",
      },
      addCRMComments: {
        label: "Add Comments",
        url: `${VALAQUENTA_API_URL}/reviewer/entity/review/`,
        method: "POST",
      },
      addDocument: {
        label: "Add Document",
        url: `${VALAQUENTA_API_URL}/reviewer/entity/document/`,
        method: "POST",
      },
      DeleteDocument: {
        label: "Delete Document",
        url: `${VALAQUENTA_API_URL}/reviewer/entity/document/`,
        method: "DELETE",
      },
      addDepositStatment: {
        label: "Add Deposit Statement",
        url: `${VALAQUENTA_API_URL}/paymentengine/accounts/sub-entity-deposit-ledger/`,
        method: "POST",
      },
    };

    return <WrappedComponent {...props} actionsMapping={actionsMapping} />;
  };
  return Comp;
};

export default SubAgentLogsTab(Logs);
