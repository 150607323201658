import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchBranches({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/branches/`, {
    params,
  });
}

export function retrieveBranch({ id }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/branches/${id}/`);
}

export function patchBranch({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crm/branches/${id}/`,
    payload
  );
}

export function createBranch({ payload }) {
  return loggingAxios.post(`${VALAQUENTA_API_URL}/crm/branches/`, payload);
}

// export function getBranchCreditStatment({ params }) {
//   return authorizedAxios.get(
//     `${VALAQUENTA_API_URL}/paymentengine/accounts/sub-entity-deposit-ledger/`,
//     {
//       params,
//     }
//   );
// }

// export function createBranchCreditStatment({ payload }) {
//   return loggingAxios.post(
//     `${VALAQUENTA_API_URL}/paymentengine/accounts/sub-entity-deposit-ledger/`,
//     payload
//   );
// }
export function getRefactoredBranchCreditStatment({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/paymentengine/subentity-deposit-ledger-entry/`,
    {
      params,
    }
  );
}

export function createRefactoredBranchCreditStatment({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/paymentengine/subentity-deposit-ledger-entry/`,
    payload
  );
}

export function getBranchPoformaStatment({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/paymentengine/proforma-subentity-ledger-entry/ `,
    {
      params,
    }
  );
}
export function createBranchPoformaStatment({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/paymentengine/proforma-subentity-ledger-entry/ `,
    payload
  );
}
