import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { variables } from "../variables";
import _, { get } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../api";
import { fetchDestinationsAutocomplete } from "../api/CMS/Destinations";

const getDestinationName = (destination) => {
  const name = _.startCase(_.get(destination, "name_en", ""));
  const type = _.get(destination, "type", "");
  const country = _.startCase(_.get(destination, "country_name_en", ""));
  const firstLvl = _.startCase(_.get(destination, "first_lvl_name_en", ""));
  const secondLvl = _.startCase(_.get(destination, "second_lvl_name_en", ""));
  const thirdLvl = _.startCase(_.get(destination, "third_lvl_name_en", ""));
  switch (type) {
    case "lvl1": {
      return `${name}-${country}`;
    }
    case "lvl2": {
      return `${name}-${country}-${firstLvl}`;
    }
    case "lvl3": {
      return `${name}-${country}-${firstLvl}-${secondLvl}`;
    }
    case "lvl4": {
      return `${name}-${country}-${firstLvl}-${secondLvl}-${thirdLvl}`;
    }
    default: {
      return `${name}-${country}`;
    }
  }
};

const styles = createUseStyles({
  DestinationsAutocomplete: {
    display: "grid",
    position: "relative",
  },
  input: {},
  suggestions: {
    display: "grid",
    position: "absolute",
    top: "100%", // Position suggestions below the input
    overflow: "auto",
    maxHeight: "25vh",
    justifyContent: "center",
    cursor: "pointer",
    border: "1px solid black",
  },
  suggestion: {
    cursor: "pointer",
    border: "1px solid rgba(0,0,0,0.5)",
    background: "white",
    padding: variables.half_gap,
    textAlign: "left",
    "&:hover": {
      backgroundColor: "grey",
      color: "white",
    },
  },
});
const DestinationsAutocomplete = ({ value, setValue, onChange }) => {
  const classes = styles();

  const [suggestions, setSuggestions] = useState([]);

  const autocompletQuery = useQuery({
    queryKey: ["DestinationsAutocomplete", value],
    queryFn: () =>
      fetchDestinationsAutocomplete({
        params: { name_en: value },
      }),
    onSuccess: (destinations) =>
      setSuggestions(destinations, "data.results", []),
    onError: (error) => genericRequestErrorHandler(error),
    enabled: value.length > 2 && value.includes("-") === false,
    refetchOnWindowFocus: false,
  });

  const onSuggestSelect = (destination) => {
    onChange(
      `${destination.id}___${destination.type}___${destination.name_en}___${destination.country_name_en}`
    );
    setValue(getDestinationName(destination));
    setSuggestions([]);
  };

  return (
    <div className={classes.DestinationsAutocomplete}>
      <input
        type="text"
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
        onBlur={() => setTimeout(() => setSuggestions([]), 100)}
      />
      {_.flatten(_.get(suggestions, "data.results", [])).length > 0 && (
        <div className={classes.suggestions}>
          {_.get(suggestions, "data.results", []).map((dest, idx) => (
            <div
              key={idx}
              className={classes.suggestion}
              onClick={() => onSuggestSelect(dest)}>
              {getDestinationName(dest)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
DestinationsAutocomplete.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default DestinationsAutocomplete;
