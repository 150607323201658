import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../../variables";
import { toast } from "react-toastify";
import Loader from "../../../../../../components/Loader";
import GenericBasicInfo from "../../../../../../components/cards/GenericBasicInfo";
import { genericRequestErrorHandler } from "../../../../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { deleteVendorAccomodation } from "../../../../../../api/Vendor/Accommodation";
import { CustomButton } from "../../../../../../components/CustomButton";
import { useAuthentication } from "../../../../../../hooks";

const statusOptions = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteAccommodationVendorModal: modalGenericStyles.modal,
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
});

const DeleteAccommodationVendorModal = ({ deletedId, data, onClose }) => {
  const classes = styles();
  const queryClient = useQueryClient();
  const { getJWT } = useAuthentication();

  const VendorDataCpl = [
    ["Vendor", null, _.get(data, "vendor", "").split("acc__")[1] || "N/A"],
    [" Vendor username", null, _.get(data, "vendor_data.username", "")],
    [" Vendor password", null, _.get(data, "vendor_data.password")],
    [" Vendor Currency", null, _.get(data, "vendor_data.currency")],
  ];

  const basicDataCpl = [
    ["Id", "id"],
    ["Member", "member"],
    ["Market Type", "market_type"],
    ["Notes", "notes"],
    ["CXL Strategy Time", "cxl_strategy_time"],
    [
      "CXL Strategy Daysahead",
      null,
      _.get(data, "cxl_strategy_daysahead", []) === 0
        ? "0"
        : _.get(data, "data.cxl_strategy_daysahead"),
    ],
    ["Status", null, _.get(statusOptions, _.get(data, "status", "") || "N/A")],
  ];
  const DeleteAccVendorMutation = useMutation({
    mutationFn: deleteVendorAccomodation,
    onSuccess: () => {
      toast.success("Accommodation Vendor Deleted");
      queryClient.invalidateQueries("AccommodationVendors");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });
  const onDelete = (id) => {
    DeleteAccVendorMutation.mutate({ id });
  };
  return (
    <div
      className={classes.DeleteAccommodationVendorModal}
      id="DeleteAccommodationVendorModal">
      <div className={classes.modalCard}>
        {DeleteAccVendorMutation.isLoading && <Loader onTop={true} />}
        <div className={classes.modalCardHeader}>
          <span>DELETE Accommodation Vendor</span>
        </div>
        <div className={classes.modalCardBody}>
          <div className={classes.card}>
            <div className={classes.cardBody}>
              <GenericBasicInfo
                header={"Basic Data"}
                cardInfo={basicDataCpl}
                objectData={data}
              />
            </div>
          </div>
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton
            id="DeleteAccommodationVendorModalCloseButton"
            appearance="ghost"
            onClick={onClose}>
            <strong>Close</strong>{" "}
          </CustomButton>
          <CustomButton
            id="DeleteAccommodationVendorModalConfirmButton"
            appearance="primary"
            onClick={() => {
              onDelete(deletedId);
            }}>
            <strong>Delete</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DeleteAccommodationVendorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  deletedId: PropTypes.number.isRequired,
};
export default DeleteAccommodationVendorModal;
