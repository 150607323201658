import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  formSections,
  modalGenericStyles,
  variables,
} from "../../../../../../variables";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Loader from "../../../../../../components/Loader";
import _ from "lodash";
import * as yup from "yup";
import BasicInformationForm from "../../MasterPoliciesComponents/BasicInformationForm";
import Member from "../../MasterPoliciesComponents/Member";
import {
  patchMasterPolicy,
  retrieveMasterPolicy,
} from "../../../../../../api/CRM/MasterPolicies";
import NotesandStatus from "../../MasterPoliciesComponents/NotesandStatus";
import TravelServices, {
  prepareTargetServices,
  prepareTravelServicesPayload,
} from "../../MasterPoliciesComponents/TravelServices";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  genericFormRequestError,
  genericRequestErrorHandler,
} from "../../../../../../api";
import { handleValidationErrors } from "../../../../../../helpers";
import { CustomButton } from "../../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .transform((value) => (value === "" ? undefined : value))
    .min(2, "Must be at least 2 characters")
    .max(100, "Must be less than 100 characters"),
  pax_from: yup.number(),
  pax_to: yup.number(),
  description: yup.string(),
});

const initialForm = {
  name: "",
  pax_from: 0,
  pax_to: 0,
  rounding_digits: "",
  service_execution_to: "",
  service_execution_from: "",
  booking_window_from: "",
  booking_window_to: "",
  members: [],
  description: "",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  MasterPoliciesEditModal: {
    ...modalGenericStyles.modal,
    overflow: "auto",
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content  1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    justifyContent: "center",
    overflowX: "hidden",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  inputGroup: formInputGroup,

  descriptionCardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    display: "grid",
    padding: "",
    paddingBottom: variables.normal_gap,
  },
  description: {
    gap: variables.normal_gap,
    "& label": {
      fontSize: "small",
    },
  },
  sectionHeader: formSections.sectionHeader,
  cardActions: { ...cardStyles.actions, gridColumn: "2 span" },
  [`@media ${variables.media.bigscreen}`]: {
    cardBody: {
      // gridAutoFlow: "",
      gridTemplateColumns: "1fr 1fr",
    },
  },
});

const MasterPoliciesEditModal = ({
  masterPolicyId,
  travelServices,
  onClose,
}) => {
  const classes = styles();
  // const mode = masterPolicyId ? "EDIT" : "ADD";

  const [form, setForm] = useState(initialForm);
  const [sourceEntityArea, setSourceEntityArea] = useState([]);
  const [preparedTravelServices, setPreparedTravelServices] = useState({});

  const preparePayload = () => {
    const members = sourceEntityArea.map((member) =>
      Number(member.split("___")[0])
    );
    const { travel_services, travel_services_policies } =
      prepareTravelServicesPayload(preparedTravelServices);
    let payload = {
      ...form,
      travel_services,
      travel_services_policies,
      members,
    };
    return payload;
  };

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["RetrieveMasterPolicyEdit", masterPolicyId],
    queryFn: () => retrieveMasterPolicy({ id: masterPolicyId }),

    onSuccess: (data) => {
      const members = _.get(data, "data.members").map(
        (member) => `${member.id}___${member.name}`
      );
      setForm(_.get(data, "data", {}));
      setSourceEntityArea(members);
      setPreparedTravelServices(prepareTargetServices(data, travelServices));
    },
    onError: (error) => {
      genericFormRequestError(error);
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ payload, id }) => {
      return patchMasterPolicy({
        id,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("Master Policy Info Updated");
      queryClient.invalidateQueries("MasterPolicies");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ id, payload }) => {
    try {
      const preparedPayload = preparePayload(payload);
      if (!preparedPayload) {
        // in case of error
        return;
      }
      await jsonSchema.validate(preparedPayload, { abortEarly: false });
      editMutation.mutate({ payload, id });
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const loading = retrieveLoading || editMutation.isLoading;

  return (
    <div className={classes.MasterPoliciesEditModal}>
      <div className={classes.modalCard}>
        {loading && !_.isEmpty(preparedTravelServices) ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className={classes.modalCardHeader}>
              <span>Edit Master Policy</span>
            </div>
            <div className={classes.modalCardBody}>
              <form
                onChange={(e) =>
                  setForm((p) => ({
                    ...p,
                    [e.target.name]: e.target.value,
                  }))
                }>
                <div className={classes.card}>
                  <div className={classes.cardBody}>
                    <BasicInformationForm
                      text="Basic Information"
                      masterPolicyId={masterPolicyId}
                      setForm={setForm}
                      form={form}
                      onClose={onClose}
                    />
                    <Member
                      text="Member"
                      masterPolicyId={masterPolicyId}
                      setForm={setForm}
                      form={form}
                      sourceEntityArea={sourceEntityArea}
                      setSourceEntityArea={setSourceEntityArea}
                      onClose={onClose}
                    />
                    <TravelServices
                      preparedTravelServices={preparedTravelServices}
                      setPreparedTravelServices={setPreparedTravelServices}
                    />
                    <NotesandStatus
                      masterPolicyId={masterPolicyId}
                      text="Notes And Status"
                      form={form}
                      setForm={setForm}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className={classes.modalCardActions}>
              <CustomButton appearance="ghost" onClick={onClose}>
                <strong>Cancel</strong>
              </CustomButton>
              <CustomButton
                appearance="primary"
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit({
                    id: masterPolicyId,
                    payload: form,
                  });
                }}>
                <strong>Submit</strong>
              </CustomButton>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
MasterPoliciesEditModal.propTypes = {
  masterPolicyId: PropTypes.number,
  travelServices: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default MasterPoliciesEditModal;
