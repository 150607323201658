import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { cardStyles, tableStyles } from "../../../variables";
import PropTypes from "prop-types";
import Loader from "../../Loader";
import { getCRMComments } from "../../../api/CRM/Members/Members";
import EditCRMComment from "../../../pages/CRM/CommonCRMModals/EditComments/index";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../api";
import _ from "lodash";
import { CustomButton } from "../../CustomButton";

const commentsStyles = createUseStyles({
  ...cardStyles,
  CommentsCard: { ...cardStyles.card },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  body: { ...tableStyles.body, display: "grid" },
  tableCell: tableStyles.body.cell,
  cardActions: { ...cardStyles.actions, gridColumn: "2 span", width: "97%" },
});

export const CommentsCard = ({ title, id, entityType }) => {
  const classes = commentsStyles();
  const [editingId, setEditingId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const { data: comments, isLoading } = useQuery({
    queryKey: ["CommentsCard", id],
    queryFn: async () =>
      await getCRMComments({
        params: {
          source_entity: `${id}___${entityType}`,
          ordering: "-created",
        },
        onError: (error) => genericRequestErrorHandler(error),
      }),
  });

  return (
    <div id="CommentsCard" className={classes.CommentsCard}>
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <React.Fragment>
            <div className={classes.header}>
              <span>{title}</span>
              <CustomButton
                id="addCommentButton"
                appearance="ghost"
                onClick={() => {
                  setEditingId(null);
                  setShowEditModal(true);
                }}>
                <strong>Add</strong>
              </CustomButton>
            </div>
            <div className={classes.body}>
              <table>
                <thead>
                  <tr className={classes.headRow}>
                    <th className={classes.headCell}>#</th>
                    <th className={classes.headCell}>Title</th>
                    <th className={classes.headCell}>Description</th>
                    <th className={classes.headCell}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {_.get(comments, "data", []).map((com, idx) => (
                    <tr key={idx}>
                      <td className={classes.tableCell}>{idx + 1}</td>
                      <td className={classes.tableCell}>
                        {com.title || "N/A"}
                      </td>
                      <td className={classes.tableCell}>{com.text || "N/A"}</td>
                      <td className={classes.tableCell}>
                        <CustomButton
                          id={`editCommentButton_${idx}`}
                          appearance="ghost"
                          onClick={() => {
                            setEditingId(com.id);
                            setShowEditModal(true);
                          }}>
                          <strong>Edit</strong>
                        </CustomButton>
                        {/* <button
                            onClick={() => {
                              setEditingId(com.id);
                              setShowEditModal(true);
                            }}
                          >
                            Edit
                          </button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        </React.Fragment>
      )}
      {showEditModal && (
        <EditCRMComment
          entityId={id}
          onClose={() => {
            setEditingId(null);
            setShowEditModal(false);
            // getComments();
          }}
          id={editingId}
          entityType={entityType}
        />
      )}
    </div>
  );
};

CommentsCard.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  // source_entity: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
};
