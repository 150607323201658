import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  modalGenericStyles,
  variables,
  formInputGroup,
} from "../../../../../variables";
import Loader from "../../../../../components/Loader/index";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { genericRequestErrorHandler } from "../../../../../api";
import { fetchWebsites } from "../../../../../api/DistributionSite/webSites";
import { toast } from "react-toastify";
import { cloneWebSiteHotelPriority } from "../../../../../api/DistributionSite/webSites/HotelPriorities";
import { handleValidationErrors } from "../../../../../helpers";
import * as yup from "yup";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  targetedApiKey: yup.string().required("Hotel is required").default(""),
});

const styles = createUseStyles({
  ...modalGenericStyles,
  ImportModal: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  card: {
    ...modalGenericStyles.card,
  },
  selectBox: {
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    justifyContent: "center",
    gap: variables.normal_gap,
    gridColumn: "2 span",
  },
  label: {
    display: "grid",
    justifyContent: "left",
  },
  inputGroupOneLine: {
    ...formInputGroup,
    gridColumn: "2 span",
    textAlign: "center",
    "& input, select": { width: "100%" },
  },
  [`@media ${variables.media.smallscreen}`]: {
    card: {
      width: "20rem",
    },
  },
});
const ImportModal = ({ source_entity, websiteApiKey, onClose }) => {
  const classes = styles();

  const queryClient = useQueryClient();

  const [targetedApiKey, setTargetedApiKey] = useState("");

  const { data, isLoading } = useQuery({
    queryKey: ["retrieveDistributionSites", source_entity],
    queryFn: async () => await fetchWebsites({ params: { source_entity } }),
    onError: (error) => genericRequestErrorHandler(error),
  });

  const cloneHotelMutation = useMutation({
    mutationFn: async ({ payload }) =>
      await cloneWebSiteHotelPriority({ payload }),
    onSuccess: () => {
      toast.success("Import Success");
      queryClient.invalidateQueries("HotelPrioritiesList");
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });
  const onClone = async ({ payload }) => {
    try {
      await jsonSchema.validate(payload, { abortEarly: false });
      return cloneHotelMutation.mutate({ payload });
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const loading = _.isEmpty(data);

  const filteredHotels = _.get(data, "data.results", []).filter(
    ({ api_key }) => api_key !== websiteApiKey
  );

  return (
    <div className={classes.ImportModal}>
      <div className={classes.card}>
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className={classes.cardHeader}>
              <h6>Choose a hotel to import its data</h6>
            </div>
            <div className={classes.cardBody}>
              <div className={classes.inputGroupOneLine}>
                <div className={classes.label}>
                  <label>Hotel</label>
                </div>
                <select onChange={(e) => setTargetedApiKey(e.target.value)}>
                  <option value="">-----</option>
                  {filteredHotels.map(({ value, name }, idx) => (
                    <option key={idx} value={value}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={classes.cardActions}>
              <CustomButton appearance="ghost" onClick={onClose}>
                <strong>Cancel</strong>
              </CustomButton>
              <CustomButton
                appearance="primary"
                onClick={() =>
                  onClone({
                    payload: {
                      source_entity: source_entity,
                      source_website_api_key: websiteApiKey,
                      target_website_api_key: targetedApiKey,
                    },
                  })
                }>
                <strong>Submit</strong>
              </CustomButton>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
ImportModal.propTypes = {
  websiteApiKey: PropTypes.string.isRequired,
  source_entity: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default ImportModal;
