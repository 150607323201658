import React from "react";
import _ from "lodash";
import { tableStyles, variables, cardStyles } from "../../../../variables";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";

const groupsTableStyles = createUseStyles({
  ...cardStyles,
  CRMGroupsTable: {
    ...cardStyles.card,
    width: "100%",
  },
  header: { ...cardStyles.header, width: "100%" },
  tableContainer: {
    display: "grid",
    justifyContent: "center",
    maxHeight: "10rem",
    padding: variables.normal_gap,
    overflowY: "auto",
    overflowX: "hidden",
  },
  noGroupsContainer: {
    display: "grid",
    gridColumn: "2 span",
  },
  table: { ...tableStyles.table, overflow: "hidden" },
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
});

const CRMGroupsTable = ({ data }) => {
  const classes = groupsTableStyles();

  return (
    <div className={classes.CRMGroupsTable}>
      <div className={classes.header}>Groups</div>
      <div className={classes.tableContainer}>
        <div className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              <th className={classes.headCell}>Group Type</th>
            </tr>
          </thead>
          <tbody>
            {_.isEmpty(data) ? (
              <tr className={classes.bodyRow}>
                <td className={classes.tableCell} colSpan="4">
                  <div className={classes.noGroupsContainer}>
                    There are no groups
                  </div>
                </td>
              </tr>
            ) : (
              data.map((dt, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  <td className={classes.tableCell}>{dt || "N/A"}</td>
                </tr>
              ))
            )}
          </tbody>
        </div>
      </div>
    </div>
  );
};
CRMGroupsTable.propTypes = {
  data: PropTypes.array.isRequired,
};
export default CRMGroupsTable;
