import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cloneAccommodator,
  fetchAccommodatorAPIConfigurations,
} from "../../../api/AccEngine/APIConfigurations";
import ControlStrip from "../../../components/ControlStrip";
import Loader from "../../../components/Loader";
import {
  currencies,
  handleApplyFilters,
  medWeekdayDatetimer,
} from "../../../helpers";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../variables";
import Navbar from "./Navbar";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../api";
import { useListPageSidebar } from "../../../hooks";
import * as yup from "yup";
import { toast } from "react-toastify";
import DeleteAccApiConfigModal from "../AccEngineAPIConfigurationModals/DeleteAccEngineAPIConfigurationModal";
import EditAccEngineAPIConfigurationModal from "../AccEngineAPIConfigurationModals/EditAccEngineAPIConfigurationModal";
import DetailAccEngineAPIConfigurationModal from "../AccEngineAPIConfigurationModals/DetailAccEngineAPIConfigurationModal";
import { AccEnginecommonTrueFalseMapping } from "../Common";
import { CustomButton } from "../../../components/CustomButton";
import VerifyModal from "../../../components/VerifyModal";
import ReservationsStatusTag from "../../../components/Tags/ReservationsStatusTag";

const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const initialFilters = {
  page_size: 20,
  page: 1,
  ordering: "",
  discending: "",
  name__icontains: "",
  global_account: "",
  is_live: "",
  supplier__icontains: "",
  created__gte: "",
  created__lte: "",
  source_entity: "",
};

const navbarSchema = yup.object().shape({
  name__icontains: yup
    .string()
    .min(2, "Name must be more than 2 characters")
    .max(100, "Name: Must be less than 100 characters"),
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const APIConfigurationStyles = createUseStyles({
  AccEngineAPIConfigurations: genericIndexesStyles.indexes.mobile,
  tableContainer: genericIndexesStyles.tableContainer.mobile,
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
  checkBox: tableStyles.checkBox,
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      gridTemplateColumns: "repeat(2,1fr) ",
      paddingTop: `${variables.normal_gap}!important`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const AccEngineAPIConfigurations = () => {
  const classes = APIConfigurationStyles();

  const [filters, setFilters] = useState(initialFilters);
  const [apiConfigurationId, setApiConfigurationId] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [accommodation, setAccommodation] = useState(null);

  useListPageSidebar();
  const queryClient = useQueryClient();

  const tableHeaders = {
    Name: (val) => _.capitalize(_.get(val, "name")),
    Supplier: (val) => _.capitalize(_.get(val, "supplier")),
    "Sourse Entity": (val) => val.source_entity,
    Currency: (val) => _.get(currencies, _.get(val, "api_currency")),
    "Is Live": (val) =>
      _.get(AccEnginecommonTrueFalseMapping, _.get(val, "is_live")) || "N/A",
    "Is Global": (val) =>
      _.get(AccEnginecommonTrueFalseMapping, _.get(val, "global_account")),
    Created: (val) => val.created,
    Status: (val) => <ReservationsStatusTag status={val.status} />,
  };

  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["AccEngineAPIConfigurations", filters],
    queryFn: async () => {
      return fetchAccommodatorAPIConfigurations({
        params: {
          ...filters,
        },
      });
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const cloneAccApiMutation = useMutation({
    mutationFn: async (uid) => await cloneAccommodator({ uid }),
    onSuccess: () => {
      toast.success("Cloned Accommodator Api Configuration added");
      setShowVerifyModal(false);
      queryClient.invalidateQueries("AccommodatorAPIConfigurations");
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });
  const onClone = (uid) => {
    setApiConfigurationId(uid);
  };
  return (
    <div
      id="accEngineAPIConfigurationsListPage"
      className={classes.AccEngineAPIConfigurations}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setApiConfigurationId(null);
          setShowEditModal(true);
        }}
      />
      <ControlStrip
        limit={filters.page_size}
        page={filters.page}
        count={_.get(data, "data.count")}
        onChangeLimit={(page_size) =>
          setFilters({ ...filters, page_size, page: 1 })
        }
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th key={idx} className={classes.headCell}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.get(data, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "created":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      break;
                    default:
                      cell = fn(val) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.tableCell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setApiConfigurationId(val.uid);
                      setShowDetailModal(true);
                    }}>
                    <strong>Details</strong>
                  </CustomButton>
                  <CustomButton
                    id={`EditAccEngineButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setApiConfigurationId(val.uid);
                      setShowEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      onClone(val.uid);
                      setShowVerifyModal(true);
                    }}>
                    <strong>Clone</strong>
                  </CustomButton>
                  <CustomButton
                    id={`DeleteAccEngineButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setAccommodation(val);
                      setApiConfigurationId(val.uid);
                      setShowDeleteModal(true);
                    }}>
                    <strong>Delete</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDetailModal && (
        <DetailAccEngineAPIConfigurationModal
          uid={apiConfigurationId}
          onClose={() => setShowDetailModal(false)}
        />
      )}
      {showEditModal && (
        <EditAccEngineAPIConfigurationModal
          apiConfigurationId={apiConfigurationId}
          onClose={() => {
            setShowEditModal(false);
            setApiConfigurationId(null);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteAccApiConfigModal
          data={accommodation}
          deletedUid={apiConfigurationId}
          onClose={() => {
            setShowDeleteModal(false);
            setApiConfigurationId(null);
          }}
        />
      )}
      {showVerifyModal && (
        <VerifyModal
          // title=""
          // message=""
          isLoading={cloneAccApiMutation.isLoading}
          onConfirm={() => {
            cloneAccApiMutation.mutate(apiConfigurationId);
          }}
          onClose={() => setShowVerifyModal(false)}
        />
      )}
    </div>
  );
};
export default AccEngineAPIConfigurations;
