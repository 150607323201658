import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import _ from "lodash";
import { countries, currencies } from "../../../../../helpers";
import { useQuery } from "@tanstack/react-query";
import { retrieveAviateAPIConfiguration } from "../../../../../api/FlightEngine/AviateApiConfiguration";
import { genericRequestErrorHandler } from "../../../../../api";
import Loader from "../../../../../components/Loader";
import { flightEngineTrueFalseMapping } from "../../../common";
import { CustomButton } from "../../../../../components/CustomButton";
import AviateLogsTab from "./AviateLogsTab";
import TabStrip from "../../../../../components/TabStrip";
import ReservationsStatusTag from "../../../../../components/Tags/ReservationsStatusTag";

const enviromentOptions = {
  "": "-----",
  STAG: "Staging",
  PROD: "Production",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  DetailAviateAPIConfigurationsModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardBody: { ...cardStyles.body, display: "" },
  row: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    row: {
      gridTemplateColumns: "2fr 1fr",
    },
  },
});

const DetailAviateAPIConfigurationsModal = ({ uid, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicData");

  const menuTabs = {
    basicData: "Basic Data",
    logs: "Logs",
  };

  const { data: aviate, isLoading: retrieveLoading } = useQuery({
    queryKey: ["DetailAviateAPIConfigurationsModal", uid],
    queryFn: () => {
      return retrieveAviateAPIConfiguration({ uid });
    },
    onError: genericRequestErrorHandler,
    refetchOnWindowFocus: false,
  });

  const basicDataCpl = [
    ["Uid", "uid"],
    ["Name", null, _.capitalize(_.get(aviate, "data.name", ""))],
    ["Source Entity", "source_entity"],
    ["Market Type", "market_type"],
    ["Country", null, _.get(countries, _.get(aviate, "data.country_code"))],
    ["Currency", null, _.get(currencies, _.get(aviate, "data.currency"))],

    [
      "Is Global",
      null,
      _.get(flightEngineTrueFalseMapping, _.get(aviate, "data.is_global")),
    ],
    [
      "Is Own",
      null,
      _.get(flightEngineTrueFalseMapping, _.get(aviate, "data.is_own")),
    ],
    [
      "Is Master Account",
      null,
      _.get(
        flightEngineTrueFalseMapping,
        _.get(aviate, "data.is_master_account")
      ) || "N/A",
    ],
    ["Website Api Key", "website_api_key"],

    ["PCC", "pcc"],
    ["GDS", "gds"],

    [
      "Environment",
      null,
      _.get(enviromentOptions, _.get(aviate, "data.environment")),
    ],
    ["Received From", null, _.capitalize(_.get(aviate, "data.received_from"))],
    [
      "Status",
      null,
      <ReservationsStatusTag status={_.get(aviate, "data.status")} />,
    ],
  ];

  const usageInfo = [
    [
      "Searches",
      null,
      _.get(aviate, "data.searches", 0) === 0
        ? "0"
        : _.get(aviate, "data.searches"),
    ],
    [
      "Max Searches",
      null,
      _.get(aviate, "data.max_searches", 0) === 0
        ? "0"
        : _.get(aviate, "data.max_searches"),
    ],
    [
      "Bookings",
      null,
      _.get(aviate, "data.bookings", 0) === 0
        ? "0"
        : _.get(aviate, "data.bookings"),
    ],
    [
      "Max Bookings",
      null,
      _.get(aviate, "data.max_bookings", 0) === 0
        ? "0"
        : _.get(aviate, "data.max_bookings"),
    ],
  ];

  const creatorDataCpl = [
    [
      "Created",
      null,
      DateTime.fromISO(_.get(aviate, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];
  const editorData = [
    [
      "Edited",
      null,
      DateTime.fromISO(_.get(aviate, "data.edited")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ) || "N/A",
    ],
  ];
  const accessData = [
    ["Username", "username"],
    ["Password", "password"],
    ["Primary Key", "primary_key"],
    ["Secondary Key", "secondary_key"],
  ];
  return (
    <div className={classes.DetailAviateAPIConfigurationsModal}>
      {/* {retrieveLoading && <Loader onTop={true} />} */}
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Aviate API Configuration Details</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {retrieveLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicData" && (
                <React.Fragment>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Basic Data"}
                        cardInfo={basicDataCpl}
                        objectData={_.get(aviate, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Creator Data"}
                        cardInfo={creatorDataCpl}
                        objectData={_.get(aviate, "data", {})}
                      />
                      <GenericBasicInfo
                        header={"Editor Data"}
                        cardInfo={editorData}
                        objectData={_.get(aviate, "data", {})}
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header="Access Data"
                        cardInfo={accessData}
                        objectData={_.get(aviate, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header="Usage Data"
                        cardInfo={usageInfo}
                        objectData={_.get(aviate, "data", {})}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activeTab === "logs" && <AviateLogsTab />}
            </React.Fragment>
          )}
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton appearance="ghost" onClick={() => onClose()}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailAviateAPIConfigurationsModal.propTypes = {
  uid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DetailAviateAPIConfigurationsModal;
