import { createUseStyles } from "react-jss";
import {
  variables,
  cardStyles,
  modalGenericStyles,
} from "../../../../../../variables";
import FinancialTable from "./FinancialTable";
import PropTypes from "prop-types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../../api";
import { fetchFinancialData } from "../../../../../../api/CRM/guestCRM/guestCRM";
import { useState } from "react";
import _ from "lodash";
import Loader from "../../../../../../components/Loader";
import { CustomButton } from "../../../../../../components/CustomButton";
import AddCreditDebitModal from "./AddCreditDebitModal";
import EditCreditDebitModal from "./EditCreditDebitModal";

const styles = createUseStyles({
  ...cardStyles,
  ...modalGenericStyles,
  FinancialInfo: {
    display: "grid",
  },
  cardHeader: {
    ...cardStyles.header,
  },
  financialContainer: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gap: variables.normal_gap,
    padding: variables.normal_gap,
  },
  currency: {
    padding: variables.normal_gap,
    alignSelf: "center",
  },
  buttons: {
    display: "grid",
    gridAutoFlow: "column",
    gap: variables.normal_gap,
    justifySelf: "center",
  },
  checkedButton: {
    padding: "6px 12px",
    borderRadius: "4px",
    backgroundColor: variables.colors.background.dark,
    color: "white",
    fontSize: "14px",
    fontWeight: "bold",
    textDecoration: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "white",
      color: variables.colors.background.darkHover,
    },
  },

  uncheckedButton: {
    padding: "6px 12px",
    borderRadius: "4px",
    backgroundColor: "white",
    color: variables.colors.background.darkHover,
    fontSize: "14px",
    fontWeight: "bold",
    textDecoration: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: variables.colors.background.dark,
      color: "#fff",
    },
  },
});

const FinancialInfo = ({ guestData }) => {
  const classes = styles();

  const [showAddCreditDebitModal, setShowAddCreditDebitModal] = useState(false);
  const [showEditCreditDebitModal, setShowEditCreditDebitModal] =
    useState(false);
  const [type, setType] = useState(null);
  const [uid, setUid] = useState(null);
  const [currency, setCurrency] = useState("");
  const queryClient = useQueryClient();

  const { data: financialData, isLoading } = useQuery({
    queryKey: ["financialData", "retrieveGuestCRM"],
    queryFn: async () =>
      await fetchFinancialData({
        params: { guest_crm_uid: guestData.uuid, page_size: 1000, page: 1 },
      }),
    onSuccess: (financialData) => {
      setCurrency(financialData?.data?.results[0]?.currency);
    },
    onError: (error) => genericRequestErrorHandler(error),
    refetchOnWindowFocus: false,
  });

  //get unique currencies
  const currencies = _.get(financialData, "data.results", []).reduce(
    (acc, entry) => {
      if (!acc.includes(entry.currency)) {
        acc.push(entry.currency);
      }
      return acc;
    },
    []
  );
  const selectedCurrencyEntries = _.get(
    financialData,
    "data.results",
    []
  ).filter((cur) => cur.currency === currency);

  return (
    <div className={classes.FinancialInfo}>
      <div className={classes.cardHeader}>
        Payment Entries
        <div className={classes.cardActions}>
          <CustomButton
            appearance="ghost"
            onClick={() => {
              queryClient.invalidateQueries("financialData");
            }}>
            <strong>Refresh</strong>
          </CustomButton>
          <CustomButton
            appearance="ghost"
            onClick={() => {
              setShowAddCreditDebitModal(true);
              setType("CR");
            }}>
            <strong>Add Credit</strong>
          </CustomButton>
          <CustomButton
            appearance="ghost"
            onClick={() => {
              setShowAddCreditDebitModal(true);
              setType("DB");
            }}>
            <strong>Add Debit</strong>
          </CustomButton>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={classes.financialContainer}>
          <div className={classes.currency}>
            {currencies.map((cur) => (
              <div
                className={
                  currency === cur
                    ? `${classes.checkedButton} `
                    : `${classes.uncheckedButton} `
                }
                key={cur}>
                <CustomButton
                  appearance="ghost"
                  onClick={() => setCurrency(cur)}>
                  <strong>{cur}</strong>
                </CustomButton>
              </div>
            ))}
          </div>
          <div className={classes.financeTable}>
            <FinancialTable
              data={selectedCurrencyEntries}
              setUid={setUid}
              setShowEditCreditDebitModal={setShowEditCreditDebitModal}
            />
          </div>
        </div>
      )}
      {showAddCreditDebitModal && (
        <AddCreditDebitModal
          type={type}
          guestData={guestData}
          onClose={() => {
            setShowAddCreditDebitModal(false);
            setType(null);
            setUid(null);
          }}
        />
      )}
      {showEditCreditDebitModal && (
        <EditCreditDebitModal
          editingUid={uid}
          type={type}
          guestData={guestData}
          onClose={() => {
            setShowEditCreditDebitModal(false);
            setType(null);
            setUid(null);
          }}
        />
      )}
    </div>
  );
};
FinancialInfo.propTypes = {
  // uid: PropTypes.string.isRequired,
  guestData: PropTypes.object.isRequired,
};
export default FinancialInfo;
