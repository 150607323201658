import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import {
  variables,
  modalGenericStyles,
  cardStyles,
  genericIndexesStyles,
  tableStyles,
  GenericNavbarsStyles,
} from "../../../variables";
import { useAuthentication } from "../../../hooks";
import TabStrip from "../../TabStrip";
import { useMutation } from "@tanstack/react-query";
import { postUserLogs } from "../../../api/UserProfile";
import { genericRequestErrorHandler } from "../../../api";
import _ from "lodash";
import { Duration } from "luxon";
import UserInfoDetailModal from "./UserInfoDetailModal";
import Loader from "../../Loader";
import { CustomButton } from "../../CustomButton";
import HttpCodeTag from "../../Tags/HttpCodeTag";
import ControlStrip from "../../ControlStrip";
import * as yup from "yup";

const navbarSchema = yup.object().shape({});

const initialFilters = {
  page_size: 20,
  page: 1,
  ordering: "-created",
  action: "",
};

export function humanizeDuration(milliseconds) {
  if (isNaN(milliseconds)) {
    return "0 mins";
  }

  var dur = Duration.fromObject({ milliseconds });
  var minutes = dur.as("minutes");
  const hours = parseInt(minutes / 60, 10);
  minutes = parseInt(minutes, 10) % 60;
  const durObj = {};
  if (hours > 0) {
    durObj["hours"] = hours;
  }
  if (minutes > 0) {
    durObj["minutes"] = minutes;
  }

  if (_.isEmpty(durObj)) {
    return "0 mins";
  }

  dur = Duration.fromObject(durObj);
  const duration = dur.toHuman({ unitDisplay: "short" });
  return duration;
}

const styles = createUseStyles({
  ...cardStyles,
  UserInfoCard: {},

  modalCard: {
    ...cardStyles.card,
    // ...genericIndexesStyles.indexes.mobile,
  },

  modalCardActions: modalGenericStyles.cardActions,
  Navbar: {
    ...GenericNavbarsStyles.navbars.mobile(),
  },
  label: GenericNavbarsStyles.label(),
  divider: GenericNavbarsStyles.divider.mobile(),
  card: cardStyles.card,
  cardHeader: {
    ...cardStyles.header,
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    justifyItems: "left",
    height: "2rem",
    fontWeight: "bold",
    background: variables.colors.background.secondary.dark,
  },
  loader: { width: "100%" },
  cardBody: cardStyles.body,
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: tableStyles.body.cell,
  successCell: {
    color: "green",
  },
  canceledCell: {
    color: "red",
  },
  actionCell: genericIndexesStyles.actionCell.mobile(),
  [`@media ${variables.media.smallscreen}`]: {
    Navbar: GenericNavbarsStyles.navbars.smallscreen(),
    divider: GenericNavbarsStyles.divider.smallscreen(),
  },
});

const UserInfoCard = () => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("userRequests");
  const [request, setRequest] = useState({});
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [filters, setFilters] = useState(initialFilters);

  const menuTabs = {
    userRequests: "User Requests",
  };

  const { user } = useAuthentication({ info_only: true });

  const postUserLogsMutation = useMutation({
    mutationFn: ({ payload }) => {
      return postUserLogs({ payload });
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    retry: false,
  });

  const logs = _.get(postUserLogsMutation, "data.data.results", []);

  useEffect(() => {
    postUserLogsMutation.mutate({
      payload: {
        index_name: ["verita*"],
        elastic_query: {
          from: filters.page + 1,
          // from: 0,
          size: filters.page_size,
          // size: 20,
          sort: [
            {
              timestamp: {
                // order: filters.ordering === "created" ? "desc" : "asc",
                order: filters.ordering === "created" ? "asc" : "desc",
                // order: "desc",
              },
            },
          ],
          query: {
            bool: {
              must: [
                {
                  match: {
                    creator: user.sub,
                  },
                },
              ],
            },
          },
        },
      },
    });
  }, [user.sub, filters.ordering, filters.page, filters.page_size]);

  console.log("logs", logs);

  return (
    <div className={classes.UserInfoCard}>
      {/* <div className={classes.modalCard}> */}
      <div className={classes.Navbar}>
        <div className={classes.label}>User Profile</div>
        <div className={classes.divider} />
      </div>
      <ControlStrip
        limit={filters.page_size}
        page={filters.page}
        count={_.get(postUserLogsMutation, "data.data.count")}
        onChangeLimit={(page_size) =>
          setFilters({ ...filters, page_size, page: 1 })
        }
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      <TabStrip
        tabs={Object.entries(menuTabs)}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className={classes.cardBody}>
        {postUserLogsMutation.isLoading ? (
          <div className={classes.loader}>
            <Loader />
          </div>
        ) : (
          activeTab === "userRequests" && (
            <div className={classes.tableContainer}>
              <table className={classes.table}>
                <thead className={classes.thead}>
                  <tr className={classes.headRow}>
                    <th className={classes.headCell}>#</th>
                    <th className={classes.headCell}>Descriptor</th>
                    <th className={classes.headCell}>Method</th>
                    <th className={classes.headCell}>Elapsed Time</th>
                    <th className={classes.headCell}>Created</th>
                    <th className={classes.headCell}>Status</th>
                    <th className={classes.headCell}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((log, idx) => (
                    <tr className={classes.bodyRow} key={idx}>
                      <td className={classes.tableCell}>{idx + 1}</td>
                      <td className={classes.tableCell}>
                        {_.get(log, "_source.descriptor") || "N/A"}
                      </td>
                      <td className={classes.tableCell}>
                        {_.get(log, "_source.method", "") || "N/A"}
                      </td>
                      <td className={classes.tableCell}>
                        {_.get(log, "_source.elapsedTime", 0) / 1000} sec.
                      </td>
                      <td className={classes.tableCell}>
                        {new Date(
                          _.get(log, "_source.timestamp", 0)
                        ).toLocaleString() || "N/A"}
                      </td>
                      <td className={classes.tableCell}>
                        <HttpCodeTag
                          statusCode={_.get(log, "_source.status", 0)}
                        />
                      </td>
                      <td className={classes.actionCell}>
                        <CustomButton
                          appearance="ghost"
                          onClick={() => {
                            setShowDetailModal(true);
                            setRequest(log);
                          }}>
                          <strong>Details</strong>
                        </CustomButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        )}
      </div>
      {showDetailModal && (
        <UserInfoDetailModal
          data={request}
          onClose={() => {
            setShowDetailModal(false);
            setRequest(null);
          }}
        />
      )}
      {/* </div> */}
    </div>
  );
};
export default UserInfoCard;
