import React from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import { useQuery } from "@tanstack/react-query";
import { fetchBackUpStatus } from "../../../../api/Monitoring/BackUpStatus";
import { genericRequestErrorHandler } from "../../../../api";
import Loader from "../../../../components/Loader";
import { medWeekdayDate } from "../../../../helpers";
import Navbar from "./Navbar";
import _ from "lodash";

const styles = createUseStyles({
  BackUpStatus: genericIndexesStyles.indexes.mobile,
  tableContainer: genericIndexesStyles.tableContainer.mobile,
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile(),
  checkBox: tableStyles.checkBox,
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      gridTemplateColumns: "repeat(2,1fr) ",
      paddingTop: `${variables.normal_gap}!important`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const BackUpStatus = () => {
  const classes = styles();

  const tableHeaders = {
    "Back Up Name": (val) => _.capitalize(val.backup_name),
    Environment: (val) => _.capitalize(val.env),
    "Daily Backup": (val) =>
      _.get(val, "daily_backup_ok") === true ? "Yes" : "No",
    "Last Back Up": (val) => val.last_backup_date,
    "Checked Day": (val) => val.check_day,
  };

  const { data, isLoading } = useQuery({
    queryKey: ["BackUpStatus"],
    queryFn: async () => await fetchBackUpStatus({}),
    onError: (error) => genericRequestErrorHandler(error),
  });

  return (
    <div className={classes.BackUpStatus}>
      <Navbar />
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th key={idx} className={classes.headCell}>
                  {value}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {_.get(data, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "checked day":
                      cell = fn(val) ? medWeekdayDate(fn(val)) : "N/A";
                      break;
                    case "last back up":
                      cell = fn(val) ? medWeekdayDate(fn(val)) : "N/A";
                      break;
                    default:
                      cell = fn(val) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.tableCell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default BackUpStatus;
