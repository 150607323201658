import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  inputGroup,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import _ from "lodash";
import {
  createNewVesselApiConfiguration,
  patchVesselApiConfiguration,
  retrieveVesselAPIConfiguration,
} from "../../../../api/VesselEngine/ApiConfiguration/ApiConfiguration";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import EntityAutocomplete from "../../../../components/EntityAutocomplete";
import { genericRequestErrorHandler } from "../../../../api";
import {
  APIConfigsStatusMapping,
  convertStringToBoolean,
  handleValidationErrors,
} from "../../../../helpers";
import { CustomButton } from "../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .min(2, "Must be at least 2 characters.")
    .max(60, "Must be less than 60 characters.")
    .default(""),
  source_entity: yup.string().required("This field is Required.").default(""),
  is_master_account: yup.boolean().default(false),
  market_type: yup
    .string()
    // .oneOf(["B2B", "B2C"], "Must be between B2B or B2C.")
    .nullable()
    .default(""),
  status: yup.string().nullable().default("AC"),
  environment: yup
    .string()
    // .oneOf(["STAGING", "PRODUCTION"], "Must be between Staging or Production.")
    .nullable()
    .default(""),
  website_api_key: yup.string().nullable().default(""),
  is_own: yup.boolean().nullable().default(false),
  is_global: yup.boolean().nullable().default(false),
  client_id: yup.string().required("This field is Required.").default(""),
  client_secret: yup.string().required("This field is Required.").default(""),
  partner_code: yup.string().required("This field is Required.").default(""),
});

const marketTypeOptions = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};
const globalAccountMapping = {
  false: "No",
  true: "Yes",
};
const enviromentOptions = {
  "": "-----",
  STAGING: "Staging",
  PRODUCTION: "Production",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditVesselEngineModal: {
    ...modalGenericStyles.modal,
    zIndex: "990",
  },
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: cardStyles.body,
  inputGroup: {
    ...formInputGroup,
  },
  entityAutoComplete: {
    ...inputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    card: {
      minWidth: "50rem",
    },
    cardCol2: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
});

const EditVesselEngineModal = ({ apiConfigurationId, onClose }) => {
  const classes = styles();
  const mode = apiConfigurationId ? "edit" : "add";

  const [form, setForm] = useState(jsonSchema.cast({}));
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["EditVesselEngineModal", apiConfigurationId],
    queryFn: () =>
      retrieveVesselAPIConfiguration({
        uid: apiConfigurationId,
      }),
    onSuccess: (data) => {
      setForm(jsonSchema.cast(_.get(data, "data")));
      setAutocompleteValue(_.get(data, "data.source_entity", ""));
    },
    onError: (error) => {
      toast.error(error.message);
      onClose();
    },
    enabled: mode === "edit",
  });

  const editMutation = useMutation(
    async ({ uid, payload }) =>
      await patchVesselApiConfiguration({ uid, payload }),
    {
      onSuccess: () => {
        toast.success("Vessel Info Updated");
        queryClient.invalidateQueries("VesselEngineAPIConfigurations");
        onClose();
      },
      onError: (error) => {
        genericRequestErrorHandler(error);
      },
    }
  );

  const addMutation = useMutation(
    async ({ payload }) => await createNewVesselApiConfiguration({ payload }),
    {
      onSuccess: () => {
        toast.success("Vessel Info Added");
        queryClient.invalidateQueries("VesselEngineAPIConfigurations");
        onClose();
      },
      onError: (error) => {
        genericRequestErrorHandler(error);
      },
    }
  );

  const handleInputChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const onSubmit = async ({ payload, uid }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload });
      } else {
        editMutation.mutate({ uid, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const boolFields = convertStringToBoolean({
    is_master_account: form.is_master_account,
    is_global: form.is_global,
    is_own: form.is_own,
  });

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  return (
    <div
      id="addEditVesselEngineAPIConfigurationModal"
      className={classes.EditVesselEngineModal}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} API Configuration</span>
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} API Configuration</span>
          </div>
          <div className={classes.modalCardBody}>
            <form onChange={handleInputChange}>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Data</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="name">Name</label>
                      <input
                        id="name"
                        type="text"
                        name="name"
                        value={_.capitalize(form.name)}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="source_entity">Source Entity</label>
                      <div className={classes.entityAutoComplete}>
                        <EntityAutocomplete
                          id="entityAutocompleteInputId"
                          subagents={false}
                          independentAgents={false}
                          branches={false}
                          value={autocompleteValue}
                          setValue={setAutocompleteValue}
                          onChange={(text) => {
                            if (text.length === 0) {
                              setForm((p) => ({
                                ...p,
                                source_entity: "",
                              }));
                              return;
                            }
                            const entityInfos = text.split("---");
                            const entityType = entityInfos[0];
                            const entityId = entityInfos[1];
                            setForm((p) => ({
                              ...p,
                              source_entity: `${entityId}___${entityType}`,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="market_type">Market Type</label>
                      <select
                        id="market_type"
                        name="market_type"
                        value={form.market_type}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(marketTypeOptions).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="environment">Environment</label>
                      <select
                        id="environment"
                        name="environment"
                        value={form.environment}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(enviromentOptions).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  <div className={classes.cardCol1}>
                    <div className={classes.inputGroup}>
                      <label>Website Api Key</label>
                      <input
                        id="website_api_key"
                        type="text"
                        name="website_api_key"
                        value={form.website_api_key}
                      />
                    </div>
                  </div>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="is_own">Is Own</label>
                      <select
                        id="is_own"
                        name="is_own"
                        value={form.is_own}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(globalAccountMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="is_global">Is Global</label>
                      <select
                        id="is_global"
                        name="is_global"
                        value={form.is_global}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(globalAccountMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="is_master_account">
                        Is Master Account
                      </label>
                      <select
                        id="is_master_account"
                        name="is_master_account"
                        value={form.is_master_account}
                        onChange={handleInputChange}>
                        {Object.entries(globalAccountMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="status">Status</label>
                      <select
                        id="status"
                        name="status"
                        value={form.status}
                        onChange={handleInputChange}>
                        {Object.entries(APIConfigsStatusMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>
                  Authorization Information
                </div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="client_id">Client Id</label>
                      <input
                        id="client_id"
                        type="text"
                        name="client_id"
                        value={form.client_id}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="client_secret">Client Secret</label>
                      <input
                        id="client_secret"
                        type="text"
                        name="client_secret"
                        value={form.client_secret}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="partner_code">Partner Code</label>
                      <input
                        id="partner_code"
                        type="text"
                        name="partner_code"
                        value={form.partner_code}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className={classes.cardActions}>
            <CustomButton
              id="cancel"
              appearance="ghost"
              onClick={() => onClose()}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="submit"
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  uid: apiConfigurationId,
                  payload: { ...form, ...boolFields },
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditVesselEngineModal.propTypes = {
  apiConfigurationId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};
export default EditVesselEngineModal;
