import { VALAQUENTA_API_URL } from "..";
import { authorizedAxios, loggingAxios } from "../../App";

export function fetchAviateAPIConfigurations({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/flightengine/api-config/aviate/`,
    {
      params,
    }
  );
}

export function retrieveAviateAPIConfiguration({ uid }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/flightengine/api-config/aviate/${uid}/`
  );
}

export function patchAviateApiConfiguration({ uid, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/flightengine/api-config/aviate/${uid}/`,
    payload
  );
}

export function createNewAviateApiConfiguration({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/flightengine/api-config/aviate/`,
    payload
  );
}

export function cloneAviateApiConfiguration({ uid }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/flightengine/api-config/aviate/clone/${uid}/`
  );
}

export function deleteAviateApiConfiguration({ uid }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/flightengine/api-config/aviate/${uid}/`
  );
}
