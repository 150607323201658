import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  tableStyles,
  variables,
} from "../../../../../../variables";
import _ from "lodash";
import { medWeekdayDatetimer } from "../../../../../../helpers";
import { Button } from "../../../../../../components/Button";
import WarningsModal from "./WarningsModal";
import FixConfigsModal from "./FixConfigsModal";
import { useQueryClient } from "@tanstack/react-query";

const srvTypesForCheck = ["ACC", "ACT", "CAR", "FER", "FL", "MI", "TRA", "TRF"];

const srvTypeMapping = {
  ACC: "Accommodation",
  ACT: "Activity",
  CAR: "Car Rental",
  COO: "Coordinator",
  FER: "Ferry",
  FL: "Flight",
  GEN: "General Service",
  MI: "Addons",
  TRA: "Train",
  TRF: "Transfer",
  REST: "Restaurant",
  PKG: "Package",
};

const emailTypeMapping = {
  VO: "Voucher",
  CXL: "Cancellation",
  RQ: "On Request",
  FA: "Failed Reservation",
  OFF: "Offer Notification",
};

const statusMapping = {
  SU: "Success",
  FA: "Failed",
  PE: "Pending",
};

const checkEmailConfigs = (
  configs,
  website_api_key,
  checkDefaultConfig = false
) => {
  const errors = [];
  if (checkDefaultConfig) {
    const defaultEmailConfigs = configs.filter(
      (config) => !config.website_api_key
    );
    if (defaultEmailConfigs.length === 0) {
      errors.push(`There are no default email configurations`);
    } else {
      srvTypesForCheck.forEach((srvType) => {
        const srvEmailConfigs = defaultEmailConfigs.filter((config) =>
          config.reservation_types.includes(srvType)
        );
        if (srvEmailConfigs.length === 0) {
          errors.push(
            `There are no email configurations for ${srvTypeMapping[srvType]}`
          );
        }
      });
    }
    return errors;
  }

  const websiteEmailConfigs = configs.filter(
    (config) => config.website_api_key === website_api_key
  );
  srvTypesForCheck.forEach((srvType) => {
    const srvEmailConfigs = websiteEmailConfigs.filter((config) =>
      config.reservation_types.includes(srvType)
    );
    if (srvEmailConfigs.length === 0) {
      errors.push(
        `There are no email configurations for ${srvTypeMapping[srvType]}`
      );
    }
  });
  return errors;
};

const hasErrors = (emailConfigs, groups_display, websites) => {
  const has_b2c = groups_display.includes("has_b2c");
  if (!has_b2c) return {};

  var websiteConfigsErrors = {};
  const websitesChecked = [];
  websites.forEach((website) => {
    if (websitesChecked.includes(website.api_key)) return;
    const errors = checkEmailConfigs(emailConfigs, website.api_key);
    if (errors.length > 0) {
      websiteConfigsErrors[website.api_key] = errors;
    }
    websitesChecked.push(website.api_key);
  });

  const defaultErrors = checkEmailConfigs(emailConfigs, null, true);
  if (defaultErrors.length > 0) {
    websiteConfigsErrors["default"] = defaultErrors;
  }
  return websiteConfigsErrors;
};

const styles = createUseStyles({
  ...cardStyles,
  EmailConfigs: { ...cardStyles.card },
  header: {
    ...cardStyles.header,
    padding: variables.normal_gap,
    fontSize: "1.2rem",
    marginTop: "2px",
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  headerActions: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gap: variables.normal_gap,
  },
  tableCell: tableStyles.body.cell,
  tableContainer: {
    display: "block",
    maxHeight: "20rem",
    overflow: "auto",
    width: "100%",
  },
  table: {
    width: "100%",
  },
  thead: {
    position: "sticky",
    top: 0,
  },
  emptyEmails: {
    color: "red",
    textAlign: "center",
    minHeight: "10rem",
    lineHeight: "10rem",
  },
  red: { color: "red" },
});
const EmailConfigs = ({ memberId, emailConfigs, groups_display, websites }) => {
  const classes = styles();

  const queryClient = useQueryClient();

  const [showWarnings, setShowWarnings] = useState(false);
  const [showFixDefaultConfigsModal, setShowFixDefaultConfigsModal] =
    useState(false);
  const [showFixConfigsModal, setShowFixConfigsModal] = useState(false);

  const errors = hasErrors(emailConfigs, groups_display, websites);
  const thereAreWarnings = !_.isEmpty(errors);
  const thereAreDefaultWarnings = Object.keys(errors).includes("default");
  const warningWebsiteApiKeyes = Object.keys(errors).filter(
    (key) => key !== "default"
  );
  const thereAreWebsiteWarnings = warningWebsiteApiKeyes.length > 0;
  const sender_emails = emailConfigs.filter((config) => config.sender);
  const sender_email =
    sender_emails.length > 0 ? sender_emails[0].sender : null;

  return (
    <div className={classes.EmailConfigs}>
      <div className={classes.header}>
        <span>
          Email Configurations{" "}
          {thereAreWarnings && (
            <span className={classes.red}>{"(There are warnings)"}</span>
          )}
        </span>
        <div className={classes.headerActions}>
          {thereAreWarnings && (
            <Button
              text="Show Warnings"
              onClick={() => setShowWarnings(true)}
            />
          )}
          {thereAreDefaultWarnings && (
            <Button
              text="Fix Default Configs"
              onClick={() => setShowFixDefaultConfigsModal(true)}
            />
          )}
          {thereAreWebsiteWarnings && (
            <Button
              text="Fix Websites Configs"
              onClick={() => setShowFixConfigsModal(true)}
            />
          )}
        </div>
      </div>
      <div className={classes.tableContainer}>
        {emailConfigs.length === 0 ? (
          <div className={classes.emptyEmails}>
            <strong>No Email Configurations</strong>
          </div>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Title</th>
                <th className={classes.headCell}>Subject</th>
                <th className={classes.headCell}>Email Type</th>
                <th className={classes.headCell}>Template Name</th>
                <th className={classes.headCell}>Website API Key</th>
                <th className={classes.headCell}>Sender</th>
                <th className={classes.headCell}>Reservation Type</th>
                <th className={classes.headCell}>Is Global</th>
                <th className={classes.headCell}>Verification Status</th>
                <th className={classes.headCell}>Created</th>
              </tr>
            </thead>
            <tbody>
              {emailConfigs.map((config, idx) => (
                <tr key={config.id}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  <td className={classes.tableCell}>{config.title || "N/A"}</td>
                  <td className={classes.tableCell}>
                    {config.subject || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(emailTypeMapping, _.get(config, "email_type")) ||
                      "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {config.template_name || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {config.website_api_key || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {config.sender || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(config, "reservation_types", [])
                      .map((type) => _.get(srvTypeMapping, type))
                      .join(", ") || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {config.is_global === null ? (
                      "N/A"
                    ) : config.is_global ? (
                      "Yes"
                    ) : (
                      <span className={classes.red}>{"No"}</span>
                    )}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(statusMapping, config.status) || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(config, "created")
                      ? medWeekdayDatetimer(_.get(config, "created"))
                      : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {showWarnings && (
        <WarningsModal
          warnings={errors}
          onClose={() => setShowWarnings(false)}
        />
      )}
      {showFixDefaultConfigsModal && (
        <FixConfigsModal
          website_api_keys={warningWebsiteApiKeyes}
          sender_email={sender_email}
          source_entity={`${memberId}___member`}
          onClose={() => {
            setShowFixDefaultConfigsModal(false);
            queryClient.invalidateQueries({
              queryKey: ["memberBirdsEye", memberId],
            });
          }}
        />
      )}
      {showFixConfigsModal && (
        <FixConfigsModal
          website_api_keys={warningWebsiteApiKeyes}
          sender_email={sender_email}
          source_entity={`${memberId}___member`}
          onClose={() => {
            setShowFixConfigsModal(false);
            queryClient.invalidateQueries(["memberBirdsEye", memberId]);
          }}
        />
      )}
    </div>
  );
};
EmailConfigs.propTypes = {
  memberId: PropTypes.number.isRequired,
  emailConfigs: PropTypes.array.isRequired,
  groups_display: PropTypes.array.isRequired,
  websites: PropTypes.array.isRequired,
};
export default EmailConfigs;
