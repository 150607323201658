import React, { useState } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { VALAQUENTA_API_URL } from "../../../../api";
import { createUseStyles } from "react-jss";
import { variables } from "../../../../variables";
import Loader from "../../../../components/Loader";
import { CustomButton } from "../../../../components/CustomButton";

const festAvailLiveEndpoints = () => {
  return axios.get(`${VALAQUENTA_API_URL}/highinquisitor/glances/`);
};

const serverModalStyles = createUseStyles({
  ServerModal: {
    width: "100%",
    padding: variables.normal_gap,
    position: "relative",
  },
  iframe: {
    width: `90%`,
    height: "80vh",
    borderRadius: "5px",
  },
});
const ServerModal = ({ server_url }) => {
  const classes = serverModalStyles();
  return (
    <div className={classes.ServerModal}>
      <h1>Server Modal</h1>
      <a target="_blank" href={server_url}>
        View Server
      </a>
      <iframe
        className={classes.iframe}
        title="Server Modal"
        frameborder="0"
        src={`${server_url}`}
      />
    </div>
  );
};

const Live = () => {
  const [showModal, setShowModal] = useState(false);
  const [serverUrl, setServerUrl] = useState("");
  const { data, isLoading } = useQuery({
    queryFn: festAvailLiveEndpoints,
  });
  const availableServers = data?.data?.glances_server_details ?? {};

  return (
    <div>
      {isLoading && <Loader onTop={true} />}
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(availableServers).map(
            ([server_name, server_url], idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{server_name}</td>
                  <td>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        setServerUrl(server_url.base_url);
                        setShowModal(true);
                      }}>
                      <strong>View</strong>
                    </CustomButton>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
      {showModal && <ServerModal server_url={serverUrl} />}
    </div>
  );
};

export default Live;
