import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";
export async function fetchFlightReservations({ params }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/flight/`,
    {
      params,
    }
  );
}

export async function retrieveFlightReservation({ reference }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/flight/${reference}/`
  );
}
export async function patchFlightReservation({ reference, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/flight/${reference}/`,
    payload
  );
}
export async function getFlightReservationStats({ params }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/flight/stats/`,
    { params }
  );
}
