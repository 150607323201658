import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { cardStyles, variables } from "../../../../../variables";
import _ from "lodash";

const emptyEntityColors = {
  base: "",
  background: {
    dark: "",
    light: "",
    secondary: {
      dark: "",
      light: "",
    },
  },
  text: {
    colored: "",
  },
};

const styles = createUseStyles({
  ...cardStyles,
  EntityColorsSection: { ...cardStyles.card },
  header: {
    ...cardStyles.header,
    fontSize: "1.2rem",
    padding: variables.normal_gap,
    marginTop: "2px",
  },
  json: {
    gridColumn: "span 2",
    display: "grid",
    gridTemplateColumns: "1fr",
  },
  red: {
    color: "red",
  },
});
const EntityColorsSection = ({ entityColors }) => {
  const classes = styles({});

  const error =
    _.isEmpty(entityColors) || _.isEqual(entityColors, emptyEntityColors)
      ? "(Entity Colors has not been set)"
      : null;
  return (
    <div className={EntityColorsSection}>
      <div className={classes.header}>
        <span>
          Entity Colors{" "}
          {error && <span className={classes.red}>{`${error}`}</span>}
        </span>
      </div>
      <div className={classes.json}>
        <pre>{JSON.stringify(entityColors, null, 2)}</pre>
      </div>
    </div>
  );
};
EntityColorsSection.propTypes = {
  entityColors: PropTypes.object.isRequired,
};
export default EntityColorsSection;
