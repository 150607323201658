import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  btnStyles,
  modalGenericStyles,
  tableStyles,
  variables,
  genericIndexesStyles,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { fetchWebSiteFAQ } from "../../../../../api/DistributionSite/webSites/FAQ";
import EditFAQ from "../EditFAQ";
import { DateTime } from "luxon";
import Loader from "../../../../../components/Loader";
import DeleteFAQ from "../DeleteFAQ";
import { statusMapping } from "../../common";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  FAQModal: {
    ...modalGenericStyles.card,
  },
  loader: { width: "100vw" },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: tableStyles,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: tableStyles.body.cell,
  actionCell: {
    ...genericIndexesStyles.actionCell.mobile(),
    padding: variables.double_gap,
  },
  btn: btnStyles.btn,
  add: {
    padding: "0.75rem",
    textAlign: "end",
  },
  addBtn: { ...btnStyles.btn, width: "4rem" },
  [`@media ${variables.media.smallscreen}`]: {
    loader: { width: "100%" },
  },
});

const FAQModal = ({ websiteId }) => {
  const classes = styles();

  const [FAQ, setFAQ] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { data: FAQList, isLoading } = useQuery({
    queryKey: ["FAQModalList"],
    queryFn: async () =>
      await fetchWebSiteFAQ({
        params: { website: websiteId },
      }),
    onError: (error) => genericRequestErrorHandler(error),
  });
  return (
    <div className={classes.FAQModal}>
      <div className={classes.cardHeader}>
        FAQ
        <div className={classes.cardActions}>
          <CustomButton
            appearance="ghost"
            onClick={() => {
              setEditId(null);
              setEditModal(true);
            }}>
            <strong>Add</strong>
          </CustomButton>
        </div>
      </div>
      <div className={classes.tableContainer}>
        {isLoading && <Loader onTop={true} />}
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              <th className={classes.headCell}>Id</th>
              <th className={classes.headCell}>Name</th>
              <th className={classes.headCell}>Active From</th>
              <th className={classes.headCell}>Active To</th>
              <th className={classes.headCell}>Status</th>
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody className={classes.tbody}>
            {_.get(FAQList, "data.results", []).map((faq, idx) => (
              <tr key={idx} className={classes.bodyRow}>
                <td className={classes.cell}>{idx + 1}</td>
                <td className={classes.cell}>{faq.id || "N/A"}</td>
                <td className={classes.cell}>{faq.name || "N/A"}</td>
                <td className={classes.cell}>
                  {faq.active_to
                    ? DateTime.fromISO(faq.active_to).toLocaleString(
                        DateTime.DATE_MED_WITH_WEEKDAY
                      )
                    : "N/A"}
                </td>
                <td className={classes.cell}>
                  {faq.active_from
                    ? DateTime.fromISO(faq.active_from).toLocaleString(
                        DateTime.DATE_MED_WITH_WEEKDAY
                      )
                    : "N/A"}
                </td>
                <td className={classes.cell}>
                  {_.get(statusMapping, _.get(faq, "status")) || "N/A"}
                </td>
                <td className={classes.actionCell}>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setEditId(faq.id);
                      setEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setFAQ(faq);
                      setDeleteId(faq.id);
                      setDeleteModal(true);
                    }}>
                    <strong>Delete</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {editModal && (
        <EditFAQ
          id={editId}
          websiteId={websiteId}
          onClose={() => {
            setEditModal(false);
            setEditId(null);
          }}
        />
      )}
      {deleteModal && (
        <DeleteFAQ
          id={deleteId}
          data={FAQ}
          onClose={() => {
            setDeleteModal(false);
          }}
        />
      )}
    </div>
  );
};
FAQModal.propTypes = {
  websiteId: PropTypes.number.isRequired,
};
export default FAQModal;
