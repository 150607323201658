import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export async function fetchEmailsReservations({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/emailengine/emails/`, {
    params,
  });
}
export async function sendReservationEmail({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/send-email/`,
    payload
  );
}
