import React, { useState } from "react";
import {
  GenericNavbarsStyles,
  inputGroup,
  variables,
} from "../../../../variables";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { onEnterDown } from "../../../../helpers";
import { CustomButton } from "../../../../components/CustomButton";

const userOptions = [
  ["", "-------"],
  ["member", "Company User"],
  ["branch", "Branch"],
  ["subagent", "Sub Agent"],
  ["independentagent", "Independent Agent"],
  ["affiliate", "Affiliate"],
];

const statusOption = { "": "-----", AC: "Active", IN: "Inactive" };

const navbarStyles = createUseStyles({
  Navbar: {
    ...GenericNavbarsStyles.navbars.mobile(),
    gridTemplateRows: "repeat(4, max-content)",
  },
  label: { ...inputGroup },
  divider: GenericNavbarsStyles.divider.mobile(),
  filters: GenericNavbarsStyles.filters.mobile(),
  actions: GenericNavbarsStyles.actions.mobile(),
  inputGroup: {
    ...inputGroup,
    width: "100%",
    "& input, select": {
      ...inputGroup["& input, select"],
      width: "100%",
    },
  },
  inputGroupOneLine: {
    ...inputGroup,
    gridColumn: "2 span",
    width: "100%",
  },
  [`@media ${variables.media.smallscreen}`]: {
    Navbar: {
      ...GenericNavbarsStyles.navbars.smallscreen(),
      gridTemplateColumns: "repeat(2, max-content) 1fr max-content",
      gridTemplateRows: "max-content",
    },
    divider: GenericNavbarsStyles.divider.smallscreen(),
    filters: {
      ...GenericNavbarsStyles.filters.smallscreen(),
      gridTemplateColumns: "repeat(7, 1fr)",
    },
    actions: GenericNavbarsStyles.actions.smallscreen(),
    inputGroup: {
      ...inputGroup,
    },
    inputGroupOneLine: { ...inputGroup.bigscreen, gridColumn: "1 span" },
  },
  [`@media ${variables.media.retina}`]: {
    filters: {
      ...GenericNavbarsStyles.filters.smallscreen(),
      gridTemplateColumns: "repeat(6, 1fr)",
      gridTemplateRows: "max-content",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    filters: {
      gridTemplateColumns: "repeat(8, 1fr)",
    },
  },
});

const Navbar = ({ filters, initialFilters, onApplyFilters, onAdd }) => {
  const classes = navbarStyles();

  const [localFilters, setLocalFilters] = useState(filters);

  return (
    <div className={classes.Navbar}>
      <div className={classes.label}>
        <strong>Users</strong>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.filters}>
        <div className={classes.inputGroup}>
          <label>Company Legal Title</label>
          <input
            id="filter_company_legal_title"
            type="text"
            value={localFilters.company_legal_title__icontains}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                company_legal_title__icontains: e.target.value,
              }))
            }
            onKeyDown={(e) =>
              onEnterDown(e, onApplyFilters, localFilters)
            }></input>
        </div>
        <div className={classes.inputGroup}>
          <label>User Type</label>
          <select
            id="filter_company_type"
            value={localFilters.company_type__in}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                company_type__in: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {userOptions.map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>First Name</label>
          <input
            id="filter_first_name"
            type="text"
            value={localFilters.first_name__icontains}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                first_name__icontains: e.target.value,
              }))
            }
            onKeyDown={(e) =>
              onEnterDown(e, onApplyFilters, localFilters)
            }></input>
        </div>
        <div className={classes.inputGroup}>
          <label>Last Name</label>
          <input
            id="filter_last_name"
            type="text"
            value={localFilters.last_name__icontains}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                last_name__icontains: e.target.value,
              }))
            }
            onKeyDown={(e) =>
              onEnterDown(e, onApplyFilters, localFilters)
            }></input>
        </div>
        <div className={classes.inputGroup}>
          <label>Tags</label>
          <input
            id="filter_tags"
            type="text"
            value={localFilters.tags}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                tags: e.target.value,
              }))
            }
            onKeyDown={(e) =>
              onEnterDown(e, onApplyFilters, localFilters)
            }></input>
        </div>
        <div className={classes.inputGroup}>
          <label>Account Email</label>
          <input
            id="filter_email"
            type="text"
            value={localFilters.email__icontains}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                email__icontains: e.target.value,
              }))
            }
            onKeyDown={(e) =>
              onEnterDown(e, onApplyFilters, localFilters)
            }></input>
        </div>
        <div className={classes.inputGroup}>
          <label>Created After</label>
          <input
            id="filter_created_after"
            type="date"
            value={localFilters.created__gte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                created__gte: e.target.value,
              }))
            }
            onKeyDown={(e) =>
              onEnterDown(e, onApplyFilters, localFilters)
            }></input>
        </div>
        <div className={classes.inputGroup}>
          <label>Created Before</label>
          <input
            id="filter_created_before"
            type="date"
            value={localFilters.created__lte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                created__lte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Status</label>
          <select
            id="filter_status"
            value={localFilters.status__in}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, status__in: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(statusOption).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={classes.actions}>
        <CustomButton
          id="ResetFiltersButton"
          appearance="ghost"
          onClick={() =>
            setLocalFilters({
              ...initialFilters,
            })
          }>
          <strong>Reset</strong>
        </CustomButton>
        <CustomButton
          id="ApplyFiltersButton"
          appearance="primary"
          onClick={() => onApplyFilters(localFilters)}>
          <strong>Apply Filters</strong>
        </CustomButton>
      </div>
    </div>
  );
};
Navbar.propTypes = {
  filters: PropTypes.object.isRequired,
  initialFilters: PropTypes.object.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};
export default Navbar;
