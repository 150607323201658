import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import { DateTime } from "luxon";
import { retrieveFlightAPIConfiguration } from "../../../../../api/FlightEngine/FlightAPIConfigurations";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import Loader from "../../../../../components/Loader";
import { flightEngineTrueFalseMapping } from "../../../common";
import { CustomButton } from "../../../../../components/CustomButton";
import AviateLogsTab from "../../../AviateApiConfiguration/AviateModals/DetailsAviateModal/AviateLogsTab";
import TabStrip from "../../../../../components/TabStrip";
import ReservationsStatusTag from "../../../../../components/Tags/ReservationsStatusTag";

const enviromentOptions = {
  "": "-----",
  STAG: "Staging",
  PROD: "Production",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailFlightAPIConfigurationsModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardBody: cardStyles.body,
  firstRow: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gap: variables.normal_gap,
    justifyItems: "left",
  },
  row: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    row: {
      gridTemplateColumns: "2fr 1fr",
    },
  },
});

const DetailFlightAPIConfigurationsModal = ({ uid, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicData");

  const menuTabs = {
    basicData: "Basic Data",
    logs: "Logs",
  };

  const { data: hitchhicker, isLoading: retrieveLoading } = useQuery({
    queryKey: ["HitchhickerDetail", uid],
    queryFn: () => {
      return retrieveFlightAPIConfiguration({ uid });
    },
    onError: genericRequestErrorHandler,
    refetchOnWindowFocus: false,
  });

  const basicDataCpl = [
    ["Uid", "uid"],
    ["User", "user"],
    ["Name", null, _.capitalize(_.get(hitchhicker, "data.name"))],
    ["Market Type", "market_type"],
    ["Source Entity", "source_entity"],
    [
      "Force Source Type",
      null,
      _.get(hitchhicker, "data.force_source_type") === 0
        ? "0"
        : _.get(hitchhicker, "data.force_source_type"),
    ],
    ["Currency", "currency"],
    ["Country", "country_code"],
    ["GDS", "gds"],
    ["PCC", "pcc"],
    ["GDS", "gds"],

    [
      "Is Global",
      null,
      _.get(flightEngineTrueFalseMapping, _.get(hitchhicker, "data.is_global")),
    ],
    [
      "Is Own",
      null,
      _.get(flightEngineTrueFalseMapping, _.get(hitchhicker, "data.is_own")),
    ],
    [
      "Is Master Account",
      null,
      _.get(
        flightEngineTrueFalseMapping,
        _.get(hitchhicker, "data.is_master_account")
      ) || "N/A",
    ],
    ["Website Api Key", "website_api_key"],
    [
      "Queue",
      null,
      _.get(hitchhicker, "data.queue") === 0
        ? "0"
        : _.get(hitchhicker, "data.queue") || "N/A",
    ],
    [
      "Use MCRE",
      null,
      _.get(flightEngineTrueFalseMapping, _.get(hitchhicker, "data.use_mcre")),
    ],
    ["MCRE Branch", "mcre_branch"],
    [
      "Ticket After Booking",
      null,
      _.get(
        flightEngineTrueFalseMapping,
        _.get(hitchhicker, "data.ticket_after_booking")
      ),
    ],
    ["Account Number", "account_number"],
    ["Received From", "received_from"],
    [
      "Environment",
      null,
      _.get(enviromentOptions, _.get(hitchhicker, "data.environment")),
    ],
    [
      "Status",
      null,
      <ReservationsStatusTag status={_.get(hitchhicker, "data.status")} />,
    ],
  ];
  const creatorData = [
    [
      "Created",
      null,
      DateTime.fromISO(_.get(hitchhicker, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];
  const editorData = [
    [
      "Edited",
      null,
      DateTime.fromISO(_.get(hitchhicker, "data.edited")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ) || "N/A",
    ],
  ];
  const crsData = [
    ["CRS Id", "crs_id"],
    ["CRS Profile", "crs_profile"],
  ];

  const usageInfo = [
    [
      "Searches",
      null,
      _.get(hitchhicker, "data.searches", 0) === 0
        ? "0"
        : _.get(hitchhicker, "data.searches"),
    ],
    [
      "Max Searches",
      null,
      _.get(hitchhicker, "data.max_searches", 0) === 0
        ? "0"
        : _.get(hitchhicker, "data.max_searches"),
    ],
    [
      "Bookings",
      null,
      _.get(hitchhicker, "data.bookings", 0) === 0
        ? "0"
        : _.get(hitchhicker, "data.bookings"),
    ],
    [
      "Max Bookings",
      null,
      _.get(hitchhicker, "data.max_bookings", 0) === 0
        ? "0"
        : _.get(hitchhicker, "data.max_bookings"),
    ],
  ];
  return (
    <div className={classes.DetailFlightAPIConfigurationsModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Hitchhicker API Configuration Details</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {retrieveLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicData" && (
                <React.Fragment>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Basic Data"}
                        cardInfo={basicDataCpl}
                        objectData={_.get(hitchhicker, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Creator Data"}
                        cardInfo={creatorData}
                        objectData={_.get(hitchhicker, "data", {})}
                      />
                      <GenericBasicInfo
                        header={"Editor Data"}
                        cardInfo={editorData}
                        objectData={_.get(hitchhicker, "data", {})}
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header="CRS Data"
                        cardInfo={crsData}
                        objectData={_.get(hitchhicker, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header="Usage Data"
                        cardInfo={usageInfo}
                        objectData={_.get(hitchhicker, "data", {})}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activeTab === "logs" && <AviateLogsTab />}
            </React.Fragment>
          )}
        </div>
        <div className={classes.cardActions}>
          <CustomButton appearance="ghost" onClick={() => onClose()}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailFlightAPIConfigurationsModal.propTypes = {
  uid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DetailFlightAPIConfigurationsModal;
