import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import { genericRequestErrorHandler } from "../../../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteFlightApiConfiguration } from "../../../../../api/FlightEngine/FlightAPIConfigurations";
import Loader from "../../../../../components/Loader";
import { flightEngineTrueFalseMapping } from "../../../common";
import _ from "lodash";
import { CustomButton } from "../../../../../components/CustomButton";

const enviromentOptions = {
  "": "-----",
  STAG: "Staging",
  PROD: "Production",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteFlightAPIConfigurationsModal: modalGenericStyles.modal,
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardBody: cardStyles.body,
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    gridTemplateColumns: "repeat(2, max-content)",
  },
});

const DeleteFlightAPIConfigurationsModal = ({ deletedUid, data, onClose }) => {
  const classes = styles();
  const queryClient = useQueryClient();

  const basicDataCpl = [
    ["Uid", "uid"],
    ["User", "user"],
    ["Name", null, _.capitalize(_.get(data, "name"))],
    ["Source Entity", "source_entity"],
    ["Market Type", "market_type"],
    [
      "Is Own",
      null,
      _.get(flightEngineTrueFalseMapping, _.get(data, "is_own")),
    ],
    ["MCRE Branch", "mcre_branch"],
    [
      "Ticket After Booking",
      null,
      _.get(data, "ticket_after_booking") === 0
        ? "0"
        : _.get(data, "ticket_after_booking"),
    ],
    ["Account Number", "account_number"],
    [
      "Use MCRE",
      null,
      _.get(flightEngineTrueFalseMapping, _.get(data, "use_mcre")),
    ],
    ["Received From", "received_from"],
    ["Environment", null, _.get(enviromentOptions, _.get(data, "environment"))],
  ];
  const deleteFlightApiConfigurationMutation = useMutation(
    deleteFlightApiConfiguration,
    {
      onSuccess: () => {
        toast.success("Flight Api Configuration Deleted");
        queryClient.invalidateQueries("FlightsAPIConfigurations");
        onClose();
      },
      onError: (error) => genericRequestErrorHandler(error),
    }
  );
  const onDelete = () => {
    deleteFlightApiConfigurationMutation.mutate({
      uid: deletedUid,
    });
  };

  return (
    <div
      id="DeleteHitchhikerFlModal"
      className={classes.DeleteFlightAPIConfigurationsModal}>
      <React.Fragment>
        <div className={classes.modalCard}>
          {deleteFlightApiConfigurationMutation.isLoading && (
            <Loader onTop={true} />
          )}
          <div className={classes.modalCardHeader}>
            <span>DELETE Hitchhicker Api Configuration</span>
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.card}>
              <div className={classes.cardBody}>
                <GenericBasicInfo
                  header={"Are you sure you want to delete this?"}
                  cardInfo={basicDataCpl}
                  objectData={data}
                />
              </div>
            </div>
          </div>
          <div className={classes.cardActions}>
            <CustomButton
              id="DeleteHitchhikerFlModalCloseButton"
              appearance="ghost"
              onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton
              id="DeleteHitchhikerFlModalConfirmButton"
              appearance="primary"
              onClick={() => {
                onDelete();
              }}>
              DELETE
            </CustomButton>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
DeleteFlightAPIConfigurationsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  deletedUid: PropTypes.string.isRequired,
};
export default DeleteFlightAPIConfigurationsModal;
