import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios } from "../../../App";

export function fetchPoliciesB2B({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/policies/b2b/`, {
    params,
  });
}

export async function retrievePoliciesB2B({ id }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crm/policies/b2b/${id}`
  );
}

export function fetchPoliciesB2BGEOCountries() {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/cms/countries/`);
}

export function fetchPoliciesB2BTravelServices() {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/travel-services`);
}
