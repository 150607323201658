import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import { DateTime } from "luxon";
import { countries } from "../../../../../helpers";
import { useQuery } from "@tanstack/react-query";
import { retrieveUnififiAPIConfiguration } from "../../../../../api/FlightEngine/Unififi";
import { genericRequestErrorHandler } from "../../../../../api";
import { CustomButton } from "../../../../../components/CustomButton";
import Loader from "../../../../../components/Loader";
import UnififiLogsTab from "./UnififiLogsTab";
import TabStrip from "../../../../../components/TabStrip";
import ReservationsStatusTag from "../../../../../components/Tags/ReservationsStatusTag";

const gdsMapping = {
  "": "-----",
  "1S": "Sabre",
  "1G": "Galileo",
  "1A": "Amadeus",
  F1: "Farelogix",
  "1U": "HH Unififi",
  AV: "Aviate",
  UF: "Unififi",
};

const enviromentOptions = {
  "": "-----",
  STAG: "Staging",
  PROD: "Production",
};

const trueFalseMapping = {
  true: "Yes",
  false: "No",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailUnififiAPIConfigurationsModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardBody: cardStyles.body,
  row: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  secondRow: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    justifyContent: "space-evenly",
    gap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    row: {
      gridTemplateColumns: "2fr 1fr",
    },
  },
});

const DetailUnififiAPIConfigurationsModal = ({ uid, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicData");

  const menuTabs = {
    basicData: "Basic Data",
    logs: "Logs",
  };

  const { data: unififi, isLoading: retrieveLoading } = useQuery({
    queryKey: ["DetailAccEngineModal", uid],
    queryFn: async () => {
      return await retrieveUnififiAPIConfiguration({ uid: uid });
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const basicDataCpl = [
    ["Uid", "uid"],
    ["Name", null, _.capitalize(_.get(unififi, "data.name"))],
    ["Source Entity", "source_entity"],
    ["Market Type", "market_type"],
    ["Currency", "currency"],
    ["Country", null, _.get(countries, _.get(unififi, "data.country_code"))],
    ["API Url", "api_url"],
    ["PCC", "pcc"],
    ["GDS", null, _.get(gdsMapping, _.get(unififi, "data.gds"))],
    [
      "Is Global",
      null,
      _.get(trueFalseMapping, _.get(unififi, "data.is_global")),
    ],
    ["Is Own", null, _.get(trueFalseMapping, _.get(unififi, "data.is_own"))],
    [
      "Is Master Account",
      null,
      _.get(trueFalseMapping, _.get(unififi, "data.is_master_account")) ||
        "N/A",
    ],
    ["Website Api Key", "website_api_key"],
    ["Search Url", "search_url"],
    ["Received From", "received_from"],
    [
      "Environment",
      null,
      _.get(enviromentOptions, _.get(unififi, "data.environment")),
    ],
    [
      "Status",
      null,
      <ReservationsStatusTag status={_.get(unififi, "data.status")} />,
    ],
  ];
  const usageInfo = [
    [
      "Searches",
      null,
      _.get(unififi, "data.searches", 0) === 0
        ? "0"
        : _.get(unififi, "data.searches"),
    ],
    [
      "Max Searches",
      null,
      _.get(unififi, "data.max_searches", 0) === 0
        ? "0"
        : _.get(unififi, "data.max_searches"),
    ],
    [
      "Bookings",
      null,
      _.get(unififi, "data.bookings", 0) === 0
        ? "0"
        : _.get(unififi, "data.bookings"),
    ],
    [
      "Max Bookings",
      null,
      _.get(unififi, "data.max_bookings", 0) === 0
        ? "0"
        : _.get(unififi, "data.max_bookings"),
    ],
  ];
  const creatorData = [
    [
      "Created",
      null,
      DateTime.fromISO(_.get(unififi, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];
  const editorData = [
    [
      "Edited",
      null,
      DateTime.fromISO(_.get(unififi, "data.edited")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];
  const accessData = [
    ["Customer Id", "customer_id"],
    ["App Key", "app_key"],
    ["App Secret", "app_secret"],
  ];

  return (
    <div className={classes.DetailUnififiAPIConfigurationsModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Unififi API Configuration Details</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {retrieveLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicData" && (
                <React.Fragment>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Basic Data"}
                        cardInfo={basicDataCpl}
                        objectData={_.get(unififi, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Creator Data"}
                        cardInfo={creatorData}
                        objectData={_.get(unififi, "data", {})}
                      />
                      <GenericBasicInfo
                        header={"Editor Data"}
                        cardInfo={editorData}
                        objectData={_.get(unififi, "data", {})}
                      />
                    </div>
                  </div>
                  {/* </div> */}
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header="Access Data"
                        cardInfo={accessData}
                        objectData={_.get(unififi, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header="Usage Data"
                        cardInfo={usageInfo}
                        objectData={_.get(unififi, "data", {})}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activeTab === "logs" && <UnififiLogsTab />}
            </React.Fragment>
          )}
        </div>

        <div className={classes.modalCardActions}>
          <CustomButton appearance="ghost" onClick={() => onClose()}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailUnififiAPIConfigurationsModal.propTypes = {
  uid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DetailUnififiAPIConfigurationsModal;
