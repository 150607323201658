import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  formSections,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import PropTypes from "prop-types";
import Loader from "../../../../components/Loader";
import _ from "lodash";
import { CustomButton } from "../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  EditCRSTagModal: {
    ...modalGenericStyles.modal,
  },
  // card: { ...modalGenericStyles.card, width: "100%" },
  // cardBody: {
  //   ...cardStyles.body,
  //   display: "grid",
  //   gridTemplateColumns: "repeat(1, 1fr)",
  //   gridGap: variables.normal_gap,
  // },
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    display: "grid",
    gridTemplateColumns: "36rem 1fr",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  tagsActions: {
    ...modalGenericStyles.cardActions,
    justifyContent: "start",
    height: "1.5rem",
    alignSelf: "end",
  },
  tags: {
    ...modalGenericStyles.card,
    display: "grid",
    gridColumn: "span 2",
    lineHeight: "5vh",
  },
  inputGroup: {
    ...formInputGroup,
  },
  formSectionHeader: formSections.sectionHeader,
  [`@media ${variables.media.smallscreen}`]: {
    modalCardBody: {
      width: "50rem",
      minHeight: "10rem",
    },
  },
});
const EditCRSTagModal = ({
  onSubmitTags,
  tagsLoading,
  onClose,
  tags: arrayTags,
  header,
}) => {
  const classes = styles();
  const [form, setForm] = useState({
    tags: _.isEmpty(arrayTags) ? [""] : arrayTags,
  });

  // const boolFields = convertStringToBoolean({

  // });

  const onSubmit = async () => {
    let newTags = form.tags.filter((tag) => tag !== "");
    let payload = { tags: [...new Set(newTags)] };
    onSubmitTags({ ...payload });
  };

  return (
    <div id="EditCRSTagModal" className={classes.EditCRSTagModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Edit {header}</span>
        </div>
        <div className={classes.modalCardBody}>
          {tagsLoading ? (
            <Loader />
          ) : (
            <form
              onChange={(e) =>
                setForm((p) => ({
                  ...p,
                  [e.target.name]: e.target.value,
                }))
              }>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="tags">Tag</label>
                    <input
                      id="tag"
                      type="text"
                      value={_.get(form, "tags.0", "")}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          tags: [
                            ...form.tags.map((tag, idx) =>
                              idx === 0 ? e.target.value : tag
                            ),
                          ],
                        });
                      }}
                    />
                  </div>
                  <div className={classes.tagsActions}>
                    {(_.get(form, "tags", []).length !== 1 ||
                      _.get(form, "tags.0", "")) !== "" && (
                      <CustomButton
                        id="removeTagButton"
                        appearance="ghost"
                        onClick={(e) => {
                          e.preventDefault();
                          if (_.get(form, "tags", []).length > 1) {
                            let newTags = form.tags;
                            newTags.splice(0, 1);
                            setForm({ tags: [...newTags] });
                            return;
                          }
                          setForm({ ...form, tags: [""] });
                        }}>
                        <strong>Remove</strong>
                      </CustomButton>
                    )}

                    {_.get(form, "tags", [""]).length === 1 && (
                      <CustomButton
                        id="addTagButton"
                        appearance="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({ tags: [...form.tags, ...[""]] });
                        }}>
                        <strong>Add Tag</strong>
                      </CustomButton>
                    )}
                  </div>
                </div>
              </div>
              {_.get(form, "tags").map(
                (tag, tagIdx) =>
                  tagIdx > 0 && (
                    <React.Fragment>
                      <div className={classes.card}>
                        <div className={classes.cardBody}>
                          <div className={classes.inputGroup}>
                            <label htmlFor="tags">Tag{tagIdx + 1}</label>
                            <input
                              id={`MultipleTags_${tagIdx}`}
                              type="text"
                              value={_.get(form, `tags.${tagIdx}`, "")}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  tags: [
                                    ...form.tags.map((tag, idx) =>
                                      idx === tagIdx ? e.target.value : tag
                                    ),
                                  ],
                                });
                              }}
                            />
                          </div>
                          <div className={classes.tagsActions}>
                            <CustomButton
                              id={`removeTagButton_${tagIdx}`}
                              appearance="ghost"
                              onClick={(e) => {
                                e.preventDefault();
                                if (_.get(form, "tags", []).length > 1) {
                                  let newTags = form.tags;
                                  newTags.splice(tagIdx, 1);
                                  setForm({ tags: [...newTags] });
                                  return;
                                }
                                setForm({ ...form, tags: [""] });
                              }}>
                              <strong>Remove</strong>
                            </CustomButton>
                            {_.get(form, "tags", []).length === tagIdx + 1 && (
                              <CustomButton
                                id={`addMultipleTagButton_${tagIdx}`}
                                appearance="primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setForm({ tags: [...form.tags, ...[""]] });
                                }}>
                                <strong>Add Tag</strong>
                              </CustomButton>
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )
              )}
            </form>
          )}
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton
            id="cancel"
            appearance="ghost"
            onClick={() => onClose()}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            id="submit"
            appearance="primary"
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
EditCRSTagModal.defaultProps = {
  tags: [],
};
EditCRSTagModal.propTypes = {
  header: PropTypes.string.isRequired,
  tagsLoading: PropTypes.bool.isRequired,
  tags: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitTags: PropTypes.func.isRequired,
};
export default EditCRSTagModal;
