import React from "react";
import { createUseStyles } from "react-jss";
import { fullDater, medWeekdayDate } from "../../helpers";
import _ from "lodash";
import PropTypes from "prop-types";

const useStyles = createUseStyles({
  optionBookingContainer: {
    display: "inline-block",
    width: "fit-content",
    padding: "4px 10px 4px 10px",
    borderRadius: "5px",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  optionBookingOrange: {
    backgroundColor: "orange",
  },
  optionBookingRed: { backgroundColor: "red" },
  optionBookingGreen: { backgroundColor: "green" },
  optionBookingBlue: { backgroundColor: "blue" },
});

const CRSCxlCell = ({ val }) => {
  const classes = useStyles();

  if (val.option_booking) {
    const optionDueDate = _.get(val, "option_due_date", null);
    return (
      <div
        className={`${classes.optionBookingContainer} ${classes.optionBookingBlue}`}>
        Option
        <br />
        {optionDueDate ? fullDater(optionDueDate) : "N/A"}
        {/* {fullDater(_.get(val, "option_due_date", ""))} */}
      </div>
    );
  }

  const isNonRefundable = val.non_refundable;
  const cxlClass = isNonRefundable
    ? classes.optionBookingRed
    : classes.optionBookingGreen;
  const maxCancellationDate = _.get(val, "max_cancellation_date", null);
  return (
    <div className={`${classes.optionBookingContainer} ${cxlClass}`}>
      {isNonRefundable
        ? "Non Refundable"
        : maxCancellationDate
        ? `Refundable ${medWeekdayDate(maxCancellationDate)}`
        : "Refundable N/A"}
      {/* : `Refundable ${medWeekdayDate(val.max_cancellation_date)}`} */}
    </div>
  );
};
CRSCxlCell.defaultProps = {
  val: {
    option_booking: false,
    option_due_date: "",
    non_refundable: false,
    max_cancellation_date: "",
  },
};
CRSCxlCell.propTypes = {
  val: PropTypes.shape({
    option_booking: PropTypes.bool,
    option_due_date: PropTypes.string,
    non_refundable: PropTypes.bool,
    max_cancellation_date: PropTypes.string,
  }),
};

export default CRSCxlCell;
