import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useMutation } from "@tanstack/react-query";
import _ from "lodash";
import { toast } from "react-toastify";
import { genericRequestErrorHandler } from "../../../../api";
import Loader from "../../../../components/Loader";
import { translate } from "../../../../api/DistributionSite/webSites";
import { variables } from "../../../../variables";
import { CustomButton } from "../../../../components/CustomButton";

const languageMapping = {
  cn: "zh",
};

const getSubfields = ({
  originField,
  fieldSubfields,
  form,
  origin_lang,
  target_lang,
}) => {
  const translations = [];

  // the subfield must have a type and fields
  // if (_.get(fieldSubfields, "fields")) {
  //   return [];
  // }
  if (fieldSubfields.length === 0) {
    return [];
  }

  const originFieldValue = _.get(form, originField);
  // if the subfield is an array
  if (Array.isArray(originFieldValue)) {
    const subfieldValues = _.get(form, originField, []);
    subfieldValues.forEach((subfieldValue) => {
      // for each field in the subfield
      fieldSubfields.forEach((fld) => {
        const origin_text = _.get(subfieldValue, fld);
        // const origin_text = _.get(values, `${originField}.${index}.${field}`);
        if (!origin_text) return;
        translations.push({
          origin_text: origin_text,
          origin_lang: origin_lang,
          target_lang: target_lang,
        });
      });
    });
    return translations;
  }

  // if the subfield is an object
  if (typeof fieldSubfields === "object") {
    fieldSubfields.forEach((field) => {
      const origin_text = _.get(form, `${originField}.${field}`);
      if (!origin_text) return;

      translations.push({
        origin_text: origin_text,
        origin_lang: origin_lang,
        target_lang: target_lang,
      });
    });
    return translations;
  }

  // if another type is passed
  return [];
};

const getFullPayload = ({
  originLang,
  targetLang,
  fields,
  subfields,
  form,
  setForm,
}) => {
  const payload = { translations: [] };
  const subFieldsKeys = Object.keys(subfields);
  fields.forEach((field) => {
    let originField = `${field}_${originLang}`;
    if (originLang === "en" && _.get(form, field)) {
      // if (originLang === "en" && _.get(values, field)) {
      originField = field;
    }
    var target_lang = targetLang;
    if (_.get(languageMapping, targetLang)) {
      target_lang = languageMapping[targetLang];
    }

    // if the field is a subfield add the payload and continue
    // to the next field
    if (subFieldsKeys.includes(field)) {
      const newTranslations = getSubfields({
        originField: originField,
        fieldSubfields: subfields[field],
        form,
        // values,
        origin_lang: originLang,
        target_lang: target_lang,
      });
      payload["translations"].push(...newTranslations);
      return;
    }

    // if the field is not a subfield run the normal flow
    let origin_text = form[originField];
    // let origin_text = values[originField];
    if (!origin_text) return;

    payload["translations"].push({
      origin_text: origin_text,
      origin_lang: originLang,
      target_lang: target_lang,
    });
  });
  return payload;
};

const setNewSubfieldsValues = ({
  originField,
  targetKey,
  subfields,
  form,
  setForm,
  originText,
  targetText,
}) => {
  const fieldValues = _.get(form, targetKey);

  // if the field is an array
  if (Array.isArray(fieldValues)) {
    var newFieldValues = [...fieldValues];
    newFieldValues.map((fieldValue, idx) => {
      // check every subfield
      subfields.forEach((subfield) => {
        const originKey = `${originField}.${idx}.${subfield}`;
        const originValue = _.get(form, originKey);
        // if the field is not the origin field continue to the next field
        if (originValue !== originText) {
          return;
        }
        newFieldValues[idx][subfield] = targetText;
      });
      setForm((p) => ({ ...p, [targetKey]: newFieldValues }));
    });
    return;
  }
  // if the field is an object
  if (typeof fieldValues === "object") {
    const newFieldValue = { ...fieldValues };
    subfields.forEach((field) => {
      const originValue = _.get(form, `${originField}.${field}`);

      // if the field is not the origin field continue to the next field
      if (originValue !== originText) {
        return;
      }

      newFieldValue[field] = targetText;
    });
    setForm((p) => ({ ...p, [targetKey]: newFieldValue }));
    return;
  }
};

const setNewValues = ({ data, fields, subfields, form, setForm }) => {
  const translations = _.get(data, "translations", []);
  const valuesKeys = Object.keys(form);
  translations.forEach((t) => {
    const originLang = _.get(t, "origin_lang");
    const originText = _.get(t, "origin_text");
    const targetLang = _.get(t, "target_lang");
    const targetText = _.get(t, "target_text");

    fields.forEach((field) => {
      let originField = `${field}_${originLang}`;
      // we check if the english field key has no suffix
      if (originLang === "en" && valuesKeys.includes(field)) {
        originField = field;
      }

      const reverseLanguageMapping = _.invert(languageMapping);
      const mappedKey = `${field}_${_.get(reverseLanguageMapping, targetLang)}`;
      const defaultTargetKey = `${field}_${targetLang}`;

      var targetKey = valuesKeys.includes(mappedKey)
        ? mappedKey
        : defaultTargetKey;
      if (targetLang === "en" && valuesKeys.includes(field)) {
        targetKey = field;
      }

      // if the field has subfields run the subfields flow
      if (Object.keys(subfields).includes(field)) {
        setNewSubfieldsValues({
          originField,
          targetKey,
          subfields: subfields[field],
          form,
          setForm,
          originText,
          targetText,
        });
        return;
      }

      // if the field is not a subfield run the normal flow
      const originValue = form[originField];
      if (originValue !== originText) {
        return;
      }
      setForm((p) => ({ ...p, [targetKey]: targetText }));
    });
  });
};

const styles = createUseStyles({
  TranslateButton: {},
  buttonGroup: {
    position: "relative",
    display: "inline-block",
    verticalAlign: "middle",
  },
  dropdownMenu: {
    position: "absolute",
    width: "18rem",
    left: "0",
    zIndex: "1000",
    minWidth: "10rem",
    padding: "0.5rem 0",
    margin: "0.125rem 0 0",
    fontSize: "1rem",
    color: "#212529",
    textAlign: "left",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    borderRadius: "0.25rem",
  },
  dropdownItem: {
    display: "block",
    width: "100%",
    padding: "0.25rem 1.5rem",
    clear: "both",
    fontWeight: "400",
    color: "#212529",
    textAlign: "inherit",
    whiteSpace: "nowrap",
    backgroundColor: "transparent",
    border: "0",
    "&:hover": {
      color: "#fff",
      textDecoration: "none",
      fontWeight: "bold",
      //   backgroundColor: variables.colors.easy.orange,
      backgroundColor: variables.colors.background.dark,
      cursor: "pointer",
    },
    "&:focus": {
      color: "#fff",
      fontWeight: "bolder",
      backgroundColor: "#007bff",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    dropdownMenu: {
      position: "absolute",
      right: "0",
      left: "auto",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    dropdownMenu: {
      position: "absolute",
      right: "0",
      left: "auto",
    },
  },
});
const TranslateButton = ({
  langs,
  fields,
  subfields,
  targetLang,
  form,
  setForm,
}) => {
  const classes = styles();
  const [showDropdown, setShowDropdown] = useState(false);

  const translateMutation = useMutation({
    mutationFn: ({ originLang }) => {
      var payload = getFullPayload({
        originLang,
        targetLang,
        fields,
        subfields,
        form,
      });

      if (_.isEmpty(payload.translations)) {
        toast.error("No fields to translate");
        return;
      }
      return translate({ payload });
    },
    onSuccess: (data) => {
      setNewValues({
        data: _.get(data, "data"),
        fields,
        subfields,
        form,
        setForm,
      });
      setShowDropdown(false);
      toast.success("Fields translated successfully");
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  return (
    <div className={classes.TranslateButton}>
      {translateMutation.isLoading ? (
        <Loader size="sm" onTop={true} />
      ) : (
        <div className={classes.buttonGroup}>
          <CustomButton
            appearance="primary"
            onClick={() => setShowDropdown((p) => !p)}>
            <strong>Translate</strong>
          </CustomButton>
          {showDropdown && (
            <div className={classes.dropdownMenu}>
              {Object.keys(langs)
                .filter(
                  (lang) =>
                    lang !== (_.get(languageMapping, targetLang) || targetLang)
                )
                .map((lang) => (
                  <div
                    key={lang}
                    className={classes.dropdownItem}
                    onClick={() => {
                      translateMutation.mutate({
                        originLang: languageMapping[lang] || lang,
                      });
                    }}>
                    Translate{" "}
                    {_.get(languageMapping, targetLang)
                      ? langs[_.get(languageMapping, targetLang)]
                      : langs[targetLang]}{" "}
                    from {langs[lang]}
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
TranslateButton.defaultProps = {
  langs: {
    en: "English",
    de: "German",
    el: "Greek",
    es: "Spanish",
    fr: "French",
    it: "Italian",
    ja: "Japanese",
    nl: "Dutch",
    pt: "Portuguese",
    th: "Thai",
    zh: "Chinese",
    lt: "Lithuanian",
  },
  fields: ["title", "subtitle", "description"],
  subfields: {},
};
TranslateButton.propTypes = {
  targetLang: PropTypes.string.isRequired,
  langs: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.string),
  subfields: PropTypes.object,
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func,
};
export default TranslateButton;
