import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  GenericNavbarsStyles,
  inputGroup,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { onEnterDown } from "../../../../../helpers";
import { CustomButton } from "../../../../../components/CustomButton";
import EntityAutocomplete from "../../../../../components/EntityAutocomplete";
import { marketTypeMapping } from "../../common";

const vendorTypes = [
  ["", "-----"],
  ["car__Carnect", "Carnect"],
];

const statusOptions = [
  ["", "-----"],
  ["AC", "Active"],
  ["IN", "Inactive"],
  ["CA", "Candidate"],
];

const navbarStyles = createUseStyles({
  Navbar: {
    ...GenericNavbarsStyles.navbars.mobile(),
    gridTemplateRows: "max-content max-content max-content max-content ",
  },
  label: { ...inputGroup },
  divider: GenericNavbarsStyles.divider.mobile(),
  filters: GenericNavbarsStyles.filters.mobile(),
  actions: GenericNavbarsStyles.actions.mobile(),
  inputGroup: {
    ...inputGroup,
    width: "100%",
    "& input, select": {
      ...inputGroup["& input, select"],
      width: "100%",
    },
  },
  inputGroupOneLine: {
    ...inputGroup,
    gridColumn: "2 span",
    width: "100%",
  },
  [`@media ${variables.media.smallscreen}`]: {
    Navbar: {
      ...GenericNavbarsStyles.navbars.smallscreen(),
      gridTemplateColumns: "repeat(2, max-content) 1fr max-content",
      gridTemplateRows: "max-content",
    },
    divider: GenericNavbarsStyles.divider.smallscreen(),
    filters: {
      ...GenericNavbarsStyles.filters.smallscreen(),
      gridTemplateColumns: "repeat(5, 1fr)",
    },
    actions: GenericNavbarsStyles.actions.smallscreen(),
    inputGroup: {
      ...inputGroup,
    },
    inputGroupOneLine: { ...inputGroup.bigscreen, gridColumn: "1 span" },
  },
  [`@media ${variables.media.bigscreen}`]: {
    filters: {
      // gridTemplateColumns: "repeat(4, 1fr)",
    },
  },
});
const Navbar = ({ filters, initialFilters, onApplyFilters, onAdd }) => {
  const classes = navbarStyles();

  const [localFilters, setLocalFilters] = useState(filters);
  const [autocompleteValue, setAutocompleteValue] = useState("");

  return (
    <div className={classes.Navbar}>
      <div className={classes.label}>
        <strong>Car Rental</strong>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.filters}>
        <div className={classes.inputGroup}>
          <label>Vendor</label>
          <select
            id="filter_vendor"
            value={localFilters.vendor}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                vendor: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {vendorTypes.map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Source Entity</label>
          <EntityAutocomplete
            id="filterSourceEntityId"
            subagents={false}
            independentAgents={false}
            branches={false}
            value={autocompleteValue}
            setValue={setAutocompleteValue}
            onChange={(text) => {
              if (text.length === 0) {
                setLocalFilters((p) => ({
                  ...p,
                  member: "",
                }));
                return;
              }
              const entityInfos = text.split("---");
              const entityId = entityInfos[1];
              setLocalFilters((p) => ({
                ...p,
                member: `${entityId}`,
              }));
            }}
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Market Type</label>
          <select
            id="filterMarketType"
            value={localFilters.market_type}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                market_type: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(marketTypeMapping).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Config uid</label>
          <input
            id="filter_config_uid"
            type="text"
            value={localFilters.config_uid__contains}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                config_uid__contains: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Created After</label>
          <input
            id="filter_created__gte"
            type="date"
            value={localFilters.created__gte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                created__gte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Created Before</label>
          <input
            id="filter_created__lte"
            type="date"
            value={localFilters.created__lte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                created__lte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Status</label>
          <select
            id="filter_status"
            value={localFilters.status}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                status: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {statusOptions.map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={classes.actions}>
        <CustomButton
          id="ResetFiltersButton"
          appearance="ghost"
          onClick={() => {
            setLocalFilters({
              ...initialFilters,
            });
            setAutocompleteValue("");
          }}>
          <strong>Reset</strong>
        </CustomButton>
        <CustomButton
          id="ApplyFiltersButton"
          appearance="primary"
          onClick={() => onApplyFilters(localFilters)}>
          <strong>Apply Filters</strong>
        </CustomButton>
        <div className={classes.divider}></div>
        <CustomButton
          id="AddCARVendorButton"
          appearance="ghost"
          onClick={onAdd}>
          <strong>Add</strong>
        </CustomButton>
      </div>
    </div>
  );
};
Navbar.propTypes = {
  filters: PropTypes.object.isRequired,
  initialFilters: PropTypes.object.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default Navbar;
