import React from "react";
import { createUseStyles } from "react-jss";
import { cardStyles, modalGenericStyles, variables } from "../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import Loader from "../Loader";
import { CustomButton } from "../CustomButton";
import DetailedLog from "../cards/DetailedLog";

export const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailLogModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    overflowX: "hidden",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: { ...cardStyles.card, overflow: "auto" },
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  greenText: {
    color: "green",
  },
  redText: {
    color: "red",
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardBody: { justifyContent: "left" },
  },
});
const DetailLogModal = ({ data, onClose }) => {
  const classes = styles();

  return (
    <div id="userActionDetailLogs" className={classes.DetailLogModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Log Details</span>
        </div>
        <div className={classes.modalCardBody}>
          {_.isEmpty(data) ? (
            <Loader />
          ) : (
            <div className={classes.card}>
              <DetailedLog log_source={_.get(data, "_source", {})} />
            </div>
          )}
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton id="close" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailLogModal.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DetailLogModal;
