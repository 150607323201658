import { VALAQUENTA_API_URL } from "..";
import { authorizedAxios, loggingAxios } from "../../App";

export function fetchAirports({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/flightengine/airports/`, {
    params,
  });
}

export function retrieveAirport({ id }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/flightengine/airports/${id}/`,
    {}
  );
}

export function patchAirport({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/flightengine/airports/${id}/`,
    payload
  );
}

export function createNewAirport({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/flightengine/airports/`,
    payload
  );
}

// export function cloneAirport({ id }) {
//   return loggingAxios.post(
//     `${VALAQUENTA_API_URL}/flightengine/airports/clone/${id}/`,
//   );
// }
