import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  formSections,
  inputGroup,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import _ from "lodash";
import {
  createCRMComments,
  patchCRMComments,
  retrieveCRMComments,
} from "../../../../api/CRM/Members/Members";
import { useAuthentication } from "../../../../hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";
import * as yup from "yup";
import { CustomButton } from "../../../../components/CustomButton";

const jsonSchema = yup.object().shape({});

const initialForm = {
  title: "",
  text: "",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditCRMComment: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    display: "grid",
    gap: variables.normal_gap,
  },
  inputGroup: {
    ...formInputGroup,
    // "& input, select": {
    //   ...inputGroup["& input, select"],
    //   width: "97%",
    // },
  },
  inputGroupOneLineBtn: {
    display: "grid",
    gridColumn: "span 2",
    gap: variables.half_gap,
    paddingBottom: variables.half_gap,
    justifyContent: "end",
    gridTemplateColumns: "repeat(2, auto)",
    width: "100%",
  },
  form: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: variables.half_gap,
    padding: variables.half_gap,
    maxWidth: "80vw",
    alignItems: "end",
  },
  formSectionHeader: formSections.sectionHeader,
  inputGroupOneLine: {
    ...inputGroup,
    gridColumn: "2 span",
    width: "100%",
    paddingTop: variables.normal_gap,
  },
  cardActions: { width: "100%", textAlign: "end" },

  [`@media ${variables.media.smallscreen}`]: {
    cardCol2: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
});

const EditCRMComment = ({
  id,
  entityId,
  source_entity,
  onClose,
  entityType,
}) => {
  const classes = styles();
  const mode = id ? "EDIT" : "ADD";
  const { user } = useAuthentication();

  const creator_metadata = {
    first_name: user.name.split("__")[0],
    last_name: user.name.split("__")[1],
    email: user.email,
    username: user.sub,
  };

  const [form, setForm] = useState({
    ...initialForm,
    source_entity: `${entityId}___${entityType}`,
    creator_metadata,
  });

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["EditComments", id],
    queryFn: () => retrieveCRMComments({ id }),
    // enabled: mode === "edit",
    onSuccess: (data) => {
      setForm(_.get(data, "data"));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const editMutation = useMutation({
    mutationFn: async ({ id, payload }) => {
      return patchCRMComments({ id, payload });
    },
    onSuccess: () => {
      toast.success("Comment Info Updated");
      queryClient.invalidateQueries("ActivitiesAPIConfigurations");
      onClose();
    },
  });

  const addMutation = useMutation({
    mutationFn: async ({ payload }) => {
      return createCRMComments({ payload });
    },
    onSuccess: () => {
      toast.success("New Comment added");
      queryClient.invalidateQueries("CommentsCard");
      onClose();
    },
  });

  const onSubmit = async ({ id, payload }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "ADD") {
        addMutation.mutate({ payload });
      } else {
        editMutation.mutate({ id, payload });
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        if (!validationErrors[err.path]) {
          validationErrors[err.path] = err.message;
          toast.error(err.message);
        }
      });
    }
  };
  const loading =
    (mode === "EDIT" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;
  return (
    <div id="addEditCRMCommentModal" className={classes.EditCRMComment}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            {_.startCase(mode)} Comment Info
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className={classes.modalCard}>
            <div className={classes.modalCardHeader}>
              {_.startCase(mode)} Comment Info
            </div>
            <div className={classes.modalCardBody}>
              <form>
                <div className={classes.card}>
                  <h5 className={classes.cardHeader}>Basic Information</h5>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol2}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="title">Title</label>
                        <input
                          id="title"
                          type="text"
                          name="title"
                          value={form.title}
                          onChange={(e) =>
                            setForm((p) => ({
                              ...p,
                              [e.target.name]: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="name">Description</label>
                        <input
                          id="text"
                          // rows="5"
                          // cols="33"
                          type="text"
                          name="text"
                          value={form.text}
                          onChange={(e) =>
                            setForm((p) => ({
                              ...p,
                              [e.target.name]: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className={classes.modalCardActions}>
              <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
                <strong>Cancel</strong>
              </CustomButton>
              <CustomButton
                id="submit"
                appearance="primary"
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit({
                    id: id,
                    payload: form,
                  });
                }}>
                <strong>Submit</strong>
              </CustomButton>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
EditCRMComment.propTypes = {
  id: PropTypes.number,
  source_entity: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditCRMComment;
