import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { variables } from "../../../../variables";
import Navbar from "./Navbar";
import { createUseStyles } from "react-jss";
import {
  fetchHotelImages,
  patchHotelImages,
  retrieveHotel,
} from "../../../../api/accommodator/";
import { toast } from "react-toastify";
import {
  findToilets,
  imageQuality,
} from "../../../../api/accommodator/Hotels/Hotels";
import Loader from "../../../../components/Loader";
import { useAuthentication } from "../../../../hooks";
import AddImagesModal from "../ImagesModals/AddImagesModal";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";

const imageStyles = createUseStyles({
  Image: {
    overflow: "auto",
    boxShadow: variables.shadows.normalShadow,
    display: "grid",
    gridTemplateRows: "auto 1fr",
    alignItems: "center",
    paddingBottom: variables.normal_gap,
    borderRadius: "5px",
    height: "100%",
  },
  img: {
    height: "15rem",
    width: "100%",
    objectFit: "cover",
  },
  imageActions: {
    display: "grid",
    gridAutoRows: "max-content",
    gap: variables.half_gap,
    justifyContent: "center",
    padding: variables.half_gap,
  },
  action: {
    cursor: "pointer",
    lineHeight: "30px",
  },
  priorityInputContainer: {
    display: "grid",
    gridTemplateColumns: "5rem auto auto",
    gridGap: variables.half_gap,
    width: "100%",
  },
  [`@media ${variables.media.smallscreen}`]: {
    Image: {
      height: "15rem",
      overflow: "hidden",
      gridTemplateColumns: "15rem 1fr",
      padding: variables.normal_gap,
      gridGap: variables.normal_gap,
    },
    imageActions: {
      justifyContent: "start",
    },
  },
});
const Image = ({
  image,
  idx,
  isLast,
  maxPriority,
  onChangePosition,
  onDelete,
}) => {
  const classes = imageStyles();

  const [newPriority, setNewPriority] = useState(image.priority);

  useEffect(() => {
    setNewPriority(image.priority);
  }, [image]);

  function onApply() {
    const newIdx = newPriority - 1;
    onChangePosition(idx, newIdx);
  }

  return (
    <div className={classes.Image}>
      <img className={classes.img} src={image.url} />
      <div className={classes.imageActions}>
        {idx > 0 && (
          <button
            className={classes.action}
            onClick={() => onChangePosition(idx, idx - 1)}>
            <strong>Up</strong>
          </button>
        )}
        <div className={classes.priorityInputContainer}>
          <input
            className={classes.action}
            type="number"
            min={1}
            max={maxPriority}
            value={newPriority}
            onChange={(e) => {
              setNewPriority(Number(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onApply();
              }
            }}
          />
          <button onClick={onApply}>
            <strong>Apply</strong>
          </button>
          <button onClick={() => setNewPriority(image.priority)}>
            <strong>Undo</strong>
          </button>
        </div>
        {isLast && (
          <button
            className={classes.action}
            onClick={() => onChangePosition(idx, idx + 1)}>
            <strong>Down</strong>
          </button>
        )}
        <button className={classes.action} onClick={() => onDelete(idx)}>
          <strong>Delete</strong>
        </button>
      </div>
    </div>
  );
};
Image.propTypes = {
  image: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  isLast: PropTypes.bool.isRequired,
  onChangePosition: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const imagesStyles = createUseStyles({
  Images: {
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
    overflow: "auto",
  },
  content: {
    display: "grid",
    gap: variables.normal_gap,
    overflow: "auto",
  },
  [`@media ${variables.media.smallscreen}`]: {
    padding: variables.normal_gap,
  },
});
const Images = (updateImages) => {
  const classes = imagesStyles();

  const [images, setImages] = useState([]);
  const [finalImages, setFinalImages] = useState([]);
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAddImageModal, setShowAddImageModal] = useState(false);

  const { getJWT } = useAuthentication();
  const { pk } = useParams();

  const { data: hotelData } = useQuery({
    queryKey: ["retrieveHotels", pk],
    queryFn: () => retrieveHotel({ pk }),
    onError: (error) => genericRequestErrorHandler(error),
  });

  async function fetchImages() {
    setLoading(true);
    const results = await fetchHotelImages({ hotelUid: pk });
    if (!results) {
      return;
    } else {
    }
    setFinalImages(results.final_images);
    setImages(results.images);

    let newInputs = {};
    results.final_images.forEach((image, idx) => {
      newInputs[idx] = idx;
    });
    setInputs(newInputs);
    setLoading(false);
  }

  async function onFindToiletImages() {
    const jwt_token = await getJWT();

    const image_urls = finalImages.map((img) => img.url);
    const payload = {
      task_name: "toilet_finder",
      basic_config: {
        model_size: "small",
        resize: false,
        device: "cuda",
      },
      pk: pk,
    };

    const result = await findToilets({
      jwt_token,
      hotelUid: pk,
      payload,
    });
    if (result) {
      toast.success("Your task has been queued");
    }
    return null;
  }

  async function onImageQuality() {
    const jwt_token = await getJWT();
    const image_urls = finalImages.map((img) => img.url);
    const payload = {
      task_name: "image_quality",
      basic_config: {
        model_size: "small",
        resize: false,
        device: "cuda",
      },
      pk: pk,
    };

    const result = await imageQuality({
      jwt_token,
      hotelUid: pk,
      payload,
    });
    if (result) {
      toast.success("Your task has been queued");
    }
    return null;
  }

  async function patchImages(payload) {
    setLoading(true);
    const results = await patchHotelImages({ hotelUid: pk, payload });
    setLoading(false);
  }

  useEffect(() => {
    fetchImages();
  }, [showAddImageModal]);

  function changePosition(firstPlace, newPlace) {
    setFinalImages((p) => {
      let newImages = _.cloneDeep(p);
      const [removedImage] = newImages.splice(firstPlace, 1);
      newImages.splice(newPlace, 0, removedImage);
      newImages.forEach((img, idx) => {
        if (!img) {
          return;
        }
        img.priority = idx + 1;
      });
      return newImages;
    });
  }

  function deleteImage(idx) {
    const newImages = _.cloneDeep(finalImages);
    newImages.splice(idx, 1);
    newImages.forEach((img, idx) => (img.priority = idx + 1));
    setFinalImages(newImages);
  }

  function updateImages() {
    const ordering = {};
    finalImages.forEach(
      (image, idx) => (ordering[image.id.toString()] = image.priority)
    );
    var imagesForUpdate = _.cloneDeep(images.images).filter((img) =>
      Object.keys(ordering).includes(img.id.toString())
    );
    imagesForUpdate.forEach((img) => {
      img.priority = ordering[img.id.toString()];
      ordering[img.id.toString()] === 1
        ? (img.is_main = true)
        : (img.is_main = false);
    });
    imagesForUpdate = _.sortBy(imagesForUpdate, (v) => v.priority);

    const payload = { images: { images: imagesForUpdate } };

    patchImages(payload);
  }
  return (
    <div className={classes.Images}>
      <Navbar
        onAdd={() => setShowAddImageModal(true)}
        updateImages={updateImages}
        hotelData={_.get(hotelData, "data", {})}
      />
      <HamburgerMenu />
      {loading ? (
        // <div>
        <Loader />
      ) : (
        // </div>
        <div className={classes.content}>
          {finalImages.map((image, idx) => (
            <Image
              key={idx}
              idx={idx}
              image={image}
              isLast={idx + 1 < finalImages.length}
              maxPriority={finalImages.length}
              onChangePosition={changePosition}
              onDelete={deleteImage}
            />
          ))}
        </div>
      )}
      {showAddImageModal && (
        <AddImagesModal
          hotelUid={pk}
          onClose={() => {
            setShowAddImageModal(false);
          }}
        />
      )}
    </div>
  );
};
export default Images;
