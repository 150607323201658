import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { modalGenericStyles, variables } from "../../../../variables";
import { toast } from "react-toastify";
import { deleteWebSites } from "../../../../api/DistributionSite/webSites";
import Loader from "../../../../components/Loader";
import GenericBasicInfo from "../../../../components/cards/GenericBasicInfo";
import _ from "lodash";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";
import { CustomButton } from "../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteWebSiteModal: modalGenericStyles.modal,
  cardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    gridTemplateColumns: "repeat(2, max-content)",
  },
});

const DeleteWebSiteModal = ({ data, uid, onClose }) => {
  const queryClient = useQueryClient();

  const classes = styles();

  const basicDataCpl = [
    ["Api Key", "api_key"],
    ["Name ", "name"],
    ["Payment Supplier ", "payment_supplier_display"],
    ["Payment Type ", "payment_type_display"],
    ["Product Categories ", "product_categories_display"],
    ["Status ", "status_display"],
  ];

  const deleteWebsite = useMutation(deleteWebSites, {
    onSuccess: () => {
      toast.success("Website Deleted");
      queryClient.invalidateQueries("DistributionSites");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });
  const onDelete = (uid) => {
    deleteWebsite.mutate({ uid });
  };

  return (
    <div className={classes.DeleteWebSiteModal}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <span>DELETE Website</span>
        </div>
        <div className={classes.cardBody}>
          <div className={classes.basicInfo}>
            <GenericBasicInfo
              header={"Are you sure you want to delete this?"}
              cardInfo={basicDataCpl}
              objectData={data}
            />
          </div>
          <div className={classes.cardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton appearance="primary" onClick={() => onDelete(uid)}>
              <strong>DELETE</strong>
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};
DeleteWebSiteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  uid: PropTypes.number.isRequired,
};
export default DeleteWebSiteModal;
