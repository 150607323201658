import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Navbar from "./Navbar";
import ControlStrip from "../../../../components/ControlStrip";
import Loader from "../../../../components/Loader";
import {
  handleApplyFilters,
  medWeekdayDate,
  medWeekdayDatetimer,
} from "../../../../helpers";
import ReservationStats from "../../CommonCRSModals/ReservationStats/ReservationStats";
import { useQueries } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { genericRequestErrorHandler } from "../../../../api";
import { commonTableHeaders } from "../../helpers";
import DetailsCarRental from "../CarRentalsModals/DetailsCarRental";
import {
  fetchCarRentalsReservations,
  getCarRentalsReservationStats,
} from "../../../../api/CRS/CarRentals";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import { DateTime } from "luxon";
import { CustomButton } from "../../../../components/CustomButton";

const initialFilters = {
  limit: 20,
  page: 1,
  ordering: "",
  discending: "",
  status: "",
  created__gte: "",
  created__lte: "",
  source_entity: "",
  external_reference__icontains: "",
  reference: "",
  network_search: "",
  supplier_reservation_reference: "",
  car_name__icontains: "",
  pickup_location__icontains: "",
  dropoff_location__icontains: "",
  pickup_datetime__gte: "",
  dropoff_datetime__lte: "",
  creator: "",
};
const navbarSchema = yup.object().shape({
  car_name: yup
    .string()
    .min(2, "Name must be more than 2 characters")
    .max(100, "Name: Must be less than 100 characters"),
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});
const carRentalsStyles = createUseStyles({
  CarRentals: {
    ...genericIndexesStyles.indexes.mobile(),
    gridTemplateRows: "auto auto auto  1fr",
  },
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tablecell: tableStyles.body.cell,
  actionCell: {
    ...tableStyles.body.cell,
    gap: variables.half_gap,
  },
  datesCell: { ...tableStyles.body.cell, minWidth: "9rem", width: "9rem" },
  [`@media ${variables.media.smallscreen}`]: {
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const CarRentals = () => {
  const classes = carRentalsStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [reference, setReference] = useState(null);

  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const results = useQueries({
    queries: [
      {
        queryKey: ["CarRentals", filters],
        queryFn: async () => {
          let correctPayload = { ...filters };
          if (filters.created__lte) {
            correctPayload["created__lte"] = DateTime.fromISO(
              filters.created__lte
            ).toISO();
          }
          if (filters.created__gte) {
            correctPayload["created__gte"] = DateTime.fromISO(
              filters.created__gte
            ).toISO();
          }
          if (filters.pickup_datetime__gte) {
            correctPayload["pickup_datetime__gte"] = DateTime.fromISO(
              filters.pickup_datetime__gte
            ).toISO();
          }
          if (filters.dropoff_datetime__lte) {
            correctPayload["dropoff_datetime__lte"] = DateTime.fromISO(
              filters.dropoff_datetime__lte
            ).toISO();
          }
          return fetchCarRentalsReservations({
            params: correctPayload,
          });
        },
        enabled: !showDetailModal,
        onError: (error) => genericRequestErrorHandler(error),
      },
      {
        queryKey: ["carRentalStats", filters],
        queryFn: async () => {
          let correctPayload = { ...filters };
          if (filters.created__lte) {
            correctPayload["created__lte"] = DateTime.fromISO(
              filters.created__lte
            ).toISO();
          }
          if (filters.created__gte) {
            correctPayload["created__gte"] = DateTime.fromISO(
              filters.created__gte
            ).toISO();
          }
          if (filters.pickup_datetime__gte) {
            correctPayload["pickup_datetime__gte"] = DateTime.fromISO(
              filters.created__lte
            ).toISO();
          }
          if (filters.dropoff_datetime__lte) {
            correctPayload["dropoff_datetime__lte"] = DateTime.fromISO(
              filters.created__gte
            ).toISO();
          }
          return getCarRentalsReservationStats({
            params: correctPayload,
          });
        },
        enabled: !showDetailModal,
      },
    ],
  });

  const tableHeaders = commonTableHeaders({
    "Supplier Reservation Reference": (val) =>
      val.supplier_reservation_reference,
    "Car Name": (val) => val.car_name,
    "Pick Up Location": (val) => val.pickup_location,
    "Pick Up Date": (val) => val.pickup_datetime,
    "Drop Off Location": (val) => val.dropoff_location,
    "Drop Of Date": (val) => val.dropoff_datetime,
    Tags: (val) => _.get(val, "tags", []).join("."),
  });
  return (
    <div id="CARListPage" className={classes.CarRentals}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
      />
      <HamburgerMenu />
      {!_.get(results, "1.isLoading", true) &&
        _.get(results, "1.data", null) && (
          <ReservationStats
            title="Reservation Stats"
            stats={_.get(results, "1.data.data.stats_per_currency")}
          />
        )}
      <ControlStrip
        limit={_.get(filters, "limit")}
        page={filters.page}
        count={_.get(results, "0.data.data.count")}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {_.get(results, "0.data.data.isLoading", false) ? (
        <Loader />
      ) : (
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                {Object.keys(tableHeaders).map((value, idx) => (
                  <th className={classes.headCell} key={idx}>
                    {value}
                  </th>
                ))}
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.get(results, "0.data.data.results", []).map((val, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.tablecell}>{idx + 1}</td>
                  {Object.entries(tableHeaders).map((entry, ridx) => {
                    var cell = null;
                    const fn = entry[1];
                    switch (entry[0].toLowerCase()) {
                      case "pick up date":
                        cell = fn(val) ? medWeekdayDate(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      case "drop of date":
                        cell = fn(val) ? medWeekdayDate(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      case "Drop Of Datetime":
                        cell = fn(val) ? medWeekdayDate(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      case "created":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      default:
                        cell = fn(val) || "N/A";
                        break;
                    }
                    return (
                      <td className={classes.tablecell} key={ridx}>
                        {cell}
                      </td>
                    );
                  })}
                  <td className={classes.actionCell}>
                    <CustomButton
                      id={`detailCARButton_${idx}`}
                      appearance="ghost"
                      onClick={() => {
                        setReference(val.reference);
                        setShowDetailModal(true);
                      }}>
                      <strong>Details</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showDetailModal && (
        <DetailsCarRental
          reference={reference}
          onClose={() => setShowDetailModal(false)}
        />
      )}
    </div>
  );
};
export default CarRentals;
