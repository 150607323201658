import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Icon } from "@iconify/react";

const loaderStyles = createUseStyles({
  loading: ({ onTop }) => ({
    display: "grid",
    placeItems: "center",
    fontSize: "35px",
    zIndex: 9999,
    position: onTop ? "absolute" : "unset",
    width: "100%",
    height: "100%",
    background: "rgba(255,255,255,0.5)",
  }),
});
const Loader = ({ onTop }) => {
  const classes = loaderStyles({ onTop });
  return (
    <div className={classes.loading}>
      <Icon icon="line-md:loading-twotone-loop" />
    </div>
  );
};
Loader.defaultProps = {
  onTop: false,
};
Loader.protoTypes = {
  onTop: PropTypes.bool,
};
export default Loader;
