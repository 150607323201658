import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  createMember,
  patchMember,
  retrieveMember,
} from "../../../../../api/CRM/Members/Members";
import Loader from "../../../../../components/Loader";
import { JSEditor } from "../../helpers/JSEditor";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { genericRequestErrorHandler } from "../../../../../api";
import {
  convertStringToNumber,
  currencies,
  handleValidationErrors,
} from "../../../../../helpers";
import { CustomButton } from "../../../../../components/CustomButton";

const b2cVivaCredentialsType = {
  "": "-----",
  LV: "Live",
  DE: "Demo",
};

const currentYear = new Date().getFullYear();

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .transform((value) => (value === "" ? undefined : value))
    .max(100, "Must be less than 100 characters"),
  legal_title: yup.string().nullable().default(""),
  registration_code: yup.string().nullable().default(""),
  currency: yup.string().nullable().default(""),
  founding_year: yup
    .number()
    .integer("A valid integer is required for founding year")
    // .positive("Must be a positive integer")
    .max(currentYear, `Cannot be greater than ${currentYear}`)
    .nullable()
    .default(null),
  mobile_phone: yup
    .string()
    .transform((value) => (value === "" ? undefined : value))
    // .min(10, "Must be at least 10 digits and start with ' + ' ")
    .max(20, "Cannot be greater than 20 digits")
    .nullable()
    .default(""),
  phone: yup
    .string()
    .transform((value) => (value === "" ? undefined : value))
    // .min(10, "Must be at least 10 digits  and start with ' + ' ")
    .max(20, "Cannot be greater than 20 digits")
    .nullable()
    .default(""),
  email: yup.string().email("Invalid email address").nullable().default(""),
  felloh_organisation: yup.string().nullable().default(""),
  felloh_public_key: yup.string().nullable().default(""),
  felloh_secret_key: yup.string().nullable().default(""),
  b2c_stripe_accepted_coupons: yup.string().nullable().default(""),
  b2c_stripe_api_public_key: yup.string().nullable().default(""),
  b2c_stripe_api_secret_key: yup.string().nullable().default(""),
  b2c_viva_api_key: yup.string().nullable().default(""),
  b2c_viva_credentials_type: yup.string().nullable().default(""),
  b2c_viva_merchant_id: yup.string().nullable().default(""),
  b2c_viva_source_code: yup.string().nullable().default(""),
  barclays_passphrase: yup.string().nullable().default(""),
  barclays_pspid: yup.string().nullable().default(""),
  entity_colors: yup.object().shape({
    base: yup.string().nullable().default(""),
    background: yup.object().shape({
      dark: yup.string().nullable().default(""),
      light: yup.string().nullable().default(""),
      secondary: yup.object().shape({
        dark: yup.string().nullable().default(""),
        light: yup.string().nullable().default(""),
      }),
    }),
    text: yup.object().shape({
      colored: yup.string().nullable().default(""),
    }),
  }),
});

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditMemberModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content  1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  inputGroup: formInputGroup,
  // },
  [`@media ${variables.media.smallscreen}`]: {
    modalCard: {
      minWidth: "50rem",
    },
    cardCol2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
});
const EditMemberModal = ({ memberId, onClose }) => {
  const classes = styles();
  const mode = memberId ? "edit" : "add";

  const queryClient = useQueryClient();

  const [form, setForm] = useState(jsonSchema.cast({}));

  const { isLoading: isFetchLoading } = useQuery({
    queryKey: ["EditMemberModal"],
    queryFn: async () => await retrieveMember({ id: memberId }),
    onSuccess: (data) => {
      const fullData = {
        ..._.get(data, "data"),
        entity_colors: {
          base: _.get(data, "data.entity_colors.base", ""),
          background: {
            dark: _.get(data, "data.entity_colors.background.dark", ""),
            light: _.get(data, "data.entity_colors.background.light", ""),
            secondary: {
              dark: _.get(
                data,
                "data.entity_colors.background.secondary.dark",
                ""
              ),
              light: _.get(
                data,
                "data.entity_colors.background.secondary.light",
                ""
              ),
            },
          },
          text: {
            colored: _.get(data, "data.entity_colors.text.colored", ""),
          },
        },
      };
      setForm(jsonSchema.cast(fullData));
    },
    onError: (error) => {
      toast.error(error.message);
    },
    enabled: mode === "edit",
  });
  const mutation = useMutation({
    mutationFn: ({ id, payload }) => {
      if (mode === "add") {
        return createMember({
          payload,
        });
      }
      return patchMember({
        id,
        payload,
      });
    },
    onSuccess: () => {
      toast.success(mode === "add" ? "Member added" : "Member Info Updated");
      queryClient.invalidateQueries("Members");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const numericFields = ["founding_year"];
  const updatedNumericFields = convertStringToNumber(form, numericFields);

  const onSubmit = async ({ id, payload }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      mutation.mutate({ id, payload });
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const handleInputChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };
  const isLoading = (mode === "edit" && isFetchLoading) || mutation.isLoading;
  return (
    <div id="addEditMemberModal" className={classes.EditMemberModal}>
      {isLoading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)}Edit Member</span>
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>Edit Member</span>
          </div>
          <div className={classes.modalCardBody}>
            <form onChange={handleInputChange}>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="name">Name</label>
                      <input
                        id="name"
                        type="text"
                        name="name"
                        value={_.capitalize(form.name)}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="legal_title">Legal Title</label>
                      <input
                        id="legal_title"
                        type="text"
                        name="legal_title"
                        value={_.capitalize(form.legal_title)}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="registration_code">
                        Registration Code
                      </label>
                      <input
                        id="registration_code"
                        type="text"
                        name="registration_code"
                        value={form.registration_code}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="currency">Currency</label>
                      <select
                        id="currency"
                        name="currency"
                        value={form.currency}
                        onChange={handleInputChange}>
                        {Object.entries(currencies).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="founding_year">Founding Year</label>
                      <input
                        id="founding_year"
                        type="number"
                        name="founding_year"
                        value={form.founding_year}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Contact Information</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol2}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="mobile_phone">Mobile Phone</label>
                        <input
                          id="mobile_phone"
                          type="text"
                          name="mobile_phone"
                          value={form.mobile_phone}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="phone">Phone</label>
                        <input
                          id="phone"
                          type="text"
                          name="phone"
                          value={form.phone}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="email">Email</label>
                        <input
                          id="email"
                          type="email"
                          name="email"
                          defaultValue={form.email}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>
                  Payment Gateway Credentials
                </div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="felloh_organisation">
                        Felloh Organisation
                      </label>
                      <input
                        id="felloh_organisation"
                        type="text"
                        name="felloh_organisation"
                        value={form.felloh_organisation}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="felloh_public_key">
                        Felloh Public Key
                      </label>
                      <input
                        id="felloh_public_key"
                        type="text"
                        name="felloh_public_key"
                        value={form.felloh_public_key}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="felloh_secret_key">
                        Felloh Secret Key
                      </label>
                      <input
                        id="felloh_secret_key"
                        type="text"
                        name="felloh_secret_key"
                        value={form.felloh_secret_key}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_stripe_accepted_coupons">
                        B2C Stripe Accepted Coupons
                      </label>
                      <input
                        id="b2c_stripe_accepted_coupons"
                        type="text"
                        name="b2c_stripe_accepted_coupons"
                        value={form.b2c_stripe_accepted_coupons}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_stripe_api_public_key">
                        B2C Stripe Api Public Key
                      </label>
                      <input
                        id="b2c_stripe_api_public_key"
                        type="text"
                        name="b2c_stripe_api_public_key"
                        value={form.b2c_stripe_api_public_key}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_stripe_api_secret_key">
                        B2C Stripe Api Secret Key
                      </label>
                      <input
                        id="b2c_stripe_api_secret_key"
                        type="text"
                        name="b2c_stripe_api_secret_key"
                        value={form.b2c_stripe_api_secret_key}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_viva_api_key">B2C Viva Api Key</label>
                      <input
                        id="b2c_viva_api_key"
                        type="text"
                        name="b2c_viva_api_key"
                        value={form.b2c_viva_api_key}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_viva_credentials_type">
                        B2C Viva Credentials Type
                      </label>
                      <select
                        id="b2c_viva_credentials_type"
                        name="b2c_viva_credentials_type"
                        value={form.b2c_viva_credentials_type}>
                        {Object.entries(b2cVivaCredentialsType).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_viva_merchant_id">
                        B2C Viva Merchant Id
                      </label>
                      <input
                        id="b2c_viva_merchant_id"
                        type="text"
                        name="b2c_viva_merchant_id"
                        value={form.b2c_viva_merchant_id}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_viva_source_code">
                        B2C Viva Source Code
                      </label>
                      <input
                        id="b2c_viva_source_code"
                        type="text"
                        name="b2c_viva_source_code"
                        value={form.b2c_viva_source_code}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="barclays_passphrase">
                        barclays Pass Phrase
                      </label>
                      <input
                        id="barclays_passphrase"
                        type="text"
                        name="barclays_passphrase"
                        value={form.barclays_passphrase}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="barclays_pspid">Barclays PSPID</label>
                      <input
                        id="barclays_pspid"
                        type="text"
                        name="barclays_pspid"
                        value={form.barclays_pspid}
                      />
                    </div>
                  </div>
                  <div className={classes.card}>
                    <div className={classes.cardHeader}>Entity Colors</div>
                    <div className={classes.cardBody}>
                      <div className={classes.cardCol1}>
                        {
                          <JSEditor
                            label="Colour Selection"
                            value={form.entity_colors}
                            onChange={(newValue) => {
                              setForm((p) => ({
                                ...p,
                                entity_colors: newValue,
                              }));
                            }}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="submit"
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  id: memberId,
                  payload: { ...form, ...updatedNumericFields },
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditMemberModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditMemberModal;
