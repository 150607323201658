import React from "react";
import { createUseStyles } from "react-jss";
import { variables } from "../../variables";

const TransferEngineStyles = createUseStyles({
  TransferEngine: {
    display: "grid",
    overflow: "hidden",
    placeContent: "center",
  },
  title: {
    display: "grid",
    textAlign: "center",
    height: "100%",
    alignItems: "end",
    padding: variables.normal_gap,
  },
});
const TransfersEngine = () => {
  const classes = TransferEngineStyles();

  return (
    <div className={classes.TransferEngine}>
      <div className={classes.title}>
        <h1>Transfers Engine</h1>
      </div>
    </div>
  );
};
export default TransfersEngine;
