import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { cardStyles, variables } from "../../../../../variables";
import { CustomButton } from "../../../../../components/CustomButton";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { getAppUserGroups } from "../../../../../api/CRM/Users/Users";
import PropTypes from "prop-types";
import _ from "lodash";
import { Icon } from "@iconify/react";
import Loader from "../../../../../components/Loader";
import EditPermission from "./EditPermission";

const styles = createUseStyles({
  Permissions: {
    ...cardStyles.card,
  },
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "1fr max-content",
    borderBottom: `2px solid ${variables.colors.base}`,
    marginBottom: variables.normal_gap,
  },
  noDataCardBody: {
    extend: "cardBody",
    gridTemplateColumns: "1fr max-content",
    justifyItems: "center",
    color: variables.colors.base,
  },
  values: {
    ...cardStyles.body,
    gridTemplateColumns: "1fr max-content",
    gap: `calc(${variables.normal_gap} / 2)`,
  },
  checkboxesValues: {
    display: "grid",
    padding: variables.normal_gap,
    gridTemplateColumns: "repeat(3, 1fr)",
    justifyItems: "center",
  },
  groupLabels: {
    color: variables.colors.base,
  },
  subGroupLabels: {
    paddingLeft: variables.double_gap,
    color: variables.colors.base,
  },

  checkIcon: {
    fontSize: "medium",
    background: "green",
    borderRadius: "50%",
    padding: `calc(${variables.normal_gap} / 6)`,
    color: "white",
  },
  notCheckIcon: {
    fontSize: "medium",
    background: "red",
    borderRadius: "50%",
    padding: `calc(${variables.normal_gap} / 6)`,
    color: "white",
  },
  permissionsValue: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gap: variables.half_gap,
  },
});

const Permissions = ({ user }) => {
  const classes = styles();

  const [showEditModal, setShowEditModal] = useState(false);

  const { data: appUserGroups, isLoading } = useQuery({
    queryKey: ["appUserGroups", user.username],
    queryFn: () => getAppUserGroups({ app_user: user.id }),
    onError: (error) => genericRequestErrorHandler(error),
  });

  const selectedPermissions = appUserGroups?.data?.results ?? [];
  const shortedSelectedPermissions = _.sortBy(selectedPermissions, (v) =>
    _.upperCase(v.group.name.split(".")?.[0])
  );
  const groupedAppUserGroups = _.groupBy(shortedSelectedPermissions, (v) =>
    _.upperCase(v.group.name.split(".")?.[0])
  );

  const booleanFields = ["view", "edit", "execute"];

  return (
    <div id="PermissionsList" className={classes.Permissions}>
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className={classes.cardHeader}>
            Permissions
            <CustomButton
              id="EditPermissionsButton"
              appearance="primary"
              onClick={() => {
                setShowEditModal(true);
              }}>
              <strong>Edit</strong>
            </CustomButton>
          </div>
          <div className={classes.values}>
            {_.isEmpty(selectedPermissions) ? (
              <div className={classes.noDataCardBody}>
                <strong> No Permissions Found</strong>
              </div>
            ) : (
              Object.values(groupedAppUserGroups).map((groupApUsrGr, idx) => {
                var mainGroup;
                var subGroups = [];
                groupApUsrGr.forEach((group) => {
                  if (group.group.name.split(".").length === 2) {
                    subGroups.push(group);
                  } else {
                    mainGroup = group;
                  }
                });

                var result = [
                  <div
                    className={classes.cardBody}
                    key={`${groupApUsrGr}_${idx}`}>
                    <div className={classes.groupLabels}>
                      <strong>
                        {_.upperCase(mainGroup.group.name.split(".")[0])}
                      </strong>
                    </div>
                    <div className={classes.checkboxesValues}>
                      {booleanFields.map((field, index) => (
                        <div
                          className={classes.permissionsValue}
                          key={`booleanField_${field}_${index}`}>
                          <div>{_.capitalize(field)}</div>
                          {_.get(mainGroup, field) === true && (
                            <Icon
                              icon="material-symbols:check"
                              className={classes.checkIcon}
                            />
                          )}
                          {_.get(mainGroup, field) === false && (
                            <Icon
                              icon="ic:baseline-cancel"
                              className={classes.notCheckIcon}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>,
                ];
                subGroups.forEach((subGroup) => {
                  result.push(
                    <div className={classes.cardBody} key={idx}>
                      <div className={classes.subGroupLabels}>
                        <strong>{_.upperCase(subGroup.group.name)}</strong>
                      </div>
                      <div className={classes.checkboxesValues}>
                        {booleanFields.map((field, index) => (
                          <div
                            className={classes.permissionsValue}
                            key={`booleanField_${field}_${index}`}>
                            <div>{_.capitalize(field)}</div>
                            {_.get(subGroup, field) === true && (
                              <Icon
                                icon="material-symbols:check"
                                className={classes.checkIcon}
                              />
                            )}
                            {_.get(subGroup, field) === false && (
                              <Icon
                                icon="ic:baseline-cancel"
                                className={classes.notCheckIcon}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                });
                return _.flatten(result);
              })
            )}
          </div>
        </React.Fragment>
      )}
      {showEditModal && (
        <EditPermission user={user} onClose={() => setShowEditModal(false)} />
      )}
    </div>
  );
};
Permissions.propTypes = {
  user: PropTypes.object.isRequired,
};
export default Permissions;
