import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  btnStyles,
  modalGenericStyles,
  tableStyles,
  variables,
  genericIndexesStyles,
} from "../../../../../variables";
import PropTypes from "prop-types";
import {
  createWebSiteHotelPriority,
  fetchWebSiteHotelPriority,
} from "../../../../../api/DistributionSite/webSites/HotelPriorities";
import Loader from "../../../../../components/Loader";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { genericRequestErrorHandler } from "../../../../../api";
import { countries } from "../../../../../helpers";
import HotelsAutocomplete from "../../../../../components//HotelsAutocomplete";
import DeleteHotelPrioritiesModal from "../DeleteHotelPrioritiesModal";
import ImportModal from "../ImportModal";
import { CustomButton } from "../../../../../components/CustomButton";

const initialAutoCompleteData = {
  name: "",
  rating: "",
  country_name: "",
  destination: "",
  address: "",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  HotelPrioritiesModal: {
    ...modalGenericStyles.card,
  },
  loader: { width: "100vw" },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: tableStyles,
  headRow: tableStyles.head.row,
  bodyRow: tableStyles.body.row,
  headCell: tableStyles.head.cell,
  cell: tableStyles.body.cell,
  actionCell: {
    ...genericIndexesStyles.actionCell.mobile(),
  },
  btn: btnStyles.btn,
  add: {
    padding: "0.75rem",
    textAlign: "end",
  },
  addBtn: { ...btnStyles.btn, width: "4rem" },
  [`@media ${variables.media.smallscreen}`]: {
    loader: { width: "100%" },
  },
});

const HotelPrioritiesModal = ({ sourceEntity, websiteApiKey }) => {
  const classes = styles();

  const queryClient = useQueryClient();

  const [showImportModal, setShowImportModal] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [autocompleteValue, setAutocompleteValue] = useState("");
  const [autocompleteData, setAutocompleteData] = useState(
    initialAutoCompleteData
  );
  const [addHotel, setAddHotel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { data: hotelPriorities, isLoading } = useQuery({
    queryKey: ["HotelPrioritiesList"],
    queryFn: async () =>
      await fetchWebSiteHotelPriority({
        params: {
          website_api_key: websiteApiKey,
          source_entity: sourceEntity,
        },
      }),
    onError: (error) => genericRequestErrorHandler(error),
  });

  const addMutation = useMutation({
    mutationFn: ({ website_api_key, source_entity, hotel }) => {
      return createWebSiteHotelPriority({
        payload: {
          website_api_key,
          source_entity,
          hotel,
        },
      });
    },
    onSuccess: () => {
      toast.success("New Hotel Priority added");
      setAutocompleteValue("");
      setAutocompleteData(initialAutoCompleteData);
      queryClient.invalidateQueries("HotelPrioritiesList");
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ website_api_key, source_entity, hotel }) => {
    try {
      addMutation.mutate({ website_api_key, source_entity, hotel });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        if (!validationErrors[err.path]) {
          validationErrors[err.path] = err.message;
          toast.error(err.message);
        }
      });
    }
  };

  const hotelInfos = autocompleteValue.split("___");
  const hotelId = hotelInfos[1];

  return (
    <div className={classes.HotelPrioritiesModal}>
      <div className={classes.cardHeader}>
        Hotel Priorities
        <div className={classes.cardActions}>
          {!addHotel && (
            <CustomButton
              appearance="ghost"
              onClick={() => {
                setAddHotel(true);
              }}>
              <strong>Add</strong>
            </CustomButton>
            // </div>
          )}
          <CustomButton
            appearance="ghost"
            onClick={() => {
              setShowImportModal(true);
            }}>
            <strong>Import</strong>
          </CustomButton>
        </div>
      </div>

      <div className={classes.tableContainer}>
        {isLoading ? (
          <div className={classes.loader}>
            <Loader />
          </div>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>Name</th>
                <th className={classes.headCell}>Rating</th>
                <th className={classes.headCell}>Country</th>
                <th className={classes.headCell}>Destination</th>
                <th className={classes.headCell}>Address</th>
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody className={classes.tbody}>
              {_.get(hotelPriorities, "data.results", []).map(
                (hotelPriority, idx) => (
                  <tr key={idx} className={classes.bodyRow}>
                    <td className={classes.cell}>
                      {hotelPriority.name || "N/A"}
                    </td>
                    <td className={classes.cell}>
                      {/* {hotelPriority.rating || "N/A"} */}
                      {hotelPriority.rating === 0
                        ? "0"
                        : _.get(hotelPriority, "rating", "N/A")}
                    </td>
                    <td className={classes.cell}>
                      {_.get(countries, _.get(hotelPriority, "country.code")) ||
                        "N/A"}
                    </td>
                    <td className={classes.cell}>
                      {hotelPriority.destination || "N/A"}
                    </td>
                    <td className={classes.cell}>
                      {hotelPriority.address || "N/A"}
                    </td>
                    <td className={classes.cell}>
                      <CustomButton
                        appearance="ghost"
                        onClick={() => {
                          setShowDeleteModal(true);
                          setDeleteId(hotelPriority.id);
                          setDeleteData(hotelPriority);
                        }}>
                        <strong>Remove</strong>
                      </CustomButton>
                    </td>
                  </tr>
                )
              )}
              {addHotel && (
                <tr>
                  <td className={classes.cell}>
                    {
                      <HotelsAutocomplete
                        value={autocompleteValue}
                        setValue={setAutocompleteValue}
                        autocompleteData={autocompleteData}
                        setAutocompleteData={setAutocompleteData}
                      />
                    }
                  </td>
                  <td className={classes.cell}>
                    {autocompleteData.rating || "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {_.get(countries, _.get(autocompleteData.country_name)) ||
                      "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {autocompleteData.destination || "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {autocompleteData.address || "N/A"}
                  </td>
                  <td className={classes.actionCell}>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        setAddHotel(false);
                        setAutocompleteData(initialAutoCompleteData);
                        setAutocompleteValue("");
                      }}>
                      <strong>Remove</strong>
                    </CustomButton>
                    <CustomButton
                      appearance="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        onSubmit({
                          website_api_key: websiteApiKey,
                          source_entity: sourceEntity,
                          hotel: hotelId,
                        });
                        setAddHotel(false);
                      }}>
                      <strong>Submit</strong>
                    </CustomButton>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      {showDeleteModal && (
        <DeleteHotelPrioritiesModal
          websiteApiKey={websiteApiKey}
          sourceEntity={sourceEntity}
          id={deleteId}
          data={deleteData}
          onClose={() => {
            setShowDeleteModal(false);
            setDeleteId(null);
          }}
        />
      )}
      {showImportModal && (
        <ImportModal
          websiteApiKey={websiteApiKey}
          source_entity={sourceEntity}
          onClose={() => {
            setShowImportModal(false);
          }}
        />
      )}
    </div>
  );
};
HotelPrioritiesModal.propTypes = {
  websiteId: PropTypes.number.isRequired,
  sourceEntity: PropTypes.string.isRequired,
  api_key: PropTypes.string.isRequired,
};
export default HotelPrioritiesModal;
