import * as yup from "yup";

export const initialForm = {
  product_categories: "MI",
  acc_label: "",
  acc_label_de: "",
  acc_label_el: "",
  acc_label_es: "",
  acc_label_fr: "",
  acc_label_it: "",
  acc_label_ja: "",
  acc_label_nl: "",
  acc_label_pt: "",
  acc_label_th: "",
  acc_label_zh: "",
  act_label: "",
  act_label_de: "",
  act_label_el: "",
  act_label_es: "",
  act_label_fr: "",
  act_label_it: "",
  act_label_ja: "",
  act_label_nl: "",
  act_label_pt: "",
  act_label_th: "",
  act_label_zh: "",
  car_label: "",
  car_label_de: "",
  car_label_el: "",
  car_label_es: "",
  car_label_fr: "",
  car_label_it: "",
  car_label_ja: "",
  car_label_nl: "",
  car_label_pt: "",
  car_label_th: "",
  car_label_zh: "",
  fer_label: "",
  fer_label_de: "",
  fer_label_el: "",
  fer_label_es: "",
  fer_label_fr: "",
  fer_label_it: "",
  fer_label_ja: "",
  fer_label_nl: "",
  fer_label_pt: "",
  fer_label_th: "",
  fer_label_zh: "",
  fl_label: "",
  fl_label_de: "",
  fl_label_el: "",
  fl_label_es: "",
  fl_label_fr: "",
  fl_label_it: "",
  fl_label_ja: "",
  fl_label_nl: "",
  fl_label_pt: "",
  fl_label_th: "",
  fl_label_zh: "",
  tra_label: "",
  tra_label_de: "",
  tra_label_el: "",
  tra_label_es: "",
  tra_label_fr: "",
  tra_label_it: "",
  tra_label_ja: "",
  tra_label_nl: "",
  tra_label_pt: "",
  tra_label_th: "",
  tra_label_zh: "",
  trf_label: "",
  trf_label_de: "",
  trf_label_el: "",
  trf_label_es: "",
  trf_label_fr: "",
  trf_label_it: "",
  trf_label_ja: "",
  trf_label_nl: "",
  trf_label_pt: "",
  trf_label_th: "",
  trf_label_zh: "",
  hotel_booking_terms_label: "",
  hotel_booking_terms_label_de: "",
  hotel_booking_terms_label_el: "",
  hotel_booking_terms_label_es: "",
  hotel_booking_terms_label_fr: "",
  hotel_booking_terms_label_it: "",
  hotel_booking_terms_label_ja: "",
  hotel_booking_terms_label_nl: "",
  hotel_booking_terms_label_pt: "",
  hotel_booking_terms_label_th: "",
  hotel_booking_terms_label_zh: "",
  name: "",
  status: "",
  production_url: "",
  staging_url: "",
  packages: false,
  market_type: "",
  private_website: false,
  marketplace: false,
  auth_type: "",
  payment_supplier: "",
  payment_type: "",
  accommodation: false,
  activities: false,
  flights: false,
  addons: false,
  transfers: false,
  restaurants: false,
  service_order: [],
  s3_bucket: "",
  website_colors: {
    base: "",
    background: {
      dark: "",
      light: "",
      secondary: {
        dark: "",
        light: "",
      },
    },
    text: {
      colored: "",
    },
  },
};

export const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Must be at least 2 characters")
    .max(100, "Must be less than 100 characters")
    .default(""),
  source_entity: yup.string("Must be a valid string").nullable().default(""),
  production_url: yup.string().url("Invalid URL format").nullable().default(""),
  staging_url: yup.string().url("Invalid URL format").nullable().default(""),
  packages: yup.bool("Must be a valid boolean").nullable(),
  // .default(false),
  market_type: yup
    .string()
    .required("This field may not be blanc ")
    .default(""),
  private_website: yup.bool("Must be a valid boolean").nullable(),
  // .default(false)
  marketplace: yup.bool("Must be a valid boolean").nullable(),
  // .default(false)
  auth_type: yup
    .string()
    .oneOf(["auth0", "external_auth0", "external"], "Invalid value")
    .nullable()
    .default(""),
  product_categories: yup
    .string()
    .oneOf(["MI", "PKG", "BOTH"], "Invalid value")
    .nullable()
    .default("MI"),
  payment_supplier: yup
    .string()
    .required("This field may not be blanc ")
    .default(""),
  payment_type: yup
    .string()
    .required("This field may not be blanc ")
    .default(""),
  status: yup.string().required("This field may not be blanc ").default(""),
  accommodation: yup
    .bool("Must be a valid boolean")
    // .nullable()
    .default(false),
  activities: yup
    .bool("Must be a valid boolean")
    // .nullable()
    .default(false),
  flights: yup
    .bool("Must be a valid boolean")
    // .nullable()
    .default(false),
  addons: yup
    .bool("Must be a valid boolean")
    // .nullable()
    .default(false),
  transfers: yup
    .bool("Must be a valid boolean")
    // .nullable()
    .default(false),
  restaurants: yup
    .bool("Must be a valid boolean")
    // .nullable()
    .default(false),
  s3_bucket: yup.string().nullable().default(""),
  service_order: yup
    .array()
    .of(
      yup
        .string()
        .oneOf(["ACC", "ACT", "MI", "FL", "REST", "TRF"], "Invalid value")
    )
    .required("This field can not be empty")
    .default([]),
  acc_label: yup
    .string()
    .nullable()
    .required("Accommodation Label: this field is Required")
    .default(""),
  acc_label_de: yup.string().nullable().default(""),
  acc_label_el: yup.string().nullable().default(""),
  acc_label_es: yup.string().nullable().default(""),
  acc_label_fr: yup.string().nullable().default(""),
  acc_label_it: yup.string().nullable().default(""),
  acc_label_ja: yup.string().nullable().default(""),
  acc_label_nl: yup.string().nullable().default(""),
  acc_label_pt: yup.string().nullable().default(""),
  acc_label_th: yup.string().nullable().default(""),
  acc_label_zh: yup.string().nullable().default(""),
  acc_label_lt: yup.string().nullable().default(""),
  act_label: yup
    .string()
    .nullable()
    .required("Activities Label: this field is Required")
    .default(""),
  act_label_de: yup.string().nullable().default(""),
  act_label_el: yup.string().nullable().default(""),
  act_label_es: yup.string().nullable().default(""),
  act_label_fr: yup.string().nullable().default(""),
  act_label_it: yup.string().nullable().default(""),
  act_label_ja: yup.string().nullable().default(""),
  act_label_nl: yup.string().nullable().default(""),
  act_label_pt: yup.string().nullable().default(""),
  act_label_th: yup.string().nullable().default(""),
  act_label_zh: yup.string().nullable().default(""),
  act_label_lt: yup.string().nullable().default(""),
  car_label: yup
    .string()
    .nullable()
    .required("Car Rental Label: this field is Required")
    .default(""),
  car_label_de: yup.string().nullable().default(""),
  car_label_el: yup.string().nullable().default(""),
  car_label_es: yup.string().nullable().default(""),
  car_label_fr: yup.string().nullable().default(""),
  car_label_it: yup.string().nullable().default(""),
  car_label_ja: yup.string().nullable().default(""),
  car_label_nl: yup.string().nullable().default(""),
  car_label_pt: yup.string().nullable().default(""),
  car_label_th: yup.string().nullable().default(""),
  car_label_zh: yup.string().nullable().default(""),
  car_label_lt: yup.string().nullable().default(""),
  fer_label: yup
    .string()
    .nullable()
    .required("Ferries Label: this field is Required")
    .default(""),
  fer_label_de: yup.string().nullable().default(""),
  fer_label_el: yup.string().nullable().default(""),
  fer_label_es: yup.string().nullable().default(""),
  fer_label_fr: yup.string().nullable().default(""),
  fer_label_it: yup.string().nullable().default(""),
  fer_label_ja: yup.string().nullable().default(""),
  fer_label_nl: yup.string().nullable().default(""),
  fer_label_pt: yup.string().nullable().default(""),
  fer_label_th: yup.string().nullable().default(""),
  fer_label_zh: yup.string().nullable().default(""),
  fer_label_lt: yup.string().nullable().default(""),
  fl_label: yup
    .string()
    .nullable()
    .required("Flights Label: this field is Required")
    .default(""),
  fl_label_de: yup.string().nullable().default(""),
  fl_label_el: yup.string().nullable().default(""),
  fl_label_es: yup.string().nullable().default(""),
  fl_label_fr: yup.string().nullable().default(""),
  fl_label_it: yup.string().nullable().default(""),
  fl_label_ja: yup.string().nullable().default(""),
  fl_label_nl: yup.string().nullable().default(""),
  fl_label_pt: yup.string().nullable().default(""),
  fl_label_th: yup.string().nullable().default(""),
  fl_label_zh: yup.string().nullable().default(""),
  fl_label_lt: yup.string().nullable().default(""),
  tra_label: yup
    .string()
    .nullable()
    .required("Trains Label: this field is Required")
    .default(""),
  tra_label_de: yup.string().nullable().default(""),
  tra_label_el: yup.string().nullable().default(""),
  tra_label_es: yup.string().nullable().default(""),
  tra_label_fr: yup.string().nullable().default(""),
  tra_label_it: yup.string().nullable().default(""),
  tra_label_ja: yup.string().nullable().default(""),
  tra_label_nl: yup.string().nullable().default(""),
  tra_label_pt: yup.string().nullable().default(""),
  tra_label_th: yup.string().nullable().default(""),
  tra_label_zh: yup.string().nullable().default(""),
  tra_label_lt: yup.string().nullable().default(""),
  trf_label: yup
    .string()
    .nullable()
    .required("Transfers  Label: this field is Required")
    .default(""),
  trf_label_de: yup.string().nullable().default(""),
  trf_label_el: yup.string().nullable().default(""),
  trf_label_es: yup.string().nullable().default(""),
  trf_label_fr: yup.string().nullable().default(""),
  trf_label_it: yup.string().nullable().default(""),
  trf_label_ja: yup.string().nullable().default(""),
  trf_label_nl: yup.string().nullable().default(""),
  trf_label_pt: yup.string().nullable().default(""),
  trf_label_th: yup.string().nullable().default(""),
  trf_label_zh: yup.string().nullable().default(""),
  trf_label_lt: yup.string().nullable().default(""),
  hotel_booking_terms_label: yup.string().nullable().default(""),
  hotel_booking_terms_label_de: yup.string().nullable().default(""),
  hotel_booking_terms_label_el: yup.string().nullable().default(""),
  hotel_booking_terms_label_es: yup.string().nullable().default(""),
  hotel_booking_terms_label_fr: yup.string().nullable().default(""),
  hotel_booking_terms_label_it: yup.string().nullable().default(""),
  hotel_booking_terms_label_ja: yup.string().nullable().default(""),
  hotel_booking_terms_label_nl: yup.string().nullable().default(""),
  hotel_booking_terms_label_pt: yup.string().nullable().default(""),
  hotel_booking_terms_label_th: yup.string().nullable().default(""),
  hotel_booking_terms_label_zh: yup.string().nullable().default(""),
  hotel_booking_terms_label_lt: yup.string().nullable().default(""),
  website_colors: yup
    .object()
    .shape({
      base: yup.string().default(""),
      background: yup.object().shape({
        dark: yup.string().default(""),
        light: yup.string().default(""),
        secondary: yup.object().shape({
          dark: yup.string().default(""),
          light: yup.string().default(""),
        }),
      }),
      text: yup.object().shape({
        colored: yup.string().default(""),
      }),
    })
    .default({
      base: "",
      background: {
        dark: "",
        light: "",
        secondary: {
          dark: "",
          light: "",
        },
      },
      text: {
        colored: "",
      },
    }),
});
