import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import GenericBasicInfo from "../GenericBasicInfo";
import { DataLog } from "../userInfoCard/DataLog";
import { DateTime } from "luxon";
import _ from "lodash";
import HttpCodeTag from "../../Tags/HttpCodeTag";

const styles = createUseStyles({
  DetailedLog: {},
});

const DetailedLog = ({ log_source }) => {
  const classes = styles();

  const basicDataCpl = [
    ["Descriptor", "descriptor"],
    ["Method", "method"],
    [
      "Elapsed Time",
      null,
      `${_.get(log_source, "elapsedTime", 0) / 1000} sec.`,
    ],
    ["Url", "url"],

    [
      "Created",
      null,
      DateTime.fromMillis(
        Date.parse(_.get(log_source, "timestamp", "") || 0) // Parse string to number
      ).toLocaleString(DateTime.DATETIME_SHORT) || "N/A",
    ],
    [
      "Status",
      null,
      <span>
        <HttpCodeTag statusCode={_.get(log_source, "status")} />
      </span>,
    ],
  ];

  return (
    <div className={classes.DetailedLog}>
      <GenericBasicInfo
        header={"Basic Data"}
        cardInfo={basicDataCpl}
        objectData={log_source}
      />
      <DataLog
        title="Request Headers"
        data={_.get(log_source, "request_headers", {})}
      />
      <DataLog
        title="Request Params"
        data={_.get(log_source, "request_params", {})}
      />
      <DataLog
        title="Request Body"
        data={_.get(log_source, "request_body", {})}
      />
      <DataLog
        title="Response Headers"
        data={_.get(log_source, "response_headers", {})}
      />
      <DataLog
        title="Response Body"
        data={_.get(log_source, "response_body", {})}
      />
    </div>
  );
};
DetailedLog.propTypes = {
  log_source: PropTypes.object.isRequired,
};
export default DetailedLog;
