import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  btnStyles,
  modalGenericStyles,
  tableStyles,
  variables,
  genericIndexesStyles,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { fetchWebSiteRestaurantPromos } from "../../../../../api/DistributionSite/webSites/RestaurantPromos";
import EditRestaurantPromos from "../EditRestaurantPromos";
import { DateTime } from "luxon";
import Loader from "../../../../../components/Loader";
import DeleteRestaurantPromos from "../DeleteRestaurantPromos";
import { statusMapping } from "../../common";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  RestaurantPromos: {
    ...modalGenericStyles.card,
  },
  loader: { width: "100vw" },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: tableStyles,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile(),
  [`@media ${variables.media.smallscreen}`]: {},
});

const RestaurantPromos = ({ websiteId }) => {
  const classes = styles();

  const [restaurant, setRestaurant] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const { data: restaurants, isLoading } = useQuery({
    queryKey: ["RestaurantPromosList"],
    queryFn: async () =>
      await fetchWebSiteRestaurantPromos({
        params: { website: websiteId },
      }),
    onError: (error) => genericRequestErrorHandler(error),
  });

  return (
    <div className={classes.RestaurantPromos}>
      <div className={classes.cardHeader}>
        Restaurant Promos
        <div className={classes.cardActions}>
          <CustomButton
            appearance="ghost"
            onClick={() => {
              setEditModal(true);
              setEditId(null);
            }}>
            <strong>Add</strong>
          </CustomButton>
        </div>
      </div>
      <div className={classes.tableContainer}>
        {isLoading ? (
          <div className={classes.loader}>
            <Loader />
          </div>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Id</th>
                <th className={classes.headCell}>Name</th>
                <th className={classes.headCell}>Featured Restaurant</th>
                <th className={classes.headCell}>Featured Supplier</th>
                <th className={classes.headCell}>Active From</th>
                <th className={classes.headCell}>Active To</th>
                <th className={classes.headCell}>Status</th>
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.get(restaurants, "data.results", []).map((res, idx) => (
                <tr key={idx} className={classes.bodyRow}>
                  <td className={classes.cell}>{idx + 1}</td>
                  <td className={classes.cell}>{res.id || "N/A"}</td>
                  <td className={classes.cell}>
                    {_.capitalize(res.name) || "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {_.capitalize(res.featured_restaurant) || "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {_.capitalize(res.featured_supplier) || "N/A"}
                  </td>

                  <td className={classes.cell}>
                    {res.active_from
                      ? DateTime.fromISO(res.active_from).toLocaleString(
                          DateTime.DATE_MED_WITH_WEEKDAY
                        )
                      : "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {res.active_to
                      ? DateTime.fromISO(res.active_to).toLocaleString(
                          DateTime.DATE_MED_WITH_WEEKDAY
                        )
                      : "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {_.get(statusMapping, _.get(res, "status")) || "N/A"}
                  </td>
                  <td className={classes.actionCell}>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        setEditId(res.id);
                        setEditModal(true);
                      }}>
                      <strong>Edit</strong>
                    </CustomButton>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        setRestaurant(res);
                        setDeleteId(res.id);
                        setDeleteModal(true);
                      }}>
                      <strong>Delete</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {editModal && (
        <EditRestaurantPromos
          id={editId}
          websiteId={websiteId}
          onClose={() => {
            setEditModal(false);
            setEditId(null);
          }}
        />
      )}
      {deleteModal && (
        <DeleteRestaurantPromos
          id={deleteId}
          data={restaurant}
          onClose={() => {
            setDeleteModal(false);
            setDeleteId(null);
          }}
        />
      )}
    </div>
  );
};
RestaurantPromos.propTypes = {
  websiteId: PropTypes.number.isRequired,
};
export default RestaurantPromos;
