import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  modalGenericStyles,
  tableStyles,
  variables,
  genericIndexesStyles,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { fetchWebSiteTermsAndConditions } from "../../../../../api/DistributionSite/webSites/TermsAndConditions";
import EditTermsAndConditions from "../EditTermsAndConditions";
import { DateTime } from "luxon";
import Loader from "../../../../../components/Loader";
import DeleteTermsAndConditions from "../DeleteTermsAndConditions";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import _ from "lodash";
import { statusMapping } from "../../common";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  TermsAndConditionsModal: {
    ...modalGenericStyles.card,
  },
  loader: { width: "100vw" },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: tableStyles,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: tableStyles.body.cell,
  actionCell: {
    ...genericIndexesStyles.actionCell.mobile(),
  },
  [`@media ${variables.media.smallscreen}`]: {},
});

const TermsAndConditionsModal = ({ websiteId }) => {
  const classes = styles();

  const [termAndCondition, setTermAndCondition] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { data: termsAndConditions, isLoading } = useQuery({
    queryKey: ["TermsAndConditionsList"],
    queryFn: async () =>
      await fetchWebSiteTermsAndConditions({
        params: { website: websiteId },
      }),
    onError: (error) => genericRequestErrorHandler(error),
  });

  return (
    <div className={classes.TermsAndConditionsModal}>
      <div className={classes.cardHeader}>
        Terms And Conditions
        <div className={classes.cardActions}>
          <CustomButton
            appearance="ghost"
            onClick={() => {
              setEditId(null);
              setEditModal(true);
            }}>
            <strong>Add</strong>
          </CustomButton>
        </div>
      </div>
      <div className={classes.tableContainer}>
        {isLoading ? (
          <div className={classes.loader}>
            <Loader />
          </div>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Id</th>
                <th className={classes.headCell}>Name</th>
                <th className={classes.headCell}>Active From</th>
                <th className={classes.headCell}>Active To</th>
                <th className={classes.headCell}>Status</th>
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody className={classes.tbody}>
              {_.get(termsAndConditions, "data.results", []).map(
                (terms, idx) => (
                  <tr key={idx} className={classes.bodyRow}>
                    <td className={classes.cell}>{idx + 1}</td>
                    <td className={classes.cell}>{terms.id || "N/A"}</td>
                    <td className={classes.cell}>{terms.name || "N/A"}</td>
                    <td className={classes.cell}>
                      {terms.active_from
                        ? DateTime.fromISO(terms.active_from).toLocaleString(
                            DateTime.DATE_MED_WITH_WEEKDAY
                          )
                        : "N/A"}
                    </td>
                    <td className={classes.cell}>
                      {terms.active_to
                        ? DateTime.fromISO(terms.active_to).toLocaleString(
                            DateTime.DATE_MED_WITH_WEEKDAY
                          )
                        : "N/A"}
                    </td>
                    <td className={classes.cell}>
                      {_.get(statusMapping, _.get(terms, "status")) || "N/A"}
                    </td>
                    <td className={classes.actionCell}>
                      <CustomButton
                        appearance="ghost"
                        onClick={() => {
                          setEditId(terms.id);
                          setEditModal(true);
                        }}>
                        <strong>Edit</strong>
                      </CustomButton>
                      <CustomButton
                        appearance="ghost"
                        onClick={() => {
                          setTermAndCondition(terms);
                          setDeleteId(terms.id);
                          setDeleteModal(true);
                        }}>
                        <strong>Delete</strong>
                      </CustomButton>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        )}
      </div>
      {editModal && (
        <EditTermsAndConditions
          id={editId}
          websiteId={websiteId}
          onClose={() => {
            setEditModal(false);
            setEditId(null);
          }}
        />
      )}
      {deleteModal && (
        <DeleteTermsAndConditions
          id={deleteId}
          data={termAndCondition}
          onClose={() => {
            setDeleteModal(false);
          }}
        />
      )}
    </div>
  );
};
TermsAndConditionsModal.propTypes = {
  websiteId: PropTypes.number.isRequired,
};
export default TermsAndConditionsModal;
