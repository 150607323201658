import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../../variables";
import GenericBasicInfo from "../../../../../../components/cards/GenericBasicInfo";
import _ from "lodash";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import { retrieveVendorFlight } from "../../../../../../api/Vendor/flight";
import { genericRequestErrorHandler } from "../../../../../../api";
import Loader from "../../../../../../components/Loader";
import VendorsDocuments from "../../../components/VendorsDocuments";
import ListPaymentTerms from "../../../components/paymentsTerms/ListPaymentTerms";
import TabStrip from "../../../../../../components/TabStrip";
import { CustomButton } from "../../../../../../components/CustomButton";
import VendorFLConfigLogsTab from "./VendorFLConfigLogsTab";
import DepositStatments from "../../../../CommonCRMModals/DepositStatmentss/DepositStatment";
import ProformaStatments from "../../../../CommonCRMModals/Proforma/List";
import { Price } from "../../../../../../components/Price";

const statusOptions = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailFlightModal: { ...modalGenericStyles.modal },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    display: "grid",
    padding: variables.double_gap,
    minHeight: "30vh",
    maxHeight: "70vh",
    overflow: "auto",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  row: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardBody: { justifyContent: "center" },
    row: {
      gridTemplateColumns: "2fr 1fr",
    },
    tabs: { gridAutoFlow: "column", justifyContent: "center" },
    tabBtns: {
      fontSize: "small",
    },
  },
});

const DetailFlightModal = ({ id, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicData");

  const menuTabs = {
    basicData: "Basic Data",
    paymentTerms: "Payment Terms",
    proforma: "Proforma Statment",
    creditStatments: "Credit Statements",
    documents: "Documents",
    logs: "Logs",
  };

  const { data: flight, isLoading: retrieveLoading } = useQuery({
    queryKey: ["DetailVendorFlight", id],
    queryFn: async () => await retrieveVendorFlight({ id: id }),
    onError: (error) => genericRequestErrorHandler(error),
  });

  const VendorDataCpl = [
    [
      "Vendor",
      null,
      _.get(flight, "data.vendor", "").split("fl__")[1] || "N/A",
    ],
    ["Client Id", null, _.get(flight, "data.vendor_data.client_id")],
    ["Currency", null, _.get(flight, "data.vendor_data.currency")],
    ["Base URL", null, _.get(flight, "data.vendor_data.base_url", "")],
    ["Grand Type", null, _.get(flight, "data.vendor_data.grand_type")],
    [
      "X Musement App",
      null,
      _.get(flight, "data.vendor_data.x_musement_application"),
    ],
  ];

  const deliveriesContactData = [
    [
      "contact Person",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "contact_person") || "N/A"
      ),
    ],
    [
      "First Name",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "first_name", "") || "N/A"
      ),
    ],
    [
      "Last Name",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "last_name") || "N/A"
      ),
    ],
    [
      "Address",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "address") || "N/A"
      ),
    ],
    [
      "Street Number",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "street_number") || "N/A"
      ),
    ],
    [
      "Street",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "street") || "N/A"
      ),
    ],
    [
      "City",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "city") || "N/A"
      ),
    ],
    [
      "Region",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "region") || "N/A"
      ),
    ],

    [
      "Country",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "country_code") || "N/A"
      ),
    ],
    [
      "Post Code",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "post_code") || "N/A"
      ),
    ],
    [
      "Email",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "email") || "N/A"
      ),
    ],

    [
      "Mobile Phone",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "mobile_phone") || "N/A"
      ),
    ],
    [
      "Phone",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "phone") || "N/A"
      ),
    ],
  ];

  const deliveriesDataCpl = [
    [
      "Id",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "id") || "N/A"
      ),
    ],
    [
      "Entityless",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "entityless") || "N/A"
      ),
    ],
    [
      "Gender",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "gender") || "N/A"
      ),
    ],
    [
      "Independentagent",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "independentagent") || "N/A"
      ),
    ],
    [
      "Member",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "member") || "N/A"
      ),
    ],

    [
      "Subagent",
      null,
      _.get(flight, "data.deliveries", []).map(
        (des) => _.get(des, "subagent") || "N/A"
      ),
    ],
    [
      "Edited",
      null,
      _.get(flight, "data.deliveries", []).map((des) =>
        DateTime.fromISO(_.get(des, "edited")).toLocaleString(
          DateTime.DATE_MED_WITH_WEEKDAY
        )
      ),
    ],
    [
      "Created",
      null,
      _.get(flight, "data.deliveries", []).map((des) =>
        DateTime.fromISO(_.get(des, "created")).toLocaleString(
          DateTime.DATE_MED_WITH_WEEKDAY
        )
      ),
    ],
  ];

  const basicDataCpl = [
    ["Id", "id"],
    ["Config uid", null, _.get(flight, "data.config_uid[0]", "") || "N/A"],
    ["Market_type", "market_type"],
    [
      "Notify Amount",
      null,
      <Price
        currency={flight?.data?.vendor_data?.currency}
        price={flight?.data?.notify_amount}
      />,
    ],
    ["Member", "member"],
    ["Notes", "notes"],
    [
      "Payment Validation",
      null,
      _.get(flight, "data.payment_validation") === true ? "Yes" : "No" || "N/A",
    ],
    // ["Deliveries", null, _.get(flight, "data.deliveries", "") || "N/A"],
    ["CXL Strategy Time", "cxl_strategy_time"],
    [
      "CXL Strategy Daysahead",
      null,
      _.get(flight, "data.cxl_strategy_daysahead", []) === 0
        ? "0"
        : _.get(flight, "data.cxl_strategy_daysahead"),
    ],
    ["Last Editor", "last_editor"],
    [
      "Edited",
      null,
      DateTime.fromISO(_.get(flight, "data.edited")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    ["Creator", "creator"],
    [
      "Created",
      null,
      DateTime.fromISO(_.get(flight, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    [
      "Status",
      null,
      _.get(statusOptions, _.get(flight, "data.status", "") || "N/A"),
    ],
  ];

  const vendorContactData = [
    ["First Name", "first_name"],
    ["Last Name", "last_name"],
    ["Title", "title"],
    ["Email", "email"],
  ];

  return (
    <div id="DetailVendorFLModal" className={classes.DetailFlightModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Flight Detail</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {retrieveLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicData" && (
                <React.Fragment>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Basic Data"}
                        cardInfo={basicDataCpl}
                        objectData={_.get(flight, "data")}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Vendor Data"}
                        cardInfo={VendorDataCpl}
                        objectData={_.get(flight, "data.vendor_data")}
                      />
                      <GenericBasicInfo
                        header={"Vendor Contact Data"}
                        cardInfo={vendorContactData}
                        objectData={_.get(
                          flight,
                          "data.vendor_data.contact_details"
                        )}
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Deliveries Data"}
                        cardInfo={deliveriesDataCpl}
                        objectData={_.get(flight, "data")}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Deliveries Contact Data"}
                        cardInfo={deliveriesContactData}
                        objectData={_.get(flight, "data")}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activeTab === "paymentTerms" && (
                <ListPaymentTerms
                  showAddButton={true}
                  vendorId={_.get(flight, "data.id")}
                />
              )}
              {activeTab === "proforma" && (
                <ProformaStatments
                  invalidationKey="DetailsSubAgentModal"
                  entityType="vendor"
                  entityData={_.get(flight, "data", {})}
                />
              )}
              {activeTab === "creditStatments" && (
                <DepositStatments
                  invalidationKey="DetailVendorFlight"
                  entityType="vendor"
                  entityData={_.get(flight, "data", {})}
                />
              )}
              {activeTab === "documents" && (
                <VendorsDocuments
                  queryKey={`vendor_flight_document`}
                  tags={[`vendor__${_.get(flight, "data.id")}`]}
                  entityData={_.get(flight, "data", {})}
                />
              )}
              {activeTab === "logs" && <VendorFLConfigLogsTab />}
            </React.Fragment>
          )}
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton
            id="cancel"
            appearance="ghost"
            text="Cancel"
            onClick={() => onClose()}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailFlightModal.propTypes = {
  id: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DetailFlightModal;
