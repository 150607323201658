import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import _, { set } from "lodash";
import Navbar from "./Navbar";
import ControlStrip from "../../../../components/ControlStrip";
import Loader from "../../../../components/Loader";
import { countries, medWeekdayDatetimer } from "../../../../helpers";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../../hooks";
import { Button } from "../../../../components/Button";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";
import EditMapperModal from "../MapperModals/EditMapperModal";
import { fetchHotels } from "../../../../api/accommodator/Hotels/Hotels";
import * as yup from "yup";
import MappingModal from "../MapperModals/MappingModal";
import { toast } from "react-toastify";
import PrimaryHotelModal from "../MapperModals/PrimaryHotelModal";
import { useLocation } from "react-router-dom";
import { CustomButton } from "../../../../components/CustomButton";

const initialFilters = {
  page_size: 20,
  page: 1,
  days: 0,
  mapped: "",
  supplier: "",
  ordering: "",
  discending: "",
  mapping_score_upper: "",
  mapping_score_lower: "",
  country_code: "",
  uid: "",
  not_mapped: "",
  manually_mapped: "",
  primary: "",
  rating: "",
  name: "",
  cms_destination: "",
  edited: "",
};
const navbarSchema = yup.object().shape({
  mapping_score_upper: yup
    .number()
    .min(0, "Mapping Score Upper must be greater than or equal to 0")
    .max(1, "Mapping Score Upper must be less than or equal to 1"),
  mapping_score_lower: yup
    .number()
    .min(0, "Mapping Score Lower must be greater than or equal to 0")
    .max(1, "Mapping Score Lower must be less than or equal to 1"),
});
const trueFalseMapping = {
  true: "Yes",
  false: "No",
};

const mapperStyles = createUseStyles({
  Mapper: genericIndexesStyles.indexes.mobile,
  tableContainer: genericIndexesStyles.tableContainer.mobile,
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: {
    ...tableStyles.body.cell,
  },
  actionCell: genericIndexesStyles.actionCell.mobile,
  dateCell: { ...tableStyles.body.cell, width: "10rem" },
  addressCell: { ...tableStyles.body.cell, minWidth: "8rem" },
  checkBox: tableStyles.checkBox,
  [`@media ${variables.media.smallscreen}`]: {
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});
const Mapper = () => {
  const classes = mapperStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [editHotel, setEditHotel] = useState({});
  const [pk, setPk] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showMappingModal, setShowMappingModal] = useState(false);
  const [showPrimaryHotelModal, setShowPrimaryHotelModal] = useState(false);

  const queryClient = useQueryClient();
  const location = useLocation();

  const tableHeaders = {
    Uid: (val) => val.uid,
    Name: (val) => _.capitalize(_.get(val, "name")),
    Supplier: (val) => _.capitalize(_.get(val, "supplier")),
    Rating: (val) => val.rating,
    Address: (val) => _.capitalize(_.get(val, "address")),
    Country: (val) => _.get(countries, _.get(val, "country_code")),
    Created: (val) => val.created,
    Edited: (val) => val.edited,
    "Manually Mapped": (val) =>
      _.get(trueFalseMapping, _.get(val, "manually_mapped")),
    Mapping: (val) => _.capitalize(_.get(val, "mapped_uid__name")),
    Primary: (val) => _.get(trueFalseMapping, _.get(val, "primary")),
    Score: (val) =>
      val.mapping_score === 0 ? "0" : _.get(val, "mapping_score", 0),
  };
  const handleApplyFilters = async (filters) => {
    if (filters.cms_destination && !filters.country_code) {
      toast.error(
        `You must choose Destination & Country before applying filters.`
      );
      return;
    }
    if (!filters.supplier) {
      toast.error(`You must choose Supplier  before applying filters.`);
      return;
    }
    try {
      const filteredFilters = Object.keys(filters).reduce((acc, key) => {
        if (filters[key] !== "") {
          acc[key] = filters[key];
        }
        return acc;
      }, {});

      await navbarSchema.validate(filteredFilters, { abortEarly: false });
      setFilters(filteredFilters);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        if (!validationErrors[err.path]) {
          validationErrors[err.path] = err.message;
          toast.error(err.message);
        }
      });
    }
  };

  const calculateDateTime = (selectedDays) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - parseInt(selectedDays, 10));
    currentDate.setHours(0, 0, 0, 0);
    const formattedDate = currentDate.toISOString();
    return formattedDate;
  };

  const { data: hotels, isLoading } = useQuery({
    queryKey: ["Mapper", filters, showMappingModal],
    queryFn: async () => {
      const validFilters = {
        ...filters,
      };
      if (filters.days) {
        validFilters["created"] = calculateDateTime(filters.days);
        delete validFilters["days"];
      }
      // if (filters.cms_destination === null) {
      //   delete validFilters["cms_destination"];
      // }

      return await fetchHotels({ params: validFilters });
    },
    enabled:
      (filters.supplier && filters.uid && filters.rating) !== "" &&
      !showEditModal,
    onError: (error) => genericRequestErrorHandler(error),
    refetchOnWindowFocus: false,
  });
  const loading =
    isLoading &&
    filters.supplier !== "" &&
    filters.uid !== "" &&
    filters.rating !== "";

  // Set filters from url
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlUid = searchParams.get("uid");
    const urlSupplier = searchParams.get("supplier");
    const tmpFilters = _.cloneDeep(filters);
    tmpFilters["uid"] = urlUid;
    tmpFilters["supplier"] = urlSupplier;
    if (tmpFilters["supplier"] || tmpFilters["uid"]) {
      handleApplyFilters(tmpFilters);
    }
  }, [location.search]);
  return (
    <div className={classes.Mapper}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyFilters}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.page_size}
        page={filters.page}
        count={_.get(hotels, "data.count", 0)}
        onChangeLimit={(page_size) => {
          const tmp = _.cloneDeep(filters);
          tmp.page_size = page_size;
          handleApplyFilters(tmp);
        }}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[
          ["mapping_score", "Score"],
          ["created", "Created"],
        ]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, order_by: ordering })
        }
      />
      {loading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th className={classes.headCell} key={idx}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.get(hotels, "data.results", []).map((dt, idx) => (
              <tr className={classes.bodyRow} key={dt.uid}>
                <td>{idx + 1}.</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "created":
                      cell = fn(dt) ? medWeekdayDatetimer(fn(dt)) : "N/A";
                      return (
                        <td className={classes.dateCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    case "edited":
                      cell = fn(dt) ? medWeekdayDatetimer(fn(dt)) : "N/A";
                      return (
                        <td className={classes.dateCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    case "address":
                      cell = fn(dt) || "N/A";
                      return (
                        <td className={classes.addressCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    default:
                      cell = fn(dt) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.tableCell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setPk(dt.pk);
                      setShowEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setPk(dt.pk);
                      setShowMappingModal(true);
                    }}>
                    <strong>Map</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setEditHotel(dt);
                      setShowPrimaryHotelModal(true);
                    }}>
                    <strong>
                      {dt.primary ? "Remove Primary" : "Set Primary"}
                    </strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showEditModal && (
        <EditMapperModal
          mapper={editHotel}
          pk={pk}
          onClose={() => {
            setShowEditModal(false);
            setEditHotel({});
          }}
        />
      )}
      {showMappingModal && (
        <MappingModal
          pk={pk}
          onClose={() => {
            setShowMappingModal(false);
            setPk(null);
          }}
        />
      )}
      {showPrimaryHotelModal && (
        <PrimaryHotelModal
          message="You want to make this hotel Primary?"
          primary={_.get(editHotel, "primary")}
          pk={_.get(editHotel, "pk", 0)}
          onClose={() => {
            setShowPrimaryHotelModal(false);
            setEditHotel(null);
            queryClient.invalidateQueries("Mapper");
          }}
        />
      )}
    </div>
  );
};

export default Mapper;
