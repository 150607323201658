import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Navbar from "./Navbar";
import ControlStrip from "../../../../components/ControlStrip";
import Loader from "../../../../components/Loader";
import { handleApplyFilters, medWeekdayDatetimer } from "../../../../helpers";
import DetailFlightModal from "../FlightsModals/DetailFlightModal";
import {
  fetchFlightReservations,
  getFlightReservationStats,
} from "../../../../api/CRS/Flights";
import ReservationStats from "../../CommonCRSModals/ReservationStats/ReservationStats";
import { commonTableHeaders } from "../../helpers";
import { useQueries } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { genericRequestErrorHandler } from "../../../../api";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import { DateTime } from "luxon";
import { CustomButton } from "../../../../components/CustomButton";

const initialFilters = {
  limit: 20,
  page: 1,
  ordering: "",
  discending: "",
  arrival_date__lte: "",
  departure_date__lte: "",
  destination_airport__icontains: "",
  status: "",
  guests_number: "",
  created__gte: "",
  created__lte: "",
  external_reference__icontains: "",
  source_entity: "",
  network_search: "",
  reference: "",
  market_type: "",
};
const navbarSchema = yup.object().shape({
  name__icontains: yup
    .string()
    .min(2, "Name must be more than 1 characters")
    .max(100, "Name must be less than 100 characters"),
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const flightStyles = createUseStyles({
  Flight: {
    ...genericIndexesStyles.indexes.mobile(),
    gridTemplateRows: "auto auto auto  1fr",
  },
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tablecell: tableStyles.body.cell,
  actionCell: {
    ...tableStyles.body.cell,
    gap: variables.half_gap,
  },
  paxCell: { ...tableStyles.body.cell, minWidth: "7rem" },
  [`@media ${variables.media.smallscreen}`]: {
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});
const Flights = () => {
  const classes = flightStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [reference, setReference] = useState(null);

  const tableHeaders = commonTableHeaders({
    Tags: (val) => _.get(val, "tags", []).join(", "),
    "Origin airport": (val) =>
      _.get(
        val,
        "transportation_info.inbound.segments[0].origin_airport.name",
        "N/A"
      ),
    "Destination airport": (val) => {
      const segments = _.get(val, "transportation_info.inbound.segments", []);
      const lastDestinationAirport = _.get(
        segments[segments.length - 1],
        "destination_airport.name",
        "N/A"
      );

      return lastDestinationAirport;
    },
    Guests: (val) => _.get(val, "guests", []).length,
    Pax: (val) => {
      const guestTypes = _.get(val, "guests", []);
      const guestTypeCounts = guestTypes.reduce((acc, guest) => {
        const guestType = guest.guest_type;
        acc[guestType] = (acc[guestType] || 0) + 1;
        return acc;
      }, {});

      const formattedGuestTypes = Object.keys(guestTypeCounts).map(
        (guestType) => {
          const count = guestTypeCounts[guestType];
          return `${guestType}: ${count}`;
        }
      );

      const formattedPax = formattedGuestTypes.join(", ");
      const paxString = formattedPax
        .replace("ADT", "Adults")
        .replace("CHD", "Children");

      return paxString;
    },
    // Pax: (val) =>
    //   _.get(val, "guests", [])
    //     .map((guest) => guest.guest_type)
    //     .join(","),
    //   const pax = _.get(val, "pax", {});
    //   const children = _.get(pax, "children", 0);
    //   const adults = _.get(pax, "adults", 0);
    //   return `Children: ${children}, Adults: ${adults}`;
    // },
    "Queue Number": (val) =>
      _.get(val, "queue_number", 0) === 0
        ? "0"
        : _.get(val, "queue_number", 0) || "N/A",
    "Market Type": (val) => _.get(val, "transportation_info.market_type", ""),
  });
  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const results = useQueries({
    queries: [
      {
        queryKey: ["flights", filters],
        queryFn: async () => {
          let correctPayload = { ...filters };
          // if (filters.created__lte) {
          //   correctPayload["created__lte"] = DateTime.fromISO(
          //     filters.created__lte
          //   ).toISODate();
          // }
          // if (filters.created__gte) {
          //   correctPayload["created__gte"] = DateTime.fromISO(
          //     filters.created__gte
          //   ).toISODate();
          // }
          if (filters.created__lte) {
            correctPayload["created__lte"] = filters.created__lte.split("T")[0];
          }
          if (filters.created__gte) {
            correctPayload["created__gte"] = filters.created__gte.split("T")[0];
          }
          return await fetchFlightReservations({
            params: correctPayload,
          });
        },
        enabled: !showDetailModal,
        onError: (error) => genericRequestErrorHandler(error),
      },
      {
        queryKey: ["flightsStats", filters],
        queryFn: async () => {
          let correctPayload = { ...filters };
          if (filters.created__lte) {
            correctPayload["created__lte"] = DateTime.fromISO(
              filters.created__lte
            ).toISODate();
          }
          if (filters.created__gte) {
            correctPayload["created__gte"] = DateTime.fromISO(
              filters.created__gte
            ).toISODate();
          }
          return await getFlightReservationStats({
            params: correctPayload,
          });
        },
        enabled: !showDetailModal,
      },
    ],
  });
  return (
    <div id="FLListPage" className={classes.Flight}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
      />
      <HamburgerMenu />
      {!_.get(results, "1.isLoading", true) &&
        _.get(results, "1.data", null) && (
          <ReservationStats
            title="Reservation Stats"
            stats={_.get(results, "1.data.data.stats_per_currency")}
          />
        )}
      <ControlStrip
        limit={_.get(filters, "limit")}
        page={filters.page}
        count={_.get(results, "0.data.data.count")}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {_.get(results, "0.isLoading", false) ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                {Object.keys(tableHeaders).map((value, idx) => (
                  <th className={classes.headCell} key={idx}>
                    {value}
                  </th>
                ))}
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.get(results, "0.data.data.results", []).map((val, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.tablecell}>{idx + 1}</td>
                  {Object.entries(tableHeaders).map((entry, ridx) => {
                    var cell = null;
                    const fn = entry[1];
                    switch (entry[0].toLowerCase()) {
                      case "created":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        break;
                      case "pax":
                        cell = fn(val) || "N/A";
                        return (
                          <td className={classes.paxCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      default:
                        cell = fn(val) || "N/A";
                        break;
                    }
                    return (
                      <td className={classes.tablecell} key={ridx}>
                        {cell}
                      </td>
                    );
                  })}
                  <td className={classes.actionCell}>
                    <CustomButton
                      id={`detailFLButton_${idx}`}
                      appearance="ghost"
                      onClick={() => {
                        setShowDetailModal(true);
                        setReference(val.reference);
                      }}>
                      <strong>Details</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showDetailModal && (
        <DetailFlightModal
          onClose={() => {
            setShowDetailModal(false);
          }}
          reference={reference}
        />
      )}
    </div>
  );
};

export default Flights;
