import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../../../components/Loader";
import { variables } from "../../../../../variables";
import { getAccommodationReservationStats } from "../../../../../api/CRS/Accommodations";
import { getFlightReservationStats } from "../../../../../api/CRS/Flights";
import { getTransferReservationStats } from "../../../../../api/CRS/Transfers";
import { getTrainApiReservationStats } from "../../../../../api/CRS/ApiTrains";
import { DateTime } from "luxon";

const titleMapping = {
  cxled_confirmed_percs: {
    ACC: `Accommodations Reservations 
    Statuses`,
    FL: `Flights Reservations 
    Statuses`,
    TRF: `Transfers Reservations 
    Statuses`,
    TRA: `Trains Reservations 
    Statuses`,
  },
  fail_success_percs: {
    ACC: `Accommodations Reservations
    Successfull Versus Fails Bookings`,
    FL: `Flights Reservations
    Successfull Versus Fails Bookings`,
    TRF: `Transfers Reservations
    Successfull Versus Fails Bookings`,
    TRA: `Trains Reservations
    Successfull Versus Fails Bookings`,
  },
};
const srvMapping = {
  ACC: "Accommodations",
  FL: "Flights",
  TRF: "Transfers",
  TRA: "Trains",
};
const chartStyles = createUseStyles({
  Chart: {
    display: "grid",
    padding: variables.normal_gap,
    width: "25vw",
    height: "30vh",
  },
  [`@media ${variables.media.smallscreen}`]: {},
});
const Chart = ({ serviceType, chartType, currency, localFilters }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const classes = chartStyles();
  const chartRef = useRef(null);

  const funcMapping = {
    ACC: getAccommodationReservationStats,
    FL: getFlightReservationStats,
    TRF: getTransferReservationStats,
    TRA: getTrainApiReservationStats,
  };

  const { data, isLoading } = useQuery({
    queryKey: [`${serviceType}_Chart`, serviceType, localFilters],
    queryFn: async () => {
      var payload = { ...localFilters };
      if (payload.created__gte && ["ACC", "TRA"].includes(serviceType)) {
        payload.created__gte = DateTime.fromISO(payload.created__gte)
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .toISO({ includeOffset: false, suppressMilliseconds: true });

        if (payload.created__lte && ["ACC", "TRA"].includes(serviceType)) {
          payload.created__lte = DateTime.fromISO(payload.created__lte)
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 0,
            })
            .toISO({ includeOffset: false, suppressMilliseconds: true });
        }
      }
      return await funcMapping[serviceType]({ params: { ...payload } });
    },
  });

  const createChartInstance = () => {
    const chartInstance = echarts.init(chartRef.current);
    const dataSources = Object.entries(
      _.get(data, `data.stats_per_currency.${currency}.${chartType}`, {})
    );
    const finalData = dataSources.map(([name, value]) => ({
      name: _.capitalize(name),
      value,
    }));
    const option = {
      title: [
        {
          text: _.get(titleMapping, `${chartType}.${serviceType}`, "N/A"),
          top: "5%",
          left: "center",
          textStyle: {
            fontWeight: "bold",
            fontSize: windowSize >= 1024 ? 16 : 5,
          },
        },
      ],
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          return `<strong>${srvMapping[serviceType]}</strong><br /> ${
            params.name
          } ${params.value * 100}%`;
        },
      },
      legend: {
        type: "scroll",
        top: "22%",
        left: "center",
      },
      series: [
        {
          top: "25%",
          name: `${srvMapping[serviceType]} Reservations`,
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: true,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: false,
            position: "start",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: windowSize >= 1920 ? 15 : 10,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: true,
          },
          data: finalData,
        },
      ],
    };

    chartInstance.setOption(option);
    return chartInstance;
  };
  const handleResize = () => setWindowSize(window.innerWidth);

  window.addEventListener("resize", handleResize);
  useEffect(() => {
    createChartInstance();
  }, [data, currency]);

  useEffect(() => {
    if (isLoading) return;
    const existingChart = echarts.getInstanceByDom(chartRef.current);
    if (existingChart) {
      existingChart.resize();
    }
  }, [windowSize]);

  return (
    <React.Fragment>
      {isLoading && <Loader onTop={true} />}
      <div ref={chartRef} className={classes.Chart} />
    </React.Fragment>
  );
};
Chart.propTypes = {
  serviceType: PropTypes.oneOf(["ACC", "FL", "TRF", "TRA"]).isRequired,
  currency: PropTypes.string.isRequired,
  chartType: PropTypes.oneOf(["cxled_confirmed_percs", "fail_success_percs"])
    .isRequired,
  color: PropTypes.string.isRequired,
  localFilters: PropTypes.object,
};
export default Chart;
