import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import { statusMapping } from "../../common";
import Loader from "../../../../../components/Loader";
import {
  createWebSiteAboutUs,
  patchWebSiteAboutUs,
  retrieveWebSiteAboutUs,
} from "../../../../../api/DistributionSite/webSites/AboutUs";
import _ from "lodash";
import { DateTime } from "luxon";
import { fetchWebSiteLandingPages } from "../../../../../api/DistributionSite/webSites/LandingPages";
import QuilEditor from "../../../../../components/QuilEditor";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import * as yup from "yup";
import { handleValidationErrors } from "../../../../../helpers";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .max(100, "Name: Must be less than 100 characters")
    .nullable()
    .default(""),
  title: yup.string().nullable().default(""),
  landing_page: yup.string().nullable().default(""),
  description: yup.string().nullable().default(null),
  status: yup
    .mixed()
    .oneOf(["AC", "IN", "CA"], "Must be between Active, Inactive or Candidate")
    .nullable()
    .default("CA"),
  active_from: yup.string().nullable(),
  active_to: yup.string().nullable(),
});

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditAboutUsModal: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  modalCard: {
    ...modalGenericStyles.card,
    maxWidth: "65rem",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  loader: { width: "100vw" },
  inputGroup: formInputGroup,
  descriptionCardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    display: "grid",
    padding: "",
    paddingBottom: variables.normal_gap,
  },
  description: {
    gap: variables.normal_gap,
    "& label": {
      fontSize: "small",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardCol2: {
      gridTemplateColumns: "1fr 1fr",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    modalCard: {
      minWidth: "65rem",
    },
  },
});

const EditAboutUsModal = ({ sourceEntity, aboutUsId, onClose }) => {
  let mode = aboutUsId ? "edit" : "add";
  const classes = styles();

  const [form, setForm] = useState(jsonSchema.cast({}));
  const [landingPages, setLandingPages] = useState([{}]);

  const queryClient = useQueryClient();

  const { data: aboutUs, isLoading: retrieveAboutUsLoading } = useQuery({
    queryKey: ["RetrieveEditAboutUsModal", aboutUsId],
    queryFn: () => retrieveWebSiteAboutUs({ id: aboutUsId }),
    onSuccess: (data) => {
      setForm(jsonSchema.cast(_.get(data, "data", {})));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    enabled: mode === "edit",
  });

  const { isLoading: landingPagesLoading } = useQuery({
    queryKey: ["retrieveWebSiteLandingPages"],
    queryFn: async () =>
      await fetchWebSiteLandingPages({
        website: aboutUsId,
        source_entity: sourceEntity,
      }),
    onSuccess: (data) => {
      const options = [
        { value: "", label: "-----" },
        ..._.get(data, "data.results", []).map((lp) => ({
          value: lp.id,
          label: `${lp.title || "N/A"} (${_.get(statusMapping, lp.status)})`,
        })),
      ];
      setLandingPages(options);
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const addMutation = useMutation({
    mutationFn: ({ payload }) => {
      return createWebSiteAboutUs({
        payload,
      });
    },
    onSuccess: () => {
      toast.success("About Us Page Added");
      queryClient.invalidateQueries("AboutUs");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ payload, id }) => {
      return patchWebSiteAboutUs({
        id,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("About Us Info Updated");
      queryClient.invalidateQueries("AboutUs");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const preparePayload = (form) => {
    let payload = { ...form };
    if (form.active_from) {
      payload["active_from"] = DateTime.fromISO(form.active_from).toISO();
    }
    if (form.active_to) {
      payload["active_to"] = DateTime.fromISO(form.active_to).toISO();
    }
    return payload;
  };

  const onSubmit = async ({ id, payload }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload });
      } else {
        editMutation.mutate({ id, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const loading =
    (mode === "edit" && retrieveAboutUsLoading) ||
    landingPagesLoading ||
    addMutation.isLoading ||
    editMutation.isLoading;

  const handleInputChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  return (
    <div className={classes.EditAboutUsModal}>
      {loading ? (
        <React.Fragment>
          <div className={classes.modalCard}>
            <div className={classes.modalCardHeader}>
              {_.startCase(mode)} About Us
            </div>
            <div className={classes.modalCardBody}>
              <Loader />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            {_.startCase(mode)} About Us
          </div>
          <div className={classes.modalCardBody}>
            <form onChange={handleInputChange}>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="name">Name</label>
                      <input type="text" name="name" value={form.name} />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="title">Title</label>
                      <input type="text" name="title" value={form.title} />
                    </div>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="landing_page">Landing Page</label>
                    <select
                      name="landing_page"
                      value={form.landing_page}
                      onChange={handleInputChange}
                      disabled={mode === "edit"}>
                      {landingPages.map((option, idx) => (
                        <option key={idx} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={classes.card}>
                    <div className={classes.descriptionCardBody}>
                      <div className={classes.description}>
                        <label htmlFor="description">Description</label>
                        <QuilEditor
                          type="text"
                          name="description"
                          value={form.description}
                          onChange={(value) => {
                            let newForm = {
                              ...form,
                              description: value,
                            };
                            setForm(newForm);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Active Data</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="active_from">Active From</label>
                      <input
                        type="date"
                        name="active_from"
                        value={
                          form.active_from
                            ? DateTime.fromISO(form.active_from).toISODate()
                            : "N/A"
                        }
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="active_to">Active To</label>
                      <input
                        type="date"
                        name="active_to"
                        value={
                          form.active_to
                            ? DateTime.fromISO(form.active_to).toISODate()
                            : "N/A"
                        }
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        value={form.status}
                        // onChange={handleInputChange}
                      >
                        {Object.entries(statusMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  id: aboutUsId,
                  payload: form,
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditAboutUsModal.propTypes = {
  aboutUsId: PropTypes.number,
  sourceEntity: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditAboutUsModal;
