import axios from "axios";
import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchTrainReservations({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/train/`,
    {
      params,
    }
  );
}

export function retrieveTrainReservation({ reference }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/train/${reference}/`
  );
}
export async function patchTrainReservation({ reference, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/train/${reference}/`,
    payload
  );
}
export function getTrainReservationStats({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/train/stats/`,
    { params }
  );
}
