import axios from "axios";
import { VALAQUENTA_API_URL } from "../..";

export function getCandidates({ params }) {
  return axios.get(`${VALAQUENTA_API_URL}/accommodator/mapping_candidate/`, {
    params: { ...params, radius: 2500 },
  });
}

export function patchMappingHotels({ payload }) {
  return axios.patch(
    `${VALAQUENTA_API_URL}/accommodator/hotel_mapping/`,
    payload
  );
}

export function patchMappingPrimaryHotels({ hotelPk, payload }) {
  return axios.patch(
    `${VALAQUENTA_API_URL}/accommodator/internal/hotels/${hotelPk}/`,
    payload
  );
}
