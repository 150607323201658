import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import { useMutation } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import Loader from "../../../../../components/Loader";
import { deletePaymentTerm } from "../../../../../api/Vendor/PaymentsTerms";
import _ from "lodash";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  DeletePaymentTerms: modalGenericStyles.modal,
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    gridTemplateColumns: "repeat(2, max-content)",
  },
});

const DeletePaymentTerms = ({ entity, onClose }) => {
  const classes = styles();

  const deletedId = _.get(entity, "id", null);

  const basicDataCpl = [
    ["Id", "id"],
    ["Bank Name", "bank_name"],
    ["Bank Account", "bank_account"],
    ["IBAN", "iban"],
    ["SWIFT", "swift"],
  ];

  const deletePaymentTermMutation = useMutation({
    mutationFn: deletePaymentTerm,
    onSuccess: () => {
      toast.success("Payment Term Deleted");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const onDelete = (id) => {
    deletePaymentTermMutation.mutate({ id });
  };
  return (
    <div id="deletePaymentTermsModal" className={classes.DeletePaymentTerms}>
      <React.Fragment>
        <div className={classes.modalCard}>
          {deletePaymentTermMutation.isLoading && <Loader onTop={true} />}
          <div className={classes.modalCardHeader}>
            <span>DELETE Payment Term</span>
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.card}>
              <div className={classes.cardBody}>
                <div className={classes.basicInfo}>
                  <GenericBasicInfo
                    header={"Are you sure you want to delete this?"}
                    cardInfo={basicDataCpl}
                    objectData={entity}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.cardActions}>
            <CustomButton
              id="cancelDeletePaymentTermsButton"
              appearance="ghost"
              onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="deletePaymentTermsButton"
              appearance="primary"
              onClick={() => {
                onDelete(deletedId);
              }}>
              <strong>DELETE</strong>
            </CustomButton>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
DeletePaymentTerms.propTypes = {
  entity: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DeletePaymentTerms;
