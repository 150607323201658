import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import {
  formatToCurrency,
  handleApplyFilters,
  medWeekdayDatetimer,
} from "../../../../../helpers";
import _ from "lodash";
import ControlStrip from "../../../../../components/ControlStrip";
import { Price } from "../../../../../components/Price";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { CustomButton } from "../../../../../components/CustomButton";
import * as yup from "yup";
import Loader from "../../../../../components/Loader";
import { getSubagentPoformaStatment } from "../../../../../api/CRM/SubAgents/SubAgents";
import AddProformaStatment from "../ProformaModals/AddProformaStatment";
import Navbar from "./Navbar";
import { DocumentsCard } from "../../../CommonCRMModals/Documents";

const entryTypeMapping = {
  CR: "Credit",
  DB: "Debit",
};
const productCategoryMapping = {
  "": "-----",
  ACC: "Accommodation",
  FL: "Flights",
  FER: "Ferries",
  TRA: "Trains",
  TR: "Transportation",
  TRF: "Transfers",
  MI: "Add Ons",
  REST: "Restaurants",
  COO: "Coordinators",
  GEN: "General Services",
  AD: "Ad hoc Land Services",
  ACT: "Activitiy",
  CAR: "Car Rental",
};
const styles = createUseStyles({
  ...cardStyles,
  SubagentProformaBuyingStatment: {
    ...cardStyles.card,
    display: "grid",
    gridTemplateRows: "max-content max-content max-content max-content",
    maxWidth: "100%",
    overflow: "auto",
  },
  credits: {
    display: "grid",
    gridTemplateRows: "1fr max-content",
    maxWidth: "100%",
    overflowX: "auto",
    padding: variables.normal_gap,
    gap: variables.normal_gap,
  },
  totals: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    justifyItems: "center",
    padding: variables.normal_gap,
    gap: variables.normal_gap,
    alignItems: "end",
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  body: { ...tableStyles.body, display: "grid" },
  tableCell: tableStyles.body.cell,
  trow: tableStyles.body.row,
  actions: {
    ...cardStyles.actions,
    alignContent: "end",
  },
  actionCell: genericIndexesStyles.actionCell.mobile,
  refresh: {},
  add: {
    display: "inline",
  },
  amount: {
    ...tableStyles.body.cell,
    width: "8rem",
  },
});

const SubagentProformaBuyingStatment = ({ entityData }) => {
  const classes = styles();
  const queryClient = useQueryClient();

  const navbarSchema = yup.object().shape({
    limit: yup.number().default(20),
    page: yup.number().default(1),
    product_type__icontains: yup.string().default(""),
    entry_type: yup.string().default(""),
    amount__gte: yup.number().nullable().default(null),
    amount__lte: yup.number().nullable().default(null),
    // currency: yup.string().default(""),
    currency: yup.string().default(entityData?.currency || ""),
    tags: yup.string().default(""),
    created__gte: yup.string().default(""),
    created__lte: yup.string().default(""),
  });

  const initialfilters = {
    limit: 20,
    page: 1,
    product_type__icontains: "",
    entry_type: "",
    amount__gte: "",
    amount__lte: "",
    tags: [""],
    currency: entityData?.currency || "",
    created__gte: "",
    created__lte: "",
  };

  const [filters, setFilters] = useState(navbarSchema.cast({}));

  const [pricing, setPricing] = useState({
    total_credit: 0,
    total_debit: 0,
    balance: 0,
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [entryId, setEntryId] = useState(null);
  const [entryType, setEntryType] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState([]);

  function applyFilters(filters, additionalFilters = {}) {
    var filteredFilters = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        filteredFilters[key] = value;
      }
    });

    return { ...filteredFilters, ...additionalFilters };
  }

  const tableHeaders = {
    "Product Type": (val) =>
      _.get(productCategoryMapping, _.get(val, "product_type")),
    Amount: (val) => <Price price={val.amount} currency={val.currency} />,
    "Entry Type": (val) => _.get(entryTypeMapping, _.get(val, "entry_type")),
    Tags: (val) => _.get(val, "tags", "").join(", "),
    // "Expiration Datetime": (val) => val.expiration_datetime,
    Created: (val) => val.created,
  };

  const { data: deposits, isLoading: depositsLoading } = useQuery({
    queryKey: ["subagentProformaBuyingStatment", filters],
    queryFn: () =>
      getSubagentPoformaStatment({
        params: applyFilters(filters, {
          source_entity: `${_.get(entityData, "id")}___subagent`,
          // parent_entity: `${_.get(entityData, "member")}___member`,
          // currency: _.get(entityData, "currency", ""),
        }),
      }),
    onSuccess: (deposits) => {
      if (
        !currencyOptions.length &&
        (deposits?.data?.results || []).length > 0
      ) {
        setCurrencyOptions(deposits?.data?.currencies);
        setFilters((p) => ({ ...p, currency: deposits?.data?.currencies[0] }));
      }
      setPricing({
        total_credit: _.get(deposits, "data.total_credit"),
        total_debit: _.get(deposits, "data.total_debit"),
        balance: _.get(deposits, "data.balance"),
      });
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  console.log("entityData", entityData);

  const onRefreshHandler = () => {
    queryClient.invalidateQueries("DetailsSubAgentModal");
  };

  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const currency = _.get(deposits, "data.results.0.currency");

  return (
    <div
      id="SubagentProformaBuyingStatments"
      className={classes.SubagentProformaBuyingStatment}>
      <Navbar
        filters={filters}
        initialFilters={initialfilters}
        currencyOptions={currencyOptions}
        onApplyFilters={handleApplyLocalFilters}
      />
      <ControlStrip
        limit={filters.limit}
        page={filters.page}
        count={_.get(deposits, "data.count")}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {depositsLoading ? (
        // <div className={classes.header}></div>
        <div className={classes.SubagentProformaBuyingStatment}>
          <div className={classes.body}>
            <Loader />
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className={classes.credits}>
            <div className={classes.totals}>
              <div>
                Total Credit:{" "}
                {currency
                  ? formatToCurrency(
                      Math.abs(parseFloat(pricing.total_credit)),
                      currency
                    )
                  : 0}
              </div>
              <div>
                Total Debit:{" "}
                {currency
                  ? formatToCurrency(
                      Math.abs(parseFloat(pricing.total_debit)),
                      currency
                    )
                  : 0}
              </div>
              <div>
                Balance:{" "}
                {currency
                  ? formatToCurrency(
                      Math.abs(parseFloat(pricing.balance)),
                      currency
                    )
                  : 0}{" "}
              </div>
            </div>
            <div className={classes.actions}>
              <div className={classes.refresh}>
                <CustomButton
                  id="refreshDespositStatments"
                  appearance="ghost"
                  onClick={onRefreshHandler}>
                  <strong>Refresh</strong>
                </CustomButton>
              </div>
              {/* {entityType === "member" ? (
                <div></div>
              ) : (
                <React.Fragment>
                  <div className={classes.add}>
                    <CustomButton
                      id="addProformaCreditButton"
                      appearance="ghost"
                      onClick={() => {
                        setShowAddModal(true);
                        setEntryType("CR");
                      }}>
                      <strong>Add Credit</strong>
                    </CustomButton>
                  </div>
                  <div className={classes.add}>
                    <CustomButton
                      id="addProformaDebitButton"
                      appearance="ghost"
                      onClick={() => {
                        setShowAddModal(true);
                        setEntryType("DB");
                      }}>
                      <strong>Add Debit</strong>
                    </CustomButton>
                  </div>
                </React.Fragment>
              )} */}
            </div>
          </div>
          <div className={classes.body}>
            <table>
              <thead>
                <tr className={classes.headRow}>
                  <th className={classes.headCell}>#</th>
                  {Object.keys(tableHeaders).map((value, idx) => (
                    <th key={idx} className={classes.headCell}>
                      {value}
                    </th>
                  ))}
                  <th className={classes.headCell}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {_.get(deposits, "data.results", []).map((val, idx) => (
                  <tr className={classes.trow} key={idx}>
                    <td className={classes.tableCell}>{idx + 1}</td>
                    {Object.entries(tableHeaders).map((entry, ridx) => {
                      var cell = null;
                      const fn = entry[1];
                      switch (entry[0].toLowerCase()) {
                        case "amount":
                          cell = fn(val) || "N/A";
                          return (
                            <td className={classes.amount} key={ridx}>
                              {cell}
                            </td>
                          );
                        case "created":
                          cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                          break;
                        default:
                          cell = fn(val) || "N/A";
                          break;
                      }
                      return (
                        <td className={classes.tableCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    })}
                    <td className={classes.actionCell}>
                      <CustomButton
                        id={`DocumentCard_${idx}`}
                        appearance="ghost"
                        onClick={() => {
                          setEntryId(val.id);
                          setShowDocumentModal(true);
                        }}>
                        <strong>Documents</strong>
                      </CustomButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
      {showAddModal && (
        <AddProformaStatment
          entityType="subagent"
          entityData={entityData}
          invalidationKey={"DetailsSubAgentModal"}
          entryType={entryType}
          onClose={() => {
            setShowAddModal(false);
          }}
        />
      )}
      {showDocumentModal && (
        <DocumentsCard
          tags={[
            `subagent_deposit_statement`,
            `${entryId}_subagent_deposit_statement`,
          ]}
          sourceEntity={`${_.get(entityData, "id")}___branch`}
          onClose={() => {
            setShowDocumentModal(false);
          }}
        />
      )}
    </div>
  );
};
SubagentProformaBuyingStatment.defaultProps = {
  entityData: {},
};
SubagentProformaBuyingStatment.propTypes = {
  entityData: PropTypes.object.isRequired,
};
export default SubagentProformaBuyingStatment;
