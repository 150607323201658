import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../../../components/Loader";
import { variables } from "../../../../../variables";
import { getAccommodationReservationStats } from "../../../../../api/CRS/Accommodations";
import { DateTime } from "luxon";
import { getTransferReservationStats } from "../../../../../api/CRS/Transfers";
import { getFlightReservationStats } from "../../../../../api/CRS/Flights";
import { getTrainApiReservationStats } from "../../../../../api/CRS/ApiTrains";

const titleMapping = {
  res_per_day: {
    ACC: "Accommodations Reservations Per Day Timeseries",
    FL: "Flights Reservations Per Day Timeseries",
    TRF: "Transfers Reservations Per Day Timeseries",
    TRA: "Trains Reservations Per Day Timeseries",
  },
  avg_price_per_day: {
    ACC: "Accommodations Avg Price Per Day Timeseries",
    FL: "Flights Avg Price Per Day Timeseries",
    TRF: "Transfers Avg Price Per Day Timeseries",
    TRA: "Trains Avg Price Per Day Timeseries",
  },
};

const dataSourceMapping = {
  res_per_day: "reservations_per_day_series",
  avg_price_per_day: "avg_price_per_day_series",
};

const tooltipLabelMapping = {
  res_per_day: "Reservations",
  avg_price_per_day: "Avg Price",
};

const srvMapping = {
  FER: "Ferries",
  ACC: "Accommodations",
  RES: "Restaurants",
  ACT: "Activities",
  TRA: "Trains",
};

const chartStyles = createUseStyles({
  chart: {
    display: "grid",
    padding: variables.normal_gap,
    width: "50vw",
    height: "30vh",
  },
  [`@media ${variables.media.smallscreen}`]: {},
});
const TimeChart = ({
  serviceType,
  chartType,
  currency,
  color,
  localFilters,
}) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const classes = chartStyles();
  const chartRef = useRef(null);

  const funcMapping = {
    ACC: getAccommodationReservationStats,
    FL: getFlightReservationStats,
    TRF: getTransferReservationStats,
    TRA: getTrainApiReservationStats,
  };

  const { data, isLoading } = useQuery({
    queryKey: [`${serviceType}_Chart`, serviceType, localFilters],
    queryFn: async () => {
      var payload = { ...localFilters };
      if (payload.created__gte && ["ACC", "TRA"].includes(serviceType)) {
        payload.created__gte = DateTime.fromISO(payload.created__gte)
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .toISO({ includeOffset: false, suppressMilliseconds: true });

        if (payload.created__lte && ["ACC", "TRA"].includes(serviceType)) {
          payload.created__lte = DateTime.fromISO(payload.created__lte)
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 0,
            })
            .toISO({ includeOffset: false, suppressMilliseconds: true });
        }
      }
      return await funcMapping[serviceType]({
        params: { ...payload },
      });
    },
  });
  const createChartInstance = (data) => {
    const chartInstance = echarts.init(chartRef.current);
    const data_per_day = _.get(
      data,
      `data.stats_per_currency.${currency}.${dataSourceMapping[chartType]}`,
      []
    );

    const finalData = data_per_day.map(([xAxis, yAxis]) => {
      return {
        name: DateTime.fromISO(xAxis).toLocaleString(
          DateTime.DATE_MED_WITH_WEEKDAY
        ),
        value: [new Date(xAxis).getTime(), yAxis],
      };
    });

    const options = {
      title: [
        {
          text: _.get(titleMapping, `${chartType}.${serviceType}`, "N/A"),
          left: "center",
          top: "5%",
          textStyle: {
            fontWeight: "bold",
            fontSize: windowSize >= 1024 ? 16 : 5,
          },
        },
      ],
      tooltip: {
        trigger: "axis",
        position: function (pt) {
          return [pt[0], "10%"];
        },
        formatter: (params) => {
          const name = params[0].name;
          var value = params[0].value[1];

          if (chartType === "avg_price_per_day") {
            value = value.toLocaleString("en-US", {
              style: "currency",
              currency: currency,
            });
          }

          return `${name}<br/><strong>${value} ${tooltipLabelMapping[chartType]}</strong>`;
        },
      },
      toolbox: {
        top: windowSize <= 1024 ? "15%" : "0%",
        feature: {
          dataZoom: {
            yAxisIndex: "none",
          },
          restore: {},
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "time",
        boundaryGap: false,
      },
      yAxis: {
        type: "value",
        boundaryGap: [0, "100%"],
        max: "dataMax",
      },
      dataZoom: [
        {
          type: "inside",
          start: 0,
          end: 100,
        },
        {
          start: 0,
          end: 100,
        },
      ],
      series: [
        {
          name: `${srvMapping[serviceType]} Reservations`,
          type: "line",
          smooth: true,
          symbol: "none",
          areaStyle: { color },
          data: finalData,
          lineStyle: {
            color: color,
          },
        },
      ],
    };

    chartInstance.setOption(options);
    return chartInstance;
  };

  const handleResize = () => setWindowSize(window.innerWidth);
  window.removeEventListener("resize", handleResize);

  useEffect(() => {
    if (isLoading) return;
    createChartInstance(data);
    window.addEventListener("resize", handleResize);
  }, [isLoading, data, currency]);

  useEffect(() => {
    const existingChart = echarts.getInstanceByDom(chartRef.current);
    if (existingChart) {
      existingChart.resize();
    }
  }, [windowSize]);

  return (
    <React.Fragment>
      {isLoading && <Loader onTop={true} />}
      <div ref={chartRef} className={classes.chart} />
    </React.Fragment>
  );
};
TimeChart.propTypes = {
  serviceType: PropTypes.oneOf(["ACC", "FL", "TRF", "TRA"]).isRequired,
  chartType: PropTypes.oneOf(["res_per_day", "avg_price_per_day"]).isRequired,
  currency: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  localFilters: PropTypes.object,
};

export default TimeChart;
