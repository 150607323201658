import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchActAPIConfigurations({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/activitiesengine/api-config/musement/`,
    { params }
  );
}

export function retrieveActAPIConfiguration({ uid }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/activitiesengine/api-config/musement/${uid}/`
  );
}

export function patchActivity({ uid, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/activitiesengine/api-config/musement/${uid}/`,
    payload
  );
}

export function createNewActivity({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/activitiesengine/api-config/musement/`,
    payload
  );
}

export function cloneActivity({ uid }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/activitiesengine/api-config/musement/clone/${uid}/`
  );
}

export function deleteActAPIConfigurations({ uid }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/activitiesengine/api-config/musement/${uid}/`
  );
}
