import React from "react";
import { createUseStyles } from "react-jss";
import { cardStyles, modalGenericStyles, variables } from "../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import Loader from "../../../components/Loader";
import DetailedLog from "../DetailedLog";
import { CustomButton } from "../../CustomButton";

export const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  UserInfoDetailModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    overflowX: "hidden",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: { ...cardStyles.card, overflow: "auto" },
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  greenText: {
    color: "green",
  },
  redText: {
    color: "red",
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardBody: { justifyContent: "left" },
  },
});
const UserInfoDetailModal = ({ data, onClose }) => {
  const classes = styles();

  return (
    <div className={classes.UserInfoDetailModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>User Details</span>
        </div>
        <div className={classes.modalCardBody}>
          {_.isEmpty(data) ? (
            <Loader />
          ) : (
            <div className={classes.card}>
              <DetailedLog log_source={_.get(data, "_source", {})} />
            </div>
          )}
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
UserInfoDetailModal.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default UserInfoDetailModal;
