import React from "react";
import { createUseStyles } from "react-jss";
import {
  modalGenericStyles,
  variables,
  cardStyles,
} from "../../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { retrieveScenarioDetail } from "../../../../../api/Monitoring/Scenarios";
import Loader from "../../../../../components/Loader";
import CallSection from "./CallSection";
import { DateTime } from "luxon";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailScenario: {
    ...modalGenericStyles.modal,
  },
  cardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  basicInfo: {
    display: "grid",
    gap: variables.normal_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
    alignContent: "center",
  },
  infoCard: {
    ...cardStyles.card,
    padding: variables.normal_gap,
    textAlign: "center",
    backgroundColor: variables.colors.background.dark,
    color: "white",
  },
  steps: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    gap: variables.double_gap,
    overflow: "auto",
  },
  [`@media ${variables.media.smallscreen}`]: {
    infoCard: {
      height: "5rem",
      padding: variables.normal_gap,
      textAlign: "center",
      backgroundColor: variables.colors.background.dark,
      color: "white",
      lineHeight: "2rem",
    },
  },
});

const DetailScenario = ({
  scenario_uid,
  environment,
  scenario_name,
  onClose,
}) => {
  const classes = styles();

  const { data, isLoading } = useQuery({
    queryKey: ["DetailModalScenario"],
    queryFn: () =>
      retrieveScenarioDetail({
        scenario_uid: scenario_uid,
        params: { scenario_name: scenario_name, env: environment },
      }),
    onError: (error) => genericRequestErrorHandler(error),
    refetchOnWindowFocus: false,
  });
  const scenarioNameData = _.get(data, "data.result.scenario_name");
  const scenarioName = scenarioNameData
    ? _.capitalize(scenarioNameData.replace(/-/g, " "))
    : "N/A";

  const dataKeys = Object.keys(_.get(data, "data.result.data", {})).filter(
    (key) => key !== "status"
  );

  return (
    <div className={classes.DetailScenario}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <span>Scenario Details</span>
        </div>
        <div className={classes.cardBody}>
          {isLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className={classes.basicInfo}>
                <div className={classes.infoCard}>
                  <span>
                    <strong>
                      Scenario name: <br />
                    </strong>
                    {scenarioName}
                  </span>
                </div>
                <div className={classes.infoCard}>
                  <span>
                    <strong>
                      Timestamp: <br />
                    </strong>
                    {DateTime.fromISO(
                      _.get(data, "data.result.timestamp")
                    ).toLocaleString(DateTime.DATETIME_SHORT) || "N/A"}
                  </span>
                </div>
              </div>
              <div className={classes.steps}>
                {dataKeys.map((key) => (
                  <CallSection
                    header={_.startCase(key)}
                    data={_.get(data, `data.result.data.${key}`, {})}
                  />
                ))}
              </div>
            </React.Fragment>
          )}
        </div>
        <div className={classes.cardActions}>
          <CustomButton appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailScenario.propTypes = {
  environment: PropTypes.string.isRequired,
  scenario_uid: PropTypes.string.isRequired,
  scenario_name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DetailScenario;
