import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  formInputGroup,
  modalGenericStyles,
  variables,
  cardStyles,
} from "../../../../../../variables";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import * as yup from "yup";
import _ from "lodash";
import Loader from "../../../../../../components/Loader";
import { createRefactoredBranchCreditStatment } from "../../../../../../api/CRM/Branches/Branches";
import { CustomButton } from "../../../../../../components/CustomButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../../api";
import { currencies, handleValidationErrors } from "../../../../../../helpers";
import { createRefactoredSubagentCreditStatment } from "../../../../../../api/CRM/SubAgents/SubAgents";
import { createVendorCreditStatment } from "../../../../../../api/Vendor/commonVendorAPI";

const productCategoryMapping = {
  "": "-----",
  ACC: "Accommodation",
  FL: "Flights",
  FER: "Ferries",
  TRA: "Trains",
  TR: "Transportation",
  TRF: "Transfers",
  MI: "Add Ons",
  REST: "Restaurants",
  COO: "Coordinators",
  GEN: "General Services",
  AD: "Ad hoc Land Services",
  ACT: "Activitiy",
  CAR: "Car Rental",
};

const addDepositStatmentStyles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  AddDepositStatment: modalGenericStyles.modal,
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  tagscardBody: {
    ...cardStyles.body,
    display: "grid",
    gridTemplateColumns: "1fr max-content",
  },
  inputGroup: formInputGroup,
  tagsActions: {
    ...modalGenericStyles.cardActions,
    justifyContent: "start",
    height: "1.5rem",
    alignSelf: "end",
  },
  tags: {
    ...modalGenericStyles.card,
    display: "grid",
    gridColumn: "span 2",
    lineHeight: "5vh",
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardCol2: {
      gridTemplateColumns: "1fr 1fr",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
});

const AddDepositStatment = ({
  entityType,
  entityData,
  invalidationKey,
  entryType,
  onClose,
}) => {
  const classes = addDepositStatmentStyles();

  const jsonSchema = yup.object().shape({
    amount: yup.number().nullable().default(0),
    description: yup.string().default(""),
    product_type: yup.string().default(""),
    currency: yup
      .string()
      .default(entityData?.currency || entityData?.vendor_data?.currency || ""),
    tags: yup.array().of(yup.string()).default([""]),
  });

  const [form, setForm] = useState(jsonSchema.cast({}));

  const querryClient = useQueryClient();

  const titleEntry = entryType === "DB" ? "Debit" : "Credit";

  const entityDataMapping = {
    branch: async ({ payload }) =>
      await createRefactoredBranchCreditStatment({
        payload,
      }),
    subagent: async ({ payload }) =>
      await createRefactoredSubagentCreditStatment({
        payload,
      }),
    vendor: async ({ payload }) =>
      await createVendorCreditStatment({
        payload,
      }),
  };

  const addMutation = useMutation({
    mutationFn: async ({ payload }) => {
      return await entityDataMapping[entityType]({ payload });
    },
    onSuccess: () => {
      toast.success(`New ${titleEntry} Statment added`);
      querryClient.invalidateQueries(invalidationKey);
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ entryType, entityData, formData }) => {
    try {
      const filteredTags = formData.tags.filter((tag) => tag !== "");
      // if (entryType === "DB") {
      //   formData.amount *= -1;
      // }
      var amount = parseFloat(formData.amount);
      if (entryType === "DB") {
        amount = amount * -1;
      }
      amount = amount.toString();
      const payload = {
        ...form,
        tags: filteredTags,
        amount: amount,
        source_entity: `${_.get(entityData, "id")}___${entityType}`,
        parent_entity: `${_.get(entityData, "member")}___member`,
        // currency: form.currency || _.get(entityData, "currency", ""),
        // currency:
        //   entityType === "vendor"
        //     ? form.currency
        //     : _.get(entityData, "currency", ""),
        entry_type: entryType,
      };

      await jsonSchema.validate(form, { abortEarly: false });
      addMutation.mutate({ payload });
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const handleInputChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  return (
    <div
      id="AddCreditDebitDepositStatment"
      className={classes.AddDepositStatment}>
      {addMutation.isLoading ? (
        <div className={classes.modalCard}>
          <div className={classes.cardHeader}>{titleEntry} Statment</div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.cardHeader}> Add {titleEntry} Statment</div>
          <div className={classes.modalCardBody}>
            <form>
              <div className={classes.card}>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="product_type">Product Type</label>
                      <select
                        id="product_type"
                        name="product_type"
                        value={form.product_type}
                        onChange={handleInputChange}>
                        {Object.entries(productCategoryMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Amount</label>
                      <input
                        id="amount"
                        type="number"
                        name="amount"
                        value={form.amount}
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* {entityType === "vendor" && ( */}
                    <div className={classes.inputGroup}>
                      <label htmlFor="currency">Currency</label>
                      <select
                        id="currency"
                        name="currency"
                        value={form.currency}
                        onChange={handleInputChange}>
                        {Object.entries(currencies).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    {/* )} */}
                    <div className={classes.inputGroup}>
                      <label>Description</label>
                      <input
                        id="description"
                        type="text"
                        name="description"
                        value={form.description}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Tags</div>
                <div className={classes.tagscardBody}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="tags">Tag</label>
                    <input
                      id="tags"
                      type="text"
                      value={_.get(form, "tags.0", "")}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          tags: [
                            ...form.tags.map((tag, idx) =>
                              idx === 0 ? e.target.value : tag
                            ),
                          ],
                        });
                      }}
                    />
                  </div>
                  <div className={classes.tagsActions}>
                    {(_.get(form, "tags", []).length !== 1 ||
                      _.get(form, "tags.0", "")) !== "" && (
                      <CustomButton
                        id="removeTagButton"
                        appearance="ghost"
                        onClick={(e) => {
                          e.preventDefault();
                          if (_.get(form, "tags", []).length > 1) {
                            let newTags = form.tags;
                            newTags.splice(0, 1);
                            setForm({ ...form, tags: [...newTags] });
                            return;
                          }
                          setForm({ ...form, tags: [""] });
                        }}>
                        <strong>Remove</strong>
                      </CustomButton>
                    )}

                    {_.get(form, "tags", [""]).length === 1 && (
                      <CustomButton
                        id="addTagButton"
                        appearance="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({ ...form, tags: [...form.tags, ...[""]] });
                        }}>
                        <strong>Add Tag</strong>
                      </CustomButton>
                    )}
                  </div>
                </div>
              </div>
              {_.get(form, "tags").map(
                (tag, tagIdx) =>
                  tagIdx > 0 && (
                    <React.Fragment>
                      <div className={classes.card}>
                        <div className={classes.tagscardBody}>
                          <div className={classes.inputGroup}>
                            <label htmlFor="tags">Tag {tagIdx + 1}</label>
                            <input
                              id={`tags_${tagIdx}`}
                              type="text"
                              value={_.get(form, `tags.${tagIdx}`, "")}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  tags: [
                                    ...form.tags.map((tag, idx) =>
                                      idx === tagIdx ? e.target.value : tag
                                    ),
                                  ],
                                });
                              }}
                            />
                          </div>
                          <div className={classes.tagsActions}>
                            <CustomButton
                              id={`removeTagButton_${tagIdx}`}
                              appearance="ghost"
                              onClick={(e) => {
                                e.preventDefault();
                                if (_.get(form, "tags", []).length > 1) {
                                  let newTags = form.tags;
                                  newTags.splice(tagIdx, 1);
                                  setForm({ ...form, tags: [...newTags] });
                                  return;
                                }
                                setForm({ ...form, tags: [""] });
                              }}>
                              <strong>Remove</strong>
                            </CustomButton>
                            {_.get(form, "tags", []).length === tagIdx + 1 && (
                              <CustomButton
                                id={`addTagButton_${tagIdx}`}
                                appearance="primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setForm({
                                    ...form,
                                    tags: [...form.tags, ...[""]],
                                  });
                                }}>
                                <strong>Add Tag</strong>
                              </CustomButton>
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )
              )}
            </form>
          </div>
          <div className={classes.cardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="submit"
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();

                onSubmit({
                  entityData: entityData,
                  entryType: entryType,
                  formData: form,
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
AddDepositStatment.propTypes = {
  entityType: PropTypes.string,
  entityData: PropTypes.object.isRequired,
  invalidationKey: PropTypes.string.isRequired,
  entryType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default AddDepositStatment;
