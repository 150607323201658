import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  formInputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { createAccNewImage } from "../../../../../api/accommodator/Hotels/Hotels";
import { Button } from "../../../../../components/Button";
import Loader from "../../../../../components/Loader";

const styles = createUseStyles({
  ...modalGenericStyles,
  AddImagesModal: {
    ...modalGenericStyles.modal,
  },
  cardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
  },
  inputGroup: {
    ...formInputGroup,
    "& label, input": {
      justifySelf: "center",
      padding: variables.half_gap,
    },
  },
  form: {
    display: "grid",
  },
  addedImg: {
    justifySelf: "center",
    width: "10rem",
    height: "10rem",
    padding: variables.normal_gap,
  },
  [`@media ${variables.media.retina}`]: {
    card: {
      minWidth: "30rem",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    card: {
      minWidth: "30rem",
    },
  },
});
const AddImagesModal = ({ onClose, hotelUid }) => {
  const classes = styles();
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    if (image && allowedTypes.includes(image.type)) {
      setImage(image);
    } else {
      setImage(null);
      toast.error("Please select a JPEG file.");
    }
    const formData = new FormData();
    formData.append("image", image);
    setLoading(true);
    const results = await createAccNewImage({
      payload: formData,
      hotelUid: hotelUid,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (results) {
      toast.success("New Image(s) Added");
      onClose();
      return;
    }
    setLoading(false);
    return;
  };

  const handleImageSelection = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className={classes.AddImagesModal}>
      <div className={classes.card}>
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className={classes.cardHeader}>
              <span>Add Image</span>
            </div>
            <div className={classes.cardBody}>
              <form className={classes.form}>
                <div className={classes.inputGroup}>
                  <label>Select Image</label>
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/jpg"
                    multiple
                    onChange={handleImageSelection}
                  />
                  {image && (
                    <div className={classes.addedImg}>
                      <img
                        className={classes.addedImg}
                        src={URL.createObjectURL(image)}
                        alt={image.name}></img>
                    </div>
                  )}
                </div>
              </form>
            </div>
            <div className={classes.cardActions}>
              <Button text="Cancel" onClick={onClose} />
              <Button
                text="Upload"
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
AddImagesModal.propTypes = {
  hotelUid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default AddImagesModal;
