import _ from "lodash";
import axios from "axios";
import { genericRequestErrorHandler, SISI_API_URL } from "../..";

export async function fetchSubagentsAutocomplete({
  errorFunc = (error) => genericRequestErrorHandler(error),
  params,
}) {
  try {
    const result = await axios.get(
      `${SISI_API_URL}/crm/subagents/autocomplete/`,
      { params }
    );
    return _.get(result, "data", []);
  } catch (error) {
    errorFunc({ error });
    return null;
  }
}
