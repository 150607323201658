import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { variables } from "../../variables";

const styles = createUseStyles({
  TabStrip: {
    display: "grid",
    gap: "2px",
    height: "3.5rem",
    gridAutoFlow: "column",
    justifyContent: "start",
    padding: `0 calc(${variables.normal_gap} / 2)`,
    alignItems: "end",
    overflowX: "auto",
    background: variables.colors.baseLighter,
  },
  tabBtn: {
    padding: `calc(${variables.normal_gap} / 2)`,
    height: "2rem",
    borderRadius: "5px 5px 0 0",
    borderBottom: "white",
    cursor: "pointer",
    transition: "background .3s, color .3s",
    background: variables.colors.base,
    color: "white",
    "&:hover": {
      background: "white",
      color: variables.colors.base,
    },
  },
  selectedTabBtn: {
    extend: "tabBtn",
    // height: "2.5rem",
    background: "white",
    border: `1px solid ${variables.colors.base}`,
    color: variables.colors.base,
  },
  [`@media ${variables.media.smallscreen}`]: {
    tabBtn: {
      // padding: `calc(${variables.normal_gap} / 2)`,
      // height: "2rem",
    },
    selectedTabBtn: {
      // height: "2rem",
    },
  },
});
const TabStrip = ({ tabs, activeTab, setActiveTab }) => {
  const classes = styles();
  return (
    <div className={classes.TabStrip}>
      {tabs.map(([key, label], idx) => (
        <div
          key={idx}
          className={
            activeTab === key ? classes.selectedTabBtn : classes.tabBtn
          }
          onClick={() => setActiveTab(key)}>
          <strong>{label}</strong>
        </div>
      ))}
    </div>
  );
};
TabStrip.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};
export default TabStrip;
