import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { retrieveUser } from "../../../../../api/CRM/Users/Users";
import Loader from "../../../../../components/Loader";
import CRMGroupsTable from "../../../CommonCRMModals/CRMGroups";
import CRMTravelServiceTable from "../../../CommonCRMModals/CRMTravelServicesTable";
import UserPreferences from "../../components/UserPreferences";
import { DateTime } from "luxon";
import { CustomButton } from "../../../../../components/CustomButton";
import TabStrip from "../../../../../components/TabStrip";
import UsersLogsTab from "./UsersLogsTab";
import Permissions from "../../components/Permissions/list";

export const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
};

const departmentMapping = {
  "": "-----",
  AD: "Administration",
  FI: "Financial",
  MA: "Marketing",
  OP: "Operation",
  RE: "Reservation",
};

const trueFalseMapping = {
  true: "Yes",
  false: "No",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  DetailUserModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardBody: { ...cardStyles.body, display: "grid" },
  basicDataCardBody: {
    extend: "cardBody",
    padding: `calc(${variables.normal_gap} * 1.6) 0`,
  },
  firstRow: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: variables.normal_gap,
    justifyContent: "left",
  },
  secondRowCardBody: {
    extend: "cardBody",
    display: "grid",
    gridTemplateColumns: "1fr",
  },
  thirdRowCardBody: {
    extend: "cardBody",
    display: "grid",
    gridTemplateColumns: "1fr ",
  },
  secondRow: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: variables.normal_gap,
    justifyContent: "left",
  },
  basicInfoRightDetails: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
  },
  basicInfoRightDetailsRow: {
    display: "grid",
    width: "100%",
    justifySelf: "center",
    justifyItems: "center",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.double_gap,
    alignContent: "start",
  },
  [`@media ${variables.media.smallscreen}`]: {
    firstRow: {
      gridTemplateColumns: "2fr 3fr",
    },
    basicInfoRightDetails: {
      gridTemplateRows: "repeat(2, 1fr)",
    },
    basicInfoRightDetailsRow: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    secondRowCardBody: {
      gridTemplateColumns: "1fr",
    },
    thirdRowCardBody: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    firstRow: {
      gridTemplateColumns: "1fr 2fr",
    },
  },
});

const DetailUserModal = ({ id, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicData");

  const menuTabs = {
    basicData: "Basic Data",
    logs: "Logs",
  };

  const { data: retrievedData, isLoading } = useQuery({
    queryKey: ["retrieveUser", id],
    queryFn: () =>
      retrieveUser({
        id: id,
      }),
    onError: (error) => genericRequestErrorHandler(error),
  });

  const basicDataCpl = [
    ["Username", "username"],
    [
      "Department",
      null,
      _.get(departmentMapping, _.get(retrievedData, "data.department")),
    ],
    [
      "Is B2C",
      null,
      _.get(trueFalseMapping, _.get(retrievedData, "data.is_b2c")),
    ],
    [
      "Is Employee",
      null,
      _.get(trueFalseMapping, _.get(retrievedData, "data.is_employee")),
    ],
    ["Provider", "provider"],
    ["Subagent", "subagent"],
    ["Branch", "branch"],
    ["Independent Agent", "independentagent"],
    [
      "User Permissions",
      null,
      _.get(retrievedData, "data.user_permissions", []).join(", "),
    ],
    ["Vendor", "vendor"],
    ["Preferred Language", "preferred_language"],
    [
      "Completeness",
      null,
      `${
        _.get(retrievedData, "data.completeness") === 0
          ? "0"
          : _.get(retrievedData, "data.completeness") || "N/A"
      }%`,
    ],
    ["Status", null, _.get(statusMapping, _.get(retrievedData, "data.status"))],
  ];

  const creatorData = [
    [
      "Created",
      null,
      DateTime.fromISO(_.get(retrievedData, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    ["Creator", "creator"],
  ];
  const editData = [
    [
      "First Name",
      null,
      _.get(retrievedData, "data.last_editor_info.first_name", ""),
    ],
    [
      "Last Name",
      null,
      _.get(retrievedData, "data.last_editor_info.last_name", ""),
    ],
    ["Email", null, _.get(retrievedData, "data.last_editor_info.email", "")],
    ["Last Editor", "last_editor"],
  ];
  const userData = [
    ["First Name", null, _.get(retrievedData, "data.user.first_name", "")],
    ["Last Name", null, _.get(retrievedData, "data.user.last_name", "")],
    ["Email", null, _.get(retrievedData, "data.user.email", "")],
  ];

  const companyData = [
    ["Company Brand Name", "company_brand_name"],
    ["Company Legal Title", "company_legal_title"],
    ["Company Currency", "company_currency"],
    ["Company Type", "company_type"],
    ["Company Email", "company_email"],
  ];

  return (
    <div id="DetailUserModal" className={classes.DetailUserModal}>
      {isLoading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>User Details</span>
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>User Details</span>
          </div>
          <TabStrip
            tabs={Object.entries(menuTabs)}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <div className={classes.modalCardBody}>
            {activeTab === "basicData" && (
              <React.Fragment>
                <div className={classes.card}>
                  <div className={classes.firstRow}>
                    <div className={classes.basicDataCardBody}>
                      <GenericBasicInfo
                        header="Basic Data"
                        cardInfo={basicDataCpl}
                        objectData={_.get(retrievedData, "data", [])}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <div className={classes.basicInfoRightDetails}>
                        <div className={classes.basicInfoRightDetailsRow}>
                          <CRMTravelServiceTable
                            data={_.get(
                              retrievedData,
                              "data.travel_services_display",
                              []
                            )}
                          />
                          <CRMGroupsTable
                            data={_.get(
                              retrievedData,
                              "data.groups_display",
                              []
                            ).map((dt) => dt.name)}
                          />
                        </div>
                        <UserPreferences
                          entity={_.get(retrievedData, "data", [])}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.secondRowCardBody}>
                    <Permissions user={_.get(retrievedData, "data", [])} />
                  </div>
                </div>
                <div className={classes.thirdRowCardBody}>
                  <GenericBasicInfo
                    header="User Data"
                    cardInfo={userData}
                    objectData={_.get(retrievedData, "data", [])}
                  />
                  <GenericBasicInfo
                    header="Company Data"
                    cardInfo={companyData}
                    objectData={_.get(retrievedData, "data", [])}
                  />
                  <GenericBasicInfo
                    header="Creator Data"
                    cardInfo={creatorData}
                    objectData={_.get(retrievedData, "data", [])}
                  />
                  <GenericBasicInfo
                    header="Edit Data"
                    cardInfo={editData}
                    objectData={_.get(retrievedData, "data", [])}
                  />
                </div>
              </React.Fragment>
            )}
            {activeTab === "logs" && <UsersLogsTab />}
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
DetailUserModal.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DetailUserModal;
