import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  inputGroup,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import PropTypes from "prop-types";
import {
  createNewTransfer,
  patchTranfer,
  retrieveTransferAPIConfiguration,
} from "../../../../api/TransfersEngine/TransferAPIConfigurations";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import _ from "lodash";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import EntityAutocomplete from "../../../../components/EntityAutocomplete";
import {
  APIConfigsStatusMapping,
  convertStringToBoolean,
  currencies,
  handleValidationErrors,
} from "../../../../helpers";
import { genericRequestErrorHandler } from "../../../../api";
import { CustomButton } from "../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .transform((value) => (value === "" ? undefined : value))
    .min(2, "Must be at least 2 characters")
    .max(100, "Must xe less than 100 characters")
    .nullable()
    .default(""),
  supplier: yup
    .string()
    .nullable()
    .oneOf(
      ["hotelbeds", "intui", "hoppago"],
      "Must be between Hotelbeds, Intui or Hoppago"
    )
    .default(""),
  source_entity: yup.string().nullable().default(""),
  market_type: yup
    .string()
    .nullable()
    .oneOf(["B2B", "B2C"], "Must be between B2B or B2C")
    .default(""),
  currency: yup.string().nullable().default(""),
  is_live: yup.boolean().nullable().default(false),
  global_account: yup.boolean().nullable().default(false),
  is_master_account: yup.boolean().default(false),
  is_own: yup.boolean().nullable().default(false),
  website_api_key: yup.string().nullable().default(""),
  status: yup.string().nullable().default("AC"),
  username: yup.string().nullable().default(""),
  password: yup.string().nullable().default(""),
  application_key: yup.string().nullable().default(""),
  access_token: yup.string().nullable().default(""),
  api_url: yup
    .string()
    .url("Invalid URL format")
    .required("This field may not be blanc")
    .default(""),
  api_key: yup.string().nullable().default(""),
});

const supplierOptions = {
  "": "-----",
  hotelbeds: "Hotelbeds",
  intui: "Intui",
  hoppago: "Hoppago",
};
const trueFalseMapping = {
  "": "----",
  false: "No",
  true: "Yes",
};
const marketTypeOptions = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditTransferAPIConfigurationsModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: { ...cardStyles.body },
  inputGroup: {
    ...formInputGroup,
    padding: `${variables.normal_gap} 0rem `,
  },
  entityAutoComplete: {
    ...inputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardBody: {
      minWidth: "50rem",
    },
    cardCol2: {
      gridTemplateColumns: "repeat(2 , 1fr)",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3 , 1fr)",
    },
  },
});

const EditTransferAPIConfigurationsModal = ({
  ApiConfigurationId,
  onClose,
}) => {
  const classes = styles();
  const mode = ApiConfigurationId ? "edit" : "add";

  const [form, setForm] = useState(jsonSchema.cast({}));
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["EditTransferAPIConfigurationsModal"],
    queryFn: () =>
      retrieveTransferAPIConfiguration({
        uid: ApiConfigurationId,
      }),
    enabled: mode === "edit",
    onSuccess: (data) => {
      setForm(jsonSchema.cast(_.get(data, "data", {})));
      setAutocompleteValue(_.get(data, "data.source_entity", ""));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const editMutation = useMutation({
    mutationFn: async ({ uid, payload }) => {
      return patchTranfer({
        uid,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("Transfer Info Updated");
      queryClient.invalidateQueries("TransfersAPIConfigurations");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });
  const addMutation = useMutation({
    mutationFn: async ({ payload }) => {
      return createNewTransfer({ payload });
    },
    onSuccess: () => {
      toast.success("Transfer Info added");
      queryClient.invalidateQueries("TransfersAPIConfigurations");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ uid, payload }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload });
      } else {
        editMutation.mutate({ uid, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const boolFields = convertStringToBoolean({
    is_master_account: form.is_master_account,
    is_live: form.is_live,
    global_account: form.global_account,
    is_own: form.is_own,
  });

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  return (
    <div
      id="addEditTRFEngineAPIConfigurationModal"
      className={classes.EditTransferAPIConfigurationsModal}>
      <div className={classes.modalCard}>
        {loading && <Loader onTop={true} />}
        <div className={classes.modalCardHeader}>
          <span>{_.startCase(mode)} Transfer API Configuration</span>
        </div>
        <div className={classes.modalCardBody}>
          <form
            onChange={(e) => {
              setForm((p) => ({
                ...p,
                [e.target.name]: e.target.value,
              }));
            }}>
            <div className={classes.card}>
              <div className={classes.cardHeader}>Basic Data</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="name">Name</label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      value={_.capitalize(form.name)}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="supplier">Supplier</label>
                    <select
                      id="supplier"
                      name="supplier"
                      value={form.supplier}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(supplierOptions).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className={classes.cardCol3}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="source_entity">Source Entity</label>
                    <div className={classes.entityAutoComplete}>
                      <EntityAutocomplete
                        id="entityAutocompleteInputId"
                        subagents={false}
                        independentAgents={false}
                        branches={false}
                        value={autocompleteValue}
                        setValue={setAutocompleteValue}
                        onChange={(text) => {
                          if (text.length === 0) {
                            setForm((p) => ({
                              ...p,
                              source_entity: "",
                            }));
                            return;
                          }
                          const entityInfos = text.split("---");
                          const entityType = entityInfos[0];
                          const entityId = entityInfos[1];
                          setForm((p) => ({
                            ...p,
                            source_entity: `${entityId}___${entityType}`,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="market_type">Market Type</label>
                    <select
                      id="market_type"
                      name="market_type"
                      value={form.market_type}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(marketTypeOptions).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="currency">Currency</label>
                    <select
                      id="currency"
                      name="currency"
                      value={form.currency}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(currencies).map(([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="is_live">Is Live</label>
                    <select
                      id="is_live"
                      name="is_live"
                      value={form.is_live}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(trueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="global_account">Is Global</label>
                    <select
                      id="global_account"
                      name="global_account"
                      value={form.global_account}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(trueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="is_own">Is Own</label>
                    <select
                      id="is_own"
                      name="is_own"
                      value={form.is_own}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(trueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="is_master_account">Is Master Account</label>
                    <select
                      id="is_master_account"
                      name="is_master_account"
                      value={form.is_master_account}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(trueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label>Website Api Key</label>
                    <input
                      id="website_api_key"
                      type="text"
                      name="website_api_key"
                      value={form.website_api_key}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="status">Status</label>
                    <select
                      id="status"
                      name="status"
                      value={form.status}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(APIConfigsStatusMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.cardHeader}>API Credentials</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label>Username</label>
                    <input
                      id="username"
                      type="text"
                      name="username"
                      value={form.username}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Password</label>
                    <input
                      id="password"
                      type="text"
                      name="password"
                      value={form.password}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="application_key">Application Key</label>
                    <input
                      id="application_key"
                      type="text"
                      name="application_key"
                      value={form.application_key}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="access_token">Access Token</label>
                    <input
                      id="access_token"
                      type="text"
                      name="access_token"
                      value={form.access_token}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="api_url">Api URL</label>
                    <input
                      id="api_url"
                      type="text"
                      name="api_url"
                      value={form.api_url}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="api_key">Api Key</label>
                    <input
                      id="api_key"
                      type="text"
                      name="api_key"
                      value={form.api_key}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className={classes.cardActions}>
          <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            id="submit"
            appearance="primary"
            onClick={(e) => {
              e.preventDefault();
              onSubmit({
                uid: ApiConfigurationId,
                payload: { ...form, ...boolFields },
              });
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
EditTransferAPIConfigurationsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditTransferAPIConfigurationsModal;
