import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  variables,
  modalGenericStyles,
  formInputGroup,
} from "../../../../../../variables";
import { Button } from "../../../../../../components/Button";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import _ from "lodash";
import Loader from "../../../../../../components/Loader";
import { createDefaultEmailConfigs } from "../../../../../../api/CRS/EmailConfiguration";
import { genericRequestErrorHandler } from "../../../../../../api";
import { CustomButton } from "../../../../../../components/CustomButton";

const schema = Yup.object().shape({
  website_api_key: Yup.string().nullable(),
  sender_email: Yup.string().email().nullable(),
  source_entity: Yup.string().required("Source Entity is required."),
});

const styles = createUseStyles({
  ...modalGenericStyles,
  FixConfigsModal: {
    ...modalGenericStyles.modal,
  },
  mainCard: {
    ...modalGenericStyles.card,
    width: "50rem",
  },
  mainCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  mainCardBody: {
    ...modalGenericStyles.card,
    display: "grid",
    maxHeight: "30rem",
    gap: variables.normal_gap,
    overflow: "auto",
    justifyItems: "center",
  },
  mainCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardBody: {
    ...cardStyles.body,
    display: "grid",
    gap: variables.normal_gap,
  },
  inputGroup: formInputGroup,
  form: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: `${variables.half_gap} ${variables.normal_gap}`,
    padding: variables.half_gap,
  },
});
const FixConfigsModal = ({
  website_api_keys,
  sender_email,
  source_entity,
  onClose,
}) => {
  const classes = styles();
  const [form, setForm] = useState({ website_api_keys, sender_email });

  const mutation = useMutation({
    mutationFn: (values) => {
      return createDefaultEmailConfigs({ payload: values });
    },
    onError: (err) => {
      genericRequestErrorHandler(err);
    },
    onSuccess: (data, variables) => {
      toast.success(
        _.get(
          data,
          "data.message",
          "Successfully created missing Email Configs."
        )
      );
      // if the fix is for the default configs, then we need to close the modal
      if (!variables.website_api_key) {
        onClose();
      }
    },
  });

  const checkAndMutate = async ({ payload, mutateFn }) => {
    try {
      await schema.validate(payload);
      return mutateFn(payload);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        if (!validationErrors[err.path]) {
          validationErrors[err.path] = err.message;
          toast.error(err.message);
        }
      });
      return false;
    }
  };

  const onSubmit = (values) => {
    const websites = _.get(values, "website_api_keys", []);
    if (websites.length === 0) {
      checkAndMutate({
        payload: {
          sender_email: values.sender_email,
          source_entity,
        },
        mutateFn: mutation.mutate,
      });
    } else {
      var successFullyMutations = [];
      websites.forEach((website_api_key) => {
        const result = checkAndMutate({
          payload: {
            website_api_key,
            sender_email: values.sender_email,
            source_entity,
          },
          mutateFn: mutation.mutateAsync,
        });
        successFullyMutations.push(result.isSuccess);
      });
      if (!successFullyMutations.includes(false)) {
        onClose();
      }
    }
  };

  return (
    <div className={classes.FixConfigsModal}>
      <div className={classes.mainCard}>
        <div className={classes.mainCardHeader}>
          <span>
            {!_.isEmpty(website_api_keys)
              ? `Fix the warnings for the websites`
              : "Fix the default warnings."}
          </span>
        </div>
        <div className={classes.mainCardBody}>
          {mutation.isLoading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <form
              onChange={(e) => {
                setForm((p) => ({
                  ...p,
                  [e.target.name]: e.target.value,
                }));
              }}>
              <div className={classes.card}>
                <div className={classes.cardBody}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="name">Sender</label>
                    <input
                      type="text"
                      name="sender_email"
                      value={form.sender_email}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
        <div className={classes.mainCardActions}>
          <CustomButton appearance="ghost" onClick={onClose} />
          <CustomButton
            appearance="primary"
            onClick={() =>
              onSubmit({ website_api_keys, sender_email: form.sender_email })
            }
          />
          {/* <Button text="Close" onClick={onClose} />
          <Button
            text="Submit"
            onClick={() =>
              onSubmit({ website_api_keys, sender_email: form.sender_email })
            } */}
          {/* /> */}
        </div>
      </div>
    </div>
  );
};

FixConfigsModal.propTypes = {
  website_api_keys: PropTypes.array,
  sender_email: PropTypes.string,
  source_entity: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default FixConfigsModal;
