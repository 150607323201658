import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { retrieveSubAgent } from "../../../../../api/CRM/SubAgents/SubAgents";
import {
  cardStyles,
  modalGenericStyles,
  tableStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { AddressesCard } from "../../../../../components/cards";
import _ from "lodash";
import { ContactsCard } from "../../../../../components/cards/contacts";
import { DateTime } from "luxon";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import EntityPreferences from "../../../../../components/Entity/entity_preferences";
import { CommentsCard } from "../../../../../components/cards/comments";
import { DocumentsCard } from "../../../CommonCRMModals/Documents";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import Loader from "../../../../../components/Loader";
import CRMTravelServicesTable from "../../../CommonCRMModals/CRMTravelServicesTable";
import CRMGroupsTable from "../../../CommonCRMModals/CRMGroups";
import TabStrip from "../../../../../components/TabStrip";
import { CustomButton } from "../../../../../components/CustomButton";
import SubAgentLogsTab from "./SubAgentLogsTab";
import SubagentBuyingStatments from "../../SabagentBuyingStatments";
import SubagentProformaBuyingStatment from "../../SubagentProformaBuyingStatment/List";

const subAgentBasicInformation = createUseStyles({
  ...cardStyles,
  SubAgentBasicInfo: cardStyles.card,
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
});

const SubAgentBasicInformation = ({ entity }) => {
  const classes = subAgentBasicInformation();
  return (
    <div className={classes.SubAgentBasicInfo}>
      <div className={classes.header}>
        <span>Basic Information</span>
      </div>
      <div className={classes.body}>
        <table>
          <thead>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>Id</th>
              <th className={classes.headCell}>Name</th>
              <th className={classes.headCell}>Email</th>
              <th className={classes.headCell}>Mobile Phone</th>
              <th className={classes.headCell}>Currency</th>
              <th className={classes.headCell}>Created</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.tableCell}>{entity.id || "N/A"}</td>
              <td className={classes.tableCell}>{entity.name || "N/A"}</td>
              <td className={classes.tableCell}>{entity.email}</td>
              <td className={classes.tableCell}>
                {entity.mobile_phone || "N/A"}
              </td>
              <td className={classes.tableCell}>{entity.currency || "N/A"}</td>
              <td className={classes.tableCell}>
                {entity.created
                  ? DateTime.fromISO(entity.created).toLocaleString(
                      DateTime.DATETIME_MED_WITH_WEEKDAY
                    )
                  : "N/A"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
SubAgentBasicInformation.propTypes = {
  entity: PropTypes.object.isRequired,
};

const b2cVivaCredentialsType = {
  "": "-----",
  LV: "Live",
  DE: "Demo",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailsSubAgentModal: {
    ...modalGenericStyles.modal,
    overflow: "auto",
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: { ...cardStyles.card, maxWidth: "100%" },
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    maxWidth: "100%",
    overflow: "hidden",
  },
  tabsContainer: {
    extend: "cardBody",
    overflow: "auto",
  },
  firstRow: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: variables.normal_gap,
    justifyContent: "left",
  },
  basicInfoCardBody: {
    extend: "cardBody",
    overflow: "auto",
  },
  basicInfoRightDetailsRowCardBody: {
    ...cardStyles.body,
    display: "grid",
    width: "100%",
    justifySelf: "center",
    justifyItems: "center",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.double_gap,
    alignContent: "start",
  },
  seconRowcardBody: {
    extend: "cardBody",
    maxHeight: "15rem",
  },
  [`@media ${variables.media.smallscreen}`]: {
    firstRow: {
      gridTemplateColumns: "1fr 1fr",
    },
    basicInfoRightDetailsRowCardBody: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    firstRow: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
});
const DetailsSubAgentModal = ({ subAgentId, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicInfo");

  const { data: subAgent, isLoading } = useQuery({
    queryKey: ["DetailsSubAgentModal", subAgentId],
    queryFn: async () =>
      await retrieveSubAgent({
        id: subAgentId,
      }),
    onError: (error) => genericRequestErrorHandler(error),
  });

  const getWorkingDays = (weekdays) => {
    let workingDays = [];
    weekdays ||
      [].forEach((weekDay, idx) => {
        let day = "";
        switch (idx) {
          case 0:
            if (weekdays[idx]) {
              day = "Monday";
            }
            break;
          case 1:
            if (weekdays[idx]) {
              day = "Tuesday ";
            }
            break;
          case 2:
            if (weekdays[idx]) {
              day = "Wednesday";
            }
            break;
          case 3:
            if (weekdays[idx]) {
              day = "Thursday";
            }
            break;
          case 4:
            if (weekdays[idx]) {
              day = "Friday";
            }
            break;
          case 5:
            if (weekdays[idx]) {
              day = "Saturday";
            }
            break;
          case 6:
            if (weekdays[idx]) {
              day = "Sunday";
            }
            break;
          default:
            day = "";
            break;
        }
        if (day) {
          workingDays.push(day);
        }
      });
    return workingDays;
  };

  const basicDataCpl = [
    ["Business Name", null, _.capitalize(_.get(subAgent, "data.name"))],
    ["Member", "member"],
    ["Registration Code", "registration_code"],
    [
      "Approved",
      null,
      _.get(subAgent, "data.approved") === true ? "Yes" : "No",
    ],
    ["Founding Year", "founding_year"],
    ["Contact Email", "contact_persons.0.email"],
    ["Mobile Phone", "mobile_phone"],
    ["Phone", "phone"],
    ["B2C Viva Credentials Type", "b2c_viva_credentials_type"],
    ["Completeness", "completeness"],
    ["Email", "email"],
    ["Url", "url"],
    ["Currency", "currency"],
    [
      "Created",
      null,
      DateTime.fromISO(_.get(subAgent, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];

  const PaymentGatewayCredentialsCpls = [
    ["Felloh Organisation", "felloh_organisation"],
    [
      "Felloh environment",
      null,
      _.startCase(_.get(subAgent, "data.felloh_environment")),
    ],
    ["Felloh Public Key", "felloh_public_key"],
    ["Felloh Secret Key", "felloh_secret_key"],
    ["B2C Stripe Accepted Coupons", "b2c_stripe_accepted_coupons"],
    ["b2c Stripe API Public Key", "b2c_stripe_api_public_key"],
    ["B2C Stripe API Secret Key", "b2c_stripe_api_secret_key"],
    ["B2C Viva API Key", "b2c_viva_api_key"],
    [
      "B2C Viva Credentials Type",
      null,
      _.get(
        b2cVivaCredentialsType,
        _.get(subAgent, "data.b2c_viva_credentials_type", "") || "N/A"
      ),
    ],
    ["B2C Viva Merchant Id", "b2c_viva_source_code"],
    ["Barclays Passphrase", "barclays_passphrase"],
    ["Barclays Passphrase", "barclays_passphrase"],
    ["Barclays Pspid", "barclays_pspid"],
  ];

  const basicCpl = [
    ["Timezone", "timezone"],
    [
      "Days",
      null,
      getWorkingDays(_.get(subAgent, "data.weekdays", [])).join(", "),
    ],
  ];

  const menuTabs = {
    basicInfo: "Basic Info",
    comments: "Comments",
    buyingStatment: "Buying Statment",
    proformaBuyingStatment: "Proforma Buying Statment",
    // creditStatment: "Credit Statment",
    logs: "Logs",
    // documents: "Documents",
  };

  console.log("subagent", subAgent);

  return (
    <div id="DetailSubagentModal" className={classes.DetailsSubAgentModal}>
      {isLoading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>Subagent Details</span>
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>Subagent Details</span>
          </div>
          <TabStrip
            tabs={Object.entries(menuTabs)}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <div className={classes.modalCardBody}>
            {activeTab === "basicInfo" && (
              <React.Fragment>
                <div className={classes.card}>
                  <div className={classes.firstRow}>
                    <div className={classes.basicInfoCardBody}>
                      <GenericBasicInfo
                        header="Basic Data"
                        cardInfo={basicDataCpl}
                        objectData={_.get(subAgent, "data", [])}
                      />
                    </div>

                    <div className={classes.basicInfoCardBody}>
                      <GenericBasicInfo
                        header="Payment Gateway Credentials"
                        cardInfo={PaymentGatewayCredentialsCpls}
                        objectData={_.get(subAgent, "data", [])}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.basicInfoRightDetailsRowCardBody}>
                    <div className={classes.seconRowcardBody}>
                      <GenericBasicInfo
                        header="Profile Operation Time"
                        cardInfo={basicCpl}
                        objectData={_.get(subAgent, "data", [])}
                        custonStyles={{ header: { width: "100%" } }}
                      />
                    </div>
                    <div className={classes.seconRowcardBody}>
                      <CRMTravelServicesTable
                        data={_.get(
                          subAgent,
                          "data.travel_services_display",
                          []
                        )}
                      />
                    </div>
                    <div className={classes.seconRowcardBody}>
                      <CRMGroupsTable
                        data={_.get(subAgent, "data.groups_display", [])}
                      />
                    </div>
                  </div>
                </div>
                <EntityPreferences entity={_.get(subAgent, "data", [])} />
                <AddressesCard
                  addresses={_.get(subAgent, "data.addresses", [])}
                />
                <ContactsCard
                  contacts={_.get(subAgent, "data.contact_persons", [])}
                />
              </React.Fragment>
            )}
            {activeTab === "comments" && (
              <CommentsCard
                title="Comments"
                id={subAgentId}
                entityType="subagent"
              />
            )}
            {activeTab === "proformaBuyingStatment" && (
              <SubagentProformaBuyingStatment
                entityData={_.get(subAgent, "data", {})}
              />
            )}
            {/* {activeTab === "creditStatment" && (
              <DepositStatments
                invalidationKey="DetailsSubAgentModal"
                entityType="subagent"
                entityData={_.get(subAgent, "data", {})}
              />
            )} */}
            {activeTab === "buyingStatment" && (
              <SubagentBuyingStatments
                entityType="subagent"
                entityData={_.get(subAgent, "data", {})}
              />
            )}
            {activeTab === "documents" && (
              <DocumentsCard
                title="Documents"
                id={subAgentId}
                showAddButton={true}
                sourceEntity={`${subAgentId}___subagent`}
              />
            )}
            {activeTab === "logs" && <SubAgentLogsTab />}
          </div>

          <div className={classes.cardActions}>
            <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
DetailsSubAgentModal.propTypes = {
  onclose: PropTypes.func.isRequired,
};
export default DetailsSubAgentModal;
