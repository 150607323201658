import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import _ from "lodash";
import { variables } from "../../../../variables";
import { countries } from "../../../../helpers";

const navbarStyles = createUseStyles({
  Navbar: {
    background: variables.colors.background.secondary.dark,
    display: "grid",
    justifyContent: "center",
    gridTemplateColumns: "1fr",
    padding: variables.half_gap,
  },
  navBarMetadata: {
    display: "grid",
    gridAutoFlow: "row",
    gridGap: variables.half_gap,
    gridAutoColumns: "max-content",
    alignItems: "end",
    height: "4.4rem",
    justifyContent: "start",
    padding: variables.half_gap,
  },
  inputGroup: {
    display: "grid",
    "& label": {
      padding: variables.half_gap,
    },
  },
  actions: {
    display: "grid",
    gridColumn: "2 span",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
    alignItems: "end",
    justifySelf: "end",
  },
  divider: {
    display: "none",
    width: "0%",
    height: "100%",
    border: `1px solid ${variables.colors.border.dark}`,
  },
  info: { display: "grid", justifyItems: "start", fontSize: "12px" },
  [`@media ${variables.media.smallscreen}`]: {
    Navbar: {
      gridTemplateColumns: "1fr auto auto",
      textAlign: "center",
      padding: variables.half_gap,
    },
    navBarMetadata: {
      display: "grid",
      gridTemplateColumns: "1fr max-content max-content",
      gridGap: variables.half_gap,
      alignItems: "end",
      height: "5.5rem",
      justifyContent: "start",
    },
    actions: {
      display: "grid",
      gridColumn: "1 span",
      gridAutoFlow: "column",
      gridGap: variables.half_gap,
      alignItems: "end",
    },
    info: { display: "grid", justifyItems: "start", fontSize: "16px" },
  },
});
const Navbar = ({ hotelData, updateImages, onGo, onAdd }) => {
  const classes = navbarStyles();
  return (
    <div className={classes.Navbar}>
      <div className={classes.navBarMetadata}>
        <div className={classes.info}>
          <strong>Images for</strong>
          <span>
            <strong>Uid:</strong> {_.get(hotelData, "uid", "") || "N/A"}
          </span>
          <strong>Name: {_.get(hotelData, "name", "") || "N/A"}</strong>
          <strong>Address: {_.get(hotelData, "address", "") || "N/A"}</strong>
          <strong>
            Country Code:{" "}
            {_.get(countries, _.get(hotelData, "country_code")) || "N/A"}
          </strong>
        </div>
        <div className={classes.divider} />
        <div className={classes.inputGroup}>
          <div>
            <button onClick={onAdd}>Upload Images</button>
          </div>
        </div>
        <div className={classes.inputGroup}>
          <div>
            <button onClick={() => updateImages()}>Update Images</button>
          </div>
        </div>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  hotelData: PropTypes.object.isRequired,
  updateImages: PropTypes.func.isRequired,
  onGo: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};
export default Navbar;
