import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  formSections,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import { hasSearchFormMapping, statusMapping } from "../../common";
import Loader from "../../../../../components/Loader";
import {
  createWebSiteLandingPage,
  patchWebSiteLandingPage,
  retrieveWebSiteLandingPage,
} from "../../../../../api/DistributionSite/webSites/LandingPages";
import _ from "lodash";
import { DateTime } from "luxon";
import QuilEditor from "../../../../../components/QuilEditor";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import * as yup from "yup";
import {
  convertStringToBoolean,
  handleValidationErrors,
  websiteLanguages,
} from "../../../../../helpers";
import WebsitesLanguages from "../../Components/WebsitesLanguages";
import TranslateButton from "../../Components/TranslateButton";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  name: yup.string().nullable().required("This field is required").default(""),
  title: yup.string().nullable().required("This field is required").default(""),
  title_de: yup.string().nullable().default(""),
  title_el: yup.string().nullable().default(""),
  title_es: yup.string().nullable().default(""),
  title_fr: yup.string().nullable().default(""),
  title_it: yup.string().nullable().default(""),
  title_ja: yup.string().nullable().default(""),
  title_nl: yup.string().nullable().default(""),
  title_pt: yup.string().nullable().default(""),
  title_th: yup.string().nullable().default(""),
  title_zh: yup.string().nullable().default(""),
  title_lt: yup.string().nullable().default(""),
  subtitle: yup.string().nullable().default(""),
  subtitle_de: yup.string().nullable().default(""),
  subtitle_el: yup.string().nullable().default(""),
  subtitle_es: yup.string().nullable().default(""),
  subtitle_fr: yup.string().nullable().default(""),
  subtitle_it: yup.string().nullable().default(""),
  subtitle_ja: yup.string().nullable().default(""),
  subtitle_nl: yup.string().nullable().default(""),
  subtitle_pt: yup.string().nullable().default(""),
  subtitle_th: yup.string().nullable().default(""),
  subtitle_zh: yup.string().nullable().default(""),
  description: yup.string().nullable().default(""),
  subtitle_lt: yup.string().nullable().default(""),
  description_de: yup.string().nullable().default(""),
  description_el: yup.string().nullable().default(""),
  description_es: yup.string().nullable().default(""),
  description_fr: yup.string().nullable().default(""),
  description_it: yup.string().nullable().default(""),
  description_ja: yup.string().nullable().default(""),
  description_nl: yup.string().nullable().default(""),
  description_pt: yup.string().nullable().default(""),
  description_th: yup.string().nullable().default(""),
  description_zh: yup.string().nullable().default(""),
  description_lt: yup.string().nullable().default(""),
  // has_search_form: yup
  //   .mixed()
  //   .oneOf(["true", "false"], "Must be between Yes or No")
  //   .default(""),
  status: yup
    .mixed()
    .oneOf(["AC", "IN", "CA"], "Must be between Active, Inactive or Candidate"),
  extra_titles: yup.array().of(yup.string()).default([]),
  extra_subtitles: yup.array().of(yup.string()).default([]),
});

const initialForm = {
  name: "",
  status: "",
  description: "",
  description_de: "",
  description_el: "",
  description_es: "",
  description_fr: "",
  description_it: "",
  description_ja: "",
  description_nl: "",
  description_pt: "",
  description_th: "",
  description_zh: "",
  description_lt: "",
  title: "",
  title_de: "",
  title_el: "",
  title_es: "",
  title_fr: "",
  title_it: "",
  title_ja: "",
  title_nl: "",
  title_pt: "",
  title_th: "",
  title_zh: "",
  title_lt: "",
  subtitle: "",
  subtitle_de: "",
  subtitle_el: "",
  subtitle_es: "",
  subtitle_fr: "",
  subtitle_it: "",
  subtitle_ja: "",
  subtitle_nl: "",
  subtitle_pt: "",
  subtitle_th: "",
  subtitle_zh: "",
  subtitle_lt: "",
  website: "",
  active_from: null,
  active_to: null,
  has_search_form: false,
  extra_subtitles: [],
  extra_titles: [],
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditLandingPages: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    gridTemplateRows: "max-content 1fr max-content",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  languages: {
    display: "grid",
    padding: variables.double_gap,
    overflow: "auto",
  },
  loader: { width: "100vw" },
  descriptionCardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    display: "grid",
    padding: "",
    paddingBottom: variables.normal_gap,
  },
  description: {
    // display: "grid",
    gap: variables.normal_gap,
    "& label": {
      fontSize: "small",
    },
  },
  languagesButton: {
    padding: variables.normal_gap,
    gap: variables.normal_gap,
    borderRadius: "5px",
    cursor: "pointer",
    fontWeight: "bold",
    transition: "background .s, color .3s",
    "&:hover": {
      background: variables.colors.background.darkHover,
      color: variables.colors.text.light,
    },
  },
  translateButton: {
    padding: variables.normal_gap,
    alignSelf: "center",
  },
  formSectionHeader: formSections.sectionHeader,
  inputGroup: { ...formInputGroup, paddingTop: variables.normal_gap },
  cardActions: { ...cardStyles.actions, gridColumn: "2 span" },

  [`@media ${variables.media.smallscreen}`]: {
    inputGroup: formInputGroup,
    languages: {
      gridTemplateColumns: "1fr max-content",
      gap: variables.normal_gap,
    },
    cardCol2: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: variables.normal_gap,
    },
    cardCol3: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      gap: variables.normal_gap,
    },
  },
});

const EditLandingPages = ({ websiteId, id, onClose }) => {
  const classes = styles();

  const mode = id ? "edit" : "add";

  const [form, setForm] = useState(initialForm);
  const [activeLanguage, setActiveLanguage] = useState("en");

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["RetrieveContactInfo", id],
    queryFn: () => retrieveWebSiteLandingPage({ id: id }),

    onSuccess: (data) => {
      setForm(_.get(data, "data", {}));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    enabled: mode === "edit",
  });

  const editMutation = useMutation({
    mutationFn: ({ payload, id }) => {
      return patchWebSiteLandingPage({
        id,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("LAnding Page Info Updated");
      queryClient.invalidateQueries("LandingPagesList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const addMutation = useMutation({
    mutationFn: ({ payload, website }) => {
      return createWebSiteLandingPage({
        payload: {
          ...payload,
          website,
        },
      });
    },
    onSuccess: () => {
      toast.success("New Landing Page Added");
      queryClient.invalidateQueries("LandingPagesList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ id, payload, website }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload, website });
      } else {
        editMutation.mutate({ id, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const boolFields = convertStringToBoolean({
    has_search_form: form.has_search_form,
  });

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;
  return (
    <div className={classes.EditLandingPages}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            {_.startCase(mode)} Landing Pages
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            {_.startCase(mode)} Landing Pages
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.languages}>
              <div className={classes.languagesButtons}>
                <WebsitesLanguages
                  activeLanguage={activeLanguage}
                  setActiveLanguage={setActiveLanguage}
                  languages={websiteLanguages}
                />
              </div>
              <div className={classes.translateButton}>
                <TranslateButton
                  targetLang={activeLanguage}
                  form={form}
                  setForm={setForm}
                />
              </div>
            </div>
            <form
              onChange={(e) =>
                setForm((p) => ({
                  ...p,
                  [e.target.name]: e.target.value,
                }))
              }>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="title">Title</label>
                    {Object.entries(websiteLanguages).map(([key, label], idx) =>
                      key === activeLanguage ? (
                        <input
                          key={key}
                          value={
                            key === "en" ? form.title : form[`title_${key}`]
                          }
                          onChange={(e) => {
                            let newForm = {
                              ...form,
                              [key === "en" ? "title" : `title_${key}`]:
                                e.target.value,
                            };
                            setForm(newForm);
                          }}
                        />
                      ) : null
                    )}
                  </div>

                  {form.extra_titles.map((title, idx) => {
                    return (
                      <div className={classes.inputGroup}>
                        <label htmlFor="extra_titles">Title {idx + 1}</label>
                        <input
                          type="text"
                          onChange={(e) => {
                            let newExtraTitles = [...form.extra_titles];
                            newExtraTitles[idx] = e.target.value;
                            setForm({
                              ...form,
                              extra_titles: newExtraTitles,
                            });
                          }}
                          defaultValue={form.extra_titles[idx]}
                        />
                      </div>
                    );
                  })}
                  <div className={classes.modalCardActions}>
                    <CustomButton
                      appearance="ghost"
                      onClick={(e) => {
                        e.preventDefault();
                        setForm({
                          ...form,
                          extra_titles: [...form.extra_titles, ""],
                        });
                      }}>
                      <strong>Add Extra Title</strong>
                    </CustomButton>
                    {form.extra_titles.length > 0 && (
                      <CustomButton
                        appearance="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({
                            ...form,
                            extra_titles: [
                              ...form.extra_titles.filter(
                                (xT, index) =>
                                  form.extra_titles.length - 1 !== index
                              ),
                            ],
                          });
                        }}>
                        <strong>Remove Title</strong>
                      </CustomButton>
                    )}
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="subtitle">Subtitle</label>
                    {Object.entries(websiteLanguages).map(([key, label], idx) =>
                      key === activeLanguage ? (
                        <input
                          key={key}
                          value={
                            key === "en"
                              ? form.subtitle
                              : form[`subtitle_${key}`]
                          }
                          onChange={(e) => {
                            let newForm = {
                              ...form,
                              [key === "en" ? "subtitle" : `subtitle_${key}`]:
                                e.target.value,
                            };
                            setForm(newForm);
                          }}
                        />
                      ) : null
                    )}
                  </div>
                  {form.extra_subtitles.map((subtitle, idx) => {
                    return (
                      <div className={classes.inputGroup}>
                        <label htmlFor="extra_subtitles">
                          Subtitle {idx + 1}
                        </label>
                        <input
                          type="text"
                          onChange={(e) => {
                            let newExtraSubtitles = [...form.extra_subtitles];
                            newExtraSubtitles[idx] = e.target.value;
                            setForm({
                              ...form,
                              extra_subtitles: newExtraSubtitles,
                            });
                          }}
                          defaultValue={form.extra_subtitles[idx]}
                        />
                      </div>
                    );
                  })}
                  <div className={classes.modalCardActions}>
                    <CustomButton
                      appearance="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        setForm({
                          ...form,
                          extra_subtitles: [...form.extra_subtitles, ""],
                        });
                      }}>
                      <strong>Add Extra Subtitle</strong>
                    </CustomButton>
                    {form.extra_subtitles.length > 0 && (
                      <CustomButton
                        appearance="ghost"
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({
                            ...form,
                            extra_subtitles: [
                              ...form.extra_subtitles.filter(
                                (xT, index) =>
                                  form.extra_subtitles.length - 1 !== index
                              ),
                            ],
                          });
                        }}>
                        <strong>Remove Subtitle</strong>
                      </CustomButton>
                    )}
                  </div>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="name">Name</label>
                      <input type="text" name="name" defaultValue={form.name} />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="has_search_form">Search Form</label>
                      <select
                        name="has_search_form"
                        value={form.has_search_form}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(hasSearchFormMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  <div className={classes.card}>
                    <div className={classes.descriptionCardBody}>
                      <div className={classes.description}>
                        <label htmlFor="description">Description</label>
                        {Object.entries(websiteLanguages).map(
                          ([key, label], idx) =>
                            key === activeLanguage ? (
                              <QuilEditor
                                key={key}
                                value={
                                  key === "en"
                                    ? form.description
                                    : form[`description_${key}`]
                                }
                                onChange={(value) => {
                                  let newForm = {
                                    ...form,
                                    [key === "en"
                                      ? "description"
                                      : `description_${key}`]: value,
                                  };
                                  setForm(newForm);
                                }}
                              />
                            ) : null
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Active Data</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="active_from">Active Data</label>
                      <input
                        type="date"
                        name="active_from"
                        value={
                          form.active_from
                            ? DateTime.fromISO(form.active_from).toISODate()
                            : "N/A"
                        }
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="active_to">Active To</label>
                      <input
                        type="date"
                        name="active_to"
                        value={
                          form.active_to
                            ? DateTime.fromISO(form.active_to).toISODate()
                            : "N/A"
                        }
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        value={form.status}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(statusMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  id: id,
                  payload: { ...form, ...boolFields },
                  website: websiteId,
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditLandingPages.propTypes = {
  id: PropTypes.number,
  websiteId: PropTypes.number.isRequired,
};
export default EditLandingPages;
