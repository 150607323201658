import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../components/cards/GenericBasicInfo";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import { DateTime } from "luxon";
import { transferEngineTrueFalseMapping } from "../../common.js";
import { genericRequestErrorHandler } from "../../../../api/index.js";
import { useQuery } from "@tanstack/react-query";
import { retrieveTransferAPIConfiguration } from "../../../../api/TransfersEngine/TransferAPIConfigurations/index.js";
import Loader from "../../../../components/Loader/index.js";
import { CustomButton } from "../../../../components/CustomButton/index.js";
import TabStrip from "../../../../components/TabStrip/index.js";
import TransferConfigLogsTab from "./TransferConfigLogsTab.js";
import ReservationsStatusTag from "../../../../components/Tags/ReservationsStatusTag.js";

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailTransferAPIConfigurationsModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardBody: cardStyles.body,
  row: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    row: {
      gridTemplateColumns: "2fr 1fr",
    },
  },
});

const DetailTransferAPIConfigurationsModal = ({ uid, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicData");

  const menuTabs = {
    basicData: "Basic Data",
    logs: "Logs",
  };

  const { data: retrievedTransferEngine, isLoading: loading } = useQuery({
    queryKey: ["DetailAccEngineModal", uid],
    queryFn: async () => await retrieveTransferAPIConfiguration({ uid: uid }),
    onError: (error) => genericRequestErrorHandler(error),
    refetchOnWindowFocus: false,
  });

  const basicDataCpl = [
    ["Uid", "uid"],

    [
      "Name",
      null,
      _.capitalize(_.get(retrievedTransferEngine, "data.name")) || "N/A",
    ],
    [
      "Supplier",
      null,
      _.capitalize(_.get(retrievedTransferEngine, "data.supplier", "")) ||
        "N/A",
    ],
    ["Source Entity", "source_entity"],
    ["Market Type", "market_type"],
    ["Curency", "currency"],
    [
      "Is Live",
      null,
      _.get(
        transferEngineTrueFalseMapping,
        _.get(retrievedTransferEngine, "data.is_live")
      ) || "N/A",
    ],
    [
      "Is Global",
      null,
      _.get(
        transferEngineTrueFalseMapping,
        _.get(retrievedTransferEngine, "data.global_account")
      ) || "N/A",
    ],
    [
      "is Own",
      null,
      _.get(
        transferEngineTrueFalseMapping,
        _.get(retrievedTransferEngine, "data.is_own")
      ) || "N/A",
    ],
    [
      "Is Master Account",
      null,
      _.get(
        transferEngineTrueFalseMapping,
        _.get(retrievedTransferEngine, "data.is_master_account")
      ) || "N/A",
    ],
    ["Website Api Key", "website_api_key"],
    [
      "Status",
      null,
      <ReservationsStatusTag
        status={_.get(retrievedTransferEngine, "data.status")}
      />,
    ],
  ];

  const creatorData = [
    [
      "Created",
      null,
      DateTime.fromISO(
        _.get(retrievedTransferEngine, "data.created")
      ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) || "N/A",
    ],
    ["Creator", "creator"],
  ];
  const accessData = [
    [
      "Username",
      null,
      _.capitalize(_.get(retrievedTransferEngine, "data.username")) || "N/A",
    ],
    ["Password", "password"],
    ["Access Token", "access_token"],
    ["Api Key", "api_key"],
    ["Application Key", "application_key"],
    ["Api Url", "api_url"],
  ];
  const editorData = [
    ["Last Editor", "last_editor"],
    [
      "Edited",
      null,
      DateTime.fromISO(
        _.get(retrievedTransferEngine, "data.edited")
      ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) || "N/A",
    ],
  ];
  return (
    <div className={classes.DetailTransferAPIConfigurationsModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Transfer API Configuration Details</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicData" && (
                <React.Fragment>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Basic Data"}
                        cardInfo={basicDataCpl}
                        objectData={_.get(retrievedTransferEngine, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Creator Data"}
                        cardInfo={creatorData}
                        objectData={_.get(retrievedTransferEngine, "data", {})}
                      />
                      <GenericBasicInfo
                        header={"Editor Data"}
                        cardInfo={editorData}
                        objectData={_.get(retrievedTransferEngine, "data", {})}
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header="API Credentials"
                        cardInfo={accessData}
                        objectData={_.get(retrievedTransferEngine, "data", {})}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activeTab === "logs" && <TransferConfigLogsTab />}
            </React.Fragment>
          )}
        </div>
        <div className={classes.cardActions}>
          <CustomButton appearance="ghost" onClick={() => onClose()}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailTransferAPIConfigurationsModal.propTypes = {
  uid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DetailTransferAPIConfigurationsModal;
