import { VALAQUENTA_API_URL } from "../../..";
import { authorizedAxios, loggingAxios } from "../../../../App";

export function fetchWebSiteContactInfo({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/contact-info-for-entity/`,
    {
      params,
    }
  );
}

export function retrieveWebSiteContactInfo({ id }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/contact-info-for-entity/${id}/`
  );
}

export function patchWebSiteContactInfo({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/canaveral/contact-info-for-entity/${id}/`,
    payload
  );
}

export function createWebSiteContactInfo({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/canaveral/contact-info-for-entity/`,
    payload
  );
}
export function deleteWebSiteContactInfo({ id }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/canaveral/contact-info-for-entity/${id}/`
  );
}
