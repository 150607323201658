import React from "react";
import Logs from "../../../../components/Logs";
import { VALAQUENTA_API_URL } from "../../../../api";

const DistributionSitesLogsTab = (WrappedComponent) => {
  const Comp = (props) => {
    const actionsMapping = {
      add: {
        label: "Add",
        url: `${VALAQUENTA_API_URL}/canaveral/websites/`,
        method: "POST",
      },
      edit: {
        label: "Edit",
        url: `${VALAQUENTA_API_URL}/canaveral/websites/`,
        method: "PATCH",
      },
      delete: {
        label: "Delete",
        url: `${VALAQUENTA_API_URL}/canaveral/websites/`,
        method: "DELETE",
      },
      translate: {
        label: "Translate",
        url: `${VALAQUENTA_API_URL}/translateengine/translate`,
        method: "POST",
      },
      //About us
      aboutUsAdd: {
        label: "About Us Add",
        url: `${VALAQUENTA_API_URL}/canaveral/about-us-for-entity`,
        method: "POST",
      },
      aboutUsEdit: {
        label: "About Us Edit",
        url: `${VALAQUENTA_API_URL}/canaveral/about-us-for-entity`,
        method: "PATCH",
      },
      aboutUsDelete: {
        label: "About Us Delete",
        url: `${VALAQUENTA_API_URL}/canaveral/about-us-for-entity`,
        method: "DELETE",
      },
      //Contact Info
      ContactInfoAdd: {
        label: "Contact Info Add",
        url: `${VALAQUENTA_API_URL}/canaveral/contact-info-for-entity/`,
        method: "POST",
      },
      ContactInfoEdit: {
        label: "Contact Info Edit",
        url: `${VALAQUENTA_API_URL}/canaveral/contact-info-for-entity/`,
        method: "PATCH",
      },
      ContactInfoDelete: {
        label: "Contact Info Delete",
        url: `${VALAQUENTA_API_URL}/canaveral/contact-info-for-entity/`,
        method: "DELETE",
      },
      //FAQ
      FAQAdd: {
        label: "FAQ Add",
        url: `${VALAQUENTA_API_URL}/canaveral/faq-for-entity/`,
        method: "POST",
      },
      FAQEdit: {
        label: "FAQ Edit",
        url: `${VALAQUENTA_API_URL}/canaveral/faq-for-entity/`,
        method: "PATCH",
      },
      FAQDelete: {
        label: "FAQ Delete",
        url: `${VALAQUENTA_API_URL}/canaveral/faq-for-entity/`,
        method: "DELETE",
      },
      //Generic Sections
      GenericSectionsAdd: {
        label: "Generic Sections Add",
        url: `${VALAQUENTA_API_URL}/canaveral/descriptive-section-for-entity/`,
        method: "POST",
      },
      GenericSectionsEdit: {
        label: "Generic Sections Edit",
        url: `${VALAQUENTA_API_URL}/canaveral/descriptive-section-for-entity/`,
        method: "PATCH",
      },
      GenericSectionsDelete: {
        label: "Generic Sections Delete",
        url: `${VALAQUENTA_API_URL}/canaveral/descriptive-section-for-entity/`,
        method: "DELETE",
      },
      //hotel Priorities
      hotelPrioritiesAdd: {
        label: "Hotel Priorities Add",
        url: `${VALAQUENTA_API_URL}/accommodator/black-list/`,
        method: "POST",
      },
      hotelPrioritiesDelete: {
        label: "Hotel Priorities Delete",
        url: `${VALAQUENTA_API_URL}/accommodator/black-list/`,
        method: "DELETE",
      },
      hotelPrioritiesClone: {
        label: "Hotel Priorities Clone",
        url: `${VALAQUENTA_API_URL}/accommodator/black-list/clone-black-list/`,
        method: "POST",
      },
      //landing pages
      landingPagesAdd: {
        label: "Landing Pages Add",
        url: `${VALAQUENTA_API_URL}/canaveral/landing-pages-for-entity/`,
        method: "POST",
      },
      landingPagesEdit: {
        label: "Landing Pages Edit",
        url: `${VALAQUENTA_API_URL}/canaveral/landing-pages-for-entity/`,
        method: "PATCH",
      },
      landingPagesDelete: {
        label: "Landing Pages Delete",
        url: `${VALAQUENTA_API_URL}/canaveral/landing-pages-for-entity/`,
        method: "DELETE",
      },
      //List Pages
      listPagesAdd: {
        label: "List Pages Add",
        url: `${VALAQUENTA_API_URL}/canaveral/tour-list-for-entity/`,
        method: "POST",
      },
      listPagesEdit: {
        label: "List Pages Edit",
        url: `${VALAQUENTA_API_URL}/canaveral/tour-list-for-entity/`,
        method: "PATCH",
      },
      listPagesDelete: {
        label: "List Pages Delete",
        url: `${VALAQUENTA_API_URL}/canaveral/tour-list-for-entity/`,
        method: "DELETE",
      },
      //Main Promos
      mainPromosAdd: {
        label: "Main Promos Add",
        url: `${VALAQUENTA_API_URL}/canaveral/main-promo-for-entity/`,
        method: "POST",
      },
      mainPromosEdit: {
        label: "Main Promos Edit",
        url: `${VALAQUENTA_API_URL}/canaveral/main-promo-for-entity/`,
        method: "PATCH",
      },
      mainPromosDelete: {
        label: "Main Promos Delete",
        url: `${VALAQUENTA_API_URL}/canaveral/main-promo-for-entity/`,
        method: "DELETE",
      },
      //Promo Landing Pages
      promoLandingPagesAdd: {
        label: "Promo Landing Pages Add",
        url: `${VALAQUENTA_API_URL}/canaveral/promo-landing-pages-for-entity/`,
        method: "POST",
      },
      promoLandingPagesEdit: {
        label: "Promo Landing Pages Edit",
        url: `${VALAQUENTA_API_URL}/canaveral/promo-landing-pages-for-entity/`,
        method: "PATCH",
      },
      promoLandingPagesDelete: {
        label: "Promo Landing Pages Delete",
        url: `${VALAQUENTA_API_URL}/canaveral/promo-landing-pages-for-entity/`,
        method: "DELETE",
      },
      //Restaurant Promos
      restaurantPromosAdd: {
        label: "Restaurant Promos Add",
        url: `${VALAQUENTA_API_URL}/canaveral/restaurant-promo-for-entity/`,
        method: "POST",
      },
      restaurantPromosEdit: {
        label: "Restaurant Promos Edit",
        url: `${VALAQUENTA_API_URL}/canaveral/restaurant-promo-for-entity/`,
        method: "PATCH",
      },
      restaurantPromosDelete: {
        label: "Restaurant Promos Delete",
        url: `${VALAQUENTA_API_URL}/canaveral/restaurant-promo-for-entity/`,
        method: "DELETE",
      },
      //Terms and Conditions
      termsAndConditionsAdd: {
        label: "Terms and Conditions Add",
        url: `${VALAQUENTA_API_URL}/canaveral/terms-for-entity/`,
        method: "POST",
      },
      termsAndConditionsEdit: {
        label: "Terms and Conditions Edit",
        url: `${VALAQUENTA_API_URL}/canaveral/terms-for-entity/`,
        method: "PATCH",
      },
      termsAndConditionsDelete: {
        label: "Terms and Conditions Delete",
        url: `${VALAQUENTA_API_URL}/canaveral/terms-for-entity/`,
        method: "DELETE",
      },
    };

    return <WrappedComponent {...props} actionsMapping={actionsMapping} />;
  };
  return Comp;
};

export default DistributionSitesLogsTab(Logs);
