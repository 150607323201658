import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios } from "../../../App";

export function fetchPoliciesB2C({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/policies/b2c/`, {
    params,
  });
}

export function retrievePoliciesB2C({ id }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/policies/b2c/${id}`);
}

export function fetchPoliciesB2CTravelServices() {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/travel-services`);
}
