import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchEmailConfigReservations({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/email-config/`,
    {
      params,
    }
  );
}

export async function retrieveEmailConfigReservation({ id }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/email-config/${id}/`
  );
}

export async function checkEmailconfigVerification({ id }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/email-config/check-verification/${id}/`
  );
}

export async function patchEmailConfigReservation({ id, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crs/internal/email-config/${id}/`,
    payload
  );
}
export async function createEmailConfigReservation({ payload }) {
  return await loggingAxios.post(
    `${VALAQUENTA_API_URL}/crs/internal/email-config/`,
    payload
  );
}
export async function createDefaultEmailConfigs({ payload }) {
  return await loggingAxios.post(
    `${VALAQUENTA_API_URL}/crs/internal/email-config/default_configs/`,
    payload
  );
}
