import React from "react";
import { createUseStyles } from "react-jss";
import { variables } from "../../variables";

const FlightEngineStyles = createUseStyles({
  FlightEngine: {
    display: "grid",
    overflow: "hidden",
    placeContent: "center",
  },
  title: {
    display: "grid",
    textAlign: "center",
    height: "100%",
    alignItems: "end",
    padding: variables.normal_gap,
  },
});
const FlightEngine = () => {
  const classes = FlightEngineStyles();
  return (
    <div className={classes.FlightEngine}>
      <div className={classes.title}>
        <h1>Filghts Engine</h1>
      </div>
    </div>
  );
};

export default FlightEngine;
