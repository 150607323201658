import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  formSections,
  inputGroup,
  modalGenericStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import PropTypes from "prop-types";
import {
  createNewAccommodator,
  patchAccommodator,
  retrieveAccommodatorAPIConfiguration,
} from "../../../../api/AccEngine/APIConfigurations";
import {
  APIConfigsStatusMapping,
  convertStringToBoolean,
  currencies,
  handleValidationErrors,
} from "../../../../helpers";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import _ from "lodash";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";
import EntityAutocomplete from "../../../../components/EntityAutocomplete";
import { CustomButton } from "../../../../components/CustomButton";

export const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
};

const supplierOptions = {
  "": "-----",
  innstant: "Innstant",
  hotelbeds: "Hotelbeds",
  webhotelier: "Webhotelier",
};

const trueFalseMapping = {
  false: "No",
  true: "Yes",
};

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Must be at least 2 characters")
    .max(100, " Must be less than 100 characters")
    .default(""),
  supplier: yup.string().required("Must not be blanc").default(""),
  api_currency: yup.string().required("Must not be blanc").default(""),
  is_live: yup.boolean().nullable().default(false),
  global_account: yup.boolean().nullable().default(false),
  is_bar_only: yup.boolean().nullable().default(false),
  is_master_account: yup.boolean().nullable().default(false),
  is_own: yup.boolean().nullable().default(false),
  with_side_bar: yup.boolean().nullable().default(false),
  source_entity: yup.string().nullable().default(""),
  website_api_key: yup.string().nullable().default(""),
  status: yup.string().nullable().default("AC"),
  username: yup.string().nullable().default(""),
  password: yup.string().nullable().default(""),
  hotelbeds_api_key: yup.string().nullable().default(""),
  hotelbeds_secret: yup.string().nullable().default(""),
  mishor_access_token: yup.string().nullable().default(""),
  mishor_application_key: yup.string().nullable().default(""),
  mishor_static_key: yup.string().nullable().default(""),
});

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditAccEngineAPIConfigurationModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    display: "grid",
    gap: variables.normal_gap,
  },
  inputGroup: formInputGroup,
  entityAutoComplete: {
    ...inputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  form: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: `${variables.half_gap} ${variables.normal_gap}`,
    padding: variables.half_gap,
  },
  actionsCell: {
    ...tableStyles.body.cell,
    "& button:not(:first-child)": {
      marginTop: variables.half_gap,
    },
  },
  sectionHeader: formSections.sectionHeader,
  cardActions: { ...cardStyles.actions, gridColumn: "2 span", width: "97%" },
  [`@media ${variables.media.retina}`]: {
    card: {
      minWidth: "50rem",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardCol2: {
      gridTemplateColumns: "1fr 1fr",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },

    modalCard: {
      minWidth: "65rem",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    card: {
      minWidth: "50rem",
      gap: variables.normal_gap,
    },
    cardCol2: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: variables.normal_gap,
    },
    cardCol3: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      gap: variables.normal_gap,
    },
  },
});

const EditAccEngineAPIConfigurationModal = ({
  apiConfigurationId,
  onClose,
}) => {
  const classes = styles();
  let mode = apiConfigurationId ? "edit" : "add";

  const [form, setForm] = useState(jsonSchema.cast({}));
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: [
      "RetrieveEditAccEngineAPIConfigurationModal",
      apiConfigurationId,
    ],
    queryFn: async () => {
      return retrieveAccommodatorAPIConfiguration({
        uid: apiConfigurationId,
      });
    },
    enabled: mode === "edit",
    onSuccess: (data) => {
      setForm(jsonSchema.cast(_.get(data, "data", {})));
      setAutocompleteValue(_.get(data, "data.source_entity"));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const addMutation = useMutation({
    mutationFn: ({ payload }) => {
      return createNewAccommodator({ payload });
    },
    onSuccess: () => {
      toast.success("New Accommodation API added");
      queryClient.invalidateQueries("AccEngineAPIConfigurations");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const editMutation = useMutation({
    mutationFn: async ({ uid, payload }) => {
      return patchAccommodator({ uid, payload });
    },
    onSuccess: () => {
      toast.success("Accommodation API Info Updated");
      queryClient.invalidateQueries("AccEngineAPIConfigurations");
      onClose();
    },
  });

  const onSubmit = async ({ uid, payload }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });

      if (mode === "add") {
        addMutation.mutate({ payload });
      } else {
        editMutation.mutate({ uid, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const handleInputChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const boolFields = convertStringToBoolean({
    global_account: form.global_account,
    is_master_account: form.is_master_account,
    is_bar_only: form.is_bar_only,
    is_own: form.is_own,
    with_side_bar: form.with_side_bar,
    is_live: form.is_live,
  });
  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;
  return (
    <div
      id="addEditAccEngineAPIConfigurationModal"
      className={classes.EditAccEngineAPIConfigurationModal}>
      <div className={classes.modalCard}>
        {loading && <Loader onTop={true} />}
        <div className={classes.modalCardHeader}>
          <span>{_.startCase(mode)} Accommodation API Configuration</span>
        </div>
        <div className={classes.modalCardBody}>
          <form
          // onChange={(e) => {
          //   setForm((p) => ({
          //     ...p,
          //     [e.target.name]: e.target.value,
          //   }));
          // }}
          >
            <div className={classes.card}>
              <div className={classes.cardHeader}>Basic Data</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol1}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="name">Name</label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      value={_.capitalize(form.name)}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className={classes.cardCol3}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="supplier">Supplier</label>
                    <select
                      id="supplier"
                      name="supplier"
                      value={form.supplier}
                      onChange={handleInputChange}>
                      {Object.entries(supplierOptions).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="source_entity">Source Entity</label>
                    <div className={classes.entityAutoComplete}>
                      <EntityAutocomplete
                        id="entityAutocompleteInputId"
                        subagents={false}
                        independentAgents={false}
                        branches={false}
                        value={autocompleteValue}
                        setValue={setAutocompleteValue}
                        onChange={(text) => {
                          if (text.length === 0) {
                            setForm((p) => ({
                              ...p,
                              source_entity: "",
                            }));
                            return;
                          }
                          const entityInfos = text.split("---");
                          const entityType = entityInfos[0];
                          const entityId = entityInfos[1];
                          setForm((p) => ({
                            ...p,
                            source_entity: `${entityId}___${entityType}`,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="api_currency">API Currency</label>
                    <select
                      id="api_currency"
                      name="api_currency"
                      value={form.api_currency}
                      onChange={handleInputChange}>
                      {Object.entries(currencies).map(([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="is_live">Is Live</label>
                    <select
                      id="is_live"
                      name="is_live"
                      value={form.is_live}
                      onChange={handleInputChange}>
                      {Object.entries(trueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="global_account">Is Global</label>
                    <select
                      id="global_account"
                      name="global_account"
                      value={form.global_account}
                      onChange={handleInputChange}>
                      {Object.entries(trueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="is_own">Is Own</label>
                    <select
                      id="is_own"
                      name="is_own"
                      value={form.is_own}
                      onChange={handleInputChange}>
                      {Object.entries(trueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="is_bar_only">Bar Only</label>
                    <select
                      id="is_bar_only"
                      name="is_bar_only"
                      value={form.is_bar_only}
                      onChange={handleInputChange}>
                      {Object.entries(trueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="is_master_account">Is Master Account</label>
                    <select
                      id="is_master_account"
                      name="is_master_account"
                      value={form.is_master_account}
                      onChange={handleInputChange}>
                      {Object.entries(trueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>

                  <div className={classes.inputGroup}>
                    <label htmlFor="with_side_bar">With Side Bar</label>
                    <select
                      id="with_side_bar"
                      name="with_side_bar"
                      value={form.with_side_bar}
                      onChange={handleInputChange}>
                      {Object.entries(trueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label>Website API Key</label>
                    <input
                      id="website_api_key"
                      type="text"
                      name="website_api_key"
                      value={form.website_api_key}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="status">Status</label>
                    <select
                      id="status"
                      name="status"
                      value={form.status}
                      onChange={handleInputChange}>
                      {Object.entries(APIConfigsStatusMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.cardHeader}>API Credentials</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label>Username</label>
                    <input
                      id="username"
                      type="text"
                      name="username"
                      value={form.username}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Password</label>
                    <input
                      id="password"
                      type="text"
                      name="password"
                      value={form.password}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Hotelbeds API Key</label>
                    <input
                      id="hotelbeds_api_key"
                      type="text"
                      name="hotelbeds_api_key"
                      value={form.hotelbeds_api_key}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Hotelbeds Secret</label>
                    <input
                      id="hotelbeds_secret"
                      type="text"
                      name="hotelbeds_secret"
                      value={form.hotelbeds_secret}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className={classes.inputGroup}>
                  <label>Mishor Access Token</label>
                  <input
                    id="mishor_access_token"
                    type="text"
                    name="mishor_access_token"
                    value={form.mishor_access_token}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label>Mishor Application Key</label>
                  <input
                    id="mishor_application_key"
                    type="text"
                    name="mishor_application_key"
                    value={form.mishor_application_key}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label>Mishor Static Key</label>
                  <input
                    id="mishor_static_key"
                    type="text"
                    name="mishor_static_key"
                    value={form.mishor_static_key}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            id="submit"
            appearance="primary"
            onClick={(e) => {
              e.preventDefault();
              onSubmit({
                uid: apiConfigurationId,
                payload: { ...form, ...boolFields },
              });
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
EditAccEngineAPIConfigurationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditAccEngineAPIConfigurationModal;
