import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { cardStyles, tableStyles, variables } from "../../../../../variables";
import _ from "lodash";

const collectionMethodMap = {
  SE: "Send Invoice",
  CH: "Charge Automatically",
};

const getErrors = (paymentTerms, groups_display) => {
  const hasB2CGroup = groups_display.includes("has_b2c");

  var error = null;
  const hasB2BTerms = paymentTerms.some((term) => term.market_type === "B2B");
  const hasB2CTerms = paymentTerms.some((term) => term.market_type === "B2C");
  const isB2CAndHasNoB2CTerms = hasB2CGroup && !hasB2CTerms;

  if (!hasB2BTerms) {
    error = "(The B2B Payment Term is missing.)";
  }
  if (hasB2BTerms && isB2CAndHasNoB2CTerms) {
    error = "(The B2C Payment Term is missing.)";
  }
  if (!hasB2BTerms && isB2CAndHasNoB2CTerms) {
    error =
      "(There are missing Payment Terms for both B2B and B2C market types.)";
  }
  return error;
};

const styles = createUseStyles({
  ...cardStyles,
  PaymentTerms: { ...cardStyles.card },
  header: {
    ...cardStyles.header,
    fontSize: "1.2rem",
    padding: variables.normal_gap,
    marginTop: "1px",
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  tableContainer: {
    display: "block",
    maxHeight: "20rem",
    overflow: "auto",
    width: "100%",
  },
  table: {
    width: "100%",
  },
  thead: {
    position: "sticky",
    top: 0,
  },
  emptyPaymentTerms: {
    color: "red",
    textAlign: "center",
    minHeight: "10rem",
    lineHeight: "10rem",
  },
  red: { color: "red" },
});
const PaymentTerms = ({ paymentTerms, groups_display }) => {
  const classes = styles();

  const warnings = getErrors(paymentTerms, groups_display);

  return (
    <div className={classes.PaymentTerms}>
      <div className={classes.header}>
        <span>
          Payment Terms{" "}
          {warnings && <span className={classes.red}>{warnings}</span>}
        </span>
      </div>
      <div className={classes.tableContainer}>
        {paymentTerms.length === 0 ? (
          <div className={classes.emptyPaymentTerms}>
            <strong>No Payment Terms</strong>
          </div>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Market Type</th>
                <th className={classes.headCell}>Collection Method</th>
              </tr>
            </thead>
            <tbody>
              {paymentTerms.map((pT, idx) => (
                <tr key={pT.id}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  <td className={classes.tableCell}>
                    {_.get(pT, "market_type") || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(
                      collectionMethodMap,
                      _.get(pT, "collection_method")
                    ) || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
PaymentTerms.propTypes = {
  paymentTerms: PropTypes.array.isRequired,
  groups_display: PropTypes.array.isRequired,
};
export default PaymentTerms;
