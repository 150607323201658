import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios } from "../../../App";

export function fetchMonitoring() {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/highinquisitor/scenario-test/available-scenarios/`
  );
}

export function fetchScenarioData({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/highinquisitor/scenario-test/`,
    {
      params,
    }
  );
}

export function retrieveScenarioDetail({ scenario_uid, params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/highinquisitor/scenario-test/${scenario_uid}/`,
    {
      params,
    }
  );
}
