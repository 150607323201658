import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  tableStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import _ from "lodash";
import { Button } from "../../../../../components/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { fixWebhookRequirements } from "../../../../../api/CRM/Members/Webhooks";
import Loader from "../../../../../components/Loader";

/**
 * @param {String} webhook
 * @returns {String} webhookPath
 * @description
 * Takes a webhook url and returns the path
 * @example
 * https://api.travelnations.com/prod/webhooks/stripe/amount_authorized/
 * returns /webhooks/stripe/amount_authorized
 */
const getCleanWebhookPath = (webhook) => {
  let env = webhook.url.includes("/prod") ? "/prod" : "/staging";
  let webhookPath = webhook.url.split(env)[1];
  if (!webhookPath) {
    return null;
  }
  webhookPath = webhookPath.endsWith("/")
    ? webhookPath.slice(0, -1)
    : webhookPath;
  return webhookPath;
};

/**
 * @param {Array} webhooks
 * @returns {Object} warningsMapping
 * @description
 * 1. Check if there are all required webhooks
 * 2. Check if there are all required events in each webhook
 * Return warningsMapping
 * @example
 * {
 * "webhookPath": ["warning"]
 * }
 */
const getWarnings = (webhooks, groups_display) => {
  if (!groups_display.includes("has_b2c")) {
    return;
  }

  // webhook: event
  const requiredWebhooks = {
    "/webhooks/stripe/amount_authorized":
      "payment_intent.amount_capturable_updated",
    "/webhooks/stripe/payment_link_paid": "checkout.session.completed",
    "/webhooks/stripe": "payment_intent.succeeded",
  };
  let warnings = {};
  const prodWebhooks = webhooks.filter((webhook) =>
    webhook.url.includes("/prod")
  );
  Object.entries(requiredWebhooks).forEach(([webhook, event]) => {
    let webhookWarns = [];
    const foundWebhook = prodWebhooks.find(
      (w) => getCleanWebhookPath(w) === webhook
    );
    if (!foundWebhook) {
      webhookWarns.push(`Missing webhook for event ${event}`);
    }
    if (!_.get(foundWebhook, "enabled_events", []).includes(event)) {
      webhookWarns.push(`Missing event ${event} in webhook`);
    }
    if (webhookWarns.length > 0) {
      warnings[webhook] = webhookWarns;
    }
  });
  return warnings;
};

/**
 * @param {Number} timestamp
 * @returns {String} date
 * @description
 * Takes a timestamp and returns a date
 * @example
 * 1697036000
 * returns 10/8/2023, 12:00:00 AM
 */
const getDateFromTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleString();
};

/**
 * @param {Array} webhooks
 * @param {Object} classes
 * @returns {Array} rows
 * @description
 * Takes an array of webhooks and returns an array of rows
 */
const getWebhookRows = (webhooks, classes) => {
  let rows = [];
  webhooks.forEach((webhook, idx) => {
    const url = _.get(webhook, "url", "");
    let environment = null;
    let webhookPath = null;
    if (url) {
      environment = url.includes("/prod") ? "Production" : "Staging";
      webhookPath = getCleanWebhookPath(webhook);
    }
    rows.push(
      <tr key={`webhook_${idx}`}>
        <td className={classes.tableCell}>{idx + 1}</td>
        <td className={classes.tableCell}>{webhookPath || "N/A"}</td>
        <td className={classes.tableCell}>{environment || "N/A"}</td>
        <td className={classes.tableCell}>
          {_.get(webhook, "enabled_events", []).join(", ") || "N/A"}
        </td>
        <td className={classes.tableCell}>
          {_.get(webhook, "livemode") ? (
            <span className={classes.green}>{"Yes"}</span>
          ) : (
            "No"
          )}
        </td>
        <td className={classes.tableCell}>
          {webhook.status ? _.startCase(webhook.status) : "N/A"}
        </td>
        <td className={classes.tableCell}>
          {webhook.created ? getDateFromTimestamp(webhook.created) : "N/A"}
        </td>
      </tr>
    );
  });
  return rows;
};

const styles = createUseStyles({
  ...cardStyles,
  WebhooksSection: { ...cardStyles.card },
  header: {
    ...cardStyles.header,
    padding: variables.normal_gap,
    fontSize: "1.2rem",
  },
  headerActions: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: variables.normal_gap,
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  tableContainer: {
    display: "block",
    maxHeight: "20rem",
    overflow: "auto",
    width: "100%",
  },
  table: {
    width: "100%",
  },
  thead: {
    position: "sticky",
    top: 0,
  },
  emptyWebhooks: {
    color: "red",
    textAlign: "center",
    minHeight: "10rem",
    lineHeight: "10rem",
  },
  green: { color: "green" },
  red: { color: "red" },
});
const WebhooksSection = ({ webhooks, groups_display, memberId }) => {
  const classes = styles();

  const [showWarnings, setShowWarnings] = useState(false);
  const [showFixModal, setShowFixModal] = useState(false);

  const warnings = getWarnings(webhooks, groups_display);
  const thereAreWarnings = !_.isEmpty(warnings);

  return (
    <div className={classes.WebhooksSection}>
      <div className={classes.header}>
        <span>
          Webhooks{" "}
          {thereAreWarnings && (
            <span className={classes.red}>{"(There are warnings)"}</span>
          )}
        </span>
        {thereAreWarnings && (
          <div className={classes.headerActions}>
            <Button
              text="Show Warnings"
              onClick={() => setShowWarnings(true)}
            />
            <Button text="Fix Errors" onClick={() => setShowFixModal(true)} />
          </div>
        )}
      </div>
      <div className={classes.tableContainer}>
        {webhooks.length === 0 ? (
          <div className={classes.emptyWebhooks}>
            <strong>No Webhooks</strong>
          </div>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Webhook</th>
                <th className={classes.headCell}>Environment</th>
                <th className={classes.headCell}>Events</th>
                <th className={classes.headCell}>Live Mode</th>
                <th className={classes.headCell}>Status</th>
                <th className={classes.headCell}>Created</th>
              </tr>
            </thead>
            <tbody>{getWebhookRows(webhooks, classes)}</tbody>
          </table>
        )}
      </div>
      {showWarnings && (
        <WarningsModal
          warnings={warnings}
          onClose={() => setShowWarnings(false)}
        />
      )}
      {showFixModal && (
        <FixRequirementsModal
          memberId={memberId}
          onClose={() => setShowFixModal(false)}
        />
      )}
    </div>
  );
};
WebhooksSection.propTypes = {
  webhooks: PropTypes.array.isRequired,
  groups_display: PropTypes.array.isRequired,
  memberId: PropTypes.number.isRequired,
};
export default WebhooksSection;

const warningModalStyles = createUseStyles({
  ...modalGenericStyles,
  WarningsModal: {
    ...modalGenericStyles.modal,
  },
  mainCard: {
    ...modalGenericStyles.card,
    width: "50rem",
  },
  mainCardBody: {
    ...modalGenericStyles.card,
    display: "grid",
    maxHeight: "30rem",
    gap: variables.normal_gap,
    overflow: "auto",
    justifyItems: "center",
  },
  card: {
    ...modalGenericStyles.card,
    width: "80%",
    minHeight: "10rem",
  },
  cardBody: {
    ...modalGenericStyles.card,
    minHeight: "6rem",
  },
});
const WarningsModal = ({ warnings, onClose }) => {
  const classes = warningModalStyles();
  return (
    <div className={classes.WarningsModal}>
      <div className={classes.mainCard}>
        <div className={classes.cardHeader}>
          <span>WARNINGS</span>
        </div>
        <div className={classes.mainCardBody}>
          {Object.keys(warnings).map((webhook, idx) => (
            <div key={idx} className={classes.card}>
              <div className={classes.cardHeader}>
                <span>{webhook}</span>
              </div>
              <div className={classes.cardBody}>
                <ul>
                  {warnings[webhook].map((warning, idx) => (
                    <li key={`${idx}_${webhook}`}>{warning}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.cardActions}>
          <Button text="Close" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};
WarningsModal.propTypes = {
  warnings: PropTypes.object.isRequired,
  groups_display: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

const fixRequirementsModalStyles = createUseStyles({
  ...modalGenericStyles,
  FixRequirementsModal: {
    ...modalGenericStyles.modal,
  },
  mainCard: {
    ...modalGenericStyles.card,
    width: "50rem",
  },
  mainCardBody: {
    ...modalGenericStyles.cardBody,
    minHeight: "5rem",
    justifyItems: "center",
  },
});
const FixRequirementsModal = ({ memberId, onClose }) => {
  const classes = fixRequirementsModalStyles();

  const queryClient = useQueryClient();

  const fixMutation = useMutation({
    mutationFn: (sourceEntity) =>
      fixWebhookRequirements({
        payload: { source_entity: `${memberId}___member` },
      }),
    onSuccess: (data) => {
      toast.success("Requirements fixed successfully");
      queryClient.invalidateQueries(["memberBirdsEye", memberId]);
      onClose();
    },
    onError: (error) => {
      toast.error("Unable to fix the requirements. Please try again later.");
    },
  });

  return (
    <div className={classes.FixRequirementsModal}>
      <div className={classes.mainCard}>
        <div className={classes.cardHeader}>
          <span>Fix the webhook requirements</span>
        </div>
        <div className={classes.mainCardBody}>
          {fixMutation.isLoading ? (
            <Loader />
          ) : (
            "Are you sure you want to fix the webhook requirements?"
          )}
        </div>
        <div className={classes.cardActions}>
          <Button text="Cancel" onClick={onClose} />
          <Button text="Submit" onClick={() => fixMutation.mutate(memberId)} />
        </div>
      </div>
    </div>
  );
};
FixRequirementsModal.propTypes = {
  memberId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
