import React from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  modalGenericStyles,
  tableStyles,
  variables,
  cardStyles,
} from "../../../../../../variables";
import PropTypes from "prop-types";
import GenericBasicInfo from "../../../../../../components/cards/GenericBasicInfo";
import { DateTime } from "luxon";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import {
  fetchPoliciesB2BTravelServices,
  retrievePoliciesB2B,
} from "../../../../../../api/CRM/PoliciesB2B/PoliciesB2B";
import { genericRequestErrorHandler } from "../../../../../../api";
import Loader from "../../../../../../components/Loader";
import OneToOneTable from "../../Components/OneToOneTable";
import GeoTable from "../../Components/GeoTable";
import Description from "../../../Common/Description";
import { CustomButton } from "../../../../../../components/CustomButton";

const travelServiceTableStyles = createUseStyles({
  ...cardStyles,
  TravelServiceTable: {
    display: "grid",
    gridTemplateRows: " 1fr",
    overflow: "hidden",
    position: "relative",
    padding: variables.normal_gap,
    width: "100%",
  },
  header: { ...cardStyles.header },
  tableContainer: {
    ...genericIndexesStyles.tableContainer.mobile(),
    padding: variables.normal_gap,
  },
  noTravelServicesContainer: {
    display: "grid",
    gridColumn: "2 span",
  },
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
});

const TravelServiceTable = ({ data }) => {
  const classes = travelServiceTableStyles();

  const { data: travelServices } = useQuery({
    queryKey: ["B2BTravelServices"],
    queryFn: () => fetchPoliciesB2BTravelServices(),

    onError: (error) => genericRequestErrorHandler(error),
  });

  var tServices = {};
  _.get(travelServices, "data.results", []).forEach((service) => {
    tServices[service.id] = service.brief_description_en;
  });

  return (
    <div className={classes.TravelServiceTable}>
      <div className={classes.header}>Target services policies</div>
      <div className={classes.tableContainer}>
        <div className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              <th className={classes.headCell}>Service Type</th>
              <th className={classes.headCell}>Amount</th>
            </tr>
          </thead>
          <tbody>
            {_.isEmpty(data) ? (
              <tr className={classes.bodyRow}>
                <td className={classes.tableCell} colSpan="4">
                  <div className={classes.noTravelServicesContainer}>
                    There are no target Policies
                  </div>
                </td>
              </tr>
            ) : (
              data.map((dt, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  <td className={classes.tableCell}>
                    {_.get(tServices, _.get(dt, "id")) || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(dt, "amount_type") === "num"
                      ? _.get(dt, "amount")
                      : `${_.get(dt, "amount")} %`}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </div>
      </div>
    </div>
  );
};
TravelServiceTable.propTypes = {
  data: PropTypes.array.isRequired,
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailB2BModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    alignContent: "start",
    gap: variables.normal_gap,
  },
  row: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  tergetServicesCardBody: {
    extend: "cardBody",
    alignContent: "start",
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardBody: { justifyContent: "center" },
    row: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
});

const DetailB2BModal = ({ b2bId, onClose }) => {
  const classes = styles();

  const { data, isLoading: isFetchLoading } = useQuery({
    queryKey: ["DetailB2BModal"],
    queryFn: () =>
      retrievePoliciesB2B({
        id: b2bId,
      }),
    onError: (error) => {
      genericRequestErrorHandler(error);
      onClose();
    },
  });

  const createdDataCpl = [
    [
      "Created",
      null,
      DateTime.fromISO(_.get(data, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    ["Creator", null, _.capitalize(_.get(data, "data.creator", ""))],
    ["Creator Username", null, _.get(data, "data.creator_info.username", "")],
    [
      "Creator First Name",
      null,
      _.get(data, "data.creator_info.first_name", ""),
    ],
    ["Creator Last Name", null, _.get(data, "data.creator_info.last_name", "")],
    ["Creator Email", null, _.get(data, "data.creator_info.email", "")],
  ];

  const editDataCpl = [
    [
      "Edited",
      null,
      DateTime.fromISO(_.get(data, "data.edited")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    [
      "Last Editor Username",
      null,
      _.get(data, "data.last_editor_info.username", ""),
    ],
    [
      "Last Editor First Name",
      null,
      _.get(data, "data.last_editor_info.first_name", ""),
    ],
    [
      "Last Editor Last Name",
      null,
      _.get(data, "data.last_editor_info.last_name", ""),
    ],
    ["Last Editor email", null, _.get(data, "data.last_editor_info.email", "")],
  ];

  const basicDataCpl = [
    ["Id", "id"],
    ["Policy Type", "policy_type"],
    ["Name", null, _.capitalize(_.get(data, "data.name", ""))],
    [
      "Source Entity",
      null,
      `${_.get(data, "data.source_entity_id", 0)}___${_.get(
        data,
        "data.source_content_type",
        ""
      )}`,
    ],
    ["Pax From", "pax_from"],
    ["Pax To", "pax_to"],
    ["Rounding Digits", "rounding_digits"],
    [
      "First lvl Places",
      null,
      _.get(data, "data.firstlvl_places", []).join(","),
    ],
    [
      "Second lvl Places",
      null,
      _.get(data, "data.secondlvl_places", []).join(","),
    ],
    [
      "Service Execution From",
      null,
      DateTime.fromISO(
        _.get(data, "data.service_execution_from")
      ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
    ],
    [
      "Service Execution To",
      null,
      DateTime.fromISO(_.get(data, "data.service_execution_to")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    [
      "Booking Window From",
      null,
      DateTime.fromISO(_.get(data, "data.booking_window_from")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    [
      "Booking Window To",
      null,
      DateTime.fromISO(_.get(data, "data.booking_window_to")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    ["Status", "status_dispay"],
  ];

  return (
    <div className={classes.DetailB2BModal}>
      {isFetchLoading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>B2B Policy Details</span>
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>B2B Policy Details</span>
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.row}>
              <div className={classes.cardBody}>
                <GenericBasicInfo
                  header={"Basic Data"}
                  cardInfo={basicDataCpl}
                  objectData={_.get(data, "data", {})}
                />
              </div>
              <div className={classes.cardBody}>
                <GenericBasicInfo
                  header={"Created Data"}
                  cardInfo={createdDataCpl}
                  objectData={_.get(data, "data", {})}
                />
                <GenericBasicInfo
                  header={"Last Edit Data"}
                  cardInfo={editDataCpl}
                  objectData={_.get(data, "data", {})}
                />
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.cardBody}>
                {_.get(data, "data.policy_type") === "O2O" && (
                  <OneToOneTable
                    title="One to One Policy"
                    data={_.get(data, "data.entities", [])}
                  />
                )}
                {_.get(data, "data.policy_type") === "GRO" && (
                  <OneToOneTable
                    title="Group Policy"
                    data={_.get(data, "data.entities", [])}
                  />
                )}
                {_.get(data, "data.policy_type") === "GEO" && (
                  <GeoTable data={_.get(data, "data", {})} />
                )}
              </div>
              <div className={classes.tergetServicesCardBody}>
                <TravelServiceTable
                  data={_.get(data, "data.travel_services_policies", [])}
                />
                <div className={classes.description}>
                  <Description
                    description={_.get(data, "data.description", "")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={() => onClose()}>
              <strong>Cancel</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
DetailB2BModal.propTypes = {
  b2bId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DetailB2BModal;
