import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchTransfersVendors({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/vendors/`, {
    params,
  });
}

export function retrieveTransfer({ id }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/vendors/${id}/`);
}

export function patchTransfer({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crm/vendors/${id}/`,
    payload
  );
}

export function createNewTransfer({ payload }) {
  return loggingAxios.post(`${VALAQUENTA_API_URL}/crm/vendors/`, payload);
}

export function deleteTransferVendor({ id }) {
  return loggingAxios.delete(`${VALAQUENTA_API_URL}/crm/vendors/${id}/`);
}
