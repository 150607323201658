import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { fetchMembersAutocomplete } from "../../api/EntityAutocomplete/MembersAutocomplete";
import PropTypes from "prop-types";
import { variables } from "../../variables";
import _ from "lodash";
import { fetchSubagentsAutocomplete } from "../../api/EntityAutocomplete/SubagentsAutocomplete";
import { fetchBranchesAutocomplete } from "../../api/EntityAutocomplete/BranchesAutocomplete";
import { fetchIntepententAgentsAutocomplete } from "../../api/EntityAutocomplete/IndependentAgentsAutocomplete";
import { onEnterDown } from "../../helpers";

var fetchMembers = async (name) => {
  const response = await fetchMembersAutocomplete({ params: { name } });
  if (!response) {
    return [];
  }
  return response;
};
fetchMembers = _.debounce(fetchMembers, 500, { leading: true });

var fetchSubagents = async (name) => {
  const response = await fetchSubagentsAutocomplete({ params: { name } });
  if (!response) {
    return [];
  }
  return response;
};
fetchSubagents = _.debounce(fetchSubagents, 500, { leading: true });

var fetchBranches = async (name) => {
  const response = await fetchBranchesAutocomplete({ params: { name } });
  if (!response) {
    return [];
  }
  return response;
};
fetchBranches = _.debounce(fetchBranches, 500, { leading: true });

var fetchIndependentAgents = async (name) => {
  const response = await fetchIntepententAgentsAutocomplete({
    params: { name },
  });
  if (!response) {
    return [];
  }
  return response;
};
fetchIndependentAgents = _.debounce(fetchIndependentAgents, 500, {
  leading: true,
});

const defaultSuggestions = {
  member: [],
  branch: [],
  subagent: [],
  indepententagent: [],
};

const styles = createUseStyles({
  EntityAutocomplete: {
    display: "grid",
    position: "relative",
  },
  suggestions: {
    display: "grid",
    position: "absolute",
    overflow: "auto",
    maxHeight: "25vh",
    justifyContent: "center",
    cursor: "pointer",
    border: "1px solid black",
  },
  suggestion: {
    cursor: "pointer",
    border: "1px solid rgba(0,0,0,0.5)",
    background: "white",
    padding: variables.half_gap,
    textAlign: "left",
    "&:hover": {
      backgroundColor: "grey",
      color: "white",
    },
  },
});
const EntityAutocomplete = ({
  id,
  disabled,
  value,
  setValue,
  onChange,
  members,
  subagents,
  independentAgents,
  branches,
}) => {
  const classes = styles();

  const [suggestions, setSuggestions] = useState(defaultSuggestions);

  const onChangeHandler = async (text) => {
    if (text.length > 2) {
      let promises = [];
      let entityTypes = [];
      if (members) {
        entityTypes.push("member");
        promises.push(fetchMembers(text));
      }
      if (subagents) {
        entityTypes.push("subagent");
        promises.push(fetchSubagents(text));
      }
      if (independentAgents) {
        entityTypes.push("indepentent_agent");
        promises.push(fetchIndependentAgents(text));
      }
      if (branches) {
        entityTypes.push("branche");
        promises.push(fetchBranches(text));
      }
      Promise.all(promises).then((responses) => {
        responses.forEach((response, idx) => {
          setSuggestions((p) => ({
            ...p,
            [entityTypes[idx]]: response,
          }));
        });
      });
    }
    if (text.length === 0) {
      onChange("");
      setSuggestions(defaultSuggestions);
    }
  };
  const onSuggestSelect = (entity, entityType) => {
    onChange(`${entityType}---${entity.id}---${entity.name}---${entity.email}`);
    setValue(`${entity.name} (${entityType})`);
    setSuggestions(defaultSuggestions);
  };

  return (
    <div classes={classes.EntityAutocomplete}>
      <input
        id={id}
        disabled={disabled}
        type="text"
        onChange={(e) => {
          setValue(e.target.value);
          onChangeHandler(e.target.value);
        }}
        value={value}
        onBlur={() => setTimeout(() => setSuggestions(defaultSuggestions), 100)}
      />
      {_.flatten(Object.values(suggestions)).length > 0 && (
        <div className={classes.suggestions}>
          {_.flatten(
            Object.entries(suggestions).map(([entityType, entities]) =>
              entities.map((entity) => [entityType, entity])
            )
          ).map(([entityType, entity], idx) => (
            <div
              key={`${entityType}__${idx}`}
              id={`${id}_${idx}`}
              className={classes.suggestion}
              onClick={() => onSuggestSelect(entity, entityType)}>
              {entity.name}, {entity.id} ({_.startCase(entityType)})
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
EntityAutocomplete.defaultProps = {
  disabled: false,
  members: true,
  subagents: true,
  branches: true,
  independentAgents: true,
  id: "EntityAutocomplete",
};
EntityAutocomplete.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default EntityAutocomplete;
