import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../components/cards/GenericBasicInfo";
import _ from "lodash";
import { genericRequestErrorHandler } from "../../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteActAPIConfigurations } from "../../../../api/ActivitiesEngine/APIConfigurations";
import { currencies } from "../../../../helpers";
import { DateTime } from "luxon";
import Loader from "../../../../components/Loader";
import { CustomButton } from "../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  DeleteVesselModal: modalGenericStyles.modal,
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    gridTemplateColumns: "repeat(2, max-content)",
  },
});

const DeleteActivitiesAPIConfigurationModal = ({
  deletedUid,
  data,
  onClose,
}) => {
  const classes = styles();
  const queryClient = useQueryClient();

  const basicDataCpl = [
    ["Uid", "uid"],
    ["Name", "name"],
    ["Currency", null, _.get(currencies, _.get(data, "currency"))],
    ["Environment", "environment"],
    ["Client Id", "client_id"],
    ["Client Secret", "client_secret"],
    ["Base Url", "base_url"],
    ["Grand Type", "grand_type"],
    ["Market Type", "market_type"],
    ["Source Entity", "source_entity"],
    ["X Musement Application", "x_musement_application"],
    [
      "Created",
      null,
      DateTime.fromISO(_.get(data, "created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];

  const deleteActApiConfigurationMutation = useMutation(
    deleteActAPIConfigurations,
    {
      onSuccess: () => {
        toast.success("Activity Api Configuration Deleted");
        onClose();
        queryClient.invalidateQueries("ActivitiesAPIConfigurations");
      },
      onError: (error) => genericRequestErrorHandler(error),
    }
  );
  const onDelete = (uid) => {
    deleteActApiConfigurationMutation.mutate({ uid });
  };

  return (
    <div id="DeleteACTEngineModal" className={classes.DeleteVesselModal}>
      <React.Fragment>
        <div className={classes.modalCard}>
          {deleteActApiConfigurationMutation.isLoading && (
            <Loader onTop={true} />
          )}
          <div className={classes.modalCardHeader}>
            <span>DELETE Activity Api Configuration</span>
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.card}>
              <div className={classes.cardBody}>
                <GenericBasicInfo
                  header={"Are you sure you want to delete this?"}
                  cardInfo={basicDataCpl}
                  objectData={data}
                />
              </div>
            </div>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton
              id="DeleteACTEngineModalCloseButton"
              appearance="ghost"
              onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="DeleteACTEngineModalConfirmButton"
              appearance="primary"
              onClick={() => {
                onDelete(deletedUid);
              }}>
              <strong>DELETE</strong>
            </CustomButton>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
DeleteActivitiesAPIConfigurationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  deletedUid: PropTypes.string.isRequired,
};
export default DeleteActivitiesAPIConfigurationModal;
