import React from "react";
import { createUseStyles } from "react-jss";
import { variables } from "../../variables";

const vesselEngineStyles = createUseStyles({
  VesselEngine: {
    display: "grid",
    overflow: "hidden",
    placeContent: "center",
  },
  title: {
    display: "grid",
    textAlign: "center",
    height: "100%",
    alignItems: "end",
    padding: variables.normal_gap,
  },
});
const VesselEngine = () => {
  const classes = vesselEngineStyles();

  return (
    <div className={classes.VesselEngine}>
      <div className={classes.title}>
        <h1>Vessel Engine</h1>
      </div>
    </div>
  );
};
export default VesselEngine;
