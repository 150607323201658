import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { fetchAirports } from "../../../../api/FlightEngine/Airports";
import ControlStrip from "../../../../components/ControlStrip";
import Loader from "../../../../components/Loader";
import {
  countries,
  handleApplyFilters,
  medWeekdayDatetimer,
} from "../../../../helpers";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import DetailAirportModal from "../AirportModals/DetailAirportModal";
import EditAirportModal from "../AirportModals/EditAirportModal";
import Navbar from "./Navbar";
import { useQuery } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import { DateTime } from "luxon";
import { genericRequestErrorHandler } from "../../../../api";
import { CustomButton } from "../../../../components/CustomButton";

const initialFilters = {
  limit: 20,
  page: 1,
  ordering: "",
  discending: "",
  name_en__icontains: "",
  iata: "",
  icao: "",
  country_code: "",
  status: "",
  created__gte: "",
  created__lte: "",
};
const navbarSchema = yup.object().shape({
  name_en__icontains: yup
    .string()
    .min(2, "Name En must be more than 1 characters")
    .max(100, "name En must be less than 100 characters"),
  iata: yup
    .string()
    .min(2, "IATA must be more than 1 characters")
    .max(100, "IATA must be less than 100 characters"),
  icao: yup
    .string()
    .min(2, "ICAO must be more than 1 characters")
    .max(100, "ICAO must be less than 100 characters"),
  created__gte: yup
    .date(DateTime.fromISO().toISO())
    .max(new Date(), "Date cannot be in the future"),
  created__lte: yup
    .date(DateTime.fromISO().toISO())
    .max(new Date(), "Date cannot be in the future"),
});
const statusMapping = {
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const airportsStyles = createUseStyles({
  Airport: genericIndexesStyles.indexes.mobile(),
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile(),
  checkBox: tableStyles.checkBox,
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      ...genericIndexesStyles.actionCell.smallscreen(),
      gridTemplateColumns: "1fr",
    },
    cell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});
const Airports = () => {
  const classes = airportsStyles();
  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [airportId, setAirportId] = useState([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const tableHeaders = {
    "Name En": (val) => _.capitalize(val.name_en),
    IATA: (val) => val.iata,
    ICAO: (val) => val.icao,
    "UTC Offset": (val) =>
      _.get(val, "utc_offset", 0) === 0 ? "0" : _.get(val, "utc_offset"),
    Country: (airport) => _.get(countries, _.get(airport, "country_code")),
    Created: (val) => val.created,
    Status: (airport) => _.get(statusMapping, _.get(airport, "status")),
  };

  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["airportsList", filters],
    queryFn: () => {
      let correctPayload = { ...filters };
      if (filters.created__lte) {
        correctPayload["created__lte"] = DateTime.fromISO(
          filters.created__lte
        ).toISO();
      }
      if (filters.created__gte) {
        correctPayload["created__gte"] = DateTime.fromISO(
          filters.created__gte
        ).toISO();
      }
      return fetchAirports({
        params: correctPayload,
      });
    },

    enabled: !showDetailsModal && !showEditModal,
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  return (
    <div id="AirportAPIConfigurationsListPageb" className={classes.Airport}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setAirportId(null);
          setShowEditModal(true);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.limit}
        page={filters.page}
        count={_.get(data, "data.count", 0)}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th key={idx} className={classes.headCell}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.get(data, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.cell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "created":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      break;
                    default:
                      cell = fn(val) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.cell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setAirportId(val.id);
                      setShowDetailsModal(true);
                    }}>
                    <strong>Details</strong>
                  </CustomButton>
                  <CustomButton
                    id={`EditAirportButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setAirportId(val.id);
                      setShowEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDetailsModal && (
        <DetailAirportModal
          id={airportId}
          onClose={() => setShowDetailsModal(false)}
        />
      )}
      {showEditModal && (
        <EditAirportModal
          airportId={airportId}
          onClose={() => {
            setAirportId(null);
            setShowEditModal(false);
          }}
        />
      )}
    </div>
  );
};
export default Airports;
