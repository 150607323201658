import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchMasterPolicies({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/policies/master/`, {
    params,
  });
}

export async function retrieveMasterPolicy({ id }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crm/policies/master/${id}`
  );
}

export async function patchMasterPolicy({ id, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crm/policies/master/${id}`,
    payload
  );
}
