import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../components/cards/GenericBasicInfo";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import { retrieveAccommodatorAPIConfiguration } from "../../../../api/AccEngine/APIConfigurations";
import { genericRequestErrorHandler } from "../../../../api";
import Loader from "../../../../components/Loader";
import { AccEnginecommonTrueFalseMapping } from "../../Common";
import { CustomButton } from "../../../../components/CustomButton";
import TabStrip from "../../../../components/TabStrip";
import AccEngineConfigLogsTab from "./AccEngineLogsTab";
import ReservationsStatusTag from "../../../../components/Tags/ReservationsStatusTag";

const styles = createUseStyles({
  DetailAccEngineAPIConfigurationModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardBody: { ...cardStyles.cardBody },
  firstRow: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  secondRow: {
    display: "grid",
  },
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    alignContent: "start",
    // overflow: "auto",
  },
  [`@media ${variables.media.smallscreen}`]: {
    firstRow: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
});

const DetailAccEngineAPIConfigurationModal = ({ uid, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicData");

  const menuTabs = {
    basicData: "Basic Data",
    logs: "Logs",
  };

  const { data: retrievedAccEngineConfig, isLoading: loading } = useQuery({
    queryKey: ["DetailAccEngineModal", uid],
    queryFn: async () => {
      return retrieveAccommodatorAPIConfiguration({ uid: uid });
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const basicDataCpl = [
    ["Uid", "uid"],

    [
      "Name",
      null,
      _.capitalize(_.get(retrievedAccEngineConfig, "data.name")) || "N/A",
    ],
    [
      "Supplier",
      null,
      _.capitalize(_.get(retrievedAccEngineConfig, "data.supplier", "")) ||
        "N/A",
    ],
    ["Source Entity", "source_entity"],
    ["Market Type", "market_type"],
    ["API Curency", "api_currency"],
    [
      "Is Live",
      null,
      _.get(
        AccEnginecommonTrueFalseMapping,
        _.get(retrievedAccEngineConfig, "data.is_live")
      ) || "N/A",
    ],
    [
      "Is Global",
      null,
      _.get(
        AccEnginecommonTrueFalseMapping,
        _.get(retrievedAccEngineConfig, "data.global_account")
      ) || "N/A",
    ],
    [
      "is Own",
      null,
      _.get(
        AccEnginecommonTrueFalseMapping,
        _.get(retrievedAccEngineConfig, "data.is_own")
      ) || "N/A",
    ],
    [
      "Is Bar Only",
      null,
      _.get(
        AccEnginecommonTrueFalseMapping,
        _.get(retrievedAccEngineConfig, "data.is_bar_only")
      ) || "N/A",
    ],
    [
      "Is Master Account",
      null,
      _.get(
        AccEnginecommonTrueFalseMapping,
        _.get(retrievedAccEngineConfig, "data.is_master_account")
      ) || "N/A",
    ],
    [
      "With Side Bar",
      null,
      _.get(
        AccEnginecommonTrueFalseMapping,
        _.get(retrievedAccEngineConfig, "data.with_side_bar")
      ) || "N/A",
    ],

    ["Website API Key", "website_api_key"],
    [
      "Status",
      null,
      <ReservationsStatusTag
        status={_.get(retrievedAccEngineConfig, "data.status")}
      />,
    ],
  ];

  const creatorData = [
    [
      "Created",
      null,
      DateTime.fromISO(
        _.get(retrievedAccEngineConfig, "data.created")
      ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) || "N/A",
    ],
    ["Creator", "creator"],
  ];
  const accessData = [
    [
      "Username",
      null,
      _.capitalize(_.get(retrievedAccEngineConfig, "data.username")) || "N/A",
    ],
    ["Password", "password"],
    ["Hotelbeds API Key", "hotelbeds_api_key"],
    ["Hotelbeds Secret", "hotelbeds_secret"],
    ["Mishor Access Token", "mishor_access_token"],
    ["Mishor Application Key", "mishor_application_key"],
    ["Mishor Static Key", "mishor_static_key"],
  ];
  const editorData = [
    ["Last Editor", "last_editor"],
    [
      "Edited",
      null,
      DateTime.fromISO(
        _.get(retrievedAccEngineConfig, "data.edited")
      ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) || "N/A",
    ],
  ];

  return (
    <div
      id="detailAccEngineAPIConfigurationsModal"
      className={classes.DetailAccEngineAPIConfigurationModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Accommodation API Configuration Details</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicData" && (
                <React.Fragment>
                  <div className={classes.firstRow}>
                    <div className={classes.card}>
                      <div className={classes.cardBody}>
                        <GenericBasicInfo
                          header={"Basic Data"}
                          cardInfo={basicDataCpl}
                          objectData={_.get(
                            retrievedAccEngineConfig,
                            "data",
                            {}
                          )}
                        />
                      </div>
                    </div>
                    <div className={classes.card}>
                      <div className={classes.cardBody}>
                        <GenericBasicInfo
                          header={"Creator Data"}
                          cardInfo={creatorData}
                          objectData={_.get(
                            retrievedAccEngineConfig,
                            "data",
                            {}
                          )}
                        />
                        <GenericBasicInfo
                          header={"Editor Data"}
                          cardInfo={editorData}
                          objectData={_.get(
                            retrievedAccEngineConfig,
                            "data",
                            {}
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.card}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header="API Credentials"
                        cardInfo={accessData}
                        objectData={_.get(retrievedAccEngineConfig, "data", {})}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activeTab === "logs" && <AccEngineConfigLogsTab />}
            </React.Fragment>
          )}
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton
            id="cancel"
            appearance="ghost"
            text="Cancel"
            onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailAccEngineAPIConfigurationModal.propTypes = {
  uid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DetailAccEngineAPIConfigurationModal;
