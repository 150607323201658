import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export async function fetchAccReservations({ params }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/accommodation/`,
    {
      params,
    }
  );
}
export async function patchAccReservation({ reference, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/accommodation/${reference}/`,
    payload
  );
}

export function getAccommodationReservationStats({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/accommodation/stats/`,
    { params }
  );
}

export function retrieveAccReservation({ reference }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/accommodation/${reference}/`
  );
}
