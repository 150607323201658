import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../../variables";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { retrieveMasterPolicy } from "../../../../../../api/CRM/MasterPolicies";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../../api";
import GenericBasicInfo from "../../../../../../components/cards/GenericBasicInfo";
import Loader from "../../../../../../components/Loader";
import { CustomButton } from "../../../../../../components/CustomButton";
import MasterPoliciesLogTab from "./MasterPoliciesLogTab";
import TabStrip from "../../../../../../components/TabStrip";

const trueFalseMapping = {
  true: "Yes",
  false: "No",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  MasterPolicyDetailModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  row: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardBody: { justifyContent: "center" },
    row: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
});

const MasterPolicyDetailModal = ({ masterPolicyId, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicData");

  const menuTabs = {
    basicData: "Basic Data",
    logs: "Logs",
  };

  const { data, isLoading: isFetchLoading } = useQuery({
    queryKey: ["MasterPolicyDetailModal"],
    queryFn: () =>
      retrieveMasterPolicy({
        id: masterPolicyId,
      }),
    onError: (error) => {
      genericRequestErrorHandler(error);
      onClose();
    },
  });

  const createdDataCpl = [
    [
      "Created",
      null,
      DateTime.fromISO(_.get(data, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    ["Creator Username", null, _.get(data, "data.creator_info.username", "")],
    [
      "Creator First Name",
      null,
      _.get(data, "data.creator_info.first_name", ""),
    ],
    ["Creator Last Name", null, _.get(data, "data.creator_info.last_name", "")],
    ["Creator Email", null, _.get(data, "data.creator_info.email", "")],
  ];

  const travelServiceType = _.get(
    data,
    "data.travel_services_policies.amount_type",
    ""
  );
  const travelServiceId = _.get(data, "data.travel_services_policies.id", "");

  const displayText =
    travelServiceType === "num"
      ? `${travelServiceId} Number: ${travelServiceType}`
      : travelServiceType === "perc"
      ? `${travelServiceId} Percentage: ${travelServiceType} %`
      : "N/A";

  const editTravelServices = [["Travel Service", null, displayText]];

  const editDataCpl = [
    [
      "Edited",
      null,
      DateTime.fromISO(_.get(data, "data.edited")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    [
      "Last Editor Username",
      null,
      _.get(data, "data.last_editor_info.username", ""),
    ],
    [
      "Last Editor First Name",
      null,
      _.get(data, "data.last_editor_info.first_name", ""),
    ],
    [
      "Last Editor Last Name",
      null,
      _.get(data, "data.last_editor_info.last_name", ""),
    ],
    ["Last Editor email", null, _.get(data, "data.last_editor_info.email", "")],
  ];

  const basicDataCpl = [
    ["Id", "id"],
    ["Name", null, _.capitalize(_.get(data, "data.name", ""))],
    ["Description", "description"],
    ["Members", null, _.get(data, "data.members", []).join(",")],
    [
      "Is Global",
      null,
      _.get(trueFalseMapping.seMapping, _.get(data, "data.is_global")),
    ],
    ["Pax From", "pax_from"],
    ["Pax To", "pax_to"],
    ["Rounding Digits", "rounding_digits"],
    [
      "First lvl Places",
      null,
      _.get(data, "data.firstlvl_places", []).join(","),
    ],
    [
      "Second lvl Places",
      null,
      _.get(data, "data.secondlvl_places", []).join(","),
    ],

    ["Status", "status_dispay"],
  ];

  const servicesPeriod = [
    [
      "Service Execution From",
      null,
      DateTime.fromISO(
        _.get(data, "data.service_execution_from")
      ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
    ],
    [
      "Service Execution To",
      null,
      DateTime.fromISO(_.get(data, "data.service_execution_to")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    [
      "Booking Window From",
      null,
      DateTime.fromISO(_.get(data, "data.booking_window_from")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    [
      "Booking Window To",
      null,
      DateTime.fromISO(_.get(data, "data.booking_window_to")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];

  return (
    <div className={classes.MasterPolicyDetailModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Master Policy Details</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {isFetchLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicData" && (
                <React.Fragment>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Basic Data"}
                        cardInfo={basicDataCpl}
                        objectData={_.get(data, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Creator Data"}
                        cardInfo={createdDataCpl}
                        objectData={_.get(data, "data", {})}
                      />
                      <GenericBasicInfo
                        header={"Editor Data"}
                        cardInfo={editDataCpl}
                        objectData={_.get(data, "data", {})}
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header="API Credentials"
                        cardInfo={servicesPeriod}
                        objectData={_.get(data, "data", {})}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activeTab === "logs" && <MasterPoliciesLogTab />}
            </React.Fragment>
          )}
        </div>
        <div className={classes.cardActions}>
          <CustomButton appearance="ghost" onClick={() => onClose()}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
MasterPolicyDetailModal.propTypes = {
  masterPolicyId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MasterPolicyDetailModal;
