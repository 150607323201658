import _, { set } from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import {
  cloneTransfer,
  fetchTransferAPIConfigurations,
} from "../../../api/TransfersEngine/TransferAPIConfigurations";
import ControlStrip from "../../../components/ControlStrip";
import Loader from "../../../components/Loader";
import {
  currencies,
  handleApplyFilters,
  medWeekdayDatetimer,
} from "../../../helpers";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../variables";
import Navbar from "./Navbar";
import DetailTransferAPIConfigurationsModal from "../transfersAPIConfigurationsModals/DetailTransferAPIConfigurationsModal";
import EditTransferAPIConfigurationsModal from "../transfersAPIConfigurationsModals/EditTransferAPIConfigurationsModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HamburgerMenu from "../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../hooks";
import * as yup from "yup";
import DeleteTransferAPIConfigurationsModal from "../transfersAPIConfigurationsModals/DeleteTransferAPIConfigurationsModal";
import { genericRequestErrorHandler } from "../../../api";
import { transferEngineTrueFalseMapping } from "../common.js";
import { CustomButton } from "../../../components/CustomButton/index.js";
import VerifyModal from "../../../components/VerifyModal.js";
import ReservationsStatusTag from "../../../components/Tags/ReservationsStatusTag.js";

const initialFilters = {
  page_size: 20,
  page: 1,
  ordering: "",
  discending: "",
  name__icontains: "",
  creator__icontains: "",
  global_account: "",
  is_live: "",
  supplier: "",
  created__gte: "",
  created__lte: "",
  source_entity: "",
};
const navbarSchema = yup.object().shape({
  name__icontains: yup
    .string()
    .min(2, "Name must be more than 1 characters")
    .max(100, "name must be less than 100 characters"),
  created__gte: yup.date().max(new Date(), "Date cannot be in the future"),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const APIConfigurationStyles = createUseStyles({
  APIConfiguration: genericIndexesStyles.indexes.mobile(),
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
  checkBox: tableStyles.checkBox,
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      gridTemplateColumns: "repeat(2,1fr) ",
      padding: `calc(${variables.normal_gap} * 2.5) 0!important`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const TransfersAPIConfigurations = () => {
  const classes = APIConfigurationStyles();

  const queryClient = useQueryClient();
  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [apiConfigurationUid, setApiConfigurationUid] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [transfer, setTransfer] = useState({});

  const tableHeaders = {
    Name: (val) => _.capitalize(_.get(val, "name")),
    Supplier: (val) => _.capitalize(_.get(val, "supplier")),
    "Source Entity": (val) => val.source_entity,
    "Market type": (val) => val.market_type,
    Currency: (val) => _.get(currencies, _.get(val, "currency")),
    "Is Live": (val) =>
      _.get(transferEngineTrueFalseMapping, _.get(val, "is_live")),
    "Global Account": (val) =>
      _.get(transferEngineTrueFalseMapping, _.get(val, "global_account")),
    Created: (val) => val.created,
    Status: (val) => <ReservationsStatusTag status={val.status} />,
  };
  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data, isLoading: loading } = useQuery(
    ["TransfersAPIConfigurations", filters],
    () =>
      fetchTransferAPIConfigurations({
        params: { ...filters, page_size: filters.page_size },
      }),
    {
      enabled: !showDetailModal && !showEditModal,
    }
  );

  const cloneTransferApiMutation = useMutation({
    mutationFn: async (uid) => await cloneTransfer({ uid }),
    onSuccess: () => {
      toast.success("Cloned Transfer Api Configuration added");
      setShowVerifyModal(false);
      queryClient.invalidateQueries("TransfersAPIConfigurations");
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });
  const onClone = (uid) => {
    setApiConfigurationUid(uid);
  };

  return (
    <div
      id="TRFEngineAPIConfigurationsListPage"
      className={classes.APIConfiguration}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setShowEditModal(true);
          setApiConfigurationUid(null);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.page_size}
        page={filters.page}
        count={_.get(data, "data.count", 0)}
        onChangeLimit={(page_size) =>
          setFilters({ ...filters, page_size, page: 1 })
        }
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                {Object.keys(tableHeaders).map((value, idx) => (
                  <th key={idx} className={classes.headCell}>
                    {value}
                  </th>
                ))}
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.get(data, "data.results", []).map((val, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  {Object.entries(tableHeaders).map((entry, ridx) => {
                    var cell = null;
                    const fn = entry[1];
                    switch (entry[0].toLowerCase()) {
                      case "created":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        break;
                      default:
                        cell = fn(val) || "N/A";
                        break;
                    }
                    return (
                      <td className={classes.tableCell} key={ridx}>
                        {cell}
                      </td>
                    );
                  })}
                  <td className={classes.actionCell}>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        setApiConfigurationUid(val.uid);
                        setShowDetailModal(true);
                      }}>
                      <strong>Details</strong>
                    </CustomButton>
                    <CustomButton
                      id={`EditTRFEngineButton_${idx}`}
                      appearance="ghost"
                      onClick={() => {
                        setApiConfigurationUid(val.uid);
                        setShowEditModal(true);
                      }}>
                      <strong>Edit</strong>
                    </CustomButton>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        onClone(val.uid);
                        setShowVerifyModal(true);
                      }}>
                      <strong>Clone</strong>
                    </CustomButton>
                    <CustomButton
                      id={`DeleteTRFEngineButton_${idx}`}
                      appearance="ghost"
                      onClick={() => {
                        setTransfer(val);
                        setApiConfigurationUid(val.uid);
                        setShowDeleteModal(true);
                      }}>
                      <strong>Delete</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showDetailModal && (
        <DetailTransferAPIConfigurationsModal
          uid={apiConfigurationUid}
          onClose={() => setShowDetailModal(false)}
        />
      )}
      {showEditModal && (
        <EditTransferAPIConfigurationsModal
          ApiConfigurationId={apiConfigurationUid}
          onClose={() => {
            setShowEditModal(false);
            setApiConfigurationUid(null);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteTransferAPIConfigurationsModal
          transfer={transfer}
          deletedUid={apiConfigurationUid}
          onClose={() => {
            setApiConfigurationUid(null);
            setShowDeleteModal(false);
          }}
        />
      )}
      {showVerifyModal && (
        <VerifyModal
          // title=""
          // message=""
          isLoading={cloneTransferApiMutation.isLoading}
          onConfirm={() => {
            cloneTransferApiMutation.mutate(apiConfigurationUid);
          }}
          onClose={() => setShowVerifyModal(false)}
        />
      )}
    </div>
  );
};
export default TransfersAPIConfigurations;
