import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export async function fetchUsers({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/users/`, {
    params,
  });
}

export function retrieveUser({ id }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/users/${id}`);
}

export async function patchUser({ id, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crm/users/${id}`,
    payload
  );
}

export async function createNewUser({ payload }) {
  return await loggingAxios.post(`${VALAQUENTA_API_URL}/crm/users/`, payload);
}

export function newGetGroups() {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/groups/`);
}

export async function getAppUserGroups(params) {
  return await authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/appuser-groups`, {
    params,
  });
}

export async function patchAppUserGroup(id, params) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crm/appuser-groups/${id}/`,
    params
  );
}

export async function createAppUserGroup(params) {
  return await loggingAxios.post(
    `${VALAQUENTA_API_URL}/crm/appuser-groups/`,
    params
  );
}

export async function deleteAppUserGroup(id) {
  return await loggingAxios.delete(
    `${VALAQUENTA_API_URL}/crm/appuser-groups/${id}/`
  );
}
