import { VALAQUENTA_API_URL } from "../../..";
import { authorizedAxios, loggingAxios } from "../../../../App";

export function fetchWebSiteHotelPriority({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/accommodator/black-list/`, {
    params,
  });
}

export async function retrieveWebSiteHotelPriority({ id }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/accommodator/black-list/${id}/`
  );
}

export function cloneWebSiteHotelPriority({ payload }) {
  return loggingAxios.put(
    `${VALAQUENTA_API_URL}/accommodator/black-list/clone-black-list/`,
    payload
  );
}

export function createWebSiteHotelPriority({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/accommodator/black-list/`,
    payload
  );
}

export async function deleteWebSiteHotelPriority({ id, params }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/accommodator/black-list/${id}/`,
    {
      params,
    }
  );
}

export function fetchWebSiteHotelPriorityAutocomplete({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/accommodator/autocomplete/`,
    {
      params,
    }
  );
}
