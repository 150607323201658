import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  formItemStyles,
  modalGenericStyles,
  variables,
  tableStyles,
} from "../../../../variables";
import PropTypes from "prop-types";
// import { memberInvoice } from "../../api/CRM";
import { memberInvoice } from "../../../../api/CRM/Members/Members";
import { toast } from "react-toastify";
import { Button } from "../../../../components/Button";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";

const styles = createUseStyles({
  ...modalGenericStyles,
  InvoiceMemberModal: modalGenericStyles.modal,
  cardActions: {
    ...modalGenericStyles.cardActions,
  },
  form: {
    display: "grid",
    gap: variables.half_gap,
    padding: variables.half_gap,
  },
  formItem: formItemStyles,
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  tableCell: tableStyles.body.cell,
});

const marketType = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};

const InvoiceMemberModal = ({ members, onClose }) => {
  const classes = styles();

  const [memberForm, setMemberForm] = useState({
    members_ids: members.map((member) => member.id),
    date_from: "",
    date_to: "",
    market_type: "",
  });
  const [loading, setLoading] = useState(false);

  const tableHeaders = {
    Id: (val) => val.id,
    Name: (val) => val.name,
  };

  // const { isLoading } = useQuery({
  //   queryKey: ["InvoiceMemberModal"],
  //   queryFn: async () =>
  //     await memberInvoice({
  //       payload: {
  //         ...memberForm,
  //       },
  //     }),
  //   onSuccess: (data) => {
  //     setMemberForm(_.get(data, "data"));
  //   },
  //   onError: (error) => {
  //     genericRequestErrorHandler(error);
  //   },
  // });
  // const onSubmit =  (e) => {
  //   e.preventDefault();
  //   mutation.mutate({ payload: {
  //     ...memberForm,
  //   } });
  // }

  const onSubmit = async (payload) => {
    const results = await memberInvoice({
      payload: {
        ...memberForm,
      },
    });
    if (results) {
      toast.success("Invoices created.");
      setLoading(false);
    }
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className={classes.InvoiceMemberModal}>
      {loading ? (
        <div>Loading</div>
      ) : (
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <span>Invoice</span>
          </div>
          <div>
            <table className={classes.table}>
              <thead className={classes.thead}>
                <tr className={classes.headRow}>
                  {Object.keys(tableHeaders).map((value, idx) => (
                    <th key={idx}>{value}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {members.map((val, idx) => (
                  <tr key={idx}>
                    {Object.entries(tableHeaders).map((entry, ridx) => {
                      var cell = null;
                      const fn = entry[1];
                      switch (entry[0].toLowerCase()) {
                        default:
                          cell = fn(val) || "N/A";
                          break;
                      }
                      return (
                        <td className={classes.tableCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={classes.cardBody}>
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.formItem}>
                <label>From</label>
                <input
                  type="date"
                  value={memberForm.date_from}
                  onChange={(e) =>
                    setMemberForm((p) => ({
                      ...p,
                      date_from: e.target.value,
                    }))
                  }
                />
              </div>
              <div className={classes.formItem}>
                <label>To</label>
                <input
                  type="date"
                  value={memberForm.date_to}
                  onChange={(e) =>
                    setMemberForm((p) => ({ ...p, date_to: e.target.value }))
                  }
                />
              </div>
              <div className={classes.formItem}>
                <label>Market Type</label>
                <select
                  value={memberForm.market_type}
                  onChange={(e) =>
                    setMemberForm((p) => ({
                      ...p,
                      market_type: e.target.value,
                    }))
                  }
                >
                  {Object.entries(marketType).map(([value, label], idx) => (
                    <option key={idx} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
          <div className={classes.cardActions}>
            <Button text="Cancel" onClick={onClose} />
            <Button text="Submit" onClick={onSubmit} />
          </div>
        </div>
      )}
    </div>
  );
};

InvoiceMemberModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
};

export default InvoiceMemberModal;
