import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { modalGenericStyles, variables } from "../../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import Loader from "../../../../../components/Loader/index";
import { deleteWebSiteMainPromos } from "../../../../../api/DistributionSite/webSites/MainPromos";
import { genericRequestErrorHandler } from "../../../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CustomButton } from "../../../../../components/CustomButton";

const basicDataCpl = [
  ["Id", "id"],
  ["Name", "name"],
];

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteMainPromo: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
  },
});
const DeleteMainPromo = ({ id, data, onClose }) => {
  const classes = styles();

  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: ({ id }) =>
      deleteWebSiteMainPromos({
        id: id,
      }),
    onSuccess: () => {
      toast.success("Main Promo Page Deleted");
      queryClient.invalidateQueries("MainPromosList");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const onDelete = (id) => {
    deleteMutation.mutate({ id });
  };

  return (
    <div className={classes.DeleteMainPromo}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <span>DELETE Main Promo</span>
        </div>
        <div className={classes.cardBody}>
          <div className={classes.basicInfo}>
            <GenericBasicInfo
              header={"Are you sure you want to delete this?"}
              cardInfo={basicDataCpl}
              objectData={data}
            />
          </div>
        </div>
        <div className={classes.cardActions}>
          <CustomButton appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton appearance="primary" onClick={() => onDelete(id)}>
            <strong>DELETE</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DeleteMainPromo.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};
export default DeleteMainPromo;
