import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  tableStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  createNewUser,
  patchUser,
  retrieveUser,
} from "../../../../../api/CRM/Users/Users";
import _ from "lodash";
import Loader from "../../../../../components/Loader";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { handleValidationErrors } from "../../../../../helpers";
import { CustomButton } from "../../../../../components/CustomButton";
// import { fetchTravelServices } from "../../../../../api/CRM/TravelServices";

const jsonSchema = yup.object().shape({
  user: yup.object().shape({
    first_name: yup.string().nullable(),
    last_name: yup.string().nullable(),
  }),
  status: yup.string().nullable().default(""),
  email: yup.string().email("Invalid email address").nullable().default(""),
  mobile_phone: yup
    .string()
    .transform((value) => (value === "" ? undefined : value))
    .max(20, "Cannot be greater than 20 digits")
    .nullable()
    .default(""),
  tags: yup.array().of(yup.string()).default([""]),
  phone: yup
    .string()
    .transform((value) => (value === "" ? undefined : value))
    .max(20, "Cannot be greater than 20 digits")
    .nullable()
    .default(""),
});

const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
};

// const departmentMapping = {
//   "": "-----",
//   AD: "Administration",
//   FI: "Financial",
//   MA: "Marketing",
//   OP: "Operation",
//   RE: "Reservation",
// };
// const travelServiceMap = {
//   FL: "Flight",
//   ACC: "Accommodation",
//   TRF: "Transfers",
//   MI: "Addon/Tour/Static Package",
//   COA: "Coach",
//   TRA: "Train",
//   FER: "Ferries",
//   GEN: "General Services",
//   REST: "Restaurant",
//   ACT: "Activities",
// };

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditUserModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: cardStyles.body,
  inputGroup: formInputGroup,
  serviceTypesContainer: {
    extend: "cardBody",
    gridAutoFlow: "column",
    justifyContent: "space-around",
  },
  serviceTypeContainer: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.normal_gap,
  },
  workDutiesCardBody: {
    extend: "cardBody",
    gridTemplateRows: "repeat(2, 1fr)",
    gap: variables.double_gap,
  },
  checkBox: tableStyles.checkBox,
  expertiseTypeContainer: {
    display: "grid",
  },
  travelSectorContainer: {
    display: "grid",
  },
  expertiseType: {
    display: "grid",
  },
  travel_sector: {
    display: "grid",
  },
  tagscardBody: {
    ...cardStyles.body,
    display: "grid",
    gridTemplateColumns: "1fr max-content",
  },
  tagsActions: {
    ...modalGenericStyles.cardActions,
    justifyContent: "start",
    height: "1.5rem",
    alignSelf: "end",
  },
  tags: {
    ...modalGenericStyles.card,
    display: "grid",
    gridColumn: "span 2",
    lineHeight: "5vh",
  },
  [`@media ${variables.media.smallscreen}`]: {
    card: {
      minWidth: "50rem",
    },
    cardCol2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    cardCol4: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
  },
});
const EditUserModal = ({ userId, onClose }) => {
  const classes = styles();
  const mode = userId ? "edit" : "add";

  const [form, setForm] = useState(jsonSchema.cast({}));
  // const [form, setForm] = useState(jsonSchema.cast({}));

  const queryClient = useQueryClient();

  const { data, isLoading: retrieveLoading } = useQuery({
    queryKey: ["editUser", userId],
    queryFn: () => retrieveUser({ id: userId }),
    enabled: mode === "edit",
    onSuccess: (data) => {
      const tags =
        (data?.data?.tags ?? []).length === 0 ? [""] : data.data.tags;
      setForm(jsonSchema.cast({ ..._.get(data, "data", {}), tags }));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  // const { data: tServices, tServicesLoading } = useQuery({
  //   queryKey: ["userTServices"],
  //   queryFn: async () => await fetchTravelServices(),
  //   enabled: true,
  //   onError: (error) => {
  //     genericRequestErrorHandler(error);
  //   },
  // });

  const editMutation = useMutation({
    mutationFn: async ({ id, payload }) => {
      return patchUser({ id, payload });
    },
    onSuccess: () => {
      toast.success("User Info Updated");
      queryClient.invalidateQueries("Users");
      onClose();
    },
  });

  const addMutation = useMutation({
    mutationFn: async ({ payload }) => {
      return createNewUser({
        payload,
      });
    },
    onSuccess: () => {
      toast.success("New User added");
      queryClient.invalidateQueries("Users");
      onClose();
    },
  });

  const onSubmit = async ({ id, payload }) => {
    let formData = { ...form };
    const user = {
      first_name: _.get(formData, "user.first_name"),
      last_name: _.get(formData, "user.last_name"),
    };
    delete formData["user.first_name"];
    delete formData["user.last_name"];
    try {
      const filteredTags = form.tags.filter((tag) => tag !== "");
      await jsonSchema.validate(form, { abortEarly: false });

      if (mode === "add") {
        addMutation.mutate({ payload: { ...payload, tags: filteredTags } });
      } else {
        editMutation.mutate({
          id,
          payload: { ...formData, tags: filteredTags, user: user },
        });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  // const handleTravelServicesChange = (e, serviceId) => {
  //   const { value } = e.target;
  //   if (value === "YES") {
  //     if (!form.travel_services.includes(serviceId)) {
  //       setForm((p) => ({
  //         ...p,
  //         travel_services: [...p.travel_services, serviceId],
  //       }));
  //     }
  //     return;
  //   }

  //   const result = form.travel_services.filter((item) => item !== serviceId);
  //   setForm((p) => ({
  //     ...p,
  //     travel_services: result,
  //   }));
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  // const handleCheckboxChange = (e, key) => {
  //   const { value, checked } = e.target;

  //   setForm((p) => {
  //     const updatedArray = checked
  //       ? [...p[key], value]
  //       : p[key].filter((item) => item !== value);

  //     return {
  //       ...p,
  //       [key]: updatedArray,
  //     };
  //   });
  // };

  // const servicesTypes = _.get(
  //   retrievedData,
  //   "data.travel_services_display",
  //   []
  // );
  // var servicesTypesMapping = {};
  // _.get(retrievedData, "data.travel_services_display", []).forEach(
  //   (item) =>
  //     (servicesTypesMapping[item.service_type] = item.brief_description_en)
  // );

  const loading =
    (mode === "edit" && retrieveLoading) ||
    // tServicesLoading ||
    addMutation.isLoading ||
    editMutation.isLoading;
  return (
    <div id="EditUserModal" className={classes.EditUserModal}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} User</span>
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} User</span>
          </div>
          <div className={classes.modalCardBody}>
            <form>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="first_name">First Name</label>
                      <input
                        id="first_name"
                        type="text"
                        name="user.first_name"
                        value={_.get(form, "user.first_name", "")}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="last_name">Last Name</label>
                      <input
                        id="last_name"
                        type="text"
                        name="user.last_name"
                        value={_.get(form, "user.last_name", "")}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="status">Status</label>
                      <select
                        id="status"
                        name="status"
                        value={form.status}
                        onChange={handleInputChange}>
                        {Object.entries(statusMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Contact Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="email">Account Email</label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        value={form.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="mobile_phone">Mobile Phone</label>
                      <input
                        id="mobile_phone"
                        type="text"
                        name="mobile_phone"
                        value={form.mobile_phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="phone">Phone</label>
                      <input
                        id="phone"
                        type="text"
                        name="phone"
                        value={form.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Tags</div>
                <div className={classes.tagscardBody}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="tags">Tag</label>
                    <input
                      type="text"
                      value={_.get(form, "tags.0", "")}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          tags: [
                            ...form.tags.map((tag, idx) =>
                              idx === 0 ? e.target.value : tag
                            ),
                          ],
                        });
                      }}
                    />
                  </div>
                  <div className={classes.tagsActions}>
                    {(_.get(form, "tags", []).length !== 1 ||
                      _.get(form, "tags.0", "")) !== "" && (
                      <CustomButton
                        appearance="ghost"
                        onClick={(e) => {
                          e.preventDefault();
                          if (_.get(form, "tags", []).length > 1) {
                            let newTags = form.tags;
                            newTags.splice(0, 1);
                            setForm({ tags: [...newTags] });
                            return;
                          }
                          setForm({ ...form, tags: [""] });
                        }}>
                        <strong>Remove</strong>
                      </CustomButton>
                    )}

                    {_.get(form, "tags", [""]).length === 1 && (
                      <CustomButton
                        appearance="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({ tags: [...form.tags, ...[""]] });
                        }}>
                        <strong>Add Tag</strong>
                      </CustomButton>
                    )}
                  </div>
                </div>
              </div>
              {_.get(form, "tags").map(
                (tag, tagIdx) =>
                  tagIdx > 0 && (
                    <React.Fragment>
                      <div className={classes.card}>
                        <div className={classes.tagscardBody}>
                          <div className={classes.inputGroup}>
                            <label htmlFor="tags">Tag {tagIdx + 1}</label>
                            <input
                              id={`tags_${tagIdx}`}
                              type="text"
                              value={_.get(form, `tags.${tagIdx}`, "")}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  tags: [
                                    ...form.tags.map((tag, idx) =>
                                      idx === tagIdx ? e.target.value : tag
                                    ),
                                  ],
                                });
                              }}
                            />
                          </div>
                          <div className={classes.tagsActions}>
                            <CustomButton
                              id={`removeTagButton_${tagIdx}`}
                              appearance="ghost"
                              onClick={(e) => {
                                e.preventDefault();
                                if (_.get(form, "tags", []).length > 1) {
                                  let newTags = form.tags;
                                  newTags.splice(tagIdx, 1);
                                  setForm({ ...form, tags: [...newTags] });
                                  return;
                                }
                                setForm({ ...form, tags: [""] });
                              }}>
                              <strong>Remove</strong>
                            </CustomButton>
                            {_.get(form, "tags", []).length === tagIdx + 1 && (
                              <CustomButton
                                id={`addTagButton_${tagIdx}`}
                                appearance="primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setForm({
                                    ...form,
                                    tags: [...form.tags, ...[""]],
                                  });
                                }}>
                                <strong>Add Tag</strong>
                              </CustomButton>
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )
              )}
            </form>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  id: userId,
                  payload: { ...form },
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditUserModal.propTypes = {
  userId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditUserModal;
