import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import { statusMapping } from "../../common";
import Loader from "../../../../../components/Loader";
import {
  createWebSiteListPage,
  patchWebSiteListPage,
  retrieveWebSiteListPage,
} from "../../../../../api/DistributionSite/webSites/listPages";
import _ from "lodash";
import { DateTime } from "luxon";
import QuilEditor from "../../../../../components/QuilEditor";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import {
  convertStringToBoolean,
  handleValidationErrors,
  websiteLanguages as wLanguages,
} from "../../../../../helpers";

import * as yup from "yup";
import WebsitesLanguages from "../../Components/WebsitesLanguages";
import TranslateButton from "../../Components/TranslateButton";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  path: yup
    .string()
    .test("no-space", "Path must not contain spaces", (value) => {
      if (value && value.includes(" ")) {
        return false;
      }
      return true;
    }),

  name: yup.string().required("This field is required").default(""),
  theme: yup.string().nullable().default(""),
  title: yup.string().nullable().required().default(""),
  title_de: yup.string().nullable().default(""),
  title_el: yup.string().nullable().default(""),
  title_es: yup.string().nullable().default(""),
  title_fr: yup.string().nullable().default(""),
  title_it: yup.string().nullable().default(""),
  title_ja: yup.string().nullable().default(""),
  title_nl: yup.string().nullable().default(""),
  title_pt: yup.string().nullable().default(""),
  title_th: yup.string().nullable().default(""),
  title_zh: yup.string().nullable().default(""),
  title_lt: yup.string().nullable().default(""),
  subtitle: yup.string().nullable().default(""),
  subtitle_de: yup.string().nullable().default(""),
  subtitle_el: yup.string().nullable().default(""),
  subtitle_es: yup.string().nullable().default(""),
  subtitle_fr: yup.string().nullable().default(""),
  subtitle_it: yup.string().nullable().default(""),
  subtitle_ja: yup.string().nullable().default(""),
  subtitle_nl: yup.string().nullable().default(""),
  subtitle_pt: yup.string().nullable().default(""),
  subtitle_th: yup.string().nullable().default(""),
  subtitle_zh: yup.string().nullable().default(""),
  subtitle_lt: yup.string().nullable().default(""),
  description: yup.string().nullable().default(""),
  description_de: yup.string().nullable().default(""),
  description_el: yup.string().nullable().default(""),
  description_es: yup.string().nullable().default(""),
  description_fr: yup.string().nullable().default(""),
  description_it: yup.string().nullable().default(""),
  description_ja: yup.string().nullable().default(""),
  description_nl: yup.string().nullable().default(""),
  description_pt: yup.string().nullable().default(""),
  description_th: yup.string().nullable().default(""),
  description_zh: yup.string().nullable().default(""),
  description_lt: yup.string().nullable().default(""),
  product_category: yup
    .mixed()
    .oneOf(
      [
        "ACC",
        "FL",
        "FER",
        "TRA",
        "TR",
        "TRF",
        "MI",
        "REST",
        "COO",
        "GEN",
        "AD",
        "ACT",
        "CAR",
      ],
      "Must be between Accommodation, Flights, Ferries, Trains, Transportation, Transfers, Add Ons, Restaurants, Coordinators, General Services, Ad hoc Land Services, Activity or Car Rental"
    ),
  status: yup
    .mixed()
    .oneOf(["AC", "IN", "CA"], "Must be between Active, Inactive or Candidate"),
  extra_titles: yup
    .array()
    .of(yup.string().required("This field is required"))
    .default([]),
  extra_subtitles: yup
    .array()
    .of(yup.string().required("This field is required"))
    .default([]),
  // private_page: yup
  //   .mixed()
  //   .oneOf(["true", "false"], "Must be between Yes or No")
  //   .default(""),
  page_type: yup
    .mixed()
    .oneOf(
      ["product", "subagent_registration"],
      "Must be between Product or Subagent Registration"
    ),
});

const initialForm = {
  name: "",
  status: "",
  description: "",
  description_de: "",
  description_el: "",
  description_es: "",
  description_fr: "",
  description_it: "",
  description_ja: "",
  description_nl: "",
  description_pt: "",
  description_th: "",
  description_zh: "",
  description_lt: "",
  title: "",
  title_de: "",
  title_el: "",
  title_es: "",
  title_fr: "",
  title_it: "",
  title_ja: "",
  title_nl: "",
  title_pt: "",
  title_th: "",
  title_zh: "",
  title_lt: "",
  subtitle: "",
  subtitle_de: "",
  subtitle_el: "",
  subtitle_es: "",
  subtitle_fr: "",
  subtitle_it: "",
  subtitle_ja: "",
  subtitle_nl: "",
  subtitle_pt: "",
  subtitle_th: "",
  subtitle_zh: "",
  subtitle_lt: "",
  website: "",
  active_from: null,
  active_to: null,
  product_category: "",
  path: "",
  theme: "",
  page_type: "product",
  extra_subtitles: [],
  extra_titles: [],
  private_page: false,
};

const pageTypeMapping = {
  product: "Product",
  subagent_registration: "Subagent Registration",
};

const trueFalseMapping = {
  "": "-----",
  true: "Yes",
  false: "No",
};

const productCategoryMapping = {
  "": "-----",
  ACC: "Accommodation",
  FL: "Flights",
  FER: "Ferries",
  TRA: "Trains",
  TR: "Transportation",
  TRF: "Transfers",
  MI: "Add Ons",
  REST: "Restaurants",
  COO: "Coordinators",
  GEN: "General Services",
  AD: "Ad hoc Land Services",
  ACT: "Activitiy",
  CAR: "Car Rental",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditListPages: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  modalCard: {
    ...modalGenericStyles.card,
    // display: "grid",
    // gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  loader: { width: "100vw" },
  languages: {
    display: "grid",
    padding: variables.double_gap,
  },
  languagesButtons: {
    height: "max-content",
    padding: variables.normal_gap,
    overflow: "auto",
  },
  translateButton: {
    padding: variables.normal_gap,
    alignSelf: "center",
  },
  inputGroup: {
    ...formInputGroup,
  },
  descriptionCardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    display: "grid",
    padding: "",
    paddingBottom: variables.normal_gap,
  },
  description: {
    gap: variables.normal_gap,
    "& label": {
      fontSize: "small",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    languages: {
      gridTemplateColumns: "1fr max-content",
      gap: variables.normal_gap,
    },
    cardCol2: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: variables.normal_gap,
    },
    cardCol3: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      gap: variables.normal_gap,
    },
  },
});

const EditListPages = ({ websiteId, id, onClose }) => {
  const classes = styles();

  const mode = id ? "edit" : "add";

  const [form, setForm] = useState(initialForm);
  const [activeLanguage, setActiveLanguage] = useState("en");

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["RetrieveListPage", id],
    queryFn: () => retrieveWebSiteListPage({ id: id }),

    onSuccess: (data) => {
      setForm(_.get(data, "data", {}));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    enabled: mode === "edit",
  });

  const editMutation = useMutation({
    mutationFn: ({ payload, id }) => {
      return patchWebSiteListPage({
        id,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("List Page Info Updated");
      queryClient.invalidateQueries("ListPagesList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const addMutation = useMutation({
    mutationFn: ({ payload, website }) => {
      return createWebSiteListPage({
        payload: {
          ...payload,
          website,
        },
      });
    },
    onSuccess: () => {
      toast.success("List Page Added");
      queryClient.invalidateQueries("ListPagesList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ id, payload, website }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload, website });
      } else {
        editMutation.mutate({ id, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const boolFields = convertStringToBoolean({
    private_page: form.private_page,
  });

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  return (
    <div className={classes.EditListPages}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            {_.startCase(mode)} List Pages
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            {_.startCase(mode)} List Pages
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.languages}>
              <div className={classes.languagesButtons}>
                <WebsitesLanguages
                  activeLanguage={activeLanguage}
                  setActiveLanguage={setActiveLanguage}
                  languages={wLanguages}
                />
              </div>
              <div className={classes.translateButton}>
                <TranslateButton
                  targetLang={activeLanguage}
                  form={form}
                  setForm={setForm}
                />
              </div>
            </div>
            <div className={classes.cardBody}>
              <form
                onChange={(e) =>
                  setForm((p) => ({
                    ...p,
                    [e.target.name]: e.target.value,
                  }))
                }>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Basic Information</div>
                  <div className={classes.cardBody}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="title">Title</label>
                      {Object.entries(wLanguages).map(([key, label], idx) =>
                        key === activeLanguage ? (
                          <input
                            key={key}
                            value={
                              key === "en" ? form.title : form[`title_${key}`]
                            }
                            onChange={(e) => {
                              let newForm = {
                                ...form,
                                [key === "en" ? "title" : `title_${key}`]:
                                  e.target.value,
                              };
                              setForm(newForm);
                            }}
                          />
                        ) : null
                      )}
                    </div>

                    {form.extra_titles.map((title, idx) => {
                      return (
                        <div className={classes.inputGroup}>
                          <label htmlFor="extra_titles">Title {idx + 1}</label>
                          <input
                            type="text"
                            onChange={(e) => {
                              let newExtraTitles = [...form.extra_titles];
                              newExtraTitles[idx] = e.target.value;
                              setForm({
                                ...form,
                                extra_titles: newExtraTitles,
                              });
                            }}
                            defaultValue={form.extra_titles[idx]}
                          />
                        </div>
                      );
                    })}
                    <div className={classes.cardActions}>
                      <CustomButton
                        appearance="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({
                            ...form,
                            extra_titles: [...form.extra_titles, ""],
                          });
                        }}>
                        <strong>Add Extra Title</strong>
                      </CustomButton>
                      {form.extra_titles.length > 0 && (
                        <CustomButton
                          appearance="ghost"
                          onClick={(e) => {
                            e.preventDefault();
                            setForm({
                              ...form,
                              extra_titles: [
                                ...form.extra_titles.filter(
                                  (xT, index) =>
                                    form.extra_titles.length - 1 !== index
                                ),
                              ],
                            });
                          }}>
                          <strong>Remove Title</strong>
                        </CustomButton>
                      )}
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="subtitle">Subtitle</label>
                      {/* <input type="text" name="subtitle" defaultValue={form.subtitle} /> */}
                      {Object.entries(wLanguages).map(([key, label], idx) =>
                        key === activeLanguage ? (
                          <input
                            key={key}
                            value={
                              key === "en"
                                ? form.subtitle
                                : form[`subtitle_${key}`]
                            }
                            onChange={(e) => {
                              let newForm = {
                                ...form,
                                [key === "en" ? "subtitle" : `subtitle_${key}`]:
                                  e.target.value,
                              };
                              setForm(newForm);
                            }}
                          />
                        ) : null
                      )}
                    </div>
                    {form.extra_subtitles.map((subtitle, idx) => {
                      return (
                        <div className={classes.inputGroup}>
                          <label htmlFor="extra_subtitles">
                            Subtitle {idx + 1}
                          </label>
                          <input
                            type="text"
                            onChange={(e) => {
                              let newExtraSubtitles = [...form.extra_subtitles];
                              newExtraSubtitles[idx] = e.target.value;
                              setForm({
                                ...form,
                                extra_subtitles: newExtraSubtitles,
                              });
                            }}
                            defaultValue={form.extra_subtitles[idx]}
                          />
                        </div>
                      );
                    })}
                    <div className={classes.cardActions}>
                      <CustomButton
                        appearance="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({
                            ...form,
                            extra_subtitles: [...form.extra_subtitles, ""],
                          });
                        }}>
                        <strong>Add Extra Subtitle</strong>
                      </CustomButton>
                      {form.extra_subtitles.length > 0 && (
                        <CustomButton
                          appearance="ghost"
                          onClick={(e) => {
                            e.preventDefault();
                            setForm({
                              ...form,
                              extra_subtitles: [
                                ...form.extra_subtitles.filter(
                                  (xT, index) =>
                                    form.extra_subtitles.length - 1 !== index
                                ),
                              ],
                            });
                          }}>
                          <strong>Remove Subtitle</strong>
                        </CustomButton>
                      )}
                    </div>
                    <div className={classes.card}>
                      <div className={classes.descriptionCardBody}>
                        <div className={classes.description}>
                          <label htmlFor="description">Description</label>
                          {Object.entries(wLanguages).map(([key, label], idx) =>
                            key === activeLanguage ? (
                              <QuilEditor
                                key={key}
                                value={
                                  key === "en"
                                    ? form.description
                                    : form[`description_${key}`]
                                }
                                onChange={(value) => {
                                  let newForm = {
                                    ...form,
                                    [key === "en"
                                      ? "description"
                                      : `description_${key}`]: value,
                                  };
                                  setForm(newForm);
                                }}
                              />
                            ) : null
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.cardCol3}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          name="name"
                          defaultValue={form.name}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="product_category">
                          Product Category
                        </label>
                        <select
                          name="product_category"
                          value={form.product_category}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(productCategoryMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="page_type">Page Type</label>
                        <select
                          name="page_type"
                          value={form.page_type}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(pageTypeMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="private_page">Private Page</label>
                        <select
                          name="private_page"
                          value={form.private_page}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(trueFalseMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="name">Path</label>
                        <input
                          type="text"
                          name="path"
                          defaultValue={form.path}
                        />
                      </div>

                      <div className={classes.inputGroup}>
                        <label htmlFor="theme">Theme</label>
                        <input
                          type="text"
                          name="theme"
                          defaultValue={form.theme}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Active Data</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol3}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="active_from">Active From</label>
                        <input
                          type="date"
                          name="active_from"
                          value={
                            form.active_from
                              ? DateTime.fromISO(form.active_from).toISODate()
                              : "N/A"
                          }
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="active_to">Active To</label>
                        <input
                          type="date"
                          name="active_to"
                          value={
                            form.active_to
                              ? DateTime.fromISO(form.active_to).toISODate()
                              : "N/A"
                          }
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="status">Status</label>
                        <select
                          name="status"
                          value={form.status}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(statusMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  id: id,
                  payload: { ...form, ...boolFields },
                  website: websiteId,
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditListPages.propTypes = {
  id: PropTypes.number,
  websiteId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditListPages;
