import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { cardStyles, tableStyles, variables } from "../../../../../variables";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import _ from "lodash";
import { DateTime } from "luxon";
import { medWeekdayDatetimer } from "../../../../../helpers";

const styles = createUseStyles({
  ...cardStyles,
  SubscriptionSection: { ...cardStyles.card },
  header: {
    ...cardStyles.header,
    padding: variables.normal_gap,
    fontSize: "1.2rem",
    marginTop: "2px",
  },
  detailSection: {
    display: "grid",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  tableContainer: {
    display: "block",
    maxHeight: "20rem",
    overflow: "auto",
    width: "100%",
  },
  table: {
    width: "100%",
  },
  thead: {
    position: "sticky",
    top: 0,
    width: "100%",
  },
  emptySubItems: {
    color: "red",
    textAlign: "center",
    minHeight: "10rem",
    lineHeight: "10rem",
  },
  red: { color: "red" },
  [`@media ${variables.media.smallscreen}`]: {
    detailSection: {
      gridTemplateColumns: "1fr 2fr",
    },
  },
});
const SubscriptionSection = ({ subscriptionData }) => {
  const classes = styles();

  const subItems = _.get(subscriptionData, "latest_invoice.items", []);

  const isInTrial =
    DateTime.fromISO(subscriptionData.trial_end) >= DateTime.now();
  const trialStart = _.get(subscriptionData, "trial_start");
  const trialEnd = _.get(subscriptionData, "trial_end");
  const currentPeriodStart = _.get(subscriptionData, "current_period_start");
  const currentPeriodEnd = _.get(subscriptionData, "current_period_end");
  const subscriptionDataCpl = [
    ["Status", null, _.startCase(subscriptionData.status)],
    [
      "Total Amount",
      null,
      _.get(subscriptionData, "latest_invoice.payment_intent.currency") &&
      _.get(subscriptionData, "latest_invoice.payment_intent.amount")
        ? `${
            _.get(subscriptionData, "latest_invoice.payment_intent.currency") ||
            "N/A"
          } ${
            _.get(subscriptionData, "latest_invoice.payment_intent.amount") ||
            "N/A"
          }`
        : "N/A",
    ],
    [
      "Is in Trial",
      null,
      isInTrial ? "Yes" : <span className={classes.red}>{"No"}</span>,
    ],
    ["Trial Start", null, trialStart ? medWeekdayDatetimer(trialStart) : "N/A"],
    ["Trial End", null, trialEnd ? medWeekdayDatetimer(trialEnd) : "N/A"],
    [
      "Current Period Start",
      null,
      currentPeriodStart ? medWeekdayDatetimer(currentPeriodStart) : "N/A",
    ],
    [
      "Current Period End",
      null,
      currentPeriodEnd ? medWeekdayDatetimer(currentPeriodEnd) : "N/A",
    ],
  ];

  return (
    <div className={classes.SubscriptionSection}>
      <div className={classes.header}>
        <span>Subscription</span>
      </div>
      <div className={classes.detailSection}>
        <GenericBasicInfo
          header="Basic Info"
          cardInfo={subscriptionDataCpl}
          objectData={subscriptionData}
          costumeStyles={{ header: { fontSize: "1.2rem" } }}
        />
        <div className={classes.subscriptionItems}>
          <div className={classes.header}>
            <span>Subscription Items</span>
          </div>
          <div className={classes.tableContainer}>
            {subItems.length === 0 ? (
              <div className={classes.emptySubItems}>
                <strong>No Subscription Items</strong>
              </div>
            ) : (
              <table className={classes.table}>
                <thead className={classes.thead}>
                  <tr className={classes.headRow}>
                    <th className={classes.headCell}>#</th>
                    <th className={classes.headCell}>Item Id</th>
                    <th className={classes.headCell}>Nickname</th>
                    <th className={classes.headCell}>Total Amount</th>
                    <th className={classes.headCell}>Quantity</th>
                    <th className={classes.headCell}>Billing Scheme</th>
                    <th className={classes.headCell}>Interval</th>
                    <th className={classes.headCell}>License Type</th>
                    <th className={classes.headCell}>Used</th>
                    <th className={classes.headCell}>Active</th>
                  </tr>
                </thead>
                <tbody>
                  {subItems.map((item, idx) => (
                    <tr key={item.id}>
                      <td className={classes.tableCell}>{idx + 1}</td>
                      <td className={classes.tableCell}>
                        {_.get(item, "item_id") || "N/A"}
                      </td>
                      <td className={classes.tableCell}>
                        {_.get(item, "nickname") || "N/A"}
                      </td>
                      <td className={classes.tableCell}>
                        {_.get(item, "currency") && _.get(item, "total_amount")
                          ? `${_.get(item, "currency") || "N/A"} ${
                              _.get(item, "total_amount") || "N/A"
                            }`
                          : "N/A"}
                      </td>
                      <td className={classes.tableCell}>
                        {_.get(item, "quantity") || "N/A"}
                      </td>
                      <td className={classes.tableCell}>
                        {_.get(item, "billing_scheme")
                          ? _.startCase(_.get(item, "billing_scheme"))
                          : "N/A"}
                      </td>
                      <td className={classes.tableCell}>
                        {_.get(item, "interval_count") &&
                        _.get(item, "interval")
                          ? `${_.get(item, "interval_count") || "N/A"} ${
                              _.get(item, "interval") || "N/A"
                            }`
                          : "N/A"}
                      </td>
                      <td>
                        {_.get(item, "license_type")
                          ? _.startCase(_.get(item, "license_type"))
                          : "N/A"}
                      </td>
                      <td>
                        {_.get(item, "used") === null ? (
                          "N/A"
                        ) : _.get(item, "used") ? (
                          "Yes"
                        ) : (
                          <span className={classes.red}>{"No"}</span>
                        )}
                      </td>
                      <td>
                        {_.get(item, "active") === null ? (
                          "N/A"
                        ) : _.get(item, "active") ? (
                          "Yes"
                        ) : (
                          <span className={classes.red}>{"No"}</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SubscriptionSection.propTypes = {
  subscriptionData: PropTypes.object.isRequired,
};
export default SubscriptionSection;
