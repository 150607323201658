import React from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  modalGenericStyles,
  variables,
  formInputGroup,
} from "../../../../../../variables";
import { useState } from "react";
import { toast } from "react-toastify";
import { genericRequestErrorHandler } from "../../../../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createNewPaymentMethod } from "../../../../../../api/CRM/guestCRM/guestCRM";
import PropTypes from "prop-types";
import { currencies, handleValidationErrors } from "../../../../../../helpers";
import _ from "lodash";
import { CustomButton } from "../../../../../../components/CustomButton";
import * as yup from "yup";
import Loader from "../../../../../../components/Loader";

const jsonSchema = yup.object().shape({});

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  AddCreditDebitModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: cardStyles.body,
  tagscardBody: {
    ...cardStyles.body,
    display: "grid",
    gridTemplateColumns: "1fr max-content",
  },
  inputGroup: { ...formInputGroup, padding: `${variables.normal_gap} 0` },
  tagsActions: {
    ...modalGenericStyles.cardActions,
    justifyContent: "start",
    height: "1.5rem",
    alignSelf: "end",
  },
  tags: {
    ...modalGenericStyles.card,
    display: "grid",
    gridColumn: "span 2",
    lineHeight: "5vh",
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardCol2: {
      gridTemplateColumns: "1fr 1fr",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
});

const AddCreditDebitModal = ({ type, guestData, onClose }) => {
  const classes = styles();

  const [form, setForm] = useState({
    amount: 0,
    description: "",
    currency: "",
    tags: [""],
  });

  const queryClient = useQueryClient();

  const addMutation = useMutation({
    mutationFn: ({ payload }) => {
      return createNewPaymentMethod({
        payload,
      });
    },
    onSuccess: () => {
      toast.success("Succesfull payment method added");
      onClose();
      queryClient.invalidateQueries("financialData");
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ uid, guest, formData, type }) => {
    try {
      await jsonSchema.validate(formData, { abortEarly: false });

      let newTags = formData.tags.filter((tag) => tag !== "");

      var amount = parseFloat(formData.amount).toFixed(2);
      if (type === "DB") {
        amount = amount * -1;
      }
      amount = amount.toString();

      const payload = {
        source_entity: _.get(guest, "source_entity", ""),
        description: _.get(formData, "description", ""),
        currency: _.get(formData, "currency", ""),
        amount: amount,
        entry_type: type,
        guest_crm_uid: guest.uuid,
        // tags: newTags,
        tags: [...new Set(newTags)],
      };

      addMutation.mutate({ payload });
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const handleInputChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const loading = addMutation.isLoading;

  return (
    <div className={classes.AddCreditDebitModal}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>Add Payment Entries</div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>Add Payment Entries</div>
          <div className={classes.modalCardBody}>
            <form onChange={handleInputChange}>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="amount">Amount</label>
                      <input type="number" name="amount" value={form.amount} />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="currency">Currency</label>
                      <select
                        name="currency"
                        value={form.currency}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(currencies).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="name">Description</label>
                      <input
                        type="text"
                        name="description"
                        value={form.description}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Tags</div>
                <div className={classes.tagscardBody}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="tags">Tag</label>
                    <input
                      type="text"
                      value={_.get(form, "tags.0", "")}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          tags: [
                            ...form.tags.map((tag, idx) =>
                              idx === 0 ? e.target.value : tag
                            ),
                          ],
                        });
                      }}
                    />
                  </div>
                  <div className={classes.tagsActions}>
                    {(_.get(form, "tags", []).length !== 1 ||
                      _.get(form, "tags.0", "")) !== "" && (
                      <CustomButton
                        appearance="ghost"
                        onClick={(e) => {
                          e.preventDefault();
                          if (_.get(form, "tags", []).length > 1) {
                            let newTags = form.tags;
                            newTags.splice(0, 1);
                            setForm({ tags: [...newTags] });
                            return;
                          }
                          setForm({ ...form, tags: [""] });
                        }}>
                        <strong>Remove</strong>
                      </CustomButton>
                    )}

                    {_.get(form, "tags", [""]).length === 1 && (
                      <CustomButton
                        appearance="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({ ...form, tags: [...form.tags, ...[""]] });
                        }}>
                        <strong>Add Tag</strong>
                      </CustomButton>
                    )}
                  </div>
                </div>
              </div>
              {_.get(form, "tags").map(
                (tag, tagIdx) =>
                  tagIdx > 0 && (
                    <React.Fragment>
                      <div className={classes.card}>
                        <div className={classes.tagscardBody}>
                          <div className={classes.inputGroup}>
                            <label htmlFor="tags">Tag{tagIdx + 1}</label>
                            <input
                              type="text"
                              value={_.get(form, `tags.${tagIdx}`, "")}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  tags: [
                                    ...form.tags.map((tag, idx) =>
                                      idx === tagIdx ? e.target.value : tag
                                    ),
                                  ],
                                });
                              }}
                            />
                          </div>
                          <div className={classes.tagsActions}>
                            <CustomButton
                              appearance="ghost"
                              onClick={(e) => {
                                e.preventDefault();
                                if (_.get(form, "tags", []).length > 1) {
                                  let newTags = form.tags;
                                  newTags.splice(0, 1);
                                  setForm({ ...form, tags: [...newTags] });
                                  return;
                                }
                                setForm({ ...form, tags: [""] });
                              }}>
                              <strong>Remove</strong>
                            </CustomButton>
                            {_.get(form, "tags", []).length === tagIdx + 1 && (
                              <CustomButton
                                appearance="primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setForm({ tags: [...form.tags, ...[""]] });
                                }}>
                                <strong>Add Tag</strong>
                              </CustomButton>
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )
              )}
            </form>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Close</strong>
            </CustomButton>
            <CustomButton
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  guest: guestData,
                  formData: form,
                  type,
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
AddCreditDebitModal.propTypes = {
  type: PropTypes.string.isRequired,
  arrayTags: PropTypes.array.isRequired,
  guestData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default AddCreditDebitModal;
