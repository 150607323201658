import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import { DateTime } from "luxon";
import { retrieveGuestsCRM } from "../../../../../api/CRM/guestCRM/guestCRM";
import { genericRequestErrorHandler } from "../../../../../api";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../../../components/Loader";
import FinancialInfo from "./financialInfo";
import TabStrip from "../../../../../components/TabStrip";
import { CustomButton } from "../../../../../components/CustomButton";
import ConnectionsTable from "./ConnectionsTable";

const genderMapping = {
  "": "-----",
  MA: "Male",
  FE: "Female",
  OTH: "Other",
};

const trueFalsemapping = {
  true: "Yes",
  false: "No",
};
const titleMapping = {
  "": "-----",
  MR: "Mr",
  MRS: "Mrs",
  MS: "Ms",
  DR: "Dr",
  CH: "Ch",
  MISS: "Miss",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailGuestCRMModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    maxWidth: "100%",
  },
  tabsContainer: {
    extend: "cardBody",
    overflow: "auto",
  },
  row: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    row: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
});
const DetailGuestCRMModal = ({ uuid, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicInfo");

  const { data: retrievedData, isLoading } = useQuery({
    queryKey: ["retrieveGuestCRM", uuid],
    queryFn: () =>
      retrieveGuestsCRM({
        uuid: uuid,
      }),
    onError: (error) => genericRequestErrorHandler(error),
    retry: false,
  });

  const basicDataCpl = [
    ["Uid", "uuid"],

    ["Source Entity", "source_entity"],
    [
      "Blocked",
      null,
      _.get(trueFalsemapping, _.get(retrievedData, "data.blocked")),
    ],

    [
      "Preferred Locales",
      null,
      retrievedData && _.get(retrievedData, "data.preferred_locales", "")
        ? _.get(retrievedData, "data.preferred_locales", "").join(", ")
        : "N/A",
    ],
    ["ext User Id", "ext_user_id"],

    [
      "Is User Complete",
      null,
      _.get(trueFalsemapping, _.get(retrievedData, "data.user_is_complete")),
    ],
    ["Status", "status_display"],
  ];

  const contactInfo = [
    [
      "Gender",
      null,
      _.get(genderMapping, _.get(retrievedData, "data.gender", "")),
    ],
    [
      "Title",
      null,
      _.get(titleMapping, _.get(retrievedData, "data.title", "")),
    ],
    ["First Name", "first_name"],
    ["Last Name", "last_name"],
    ["Email", "email"],
    ["Address", "address"],
    ["Date Of Birth", "date_of_birth"],
  ];

  const addressData = [
    ["Address", "adress"],
    ["Postal Code", "postal_code"],
    ["Country Code", "country_code"],
    ["Phone", "phone"],
    ["Mobile Phone", "mobile_phone"],
  ];

  const creatorData = [
    [
      "Created",
      null,
      DateTime.fromISO(_.get(retrievedData, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];

  const subscriptionsData = [
    [
      "More Subscriptions",
      null,
      _.get(retrievedData, "data.subscriptions.has_more") === true
        ? "Yes"
        : "No",
    ],
    [
      "Subscription Url",
      null,
      _.get(retrievedData, "data.subscriptions.url", ""),
    ],
    [
      "Subscription Object",
      null,
      _.get(retrievedData, "data.subscriptions.object", ""),
    ],
  ];

  const freePeriod = [
    ["Free Period End", "free_period_end"],
    ["Free Period Start", "free_period_start"],
    [
      "In Free Period",
      null,
      _.get(trueFalsemapping, _.get(retrievedData, "data.in_free_period")),
    ],
  ];

  const stripeData = [
    ["Stripe Id", "stripe_id"],
    ["Stripe Public Key", "stripe_public_key"],
    ["Stripe Subscription Id", "stripe_subscription_id"],
  ];

  const menuTabs = {
    basicInfo: "Basic Info",
    financialInfo: "Financial Info",
    logs: "Logs",
  };
  return (
    <div className={classes.DetailGuestCRMModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Detail Guest CRM</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {isLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicInfo" && (
                <React.Fragment>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Basic Data"}
                        cardInfo={basicDataCpl}
                        objectData={_.get(retrievedData, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Creator Data"}
                        cardInfo={creatorData}
                        objectData={_.get(retrievedData, "data", {})}
                      />
                      <GenericBasicInfo
                        header="Contact Data"
                        cardInfo={contactInfo}
                        objectData={_.get(retrievedData, "data", {})}
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Subscription Data"}
                        cardInfo={subscriptionsData}
                        objectData={_.get(retrievedData, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Stripe Data"}
                        cardInfo={stripeData}
                        objectData={_.get(retrievedData, "data", {})}
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Address Data"}
                        cardInfo={addressData}
                        objectData={_.get(retrievedData, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header="Contact Data"
                        cardInfo={contactInfo}
                        objectData={_.get(retrievedData, "data", {})}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activeTab === "financialInfo" && (
                <FinancialInfo
                  guestData={_.get(retrievedData, "data", {})}
                  uid={uuid}
                />
              )}
            </React.Fragment>
          )}
          <ConnectionsTable
            data={_.get(retrievedData, "data.connections", [])}
          />
        </div>
        <div className={classes.cardActions}>
          <CustomButton appearance="ghost" onClick={onClose}>
            <strong>Close</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailGuestCRMModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
};
export default DetailGuestCRMModal;
