import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import Loader from "../../../../../components/Loader";
import {
  cardStyles,
  formInputGroup,
  formSections,
  modalGenericStyles,
  variables,
} from "../../../../../variables";

const secimalOptions = [
  ["1", "No Decimal Places"],
  [".1", "1 decimal place"],
  [".2", "2 decimal places"],
  [".3", "3 decimal places"],
];

const basicInformationFormStyles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  BasicInformationForm: {
    ...cardStyles.card,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  inputGroup: formInputGroup,

  card: cardStyles.card,
  cardHeader: { ...cardStyles.header, fontSize: "large" },
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },

  sectionHeader: formSections.sectionHeader,
  [`@media ${variables.media.smallscreen}`]: {
    cardCol2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
});

const BasicInformationForm = ({ text, loading, form, setForm }) => {
  const classes = basicInformationFormStyles();

  return (
    <div className={classes.BasicInformationForm}>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              <span>{text}</span>
            </div>

            <div className={classes.cardBody}>
              <form
                // className={classes.form}
                onChange={(e) =>
                  setForm((p) => ({
                    ...p,
                    [e.target.name]: e.target.value,
                  }))
                }>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="name">Rule Name</label>
                      <input
                        type="text"
                        name="name"
                        defaultValue={form.name}
                        onChange={(e) =>
                          setForm((p) => ({
                            ...p,
                            name: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="pax_from">Min Pax</label>
                      <input
                        type="number"
                        name="pax_from"
                        defaultValue={form.pax_from}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="pax_to">Max Pax</label>
                      <input
                        type="number"
                        name="pax_to"
                        defaultValue={form.pax_to}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="rounding_digits">Rounding Digits</label>
                      <input
                        type="text"
                        name="rounding_digits"
                        defaultValue={form.rounding_digits}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="service_execution_from">
                        Distribution Period From
                      </label>
                      <input
                        type="date"
                        name="service_execution_from"
                        defaultValue={form.service_execution_from}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="service_execution_to">
                        Distribution Period To
                      </label>
                      <input
                        type="date"
                        name="service_execution_to"
                        defaultValue={form.service_execution_to}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="booking_window_from">
                        Travel Period From
                      </label>
                      <input
                        type="date"
                        name="booking_window_from"
                        defaultValue={form.booking_window_from}
                        onChange={(e) =>
                          setForm((p) => ({
                            ...p,
                            booking_window_from: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="booking_window_to">
                        Travel Period To
                      </label>
                      <input
                        type="date"
                        name="booking_window_to"
                        defaultValue={form.booking_window_to}
                        onChange={(e) =>
                          setForm((p) => ({
                            ...p,
                            booking_window_to: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

BasicInformationForm.propTypes = {
  text: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
};
export default BasicInformationForm;
