import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { modalGenericStyles, variables } from "../../../../../../variables";
import { toast } from "react-toastify";
import Loader from "../../../../../../components/Loader";
import GenericBasicInfo from "../../../../../../components/cards/GenericBasicInfo";
import { genericRequestErrorHandler } from "../../../../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { deleteACTVendor } from "../../../../../../api/Vendor/Activitiy";
import { DateTime } from "luxon";
import { CustomButton } from "../../../../../../components/CustomButton";

const statusOptions = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteActivitiesVendorsModal: modalGenericStyles.modal,
  cardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: variables.normal_gap,
  },
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    gridTemplateColumns: "repeat(2, max-content)",
  },
});

const DeleteActivitiesVendorsModal = ({ deletedId, data, onClose }) => {
  const classes = styles();
  const queryClient = useQueryClient();

  const VendorDataCpl = [
    ["Vendor", null, _.get(data, "vendor", "").split("act__")[1] || "N/A"],
    [" Vendor Client Id", null, _.get(data, "vendor_data.client_id")],
    [" Vendor Currency", null, _.get(data, "vendor_data.currency")],
    [" Vendor Base URL", null, _.get(data, "vendor_data.base_url", "")],
    [" Vendor Grand Type", null, _.get(data, "vendor_data.grand_type")],
    [
      " Vendor X Musement Application",
      null,
      _.get(data, "vendor_data.x_musement_application"),
    ],
  ];

  const basicDataCpl = [
    ["Id", "id"],
    ["Market_type", "market_type"],
    ["Member", "member"],
    ["Notes", "notes"],
    [
      "Payment Validation",
      null,
      _.get(data, "payment_validation") === true ? "Yes" : "No" || "N/A",
    ],
    // ["Deliveries", null, _.get(data, "deliveries", "") || "N/A"],
    ["CXL Strategy Time", "cxl_strategy_time"],
    [
      "CXL Strategy Daysahead",
      null,
      _.get(data, "cxl_strategy_daysahead", []) === 0
        ? "0"
        : _.get(data, "cxl_strategy_daysahead"),
    ],
    ["Last Editor", "last_editor"],
    ["Creator", "creator"],
    [
      "Edited",
      null,
      DateTime.fromISO(_.get(data, "edited")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    [
      "Created",
      null,
      DateTime.fromISO(_.get(data, "created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    ["Status", null, _.get(statusOptions, _.get(data, "status", "") || "N/A")],
  ];

  const DeleteACTVendorsModal = useMutation(deleteACTVendor, {
    onSuccess: () => {
      toast.success("Activity Vendor Deleted");
      queryClient.invalidateQueries("ActivitiesVendors");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });
  const onDelete = async (id) => {
    await DeleteACTVendorsModal.mutateAsync({ id });
  };
  return (
    <div
      id="DeleteActivityVendorModal"
      className={classes.DeleteActivitiesVendorsModal}>
      <React.Fragment>
        <div className={classes.card}>
          {DeleteACTVendorsModal.isLoading && <Loader onTop={true} />}
          <div className={classes.cardHeader}>
            <span>DELETE Activity Vendor </span>
          </div>
          <div className={classes.cardBody}>
            <div className={classes.basicInfo}>
              <GenericBasicInfo
                header={"Basic Data"}
                cardInfo={basicDataCpl}
                objectData={data}
              />
            </div>
            <div className={classes.basicInfo}>
              <GenericBasicInfo
                header={"Vendor Data"}
                cardInfo={VendorDataCpl}
                objectData={data}
              />
            </div>
          </div>
          <div className={classes.cardActions}>
            <CustomButton
              id="DeleteActivitiesVendorsModalCancelButton"
              appearance="ghost"
              onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="DeleteActivitiesVendorsModalConfirmButton"
              appearance="primary"
              onClick={() => {
                onDelete(deletedId);
              }}>
              <strong>DELETE</strong>
            </CustomButton>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
DeleteActivitiesVendorsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  deletedId: PropTypes.number.isRequired,
};
export default DeleteActivitiesVendorsModal;
