import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { variables } from "../../variables";
import { PropTypes } from "prop-types";
import { onEnterDown } from "../../helpers";

const styles = createUseStyles({
  ControlStrip: {
    display: "grid",
    gridTemplateRows: ({ withOrder }) =>
      withOrder ? "max-content " : "max-content ",
    justifyItems: "center",
    padding: variables.normal_gap,
    gap: variables.normal_gap,
    alignItems: "baseline",
    color: variables.colors.baseDarker,
  },
  countsContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "baseline",
  },
  count: {
    padding: variables.normal_gap,
    "& span": {
      color: variables.colors.text.dark,
    },
  },
  paginationItems: {
    padding: variables.half_gap,
    border: "none",
    backgroundColor: "white",
    "&:hover": {
      cursor: "pointer",
    },
    "& label": {
      padding: variables.half_gap,
    },
    "&:enabled": {
      color: variables.colors.baseDarker,
    },
  },
  orderItems: {
    display: "grid",
    gridAutoFlow: "row",
    padding: variables.half_gap,
    "& label": {
      padding: variables.half_gap,
    },
  },
  enableButton: {
    gap: variables.normal_gap,
    color: "white",
    backgroundColor: variables.colors.background.dark,
  },
  [`@media ${variables.media.smallscreen}`]: {
    ControlStrip: {
      gridTemplateColumns: ({ withOrder }) =>
        withOrder ? "max-content 1fr max-content" : "max-content 1fr",
      justifyItems: "center",
    },
    orderItems: {
      display: "block",
      gridAutoFlow: "column",
    },
  },
});
const ControlStrip = ({
  limit,
  page,
  count,
  pageOptions,
  onChangeLimit,
  onChangePage,
  orderOptions,
  directionOptions,
  onChangeOrderOptions,
}) => {
  const classes = styles({ withOrder: orderOptions.length ? true : false });

  const [direction, setDirection] = useState("");
  const [order, setOrder] = useState("");
  const totalPages = Math.ceil(count / limit);
  function CreatePagesButtons(totalPages) {
    let pagesNum = [];

    useEffect(() => {
      if (!direction && !order) {
        return;
      }
      onChangeOrderOptions(direction === "DIS" ? `-${order}` : order);
    }, [order, direction]);

    for (let num = 0; num < totalPages; num++) {
      const element = (
        <button
          key={num}
          className={
            num + 1 === page ? classes.enableButton : classes.paginationItems
          }
          onClick={() => {
            onChangePage(num + 1);
          }}
          disabled={num + 1 === page}>
          <strong>{num + 1}</strong>
        </button>
      );
      if (num - 2 < page && num + 4 > page) {
        pagesNum.push(element);
      }
    }

    return pagesNum;
  }
  return (
    <div className={classes.ControlStrip}>
      <div className={classes.countsContainer}>
        <div>
          <select
            className={classes.paginationItems}
            value={limit}
            onChange={(e) => onChangeLimit(Number(e.target.value))}
            onKeyDown={(e) => onEnterDown(e, limit, onChangeLimit)}>
            {pageOptions.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
          Items per Page
        </div>
        <div className={classes.count}>
          Total Results: <span>{count}</span>
        </div>
      </div>
      {orderOptions.length > 0 && (
        <div className={classes.orderItems}>
          <label>Order By</label>
          <select
            value={order}
            onChange={(e) => setOrder(e.target.value)}
            onKeyDown={(e) => onEnterDown(e, setOrder, order)}>
            <option key={"default"} value={""}>
              -----
            </option>
            {orderOptions.map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
          {"      "} <label>Order Type</label>
          <select
            value={direction}
            // value={direction}
            onChange={(e) => setDirection(e.target.value)}>
            {directionOptions.map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      )}
      <div>
        <button
          className={classes.paginationItems}
          onClick={() => onChangePage(1)}
          disabled={page === 1}>
          <strong>{"<<"}</strong>
        </button>
        <button
          className={classes.paginationItems}
          onClick={() => onChangePage(page - 1)}
          disabled={page === 1}>
          <strong>{"<"}</strong>
        </button>
        {CreatePagesButtons(totalPages)}

        <button
          className={classes.paginationItems}
          onClick={() => onChangePage(page + 1)}
          disabled={page >= totalPages}>
          <strong>{">"}</strong>
        </button>
        <button
          className={classes.paginationItems}
          onClick={() => onChangePage(totalPages)}
          disabled={page >= totalPages}>
          <strong>{">>"}</strong>
        </button>
      </div>
    </div>
  );
};
ControlStrip.defaultProps = {
  pageOptions: [10, 20, 50],
  page: 1,
  limit: 10,
  count: 0,
  orderOptions: [],
  directionOptions: [
    ["", "-----"],
    ["ASC", "Ascending"],
    ["DIS", "Descending"],
  ],
};
ControlStrip.propTypes = {
  limit: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  pageOptions: PropTypes.array,
  onChangeLimit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  children: PropTypes.node,
  orderOptions: PropTypes.array,
  onChangeOrderOptions: PropTypes.func,
};
export default ControlStrip;
