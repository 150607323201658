import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";

const statusMapping = {
  paid: { label: "Paid", color: "green" },
  unpaid: { label: "Cancelled", color: "red" },
  canceled: { label: "Unpaid", color: "orange" },
};

const styles = createUseStyles({
  ReceivablesStatus: ({ status }) => ({
    display: "inline-block",
    padding: "4px 10px 4px 10px",
    backgroundColor: statusMapping[status]?.color || "grey",
    width: "fit-content",
    color: "white",
    borderRadius: "3px",
  }),
});

const ReceivablesStatus = ({ status }) => {
  const classes = styles({ status });

  return (
    <div className={classes.ReceivablesStatus}>
      <strong>{statusMapping[status]?.label || "N/A"}</strong>
    </div>
  );
};

ReceivablesStatus.propTypes = {
  status: PropTypes.string,
};

export default ReceivablesStatus;
