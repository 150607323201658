import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import {
  createNewFlight,
  patchFlight,
  retrieveVendorFlight,
} from "../../../../../../api/Vendor/flight";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import Loader from "../../../../../../components/Loader";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../../api";
import Deliveries from "../../common/deliveries";
import {
  convertStringToBoolean,
  convertStringToNumber,
  countries,
  currencies,
  handleValidationErrors,
} from "../../../../../../helpers";
import EntityAutocomplete from "../../../../../../components/EntityAutocomplete";
import { CustomButton } from "../../../../../../components/CustomButton";

const titleMapping = {
  "": "-----",
  mr: "Mr",
  ms: "Ms",
};

const getSchema = ({ required_message, vendor }) => {
  return yup.object().shape({
    vendor: yup.string().required(`Vendor: ${required_message}`),
    status: yup.string().required(`Status: ${required_message}`),
    travel_service_type: yup.string().default("FL"),
    notify_amount: yup.number().nullable(),
    member: yup
      .number()
      .integer()
      .typeError("Member must be an integer")
      .required(`Member: ${required_message}`),
    is_own: yup.boolean(),
    deliveries: yup.array().of(
      yup.object().shape({
        street: yup.string().required(`Street: ${required_message}`),
        street_number: yup
          .string()
          .required(`Street Number: ${required_message}`),
        post_code: yup.string().required(`Post code: ${required_message}`),
        city: yup.string().required(`City: ${required_message}`),
        region: yup.string().required(`Region: ${required_message}`),
        country_code: yup.string().required(`Country: ${required_message}`),
        first_name: yup.string().required(`First Name: ${required_message}`),
        last_name: yup.string().required(`Last Name: ${required_message}`),
        gender: yup.string().required(`Gender ${required_message}`),
        email: yup.string().email().required(`Email: ${required_message}`),
      })
    ),
    vendor_data: yup.object().shape({
      user_id: yup.string().test({
        name: "user_id_is_required_for_sabre_and_Amadeus",
        message: `user id: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["fl__Sabre", "fl__Amadeus"].includes(vendor) ? !!value : true;
        },
      }),
      password: yup.string().test({
        name: "password_is_required_for_sabre_Farelogix_NDC_Amadeus_Aviate",
        message: `Password: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return [
            "fl__Sabre",
            "fl__Farelogix NDC",
            "fl__Amadeus",
            "fl__Aviate",
          ].includes(vendor)
            ? !!value
            : true;
        },
      }),
      queue: yup.string().test({
        name: "queue_is_required_for_sabre_Farelogix_NDC_Galileo_Amadeus",
        message: `Queue: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return [
            "fl__Sabre",
            "fl__Farelogix NDC",
            "fl__Galileo",
            "fl__Amadeus",
          ].includes(vendor)
            ? !!value
            : true;
        },
      }),
      ipcc: yup.string().test({
        name: "ipcc_is_required_for_sabre_and_Farelogix_NDC_and_Galileo_Amadeus",
        message: `IPCC: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return [
            "fl__Sabre",
            "fl__Farelogix NDC",
            "fl__Galileo",
            "fl__Amadeus",
          ].includes(vendor)
            ? !!value
            : true;
        },
      }),
      airline: yup.string().test({
        name: "airline_is_required_for_Farelogix_NDC",
        message: `Airline: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return vendor === "fl__Farelogix NDC" ? !!value : true;
        },
      }),
      office_id: yup.string().test({
        name: "office_id_is_required_for_Farelogix_NDC",
        message: `Office Id: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return vendor === "fl__Farelogix NDC" ? !!value : true;
        },
      }),
      agent_id: yup.string().test({
        name: "agent_id_is_required_for_Farelogix_NDC",
        message: `Agent Id: ${required_message}`,
        test: function (value) {
          return vendor === "fl__Farelogix NDC" ? !!value : true;
        },
      }),
      username: yup.string().test({
        name: "username_is_required_for_aviate",
        message: `Username: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["fl__Aviate"].includes(vendor) ? !!value : true;
        },
      }),
      primary_key: yup.string().test({
        name: "primary_key_is_required_for_aviate",
        message: required_message,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["fl__Aviate"].includes(vendor) ? !!value : true;
        },
      }),
      secondary_key: yup.string().test({
        name: "secondary_key_is_required_for_aviate",
        message: `Secondary Key: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["fl__Aviate"].includes(vendor) ? !!value : true;
        },
      }),
      app_key: yup.string().test({
        name: "app_key_is_required_for_Unififi",
        message: `App Key: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["fl__Unififi"].includes(vendor) ? !!value : true;
        },
      }),
      app_secret: yup.string().test({
        name: "app_secret_is_required_for_Unififi",
        message: `App Secret: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["fl__Unififi"].includes(vendor) ? !!value : true;
        },
      }),
      customer_id: yup.string().test({
        name: "customer_id_is_required_for_Unififi",
        message: `Customer Id: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["fl__Unififi"].includes(vendor) ? !!value : true;
        },
      }),
      currency: yup.string().required(`Currency: ${required_message}`),
      config_uid: yup.array().of(yup.string()),
      contact_details: yup.object().shape({
        // title: yup.string().oneOf(["mr", "ms"], "Invalid Value"),
        first_name: yup.string(),
        last_name: yup.string(),
        email: yup.string().email("Invalid Email"),
      }),
    }),
  });
};

const airlineOptions = {
  "": "-----",
  "Airfrance KLM": "Airfrance KLM",
  "Singapore Airlines": "Singapore Airlines",
  "British Airways": "British Airways",
  "Olympic Air": "Olympic Air",
  Lufthansa: "Lufthansa",
  "American Airlines": "American Airlines",
  "Brussels Airlines": "Brussels Airlines",
  "Austrian Airlines": "Austrian Airlines",
  Condor: "Condor",
  Emirates: "Emirates",
  Flybe: "Flybe",
  HOP: "HOP",
  Iberia: "Iberia",
  "Iberia Express": "Iberia Express",
  "KLM Royal Dutch Airlines": "KLM Royal Dutch Airlines",

  "Norwegian (Air Shuttle, Air International, UK)":
    "Norwegian (Air Shuttle, Air International, UK)",
  Olympic: "Olympic",
  "Swiss International Air Lines": "Swiss International Air Lines",
  Vueling: "Vueling",
};
const gdsMapping = {
  "": "-----",
  "1S": "Sabre",
  "1G": "Galileo",
  "1A": "Amadeus",
  F1: "Farelogix",
  "1U": "HH Unififi",
  AV: "Aviate",
  UF: "Unififi",
};

const marketTypeMapping = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};

const trueFalseMapping = {
  "": "-----",
  false: "No",
  true: "Yes",
};

const forceSourceTypeMapping = {
  0: "Default",
  1: "Force GDC",
  2: "Force IBE",
};

const environmentMapping = {
  "": "-----",
  PROD: "Production",
  STAGE: "Staging",
};

const vendorsMapping = {
  "": "-----",
  fl__Sabre: "Sabre",
  fl__Galileo: "Galileo",
  "fl__Farelogix NDC": "Farelogix NDC",
  fl__Amadeus: "Amadeus",
  fl__Aviate: "Aviate",
  fl__Unififi: "Unififi",
};
const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};
const initialForm = {
  vendor: "",
  status: "",
  member: "",
  notify_amount: 0,
  config_uid: "",
  market_type: "",
  travel_service_type: "FL",
  vendor_data: {
    airline: "",
    user_id: "",
    name: "",
    uce_mcre: false,
    mcre_branch: "",
    crs_id: "",
    force_source_type: "",
    user: "",
    ipcc: "",
    queue: "",
    source_entity: "",
    account_number: "",
    ticket_after_booking: false,
    max_searches: "",
    max_bookings: "",
    reeived_from: "",
    country_code: "",
    pcc: "",
    gds: "",
    currency: "",
    environment: "",
    api_url: "",
    app_key: "",
    app_secret: "",
    customer_id: "",
    is_own: false,
    is_global: false,
    password: "",
    office_id: "",
    agent_id: "",
    username: "",
    primary_key: "",
    secondary_key: "",
    contact_details: {
      title: "",
      first_name: "",
      last_name: "",
      email: "",
    },
  },
  deliveries: [
    {
      street: "",
      street_number: "",
      post_code: "",
      city: "",
      region: "",
      country_code: "",
      first_name: "",
      last_name: "",
      gender: "",
      email: "",
    },
  ],
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditFlightModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content  1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridTemplateColumns: "1fr",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    alignContent: "start",
  },
  inputGroup: {
    ...formInputGroup,
    "& select": {
      width: "100%",
    },
    // textAlign: "start"
  },
  entityAutoComplete: {
    ...formInputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  formCardBody: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: variables.half_gap,
    padding: variables.normal_gap,
    // height: "2rem",
  },
  loader: {
    width: "100vw",
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCardBody: {
      gridTemplateColumns: "1fr 1fr",
      gap: variables.normal_gap,
    },
    card: {
      width: "60rem",
    },
    cardBody: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
});

const EditFlightModal = ({ flightId, onClose }) => {
  const classes = styles();
  const queryClient = useQueryClient();

  const mode = flightId ? "edit" : "add";

  const [form, setForm] = useState(initialForm);
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const { isLoading: isFetchingLoading } = useQuery({
    queryKey: ["EditFlightVendorModal"],
    queryFn: () => retrieveVendorFlight({ id: flightId }),
    enabled: mode === "edit",
    onSuccess: (data) => {
      const retrievedData = _.get(data, "data", {});
      const contactPerson = _.get(
        retrievedData,
        "vendor_data.contact_details",
        {}
      );
      setForm({
        ...retrievedData,
        vendor_data: {
          ...retrievedData.vendor_data,
          contact_details: !_.isEmpty(contactPerson)
            ? contactPerson
            : {
                title: "",
                first_name: "",
                last_name: "",
                email: "",
              },
        },
      });
      setAutocompleteValue(_.get(data, "data.member"));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const handleInputChange = (e) => {
    setForm((p) => ({
      ...p,
      [e.target.name]:
        e.target.name === "config_uid" ? [e.target.value] : e.target.value,
    }));
  };
  const handleVendorDataInputChange = (e) => {
    setForm((p) => ({
      ...p,
      vendor_data: {
        ..._.get(form, "vendor_data", {}),
        [e.target.name]: e.target.value,
      },
    }));
  };
  const handleInputContactPersonChange = (e) => {
    setForm((p) => ({
      ...p,
      vendor_data: {
        ..._.get(form, "vendor_data", {}),
        contact_details: {
          ..._.get(form, "vendor_data.contact_details", {}),
          [e.target.name]: e.target.value,
        },
      },
    }));
  };

  const mutation = useMutation({
    mutationFn: async ({ id, payload }) => {
      await validationSchema.validate(form);
      if (mode === "add") {
        return createNewFlight({ payload: { ...payload } });
      } else {
        return patchFlight({ id, payload: { ...payload } });
      }
    },
    onSuccess: () => {
      toast.success(
        mode === "add" ? "New Flight added" : "Vendor Flights info updated"
      );
      queryClient.invalidateQueries("vendorFlights");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const validationSchema = getSchema({
    required_message: "This field is required.",
    vendor: form.vendor,
  });

  const boolFields = convertStringToBoolean({
    uce_mcre: _.get(form, "vendor_data.uce_mcre"),
    is_own: _.get(form, "is_own"),
    is_global: _.get(form, "vendor_data.is_global"),
    ticket_after_booking: _.get(form, "vendor_data.ticket_after_booking"),
  });

  const numericFields = ["notify_amount"];
  const updatedNumericFields = convertStringToNumber(form, numericFields);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(form, { abortEarly: false });
      mutation.mutate({
        id: flightId,
        payload: {
          ...form,
          ...updatedNumericFields,
          config_uid: _.isEmpty(_.get(form, "config_uid.[0]", []))
            ? []
            : [_.get(form, "config_uid[0]", [])],
          member: _.get(form, "member"),
          vendor_data: {
            ...form.vendor_data,
            ...boolFields,
          },
        },
      });
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const loading = mutation.isLoading || (isFetchingLoading && mode === "edit");
  return (
    <div id="AddEditFLVendorModal" className={classes.EditFlightModal}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} Flight Vendor</span>
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.loader}>
              <Loader />
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} Flight Vendor</span>
          </div>
          <div className={classes.modalCardBody}>
            <form>
              <div className={classes.cardHeader}>Basic Data</div>
              <div className={classes.cardBody}>
                <div className={classes.inputGroup}>
                  <label>Vendor</label>
                  <select
                    id="vendor"
                    name="vendor"
                    value={form.vendor}
                    disabled={mode === "edit"}
                    onChange={handleInputChange}>
                    {Object.entries(vendorsMapping).map(([key, label], idx) => (
                      <option key={idx} value={key}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="member">Member</label>
                  <div className={classes.entityAutoComplete}>
                    <EntityAutocomplete
                      id="memberAutocomplete"
                      disabled={mode === "edit"}
                      subagents={false}
                      independentAgents={false}
                      branches={false}
                      value={autocompleteValue}
                      setValue={setAutocompleteValue}
                      onChange={(text) => {
                        if (text.length === 0) {
                          setForm((p) => ({
                            ...p,
                            member: "",
                          }));
                          return;
                        }
                        const entityInfos = text.split("---");
                        const entityId = entityInfos[1];
                        setForm((p) => ({
                          ...p,
                          member: `${entityId}`,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="status">Status</label>
                  <select
                    id="status"
                    name="status"
                    value={form.status}
                    onChange={handleInputChange}>
                    {Object.entries(statusMapping).map(
                      ([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="market_type">Market Type</label>
                  <select
                    id="market_type"
                    name="market_type"
                    value={_.get(form, "market_type")}
                    onChange={handleInputChange}>
                    {Object.entries(marketTypeMapping).map(
                      ([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="currency">Currency</label>
                  <select
                    id="currency"
                    name="currency"
                    value={_.get(form, "vendor_data.currency")}
                    onChange={handleVendorDataInputChange}>
                    {Object.entries(currencies).map(([value, label], idx) => (
                      <option key={idx} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={classes.inputGroup}>
                  <label>Notify Amount</label>
                  <input
                    type="number"
                    name="notify_amount"
                    id="notify_amount"
                    value={_.get(form, "notify_amount")}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label>Config uid</label>
                  <input
                    id="config_uid"
                    type="text"
                    name="config_uid"
                    value={_.get(form, "config_uid[0]")}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="is_own">Is Own</label>
                  <select
                    id="is_own"
                    name="is_own"
                    value={_.get(form, "is_own")}
                    onChange={handleInputChange}>
                    {Object.entries(trueFalseMapping).map(
                      ([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <div>
                <Deliveries form={form} onChange={setForm} />
              </div>
              <div className={classes.cardHeader}>Access Data</div>
              <div className={classes.cardBody}>
                <div className={classes.inputGroup}>
                  <label htmlFor="username">Username</label>
                  <input
                    id="username"
                    type="text"
                    name="username"
                    value={_.get(form, "vendor_data.username")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="password">Password</label>
                  <input
                    id="password"
                    type="text"
                    name="password"
                    value={_.get(form, "vendor_data.password")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="app_key">App Key</label>
                  <input
                    id="app_key"
                    type="text"
                    name="app_key"
                    value={_.get(form, "vendor_data.app_key")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="app_secret">App Secret</label>
                  <input
                    id="app_secret"
                    type="text"
                    name="app_secret"
                    value={_.get(form, "vendor_data.app_secret")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
              </div>
              <div className={classes.cardHeader}>Contact Person</div>
              <div className={classes.cardBody}>
                <div className={classes.inputGroup}>
                  <label>Gender Title</label>
                  <select
                    id="title"
                    name="title"
                    value={_.get(form, "vendor_data.contact_details.title")}
                    onChange={handleInputContactPersonChange}>
                    {Object.entries(titleMapping).map(([key, label], idx) => (
                      <option key={idx} value={key}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={classes.inputGroup}>
                  <label>First Name</label>
                  <input
                    id="first_name"
                    type="text"
                    name="first_name"
                    value={_.get(
                      form,
                      "vendor_data.contact_details.first_name"
                    )}
                    onChange={handleInputContactPersonChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label>Last Name</label>
                  <input
                    id="last_name"
                    type="text"
                    name="last_name"
                    value={_.get(form, "vendor_data.contact_details.last_name")}
                    onChange={handleInputContactPersonChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label>Email</label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    value={_.get(form, "vendor_data.contact_details.email")}
                    onChange={handleInputContactPersonChange}
                  />
                </div>
              </div>
            </form>
            {/* <div className={classes.card}> */}
            <div>
              <div className={classes.cardHeader}>Vendor Data</div>
              <div className={classes.cardBody}>
                {/* <div className={classes.cardHeader}>Vendor Data</div> */}
                <div className={classes.inputGroup}>
                  <label htmlFor="name">Name</label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    value={_.get(form, "vendor_data.name")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label>User Id</label>
                  <input
                    id="user_id"
                    type="text"
                    name="user_id"
                    value={_.get(form, "vendor_data.user_id")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label>Source Entity</label>
                  <input
                    type="text"
                    name="source_entity"
                    disabled
                    value={`${_.get(form, "member")}___member`}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="user">User</label>
                  <input
                    id="user"
                    type="text"
                    name="user"
                    value={_.get(form, "vendor_data.user")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>

                <div className={classes.inputGroup}>
                  <label htmlFor="primary_key">Primary Key</label>
                  <input
                    id="primary_key"
                    type="text"
                    name="primary_key"
                    value={_.get(form, "vendor_data.primary_key")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="secondary_key">Secondary Key</label>
                  <input
                    id="secondary_key"
                    type="text"
                    name="secondary_key"
                    value={_.get(form, "vendor_data.secondary_key")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="uce_mcre">Airline</label>
                  <select
                    id="airline"
                    name="airline"
                    value={_.get(form, "vendor_data.airline")}
                    onChange={handleVendorDataInputChange}>
                    {Object.entries(airlineOptions).map(
                      ([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className={classes.inputGroup}>
                  <label>Office Id</label>
                  <input
                    id="office_id"
                    type="text"
                    name="office_id"
                    value={_.get(form, "vendor_data.office_id")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label>Agent Id</label>
                  <input
                    id="agent_id"
                    type="text"
                    name="agent_id"
                    value={_.get(form, "vendor_data.agent_id")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="uce_mcre">UCE MCRE</label>
                  <select
                    id="uce_mcre"
                    name="uce_mcre"
                    value={_.get(form, "vendor_data.uce_mcre")}
                    onChange={handleVendorDataInputChange}>
                    {Object.entries(trueFalseMapping).map(
                      ([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="mcre_branch">MCRE Branch</label>
                  <input
                    id="mcre_branch"
                    type="text"
                    name="mcre_branch"
                    value={_.get(form, "vendor_data.mcre_branch")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="crs_id">CRS Id</label>
                  <input
                    id="crs_id"
                    type="text"
                    name="crs_id"
                    value={_.get(form, "vendor_data.crs_id")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="force_source_type">Force source type</label>
                  <select
                    id="force_source_type"
                    name="force_source_type"
                    value={_.get(form, "vendor_data.force_source_type")}
                    onChange={handleVendorDataInputChange}>
                    {Object.entries(forceSourceTypeMapping).map(
                      ([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      )
                    )}
                  </select>
                </div>

                <div className={classes.inputGroup}>
                  <label htmlFor="queue">Queue</label>
                  <input
                    id="queue"
                    type="text"
                    name="queue"
                    value={_.get(form, "vendor_data.queue")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="account_number">Account Number</label>
                  <input
                    id="account_number"
                    type="text"
                    name="account_number"
                    value={_.get(form, "vendor_data.account_number")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="ticket_after_booking">
                    Ticket After Booking
                  </label>
                  <select
                    id="ticket_after_booking"
                    name="ticket_after_booking"
                    value={_.get(form, "vendor_data.ticket_after_booking")}
                    onChange={handleVendorDataInputChange}>
                    {Object.entries(trueFalseMapping).map(
                      ([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="max_searches">Max Searches</label>
                  <input
                    id="max_searches"
                    type="number"
                    name="max_searches"
                    value={_.get(form, "vendor_data.max_searches")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="max_bookings">Max Bookings</label>
                  <input
                    id="max_bookings"
                    type="number"
                    name="max_bookings"
                    value={_.get(form, "vendor_data.max_bookings")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="reeived_from">Received From</label>
                  <input
                    id="reeived_from"
                    type="text"
                    name="reeived_from"
                    value={_.get(form, "vendor_data.reeived_from")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="country_code">Country</label>
                  <select
                    id="country_code"
                    name="country_code"
                    value={_.get(form, "vendor_data.country_code")}
                    onChange={handleVendorDataInputChange}>
                    {Object.entries(countries).map(([value, label], idx) => (
                      <option key={idx} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="pcc">PCC</label>
                  <input
                    id="pcc"
                    type="text"
                    name="pcc"
                    value={_.get(form, "vendor_data.pcc")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="gds">GDS</label>
                  <select
                    id="gds"
                    name="gds"
                    value={_.get(form, "vendor_data.gds")}
                    onChange={handleVendorDataInputChange}>
                    {Object.entries(gdsMapping).map(([value, label], idx) => (
                      <option key={idx} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={classes.inputGroup}>
                  <label>Ipcc</label>
                  <input
                    id="ipcc"
                    type="text"
                    name="ipcc"
                    value={_.get(form, "vendor_data.ipcc")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <label htmlFor="environment">Environment</label>
                  <select
                    id="environment"
                    name="environment"
                    value={_.get(form, "vendor_data.environment")}
                    onChange={handleVendorDataInputChange}>
                    {Object.entries(environmentMapping).map(
                      ([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      )
                    )}
                  </select>
                </div>

                <div className={classes.inputGroup}>
                  <label htmlFor="api_url">Api URL</label>
                  <input
                    id="api_url"
                    type="text"
                    name="api_url"
                    value={_.get(form, "vendor_data.api_url")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>

                <div className={classes.inputGroup}>
                  <label htmlFor="customer_id">Customer Id</label>
                  <input
                    id="customer_id"
                    type="text"
                    name="customer_id"
                    value={_.get(form, "vendor_data.customer_id")}
                    onChange={handleVendorDataInputChange}
                  />
                </div>

                <div className={classes.inputGroup}>
                  <label htmlFor="is_global">Is Global</label>
                  <select
                    id="is_global"
                    name="is_global"
                    value={_.get(form, "vendor_data.is_global")}
                    onChange={handleVendorDataInputChange}>
                    {Object.entries(trueFalseMapping).map(
                      ([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="submit"
              appearance="primary"
              text="Submit"
              onClick={onSubmit}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditFlightModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditFlightModal;
