import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  cardStyles,
  tableStyles,
  variables,
} from "../../../../../variables";

/**
 * @param {Array} websites
 * @returns {Array} apiConfigs
 * @description
 * Reads the websites and returns the API configs of all websites.
 */
const getAPIConfigs = (websites) => {
  let apiConfigs = [];
  websites.forEach((website) => {
    if (website.auth_api_configs) {
      apiConfigs = [...apiConfigs, ...website.auth_api_configs];
    }
  });
  return apiConfigs;
};

const styles = createUseStyles({
  ...cardStyles,
  WebsitesAPIConfig: { ...cardStyles.card },
  header: {
    ...cardStyles.header,
    padding: variables.normal_gap,
    fontSize: "1.2rem",
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  tableContainer: {
    display: "block",
    maxHeight: "20rem",
    overflow: "auto",
    width: "100%",
  },
  table: {
    width: "100%",
  },
  thead: {
    position: "sticky",
    top: 0,
  },
  emptyWebsites: {
    color: "red",
    textAlign: "center",
    minHeight: "10rem",
    lineHeight: "10rem",
  },
  red: { color: "red" },
  actionCell: genericIndexesStyles.actionCell.mobile,
});
const WebsitesAPIConfig = ({ websites }) => {
  const classes = styles();

  const apiConfigs = getAPIConfigs(websites);

  return (
    <div className={classes.WebsitesAPIConfig}>
      <div className={classes.header}>
        <span>Websites Authentication API Configs</span>
      </div>
      <div className={classes.tableContainer}>
        {apiConfigs.length === 0 ? (
          <div className={classes.emptyWebsites}>
            <strong>No Websites Authentication API Configs</strong>
          </div>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Website</th>
                <th className={classes.headCell}>Domain</th>
                <th className={classes.headCell}>Email Sender</th>
                <th className={classes.headCell}>New User Trial</th>
                <th className={classes.headCell}>Trial Period Days</th>
                <th className={classes.headCell}>With Subscription</th>
              </tr>
            </thead>
            <tbody>
              {apiConfigs.map((apiConf, idx) => (
                <tr key={apiConf.id}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  <td className={classes.tableCell}>
                    {apiConf.website || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {apiConf.domain || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {apiConf.email_sender || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {apiConf.new_user_trial ? (
                      "Yes"
                    ) : (
                      <span className={classes.red}>{"No"}</span>
                    )}
                  </td>
                  <td className={classes.tableCell}>
                    {apiConf.trial_period_days === 0
                      ? "0 days"
                      : `${apiConf.trial_period_days} day${
                          apiConf.trial_period_days ? "s" : ""
                        }` || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {apiConf.with_subscription ? (
                      "Yes"
                    ) : (
                      <span className={classes.red}>{"No"}</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
WebsitesAPIConfig.propTypes = {
  websites: PropTypes.array.isRequired,
  emailConfigs: PropTypes.array.isRequired,
};
export default WebsitesAPIConfig;
