import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  formSections,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import { statusMapping } from "../../common";
import Loader from "../../../../../components/Loader";
import {
  createWebSiteContactInfo,
  patchWebSiteContactInfo,
  retrieveWebSiteContactInfo,
} from "../../../../../api/DistributionSite/webSites/ContactInfo";
import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import * as yup from "yup";
import { handleValidationErrors } from "../../../../../helpers";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Must be at least 2 characters")
    .max(100, "Must be less than 100 characters")
    .default(""),
  status: yup
    .mixed()
    .oneOf(["AC", "IN", "CA"], "Must be between Active, Inactive or Candidate")
    .default("AC"),
  address: yup.string().default(""),
  phone: yup
    .string()
    .transform((value) => (value === "" ? undefined : value))
    .max(20, "Cannot be greater than 20 digits")
    .nullable()
    .default(null),
  emergency_phone: yup
    .string()
    .transform((value) => (value === "" ? undefined : value))
    .max(20, "Cannot be greater than 20 digits")
    .notRequired()
    .nullable()
    .default(null),
  email: yup.string().email("Invalid email address").nullable(),
  facebook_url: yup.string().url().nullable().default(null),
  instagram_url: yup.string().url().nullable().default(null),
  linkedin_url: yup.string().url().nullable().default(null),
  tiktok_url: yup.string().url().nullable().default(null),
  twitter_url: yup.string().url().nullable().default(null),
  weibo_url: yup.string().url().nullable().default(null),
  registration_info: yup.string().default(""),
  registration_number: yup.string().default(""),
});

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditContactInfo: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  loader: { width: "100vw" },
  inputGroup: formInputGroup,
  formSectionHeader: formSections.sectionHeader,
  cardActions: { ...cardStyles.actions, gridColumn: "2 span" },

  [`@media ${variables.media.smallscreen}`]: {
    cardBody: {
      minWidth: "50rem",
      justifyContent: "center",
      gridTemplateColumns: "repeat(2,1fr)",
    },
    cardCol2: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: variables.normal_gap,
    },
    cardCol3: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      gap: variables.normal_gap,
    },
  },
  [`@media ${variables.media.bigscreen}`]: {},
});

const EditContactInfo = ({ websiteId, id, onClose }) => {
  const mode = id ? "edit" : "add";
  const classes = styles();

  const [form, setForm] = useState(jsonSchema.cast({}));

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["RetrieveContactInfo", id],
    queryFn: () => retrieveWebSiteContactInfo({ id: id }),

    onSuccess: (data) => {
      setForm(jsonSchema.cast(_.get(data, "data", {})));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    enabled: mode === "edit",
  });

  const editMutation = useMutation({
    mutationFn: ({ payload, id }) => {
      return patchWebSiteContactInfo({
        id,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("Contact Info Updated");
      queryClient.invalidateQueries("contactInfoList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const addMutation = useMutation({
    mutationFn: ({ payload, website }) => {
      return createWebSiteContactInfo({
        payload: { ...payload, website },
      });
    },
    onSuccess: () => {
      toast.success("New Contact Info added");
      queryClient.invalidateQueries("contactInfoList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ id, payload, website }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload, website });
      } else {
        editMutation.mutate({ id, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  return (
    <div className={classes.EditContactInfo}>
      {loading ? (
        <React.Fragment>
          <div className={classes.modalCard}>
            <div className={classes.modalCardHeader}>
              {_.startCase(mode)} Contact Info
            </div>
            <div className={classes.modalCardBody}>
              <Loader />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            {_.startCase(mode)} Contact Info
          </div>
          <div className={classes.modalCardBody}>
            <form
              onChange={(e) =>
                setForm((p) => ({
                  ...p,
                  [e.target.name]: e.target.value,
                }))
              }>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="name">Name</label>
                      <input type="text" name="name" defaultValue={form.name} />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        value={form.status}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(statusMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Contact Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="address">Address</label>
                      <input
                        type="text"
                        name="address"
                        defaultValue={form.address}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="phone">Phone</label>
                      <input
                        type="number"
                        name="phone"
                        defaultValue={form.phone}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="emergency_phone">Emergency Phone</label>
                      <input
                        type="number"
                        name="emergency_phone"
                        defaultValue={form.emergency_phone}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        defaultValue={form.email}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="facebook_url">Facebook</label>
                      <input
                        type="text"
                        name="facebook_url"
                        defaultValue={form.facebook_url}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="instagram_url">Instagram</label>
                      <input
                        type="text"
                        name="instagram_url"
                        defaultValue={form.instagram_url}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="linkedin_url">Linkedin</label>
                      <input
                        type="text"
                        name="linkedin_url"
                        defaultValue={form.linkedin_url}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="tiktok_url">Tiktok</label>
                      <input
                        type="text"
                        name="tiktok_url"
                        defaultValue={form.tiktok_url}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="twitter_url">Twitter</label>
                      <input
                        type="text"
                        name="twitter_url"
                        defaultValue={form.twitter_url}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="weibo_url">Weibo</label>
                      <input
                        type="text"
                        name="weibo_url"
                        defaultValue={form.weibo_url}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Register Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="address">Registration Info</label>
                      <input
                        type="text"
                        name="registration_info"
                        defaultValue={form.registration_info}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="registration_number">
                        Registration Number
                      </label>
                      <input
                        type="number"
                        name="registration_number"
                        defaultValue={form.registration_number}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  id: id,
                  payload: form,
                  website: websiteId,
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditContactInfo.propTypes = {
  id: PropTypes.number,
  websiteId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditContactInfo;
