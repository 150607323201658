import React, { useState } from "react";
import { inputGroup, variables } from "../../variables";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { onEnterDown } from "../../helpers";
import { CustomButton } from "../../components/CustomButton";

const navbarStyles = createUseStyles({
  Navbar: {
    display: "grid",
    gridAutoFlow: "row",
    padding: variables.normal_gap,
    textAlign: "center",
    background: variables.colors.base,
    paddingTop: "2rem",
    gridGap: variables.half_gap,
  },
  label: { fontWeight: "bold", fontSize: "large" },
  divider: {
    width: "100%",
    height: "0%",
    border: `1px solid ${variables.colors.border.dark}`,
  },
  filters: {
    display: "grid",
    gridTemplateColumns: "repeat (2, 1fr)",
    gridGap: variables.half_gap,
    alignItems: "end",
    justifyItems: "center",
    padding: variables.normal_gap,
  },
  actions: { display: "grid", gridGap: variables.normal_gap },
  inputGroup: {
    ...inputGroup,
    width: "100%",
    gridColumn: "2 span",
    "& label": {
      fontWeight: "bold",
      color: "white !important",
    },
    "& input, select": {
      ...inputGroup["& input, select"],
      width: "10rem",
    },
  },
  inputGroupOneLine: {
    ...inputGroup,
    gridColumn: "2 span",
    width: "100%",
    "& label": {
      fontWeight: "bold",
      color: "white !important",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    Navbar: {
      gridTemplateColumns: "1fr max-content",
      alignItems: "center",
      overflow: "auto",
      justifyContent: "start",
      fontSize: "small",
      padding: variables.normal_gap,
      paddingTop: variables.half_gap,
      paddingBottom: variables.half_gap,
    },
    divider: {
      gridColumn: "1 span",
      alignSelf: "center",
      height: "100%",
      width: "0%",
    },
    filters: {
      padding: variables.half_gap,
      alignItems: "end",
      paddingTop: variables.half_gap,
      paddingBottom: variables.half_gap,
      justifyItems: "start",
      width: "15rem",
    },
    actions: {
      display: "grid",
      padding: variables.half_gap,
      alignItems: "end",

      gridAutoFlow: "column",
      gridGap: variables.normal_gap,
      height: "100%",
      boxSizing: "border-box",
    },
    inputGroup: {
      ...inputGroup.bigscreen,
      gridColumn: "1 span",
      "& input, select": {
        width: "10rem",
      },
    },
    inputGroupOneLine: { ...inputGroup.bigscreen, gridColumn: "1 span" },
  },
  [`@media ${variables.media.bigscreen}`]: {},
});
const Navbar = ({
  filters,
  initialFilters,
  actionsMapping,
  onApplyFilters,
}) => {
  const classes = navbarStyles();
  const [localFilters, setLocalFilters] = useState(filters);

  return (
    <div className={classes.Navbar}>
      <div className={classes.filters}>
        <div className={classes.inputGroupOneLine}>
          <label>Actions</label>
          <select
            value={localFilters.action}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                action: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            <option value={""}>-----</option>
            {Object.keys(actionsMapping).map((actionKey, idx) => (
              <option key={idx} value={actionKey}>
                {actionsMapping[actionKey].label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={classes.actions}>
        <CustomButton
          appearance="filterGhost"
          onClick={() => {
            setLocalFilters({
              ...initialFilters,
            });
          }}>
          <strong>Reset</strong>
        </CustomButton>
        <CustomButton
          appearance="filterPrimary"
          onClick={() => onApplyFilters(localFilters)}>
          <strong>Apply Filters</strong>
        </CustomButton>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  filters: PropTypes.object.isRequired,
  initialFilters: PropTypes.object.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  actionsMapping: PropTypes.object.isRequired,
};
export default Navbar;
