import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  formSections,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import { statusMapping } from "../../common";
import Loader from "../../../../../components/Loader";
import {
  createWebSiteRestaurantPromo,
  patchWebSiteRestaurantPromo,
  retrieveWebSiteRestaurantPromo,
} from "../../../../../api/DistributionSite/webSites/RestaurantPromos";
import _ from "lodash";
import { DateTime } from "luxon";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import * as yup from "yup";
import { handleValidationErrors } from "../../../../../helpers";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  status: yup
    .mixed()
    .oneOf(["AC", "IN", "CA"], "Must be between Active, Inactive or Candidate"),
  featured_restaurant: yup.string(),
  // featured_supplier: yup
  //   .mixed()
  //   .oneOf(["opentable"], "Must be between Opentable"),
});

const initialForm = {
  name: "",
  status: "",
  active_from: null,
  active_to: null,
  featured_supplier: "opentable",
  featured_restaurant: "",
};

const featuredSupplierMapping = {
  "": "-----",
  opentable: "Opentable",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditRestaurantPromos: {
    ...modalGenericStyles.modal,
    gridGap: variables.normal_gap,
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    minHeight: "5rem",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  loader: { width: "100vw" },
  inputGroup: formInputGroup,
  inputGroupOneLine: {
    ...formInputGroup,
    gridColumn: "2 span",
    textAlign: "center",
  },
  inputGroupOneLineBtn: {
    display: "grid",
    gridColumn: "span 2",
    gap: variables.half_gap,
    paddingBottom: variables.half_gap,
    justifyContent: "end",
    gridTemplateColumns: "repeat(2, auto)",
  },
  formSectionHeader: formSections.sectionHeader,
  cardActions: { ...cardStyles.actions, gridColumn: "2 span" },
  [`@media ${variables.media.smallscreen}`]: {
    cardCol2: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: variables.normal_gap,
    },
    cardCol3: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      gap: variables.normal_gap,
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    cardCol3: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      gap: variables.normal_gap,
    },
  },
});

const EditRestaurantPromos = ({ websiteId, id, onClose }) => {
  const classes = styles();
  const mode = id ? "edit" : "add";

  const [form, setForm] = useState(initialForm);

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["RetrieveResPromo", id],
    queryFn: () => retrieveWebSiteRestaurantPromo({ id: id }),

    onSuccess: (data) => {
      setForm(_.get(data, "data", {}));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    enabled: mode === "edit",
  });

  const editMutation = useMutation({
    mutationFn: ({ payload, id, website }) => {
      return patchWebSiteRestaurantPromo({
        id,
        payload: {
          ...payload,
          website,
        },
      });
    },
    onSuccess: () => {
      toast.success("Restaurant Promo Info Updated");
      queryClient.invalidateQueries("RestaurantPromosList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const addMutation = useMutation({
    mutationFn: ({ payload, website }) => {
      return createWebSiteRestaurantPromo({
        payload: {
          ...payload,
          website,
        },
      });
    },
    onSuccess: () => {
      toast.success("Restaurant Promo Page Added");
      queryClient.invalidateQueries("RestaurantPromosList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ id, payload, website }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload, website });
      } else {
        editMutation.mutate({ id, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };
  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  return (
    <div className={classes.EditRestaurantPromos}>
      {retrieveLoading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>Restaurant Promo</span>
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          {loading && <Loader onTop={true} />}
          <div className={classes.modalCardHeader}>
            {_.startCase(mode)} Restaurant Promo
          </div>
          <div className={classes.modalCardBody}>
            <form
              onChange={(e) =>
                setForm((p) => ({
                  ...p,
                  [e.target.name]: e.target.value,
                }))
              }>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="name">Name</label>
                      <input type="text" name="name" defaultValue={form.name} />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="featured_supplier">
                        Featured Supplier
                      </label>
                      <select
                        name="featured_supplier"
                        value={form.featured_supplier}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(featuredSupplierMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="featured_restaurant">
                        Featured Restaurant
                      </label>
                      <input
                        type="text"
                        name="featured_restaurant"
                        defaultValue={form.featured_restaurant}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Active Data</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="active_from">Active From</label>
                      <input
                        type="date"
                        name="active_from"
                        value={
                          form.active_from
                            ? DateTime.fromISO(form.active_from).toISODate()
                            : "N/A"
                        }
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="active_to">Active To</label>
                      <input
                        type="date"
                        name="active_to"
                        value={
                          form.active_to
                            ? DateTime.fromISO(form.active_to).toISODate()
                            : "N/A"
                        }
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        value={form.status}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(statusMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  id: id,
                  payload: form,
                  website: websiteId,
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditRestaurantPromos.propTypes = {
  id: PropTypes.number,
  websiteId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditRestaurantPromos;
