import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../components/cards/GenericBasicInfo";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";
import { deleteAccApiConfiguration } from "../../../../api/AccEngine/APIConfigurations";
import _ from "lodash";
import { DateTime } from "luxon";
import Loader from "../../../../components/Loader";
import { CustomButton } from "../../../../components/CustomButton";

const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteAccEngineAPIConfigurationModal: modalGenericStyles.modal,
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    gridTemplateColumns: "repeat(2, max-content)",
  },
});

const DeleteAccEngineAPIConfigurationModal = ({
  deletedUid,
  data,
  onClose,
}) => {
  const classes = styles();
  const queryClient = useQueryClient();

  const basicDataCpl = [
    ["Uid", "uid"],
    ["Username", null, _.capitalize(_.get(data, "username"))],
    ["Password", "password"],
    ["Name", null, _.capitalize(_.get(data, "name"))],
    ["Supplier", null, _.capitalize(_.get(data, "supplier", ""))],
    ["Market Type", "market_type"],
    ["Source Entity", "source_entity"],
    ["API Curency", "api_currency"],
    [
      "Created",
      null,
      DateTime.fromISO(_.get(data, "created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    ["Status", null, _.get(statusMapping, _.get(data, "status"))],
  ];

  const deleteAccApiConfigurationMutation = useMutation({
    mutationFn: deleteAccApiConfiguration,
    onSuccess: () => {
      toast.success("Accommodator Api Configuration Deleted");
      onClose();
      queryClient.invalidateQueries("AccommodatorAPIConfigurations");
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const onDelete = (uid) => {
    deleteAccApiConfigurationMutation.mutate({ uid });
  };
  return (
    <div
      id="DeleteAccEngineModal"
      className={classes.DeleteAccEngineAPIConfigurationModal}>
      <React.Fragment>
        <div className={classes.modalCard}>
          {deleteAccApiConfigurationMutation.isLoading && (
            <Loader onTop={true} />
          )}
          <div className={classes.modalCardHeader}>
            <span>DELETE Accommodation Engine Api Configuration</span>
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.card}>
              <div className={classes.cardBody}>
                <div className={classes.basicInfo}>
                  <GenericBasicInfo
                    header={"Are you sure you want to delete this?"}
                    cardInfo={basicDataCpl}
                    objectData={data}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.cardActions}>
            <CustomButton
              id="DeleteAccEngineModalCloseButton"
              appearance="ghost"
              onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="DeleteAccEngineModalConfirmButton"
              appearance="primary"
              onClick={() => {
                onDelete(deletedUid);
              }}>
              <strong>Delete</strong>
            </CustomButton>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
DeleteAccEngineAPIConfigurationModal.propTypes = {
  deletedUid: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DeleteAccEngineAPIConfigurationModal;
