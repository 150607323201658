import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import {
  createCarEngineAPIConfigurations,
  patchCarEngineAPIConfigurations,
  retrieveCarEngineAPIConfigurations,
} from "../../../../api/CarRentalEngine/ApiConfig";
import Loader from "../../../../components/Loader";
import {
  cardStyles,
  formInputGroup,
  formSections,
  inputGroup,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";
import EntityAutocomplete from "../../../../components/EntityAutocomplete";
import {
  CarRentalMarketTypeMapping,
  CarRentalSupplierMapping,
  CarRentalTrueFalseMapping,
} from "../../common";
import {
  APIConfigsStatusMapping,
  convertStringToBoolean,
  handleValidationErrors,
} from "../../../../helpers";
import { CustomButton } from "../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Must be at least 2 characters")
    .max(100, "Must be less than 100 characters")
    .nullable()
    .default(""),
  supplier: yup
    .string()
    // .oneOf(["carnect"], "Supplier: Must be Carnect")
    .required("Must not be blanc")
    .default(""),
  market_type: yup
    .string()
    .nullable()
    .oneOf(["B2B", "B2C"], "Market Type: Must be between B2B or B2C")
    .default(""),
  is_live: yup.bool("Must be a valid boolean").default(false),
  global_account: yup.bool("Must be a valid boolean").nullable().default(false),
  is_own: yup.bool("Must be a valid boolean").nullable().default(false),
  is_master_account: yup.boolean().nullable().default(false),
  website_api_key: yup.string().nullable().default(""),
  username: yup.string().nullable().default(""),
  password: yup.string().nullable().default(""),
  application_key: yup.string().nullable().default(""),
  access_token: yup.string().nullable().default(""),
  api_url: yup.string().required("Must not be blanc").default(""),
  api_key: yup.string().required("Must not be blanc").default(""),
});

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditApiConfigurationCarEngineModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content  1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  inputGroup: formInputGroup,
  entityAutoComplete: {
    ...inputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  cardActions: {
    ...cardStyles.actions,
    gridColumn: "2 span",
  },

  formSectionHeader: formSections.sectionHeader,
  [`@media ${variables.media.smallscreen}`]: {
    modalCard: {
      minWidth: "50rem",
    },
    cardCol2: {
      gridTemplateColumns: "repeat(2 , 1fr)",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {},
});
const EditApiConfigurationCarEngineModal = ({ apiConfigId, onClose }) => {
  const classes = styles();

  const mode = apiConfigId ? "edit" : "add";
  const queryClient = useQueryClient();

  const [form, setForm] = useState(jsonSchema.cast({}));
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["EditApiConfigurationCarEngineModal"],
    queryFn: () => {
      return retrieveCarEngineAPIConfigurations({
        uid: apiConfigId,
      });
    },
    enabled: mode === "edit",
    onSuccess: (data) => {
      setForm(jsonSchema.cast(_.get(data, "data", {}), { stripUnknown: true }));
      setAutocompleteValue(_.get(data, "data.source_entity"));
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const editMutation = useMutation({
    mutationFn: async ({ uid, payload }) => {
      return patchCarEngineAPIConfigurations({ uid, payload });
    },
    onSuccess: () => {
      toast.success("Rental Car Updated");
      queryClient.invalidateQueries("ApiConfigurationCarRental");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const addMutation = useMutation({
    mutationFn: async ({ payload }) => {
      return createCarEngineAPIConfigurations({ payload });
    },
    onSuccess: () => {
      toast.success("Rental Car added");
      queryClient.invalidateQueries("ApiConfigurationCarRental");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const onSubmit = async ({ uid, payload }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });

      if (mode === "add") {
        addMutation.mutate({ payload });
      } else {
        editMutation.mutate({ uid, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };
  const handleInputChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const boolFields = convertStringToBoolean({
    is_global: form.is_global,
    is_live: form.is_live,
    is_own: form.is_own,
  });

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  return (
    <div
      id="addEditCAREngineAPIConfigurationModal"
      className={classes.EditApiConfigurationCarEngineModal}>
      <div className={classes.modalCard}>
        {loading && <Loader onTop={true} />}
        <div className={classes.modalCardHeader}>
          <span>{_.startCase(mode)} Car Rental Api configuration</span>
        </div>
        <div className={classes.modalCardBody}>
          <form>
            <div className={classes.card}>
              <div className={classes.cardHeader}>Basic Data</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="name">Name</label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      value={_.capitalize(form.name)}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="source_entity">Source Entity</label>
                    <div className={classes.entityAutoComplete}>
                      <EntityAutocomplete
                        id="entityAutocompleteInputId"
                        subagents={false}
                        independentAgents={false}
                        branches={false}
                        value={autocompleteValue}
                        setValue={setAutocompleteValue}
                        onChange={(text) => {
                          if (text.length === 0) {
                            setForm((p) => ({
                              ...p,
                              source_entity: "",
                            }));
                            return;
                          }
                          const entityInfos = text.split("---");
                          const entityType = entityInfos[0];
                          const entityId = entityInfos[1];
                          setForm((p) => ({
                            ...p,
                            source_entity: `${entityId}___${entityType}`,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="supplier">Supplier</label>
                    <select
                      id="supplier"
                      name="supplier"
                      value={form.supplier}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(CarRentalSupplierMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="market_type">Market Type</label>
                    <select
                      id="market_type"
                      name="market_type"
                      value={form.market_type}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(CarRentalMarketTypeMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className={classes.cardCol3}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="is_live">is Live</label>
                    <select
                      id="is_live"
                      name="is_live"
                      value={form.is_live}
                      onChange={handleInputChange}>
                      {Object.entries(CarRentalTrueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="global_account">Is Global</label>
                    <select
                      id="global_account"
                      name="global_account"
                      value={form.global_account}
                      onChange={handleInputChange}>
                      {Object.entries(CarRentalTrueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="is_own">is Own</label>
                    <select
                      id="is_own"
                      name="is_own"
                      value={form.is_own}
                      onChange={handleInputChange}>
                      {Object.entries(CarRentalTrueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="is_master_account">Is Master Account</label>
                    <select
                      id="is_master_account"
                      name="is_master_account"
                      value={form.is_master_account}
                      onChange={handleInputChange}>
                      {Object.entries(CarRentalTrueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>

                  <div className={classes.inputGroup}>
                    <label>Website API Key</label>
                    <input
                      id="website_api_key"
                      type="text"
                      name="website_api_key"
                      value={form.website_api_key}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="status">Status</label>
                    <select
                      id="status"
                      name="status"
                      value={form.status}
                      onChange={handleInputChange}>
                      {Object.entries(APIConfigsStatusMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.cardHeader}>Access Information</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="username">Username</label>
                    <input
                      id="username"
                      type="text"
                      name="username"
                      value={form.username}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="password">Password</label>
                    <input
                      id="password"
                      type="text"
                      name="password"
                      value={form.password}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="application_key">Application Key</label>
                    <input
                      id="application_key"
                      type="text"
                      name="application_key"
                      value={form.application_key}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="access_token">Access Token</label>
                    <input
                      id="access_token"
                      type="text"
                      name="access_token"
                      value={form.access_token}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className={classes.inputGroup}>
                    <label htmlFor="api_url">API URL</label>
                    <input
                      id="api_url"
                      type="text"
                      name="api_url"
                      value={form.api_url}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="api_key">API Key</label>
                    <input
                      id="api_key"
                      type="text"
                      name="api_key"
                      value={form.api_key}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            id="submit"
            appearance="primary"
            onClick={(e) => {
              e.preventDefault();

              onSubmit({
                uid: apiConfigId,
                payload: { ...form, ...boolFields },
              });
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
EditApiConfigurationCarEngineModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditApiConfigurationCarEngineModal;
