import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  inputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import {
  APIConfigsStatusMapping,
  convertStringToBoolean,
  convertStringToNumber,
  countries,
  currencies,
  handleValidationErrors,
} from "../../../../../helpers";
import { toast } from "react-toastify";
import Loader from "../../../../../components/Loader";
import _ from "lodash";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import {
  createNewAviateApiConfiguration,
  patchAviateApiConfiguration,
  retrieveAviateAPIConfiguration,
} from "../../../../../api/FlightEngine/AviateApiConfiguration";
import EntityAutocomplete from "../../../../../components/EntityAutocomplete";
import { flightEngineTrueFalseMapping } from "../../../common";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Name: Must be at least 2 characters")
    .max(100, "Name: Must be less than 100 characters")
    .nullable()
    .default(""),
  market_type: yup
    .string()
    .nullable()
    .oneOf(["B2B", "B2C"], "Must be between B2B or B2C.")
    .default(""),
  country_code: yup
    .string()
    .required("This field may not be blanc.")
    .default(""),
  currency: yup.string().required("This field may not be blanc.").default(""),
  environment: yup
    .string()
    .required("This field may not be blanc.")
    .default(""),
  is_global: yup.boolean().nullable().default(false),
  is_own: yup.boolean().nullable().default(false),
  website_api_key: yup.string().nullable().default(""),
  pcc: yup
    .string()
    .nullable()
    .min(1, "This field may not be blanc.")
    .default(null),
  gds: yup.string().nullable().oneOf(["AV"], "Must be AV").default("AV"),
  received_from: yup.string().nullable().default(""),
  username: yup.string().required("This field may not be blanc.").default(""),
  password: yup.string().required("This field may not be blanc.").default(""),
  primary_key: yup
    .string()
    .required("This field may not be blanc.")
    .default(""),
  secondary_key: yup
    .string()
    .required("This field may not be blanc.")
    .default(""),
  searches: yup.number().nullable().default(0),
  max_searches: yup.number().nullable().default(0),
  bookings: yup.number().nullable().default(0),
  max_bookings: yup.number().nullable().default(0),
});

const gdsMapping = {
  "": "-----",
  AV: "Aviate",
};
const environmentMapping = {
  "": "-----",
  PROD: "Production",
  STAG: "Staging",
};
const trueFalseMapping = {
  "": "-----",
  true: "Yes",
  false: "No",
};
const marketTypeMapping = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditAviateAPIConfigurationModal: {
    ...modalGenericStyles.modal,
    zIndex: "900",
  },
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardBody: cardStyles.body,
  cardHeader: cardStyles.header,
  inputGroup: {
    ...formInputGroup,
    padding: `${variables.normal_gap} 0`,
  },
  entityAutoComplete: {
    ...inputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCard: {
      minWidth: "50rem",
    },
    cardCol2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    cardCol4: {
      display: "grid",
      gridTemplateColumns: "repeat(4,1fr)",
      gap: variables.normal_gap,
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    modalCard: {
      minWidth: "50rem",
    },
    cardCol2: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: variables.normal_gap,
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    cardCol4: {
      display: "grid",
      gridTemplateColumns: "repeat(4,1fr)",
      gap: variables.normal_gap,
    },
  },
});

const EditAviateAPIConfigurationModal = ({ apiConfigurationId, onClose }) => {
  const classes = styles();
  const mode = apiConfigurationId ? "edit" : "add";

  const [form, setForm] = useState(jsonSchema.cast({}));
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["EditAviateAPIConfigurationModal"],
    queryFn: () => {
      return retrieveAviateAPIConfiguration({
        uid: apiConfigurationId,
      });
    },
    onSuccess: (data) => {
      setForm(_.get(data, "data"));
      setForm(jsonSchema.cast(_.get(data, "data", {}), { stripUnknown: true }));
    },
    onError: (error) => {
      toast.error(error.message);
      onClose();
    },
    enabled: mode === "edit",
  });

  const editMutation = useMutation(
    async ({ uid, payload }) => {
      return await patchAviateApiConfiguration({ uid, payload });
    },
    {
      onSuccess: () => {
        toast.success("Aviate Info Updated");
        queryClient.invalidateQueries("AviateAPIConfigurations");
        onClose();
      },
      onError: (error) => {
        genericRequestErrorHandler(error);
      },
    }
  );

  const addMutation = useMutation(
    async ({ payload }) => {
      return await createNewAviateApiConfiguration({ payload });
    },
    {
      onSuccess: () => {
        toast.success("New Aviate added");
        queryClient.invalidateQueries("AviateAPIConfigurations");
        onClose();
      },
      onError: (error) => {
        genericRequestErrorHandler(error);
      },
    }
  );

  const boolFields = convertStringToBoolean({
    is_global: form.is_global,
    is_own: form.is_own,
  });

  const numericFields = [
    "searches",
    "max_searches",
    "bookings",
    "max_bookings",
  ];
  const updatedNumericFields = convertStringToNumber(form, numericFields);
  const onSubmit = async ({ payload, uid }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload: { ...payload } });
      } else {
        editMutation.mutate({ uid, payload: { ...payload } });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  return (
    <div
      id="addEditAviateFlAPIConfigurationModal"
      className={classes.EditAviateAPIConfigurationModal}>
      {loading && <Loader onTop={true} />}
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>{_.startCase(mode)} Aviate API Configuration</span>
        </div>
        <div className={classes.modalCardBody}>
          <form onChange={handleInputChange}>
            <div className={classes.card}>
              <div className={classes.cardHeader}>Basic Data</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol3}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="name">Name</label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      value={_.capitalize(form.name)}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="source_entity">Source Entity</label>
                    <div className={classes.entityAutoComplete}>
                      <EntityAutocomplete
                        id="entityAutocompleteInputId"
                        subagents={false}
                        independentAgents={false}
                        branches={false}
                        value={autocompleteValue}
                        setValue={setAutocompleteValue}
                        onChange={(text) => {
                          if (text.length === 0) {
                            setForm((p) => ({
                              ...p,
                              source_entity: "",
                            }));
                            return;
                          }
                          const entityInfos = text.split("---");
                          const entityType = entityInfos[0];
                          const entityId = entityInfos[1];
                          setForm((p) => ({
                            ...p,
                            source_entity: `${entityId}___${entityType}`,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className={classes.inputGroup}>
                    <label htmlFor="market_type">Market Type</label>
                    <select
                      id="market_type"
                      name="market_type"
                      value={form.market_type}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(marketTypeMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className={classes.cardCol3}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="country_code">Country</label>
                    <select
                      id="country_code"
                      name="country_code"
                      value={form.country_code}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(countries).map(([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="currency">Currency</label>
                    <select
                      id="currency"
                      name="currency"
                      value={form.currency}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(currencies).map(([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={classes.inputGroup}>
                    <label htmlFor="environment">Environment</label>
                    <select
                      id="environment"
                      name="environment"
                      value={form.environment}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(environmentMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="is_global">Is Global</label>
                    <select
                      id="is_global"
                      name="is_global"
                      value={form.is_global}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(flightEngineTrueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="is_own">Is Own</label>
                    <select
                      id="is_own"
                      name="is_own"
                      value={form.is_own}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(trueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="is_master_account">Is Master Account</label>
                    <select
                      id="is_master_account"
                      name="is_master_account"
                      value={form.is_master_account}
                      onChange={handleInputChange}>
                      {Object.entries(trueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className={classes.cardCol3}>
                  <div className={classes.inputGroup}>
                    <label>Website Api Key</label>
                    <input
                      id="website_api_key"
                      type="text"
                      name="website_api_key"
                      value={form.website_api_key}
                    />
                  </div>

                  <div className={classes.inputGroup}>
                    <label htmlFor="pcc">PCC</label>
                    <input id="pcc" type="text" name="pcc" value={form.pcc} />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="gds">GDS</label>
                    <select
                      id="gds"
                      name="gds"
                      value={form.gds}
                      onChange={() => {
                        return;
                      }}>
                      {Object.entries(gdsMapping).map(([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="received_from">Received From</label>
                    <input
                      id="received_from"
                      type="text"
                      name="received_from"
                      value={form.received_from}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="status">Status</label>
                    <select
                      id="status"
                      name="status"
                      value={form.status}
                      onChange={handleInputChange}>
                      {Object.entries(APIConfigsStatusMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.cardHeader}>Account Information</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="username">Username</label>
                    <input
                      id="username"
                      type="text"
                      name="username"
                      value={form.username}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="password">Password</label>
                    <input
                      id="password"
                      type="text"
                      name="password"
                      value={form.password}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="primary_key">Primary Key</label>
                    <input
                      id="primary_key"
                      type="text"
                      name="primary_key"
                      value={form.primary_key}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="secondary_key">Secondary Key</label>
                    <input
                      id="secondary_key"
                      type="text"
                      name="secondary_key"
                      value={form.secondary_key}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.cardHeader}>Usage data</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol4}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="searches">Searches</label>
                    <input
                      id="searches"
                      type="number"
                      name="searches"
                      value={form.searches}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="max_searches">Max Searches</label>
                    <input
                      id="max_searches"
                      type="number"
                      name="max_searches"
                      value={form.max_searches}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="bookings">Bookings</label>
                    <input
                      id="bookings"
                      type="number"
                      name="bookings"
                      value={form.bookings}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="max_bookings">Max Bookings</label>
                    <input
                      id="max_bookings"
                      type="number"
                      name="max_bookings"
                      value={form.max_bookings}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            id="submit"
            appearance="primary"
            onClick={(e) => {
              e.preventDefault();
              onSubmit({
                uid: apiConfigurationId,
                payload: { ...form, ...updatedNumericFields, ...boolFields },
              });
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
EditAviateAPIConfigurationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditAviateAPIConfigurationModal;
