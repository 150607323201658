import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";

export const CANAVERAL_API_URL = process.env.REACT_APP_CANAVERAL_API_URL;
export const VALAQUENTA_API_URL = process.env.REACT_APP_VALAQUENTA_API_URL;
export const SISI_API_URL = process.env.REACT_APP_SISI_API_URL;

export const getAuthHeaders = ({ jwt_token }) => {
  return { Authorization: `JWT ${jwt_token}` };
};

export function genericRequestError(error) {
  toast.error(
    <p>
      We failed to complete this action. We are sorry for the inconvenience.
      <span>{_.get(error, "response.data.message")}</span>
      <span>{_.get(error, "response.data.error")}</span>
    </p>
  );
}

export function genericFormRequestError(error) {
  toast.error(
    <React.Fragment>
      <p>
        We were unable to complete this action. Please check the errors below.
      </p>
      <br />
      {typeof error === "object" ? (
        <ul>
          {_.flatten(
            Object.entries(error || {}).map(([field, fieldErrors]) => {
              if (Array.isArray(fieldErrors)) {
                return _.flatten(
                  fieldErrors.map((fieldError, idx) => (
                    <li key={`${idx}`}>
                      <strong>{_.startCase(field)}</strong>: {fieldError}
                    </li>
                  ))
                );
              } else {
                return (
                  <li key={field}>
                    <strong>{_.startCase(field)}</strong>: {fieldErrors}
                  </li>
                );
              }
            })
          )}
        </ul>
      ) : (
        <strong>{error}</strong>
      )}
    </React.Fragment>
  );
}

export function genericRequestErrorHandler(error) {
  if (error.code === "ERR_BAD_REQUEST") {
    genericFormRequestError(_.get(error, "response.data"));
  } else {
    genericRequestError(error);
  }
}

export const authorization = (jwt_token) => {
  return getAuthHeaders({ jwt_token });
};
