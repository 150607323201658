import { VALAQUENTA_API_URL } from "../../..";
import { authorizedAxios, loggingAxios } from "../../../../App";

export function fetchVesselAPIConfigurations({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/vesselengine/api-config/`, {
    params,
  });
}

export function retrieveVesselAPIConfiguration({ uid }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/vesselengine/api-config/${uid}/`
  );
}

export function patchVesselApiConfiguration({ uid, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/vesselengine/api-config/${uid}/`,
    payload
  );
}

export function createNewVesselApiConfiguration({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/vesselengine/api-config/`,
    payload
  );
}

export function cloneVesselApiConfiguration({ uid }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/vesselengine/api-config/clone/${uid}/`
  );
}

export function deleteVesselApiConfiguration({ uid }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/vesselengine/api-config/${uid}/`
  );
}
