import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { modalGenericStyles, variables } from "../../../../../variables";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import EmailConfigs from "./EmailConfigs";
import PaymentMethods from "./PaymentMethods";
import WebsitesSection from "./WebsitesSection";
import SubscriptionSection from "./SubscriptionSection";
import VendorsSection from "./VendorsSection";
import APIConfigsSection from "./APIConfigsSection";
import UnpaidInvoicesSection from "./UnpaidInvoicesSection";
import { getMemberOverview } from "../../../../../api/Newspaper";
import Loader from "../../../../../components/Loader";
import { toast } from "react-toastify";
import EntityColorsSection from "./EntityColorsSection";
import PaymentGatewaysSection from "./PaymentGatewaysSection";
import LedgersSection from "./LedgersSection";
import WebsitesAPIConfig from "./WebsitesAPIConfig";
import WebhooksSection from "./WebhooksSection";
import ProductFees from "./ProductFeesSection";
import PaymentTerms from "./PaymentTermsSection";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  BirdsEyeModal: {
    ...modalGenericStyles.modal,
    overflow: "auto",
  },
  card: { ...modalGenericStyles.card, maxWidth: "95vw" },
  cardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
  },
  logo: {
    height: "100px",
    "& img": {
      height: "100%",
      width: "auto",
    },
  },
  basicSection: {
    display: "grid",
    gridGap: variables.normal_gap,
    alignContent: "start",
  },
  separateRow2: {
    display: "grid",
    gridGap: variables.normal_gap,
    alignContent: "start",
  },
  red: {
    color: "red",
  },
  [`@media ${variables.media.smallscreen}`]: {
    basicSection: {
      gridTemplateColumns: "1fr 2fr",
    },
    separateRow2: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
});
const BirdsEyeModal = ({ memberId, onClose }) => {
  const classes = styles();

  const { data: queryData, isLoading } = useQuery({
    queryKey: ["memberBirdsEye", memberId],
    queryFn: () => {
      return getMemberOverview(memberId);
    },
    onError: (error) => {
      const endpoint = _.get(error, "response.data.detail.endpoint", "");
      toast.error(
        <p>
          Error fetching member overview. We are sorry for the inconvenience.
          <br />
          The was an error with the task:{" "}
          <span className={classes.red}>{endpoint}</span>
        </p>,
        {
          duration: 5000,
        }
      );
      onClose();
    },
    retry: false,
  });

  const data = _.get(queryData, "data", {});

  const apiConfigs = _.get(data, "api_configs", {});
  const totalApiConfigs = _.flatten(_.values(apiConfigs));

  const basicDataCpl = [
    [
      "Profile Completeness",
      null,
      `${
        _.get(data, "completeness") === 0
          ? "0"
          : _.get(data, "completeness") || "N/A"
      }%`,
    ],
    [
      "Has Logo",
      null,
      _.isEmpty(_.get(data, "logo", {})) ? (
        <span className={classes.red}>{"No"}</span>
      ) : (
        "Yes"
      ),
    ],
    [
      "Has Entity Colors",
      null,
      _.isEmpty(_.get(data, "entity_colors", {})) ? (
        <span className={classes.red}>{"No"}</span>
      ) : (
        "Yes"
      ),
    ],
    [
      "Inner Users",
      null,
      _.get(data, "inner_users", "0") === 0
        ? "0"
        : _.get(data, "inner_users", "0"),
    ],
    [
      "Sales Partners",
      null,
      _.get(data, "sales_partners", "0") === 0
        ? "0"
        : _.get(data, "sales_partners", "0"),
    ],
    [
      "Total Payment Methods",
      null,
      _.get(data, "payment_methods", []).length === 0
        ? "0"
        : _.get(data, "payment_methods", []).length,
    ],
    [
      "Total Websites",
      null,
      _.get(data, "websites", []).length === 0
        ? "0"
        : _.get(data, "websites", []).length,
    ],
    [
      "Total API Configs",
      null,
      totalApiConfigs.length === 0 ? "0" : totalApiConfigs.length,
    ],
    [
      "Total Email Configs",
      null,
      _.get(data, "email_configs", []).length === 0
        ? "0"
        : _.get(data, "email_configs", []).length,
    ],
    [
      "Total Vendors",
      null,
      _.get(data, "vendors", []).length === 0
        ? "0"
        : _.get(data, "vendors", []).length,
    ],
    [
      "Total Unpaid Invoices",
      null,
      _.get(data, "unpaid_invoices", []).length === 0
        ? "0"
        : _.get(data, "unpaid_invoices", []).length,
    ],
  ];
  return (
    <div className={classes.BirdsEyeModal}>
      {isLoading && <Loader onTop={true} />}
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <span>Birds Eye</span>
        </div>
        <div className={classes.cardBody}>
          {!_.isEmpty(_.get(data, "logo", {})) && (
            <div className={classes.logo}>
              <img src={_.get(data, "logo.photo_sm_url")} alt="" />
            </div>
          )}

          <React.Fragment>
            <div className={classes.basicSection}>
              <GenericBasicInfo
                header="Basic Data"
                cardInfo={basicDataCpl}
                objectData={data}
                costumeStyles={{ header: { fontSize: "1.2rem" } }}
              />
              <PaymentMethods
                paymentMethods={_.get(data, "payment_methods", [])}
              />
            </div>
            <div className={classes.separateRow2}>
              <PaymentGatewaysSection
                paymentGateways={_.get(data, "payment_gateways", {})}
              />
              <EntityColorsSection
                entityColors={_.get(data, "entity_colors", {})}
              />
            </div>
            <SubscriptionSection
              subscriptionData={_.get(data, "subscription", {})}
            />
            <WebsitesSection
              websites={_.get(data, "websites", [])}
              emailConfigs={_.get(data, "email_configs", [])}
            />
            <WebsitesAPIConfig websites={_.get(data, "websites", [])} />
            <EmailConfigs
              memberId={memberId}
              emailConfigs={_.get(data, "email_configs", [])}
              groups_display={_.get(data, "groups_display", [])}
              websites={_.get(data, "websites", [])}
            />
            <VendorsSection
              vendors={_.get(data, "vendors", [])}
              apiConfigs={apiConfigs}
            />
            <APIConfigsSection apiConfigs={apiConfigs} />
            <UnpaidInvoicesSection
              unpaidInvoices={_.get(data, "unpaid_invoices", [])}
            />
            <LedgersSection
              ledgers={_.get(data, "ledgers", [])}
              groups_display={_.get(data, "groups_display", [])}
            />

            <div className={classes.separateRow2}>
              <PaymentTerms
                paymentTerms={_.get(data, "payment_terms", [])}
                groups_display={_.get(data, "groups_display", [])}
              />
              <ProductFees
                productFees={_.get(data, "product_fees", [])}
                groups_display={_.get(data, "groups_display", [])}
              />
            </div>
            <WebhooksSection
              webhooks={_.get(data, "webhooks", [])}
              groups_display={_.get(data, "groups_display", [])}
              memberId={memberId}
            />
          </React.Fragment>
        </div>
        <div className={classes.cardActions}>
          <CustomButton appearance="ghost" onClick={onClose}>
            <strong>Close</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
BirdsEyeModal.propTypes = {
  memberId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default BirdsEyeModal;
