import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  variables,
  modalGenericStyles,
} from "../../../../../../variables";
import { Button } from "../../../../../../components/Button";

const warningModalStyles = createUseStyles({
  ...modalGenericStyles,
  WarningsModal: {
    ...modalGenericStyles.modal,
  },
  mainCard: {
    ...modalGenericStyles.card,
    width: "50rem",
  },
  mainCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  mainCardBody: {
    ...modalGenericStyles.card,
    display: "grid",
    maxHeight: "30rem",
    gap: variables.normal_gap,
    overflow: "auto",
    justifyItems: "center",
  },
  card: {
    ...cardStyles.card,
    width: "80%",
  },
  cardBody: {
    ...cardStyles.body,
  },
  cardHeader: {
    ...cardStyles.header,
  },
});
const WarningsModal = ({ warnings, onClose }) => {
  const classes = warningModalStyles();
  return (
    <div className={classes.WarningsModal}>
      <div className={classes.mainCard}>
        <div className={classes.mainCardHeader}>
          <span>WARNINGS</span>
        </div>
        <div className={classes.mainCardBody}>
          {Object.keys(warnings).map((websiteName, idx) => (
            <div key={idx} className={classes.card}>
              <div className={classes.cardHeader}>
                <strong>{websiteName}</strong>
              </div>
              <div className={classes.cardBody}>
                <ul>
                  {warnings[websiteName].map((warning, idx) => (
                    <li key={`${idx}_${websiteName}`}>{warning}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.cardActions}>
          <Button text="Close" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};
WarningsModal.propTypes = {
  warnings: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default WarningsModal;
