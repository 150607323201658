import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../../../components/cards/GenericBasicInfo";
import Loader from "../../../../../../components/Loader/index";
import {
  deleteDocument,
  retrieveDocument,
} from "../../../../../../api/CRM/Documents";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CustomButton } from "../../../../../../components/CustomButton";
import _ from "lodash";

const deleteDocumentModalStyles = createUseStyles({
  ...modalGenericStyles,
  DeleteDocumentModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    display: "grid",
    gap: variables.normal_gap,
  },
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
  },
});

const DeleteDocumentModal = ({ uid, onClose }) => {
  const classes = deleteDocumentModalStyles();

  const { data, isLoading } = useQuery({
    queryKey: ["DeleteDocumentModal"],
    queryFn: () =>
      retrieveDocument({
        uid: uid,
      }),
    onError: (error) => toast.error(error),
  });

  const basicDataCpl = [
    ["Filename", "filename"],
    ["Path", "path"],
    ["Tags", null, _.get(data, "data.tags_display", []).join(",")],
    ["Creator Username", "creator_metadata.username"],
    ["Creator First Name", "creator_metadata.first_name"],
    ["Creator Last Name", "creator_metadata.last_name"],
    ["Creator Email", "creator_metadata.email"],
    ["Source Entity", "creator_metadata.source_entity"],
  ];

  const deleteDocumentMutation = useMutation(deleteDocument, {
    onSuccess: () => {
      toast.success("Document Deleted");
      onClose();
    },
    onError: (error) => toast.error(error.message),
  });

  const onDelete = async (uid) => {
    await deleteDocumentMutation.mutateAsync({ uid });
  };
  const loading = isLoading || deleteDocumentMutation.isLoading;
  return (
    <div id="DeleteDocumentModal" className={classes.DeleteDocumentModal}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>DELETE Document</span>
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>DELETE Document</span>
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.card}>
              <div className={classes.cardBody}>
                <div className={classes.basicInfo}>
                  <GenericBasicInfo
                    header={"Are you sure you want to delete this?"}
                    cardInfo={basicDataCpl}
                    objectData={_.get(data, "data")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton
              id="DeleteDocumentModalCloseButton"
              appearance="ghost"
              onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="DeleteCAREngineModalConfirmButton"
              appearance="primary"
              onClick={() => {
                onDelete(uid);
              }}>
              <strong>DELETE</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
DeleteDocumentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
};
export default DeleteDocumentModal;
