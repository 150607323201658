import React from "react";
import Logs from "../../../../components/Logs";
import { VALAQUENTA_API_URL } from "../../../../api";

const CarEngineLogsTab = (WrappedComponent) => {
  const Comp = (props) => {
    const actionsMapping = {
      add: {
        label: "Add",
        url: `${VALAQUENTA_API_URL}/carrentengine/api-config/`,
        method: "POST",
      },
      edit: {
        label: "Edit",
        url: `${VALAQUENTA_API_URL}/carrentengine/api-config/`,
        method: "PATCH",
      },
      delete: {
        label: "Delete",
        url: `${VALAQUENTA_API_URL}/carrentengine/api-config/`,
        method: "DELETE",
      },
      clone: {
        label: "Clone",
        url: `${VALAQUENTA_API_URL}/carrentengine/api-config/clone/`,
        method: "POST",
      },
    };

    return <WrappedComponent {...props} actionsMapping={actionsMapping} />;
  };
  return Comp;
};

export default CarEngineLogsTab(Logs);
