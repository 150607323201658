// import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router";
import { GlobalModalContext, links } from "../App";
import { useAuthentication } from "../hooks";
import { variables } from "../variables";
import { CloseHamburgerMenu } from "./HamburgerMenu";
import { CloseSidebarArrow, OpenSidebarArrow } from "./OpenCloseSidebarArrow";
import { Button } from "./Button";

const LoginButton = () => {
  const { loginWithRedirect } = useAuthentication({ info_only: true });

  return (
    <Button text="Log In" isBold={true} onClick={() => loginWithRedirect()} />
  );
};

const logoutButtonStyles = createUseStyles({
  LogoutButton: {
    margin: variables.half_gap,
  },
});
const LogoutButton = () => {
  const classes = logoutButtonStyles();
  const { logout } = useAuthentication({ info_only: false });
  return (
    <React.Fragment>
      <div className={classes.LogoutButton}>
        <Button text="Log Out" isBold={true} onClick={() => logout()} />
      </div>
    </React.Fragment>
  );
};

const userInformationStyles = createUseStyles({
  UserInformation: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: variables.normal_gap,
    alignItems: "center",
    color: variables.colors.text.light,
    padding: variables.normal_gap,
    cursor: "pointer",
    fontSize: "small",
    "&:hover": {
      backgroundColor: variables.colors.background.darkHover,
    },
  },
  userImg: {
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
    objectFit: "cover",
  },
});
const UserInformation = ({ onClick }) => {
  const { user } = useAuthentication({ info_only: true });
  const classes = userInformationStyles();
  return (
    <div className={classes.UserInformation} onClick={onClick}>
      <img src={user.picture} className={classes.userImg} />
      {/* <button className={classes.userButton} onClick={onClick}>
        {user.name}
      </button> */}
      <span>{user.name}</span>
    </div>
  );
};
UserInformation.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const LinkStyle = (props) => ({
  padding: variables.normal_gap,
  cursor: "pointer",
  background: props.isActive ? variables.colors.background.darkHover : "none",
  display: "block",
  color: props.isActive
    ? variables.colors.text.dark
    : variables.colors.text.light,
  transition: "background .3s, color .3s",
  "&:hover": {
    background: variables.colors.background.darkHover,
    color: variables.colors.text.dark,
  },
});

const linkStyles = createUseStyles({
  Link: (props) => LinkStyle(props),
  linkBody: (props) => ({ display: props.shouldRender ? "block" : "none" }),
});
const Link = ({ link, isActive, isSublinkActive, onClick, children }) => {
  const classes = linkStyles({ shouldRender: isActive || isSublinkActive });
  return (
    <React.Fragment>
      <a onClick={onClick} className={classes.Link}>
        <strong>{link.title}</strong>
      </a>
      <div className={classes.linkBody}>{children}</div>
    </React.Fragment>
  );
};
Link.propTypes = {
  link: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  isSublinkActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const subLinkStyles = createUseStyles({
  SubLink: (props) => ({
    ...LinkStyle(props),
    paddingLeft: `calc(${variables.normal_gap} * 3)`,
  }),
});
const SubLink = ({ link, isActive, onClick }) => {
  const classes = subLinkStyles({ isActive });
  return (
    <a onClick={onClick} className={classes.SubLink}>
      <strong>{link.title}</strong>
    </a>
  );
};
SubLink.propTypes = {
  link: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const sidebarStyles = createUseStyles({
  Sidebar: {
    position: "fixed",
    display: "grid",
    gridTemplateRows: "max-content 1fr max-content",
    backgroundColor: variables.colors.background.dark,
    padding: variables.normal_gap,
    gap: variables.normal_gap,
    boxShadow: variables.shadows.normal,
    zIndex: 4,
    height: "100vh",
    textAlign: "center",
    overflow: "auto",
    boxSizing: "border-box",
  },
  header: {
    textAlign: "center",
    color: variables.colors.text.light,
  },
  links: {
    overflow: "auto",
  },
  subLinks: {
    boxShadow: variables.shadows.normalInsetShadow,
    background: variables.colors.background.secondary.dark,
    color: variables.colors.text.dark,
    display: "grid",
  },
  logInBtn: {
    textAlign: "center",
    display: "grid",
  },
  logOutbbb: {
    fontWeight: "bold",
  },
  [`@media ${variables.media.smallscreen}`]: {
    Sidebar: {
      position: "relative",
      gridTemplateRows: " max-content 3rem 1fr auto",
      overflowY: "hidden",
      textAlign: "start",
      width: ({ openSidebar }) => (openSidebar ? "max-content" : "4rem"),
    },
    header: ({ openSidebar }) =>
      openSidebar ? { display: "block" } : { display: "none" },
    links: ({ openSidebar }) =>
      openSidebar ? { display: "block" } : { display: "none" },
    logInBtn: ({ openSidebar }) =>
      openSidebar ? { display: "grid" } : { display: "none" },
  },
});
const Sidebar = ({ isActive }) => {
  const [activeId, setActiveId] = useState(null);
  const navigate = useNavigate();

  const { setShowBurgerMenu, openSidebar } = useContext(GlobalModalContext);
  const classes = sidebarStyles({ navigate, openSidebar });

  const { isAuthenticated } = useAuthentication({ info_only: true });
  return (
    <div className={classes.Sidebar}>
      <CloseHamburgerMenu />
      <React.Fragment>
        {openSidebar ? <CloseSidebarArrow /> : <OpenSidebarArrow />}
        <div className={classes.header} onClick={() => navigate("/")}>
          <h3>Veritas</h3>
        </div>
        <div className={classes.links}>
          {links()
            .filter((link) => link.inMenu === true)
            .map((link, idx) => {
              const sublinks = _.get(link, "links", []);
              const isSublinkActive = sublinks.some(
                (sublink) => sublink.id === activeId
              );

              return (
                <React.Fragment key={idx}>
                  <Link
                    key={idx}
                    link={link}
                    isActive={link.id === activeId}
                    isSublinkActive={isSublinkActive}
                    onClick={() => {
                      setActiveId(link.id);
                      navigate(link.path);
                    }}>
                    {sublinks.length > 0 && (
                      <div className={classes.subLinks}>
                        {sublinks
                          .filter((sublink) => sublink.inMenu === true)
                          .map((sublink, jidx) => {
                            return (
                              <React.Fragment key={jidx}>
                                <SubLink
                                  key={jidx}
                                  link={sublink}
                                  isActive={sublink.id === activeId}
                                  onClick={() => {
                                    setActiveId("");
                                    setShowBurgerMenu(false);
                                    navigate(`${link.path}${sublink.path}`);
                                  }}></SubLink>
                              </React.Fragment>
                            );
                          })}
                      </div>
                    )}
                  </Link>
                </React.Fragment>
              );
            })}
        </div>
        <div className={classes.logInBtn}>
          {!isAuthenticated && <LoginButton />}
          {isAuthenticated && <LogoutButton />}
          {isAuthenticated && (
            <UserInformation
              onClick={() => {
                navigate(`/userInfo`);
              }}
            />
          )}
        </div>
      </React.Fragment>
    </div>
  );
};
Sidebar.defaultProps = {
  isActive: true,
  showSidebar: true,
};
Sidebar.propTypes = {
  isActive: PropTypes.bool.isRequired,
  showSidebar: PropTypes.bool.isRequired,
};
export default Sidebar;
