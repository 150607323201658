import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../components/cards/GenericBasicInfo";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";
import { retrieveCarEngineAPIConfigurations } from "../../../../api/CarRentalEngine/ApiConfig";
import Loader from "../../../../components/Loader";
import { CarRentalTrueFalseMapping } from "../../common";
import { CustomButton } from "../../../../components/CustomButton";
import CarEngineLogsTab from "./CarEngineLogsTab";
import TabStrip from "../../../../components/TabStrip";
import ReservationsStatusTag from "../../../../components/Tags/ReservationsStatusTag";

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailsApiConfigurationCarEngineModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    // display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  row: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardBody: { justifyContent: "center" },
    row: {
      gridTemplateColumns: "2fr 1fr",
    },
  },
});
const DetailsApiConfigurationCarEngineModal = ({ uid, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicData");

  const menuTabs = {
    basicData: "Basic Data",
    logs: "Logs",
  };

  const { data: carRental, isLoading: retrieveLoading } = useQuery({
    queryKey: ["DetailsApiConfigurationCarEngineModal", uid],
    queryFn: () => {
      return retrieveCarEngineAPIConfigurations({ uid: uid });
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const basicDataCpl = [
    ["Uid", "uid"],
    ["Name", null, _.capitalize(_.get(carRental, "data.name"))],
    ["Supplier", null, _.capitalize(_.get(carRental, "data.supplier"))],
    ["Market Type", "market_type"],
    ["Source Entity", "source_entity"],
    [
      "Is Live",
      null,
      _.get(CarRentalTrueFalseMapping, _.get(carRental, "data.is_live")),
    ],
    [
      "Is Global",
      null,
      _.get(CarRentalTrueFalseMapping, _.get(carRental, "data.global_account")),
    ],
    [
      "Is Own",
      null,
      _.get(CarRentalTrueFalseMapping, _.get(carRental, "data.is_own")),
    ],
    [
      "Is Master Account",
      null,
      _.get(
        CarRentalTrueFalseMapping,
        _.get(carRental, "data.is_master_account")
      ) || "N/A",
    ],
    ["Website API Key", "website_api_key"],
    [
      "Status",
      null,
      <ReservationsStatusTag status={_.get(carRental, "data.status")} />,
    ],
  ];
  const creatorData = [
    ["Creator", "creator"],
    [
      "Created",
      null,
      DateTime.fromISO(_.get(carRental, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];
  const editorData = [
    ["Last Editor", "last_editor"],
    [
      "Edited",
      null,
      DateTime.fromISO(_.get(carRental, "data.edited")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];
  const accessData = [
    ["Username", "username"],
    ["Password", "password"],
    ["Application Key", "application_key"],
    ["Access Token", "access_token"],
    ["API Url", "api_url"],
    ["API Key", "api_key"],
  ];

  return (
    <div className={classes.DetailsApiConfigurationCarEngineModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Car Rental API Configuration Details</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {retrieveLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicData" && (
                <React.Fragment>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Basic Data"}
                        cardInfo={basicDataCpl}
                        objectData={_.get(carRental, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Creator Data"}
                        cardInfo={creatorData}
                        objectData={_.get(carRental, "data", {})}
                      />
                      <GenericBasicInfo
                        header={"Editor Data"}
                        cardInfo={editorData}
                        objectData={_.get(carRental, "data", {})}
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header="API Credentials"
                        cardInfo={accessData}
                        objectData={_.get(carRental, "data", {})}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activeTab === "logs" && <CarEngineLogsTab />}
            </React.Fragment>
          )}
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton appearance="ghost" onClick={onClose}>
            <strong>Close</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailsApiConfigurationCarEngineModal.propTypes = {
  uid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DetailsApiConfigurationCarEngineModal;
