import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchTransferAPIConfigurations({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/transferengine/account-config/`,
    {
      params,
    }
  );
}

export function retrieveTransferAPIConfiguration({ uid }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/transferengine/account-config/${uid}/`
  );
}

export function patchTranfer({ uid, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/transferengine/account-config/${uid}/`,
    payload
  );
}

export function createNewTransfer({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/transferengine/account-config/`,
    payload
  );
}

export function cloneTransfer({ uid }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/transferengine/account-config/clone/${uid}/`
  );
}
export function deleteTransferApiConfiguration({ uid }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/transferengine/account-config/${uid}/`
  );
}
