import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  cardStyles,
  tableStyles,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import PropTypes from "prop-types";
import Loader from "../../../../components/Loader";
import { fetchDocuments } from "../../../../api/CRM/Documents";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import DeleteDocumentModal from "./DocumentsModal/DeleteDocumentModal";
import AddDocument from "./DocumentsModal/AddDocumentModal";
import { toast } from "react-toastify";
import { CustomButton } from "../../../../components/CustomButton";

const initialFilters = {
  tags: "",
  sourceEntity: "",
};

const documentsCardStyles = createUseStyles({
  ...cardStyles,
  DocumentsCard: modalGenericStyles.modal,
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    display: "grid",
    gap: variables.normal_gap,
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
});

export const DocumentsCard = ({ tags, sourceEntity, onClose }) => {
  const classes = documentsCardStyles();
  const [filters, setFilters] = useState(initialFilters);
  const [deleteUid, setDeleteUid] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ["DocumentsCard", filters],
    queryFn: () =>
      fetchDocuments({
        params: { source_entity: sourceEntity, tags: tags },
      }),
    onError: (error) => toast.error(error),
    enabled: !showAddModal && !showDeleteModal,
  });
  return (
    <div id="DocumentsCard" className={classes.DocumentsCard}>
      {/* {isLoading && <Loader onTop={true} />} */}
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className={classes.modalCard}>
            <div className={classes.modalCardHeader}>
              <span>Documents</span>
              <CustomButton
                id="addDocumentButton"
                appearance="ghost"
                onClick={() => {
                  setShowAddModal(true);
                }}>
                <strong>Add</strong>
              </CustomButton>
            </div>
            <div className={classes.modalCardBody}>
              {!showAddModal && !showDeleteModal && (
                <React.Fragment>
                  <div className={classes.body}>
                    <table>
                      <thead>
                        <tr className={classes.headRow}>
                          <th className={classes.headCell}>#</th>
                          <th className={classes.headCell}>Creator Username</th>
                          <th className={classes.headCell}>
                            Creator First Name
                          </th>
                          <th className={classes.headCell}>
                            Creator Last Name
                          </th>
                          <th className={classes.headCell}>Creator Email</th>
                          <th className={classes.headCell}>
                            Creator Source Entity
                          </th>
                          <th className={classes.headCell}>Source Entity</th>
                          <th className={classes.headCell}>File Name</th>
                          <th className={classes.headCell}>Tags</th>
                          <th className={classes.headCell}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.get(data, "data", []).map((doc, idx) => (
                          <tr key={idx}>
                            <td className={classes.tableCell}>{idx + 1}</td>
                            <td className={classes.tableCell}>
                              {_.get(doc, "data.creator_metadata.username") ||
                                "N/A"}
                            </td>
                            <td className={classes.tableCell}>
                              {_.get(doc, "creator_metadata.first_name") ||
                                "N/A"}
                            </td>
                            <td className={classes.tableCell}>
                              {_.get(doc, "creator_metadata.last_name") ||
                                "N/A"}
                            </td>
                            <td className={classes.tableCell}>
                              {_.get(doc, "creator_metadata.email") || "N/A"}
                            </td>
                            <td className={classes.tableCell}>
                              {_.get(doc, "creator_metadata.source_entity") ||
                                "N/A"}
                            </td>
                            <td className={classes.tableCell}>
                              {_.get(doc, "source_entity") || "N/A"}
                            </td>
                            <td className={classes.tableCell}>
                              {_.get(doc, "filename") || "N/A"}
                            </td>
                            <td className={classes.tableCell}>
                              {_.get(doc, "tags_display", []).join(",") ||
                                "N/A"}
                            </td>
                            <td className={classes.actionCell}>
                              <CustomButton
                                id={`DeleteDocumentButton_${idx}`}
                                appearance="primary"
                                onClick={() => {
                                  setDeleteUid(doc.uid);
                                  setShowDeleteModal(true);
                                }}>
                                <strong>Delete</strong>
                              </CustomButton>
                              <CustomButton
                                appearance="ghost"
                                onClick={() => {
                                  window.open(doc.path, "_blank", "noreferrer");
                                }}>
                                <strong>Download</strong>
                              </CustomButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className={classes.modalCardActions}>
              <CustomButton
                id="CloseDocumentModal"
                appearance="ghost"
                onClick={onClose}>
                <strong>Cancel</strong>
              </CustomButton>
            </div>
          </div>

          {showAddModal && (
            <AddDocument
              // entityId={id}
              tags={tags}
              onClose={() => {
                setShowAddModal(false);
              }}
              sourceEntity={sourceEntity}
            />
          )}
          {showDeleteModal && (
            <DeleteDocumentModal
              uid={deleteUid}
              onClose={() => {
                setShowDeleteModal(false);
              }}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

DocumentsCard.propTypes = {
  tags: PropTypes.array.isRequired,
  sourceEntity: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
