import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchACTVendors({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/vendors/`, {
    params,
  });
}

export function retrieveACTVendors({ id }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/vendors/${id}/`);
}

export function patchACTVendors({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crm/vendors/${id}/`,
    payload
  );
}

export function createNewACTVendors({ payload }) {
  return loggingAxios.post(`${VALAQUENTA_API_URL}/crm/vendors/`, payload);
}

export function deleteACTVendor({ id }) {
  return loggingAxios.delete(`${VALAQUENTA_API_URL}/crm/vendors/${id}/`);
}
