import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../../variables";
import Navbar from "./Navbar";
import Loader from "../../../../../components/Loader";
import ControlStrip from "../../../../../components/ControlStrip";
import { fetchFlights } from "../../../../../api/Vendor/flight";
import _ from "lodash";
import {
  countries,
  currencies,
  handleApplyFilters,
  medWeekdayDatetimer,
} from "../../../../../helpers";
import DetailFlightModal from "../FlightsModals/DetailFlightModal";
import EditFlightModal from "../FlightsModals/EditFlightModal";
import { useQuery } from "@tanstack/react-query";
import HamburgerMenu from "../../../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../../../hooks";
import * as yup from "yup";
import { DateTime } from "luxon";
import { genericRequestErrorHandler } from "../../../../../api";
import DeleteVendorFlightModal from "../FlightsModals/DeleteVendorFlightModal";
import { CustomButton } from "../../../../../components/CustomButton";
import { Price } from "../../../../../components/Price";

const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};
const genderMapping = {
  MA: "Male",
  FE: "Female",
};

const initialFilters = {
  limit: 20,
  page: 1,
  ordering: "",
  discending: "",
  member: "",
  market_type: "",
  creator__icontains: "",
  created__gte: "",
  created__lte: "",
  status: "",
  config_uid__contains: "",
  vendor: "",
};

const navbarSchema = yup.object().shape({
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const flightStyles = createUseStyles({
  Flight: genericIndexesStyles.indexes.mobile(),
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
  dateCell: { ...tableStyles.body.cell, width: "9rem", minWidth: "9rem" },
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      paddingTop: `${variables.normal_gap}!important`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const Flight = () => {
  const classes = flightStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [flightId, setFlightId] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [flight, setflight] = useState(null);

  const tableHeaders = {
    Vendors: (val) => (val.vendor ? val.vendor.split("fl__")[1] : "N/A"),
    Member: (val) => _.get(val, "member"),
    "Market Type": (val) => _.get(val, "market_type"),
    "Config uid": (val) => _.get(val, "config_uid[0]"),
    Currency: (val) =>
      _.get(currencies, _.get(val, "vendor_data.currency") || "N/A"),
    "Notify Amount": (val) => (
      <Price currency={val.vendor_data.currency} price={val.notify_amount} />
    ),
    "User Id": (val) => _.get(val, "vendor_data.user_id"),
    Airline: (val) => _.get(val, "vendor_data.airline"),
    Email: (val) => {
      return _.get(val, "deliveries", [])
        .map((des) => _.get(des, "email"))
        .join(",");
    },
    Queue: (val) => _.get(val, "vendor_data.queue"),
    Gender: (val) => {
      return _.get(val, "deliveries", [])
        .map((des) => _.get(genderMapping, _.get(des, "gender")))
        .join(",");
    },
    Country: (val) => {
      return _.get(val, "deliveries", [])
        .map((des) => _.get(countries, _.get(des, "country_code")))
        .join(",");
    },
    Region: (val) => {
      return _.get(val, "deliveries", [])
        .map((des) => _.get(des, "region"))
        .join(",");
    },
    City: (val) => {
      return _.get(val, "deliveries", [])
        .map((des) => _.get(des, "city"))
        .join(",");
    },
    "Post Code": (val) => {
      return _.get(val, "deliveries", [])
        .map((des) => _.get(des, "post_code"))
        .join(",");
    },
    Street: (val) => {
      return _.get(val, "deliveries", [])
        .map((des) => _.get(des, "street"))
        .join(",");
    },
    "Last Name": (val) => {
      return _.get(val, "deliveries", [])
        .map((des) => _.get(des, "last_name"))
        .join(",");
    },
    "First Name": (val) => {
      return _.get(val, "deliveries", [])
        .map((des) => _.get(des, "first_name"))
        .join(",");
    },
    "Office Id": (dt) => _.get(dt, "vendor_data.office_id"),

    Created: (val) => val.created,
    Status: (val) => _.get(statusMapping, _.get(val, "status", "")),
  };
  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data, isLoading: loading } = useQuery({
    queryKey: ["vendorFlights", filters],
    queryFn: () => {
      let correctPayload = { ...filters };
      if (filters.created__lte) {
        correctPayload["created__lte"] = DateTime.fromISO(
          filters.created__lte
        ).toISO();
      }
      if (filters.created__gte) {
        correctPayload["created__gte"] = DateTime.fromISO(
          filters.created__gte
        ).toISO();
      }
      return fetchFlights({
        params: { ...correctPayload, vendor_type: "fl" },
      });
    },
    enabled: !showDetailModal && !showEditModal,
    onError: (error) => genericRequestErrorHandler(error),
  });
  return (
    <div id="FLVendorsListPage" className={classes.Flight}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setFlightId(null);
          setShowEditModal(true);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.limit}
        page={filters.page}
        count={_.get(data, "data.count", 0)}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {loading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th className={classes.headCell} key={idx}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody className={classes.tbody}>
            {_.get(data, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "created":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      return (
                        <td className={classes.dateCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    default:
                      cell = fn(val) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.tableCell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    id={`detailFLVendorButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setFlightId(val.id);
                      setShowDetailModal(true);
                    }}>
                    <strong>Details</strong>
                  </CustomButton>
                  <CustomButton
                    id={`editFLVendorButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setShowEditModal(true);
                      setFlightId(val.id);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                  <CustomButton
                    id={`deleteFLVendorButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setflight(val);
                      setFlightId(val.id);
                      setShowDeleteModal(true);
                    }}>
                    <strong>Delete</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDetailModal && (
        <DetailFlightModal
          id={flightId}
          onClose={() => {
            setShowDetailModal(false);
            setFlightId(null);
          }}
        />
      )}
      {showEditModal && (
        <EditFlightModal
          flightId={flightId}
          onClose={() => {
            setShowEditModal(false);
            setFlightId(null);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteVendorFlightModal
          data={flight}
          deletedId={flightId}
          onClose={() => {
            setShowDeleteModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Flight;
