import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";

const colorMapping = {
  200: "green",
  201: "green",
  204: "green",
  400: "purple",
  401: "purple",
  402: "purple",
  403: "purple",
  404: "purple",
  500: "red",
};

const styles = createUseStyles({
  HttpCodeTag: ({ statusCode }) => ({
    display: "inline-block",
    width: "fit-content",
    borderRadius: "3px",
    padding: "4px 10px 4px 10px",
    backgroundColor: colorMapping[statusCode] || "grey",
    color: "white",
  }),
});
const HttpCodeTag = ({ statusCode }) => {
  const classes = styles({ statusCode });

  return (
    <div className={classes.HttpCodeTag}>
      <strong>{statusCode !== null ? `${statusCode}` : "N/A"}</strong>
    </div>
  );
};
HttpCodeTag.propTypes = {
  statusCode: PropTypes.number,
};
export default HttpCodeTag;
