import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import {} from "../../../../../../api/Vendor/flight";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import Loader from "../../../../../../components/Loader";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../../api";
import {
  createNewTRAVendors,
  patchTRAVendors,
  retrieveTRAVendors,
} from "../../../../../../api/Vendor/Trains";
import {
  convertStringToBoolean,
  convertStringToNumber,
  currencies,
  handleValidationErrors,
} from "../../../../../../helpers";
import EntityAutocomplete from "../../../../../../components/EntityAutocomplete";
import { CustomButton } from "../../../../../../components/CustomButton";

const titleMapping = {
  "": "-----",
  mr: "Mr",
  ms: "Ms",
};

const marketTypeMapping = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};

const trueFalseMapping = {
  "": "-----",
  false: "No",
  true: "Yes",
};

const supplierOptions = {
  "": "-----",
  raileurope: "Rail Europe",
};
const vendorsMapping = {
  "": "-----",
  "tra__Rail Europe": "Rail Europe",
};
const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};
const initialForm = {
  vendor: "",
  config_uid: "",
  status: "",
  member: "",
  notify_amount: 0,
  is_own: false,
  travel_service_type: "TRA",
  market_type: "",
  vendor_data: {
    name: "",
    supplier: "",
    is_live: false,
    global_account: false,
    username: "",
    password: "",
    application_key: "",
    access_token: "",
    api_url: "",
    api_key: "",
    currency: "",
    contact_details: {
      title: "",
      first_name: "",
      last_name: "",
      email: "",
    },
  },
};

const getSchema = ({ vendor, required_message }) => {
  return yup.object().shape({
    vendor: yup.string().required(`${required_message}`),
    status: yup.string().required(`${required_message}`),
    notify_amount: yup.number().nullable(),
    is_own: yup.boolean().nullable(),
    travel_service_type: yup.string().default("TRA"),
    member: yup
      .number()
      .integer()
      .typeError("Member must be an integer")
      .required(`${required_message}`),
    vendor_data: yup.object().shape({
      username: yup.string().test({
        name: "username_is_required_for_RailEurope",
        message: `${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["tra__Rail Europe"].includes(vendor) ? !!value : true;
        },
      }),
      password: yup.string().test({
        name: "password_is_required_for_RailEurope",
        message: ` ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["tra__Rail Europe"].includes(vendor) ? !!value : true;
        },
      }),
      application_key: yup.string().test({
        name: "application_key_is_required_for_RailEurope",
        message: `Application  ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["tra__Rail Europe"].includes(vendor) ? !!value : true;
        },
      }),
      access_token: yup.string().test({
        name: "access_token_is_required_for_RailEurope",
        message: `${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["tra__Rail Europe"].includes(vendor) ? !!value : true;
        },
      }),
      api_url: yup.string().test({
        name: "api_url_is_required_for_RailEurope",
        message: `${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["tra__Rail Europe"].includes(vendor) ? !!value : true;
        },
      }),
      api_key: yup.string().test({
        name: "api_key_is_required_for_RailEurope",
        message: `${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return vendor === "tra__Rail Europe" ? !!value : true;
        },
      }),
      currency: yup.string().required(`${required_message}`),
      contact_details: yup.object().shape({
        // title: yup.string().oneOf(["mr", "ms"], "Invalid Value"),
        first_name: yup.string(),
        last_name: yup.string(),
        email: yup.string().email("Invalid Email"),
      }),
    }),
  });
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditTrainVendorsModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content  1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  inputGroup: { ...formInputGroup, textAlign: "start" },
  entityAutoComplete: {
    ...formInputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  cardActions: { ...cardStyles.actions, gridColumn: "2 span" },
  [`@media ${variables.media.smallscreen}`]: {
    modalCard: {
      width: "60rem",
    },
    cardCol2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
});

const EditTrainVendorsModal = ({ id, onClose }) => {
  const classes = styles();
  const queryClient = useQueryClient();

  const mode = id ? "edit" : "add";

  const [form, setForm] = useState(initialForm);
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const { isLoading: isFetchingLoading } = useQuery({
    queryKey: ["RetrieveEditTrainVendorsModal"],
    queryFn: () => retrieveTRAVendors({ id: id }),
    enabled: mode === "edit",
    onSuccess: (data) => {
      const retrievedData = _.get(data, "data", {});
      const contactPerson = _.get(
        retrievedData,
        "vendor_data.contact_details",
        {}
      );
      setForm({
        ...retrievedData,
        vendor_data: {
          ...retrievedData.vendor_data,
          contact_details: !_.isEmpty(contactPerson)
            ? contactPerson
            : {
                title: "",
                first_name: "",
                last_name: "",
                email: "",
              },
        },
      });
      setAutocompleteValue(_.get(data, "data.member"));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const handleInputChange = (e) => {
    setForm((p) => ({
      ...p,
      [e.target.name]:
        e.target.name === "config_uid" ? [e.target.value] : e.target.value,
    }));
  };
  const handleVendorDataInputChange = (e) => {
    setForm((p) => ({
      ...p,
      vendor_data: {
        ..._.get(form, "vendor_data", {}),
        [e.target.name]: e.target.value,
      },
    }));
  };
  const handleInputContactPersonChange = (e) => {
    setForm((p) => ({
      ...p,
      vendor_data: {
        ..._.get(form, "vendor_data", {}),
        contact_details: {
          ..._.get(form, "vendor_data.contact_details", {}),
          [e.target.name]: e.target.value,
        },
      },
    }));
  };

  const mutation = useMutation({
    mutationFn: async ({ id, payload }) => {
      if (mode === "add") {
        return createNewTRAVendors({ payload: { ...payload } });
      } else {
        return patchTRAVendors({ id, payload: { ...payload } });
      }
    },

    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    onSuccess: () => {
      toast.success(
        mode === "add" ? "New Train Vendor added" : "Train Vendor info updated"
      );
      queryClient.invalidateQueries("vendorFlights");
      onClose();
    },
  });

  const validationSchema = getSchema({
    required_message: "This field is required.",
    vendor: form.vendor,
  });

  const numericFields = ["notify_amount"];
  const updatedNumericFields = convertStringToNumber(form, numericFields);

  const boolFields = convertStringToBoolean({
    is_own: _.get(form, "is_own"),
    global_account: _.get(form, "vendor_data.global_account"),
    is_live: _.get(form, "vendor_data.is_live"),
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(form, { abortEarly: false });
      mutation.mutate({
        id: id,
        payload: {
          ...form,
          ...updatedNumericFields,
          ...boolFields,
          config_uid: _.isEmpty(_.get(form, "config_uid.[0]", []))
            ? []
            : [_.get(form, "config_uid[0]", [])],
          member: _.get(form, "member"),
          vendor_data: {
            ...form.vendor_data,
            ...boolFields,
          },
        },
      });
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const loading = mutation.isLoading || (isFetchingLoading && mode === "edit");

  return (
    <div id="AddEditTRAVendorModal" className={classes.EditTrainVendorsModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>{_.startCase(mode)} Train Vendor</span>
        </div>
        <div className={classes.modalCardBody}>
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <form>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Basic Data</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol2}>
                      <div className={classes.inputGroup}>
                        <label>Vendor</label>
                        <select
                          name="vendor"
                          id="vendor"
                          value={form.vendor}
                          disabled={mode === "edit"}
                          onChange={handleInputChange}>
                          {Object.entries(vendorsMapping).map(
                            ([key, label], idx) => (
                              <option key={idx} value={key}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="member">Member(Source Entity)</label>
                        <div className={classes.entityAutoComplete}>
                          <EntityAutocomplete
                            id="memberAutocomplete"
                            subagents={false}
                            independentAgents={false}
                            branches={false}
                            value={autocompleteValue}
                            disabled={mode === "edit"}
                            setValue={setAutocompleteValue}
                            onChange={(text) => {
                              if (text.length === 0) {
                                setForm((p) => ({
                                  ...p,
                                  member: "",
                                }));
                                return;
                              }
                              const entityInfos = text.split("---");
                              const entityType = entityInfos[0];
                              const entityId = entityInfos[1];
                              setForm((p) => ({
                                ...p,
                                member: `${entityId}`,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={classes.cardCol3}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="status">Status</label>
                        <select
                          id="status"
                          name="status"
                          value={form.status}
                          onChange={handleInputChange}>
                          {Object.entries(statusMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Market Type</label>
                        <select
                          id="market_type"
                          name="market_type"
                          value={_.get(form, "market_type")}
                          onChange={handleInputChange}>
                          {Object.entries(marketTypeMapping).map(
                            ([key, label], idx) => (
                              <option key={idx} value={key}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Currency</label>
                        <select
                          id="currency"
                          name="currency"
                          value={_.get(form, "vendor_data.currency")}
                          onChange={handleVendorDataInputChange}>
                          {Object.entries(currencies).map(
                            ([key, label], idx) => (
                              <option key={idx} value={key}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Notify Amount</label>
                        <input
                          type="number"
                          name="notify_amount"
                          id="notify_amount"
                          value={_.get(form, "notify_amount")}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Config uid</label>
                        <input
                          id="config_uid"
                          type="text"
                          name="config_uid"
                          value={_.get(form, "config_uid[0]")}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Is Own</label>
                        <select
                          id="is_own"
                          name="is_own"
                          value={_.get(form, "is_own")}
                          onChange={handleInputChange}>
                          {Object.entries(trueFalseMapping).map(
                            ([key, label], idx) => (
                              <option key={idx} value={key}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Vendor Data</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol2}>
                      <div className={classes.inputGroup}>
                        <label>Username</label>
                        <input
                          id="username"
                          type="text"
                          name="username"
                          value={_.get(form, "vendor_data.username")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Password</label>
                        <input
                          id="password"
                          type="text"
                          name="password"
                          value={_.get(form, "vendor_data.password")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                    </div>
                    <div className={classes.cardCol3}>
                      <div className={classes.inputGroup}>
                        <label>Name</label>
                        <input
                          id="name"
                          type="text"
                          name="name"
                          value={_.get(form, "vendor_data.name")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Supplier</label>
                        <select
                          id="supplier"
                          name="supplier"
                          value={_.get(form, "vendor_data.supplier")}
                          onChange={handleVendorDataInputChange}>
                          {Object.entries(supplierOptions).map(
                            ([key, label], idx) => (
                              <option key={idx} value={key}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Is Live</label>
                        <select
                          id="is_live"
                          name="is_live"
                          value={_.get(form, "vendor_data.is_live")}
                          onChange={handleVendorDataInputChange}>
                          {Object.entries(trueFalseMapping).map(
                            ([key, label], idx) => (
                              <option key={idx} value={key}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Is Global</label>
                        <select
                          id="global_account"
                          name="global_account"
                          value={_.get(form, "vendor_data.global_account")}
                          onChange={handleVendorDataInputChange}>
                          {Object.entries(trueFalseMapping).map(
                            ([key, label], idx) => (
                              <option key={idx} value={key}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Application Key</label>
                        <input
                          id="application_key"
                          type="text"
                          name="application_key"
                          value={_.get(form, "vendor_data.application_key")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Access Token</label>
                        <input
                          id="access_token"
                          type="text"
                          name="access_token"
                          value={_.get(form, "vendor_data.access_token")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                    </div>
                    <div className={classes.cardCol2}>
                      <div className={classes.inputGroup}>
                        <label>Api Key</label>
                        <input
                          id="api_key"
                          type="text"
                          name="api_key"
                          value={_.get(form, "vendor_data.api_key")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Api Url</label>
                        <input
                          id="api_url"
                          type="text"
                          name="api_url"
                          value={_.get(form, "vendor_data.api_url")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Acccess Data</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol2}>
                      <div className={classes.inputGroup}>
                        <label>Username</label>
                        <input
                          id="username"
                          type="text"
                          name="username"
                          value={_.get(form, "vendor_data.username")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Password</label>
                        <input
                          id="password"
                          type="text"
                          name="password"
                          value={_.get(form, "vendor_data.password")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Contact Person</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol2}>
                      <div className={classes.inputGroup}>
                        <label>Gender Title</label>
                        <select
                          id="title"
                          name="title"
                          value={_.get(
                            form,
                            "vendor_data.contact_details.title"
                          )}
                          onChange={handleInputContactPersonChange}>
                          {Object.entries(titleMapping).map(
                            ([key, label], idx) => (
                              <option key={idx} value={key}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label>First Name</label>
                        <input
                          id="first_name"
                          type="text"
                          name="first_name"
                          value={_.get(
                            form,
                            "vendor_data.contact_details.first_name"
                          )}
                          onChange={handleInputContactPersonChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Last Name</label>
                        <input
                          id="last_name"
                          type="text"
                          name="last_name"
                          value={_.get(
                            form,
                            "vendor_data.contact_details.last_name"
                          )}
                          onChange={handleInputContactPersonChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Email</label>
                        <input
                          id="email"
                          type="email"
                          name="email"
                          value={_.get(
                            form,
                            "vendor_data.contact_details.email"
                          )}
                          onChange={handleInputContactPersonChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </React.Fragment>
          )}
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton id="submit" appearance="primary" onClick={onSubmit}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
EditTrainVendorsModal.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditTrainVendorsModal;
