export const CarRentalMarketTypeMapping = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};

export const CarRentalSupplierMapping = {
  "": "-----",
  carnect: "Carnect",
};

export const CarRentalTrueFalseMapping = {
  true: "Yes",
  false: "No",
};
