import React from "react";
import { createUseStyles } from "react-jss";
import { variables } from "../../variables";

const railEuropeEngine = createUseStyles({
  RailEuropeEngine: {
    display: "grid",
    overflow: "hidden",
    placeContent: "center",
  },
  title: {
    display: "grid",
    textAlign: "center",
    height: "100%",
    alignItems: "end",
    padding: variables.normal_gap,
  },
});
const RailEuropeEngine = () => {
  const classes = railEuropeEngine();

  return (
    <div className={classes.RailEuropeEngine}>
      <div className={classes.title}>
        <h1>Rail Europe Engine</h1>
      </div>
    </div>
  );
};
export default RailEuropeEngine;
