import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  inputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import {
  APIConfigsStatusMapping,
  convertStringToBoolean,
  convertStringToNumber,
  countries,
  currencies,
  handleValidationErrors,
} from "../../../../../helpers";
import { toast } from "react-toastify";
import Loader from "../../../../../components/Loader";
import _ from "lodash";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import {
  createNewUnififiApiConfiguration,
  patchUnififiApiConfiguration,
  retrieveUnififiAPIConfiguration,
} from "../../../../../api/FlightEngine/Unififi";
import EntityAutocomplete from "../../../../../components/EntityAutocomplete";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Name: Must be at least 2 characters")
    .max(100, "Name: Must be less than 100 characters")
    .nullable()
    .default(""),
  source_entity: yup.string().nullable().default(""),
  market_type: yup
    .string()
    .oneOf(["B2B", "B2C"], "Must be between B2B or B2C.")
    .nullable()
    .default(""),
  country_code: yup
    .string()
    .nullable()
    .required("This field may not be blanc.")
    .default(""),
  currency: yup.string().required("This field may not be blanc.").default(""),
  pcc: yup
    .string()
    .nullable()
    .required("This field may not be blanc.")
    .default(""),
  gds: yup
    .string()
    .nullable()
    .oneOf(
      ["1S", "1G", "1A", "F1", "1U", "AV", "UF"],
      "Must be between Sabre, Galileo, Amadeus, Farelogix, HH Unififi, Aviate or Unififi."
    )
    .default(""),
  is_own: yup.boolean().nullable().default(false),
  is_global: yup.boolean().nullable().default(false),
  is_master_account: yup.boolean().nullable().default(false),
  website_api_key: yup.string().nullable().default(""),
  status: yup.string().nullable().default("AC"),
  api_url: yup
    .string()
    .oneOf(
      ["http://apitest.unififi.com/", "http://openapi-booking.unififi.com/"],
      "Must be between http://apitest.unififi.com/ or http://openapi-booking.unififi.com/"
    )
    .nullable()
    .default(""),
  search_url: yup
    .string()
    .oneOf(
      ["http://apitest.unififi.com/", "http://openapi-shopping.unififi.com/"],
      "Must be between http://apitest.unififi.com/ or http://openapi-shopping.unififi.com/"
    )
    .nullable()
    .default(""),
  received_from: yup.string().nullable().default(""),
  environment: yup
    .string()
    .required("This field may not be blanc.")
    .nullable()
    .default(""),
  customer_id: yup
    .string()
    .required("This field may not be blanc.")
    .nullable()
    .default(""),
  app_key: yup
    .string()
    .required("This field may not be blanc.")
    .nullable()
    .default(""),
  app_secret: yup
    .string()
    .required("This field may not be blanc.")
    .nullable()
    .default(""),
  searches: yup.number().nullable().default(0),
  max_searches: yup.number().nullable().default(0),
  bookings: yup.number().nullable().default(0),
  max_bookings: yup.number().nullable().default(0),
});

const searchUrlMapping = {
  "": "-----",
  "http://apitest.unififi.com/": "http://apitest.unififi.com/",
  "http://openapi-shopping.unififi.com/":
    "http://openapi-shopping.unififi.com/",
};
const apiUrlMapping = {
  "": "-----",
  "http://apitest.unififi.com/": "http://apitest.unififi.com/",
  "http://openapi-booking.unififi.com/": "http://openapi-booking.unififi.com/",
};

const isGlobalMapping = {
  "": "-----",
  true: "Yes",
  false: "No",
};
const gdsMapping = {
  "": "-----",
  "1S": "Sabre",
  "1G": "Galileo",
  "1A": "Amadeus",
  F1: "Farelogix",
  "1U": "HH Unififi",
  AV: "Aviate",
  UF: "Unififi",
};
const environmentMapping = {
  "": "-----",
  PROD: "Production",
  STAG: "Staging",
};
const trueFalseMapping = {
  "": "-----",
  true: "Yes",
  false: "No",
};
const marketTypeMapping = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditUnififiApiConfigurationModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardBody: cardStyles.body,
  cardHeader: cardStyles.header,
  inputGroup: {
    ...formInputGroup,
    padding: `${variables.normal_gap} 0`,
  },
  entityAutoComplete: {
    ...inputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCard: {
      minWidth: "50rem",
    },
    cardCol2: {
      gridTemplateColumns: "1fr 1fr",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    cardCol4: {
      display: "grid",
      gridTemplateColumns: "repeat(4,1fr)",
      gap: variables.normal_gap,
    },
  },
});

const EditUnififiApiConfigurationModal = ({ apiConfigurationId, onClose }) => {
  const classes = styles();
  const mode = apiConfigurationId ? "edit" : "add";

  const [form, setForm] = useState(jsonSchema.cast({}));
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["EditUnififiAPIConfigurationModal"],
    queryFn: () => {
      return retrieveUnififiAPIConfiguration({
        uid: apiConfigurationId,
      });
    },
    onSuccess: (data) => {
      setForm(jsonSchema.cast(_.get(data, "data", {})));
      setAutocompleteValue(_.get(data, "data.source_entity", ""));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
      onClose();
    },
    enabled: mode === "edit",
  });

  const handleInputChange = (e) => {
    e.preventDefault();
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const addMutation = useMutation({
    mutationFn: async ({ payload }) => {
      return createNewUnififiApiConfiguration({ payload });
    },
    onSuccess: () => {
      toast.success("New Unififi added");
      queryClient.invalidateQueries("UnififiApiConfiguration");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });
  const editMutation = useMutation({
    mutationFn: async ({ uid, payload }) => {
      return patchUnififiApiConfiguration({
        uid,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("Unififi Info Updated");
      queryClient.invalidateQueries("UnififiApiConfiguration");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const boolFields = convertStringToBoolean({
    is_master_account: form.is_master_account,
    is_global: form.is_global,
    is_own: form.is_own,
  });

  const onSubmit = async ({ uid, payload }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload: { ...payload, ...boolFields } });
      } else {
        editMutation.mutate({ uid, payload: { ...payload, ...boolFields } });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };
  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  const numericFields = [
    "searches",
    "max_searches",
    "bookings",
    "max_bookings",
  ];
  const updatedNumericFields = convertStringToNumber(form, numericFields);

  return (
    <div
      id="addEditUnififiFlAPIConfigurationModal"
      className={classes.EditUnififiApiConfigurationModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>{_.startCase(mode)} Unififi API Configuration</span>
        </div>
        <div className={classes.modalCardBody}>
          {loading ? (
            <Loader />
          ) : (
            <form>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Data</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="name">Name</label>
                      <input
                        id="name"
                        type="text"
                        name="name"
                        value={_.capitalize(form.name)}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="source_entity">Source Entity</label>
                      <div className={classes.entityAutoComplete}>
                        <EntityAutocomplete
                          id="entityAutocompleteInputId"
                          subagents={false}
                          independentAgents={false}
                          branches={false}
                          value={autocompleteValue}
                          setValue={setAutocompleteValue}
                          onChange={(text) => {
                            if (text.length === 0) {
                              setForm((p) => ({
                                ...p,
                                source_entity: "",
                              }));
                              return;
                            }
                            const entityInfos = text.split("---");
                            const entityType = entityInfos[0];
                            const entityId = entityInfos[1];
                            setForm((p) => ({
                              ...p,
                              source_entity: `${entityId}___${entityType}`,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="market_type">Market Type</label>
                      <select
                        id="market_type"
                        name="market_type"
                        value={form.market_type}
                        onChange={handleInputChange}>
                        {Object.entries(marketTypeMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="country_code">Country</label>
                      <select
                        id="country_code"
                        name="country_code"
                        value={form.country_code}
                        onChange={handleInputChange}>
                        {Object.entries(countries).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="currency">Currency</label>
                      <select
                        id="currency"
                        name="currency"
                        value={form.currency}
                        onChange={handleInputChange}>
                        {Object.entries(currencies).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="pcc">PCC</label>
                      <input
                        id="pcc"
                        type="text"
                        name="pcc"
                        value={form.pcc}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="gds">GDS</label>
                      <select
                        id="gds"
                        name="gds"
                        value={form.gds}
                        onChange={handleInputChange}>
                        {Object.entries(gdsMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="is_own">Is Own</label>
                      <select
                        id="is_own"
                        name="is_own"
                        value={form.is_own}
                        onChange={handleInputChange}>
                        {Object.entries(trueFalseMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="is_global">Is Global</label>
                      <select
                        id="is_global"
                        name="is_global"
                        value={form.is_global}
                        onChange={handleInputChange}>
                        {Object.entries(isGlobalMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="is_master_account">
                        Is Master Account
                      </label>
                      <select
                        id="is_master_account"
                        name="is_master_account"
                        value={form.is_master_account}
                        onChange={handleInputChange}>
                        {Object.entries(trueFalseMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label>Website Api Key</label>
                      <input
                        id="website_api_key"
                        type="text"
                        name="website_api_key"
                        value={form.website_api_key}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="api_url">Api url</label>
                      <select
                        id="api_url"
                        name="api_url"
                        value={form.api_url}
                        onChange={handleInputChange}>
                        {Object.entries(apiUrlMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="search_url">Search Url</label>
                      <select
                        id="search_url"
                        name="search_url"
                        value={form.search_url}
                        onChange={handleInputChange}>
                        {Object.entries(searchUrlMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="received_from">Received From</label>
                      <input
                        id="received_from"
                        type="text"
                        name="received_from"
                        value={form.received_from}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="environment">Environment</label>
                      <select
                        id="environment"
                        name="environment"
                        value={form.environment}
                        onChange={handleInputChange}>
                        {Object.entries(environmentMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="status">Status</label>
                      <select
                        id="status"
                        name="status"
                        value={form.status}
                        onChange={handleInputChange}>
                        {Object.entries(APIConfigsStatusMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Access Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="customer_id">Customer Id</label>
                      <input
                        id="customer_id"
                        type="text"
                        name="customer_id"
                        value={form.customer_id}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="app_key">App Key</label>
                      <input
                        id="app_key"
                        type="text"
                        name="app_key"
                        value={form.app_key}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="app_secret">App Secret</label>
                      <input
                        id="app_secret"
                        type="text"
                        name="app_secret"
                        value={form.app_secret}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className={classes.card}>
                    <div className={classes.cardHeader}>Usage data</div>
                    <div className={classes.cardBody}>
                      <div className={classes.cardCol4}>
                        <div className={classes.inputGroup}>
                          <label htmlFor="searches">Searches</label>
                          <input
                            id="searches"
                            type="number"
                            name="searches"
                            value={form.searches}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className={classes.inputGroup}>
                          <label htmlFor="max_searches">Max Searches</label>
                          <input
                            id="max_searches"
                            type="number"
                            name="max_searches"
                            value={form.max_searches}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className={classes.inputGroup}>
                          <label htmlFor="bookings">Bookings</label>
                          <input
                            id="bookings"
                            type="number"
                            name="bookings"
                            value={form.bookings}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className={classes.inputGroup}>
                          <label htmlFor="max_bookings">Max Bookings</label>
                          <input
                            id="max_bookings"
                            type="number"
                            name="max_bookings"
                            value={form.max_bookings}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            id="submit"
            appearance="primary"
            onClick={(e) => {
              e.preventDefault();
              onSubmit({
                uid: apiConfigurationId,
                payload: {
                  ...form,
                  ...boolFields,
                  ...updatedNumericFields,
                },
              });
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
EditUnififiApiConfigurationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditUnififiApiConfigurationModal;
