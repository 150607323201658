import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { variables } from "../../variables";
import _ from "lodash";

function buttonSizer({ size }) {
  const md = {
    fontSize: "14px",
    // padding: "8px 12px",
    lineHeight: "20px",
  };

  let sizeOptions = {};
  switch (size) {
    case "lg":
      sizeOptions = {
        fontSize: "16px",
        lineHeight: "22px",
      };
      break;
    case "sm":
      sizeOptions = {
        fontSize: "14px",
        lineHeight: "20px",
      };
      break;
    case "xs":
      sizeOptions = {
        fontSize: "12px",
        lineHeight: "20px",
      };
      break;
    case "md": {
      sizeOptions = md;
      break;
    }
    default:
      sizeOptions = md;
      break;
  }
  return sizeOptions;
}

function buttonStyler({ appearance }) {
  const primary = {
    extend: "button",
    border: `solid 1px ${variables.colors.base}`,
    backgroundColor: variables.colors.base,
    color: "white",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: variables.colors.baseDarker,
      border: `solid 1px ${variables.colors.baseDarker}`,
    },
  };

  switch (appearance) {
    case "fade": {
      return {
        border: `solid 1px ${variables.colors.baseLighter}`,
        backgroundColor: variables.colors.baseLighter,
        color: "white",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: variables.colors.base,
          border: `solid 1px ${variables.colors.base}`,
        },
      };
    }
    case "primary": {
      return primary;
    }
    case "ghost": {
      return {
        extend: "button",
        border: `solid 1px ${variables.colors.base}`,
        backgroundColor: "white",
        color: variables.colors.base,
        "&:hover": {
          cursor: "pointer",
          color: variables.colors.baseDarker,
          border: `solid 1px ${variables.colors.baseDarker}`,
        },
      };
    }
    case "filterGhost": {
      return {
        extend: "button",
        border: `solid 1px white`,
        backgroundColor: variables.colors.base,
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      };
    }
    case "filterPrimary": {
      return {
        extend: "button",
        border: `solid 1px ${variables.colors.base}`,
        backgroundColor: "white",
        color: variables.colors.base,
        "&:hover": {
          cursor: "pointer",
          color: variables.colors.baseDarker,
          border: `solid 1px ${variables.colors.baseDarker}`,
        },
      };
    }
    case "alert": {
      return {
        extend: "button",
        border: `solid 1px red`,
        backgroundColor: "white",
        color: "red",
        "&:hover": {
          cursor: "pointer",
          color: "white",
          border: `solid 1px white`,
          backgroundColor: "red",
        },
      };
    }
    default: {
      return primary;
    }
  }
}

const customButtonStyles = createUseStyles({
  CustomButton: ({ size, appearance, customStyles }) => ({
    borderRadius: "3px",
    textAlign: "center",
    placeItems: "center",
    fontWeight: "bold",
    ...buttonSizer({ size }),
    ...buttonStyler({ appearance }),
    ..._.get(customStyles, "CustomButton", {}),
  }),
  children: { height: "100%" },
  icon: { fontSize: "large" },
});
export const CustomButton = ({
  size,
  appearance = "primary",
  type,
  placement = "left",
  customStyles = {},
  onClick,
  children,
  isDisabled,
  id,
  props,
}) => {
  const classes = customButtonStyles({
    appearance,
    size,
    customStyles,
  });
  return (
    <button
      type={type}
      className={classes.CustomButton}
      onClick={onClick}
      disabled={isDisabled}
      id={id}
      {...props}>
      {children}
    </button>
  );
};
CustomButton.defaultProps = {
  type: "button",
  appearance: "primary",
  size: "md",
  isDisabled: false,
  onClick: () => null,
  props: {},
};
CustomButton.propTypes = {
  size: PropTypes.oneOf(["lg", "md", "sm", "xs"]),
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  appearance: PropTypes.oneOf([
    "ghost",
    "primary",
    "filterGhost",
    "filterPrimary",
    "fade",
    "alert",
  ]).isRequired,
  isDisabled: PropTypes.bool,
  customStyles: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  id: PropTypes.string,
  props: PropTypes.object,
};
