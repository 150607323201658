import { VALAQUENTA_API_URL } from "..";
import { authorizedAxios, loggingAxios } from "../../App";

export function fetchUnififiAPIConfigurations({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/flightengine/api-config/unififi/`,
    {
      params,
    }
  );
}

export function retrieveUnififiAPIConfiguration({ uid }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/flightengine/api-config/unififi/${uid}/`
  );
}

export function patchUnififiApiConfiguration({ uid, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/flightengine/api-config/unififi/${uid}/`,
    payload
  );
}

export function createNewUnififiApiConfiguration({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/flightengine/api-config/unififi/`,
    payload
  );
}

export function cloneUnififiApiConfiguration({ uid }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/flightengine/api-config/unififi/clone/${uid}/`
  );
}

export function deleteUnififiApiConfiguration({ uid }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/flightengine/api-config/unififi/${uid}/`
  );
}
