import React from "react";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../../variables";

import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../../../components/cards/GenericBasicInfo";
import _ from "lodash";
import HttpCodeTag from "../../../../../../components/Tags/HttpCodeTag";

const styles = createUseStyles({
  CallSection: {
    ...modalGenericStyles.card,
    display: "grid",
    gridTemplateRows: "repeat(2, max-content)",
    width: "20rem",
  },
  title: {
    ...cardStyles.header,
    fontSize: "small",
  },
  body: {
    ...cardStyles.body,
    display: "grid",
    gridTemplateRows: "repeat(2, max-content)",
    gap: variables.normal_gap,
  },
  noCriteria: {
    display: "grid",
    alignContent: "center",
    textAlign: "center",
  },
  [`@media ${variables.media.smallscreen}`]: {
    CallSection: {
      width: "50rem",
    },
    title: {
      fontSize: "large",
    },
    body: {
      gridTemplateColumns: "15rem 1fr",
    },
  },
  erroCell: {
    maxWidth: "20rem",
    overflow: "auto",
  },
});

const CallSection = ({ header, data }) => {
  const classes = styles();

  const criteriaPassed = _.get(data, "criteria_passed", {});
  const criteriaCpl = Object.entries(criteriaPassed).map(([key, value]) => [
    _.startCase(key),
    null,
    value ? "Yes" : "No",
  ]);

  const error = _.get(data, "error_message.error");

  const statusCpl = [
    [
      "Status Code",
      null,
      // <span className={status === 200 ? classes.greenText : classes.redText}>
      //   {statusText}
      // </span>,
      <span>
        <HttpCodeTag statusCode={_.get(data, "status_code")} />
      </span>,
    ],
    // [
    //   "Error Message",
    //   null,
    //   _.get(data, "error_message.error")
    //     ? _.get(data, "error_message.message")
    //     : "No Error",
    // ],
  ];

  if (error === true) {
    statusCpl.push([
      "Error Message",
      null,
      <div className={classes.erroCell}>
        {_.get(data, "error_message.message")}
      </div>,
    ]);
  }

  return (
    <div className={classes.CallSection}>
      <div className={classes.title}>
        <strong>{header}</strong>
      </div>
      <div className={classes.body}>
        {_.isEmpty(criteriaPassed) ? (
          <div className={classes.noCriteria}>
            There are no criteria to check
          </div>
        ) : (
          <GenericBasicInfo
            header="Criteria Passed"
            cardInfo={criteriaCpl}
            objectData={criteriaPassed}
            // customStyles={{
            //   body: { justifyContent: "left" },
            // }}
          />
        )}
        <GenericBasicInfo
          header="Outcome"
          cardInfo={statusCpl}
          objectData={data}
          customStyles={{
            GenericBasicInfo: { width: "100%" },
            body: { justifyContent: "left" },
          }}
        />
      </div>
    </div>
  );
};
CallSection.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};
export default CallSection;
