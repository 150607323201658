import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { Icon } from "@iconify/react";
import { GlobalModalContext } from "../../App";
import { variables } from "../../variables";

const hamburgerMenuStyles = createUseStyles({
  HamburgerMenu: {
    background: "transparent",
    position: "absolute",
    right: "0",
    top: 0,
    width: "max-content",
    height: "max-content",
    padding: variables.normal_gap,
  },
  button: {
    display: "grid",
    placeItems: "center",
    height: "2.5rem",
    width: "2.5rem",
    border: "none",
    background: variables.colors.background.dark,
    color: "white",
  },
  hamburger: {
    height: "20px",
    width: "20px",
  },
  [`@media ${variables.media.smallscreen}`]: {
    HamburgerMenu: { display: "none" },
  },
});
const HamburgerMenu = () => {
  const classes = hamburgerMenuStyles();

  const { setShowBurgerMenu } = useContext(GlobalModalContext);

  return (
    <div className={classes.HamburgerMenu}>
      <button
        className={classes.button}
        onClick={() => setShowBurgerMenu(true)}>
        <Icon
          icon="game-icons:hamburger-menu"
          id="hamburgerButton"
          className={classes.hamburger}
        />
      </button>
    </div>
  );
};

const closeHamburgerMenuStyles = createUseStyles({
  CloseHamburgerMenu: {
    position: "absolute",
    top: variables.normal_gap,
    right: variables.normal_gap,
    fontSize: "xxx-large",
    color: "white",
  },
  [`@media ${variables.media.smallscreen}`]: {
    CloseHamburgerMenu: { display: "none" },
  },
});
export const CloseHamburgerMenu = () => {
  const { setShowBurgerMenu } = useContext(GlobalModalContext);

  const classes = closeHamburgerMenuStyles();

  return (
    <div
      className={classes.CloseHamburgerMenu}
      onClick={() => setShowBurgerMenu(false)}
    >
      <Icon icon="emojione-monotone:letter-x" />
    </div>
  );
};

export default HamburgerMenu;
