import axios from "axios";
import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios } from "../../../App";

export async function fetchDestinations({ params }) {
  return await authorizedAxios.get(`${VALAQUENTA_API_URL}/cms/destinations/`, {
    params,
  });
}

export function fetchDestinationsAutocomplete({ params }) {
  return axios.get(`${VALAQUENTA_API_URL}/cms/destinations/autocomplete/`, {
    params,
  });
}
