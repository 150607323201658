import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { fetchUsers } from "../../../../api/CRM/Users/Users";
import ControlStrip from "../../../../components/ControlStrip";
import Loader from "../../../../components/Loader";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Navbar from "./Navbar";
import _ from "lodash";
import DetailUserModal from "../UsersModals/DetailUserModal";
import { handleApplyFilters, medWeekdayDatetimer } from "../../../../helpers";
import EditUserModal from "../UsersModals/EditUserModal";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { genericRequestErrorHandler } from "../../../../api";
import { CustomButton } from "../../../../components/CustomButton";

const initialFilters = {
  limit: 20,
  page: 1,
  company_type__in: "",
  first_name__icontains: "",
  last_name__icontains: "",
  tags: "",
  created__gte: "",
  creator__icontains: "",
  status__in: "",
  email__icontains: "",
  ordering: "",
  discending: "",
  created__lte: "",
  company_legal_title__icontains: "",
};

const navbarSchema = yup.object().shape({
  // company_legal_title__icontains: yup
  //   .string()
  //   .min(2, "Company Legal title must be more than 2 characters")
  //   .max(100, "Company Legal title must be less than 100 characters"),
  // first_name__icontains: yup
  //   .string()
  //   .min(2, "First name must be more than 2 characters")
  //   .max(100, "First name: Must be less than 100 characters"),
  // last_name__icontains: yup
  //   .string()
  //   .min(2, "Last name must be more than 2 characters")
  //   .max(100, "Last name must be less than 100 characters"),
  // email__icontains: yup.string().email("Not a valid email"),
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});
const departmentMapping = {
  OP: "Operation",
};

const statusMapping = {
  AC: "Active",
  IN: "Inactive",
};

const userStyles = createUseStyles({
  Users: genericIndexesStyles.indexes.mobile(),
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  tablecell: tableStyles.body.cell,
  bodyRow: tableStyles.body.row,
  actionCell: genericIndexesStyles.actionCell.mobile,
  datesCell: { ...tableStyles.body.cell, minWidth: "9rem", width: "9rem" },
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      display: "grid",
      paddingTop: `calc(${variables.normal_gap}*1.1) !important`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0!important`,
    },
  },
});

const Users = () => {
  const classes = userStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const tableHeaders = {
    "Company Legal Title": (val) =>
      _.capitalize(_.get(val, "company_legal_title")),
    "User Type": (user) =>
      user.branch_id
        ? "Branch User"
        : user.sugagent_id
        ? "Sub Agent"
        : user.company_id
        ? "Company"
        : "N/A",
    "First / Last Name": (val) =>
      _.capitalize(_.get(val, "user.first_name")) ||
      _.capitalize(_.get(val, "user.last_name"))
        ? `${_.capitalize(_.get(val, "user.first_name")) || "N/A"} 
        ${_.capitalize(_.get(val, "user.last_name")) || "N/A"}`
        : "N/A",
    Tags: (val) => val.tags.map((tag) => tag).join(", "),
    "Account Email": (val) => val.email,
    "Job Description": (user) => {
      return _.get(user, "job_description", [])
        .map((des) => _.capitalize(_.get(des, "brief_description_en")))
        .join(",");
    },
    "Business Name": (val) => _.capitalize(_.get(val, "company_brand_name")),
    Department: (user) =>
      _.capitalize(_.get(departmentMapping, _.get(user, "department"))),
    "Last Login": (val) => _.get(val, "user.last_login"),
    Created: (val) => val.created,
    Status: (user) => _.get(statusMapping, _.get(user, "status")),
  };

  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data: users, isLoading } = useQuery({
    queryKey: ["Users", filters],
    queryFn: () => {
      let correctPayload = { ...filters };
      if (filters.created__lte) {
        correctPayload["created__lte"] = DateTime.fromISO(
          filters.created__lte
        ).toISO();
      }
      if (filters.created__gte) {
        correctPayload["created__gte"] = DateTime.fromISO(
          filters.created__gte
        ).toISO();
      }
      return fetchUsers({
        params: correctPayload,
      });
    },
    enabled: !showDetailModal && !showEditModal,
    onError: (error) => genericRequestErrorHandler(error),
  });

  return (
    <div id="UsersListPage" className={classes.Users}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setUserId(null);
          setShowEditModal(true);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.limit}
        page={filters.page}
        count={_.get(users, "data.count", 0)}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading ? (
        <Loader />
      ) : (
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                {Object.keys(tableHeaders).map((value, idx) => (
                  <th className={classes.headCell} key={idx}>
                    {value}
                  </th>
                ))}
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.get(users, "data.results", []).map((val, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  {Object.entries(tableHeaders).map((entry, ridx) => {
                    var cell = null;
                    const fn = entry[1];
                    switch (entry[0].toLowerCase()) {
                      case "last login":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      case "created":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      default:
                        cell = fn(val) || "N/A";
                        break;
                    }
                    return (
                      <td className={classes.tableCell} key={ridx}>
                        {cell}
                      </td>
                    );
                  })}
                  <td className={classes.actionCell}>
                    <CustomButton
                      id={`UserDetailButton`}
                      appearance="ghost"
                      onClick={() => {
                        setUserId(val.id);
                        setShowDetailModal(true);
                      }}>
                      <strong>Details</strong>
                    </CustomButton>
                    <CustomButton
                      id={`UserEditButton_${idx}`}
                      appearance="ghost"
                      onClick={() => {
                        setUserId(val.id);
                        setShowEditModal(true);
                      }}>
                      <strong>Edit</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showDetailModal && (
        <DetailUserModal
          id={userId}
          onClose={() => {
            setShowDetailModal(false);
            setUserId(null);
          }}
        />
      )}
      {showEditModal && (
        <EditUserModal
          userId={userId}
          onClose={() => {
            setShowEditModal(false);
            setUserId(null);
          }}
        />
      )}
    </div>
  );
};
export default Users;
