import React from "react";
import { createUseStyles } from "react-jss";
import { cardStyles, tableStyles, variables } from "../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";

const departmentOptions = {
  AD: "Administration",
  FI: "Financial",
  MA: "Marketing",
  OP: "Operation",
  RE: "Reservation",
};

const constactStyles = createUseStyles({
  ...cardStyles,
  ContactsCard: {
    ...cardStyles.card,
    maxWidth: "100%",
  },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: { ...tableStyles.table, maxWidth: "100%", overflow: "hidden" },
  thead: tableStyles.head,
  tbody: tableStyles.body,
  trow: tableStyles.body.row,
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
});

export const ContactsCard = ({ contacts }) => {
  const classes = constactStyles();
  return (
    <div className={classes.ContactsCard}>
      <div className={classes.header}>
        <span>Contacts</span>
      </div>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              <th className={classes.headCell}>Id</th>
              <th className={classes.headCell}>First Name</th>
              <th className={classes.headCell}>Last Name</th>
              <th className={classes.headCell}>Mobile Phone</th>
              <th className={classes.headCell}>Phone</th>
              <th className={classes.headCell}>Fax</th>
              <th className={classes.headCell}>E-mail</th>
              <th className={classes.headCell}>Department</th>
              <th className={classes.headCell}>Gender</th>
            </tr>
          </thead>
          <tbody className={classes.tbody}>
            {(contacts || []).map((cont, idx) => (
              <tr className={classes.trow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                <td className={classes.tableCell}>{cont.id || "N/A"}</td>
                <td className={classes.tableCell}>
                  {cont.first_name || "N/A"}
                </td>
                <td className={classes.tableCell}>{cont.last_name || "N/A"}</td>
                <td className={classes.tableCell}>
                  {cont.mobile_phone || "N/A"}
                </td>
                <td className={classes.tableCell}>{cont.phone || "N/A"}</td>
                <td className={classes.tableCell}>{cont.fax || "N/A"}</td>
                <td className={classes.tableCell}>{cont.email || "N/A"}</td>
                <td className={classes.tableCell}>
                  {_.get(departmentOptions, cont.department) || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {cont.gender === "MA" ? "Male" : "Female"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

ContactsCard.propTypes = {
  contacts: PropTypes.array.isRequired,
};
