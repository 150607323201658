import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { cardStyles, tableStyles, variables } from "../../variables";
import _ from "lodash";

const adressTypeOptions = {
  BU: "Business Address",
  CO: "Contact Address",
  BI: "Billing  Address",
  MA: "Mail",
};

const addressesCardStyles = createUseStyles({
  ...cardStyles,
  AddressesCard: {
    ...cardStyles.card,
    maxWidth: "100%",
  },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: { ...tableStyles.table, maxWidth: "100%", overflow: "hidden" },
  thead: tableStyles.head,
  tbody: tableStyles.body,
  trow: tableStyles.body.row,
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
});
export const AddressesCard = ({ addresses }) => {
  const classes = addressesCardStyles();

  return (
    <div className={classes.AddressesCard}>
      <div className={classes.header}>
        <span>Addresses</span>
      </div>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              <th className={classes.headCell}>Id</th>
              <th className={classes.headCell}>Street</th>
              <th className={classes.headCell}>Street Number</th>
              <th className={classes.headCell}>Post Code</th>
              <th className={classes.headCell}>City</th>
              <th className={classes.headCell}>Region</th>
              <th className={classes.headCell}>Country Code</th>
              <th className={classes.headCell}>Address Type</th>
            </tr>
          </thead>
          <tbody className={classes.tbody}>
            {(addresses || []).map((addr, idx) => (
              <tr className={classes.trow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                <td className={classes.tableCell}>{addr.id || "N/A"}</td>
                <td className={classes.tableCell}>{addr.street || "N/A"}</td>
                <td className={classes.tableCell}>
                  {addr.street_number || "N/A"}
                </td>
                <td className={classes.tableCell}>{addr.post_code || "N/A"}</td>
                <td className={classes.tableCell}>{addr.city || "N/A"}</td>
                <td className={classes.tableCell}>{addr.region || "N/A"}</td>
                <td className={classes.tableCell}>
                  {addr.country_code || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.get(adressTypeOptions, addr.address_type) || "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
AddressesCard.propTypes = {
  addresses: PropTypes.array.isRequired,
};
