import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  modalGenericStyles,
  tableStyles,
  variables,
  genericIndexesStyles,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { fetchWebSiteMainPromos } from "../../../../../api/DistributionSite/webSites/MainPromos";
import { DateTime } from "luxon";
import _ from "lodash";
import { statusMapping } from "../../common";
import EditMainPromos from "../EditMainPromos";
import Loader from "../../../../../components/Loader";
import DeleteMainPromo from "../DeleteMainPromo";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  MainPromosModal: {
    ...modalGenericStyles.card,
  },
  loader: { width: "100vw" },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: tableStyles,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile(),
  [`@media ${variables.media.smallscreen}`]: {
    loader: { width: "100%" },
  },
});

const MainPromosModal = ({ sourceEntity, websiteId }) => {
  const classes = styles();

  const [mainPromo, setMainPromo] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { data: MainPromosList, isLoading } = useQuery({
    queryKey: ["MainPromosList"],
    queryFn: async () =>
      await fetchWebSiteMainPromos({
        params: { source_entity: sourceEntity, website: websiteId },
      }),
    onError: (error) => genericRequestErrorHandler(error),
  });
  return (
    <div className={classes.MainPromosModal}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          Main Promos
          <div className={classes.cardActions}>
            <CustomButton
              appearance="ghost"
              onClick={() => {
                setEditId(null);
                setEditModal(true);
              }}>
              <strong>Add</strong>
            </CustomButton>
          </div>
        </div>
        <div className={classes.tableContainer}>
          {isLoading ? (
            <div className={classes.loader}>
              <Loader />
            </div>
          ) : (
            <table className={classes.table}>
              <thead className={classes.thead}>
                <tr className={classes.headRow}>
                  <th className={classes.headCell}>#</th>
                  <th className={classes.headCell}>Id</th>
                  <th className={classes.headCell}>Name</th>
                  <th className={classes.headCell}>Active From</th>
                  <th className={classes.headCell}>Active To</th>
                  <th className={classes.headCell}>Status</th>
                  <th className={classes.headCell}>Actions</th>
                </tr>
              </thead>
              <tbody className={classes.tbody}>
                {_.get(MainPromosList, "data.results", []).map(
                  (mainPromo, idx) => (
                    <tr key={idx} className={classes.bodyRow}>
                      <td className={classes.cell}>{idx + 1}</td>
                      <td className={classes.cell}>{mainPromo.id}</td>
                      <td className={classes.cell}>
                        {mainPromo.name || "N/A"}
                      </td>
                      <td className={classes.cell}>
                        {mainPromo.active_from
                          ? DateTime.fromISO(
                              mainPromo.active_from
                            ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                          : "N/A"}
                      </td>
                      <td className={classes.cell}>
                        {mainPromo.active_to
                          ? DateTime.fromISO(
                              mainPromo.active_to
                            ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                          : "N/A"}
                      </td>
                      <td className={classes.cell}>
                        {_.get(statusMapping, _.get(mainPromo, "status")) ||
                          "N/A"}
                      </td>
                      <td className={classes.actionCell}>
                        <CustomButton
                          appearance="ghost"
                          onClick={() => {
                            setEditId(mainPromo.id);
                            setEditModal(true);
                          }}>
                          <strong>Edit</strong>
                        </CustomButton>
                        <CustomButton
                          appearance="ghost"
                          text="Delete"
                          onClick={() => {
                            setMainPromo(mainPromo);
                            setDeleteId(mainPromo.id);
                            setDeleteModal(true);
                          }}>
                          <strong>Delete</strong>
                        </CustomButton>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {editModal && (
        <EditMainPromos
          id={editId}
          sourceEntity={sourceEntity}
          websiteId={websiteId}
          onClose={() => {
            setEditModal(false);
            setEditId(null);
          }}
        />
      )}
      {deleteModal && (
        <DeleteMainPromo
          id={deleteId}
          data={mainPromo}
          onClose={() => {
            setDeleteModal(false);
          }}
        />
      )}
    </div>
  );
};
MainPromosModal.propTypes = {
  sourceEntity: PropTypes.string.isRequired,
  websiteId: PropTypes.number.isRequired,
};
export default MainPromosModal;
