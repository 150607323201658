import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { cardStyles, tableStyles } from "../../../../../variables";
import _ from "lodash";

const styles = createUseStyles({
  ...cardStyles,
  AddonsPaymentLinks: cardStyles.card,
  GuestCard: cardStyles.card,
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  unpaidText: { color: "red" },
});

const AddonsPaymentLinks = ({ header, data }) => {
  const classes = styles();

  return (
    <div className={classes.AddonsPaymentLinks}>
      <div className={classes.header}>{header}</div>
      <div className={classes.body}>
        <table>
          <thead>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              <th className={classes.headCell}>Amount</th>
              <th className={classes.headCell}>Product Name</th>
              <th className={classes.headCell}>Description</th>
              <th className={classes.headCell}>Due Date</th>
              <th className={classes.headCell}>Type</th>
              <th className={classes.headCell}>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((dt, idx) => (
              <tr className={classes.bodyRow}>
                <td className={classes.tableCell}>{idx + 1}</td>
                <td className={classes.tableCell}>
                  {`
                    ${_.upperCase(_.get(dt, "currency", ""))}
                    ${_.get(dt, "amount", 0)}
                    ` || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.get(dt, "product_name", "") || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.get(dt, "description", "") || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.get(dt, "due_date") || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.capitalize(_.get(dt, "type", "")) || "N/A"}
                </td>
                <td
                  className={`${classes.tableCell} ${
                    _.get(dt, "status") === "unpaid" ? classes.unpaidText : ""
                  }`}>
                  {_.capitalize(_.get(dt, "status", "")) || "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
AddonsPaymentLinks.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};
export default AddonsPaymentLinks;
