import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchSubAgents({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/subagents/`, {
    params,
  });
}

export function retrieveSubAgent({ id }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/subagents/${id}/`);
}

export function patchSubAgent({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crm/subagents/${id}/`,
    payload
  );
}

export function createNewSubAgent({ payload }) {
  return loggingAxios.post(`${VALAQUENTA_API_URL}/crm/subagents/`, payload);
}
// export function getSubagentCreditStatment({ params }) {
//   return authorizedAxios.get(
//     `${VALAQUENTA_API_URL}/paymentengine/accounts/sub-entity-deposit-ledger/`,
//     {
//       params,
//     }
//   );
// }
// export function createSubagentCreditStatment({ payload }) {
//   return loggingAxios.post(
//     `${VALAQUENTA_API_URL}/paymentengine/accounts/sub-entity-deposit-ledger/`,
//     payload
//   );
// }

export function getRefactoredSubagentCreditStatment({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/paymentengine/subentity-deposit-ledger-entry/`,
    {
      params,
    }
  );
}

export function createRefactoredSubagentCreditStatment({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/paymentengine/subentity-deposit-ledger-entry/`,
    payload
  );
}

export function getSubagentPoformaStatment({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/paymentengine/proforma-subentity-ledger-entry/`,
    {
      params,
    }
  );
}

export function createSubagentPoformaStatment({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/paymentengine/proforma-subentity-ledger-entry/`,
    payload
  );
}
