import React from "react";
import { createUseStyles } from "react-jss";
import { variables } from "../../variables";

const AccomodatorStyles = createUseStyles({
  Accommodator: {
    display: "grid",
    overflow: "hidden",
    placeContent: "center",
  },
  title: {
    display: "grid",
    textAlign: "center",
    height: "100%",
    alignItems: "end",
    padding: variables.normal_gap,
  },
});
const Accommodator = () => {
  const classes = AccomodatorStyles();

  return (
    <div className={classes.Accommodator}>
      <div className={classes.title}>
        <h1>Accommodator</h1>
      </div>
    </div>
  );
};
export default Accommodator;
