import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
  cardStyles,
} from "../../../../../variables";
import _ from "lodash";
import PropTypes from "prop-types";

const styles = createUseStyles({
  ...cardStyles,
  OneToOneTable: {
    display: "grid",
    gridTemplateRows: " 1fr",
    overflow: "hidden",
    position: "relative",
    padding: variables.normal_gap,
    width: "100%",
  },
  header: { ...cardStyles.header },
  tableContainer: {
    ...genericIndexesStyles.tableContainer.mobile(),
    padding: variables.normal_gap,
  },
  noTravelServicesContainer: {
    display: "grid",
    gridColumn: "2 span",
  },
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
});

const OneToOneTable = ({ title, data }) => {
  const classes = styles();
  return (
    <div className={classes.OneToOneTable}>
      <div className={classes.header}>{title}</div>
      <div className={classes.tableContainer}>
        <div className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              <th className={classes.headCell}>Type</th>
              <th className={classes.headCell}>Id</th>
              <th className={classes.headCell}>Name</th>
              <th className={classes.headCell}>Legal Title</th>
            </tr>
          </thead>
          <tbody>
            {data.map((dt, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                <td className={classes.tableCell}>
                  {_.capitalize(_.get(dt, "target_content_type", "")) || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.get(dt, "target_entity_id", "") || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.get(dt, "target_entity_name", "") || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.get(dt, "target_entity_legal_title", "") || "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </div>
      </div>
    </div>
  );
};
OneToOneTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};
export default OneToOneTable;
