import { VALAQUENTA_API_URL } from "../..";
import { loggingAxios, authorizedAxios } from "../../../App";

export function fetchAccommodatorAPIConfigurations({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/accengine/account-config/`,
    {
      params,
    }
  );
}

export function retrieveAccommodatorAPIConfiguration({ uid }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/accengine/account-config/${uid}/`
  );
}

export function patchAccommodator({ uid, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/accengine/account-config/${uid}/`,
    payload
  );
}

export function createNewAccommodator({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/accengine/account-config/`,
    payload
  );
}

export function cloneAccommodator({ uid }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/accengine/account-config/clone/${uid}/`
  );
}

export function deleteAccApiConfiguration({ uid }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/accengine/account-config/${uid}/`
  );
}
