import axios from "axios";
import { VALAQUENTA_API_URL } from "../..";
import { loggingAxios } from "../../../App";

export async function fixWebhookRequirements({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/crm/members/webhooks/fix_requirements/`,
    payload
  );
}
