import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../../variables";
import { toast } from "react-toastify";
import Loader from "../../../../../../components/Loader";
import GenericBasicInfo from "../../../../../../components/cards/GenericBasicInfo";
import _ from "lodash";
import { genericRequestErrorHandler } from "../../../../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTransferVendor } from "../../../../../../api/Vendor/Transfer";
import { CustomButton } from "../../../../../../components/CustomButton";

const statusOptions = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteTransferVendorModal: modalGenericStyles.modal,
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content  1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
});

const DeleteTransferVendorModal = ({ deletedId, data, onClose }) => {
  const classes = styles();
  const queryClient = useQueryClient();

  const DeleteTransferVendorMutation = useMutation(deleteTransferVendor, {
    onSuccess: () => {
      toast.success("Transfer Vendor Deleted");
      queryClient.invalidateQueries("TransfersVendor");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });
  const onDelete = async (id) => {
    await DeleteTransferVendorMutation.mutateAsync({ id });
  };

  const basicDataCpl = [
    ["Id", "id"],
    ["Market_type", "market_type"],
    ["Member", "member"],
    ["Notes", "notes"],
    [
      "Payment Validation",
      null,
      _.get(data, "payment_validation") === true ? "Yes" : "No" || "N/A",
    ],
    ["CXL Strategy Time", "cxl_strategy_time"],
    [
      "CXL Strategy Daysahead",
      null,
      _.get(data, "cxl_strategy_daysahead", []) === 0
        ? "0"
        : _.get(data, "cxl_strategy_daysahead"),
    ],
    ["Status", null, _.get(statusOptions, _.get(data, "status", "") || "N/A")],
  ];

  return (
    <div
      id="DeleteTRFVendorModal"
      className={classes.DeleteTransferVendorModal}>
      <React.Fragment>
        <div className={classes.modalCard}>
          {DeleteTransferVendorMutation.isLoading && <Loader onTop={true} />}
          <div className={classes.modalCardHeader}>
            <span>DELETE Transfer Vendor</span>
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.cardBody}>
              <GenericBasicInfo
                header={"Basic Data"}
                cardInfo={basicDataCpl}
                objectData={data}
              />
            </div>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton
              id="DeleteTRFVendorModalCloseButton"
              appearance="ghost"
              onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>

            <CustomButton
              id="DeleteTRFVendorModalConfirmButton"
              appearance="primary"
              onClick={() => {
                onDelete(deletedId);
              }}>
              <strong>DELETE</strong>
            </CustomButton>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
DeleteTransferVendorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  deletedId: PropTypes.string.isRequired,
};
export default DeleteTransferVendorModal;
