import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {} from "../../../../../../api/Vendor/flight";
import {
  cardStyles,
  formInputGroup,
  formSections,
  modalGenericStyles,
  variables,
} from "../../../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import Loader from "../../../../../../components/Loader";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../../api";
import {
  createNewVendorAccommodation,
  patchVendorAccommodation,
  retrieveVendorAccomodation,
} from "../../../../../../api/Vendor/Accommodation";
import { toast } from "react-toastify";
import {
  convertStringToBoolean,
  convertStringToNumber,
  currencies,
  handleValidationErrors,
} from "../../../../../../helpers";
import EntityAutocomplete from "../../../../../../components/EntityAutocomplete";
import { CustomButton } from "../../../../../../components/CustomButton";

const titleMapping = {
  "": "-----",
  mr: "Mr",
  ms: "Ms",
};

const trueFalseMapping = {
  false: "No",
  true: "Yes",
};

const supplierOptions = {
  "": "-----",
  hotelbeds: "Hotelbeds",
  innstant: "Innstant",
  webhotelier: "webhotelier",
};
const marketTypeMapping = {
  B2B: "B2B",
  B2C: "B2C",
};

const vendorsMapping = {
  "": "-----",
  acc__DOTW: "DOTW",
  "acc__Go Global": "Go Global",
  acc__Hotelbeds: "Hotelbeds",
  "acc__InnstantTravel Hotels": "InnstantTravel Hotels",
  acc__Miki: "Miki",
  acc__Sunhotels: "Sunhotels",
  "acc__Total Stay": "Total Stay",
  acc__Travco: "Travco",
  acc__WebHotelier: "WebHotelier",
  acc__Restel: "Restel",
  "acc__TBO Holidays": "TBO Holidays",
  acc__Abreu: "Abreu",
  acc__HotelSton: "HotelSton",
};
const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};
const initialForm = {
  vendor: "",
  config_uid: "",
  status: "",
  member: "",
  market_type: "B2B",
  notify_amount: 0,
  travel_service_type: "ACC",
  is_own: false,
  vendor_data: {
    travel_entities: "",
    name: "",
    mishor_application_key: "",
    mishor_access_token: "",
    mishor_static_key: "",
    hotelbeds_api_key: "",
    hotelbeds_secret: "",
    included_bedbanks: false,
    is_live: false,
    global_account: false,
    is_bar_only: false,
    with_side_bar: false,
    login_id: "",
    code: "",
    source_entity: "",
    supplier: "",
    id: "",
    api_key: "",
    username: "",
    password: "",
    apikey: "",
    secret: "",
    client_code: "",
    api_password: "",
    login: "",
    agent_code: "",
    user: "",
    currency: "",
    api_currency: "",
    codusu: "",
    secacc: 0,
    codigousu: "",
    clausu: "",
    afil: "",
    contact_details: {
      title: "",
      first_name: "",
      last_name: "",
      email: "",
    },
  },
};

const getSchema = ({ required_message, vendor }) => {
  return yup.object().shape({
    vendor: yup.string().required(`Vendor: ${required_message}`),
    status: yup.string().required(`Status: ${required_message}`),
    is_own: yup.boolean(),
    notify_amount: yup.number().nullable(),
    travel_service_type: yup.string().default("ACC"),
    member: yup
      .number()
      .integer()
      .typeError("Member must be an integer")
      .required(`Member: ${required_message}`),
    vendor_data: yup.object().shape({
      login_id: yup.string().test({
        name: "login_id_is_required_for_DOTW",
        message: `Login Id: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return vendor === "acc__DOTW" ? !!value : true;
        },
      }),
      code: yup.string().test({
        name: "code_is_required_for_DOTW",
        message: `Code: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return vendor === "acc__DOTW" ? !!value : true;
        },
      }),
      id: yup.string().test({
        name: "id_is_required_for_Go_Global",
        message: `Id: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return vendor === "acc__Go Global" ? !!value : true;
        },
      }),
      username: yup.string().test({
        name: "username_is_required_for_GoGlobal_InnstantTravelHotels_TBOHolidays_Abreu",
        message: `Username: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return [
            "acc__Go Global",
            "acc__InnstantTravel Hotels",
            "acc__TBO Holidays",
            "acc__Abreu",
          ].includes(vendor)
            ? !!value
            : true;
        },
      }),
      password: yup.string().test({
        name: "password_is_required_for_GoGlobal_InnstantTravelHotels_Sunhotels_TotalStay_Travco_WebHotelier_TBOHolidays_Abreu_HotelSton",
        message: `Password: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return [
            "acc__Go Global",
            "acc__InnstantTravel Hotels",
            "acc__Sunhotels",
            "acc__Total Stay",
            "acc__Travco",
            "acc__TBO Holidays",
            "acc__WebHotelier",
            "acc__Abreu",
            "acc__HotelSton",
          ].includes(vendor)
            ? !!value
            : true;
        },
      }),
      apikey: yup.string().test({
        name: "api_key_is_required_for_Hotelbeds",
        message: `Api Key: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return vendor === "acc__Hotelbeds" ? !!value : true;
        },
      }),
      secret: yup.string().test({
        name: "secret_is_required_for_Hotelbeds",
        message: `Secret: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return vendor === "acc__Hotelbeds" ? !!value : true;
        },
      }),
      client_code: yup.string().test({
        name: "client_code_is_required_for_Miki",
        message: `Client Code: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return vendor === "acc__Miki" ? !!value : true;
        },
      }),
      api_password: yup.string().test({
        name: "api_password_is_required_for_Miki",
        message: `Api Password: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return vendor === "acc__Miki" ? !!value : true;
        },
      }),
      login: yup.string().test({
        name: "login_is_required_for_Sunhotels_TotalStay_HotelSton",
        message: `Login: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return [
            "acc__Sunhotels",
            "acc__Total Stay",
            "acc__HotelSton",
          ].includes(vendor)
            ? !!value
            : true;
        },
      }),
      agent_code: yup.string().test({
        name: "agent_code_is_required_for_Travco",
        message: `Agent Code: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return vendor === "acc__Travco" ? !!value : true;
        },
      }),
      user: yup.string().test({
        name: "user_is_required_for_WebHotelier",
        message: `User: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return vendor === "acc__WebHotelier" ? !!value : true;
        },
      }),
      codusu: yup.string().test({
        name: "codusu_required_for_Restel",
        message: `Codusu: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["acc__Restel"].includes(vendor) ? !!value : true;
        },
      }),
      secacc: yup.number().test({
        name: "secacc_required_for_Restel",
        message: `Secacc: ${required_message}`,
        test: function (value) {
          if (value && value === 0) {
            return false;
          }
          return ["acc__Restel"].includes(vendor) ? !!value : true;
        },
      }),
      codigousu: yup.string().test({
        name: "codigousu_required_for_Restel",
        message: `Codigousu: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["acc__Restel"].includes(vendor) ? !!value : true;
        },
      }),
      clausu: yup.string().test({
        name: "clausu_required_for_Restel",
        message: `Clausu: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["acc__Restel"].includes(vendor) ? !!value : true;
        },
      }),
      afil: yup.string().test({
        name: "afil_required_for_Restel",
        message: `Afil: ${required_message}`,
        test: function (value) {
          if (value && value.trim().length === 0) {
            return false;
          }
          return ["acc__Restel"].includes(vendor) ? !!value : true;
        },
      }),
      currency: yup.string().required(`Currency: ${required_message}`),
      config_uid: yup.array().of(yup.string()),
      contact_details: yup.object().shape({
        // title: yup.string().oneOf(["mr", "ms"], "Gender Title: Invalid Value"),
        first_name: yup.string(),
        last_name: yup.string(),
        email: yup.string().email("Invalid Email"),
      }),
    }),
  });
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditAccommodationModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content  1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  inputGroup: { ...formInputGroup, textAlign: "start" },
  form: {
    display: "grid",
    gap: variables.normal_gap,
    padding: variables.half_gap,
  },
  formSectionHeader: formSections.sectionHeader,
  entityAutoComplete: {
    ...formInputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  cardActions: { ...cardStyles.actions, gridColumn: "2 span" },
  [`@media ${variables.media.smallscreen}`]: {
    modalCard: {
      width: "60rem",
    },
    cardCol2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
});

const EditAccommodationModal = ({ accommodationId, onClose }) => {
  const classes = styles();

  const mode = accommodationId ? "edit" : "add";

  const [form, setForm] = useState(initialForm);
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const queryClient = useQueryClient();

  const { isLoading } = useQuery({
    queryKey: ["RetrieveEditAccommodationModal"],
    queryFn: () => {
      return retrieveVendorAccomodation({ id: accommodationId });
    },
    enabled: mode === "edit",
    onSuccess: (data) => {
      const retrievedData = _.get(data, "data", {});
      const contactPerson = _.get(
        retrievedData,
        "vendor_data.contact_details",
        {}
      );
      setForm({
        ...retrievedData,
        vendor_data: {
          ...retrievedData.vendor_data,
          contact_details: !_.isEmpty(contactPerson)
            ? contactPerson
            : {
                title: "",
                first_name: "",
                last_name: "",
                email: "",
              },
        },
      });
      setAutocompleteValue(_.get(data, "data.member"));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const mutation = useMutation({
    mutationFn: async ({ id, payload }) => {
      if (mode === "add") {
        return createNewVendorAccommodation({
          payload: { ...payload },
        });
      } else {
        return patchVendorAccommodation({
          id,
          payload: { ...payload },
        });
      }
    },

    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    onSuccess: () => {
      toast.success(
        mode === "add"
          ? "New Vendor Accommodation added"
          : "Vendor Accommodation info updated"
      );
      queryClient.invalidateQueries("AccommodationVendors");
      onClose();
    },
  });

  const validationSchema = getSchema({
    required_message: "This field is required.",
    vendor: form.vendor,
  });

  const numericFields = ["notify_amount"];
  const updatedNumericFields = convertStringToNumber(form, numericFields);

  const boolFields = convertStringToBoolean({
    global_account: _.get(form, "vendor_data.global_account"),
    is_own: _.get(form, "is_own"),
    is_live: _.get(form, "vendor_data.is_live"),
    is_bar_only: _.get(form, "vendor_data.is_bar_only"),
    with_side_bar: _.get(form, "vendor_data.with_side_bar"),
    included_bedbanks: _.get(form, "vendor_data.included_bedbanks"),
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(form, { abortEarly: false });
      mutation.mutate({
        id: accommodationId,
        payload: {
          ...form,
          ...updatedNumericFields,

          config_uid: _.isEmpty(_.get(form, "config_uid.[0]", []))
            ? []
            : [_.get(form, "config_uid[0]", [])],
          member: _.get(form, "member"),
          vendor_data: {
            ...form.vendor_data,
            secacc: _.get(form, "vendor_data.secacc")
              ? parseInt(_.get(form, "vendor_data.secacc"))
              : 0,
            ...boolFields,
          },
        },
      });
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const handleInputChange = (e) => {
    setForm((p) => ({
      ...p,
      [e.target.name]:
        e.target.name === "config_uid" ? [e.target.value] : e.target.value,
    }));
  };
  const handleVendorDataInputChange = (e) => {
    setForm((p) => ({
      ...p,
      vendor_data: {
        ..._.get(form, "vendor_data", {}),
        [e.target.name]: e.target.value,
      },
    }));
  };
  const handleInputContactPersonChange = (e) => {
    setForm((p) => ({
      ...p,
      vendor_data: {
        ..._.get(form, "vendor_data", {}),
        contact_details: {
          ..._.get(form, "vendor_data.contact_details", {}),
          [e.target.name]: e.target.value,
        },
      },
    }));
  };

  const loading = mutation.isLoading || (isLoading && mode === "edit");
  return (
    <div className={classes.EditAccommodationModal} id="AddEditACCVendorModal">
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} Accommodation Vendor</span>
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} Accommodation Vendor</span>
          </div>
          <div className={classes.modalCardBody}>
            <form>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Data</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label>Vendor</label>
                      <select
                        name="vendor"
                        id="vendor"
                        value={form.vendor}
                        disabled={mode === "edit"}
                        onChange={handleInputChange}>
                        {Object.entries(vendorsMapping).map(
                          ([key, label], idx) => (
                            <option key={idx} value={key}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    <div className={classes.inputGroup}>
                      <label htmlFor="member">Member</label>
                      <div className={classes.entityAutoComplete}>
                        <EntityAutocomplete
                          id="memberAutocomplete"
                          subagents={false}
                          independentAgents={false}
                          branches={false}
                          disabled={mode === "edit"}
                          value={autocompleteValue}
                          setValue={setAutocompleteValue}
                          onChange={(text) => {
                            if (text.length === 0) {
                              setForm((p) => ({
                                ...p,
                                member: "",
                              }));
                              return;
                            }
                            const entityInfos = text.split("---");
                            const entityId = entityInfos[1];
                            setForm((p) => ({
                              ...p,
                              member: `${entityId}`,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        id="status"
                        value={form.status}
                        onChange={handleInputChange}>
                        {Object.entries(statusMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Market Type</label>
                      <select
                        name="market_type"
                        id="market_type"
                        value={_.get(form, "market_type")}
                        onChange={handleInputChange}>
                        {Object.entries(marketTypeMapping).map(
                          ([key, label], idx) => (
                            <option key={idx} value={key}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Currency</label>
                      <select
                        name="currency"
                        id="currency"
                        value={_.get(form, "vendor_data.currency")}
                        onChange={handleVendorDataInputChange}>
                        {Object.entries(currencies).map(([key, label], idx) => (
                          <option key={idx} value={key}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Notify Amount</label>
                      <input
                        type="number"
                        name="notify_amount"
                        id="notify_amount"
                        value={_.get(form, "notify_amount")}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Config uid</label>
                      <input
                        type="text"
                        name="config_uid"
                        id="config_uid"
                        value={_.get(form, "config_uid[0]")}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Is Own</label>
                      <select
                        name="is_own"
                        id="is_own"
                        value={_.get(form, "is_own")}
                        onChange={handleInputChange}>
                        {Object.entries(trueFalseMapping).map(
                          ([key, label], idx) => (
                            <option key={idx} value={key}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Vendor Data</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label>Supplier</label>
                      <select
                        name="supplier"
                        id="supplier"
                        value={_.get(form, "vendor_data.supplier")}
                        onChange={handleVendorDataInputChange}>
                        {Object.entries(supplierOptions).map(
                          ([key, label], idx) => (
                            <option key={idx} value={key}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Source Entity</label>
                      <input
                        type="text"
                        name="source_entity"
                        id="source_entity"
                        disabled
                        value={`${_.get(form, "member")}___member`}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label>User</label>
                      <input
                        type="text"
                        name="user"
                        id="user"
                        value={_.get(form, "vendor_data.user")}
                        onChange={handleVendorDataInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Global Account</label>
                      <select
                        name="global_account"
                        id="global_account"
                        value={_.get(form, "vendor_data.global_account")}
                        onChange={handleVendorDataInputChange}>
                        {Object.entries(trueFalseMapping).map(
                          ([key, label], idx) => (
                            <option key={idx} value={key}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Is Live</label>
                      <select
                        name="is_live"
                        id="is_live"
                        value={_.get(form, "vendor_data.is_live")}
                        onChange={handleVendorDataInputChange}>
                        {Object.entries(trueFalseMapping).map(
                          ([key, label], idx) => (
                            <option key={idx} value={key}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Is Bar Only</label>
                      <select
                        name="is_bar_only"
                        id="is_bar_only"
                        value={_.get(form, "vendor_data.is_bar_only")}
                        onChange={handleVendorDataInputChange}>
                        {Object.entries(trueFalseMapping).map(
                          ([key, label], idx) => (
                            <option key={idx} value={key}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label>With Side Bar</label>
                      <select
                        name="with_side_bar"
                        id="with_side_bar"
                        value={_.get(form, "vendor_data.with_side_bar")}
                        onChange={handleVendorDataInputChange}>
                        {Object.entries(trueFalseMapping).map(
                          ([key, label], idx) => (
                            <option key={idx} value={key}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    <div className={classes.inputGroup}>
                      <label>Included bedbanks</label>
                      <select
                        name="included_bedbanks"
                        id="included_bedbanks"
                        value={_.get(form, "vendor_data.included_bedbanks")}
                        onChange={handleVendorDataInputChange}>
                        {Object.entries(trueFalseMapping).map(
                          ([key, label], idx) => (
                            <option key={idx} value={key}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Mishor Application Key</label>
                      <input
                        type="text"
                        name="mishor_application_key"
                        id="mishor_application_key"
                        value={_.get(
                          form,
                          "vendor_data.mishor_application_key"
                        )}
                        onChange={handleVendorDataInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Mishor Access Token</label>
                      <input
                        type="text"
                        name="mishor_access_token"
                        id="mishor_access_token"
                        value={_.get(form, "vendor_data.mishor_access_token")}
                        onChange={handleVendorDataInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Mishor Static Key</label>
                      <input
                        type="text"
                        name="mishor_static_key"
                        id="mishor_static_key"
                        value={_.get(form, "vendor_data.mishor_static_key")}
                        onChange={handleVendorDataInputChange}
                      />
                    </div>

                    <div className={classes.inputGroup}>
                      <label>Codusu</label>
                      <input
                        type="text"
                        name="codusu"
                        id="codusu"
                        value={_.get(form, "vendor_data.codusu")}
                        onChange={handleVendorDataInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Secacc</label>
                      <input
                        type="number"
                        name="secacc"
                        id="secacc"
                        value={_.get(form, "vendor_data.secacc")}
                        onChange={handleVendorDataInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Codigousu</label>
                      <input
                        type="text"
                        name="codigousu"
                        id="codigousu"
                        value={_.get(form, "vendor_data.codigousu")}
                        onChange={handleVendorDataInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Clausu</label>
                      <input
                        type="text"
                        name="clausu"
                        id="clausu"
                        value={_.get(form, "vendor_data.clausu")}
                        onChange={handleVendorDataInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Afil</label>
                      <input
                        type="text"
                        name="afil"
                        id="afil"
                        value={_.get(form, "vendor_data.afil")}
                        onChange={handleVendorDataInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Login Id</label>
                      <input
                        type="text"
                        name="login_id"
                        id="login_id"
                        value={_.get(form, "vendor_data.login_id")}
                        onChange={handleVendorDataInputChange}
                      />
                    </div>

                    <div className={classes.inputGroup}>
                      <label>Code</label>
                      <input
                        type="text"
                        name="code"
                        id="code"
                        value={_.get(form, "vendor_data.code")}
                        onChange={handleVendorDataInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Acccess Data</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol2}>
                      <div className={classes.inputGroup}>
                        <label>Username</label>
                        <input
                          type="text"
                          name="username"
                          id="username"
                          value={_.get(form, "vendor_data.username")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Password</label>
                        <input
                          type="text"
                          name="password"
                          id="password"
                          value={_.get(form, "vendor_data.password")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Hotelbeds Api Key</label>
                        <input
                          type="text"
                          name="hotelbeds_api_key"
                          id="hotelbeds_api_key"
                          value={_.get(form, "vendor_data.hotelbeds_api_key")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Hotelbeds Secret</label>
                        <input
                          type="text"
                          name="hotelbeds_secret"
                          id="hotelbeds_secret"
                          value={_.get(form, "vendor_data.hotelbeds_secret")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Api Key</label>
                        <input
                          type="text"
                          name="apikey"
                          id="apikey"
                          value={_.get(form, "vendor_data.apikey")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Api Password</label>
                        <input
                          type="text"
                          name="api_password"
                          id="api_password"
                          value={_.get(form, "vendor_data.api_password")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Log in</label>
                        <input
                          type="text"
                          name="login"
                          id="login"
                          value={_.get(form, "vendor_data.login")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Secret</label>
                        <input
                          type="text"
                          name="secret"
                          id="secret"
                          value={_.get(form, "vendor_data.secret")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>

                      <div className={classes.inputGroup}>
                        <label>Client Code</label>
                        <input
                          type="text"
                          name="client_code"
                          id="client_code"
                          value={_.get(form, "vendor_data.client_code")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>

                      <div className={classes.inputGroup}>
                        <label>Agent Code</label>
                        <input
                          type="text"
                          name="agent_code"
                          id="agent_code"
                          value={_.get(form, "vendor_data.agent_code")}
                          onChange={handleVendorDataInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Contact Person</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label>Gender Title</label>
                      <select
                        name="title"
                        id="title"
                        value={_.get(form, "vendor_data.contact_details.title")}
                        onChange={handleInputContactPersonChange}>
                        {Object.entries(titleMapping).map(
                          ([key, label], idx) => (
                            <option key={idx} value={key}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label>First Name</label>
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        value={_.get(
                          form,
                          "vendor_data.contact_details.first_name"
                        )}
                        onChange={handleInputContactPersonChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Last Name</label>
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        value={_.get(
                          form,
                          "vendor_data.contact_details.last_name"
                        )}
                        onChange={handleInputContactPersonChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={_.get(form, "vendor_data.contact_details.email")}
                        onChange={handleInputContactPersonChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={onClose} id="cancel">
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton appearance="primary" onClick={onSubmit} id="submit">
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditAccommodationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditAccommodationModal;
