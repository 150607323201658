import { VALAQUENTA_API_URL } from "..";
import { authorizedAxios, loggingAxios } from "../../App";

export function fetchRailEuropeAPIConfig({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/railengine/api-config/`, {
    params,
  });
}

export function retrieveRailEuropeAPIConfig({ uid }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/railengine/api-config/${uid}/`
  );
}

export function patchRailEuropeAPIConfig({ uid, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/railengine/api-config/${uid}/`,
    payload
  );
}

export function CreateNewRailEuropeAPIConfig({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/railengine/api-config/`,
    payload
  );
}

export function cloneRailEuropeAPIConfig({ uid }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/railengine/api-config/clone/${uid}/`
  );
}

export function deleteRailEuropeAPIConfig({ uid }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/railengine/api-config/${uid}/`
  );
}
