import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  GenericNavbarsStyles,
  inputGroup,
  variables,
} from "../../../../variables";
import { onEnterDown } from "../../../../helpers";
import { CustomButton } from "../../../../components/CustomButton";

const emailTypeMapping = {
  "": "-----",
  VO: "Voucher",
  CXL: "Cancellation",
  RQ: "On Request",
  FA: "Failed Reservation",
  OFF: "Offer Notification",
};
const reservationTypeMapping = {
  "": "-----",
  ACC: "Accommodations",
  GEN: "General Services",
  MI: "Addons",
  TRF: "Transfers ",
  PKG: "Packages",
  FL: "Flights",
  COO: "Coordinators",
  TRA: "Trains",
  FER: "Ferrys",
  COA: "Coaches",
  REST: "Restaurants",
  ACT: "Activities",
  CAR: "Car Rentals",
};
// const reservationsTypesMapping = {
//   "": "-----",
//   ACC: "Accommodation",
//   GEN: "General Service",
//   MI: "Addon",
//   TRF: "Transfers ",
//   PKG: "Package ",
//   FL: "Flights",
//   COO: "Coordinator",
//   TRA: "Train",
//   FER: "Ferry",
//   COA: "Coach",
//   REST: "Restaurant",
//   ACT: "Activity",
//   OFF: "Offer",
// };
const marketTypeMapping = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};
const navbarStyles = createUseStyles({
  Navbar: {
    ...GenericNavbarsStyles.navbars.mobile(),
    gridTemplateRows: "max-content max-content max-content max-content ",
  },
  label: { ...inputGroup },
  divider: GenericNavbarsStyles.divider.mobile(),
  filters: GenericNavbarsStyles.filters.mobile(),
  actions: GenericNavbarsStyles.actions.mobile(),
  inputGroup: {
    ...inputGroup,
    width: "100%",
    "& input, select": {
      ...inputGroup["& input, select"],
      width: "100%",
    },
  },
  inputGroupOneLine: {
    ...inputGroup,
    gridColumn: "2 span",
    width: "100%",
  },
  [`@media ${variables.media.smallscreen}`]: {
    Navbar: {
      ...GenericNavbarsStyles.navbars.smallscreen(),
      gridTemplateColumns: "repeat(2, max-content) 1fr auto",
      gridTemplateRows: "max-content",
    },
    divider: GenericNavbarsStyles.divider.smallscreen(),
    filters: {
      ...GenericNavbarsStyles.filters.smallscreen(),
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    actions: {
      ...GenericNavbarsStyles.actions.smallscreen(),
      padding: "0rem",
      paddingBottom: variables.half_gap,
    },
    inputGroup: {
      ...inputGroup,
      width: "100%",
      "& input, select": {
        ...inputGroup["& input, select"],
        width: "100%",
      },
    },
    inputGroupOneLine: { ...inputGroup.bigscreen, gridColumn: "1 span" },
  },
  [`@media ${variables.media.bigscreen}`]: {
    filters: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
  },
});

const Navbar = ({ filters, initialFilters, onApplyFilters, onAdd }) => {
  const classes = navbarStyles();

  const [localFilters, setLocalFilters] = useState(filters);

  return (
    <div className={classes.Navbar}>
      <div className={classes.label}>
        <strong>Email Configuration</strong>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.filters}>
        <div className={classes.inputGroup}>
          <label>Title</label>
          <input
            id="filterTitle"
            type="text"
            value={localFilters.title__icontains}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                title__icontains: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Email Type</label>
          <select
            id="filterEmailType"
            type="text"
            value={localFilters.email_type}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                email_type: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(emailTypeMapping).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Reservation Type</label>
          <select
            id="filterReservationType"
            type="text"
            value={localFilters.reservation_type}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                reservation_type: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(reservationTypeMapping).map(
              ([value, label], idx) => (
                <option key={idx} value={value}>
                  {label}
                </option>
              )
            )}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Market Type</label>
          <select
            id="filterMarketType"
            type="text"
            value={localFilters.market_type}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                market_type: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(marketTypeMapping).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={classes.actions}>
        <CustomButton
          id="ResetFiltersButton"
          appearance="ghost"
          onClick={() => {
            setLocalFilters({
              ...initialFilters,
            });
          }}>
          <strong>Reset</strong>
        </CustomButton>
        <CustomButton
          id="ApplyFiltersButton"
          appearance="primary"
          onClick={() => onApplyFilters(localFilters)}>
          <strong>Apply Filters</strong>
        </CustomButton>
      </div>
    </div>
  );
};
Navbar.propTypes = {
  filters: PropTypes.object.isRequired,
  initialFilters: PropTypes.object.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default Navbar;
