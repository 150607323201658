import { CANAVERAL_API_URL, VALAQUENTA_API_URL } from "../../..";
import { authorizedAxios, loggingAxios } from "../../../../App";

export function fetchWebSiteLandingPages({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/landing-pages-for-entity/`,
    { params }
  );
}

export function retrieveWebSiteLandingPage({ id }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/landing-pages-for-entity/${id}/`
  );
}

export function patchWebSiteLandingPage({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/canaveral/landing-pages-for-entity/${id}/`,
    payload
  );
}

export function createWebSiteLandingPage({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/canaveral/landing-pages-for-entity/`,
    payload
  );
}

export function deleteWebSiteLandingPage({ id }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/canaveral/landing-pages-for-entity/${id}/`
  );
}

export async function fetchAddonsQuickInfo({ params }) {
  return await authorizedAxios.get(`${CANAVERAL_API_URL}/addons-quick-info/`, {
    params,
  });
}

export async function fetchPackagesQuickInfo({ params }) {
  return await authorizedAxios.get(
    `${CANAVERAL_API_URL}/packages-quick-info/`,
    {
      params,
    }
  );
}
