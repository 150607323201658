export const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

export const hasSearchFormMapping = {
  "": "-----",
  true: "Yes",
  false: "No",
};
