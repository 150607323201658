import React from "react";
import { createUseStyles } from "react-jss";
import {
  btnStyles,
  cardStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import ReceivablesStatus from "./ReceivablesStatus";

const providersMapping = {
  "": "-----",
  stripe: "Stripe",
  fellow: "Fellow",
};
const typeMapping = {
  deposit: "Deposit",
  balance: "Balance",
  prepaid: "Prepaid",
  part_payment: "Part Payment",
};
const paymentFlowMapping = {
  immediate: "Immediate",
  for_authorization: "For Authorization",
  authorized: "Authorized",
};

const guestStyles = createUseStyles({
  ...cardStyles,
  Receivables: { ...cardStyles.card, ...cardStyles.card, maxWidth: "100%" },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: { ...tableStyles.table, maxWidth: "100%", overflow: "hidden" },
  thead: tableStyles.head,
  tbody: tableStyles.body,
  trow: tableStyles.body.row,
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  btn: btnStyles.btn,
  noDataCardBody: {
    display: "grid",
    padding: variables.normal_gap,
    gridTemplateColumns: "1fr max-content",
    justifyItems: "center",
    color: variables.colors.base,
  },
});

const Receivables = ({ title, data }) => {
  const classes = guestStyles();

  console.log("data", data);

  return (
    <div id="receivablesList" className={classes.GuestCard}>
      {_.isEmpty(data) ? (
        <React.Fragment>
          <div className={classes.header}>
            <span>Payment Links</span>
          </div>
          <div className={classes.tableContainer}>
            <table className={classes.table}>
              <thead className={classes.thead}>
                <tr className={classes.headRow}>
                  <th className={classes.headCell}>#</th>
                  <th className={classes.headCell}>Amount</th>
                  <th className={classes.headCell}>Product Name</th>
                  <th className={classes.headCell}>Description</th>
                  <th className={classes.headCell}>Providers</th>
                  <th className={classes.headCell}>Due Date</th>
                  <th className={classes.headCell}>Type</th>
                  <th className={classes.headCell}>Payment Flow</th>
                  <th className={classes.headCell}>Status</th>
                </tr>
              </thead>
            </table>
            <div className={classes.noDataCardBody}>
              <span>
                <strong>No Data Available</strong>
              </span>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={classes.header}>
            <span>Payment Links</span>
          </div>
          <div className={classes.tableContainer}>
            <table className={classes.table}>
              <thead className={classes.thead}>
                <tr className={classes.headRow}>
                  <th className={classes.headCell}>#</th>
                  <th className={classes.headCell}>Amount</th>
                  <th className={classes.headCell}>Product Name</th>
                  <th className={classes.headCell}>Description</th>
                  <th className={classes.headCell}>Providers</th>
                  <th className={classes.headCell}>Due Date</th>
                  <th className={classes.headCell}>Type</th>
                  <th className={classes.headCell}>Payment Flow</th>
                  <th className={classes.headCell}>Status</th>
                </tr>
              </thead>
              <tbody className={classes.tbody}>
                {(data || []).map((data, idx) => (
                  <tr key={idx} className={classes.trow}>
                    <td className={classes.tableCell}>{idx + 1}</td>
                    <td className={classes.tableCell}>
                      {_.get(data, "amount", 0) || "N/A"}
                    </td>

                    <td className={classes.tableCell}>
                      {_.get(data, "product_name", "") || "N/A"}
                    </td>
                    <td className={classes.tableCell}>
                      {_.get(data, "description", "") || "N/A"}
                    </td>
                    <td className={classes.tableCell}>
                      {_.get(providersMapping, _.get(data, "provider", "")) ||
                        "N/A"}
                    </td>
                    <td className={classes.tableCell}>
                      {" "}
                      {_.get(data, "due_date", "") || "N/A"}
                    </td>
                    <td className={classes.tableCell}>
                      {" "}
                      {_.get(typeMapping, _.get(data, "type", "")) || "N/A"}
                    </td>
                    <td className={classes.tableCell}>
                      {" "}
                      {_.get(
                        paymentFlowMapping,
                        _.get(data, "payment_flow", "")
                      ) || "N/A"}
                    </td>
                    <td className={classes.tableCell}>
                      {<ReceivablesStatus status={_.get(data, "status", "")} />}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
Receivables.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
};
export default Receivables;
