import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { modalGenericStyles, variables } from "../../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import Loader from "../../../../../components/Loader/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import _ from "lodash";
import { deleteWebSiteHotelPriority } from "../../../../../api/DistributionSite/webSites/HotelPriorities";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteHotelPrioritiesModal: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
  },
});
const DeleteHotelPrioritiesModal = ({
  id,
  sourceEntity,
  websiteApiKey,
  data,
  onClose,
}) => {
  const classes = styles();

  const queryClient = useQueryClient();

  const basicDataCpl = [
    ["Id", "id"],
    ["Name", "name"],
  ];

  const deleteHotelPrioritySection = useMutation({
    mutationFn: ({ id, params }) =>
      deleteWebSiteHotelPriority({
        id,
        params,
      }),
    onSuccess: () => {
      toast.success("Hotel Priority Section Deleted");
      onClose();
      queryClient.invalidateQueries("HotelPrioritiesList");
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const onDelete = (id, websiteApiKey, sourceEntity) => {
    deleteHotelPrioritySection.mutate({
      id,
      params: {
        website_api_key: websiteApiKey,
        source_entity: sourceEntity,
      },
    });
  };

  const loading = deleteHotelPrioritySection.isLoading || _.isEmpty(data);

  return (
    <div className={classes.DeleteHotelPrioritiesModal}>
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <span>DELETE Hotel Priority</span>
          </div>
          <div className={classes.cardBody}>
            <div className={classes.basicInfo}>
              <GenericBasicInfo
                header={"Are you sure you want to delete this?"}
                cardInfo={basicDataCpl}
                objectData={data}
              />
            </div>
          </div>
          <div className={classes.cardActions}>
            <CustomButton appearance="ghost" text="Cancel" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              appearance="primary"
              onClick={() => onDelete(id, websiteApiKey, sourceEntity)}>
              <strong>DELETE</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
DeleteHotelPrioritiesModal.propTypes = {
  id: PropTypes.number.isRequired,
  websiteApiKey: PropTypes.string.isRequired,
  sourceEntity: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DeleteHotelPrioritiesModal;
