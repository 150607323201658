import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  btnStyles,
  modalGenericStyles,
  tableStyles,
  variables,
  genericIndexesStyles,
  cardStyles,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { fetchWebSiteContactInfo } from "../../../../../api/DistributionSite/webSites/ContactInfo";
import { statusMapping } from "../../common";
import _ from "lodash";
import Loader from "../../../../../components/Loader";
import EditContactInfo from "../EditContactInfo";
import DeleteContactInfo from "../DeleteContactInfo";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...cardStyles,
  ...modalGenericStyles,
  ContactInfoModal: {
    ...cardStyles.card,
  },
  loader: { width: "100vw" },
  cardBody: {
    ...cardStyles.cardBody,
    // minHeight: "30vh",
    // textAlign: "-webkit-center",
  },
  tableContainer: {
    overflow: "auto",
  },
  table: { ...tableStyles.table },
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: tableStyles.body.cell,
  actionCell: {
    ...genericIndexesStyles.actionCell.mobile(),
    padding: variables.double_gap,
  },
  // btn: btnStyles.btn,
  add: {
    padding: "0.75rem",
    textAlign: "end",
  },
  addBtn: { ...btnStyles.btn, width: "4rem" },
  [`@media ${variables.media.smallscreen}`]: {
    loader: { width: "100%" },
  },
});

const ContactInfoModal = ({ websiteId }) => {
  const classes = styles();

  const [contactInfo, setContactInfo] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ["contactInfoList"],
    queryFn: async () =>
      await fetchWebSiteContactInfo({
        params: {
          website: websiteId,
        },
        onError: (error) => genericRequestErrorHandler(error),
      }),
  });

  return (
    <div className={classes.ContactInfoModal}>
      <React.Fragment>
        <React.Fragment>
          <div className={classes.cardHeader}>
            Contact Info
            <div className={classes.add}>
              <CustomButton
                appearance="ghost"
                onClick={() => {
                  setEditId(null);
                  setShowEditModal(true);
                }}>
                <strong>Add</strong>
              </CustomButton>
            </div>
          </div>
          <div className={classes.cardBody}>
            {isLoading && <Loader onTop={true} />}
            <table className={classes.table}>
              <thead className={classes.thead}>
                <tr className={classes.headRow}>
                  <th className={classes.headCell}>#</th>
                  <th className={classes.headCell}>Id</th>
                  <th className={classes.headCell}>Name</th>
                  <th className={classes.headCell}>Address</th>
                  <th className={classes.headCell}>Phone</th>
                  <th className={classes.headCell}>Email</th>
                  <th className={classes.headCell}>Emergency Phone</th>
                  <th className={classes.headCell}>Registration Info</th>
                  <th className={classes.headCell}>Registration Number</th>
                  <th className={classes.headCell}>Status</th>
                  <th className={classes.headCell}>Actions</th>
                </tr>
              </thead>
              <tbody className={classes.tbody}>
                {_.get(data, "data.results", []).map((contact, idx) => (
                  <tr key={idx} className={classes.bodyRow}>
                    <td className={classes.cell}>{idx + 1}</td>
                    <td className={classes.cell}>{contact.id}</td>
                    <td className={classes.cell}>{contact.name || "N/A"}</td>
                    <td className={classes.cell}>{contact.address || "N/A"}</td>
                    <td className={classes.cell}>{contact.phone || "N/A"}</td>
                    <td className={classes.cell}>{contact.email || "N/A"}</td>
                    <td className={classes.cell}>
                      {contact.emergency_phone || "N/A"}
                    </td>
                    <td className={classes.cell}>
                      {contact.registration_info || "N/A"}
                    </td>
                    <td className={classes.cell}>
                      {contact.registration_number || "N/A"}
                    </td>
                    <td className={classes.cell}>
                      {_.get(statusMapping, _.get(contact, "status")) || "N/A"}
                    </td>
                    <td className={classes.actionCell}>
                      <CustomButton
                        appearance="ghost"
                        onClick={() => {
                          setEditId(contact.id);
                          setShowEditModal(true);
                        }}>
                        <strong>Edit</strong>
                      </CustomButton>
                      <CustomButton
                        appearance="ghost"
                        onClick={() => {
                          setDeleteId(contact.id);
                          setContactInfo(contact);
                          setDeleteModal(true);
                        }}>
                        <strong>Delete</strong>
                      </CustomButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </React.Fragment>
        {showEditModal && (
          <EditContactInfo
            id={editId}
            websiteId={websiteId}
            onClose={() => {
              setEditId(null);
              setShowEditModal(false);
            }}
          />
        )}
        {deleteModal && (
          <DeleteContactInfo
            id={deleteId}
            data={contactInfo}
            onClose={() => {
              setDeleteModal(false);
            }}
          />
        )}
      </React.Fragment>
    </div>
  );
};
ContactInfoModal.propTypes = {
  websiteId: PropTypes.number.isRequired,
};
export default ContactInfoModal;
