import tinycolor from "tinycolor2";

export const variables = {
  double_gap: "1.5rem",
  normal_gap: ".75rem",
  half_gap: ".375rem",
  colors: {
    base: "#673AB7",
    baseLighter: tinycolor("#673AB7").lighten(30).toHexString(),
    baseLighter1: tinycolor("#673AB7").lighten(15).toHexString(),
    baseLighter2: tinycolor("#673AB7").lighten(45).toHexString(),
    baseDarker: tinycolor("#673AB7").darken(10).toHexString(),
    background: {
      dark: "#673AB7",
      darkHover: tinycolor("#673AB7").lighten(20).toHexString(),
      secondary: {
        dark: "#cdcdcd",
        light: "#f5f5f5",
      },
    },
    border: {
      dark: "#673AB7",
      baseBorder: tinycolor("#673AB7").lighten(20).toHexString(),
    },
    text: {
      light: "white",
      dark: "black",
    },
    tableAltRows: "#e4e4e4",
  },
  shadows: {
    normalShadow: "2px 2px 5px rgba(0, 0, 0, 0.12)",
    heavyShadow: "2px 2px 5px rgba(0, 0, 0, 0.4)",
    normalInsetShadow: "inset 2px 2px 10px rgba(0, 0, 0, 0.2)",
    normalInsetSideShadow: "inset 2px 0px 10px rgba(0, 0, 0, 0.1)",
    hoverShadow: `2px 2px 20px rgba(0, 0, 0, 0.2)`,
  },
  media: {
    smallscreen: "screen and (min-width: 1024px)",
    bigscreen: "screen and (min-width: 1441px)",
    retina:
      "screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)",
    // "screen and (-webkit-min-device-pixel-ratio: 1) and (max-resolution: 230dpi) and (orientation: landscape)",
  },
};

export const VerticalTableStyles = {
  table: { width: "100%" },
  head: {
    row: {
      width: "100%",
      // background: variables.colors.background.secondary.dark,
      background: variables.colors.base,
      textAlign: "end",
      // color: variables.colors.text.dark,
      color: "white",
      padding: variables.half_gap,
      borderBottom: `2px solid ${variables.colors.border.dark}`,
    },
  },
  body: {
    cell: {
      textAlign: "center",
      padding: variables.half_gap,
      color: variables.colors.text.dark,
    },
  },
};

export const tableStyles = {
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  head: {
    row: {
      width: "100%",
      // background: variables.colors.background.secondary.dark,
      background: variables.colors.base,
      borderBottom: `2px solid ${variables.colors.border.baseBorder}`,
    },
    cell: {
      textAlign: "center",
      // color: variables.colors.text.dark,
      color: "white",
      padding: variables.half_gap,
      borderBottom: `2px solid ${variables.colors.border.dark}`,
    },
  },
  body: {
    row: {
      width: "100%",
      verticalAlign: "middle",
      textAlign: "center",
      "&:nth-child(even)": {
        // background: variables.colors.tableAltRows,
        background: variables.colors.baseLighter2,
      },
    },
    cell: {
      justifyContent: "center",
      textAlign: "center",
      padding: variables.half_gap,
      color: variables.colors.text.dark,
    },
  },
  checkBox: {
    width: `calc(${variables.normal_gap} * 1.5)`,
    height: `calc(${variables.normal_gap} * 1.5)`,
    textAlign: "center",
  },
};

export const selectStyles = {
  select: {
    display: "grid",
    height: "78%",
    textAlign: "center",
    width: "100%",
    borderRadius: "5px",
    cursor: "pointer",
  },
  options: {
    width: "50%",
  },
};

export const modalGenericStyles = {
  "@keyframes slidein": {
    from: {
      background: "rgba(0,0,0,0)",
      backdropFilter: "blur(0px)",
    },
    to: {
      background: "rgba(0,0,0,0.3)",
      backdropFilter: "blur(5px)",
    },
  },
  modal: {
    display: "grid",
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    zIndex: 5,
    placeContent: "center",
    background: "rgba(0,0,0,0.3)",
    backdropFilter: "blur(5px)",
    animationDuration: ".5s",
    animationName: "$slidein",
  },
  card: {
    position: "relative",
    display: "grid",
    gridTemplateRows: "max-content 1fr max-content",
    background: "white",
    boxShadow: variables.shadows.normalShadow,
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    maxHeight: "80vh",
    maxWidth: "90vw",
    // smallscreen: { card: { maxWidth: "80vw" } },
  },
  cardHeader: {
    borderBottom: `2px solid ${variables.colors.border.baseBorder}`,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    padding: variables.normal_gap,
    gridGap: variables.normal_gap,
    fontSize: "x-large",
    color: variables.colors.baseDarker,
  },
  cardBody: {
    display: "grid",
    padding: variables.double_gap,
    minHeight: "30vh",
    maxHeight: "70vh",
    overflow: "auto",
  },
  cardActions: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoFlow: "column",
    justifyContent: "end",
    padding: variables.normal_gap,
  },

  [`@media ${variables.media.retina}`]: {
    card: {
      minWidth: "40rem",
    },
  },
};

export const formItemStyles = {
  display: "grid",
  gridGap: variables.half_gap,
  width: "100%",
  padding: variables.half_gap,
  justifySelf: "center",
  "& label": {
    fontSize: "small",
    fontWeight: "bold",
  },
  "& input, select": {
    padding: variables.half_gap,
  },
};

// ============================== TABS ==============================
export const tabsStyles = {
  display: "grid",
  // gridTemplateColumns: "repeat(2, 7rem)",
  gridAutoFlow: "column",
  padding: variables.half_gap,
  gridGap: `${variables.half_gap} ${variables.normal_gap}`,
  justifyContent: "start",
};

export const tabBtnsStyles = {
  justifyContent: "center",
  fontSize: "x-small",
  height: "50px",
  borderRadius: "5px",
  cursor: "pointer",
  fontWeight: "bold",
  transition: "background .3s, color .3s",
  "&:hover": {
    background: variables.colors.background.darkHover,
    color: variables.colors.text.light,
  },
};

// ============================== INDEXES ==============================
export const genericIndexesStyles = {
  indexes: {
    mobile: () => ({
      display: "grid",
      gridTemplateRows: "auto auto  1fr",
      overflow: "hidden",
      position: "relative",
    }),
    smallscreen: () => ({}),
  },
  tableContainer: {
    mobile: () => ({
      overflow: "auto",
    }),
    smallscreen: () => ({}),
  },
  actionCell: {
    mobile: () => ({
      ...tableStyles.body.cell,
      display: "grid",
      gap: variables.half_gap,
    }),
    smallscreen: () => ({
      ...tableStyles.body.cell,
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: variables.half_gap,
      padding: [
        `${variables.double_gap} ${variables.normal_gap}`,
        `${variables.double_gap} ${variables.normal_gap}`,
      ],
    }),
  },
};
// ============================== NAVBARS ==============================
export const GenericNavbarsStyles = {
  navbars: {
    mobile: () => ({
      display: "grid",
      gridAutoFlow: "row",
      background: variables.colors.background.secondary.dark,
      // background: variables.colors.baseLighter1,
      textAlign: "center",
      padding: variables.normal_gap,
      paddingTop: "2rem",
      gridGap: variables.half_gap,
      minHeight: "7vh",
    }),
    smallscreen: () => ({
      gridAutoFlow: "column",
      alignItems: "center",
      overflow: "auto",
      justifyContent: "start",
      fontSize: "small",
      padding: variables.normal_gap,
      paddingTop: variables.half_gap,
      paddingBottom: variables.half_gap,
    }),
  },
  label: () => ({
    fontWeight: "bold",
    fontSize: "large",
  }),
  // ============================== FILTERS ==============================
  filters: {
    mobile: () => ({
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridGap: variables.half_gap,
      alignItems: "end",
      justifyItems: "center",
      padding: variables.normal_gap,
    }),
    smallscreen: () => ({
      padding: variables.half_gap,
      alignItems: "end",
      paddingTop: variables.half_gap,
      paddingBottom: variables.half_gap,
      justifyItems: "start",
    }),
  },
  // ============================== ACTIONS ==============================
  actions: {
    mobile: () => ({
      display: "grid",
      // gridAutoFlow: "column",
      gridGap: variables.normal_gap,
    }),
    smallscreen: () => ({
      display: "grid",
      padding: variables.half_gap,
      alignItems: "end",

      gridAutoFlow: "column",
      gridGap: variables.normal_gap,
      height: "100%",
      boxSizing: "border-box",
    }),
  },
  // ============================== DIVIDER ==============================
  divider: {
    mobile: () => ({
      // gridColumn: "2 span",
      width: "100%",
      height: "0%",
      border: `1px solid ${variables.colors.border.dark}`,
      // border: `1px solid white`,
    }),
    smallscreen: () => ({
      gridColumn: "1 span",
      alignSelf: "center",
      height: "100%",
      width: "0%",
    }),
  },
};
// ============================== FORMS ==============================
export const formSections = {
  sectionHeader: {
    gridColumn: "span 2",
    borderBottom: `2px solid ${variables.colors.border.baseBorder}`,
    paddingTop: variables.normal_gap,
    marginBottom: variables.half_gap,
  },
};

// ============================== BUTTONS ==============================
export const btnStyles = {
  btn: {
    height: "78%",
    padding: ".35em",
    textAlign: "center",
    width: "100%",
    cursor: "pointer",
  },
};
export const addBtnStyles = {
  addBtn: {
    backgroundColor: variables.colors.background.dark,
    borderRadius: "5px",
    color: "#FFFFFF",
    fontWeight: "bold",
    cursor: "pointer",
    alignSelf: "stretch",
    height: "25px",
    lineHeight: "25px",
    textAlign: "center",
    width: "100%%",
    border: "0",
  },
};
// ============================== CARDS ==============================
export const cardStyles = {
  card: {
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    alignContent: "start",
  },
  header: {
    padding: variables.normal_gap,
    borderBottom: `2px solid ${variables.colors.base}`,
    background: variables.colors.background.ultraLight,
    borderRadius: [variables.half_gap, variables.half_gap, 0, 0],
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: variables.half_gap,
    alignItems: "center",
    color: variables.colors.base,
  },
  body: {
    gridAutoFlow: "row",
    padding: variables.normal_gap,
    gap: variables.normal_gap,
  },
  actions: {
    display: "grid",
    gridAutoFlow: "column",
    justifyContent: "end",
    gridGap: variables.half_gap,
    padding: variables.normal_gap,
  },
  cardCol1: {
    display: "grid",
    gridTemplateColumns: "repeat(1,1fr)",
    gap: variables.normal_gap,
  },
  cardCol2: {
    display: "grid",
    gridTemplateColumns: "repeat(1,1fr)",
    gap: variables.normal_gap,
  },
  cardCol3: {
    display: "grid",
    gridTemplateColumns: "repeat(1,1fr)",
    gap: variables.normal_gap,
  },
  cardCol4: {
    display: "grid",
    gridTemplateColumns: "repeat(1,1fr)",
    gap: variables.normal_gap,
  },
};

// ============================== INPUTS =============================

export const inputGroup = {
  display: "grid",
  gridGap: variables.half_gap,
  "& select, input": {
    boxSizing: "border-box",
    height: `calc(${variables.normal_gap} * 2)`,
  },
  bigscreen: {
    width: "100%",
    "& input, select": {
      width: "100%",
    },
  },
};

export const formInputGroup = {
  display: "grid",
  gridGap: `calc(${variables.normal_gap} / 3)`,
  gridTemplateRows: "max-content 2rem",
  gridAutoFlow: "max-content",
  width: "100%",
  paddingBottom: variables.normal_gap,
  "& label": {
    fontSize: "small",
  },
};
