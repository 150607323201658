import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  formSections,
  inputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Loader from "../../../../../components/Loader";
import _ from "lodash";
import {
  createEmailConfigReservation,
  patchEmailConfigReservation,
  retrieveEmailConfigReservation,
} from "../../../../../api/CRS/EmailConfiguration";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import * as yup from "yup";
import EntityAutocomplete from "../../../../../components/EntityAutocomplete";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  // name: yup
  //   .string()
  //   .min(2, "Name: Must be at least 2 characters")
  //   .max(100, "Name: Must be less than 100 characters"),
  // market_type: yup
  //   .mixed()
  //   .oneOf(["B2B", "B2C"], "Market Type: Must be between B2B or B2C"),
  // is_live: yup.bool("Is Live: Must be a valid boolean"),
  // api_Url: yup.string().min(1, "Api Url: Must not be blanc"),
  // api_key: yup.string().min(1, "Api Key: Must not be blanc"),
});

const marketTypeOptions = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};
const emailTypeMapping = {
  "": "-----",
  VO: "Voucher",
  CXL: "Cancellation",
  RQ: "On Request",
  FA: "Failed Reservation",
  OFF: "Offer Notification",
};
const reservationTypeMapping = {
  "": "-----",
  ACC: "Accommodation",
  GEN: "General Service",
  MI: "Addon",
  TRF: "Transfers ",
  PKG: "Package ",
  FL: "Flights",
  COO: "Coordinator",
  TRA: "Train",
  FER: "Ferry",
  COA: "Coach",
  REST: "Restaurant",
  ACT: "Activity",
};
const globalAccountMapping = {
  false: "No",
  true: "Yes",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditEmailConfigurationModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content  1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  inputGroup: {
    ...formInputGroup,
    "& input, select": {
      ...inputGroup["& input, select"],
      width: "95%",
    },
  },
  entityAutoComplete: {
    ...inputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  form: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: variables.half_gap,
    padding: variables.half_gap,
  },
  sectionHeader: formSections.sectionHeader,
  [`@media ${variables.media.smallscreen}`]: {
    modalCard: {
      minWidth: "50rem",
    },
    cardCol2: {
      gridTemplateColumns: "repeat(2 , 1fr)",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
});

const EditEmailConfigurationModal = ({ selectedId, onClose }) => {
  const classes = styles();
  const queryClient = useQueryClient();
  const mode = selectedId ? "edit" : "add";
  const [form, setForm] = useState({});
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const { isLoading: isFetchLoading } = useQuery({
    queryKey: ["EditEmailConfigurationModal", selectedId],
    queryFn: () => retrieveEmailConfigReservation({ id: selectedId }),
    enabled: mode === "edit",
    onSuccess: (data) => {
      setForm(_.get(data, "data"));
      setAutocompleteValue(_.get(data, "data.source_entity", ""));
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const mutation = useMutation({
    mutationFn: async ({ uid, payload }) => {
      if (mode === "add") {
        return createEmailConfigReservation({ payload: { ...form } });
      }
      return patchEmailConfigReservation({
        id: selectedId,
        payload: { ...form },
      });
    },

    onSuccess: () => {
      toast.success(
        mode === "add" ? "Email Config added" : "Email Config Updated"
      );
      queryClient.invalidateQueries("emailsConfig");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });
  const isLoading = (mode === "edit" && isFetchLoading) || mutation.isLoading;

  const handleInputChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const onSubmit = async ({ id, payload }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      mutation.mutate({ id, payload });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        if (!validationErrors[err.path]) {
          validationErrors[err.path] = err.message;
          toast.error(err.message);
        }
      });
    }
  };

  return (
    <div id="editEmailConfig" className={classes.EditEmailConfigurationModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>{_.startCase(mode)} Email Configuration</span>
        </div>
        <div className={classes.modalCardBody}>
          {isLoading ? (
            <Loader />
          ) : (
            <form onChange={handleInputChange}>
              <div className={classes.card}>
                <div className={classes.sectionHeader}>Basic Data</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="title">Title</label>
                      <input
                        id="title"
                        type="text"
                        name="title"
                        defaultValue={form.title}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="source_entity">Source Entity</label>
                      <div className={classes.entityAutoComplete}>
                        <EntityAutocomplete
                          id="entityAutocompleteId"
                          value={autocompleteValue}
                          setValue={setAutocompleteValue}
                          onChange={(text) => {
                            if (text.length === 0) {
                              setForm((p) => ({
                                ...p,
                                source_entity: "",
                              }));
                              return;
                            }
                            const entityInfos = text.split("---");
                            const entityType = entityInfos[0];
                            const entityId = entityInfos[1];
                            setForm((p) => ({
                              ...p,
                              source_entity: `${entityId}___${entityType}`,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="market_type">Market Type</label>
                      <select
                        id="market_type"
                        name="market_type"
                        value={form.market_type}
                        onChange={() => {}}>
                        {Object.entries(marketTypeOptions).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="sender">Sender</label>
                      <input
                        id="sender"
                        type="email"
                        name="sender"
                        defaultValue={form.sender}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="template_name">Template Name</label>
                      <input
                        id="template_name"
                        type="text"
                        name="template_name"
                        defaultValue={form.template_name}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="subject">Subject</label>
                      <input
                        id="subject"
                        type="text"
                        name="subject"
                        defaultValue={form.subject}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="is_global">Is Global</label>
                      <select
                        id="is_global"
                        name="is_global"
                        value={form.is_global}
                        onChange={() => {}}>
                        {Object.entries(globalAccountMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="email_type">Email Type</label>
                      <select
                        id="email_type"
                        name="email_type"
                        value={form.email_type}
                        onChange={() => {}}>
                        {Object.entries(emailTypeMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="reservation_type">Reservation Type</label>
                      <select
                        id="reservation_type"
                        name="reservation_type"
                        value={form.reservation_type}
                        onChange={() => {}}>
                        {Object.entries(reservationTypeMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            id="submit"
            appearance="primary"
            onClick={(e) => {
              e.preventDefault();
              onSubmit({
                id: selectedId,
                payload: form,
              });
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
EditEmailConfigurationModal.propTypes = {
  selectedId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditEmailConfigurationModal;
