import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Navbar from "./Navbar";
import ControlStrip from "../../../../components/ControlStrip";
import Loader from "../../../../components/Loader";
import {
  currencies,
  handleApplyFilters,
  medWeekdayDatetimer,
} from "../../../../helpers";
import { fetchSubAgents } from "../../../../api/CRM/SubAgents/SubAgents";
import DetailsSubAgentModal from "../SubAgentsModals/DetailSubAgentModal";
import EditSubAgentModal from "../SubAgentsModals/EditSubAgentModal";
import { useQuery } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import { DateTime } from "luxon";
import { genericRequestErrorHandler } from "../../../../api";
import { CustomButton } from "../../../../components/CustomButton";

const initialFilters = {
  limit: 20,
  page: 1,
  name__icontains: "",
  mobile_phone__contains: "",
  legal_title__icontains: "",
  email__icontains: "",
  tags: "",
  created__gte: "",
  created__lte: "",
  ordering: "",
  discending: "",
  approved: "",
  currency__icontains: "",
  completeness__gte: "",
  id: null,
};

const navbarSchema = yup.object().shape({
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const subAgentStyles = createUseStyles({
  Subagents: genericIndexesStyles.indexes.mobile(),
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
  datesCell: { ...tableStyles.body.cell, minWidth: "9rem" },

  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0 !important`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});
const SubAgents = () => {
  const classes = subAgentStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [subAgentId, setsubAgentId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const tableHeaders = {
    Name: (val) => _.capitalize(_.get(val, "name")),
    "Source Entity": (val) => `${_.get(val, "id")}___subagent`,
    "Legal Title": (val) => _.capitalize(_.get(val, "legal_title")),
    Tags: (val) => val.tags.map((tag) => tag).join(", "),
    "Main Service": (val) => _.capitalize(_.get(val, "main_service")),
    Currency: (branch) => _.get(currencies, _.get(branch, "currency")),
    Email: (val) => val.email,
    Completeness: (val) => val.completeness,
    Approved: (val) => (_.get(val, "approved") === true ? "Yes" : "No"),
    Created: (val) => val.created,
  };

  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["SubAgents", filters],
    queryFn: () => {
      let correctPayload = { ...filters };
      if (filters.created__lte) {
        correctPayload["created__lte"] = DateTime.fromISO(
          filters.created__lte
        ).toISO();
      }
      if (filters.created__gte) {
        correctPayload["created__gte"] = DateTime.fromISO(
          filters.created__gte
        ).toISO();
      }
      return fetchSubAgents({
        params: correctPayload,
      });
    },
    enabled: !showDetailModal && !showEditModal,
    onError: (error) => genericRequestErrorHandler(error),
  });

  return (
    <div id="subagentsListPage" className={classes.SubAgents}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setsubAgentId(null);
          setShowEditModal(true);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.limit}
        page={filters.page}
        count={_.get(data, "data.count", 0)}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th className={classes.headCell} key={idx}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.get(data, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "created":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      return (
                        <td className={classes.datesCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    default:
                      cell = fn(val) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.tableCell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    id={`DetailSubagentButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setsubAgentId(val.id);
                      setShowDetailModal(true);
                    }}>
                    <strong>Details</strong>
                  </CustomButton>
                  <CustomButton
                    id={`EditSubagentButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setsubAgentId(val.id);
                      setShowEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDetailModal && (
        <DetailsSubAgentModal
          subAgentId={subAgentId}
          onClose={() => {
            setShowDetailModal(false);
          }}
        />
      )}
      {showEditModal && (
        <EditSubAgentModal
          onClose={() => {
            setShowEditModal(false);
            setsubAgentId(null);
          }}
          subAgentId={subAgentId}
        />
      )}
      {/* {showAddModal && (
        <EditSubAgentModal onClose={() => setShowAddModal(false)} />
      )} */}
    </div>
  );
};

export default SubAgents;
