import { VALAQUENTA_API_URL } from "../../..";
import { authorizedAxios, loggingAxios } from "../../../../App";

export function fetchWebSiteMainPromos({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/main-promo-for-entity/`,
    {
      params,
    }
  );
}

export function retrieveWebSiteMainPromos({ id }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/main-promo-for-entity/${id}`
  );
}

export function patchWebSiteMainPromos({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/canaveral/main-promo-for-entity/${id}/`,
    payload
  );
}

export function createWebSiteMainPromo({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/canaveral/main-promo-for-entity/`,
    payload
  );
}

export function deleteWebSiteMainPromos({ id }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/canaveral/main-promo-for-entity/${id}/`
  );
}
