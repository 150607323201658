import React from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  modalGenericStyles,
  variables,
  formInputGroup,
} from "../../../../../../variables";
import { useState } from "react";
import { toast } from "react-toastify";
import { genericRequestErrorHandler } from "../../../../../../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  patchFinancialData,
  retrieveFinancialData,
} from "../../../../../../api/CRM/guestCRM/guestCRM";
import PropTypes from "prop-types";
import { handleValidationErrors } from "../../../../../../helpers";
import _ from "lodash";
import { CustomButton } from "../../../../../../components/CustomButton";
import * as yup from "yup";
import Loader from "../../../../../../components/Loader";

const jsonSchema = yup.object().shape({});

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditCreditDebitModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: cardStyles.body,
  inputGroup: { ...formInputGroup, padding: `${variables.normal_gap} 0` },
  tagscardBody: {
    ...cardStyles.body,
    display: "grid",
    gridTemplateColumns: "1fr max-content",
  },
  tagsActions: {
    ...modalGenericStyles.cardActions,
    justifyContent: "start",
    height: "1.5rem",
    alignSelf: "end",
  },
  tags: {
    ...modalGenericStyles.card,
    display: "grid",
    gridColumn: "span 2",
    lineHeight: "5vh",
  },
});

const EditCreditDebitModal = ({ editingUid, type, guestData, onClose }) => {
  let mode = editingUid ? "edit" : "add";
  const classes = styles();

  const [form, setForm] = useState({
    description: "",
    currency: "",
    // expiration_datetime: "",
    tags: [""],
  });

  const queryClient = useQueryClient();

  const { data, isLoading: retrieveLoading } = useQuery({
    queryKey: ["RetrieveEditCreditDebitModal", editingUid],
    queryFn: async () => {
      return retrieveFinancialData({
        uid: editingUid,
      });
    },
    enabled: mode === "edit",
    onSuccess: (data) => {
      const objData = _.get(data, "data", {});
      setForm({
        ...data,
        tags: _.isEmpty(objData?.tags || []) ? [""] : objData.tags,
      });
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    refetchOnWindowFocus: false,
  });

  const editMutation = useMutation({
    mutationFn: async ({ uid, payload }) => {
      return patchFinancialData({ uid, payload });
    },
    onSuccess: () => {
      toast.success("payment method Updated");
      queryClient.invalidateQueries("financialData");
      onClose();
    },
  });

  const onSubmit = async ({ uid, guest, formData, type }) => {
    try {
      await jsonSchema.validate(formData, { abortEarly: false });
      let newTags = _.get(formData, "tags", []).filter((tag) => tag !== "");
      const payload = {
        source_entity: _.get(guest, "source_entity", ""),
        description: _.get(formData, "description", ""),
        currency: _.get(formData, "currency", ""),
        guest_crm_uid: guest.uuid,
        tags: [...new Set(newTags)],
      };

      editMutation.mutate({ uid, payload });
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const handleInputChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const loading = retrieveLoading || editMutation.isLoading;

  return (
    <div className={classes.EditCreditDebitModal}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>Edit Payment Entries</div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>Edit Payment Entries</div>
          <div className={classes.modalCardBody}>
            <form onChange={handleInputChange}>
              <div className={classes.card}>
                <div className={classes.cardHeader}></div>
                <div className={classes.cardBody}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="name">Description</label>
                    <input
                      type="text"
                      name="description"
                      value={form.description}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Information</div>
                <div className={classes.tagscardBody}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="tags">Tag</label>
                    <input
                      type="text"
                      value={_.get(form, "tags.0", "")}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          tags: [
                            ...form.tags.map((tag, idx) =>
                              idx === 0 ? e.target.value : tag
                            ),
                          ],
                        });
                      }}
                    />
                  </div>
                  <div className={classes.tagsActions}>
                    {(_.get(form, "tags", [""]).length !== 1 ||
                      _.get(form, "tags.0", "")) !== "" && (
                      <CustomButton
                        appearance="ghost"
                        onClick={(e) => {
                          e.preventDefault();
                          if (_.get(form, "tags", []).length > 1) {
                            let newTags = form.tags;
                            newTags.splice(0, 1);
                            setForm({ tags: [...newTags] });
                            return;
                          }
                          setForm({ ...form, tags: [""] });
                        }}>
                        <strong>Remove</strong>
                      </CustomButton>
                    )}

                    {_.get(form, "tags", [""]).length === 1 && (
                      <CustomButton
                        appearance="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({ tags: [...form.tags, ...[""]] });
                        }}>
                        <strong>Add Tag</strong>
                      </CustomButton>
                    )}
                  </div>
                </div>
              </div>
              {_.get(form, "tags").map(
                (tag, tagIdx) =>
                  tagIdx > 0 && (
                    <React.Fragment>
                      <div className={classes.card}>
                        <div className={classes.cardBody}>
                          <div className={classes.inputGroup}>
                            <label htmlFor="tags">Tag{tagIdx + 1}</label>
                            <input
                              type="text"
                              value={_.get(form, `tags.${tagIdx}`, "")}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  tags: [
                                    ...form.tags.map((tag, idx) =>
                                      idx === tagIdx ? e.target.value : tag
                                    ),
                                  ],
                                });
                              }}
                            />
                          </div>
                          <div className={classes.tagsActions}>
                            <CustomButton
                              appearance="ghost"
                              onClick={(e) => {
                                e.preventDefault();
                                if (_.get(form, "tags", []).length > 1) {
                                  let newTags = form.tags;
                                  newTags.splice(0, 1);
                                  setForm({ tags: [...newTags] });
                                  return;
                                }
                                setForm({ ...form, tags: [""] });
                              }}>
                              <strong>Remove</strong>
                            </CustomButton>
                            {_.get(form, "tags", []).length === tagIdx + 1 && (
                              <CustomButton
                                appearance="primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setForm({ tags: [...form.tags, ...[""]] });
                                }}>
                                <strong>Add Tag</strong>
                              </CustomButton>
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )
              )}
            </form>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Close</strong>
            </CustomButton>
            <CustomButton
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  uid: editingUid,
                  guest: guestData,
                  formData: form,
                  type,
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditCreditDebitModal.propTypes = {
  type: PropTypes.string.isRequired,
  guestData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditCreditDebitModal;
