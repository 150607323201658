import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Chart from "../Chart";
import {
  variables,
  inputGroup,
  GenericNavbarsStyles,
} from "../../../../../variables";
import TimeChart from "../TimeChart";
import _ from "lodash";
import BarChart from "../BarChart";
import { Button } from "../../../../../components/Button";

const initialFilters = {
  status: "",
  created__gte: "",
  created__lte: "",
};

const managingMultipleChartsStyles = createUseStyles({
  ManagingMultipleCharts: {
    display: "grid",
    gridGap: variables.double_gap,
  },
  buttons: {
    display: "grid",
    gridAutoFlow: "column",
    gap: variables.normal_gap,
    justifySelf: "center",
  },
  uncheckedButton: {
    padding: "6px 12px",
    borderRadius: "4px",
    backgroundColor: variables.colors.background.dark,
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
    textDecoration: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "white",
      color: variables.colors.background.darkHover,
    },
  },
  checkedButton: {
    padding: "6px 12px",
    borderRadius: "4px",
    backgroundColor: "white",
    color: variables.colors.background.darkHover,
    fontSize: "14px",
    fontWeight: "bold",
    textDecoration: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: variables.colors.background.dark,
      color: "#fff",
    },
  },
  divider: {
    height: "1px",
    width: "100%",
    background: variables.colors.border.dark,
    opacity: 0.2,
  },
  columnContainer: {
    display: "grid",
    gridTemplateColumns: "70% 30%",
    gridGap: variables.normal_gap,
    "& div": {
      padding: 0,
    },
    "& canvas": {
      alignSelf: "center",
      height: "100%!important",
      width: "100%!important",
    },
  },
  rowContainer: {
    display: "grid",
    gridTemplateRows: "repeat(2, 1fr)",
    gridGap: variables.double_gap,
  },
  filters: {
    ...GenericNavbarsStyles.navbars.mobile(),
    display: "grid",
    gridTemplateColumns: " max-content max-content",
    justifyContent: "center",
    paddingTop: "0rem",
    padding: "0rem",
  },
  leftSection: {
    ...GenericNavbarsStyles.filters.mobile(),
  },
  rightSection: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    paddingTop: "1.5rem",
    alignSelf: "center",
  },
  inputGroup: {
    ...inputGroup,
    width: "100%",
    "& input, select": {
      ...inputGroup["& input, select"],
      width: "100%",
    },
  },
  actions: { ...GenericNavbarsStyles.actions.mobile() },
});
const ManagingMultipleCharts = () => {
  const classes = managingMultipleChartsStyles();

  const [onChangeFilters, setOnChangeFilters] = useState(initialFilters);
  const [temporaryFilters, setTemporaryFilters] = useState(initialFilters);
  const [selectedCurrency, setSelectedCurrency] = useState("EUR");

  const currencies = ["EUR", "USD", "GBP", "CAD", "AUD"];
  const chartConfigs = [
    {
      serviceType: "ACC",
      chartType: "res_per_day",
      color: "blue",
    },
    {
      serviceType: "ACC",
      chartType: "avg_price_per_day",
      color: "red",
    },
    {
      serviceType: "FL",
      chartType: "res_per_day",
      color: "green",
    },
    {
      serviceType: "FL",
      chartType: "avg_price_per_day",
      color: "orange",
    },
    {
      serviceType: "TRF",
      chartType: "res_per_day",
      color: "purple",
    },
    {
      serviceType: "TRF",
      chartType: "avg_price_per_day",
    },
    {
      serviceType: "TRA",
      chartType: "res_per_day",
      color: "yellow",
    },
    {
      serviceType: "TRA",
      chartType: "avg_price_per_day",
      color: "brown",
    },
  ];
  const barChartConfigs = [
    {
      serviceType: "ACC",
      chartType: "entities_per_month",
      color: "maroon",
    },
    {
      serviceType: "FL",
      chartType: "entities_per_month",
      color: "teal",
    },
    {
      serviceType: "TRF",
      chartType: "entities_per_month",
      color: "lime",
    },
    {
      serviceType: "TRA",
      chartType: "entities_per_month",
      color: "olive",
    },
  ];
  const pieChartConfigs = [
    {
      serviceType: "ACC",
      chartType: "cxled_confirmed_percs",
      dataSource: ["cancelled", "confirmed"],
    },
    {
      serviceType: "ACC",
      chartType: "fail_success_percs",
      dataSource: ["fail", "success"],
    },

    {
      serviceType: "FL",
      chartType: "cxled_confirmed_percs",
      dataSource: ["cancelled", "confirmed"],
    },
    {
      serviceType: "FL",
      chartType: "fail_success_percs",
      dataSource: ["fail", "success"],
    },
    {
      serviceType: "TRF",
      chartType: "cxled_confirmed_percs",
      dataSource: ["cancelled", "confirmed"],
    },
    {
      serviceType: "TRF",
      chartType: "fail_success_percs",
      dataSource: ["fail", "success"],
    },
    {
      serviceType: "TRA",
      chartType: "cxled_confirmed_percs",
      dataSource: ["cancelled", "confirmed"],
    },
    {
      serviceType: "TRA",
      chartType: "fail_success_percs",
      dataSource: ["fail", "success"],
    },
  ];
  const chartsByServiceType = _.groupBy(chartConfigs, "serviceType");

  const applyFilters = () => {
    setOnChangeFilters(temporaryFilters);
  };

  return (
    <div className={classes.ManagingMultipleCharts}>
      <div className={classes.filters}>
        <div className={classes.leftSection}>
          <div className={classes.inputGroup}>
            <label>Created After</label>
            <input
              type="date"
              value={temporaryFilters.created__gte}
              onChange={(e) =>
                setTemporaryFilters((p) => ({
                  ...p,
                  created__gte: e.target.value,
                }))
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  applyFilters();
                }
              }}
            />
          </div>
          <div className={classes.inputGroup}>
            <label>Created Before</label>
            <input
              type="date"
              value={temporaryFilters.created__lte}
              onChange={(e) =>
                setTemporaryFilters((p) => ({
                  ...p,
                  created__lte: e.target.value,
                }))
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  applyFilters();
                }
              }}
            />
          </div>
        </div>
        <div className={classes.rightSection}>
          <div className={classes.actions}>
            <Button
              text="Reset Filters"
              onClick={() => {
                setTemporaryFilters({
                  ...initialFilters,
                });
              }}
            />
          </div>
          <div className={classes.actions}>
            <Button text="Apply Filters" onClick={applyFilters} />
          </div>
        </div>
      </div>
      <div className={classes.buttons}>
        {currencies.map((currency) => (
          <button
            key={currency}
            className={
              selectedCurrency === currency
                ? `${classes.checkedButton} `
                : `${classes.uncheckedButton} `
            }
            onClick={() => {
              setSelectedCurrency(currency);
            }}>
            {currency}
          </button>
        ))}
      </div>
      {Object.entries(chartsByServiceType).map(([serviceType, charts]) => (
        <div className={classes.columnContainer} key={serviceType}>
          <div className={classes.rowContainer}>
            {charts.map((chart, chartIdx) => (
              <TimeChart
                key={chartIdx}
                serviceType={serviceType}
                chartType={chart.chartType}
                currency={selectedCurrency}
                color={chart.color}
                localFilters={onChangeFilters}
              />
            ))}
          </div>
          <div className={classes.rowContainer}>
            {pieChartConfigs
              .filter((config) => config.serviceType === serviceType)
              .map((pieConfig, pieIdx) => (
                <Chart
                  key={pieIdx}
                  serviceType={pieConfig.serviceType}
                  chartType={pieConfig.chartType}
                  currency={selectedCurrency}
                  dataSource={pieConfig.dataSource}
                  localFilters={onChangeFilters}
                />
              ))}
          </div>
          <div className={classes.rowContainer}>
            {barChartConfigs
              .filter((config) => config.serviceType === serviceType)
              .map((barConfig, barIdx) => (
                <BarChart
                  key={barIdx}
                  serviceType={barConfig.serviceType}
                  chartType={barConfig.chartType}
                  currency={selectedCurrency}
                  color={barConfig.color}
                  localFilters={onChangeFilters}
                />
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};
export default ManagingMultipleCharts;
