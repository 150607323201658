import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  formSections,
  inputGroup,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import PropTypes from "prop-types";
import {
  createNewActivity,
  patchActivity,
  retrieveActAPIConfiguration,
} from "../../../../api/ActivitiesEngine/APIConfigurations";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import _ from "lodash";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";
import EntityAutocomplete from "../../../../components/EntityAutocomplete";
import {
  APIConfigsStatusMapping,
  convertStringToBoolean,
  currencies,
  handleValidationErrors,
} from "../../../../helpers";
import { activitiesTrueFalseMapping } from "../../common";
import { CustomButton } from "../../../../components/CustomButton";

const enviromentOptions = {
  "": "-----",
  STAGING: "Staging",
  PRODUCTION: "Production",
};

const marketTypeOptions = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, " Must be at least 2 characters.")
    .max(100, " Must be less than 100 characters.")
    .nullable()
    .default(""),
  source_entity: yup
    .string()
    .required("This field may not be blank.")
    .default(""),
  market_type: yup
    .string()
    .nullable()
    .oneOf(["B2B", "B2C"], "Must be one of the following values: B2B, B2C")
    .default(""),
  currency: yup.string().nullable().default(""),
  environment: yup
    .string()
    .nullable()
    .oneOf(
      ["STAGING", "PRODUCTION"],
      "Must be one of the following values: STAGING, PRODUCTION"
    )
    .default(""),
  is_own: yup.boolean().nullable().default(false),
  is_global: yup.boolean().nullable().default(false),
  is_master_account: yup.boolean().nullable().default(false),
  website_api_key: yup.string().nullable().default(""),
  base_url: yup
    .string()
    .url("Invalid URL format")
    .required("This field may not be blank")
    .default(""),
  client_id: yup.string().required("This field may not be blank.").default(""),
  client_secret: yup
    .string()
    .required("This field may not be blank.")
    .default(""),
  grand_type: yup.string().nullable().default(""),
  x_musement_application: yup.string().nullable().default(""),
});

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditActivitiesAPIConfigurationModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: cardStyles.body,
  inputGroup: { ...formInputGroup, padding: `${variables.normal_gap} 0` },
  entityAutoComplete: {
    ...inputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  sectionHeader: formSections.sectionHeader,
  cardActions: { ...cardStyles.actions, gridColumn: "2 span", width: "97%" },
  [`@media ${variables.media.retina}`]: {
    modalCardBody: {
      minWidth: "50rem",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCardBody: {
      minWidth: "50rem",
    },
    cardCol2: {
      gridTemplateColumns: "1fr 1fr",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
});
const EditActivitiesAPIConfigurationModal = ({
  apiConfigurationUid,
  onClose,
}) => {
  const classes = styles();
  const [form, setForm] = useState(jsonSchema.cast({}));
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const mode = apiConfigurationUid ? "edit" : "add";
  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["EditActivitiesAPIConfigurationModal"],
    queryFn: () => {
      return retrieveActAPIConfiguration({
        uid: apiConfigurationUid,
      });
    },
    enabled: mode === "edit",
    onSuccess: (data) => {
      setForm(jsonSchema.cast(_.get(data, "data", {})));
      setAutocompleteValue(_.get(data, "data.source_entity"));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const addMutation = useMutation({
    mutationFn: async ({ payload }) => {
      return createNewActivity({ payload });
    },
    onSuccess: () => {
      toast.success("New Activity added");
      queryClient.invalidateQueries("ActivitiesAPIConfigurations");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });
  const editMutation = useMutation({
    mutationFn: async ({ uid, payload }) => {
      return patchActivity({ uid, payload });
    },
    onSuccess: () => {
      toast.success("Activity Info Updated");
      queryClient.invalidateQueries("ActivitiesAPIConfigurations");
      onClose();
    },
  });

  const onSubmit = async ({ uid, payload }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload });
      } else {
        editMutation.mutate({ uid, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };
  const handleInputChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };
  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  const boolFields = convertStringToBoolean({
    is_global: form.is_global,
    is_own: form.is_own,
  });

  return (
    <div
      id="addEditACTEngineAPIConfigurationModal"
      className={classes.EditActivitiesAPIConfigurationModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>{_.startCase(mode)} Activities API Configuration</span>
        </div>
        {loading ? (
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            <div className={classes.modalCardBody}>
              <form>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Basic Data</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol2}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="name">Name</label>
                        <input
                          id="name"
                          type="text"
                          name="name"
                          value={_.capitalize(form.name)}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="source_entity">Source Entity</label>
                        <div className={classes.entityAutoComplete}>
                          <EntityAutocomplete
                            id="entityAutocompleteInputId"
                            subagents={false}
                            independentAgents={false}
                            branches={false}
                            value={autocompleteValue}
                            setValue={setAutocompleteValue}
                            onChange={(text) => {
                              if (text.length === 0) {
                                setForm((p) => ({
                                  ...p,
                                  source_entity: "",
                                }));
                                return;
                              }
                              const entityInfos = text.split("---");
                              const entityType = entityInfos[0];
                              const entityId = entityInfos[1];
                              setForm((p) => ({
                                ...p,
                                source_entity: `${entityId}___${entityType}`,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={classes.cardCol3}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="market_type">Market Type</label>
                        <select
                          id="market_type"
                          name="market_type"
                          value={form.market_type}
                          onChange={handleInputChange}>
                          {Object.entries(marketTypeOptions).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className={classes.inputGroup}>
                        <label htmlFor="currency">Currency</label>
                        <select
                          id="currency"
                          name="currency"
                          value={form.currency}
                          onChange={handleInputChange}>
                          {Object.entries(currencies).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="environment">Environment</label>
                        <select
                          id="environment"
                          name="environment"
                          value={form.environment}
                          onChange={handleInputChange}>
                          {Object.entries(enviromentOptions).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>

                    <div className={classes.cardCol2}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="is_global">Is Global</label>
                        <select
                          id="is_global"
                          name="is_global"
                          value={form.is_global}
                          onChange={handleInputChange}>
                          {Object.entries(activitiesTrueFalseMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="is_own">Is Own</label>
                        <select
                          id="is_own"
                          name="is_own"
                          value={form.is_own}
                          onChange={handleInputChange}>
                          {Object.entries(activitiesTrueFalseMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="is_master_account">
                          Is Master Account
                        </label>
                        <select
                          id="is_master_account"
                          name="is_master_account"
                          value={form.is_master_account}
                          onChange={handleInputChange}>
                          {Object.entries(activitiesTrueFalseMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label>Website Api Key</label>
                        <input
                          id="website_api_key"
                          type="text"
                          name="website_api_key"
                          value={form.website_api_key}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="status">Status</label>
                        <select
                          id="status"
                          name="status"
                          value={form.status}
                          onChange={handleInputChange}>
                          {Object.entries(APIConfigsStatusMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="base_url">Base Url</label>
                        <input
                          id="base_url"
                          type="text"
                          name="base_url"
                          value={form.base_url}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>API Credentials</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol2}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="client_id">Client Id</label>
                        <input
                          id="client_id"
                          type="text"
                          name="client_id"
                          value={form.client_id}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="client_secret">Client Secret</label>
                        <input
                          id="client_secret"
                          type="text"
                          name="client_secret"
                          value={form.client_secret}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="grand_type">Grand Type</label>
                        <input
                          id="grand_type"
                          type="text"
                          name="grand_type"
                          value={form.grand_type}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label>X Musement Application</label>
                        <input
                          id="x_musement_application"
                          type="text"
                          name="x_musement_application"
                          value={form.x_musement_application}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className={classes.modalCardActions}>
              <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
                <strong>Cancel</strong>
              </CustomButton>
              <CustomButton
                id="submit"
                appearance="primary"
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit({
                    uid: apiConfigurationUid,
                    payload: { ...form, ...boolFields },
                  });
                }}>
                <strong>Submit</strong>
              </CustomButton>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
EditActivitiesAPIConfigurationModal.propTypes = {
  apiConfigurationUid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditActivitiesAPIConfigurationModal;
