import React from "react";
import Logs from "../../../../../../components/Logs";
import { VALAQUENTA_API_URL } from "../../../../../../api";

const VendorACTConfigLogsTab = (WrappedComponent) => {
  const Comp = (props) => {
    const actionsMapping = {
      add: {
        label: "Add",
        url: `${VALAQUENTA_API_URL}/crm/vendors/`,
        method: "POST",
      },
      edit: {
        label: "Edit",
        url: `${VALAQUENTA_API_URL}/crm/vendors/`,
        method: "PATCH",
      },
      delete: {
        label: "Delete",
        url: `${VALAQUENTA_API_URL}/crm/vendors/`,
        method: "DELETE",
      },
      addPaymentTerms: {
        label: "Add Payment Terms",
        url: `${VALAQUENTA_API_URL}/crm/subentity-payment-terms/`,
        method: "POST",
      },
      editaPymentTerms: {
        label: "Edit Payment Terms",
        url: `${VALAQUENTA_API_URL}/crm/subentity-payment-terms/`,
        method: "PATCH",
      },
      DeletePaymentTerms: {
        label: "Delete Payment Terms",
        url: `${VALAQUENTA_API_URL}/crm/subentity-payment-terms/`,
        method: "delete",
      },
    };

    return <WrappedComponent {...props} actionsMapping={actionsMapping} />;
  };
  return Comp;
};

export default VendorACTConfigLogsTab(Logs);
