import React from "react";
import { createUseStyles } from "react-jss";
import {
  btnStyles,
  cardStyles,
  tableStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { Duration } from "luxon";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import { fullDater } from "../../../../../helpers";
import _ from "lodash";

export function humanizeDuration(milliseconds) {
  if (isNaN(milliseconds)) {
    return "0 mins";
  }

  var dur = Duration.fromObject({ milliseconds });
  var minutes = dur.as("minutes");
  const hours = parseInt(minutes / 60, 10);
  minutes = parseInt(minutes, 10) % 60;
  const durObj = {};
  if (hours > 0) {
    durObj["hours"] = hours;
  }
  if (minutes > 0) {
    durObj["minutes"] = minutes;
  }

  if (_.isEmpty(durObj)) {
    return "0 mins";
  }

  dur = Duration.fromObject(durObj);
  const duration = dur.toHuman({ unitDisplay: "short" });
  return duration;
}

const segmentsStyles = createUseStyles({
  Segments: { ...cardStyles.card, overflow: "hidden" },
  header: cardStyles.header,
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: { ...tableStyles.table, maxWidth: "100%", overflow: "hidden" },
  thead: tableStyles.head,
  tbody: tableStyles.body,
  trow: tableStyles.body.row,
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  btn: btnStyles.btn,
});
const Segments = ({ segments }) => {
  const classes = segmentsStyles();

  return (
    <div className={classes.Segments}>
      <div className={classes.header}>
        <span>Segments</span>
      </div>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              <th className={classes.headCell}>Departure</th>
              <th className={classes.headCell}>Arrival</th>
              <th className={classes.headCell}>Departure Arrival</th>
              <th className={classes.headCell}>Duration Time</th>
              <th className={classes.headCell}>Carrier</th>
              <th className={classes.headCell}>Cabin Class</th>
              <th className={classes.headCell}>Flight Number</th>
              <th className={classes.headCell}>Fare Base</th>
            </tr>
          </thead>
          <tbody className={classes.tbody}>
            {segments.map((seg, idx) => (
              <tr key={idx} className={classes.trow}>
                <td className={classes.tableCell}>{idx + 1}</td>
                <td className={classes.tableCell}>
                  {_.get(seg, "departure")
                    ? fullDater(_.get(seg, "departure"))
                    : "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.get(seg, "arrival")
                    ? fullDater(_.get(seg, "arrival"))
                    : "N/A"}
                </td>
                {/* <td className={classes.tableCell}>{`${_.get(
                  seg,
                  "origin_airport.iata"
                )} - ${_.get(seg, "destination_airport.iata")}`}</td> */}
                <td className={classes.tableCell}>
                  {humanizeDuration(_.get(seg, "duration"))}
                </td>
                <td className={classes.tableCell}>
                  {_.get(seg, "carrier_code") || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.get(seg, "cabin_class") || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.get(seg, "flight_number") || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.get(seg, "fare_base") || "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
Segments.defaultProps = {
  segments: [],
};
Segments.propTypes = {
  segments: PropTypes.array.isRequired,
};

const legStyles = createUseStyles({
  ...cardStyles,
  Leg: {
    width: "100%",
    display: "grid",
    gridGap: variables.normal_gap,
  },
  card: { ...cardStyles.card, maxWidth: "100%" },
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
});

const Leg = ({ transportation, type, legIdx }) => {
  const classes = legStyles();

  const legItineraryData = [
    [
      "Departure Airport",
      null,
      `${
        _.get(transportation, `prebook_data.flight.legs[${legIdx}]`, []).map(
          (item) => item.segments[0].origin_airport.iata
        ) || "N/A"
      },${
        _.get(transportation, `prebook_data.flight.legs[${legIdx}]`, []).map(
          (item) => item.segments[0].origin_airport.name
        ) || "N/A"
      }`,
    ],
    [
      "Arrival Airport",
      null,
      `${
        _.get(transportation, `prebook_data.flight.legs[${legIdx}]`, []).map(
          (item) =>
            item.segments[item.segments.length - 1].destination_airport.iata
        ) || "N/A"
      }
      ,
      ${
        _.get(transportation, `prebook_data.flight.legs[${legIdx}]`, []).map(
          (item) =>
            item.segments[item.segments.length - 1].destination_airport.name
        ) || "N/A"
      }
      `,
    ],
    [
      "Duration",
      null,
      humanizeDuration(
        _.get(transportation, `prebook_data.flight.legs[${legIdx}]`, []).map(
          (item) => item.duration
        ) || "N/A"
      ),
    ],
  ];

  let transferTime = 0;

  _.get(
    transportation,
    `prebook_data.flight.legs.${legIdx}.0.segments`,
    []
  ).forEach((item) => {
    if (item.duration) {
      transferTime += item.duration;
    }
  });

  const transferData = [
    [
      "Transfer Airport",
      null,
      _.get(transportation, `prebook_data.flight.legs[${legIdx}]`, [])
        .map((leg) =>
          _.get(leg, "segments", []).map((item) => item.origin_airport.iata)
        )
        .join("-") || "N/A",
    ],
    ["Transfer Time", null, humanizeDuration(transferTime)],
  ];

  const baggageAllowanceData = [
    ["Baggage Pieces", null, "N/A"],
    ["Total Weight", null, "N/A"],
    ["Extra Baggage", null, "N/A"],
  ];

  return (
    <div className={classes.Leg}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <strong>{`Leg ${legIdx + 1}${type ? ` (${type})` : ""}`}</strong>
        </div>
        <div className={classes.cardBody}>
          <div className={classes.cardCol3}>
            <GenericBasicInfo
              header={[
                "Leg Itinerary ",
                null,
                `${
                  _.get(
                    transportation,
                    `prebook_data.flight.legs[${legIdx}]`,
                    []
                  ).map((item) => item.segments[0].origin_airport.iata) || "N/A"
                },
                    
                    ${
                      _.get(
                        transportation,
                        `prebook_data.flight.legs[${legIdx}]`,
                        []
                      ).map(
                        (item) =>
                          item.segments[item.segments.length - 1]
                            .destination_airport.iata
                      ) || "N/A"
                    }`,
              ]}
              cardInfo={legItineraryData}
              objectData={transportation}
            />
            <GenericBasicInfo
              header="Transfer Information"
              cardInfo={transferData}
              objectData={transportation}
            />
            <GenericBasicInfo
              header="Baggage Allowance"
              cardInfo={baggageAllowanceData}
              objectData={transportation}
            />
          </div>
        </div>
      </div>
      <Segments
        segments={_.get(
          transportation,
          `prebook_data.flight.legs.${legIdx}.0.segments`,
          []
        )}
      />
    </div>
  );
};
Leg.propTypes = {
  legIdx: PropTypes.number.isRequired,
  type: PropTypes.string,
  transportation: PropTypes.object.isRequired,
};
export default Leg;
