import React, { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

const styles = createUseStyles({
  JSEditor: {
    gridColumn: "span 2",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
});
export const JSEditor = ({ onChange, value }) => {
  const classes = styles({});
  const containerRef = useRef(null);

  const options = {
    mode: "text",
    // onChangeText: (changedValue) => {
    //   try {
    //     JSON.parse(changedValue);
    //   } catch (e) {
    //     return;
    //   }
    //   onChange(JSON.parse(changedValue));
    // },
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.innerHTML = "";
      const editor = new JSONEditor(containerRef.current, options);
      editor.set(value);

      // onBlur event to trigger the update when the user clicks away
      editor.textarea.addEventListener("blur", () => {
        try {
          const changedValue = editor.get();
          onChange(changedValue);
        } catch (e) {
          toast.error("Invalid text format");
        }
      });
    }
  }, [value, onChange]);

  return (
    <div className={classes.JSEditor}>
      <pre>{JSON.stringify(value, null, 2)}</pre>
      <div ref={containerRef} />
    </div>
  );
};
JSEditor.defaultProps = {
  value: {},
};
JSEditor.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
