import _, { set } from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import {
  cloneFlight,
  fetchFlightsAPIConfigurations,
} from "../../../../api/FlightEngine/FlightAPIConfigurations";
import ControlStrip from "../../../../components/ControlStrip";
import Loader from "../../../../components/Loader";
import {
  countries,
  currencies,
  handleApplyFilters,
  medWeekdayDatetimer,
} from "../../../../helpers";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import DetailFlightAPIConfigurationsModal from "../FlightsModals/DetailFlightAPIConfigurationsModal";
import EditFlightAPIConfigurationModal from "../FlightsModals/EditFlightAPIConfigurationsModal";
import Navbar from "./Navbar";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import DeleteFlightAPIConfigurationsModal from "../FlightsModals/DeleteFlightAPIConfigurationsModal";
import { DateTime } from "luxon";
import { genericRequestErrorHandler } from "../../../../api";
import { CustomButton } from "../../../../components/CustomButton";
import VerifyModal from "../../../../components/VerifyModal";
import ReservationsStatusTag from "../../../../components/Tags/ReservationsStatusTag";

const initialFilters = {
  page_size: 20,
  page: 1,
  ordering: "",
  discending: "",
  user__icontains: "",
  name__icontains: "",
  country_code: "",
  force_source_type: "",
  created__lte: "",
  created__gte: "",
  source_entity: "",
};
const navbarSchema = yup.object().shape({
  name__icontains: yup
    .string()
    .min(2, "Name must be more than 1 characters")
    .max(100, "Name must be less than 100 characters"),
  created__gte: yup.date().max(new Date(), "Date cannot be in the future"),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const sourceTypeOptions = {
  "": "-----",
  0: "Default",
  1: "ForceGDS",
  2: "ForceIBE",
};

const APIConfigurationStyles = createUseStyles({
  APIConfiguration: genericIndexesStyles.indexes.mobile(),
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile(),
  checkBox: tableStyles.checkBox,
  dateCell: { ...tableStyles.body.cell, width: "9rem", minWidth: "9rem" },

  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      gridTemplateColumns: "repeat(2,1fr) ",
      padding: `calc(${variables.normal_gap} * 2.5) ${variables.normal_gap}`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const FlightsAPIConfigurations = () => {
  const classes = APIConfigurationStyles();

  const queryClient = useQueryClient();
  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [apiConfigurationId, setApiConfigurationId] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [flight, setflight] = useState({});

  const tableHeaders = {
    User: (val) => _.capitalize(val.user),
    Name: (val) => _.capitalize(val.name),
    "Source Entity": (val) => val.source_entity,
    "Source Type": (val) =>
      _.get(sourceTypeOptions, _.get(val, "force_source_type")),
    Country: (flight) => _.get(countries, _.get(flight, "country_code")),
    Currency: (flight) => _.get(currencies, _.get(flight, "currency")),
    "MCRE Branch": (val) => val.mcre_branch,
    PCC: (val) => val.pcc,
    Queue: (val) => val.queue,
    Searches: (val) => val.searches,
    "Max Searches": (val) =>
      _.get(val, "max_searches") === 0 ? "0" : val.max_searches,
    Bookings: (val) => (val.bookings === 0 ? "0" : val.bookings),
    "Max Bookings": (val) =>
      _.get(val, "max_bookings") === 0 ? "0" : val.max_bookings,
    "Received From": (val) => _.capitalize(val.received_from),
    Created: (val) => val.created,
    Status: (val) => <ReservationsStatusTag status={val.status} />,
  };
  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["FlightsAPIConfigurations", filters],
    queryFn: () => {
      let correctPayload = { ...filters };
      if (filters.created__lte) {
        correctPayload["created__lte"] = DateTime.fromISO(
          filters.created__lte
        ).toISO();
      }
      if (filters.created__gte) {
        correctPayload["created__gte"] = DateTime.fromISO(
          filters.created__gte
        ).toISO();
      }
      return fetchFlightsAPIConfigurations({
        params: correctPayload,
      });
    },
    enabled: !showDeleteModal && !showEditModal && !showDetailModal,
    onError: (error) => genericRequestErrorHandler(error),
  });

  const cloneHitchickerMutation = useMutation({
    mutationFn: (uid) => {
      return cloneFlight({ uid });
    },
    onSuccess: () => {
      toast.success("Cloned Hitchhaker added");
      queryClient.invalidateQueries("FlightsAPIConfigurations");
      setShowVerifyModal(false);
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });
  const onClone = (uid) => {
    setApiConfigurationId(uid);
  };

  return (
    <div
      id="HitchhikerFlAPIConfigurationsListPage"
      className={classes.APIConfiguration}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setShowEditModal(true);
          setApiConfigurationId(null);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.page_size}
        page={filters.page}
        count={_.get(data, "data.count", 0)}
        onChangeLimit={(page_size) =>
          setFilters({ ...filters, page_size, page: 1 })
        }
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th key={idx} className={classes.headCell}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.get(data, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "created":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      return (
                        <td className={classes.dateCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    default:
                      cell = fn(val) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.tableCell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setApiConfigurationId(val.uid);
                      setShowDetailModal(true);
                    }}>
                    <strong>Details</strong>
                  </CustomButton>
                  <CustomButton
                    id={`EditHitchhikerFlButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setApiConfigurationId(val.uid);
                      setShowEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      onClone(val.uid);
                      setShowVerifyModal(true);
                    }}>
                    <strong>Clone</strong>
                  </CustomButton>
                  <CustomButton
                    id={`DeleteHitchhikerFlButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setflight(val);
                      setApiConfigurationId(val.uid);
                      setShowDeleteModal(true);
                    }}>
                    <strong>Delete</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDetailModal && (
        <DetailFlightAPIConfigurationsModal
          uid={apiConfigurationId}
          onClose={() => {
            setShowDetailModal(false);
          }}
        />
      )}
      {showEditModal && (
        <EditFlightAPIConfigurationModal
          apiConfigurationId={apiConfigurationId}
          onClose={() => {
            setShowEditModal(false);
            setApiConfigurationId(null);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteFlightAPIConfigurationsModal
          data={flight}
          deletedUid={apiConfigurationId}
          onClose={() => {
            setShowDeleteModal(false);
          }}
        />
      )}
      {showVerifyModal && (
        <VerifyModal
          // title=""
          // message=""
          isLoading={cloneHitchickerMutation.isLoading}
          onConfirm={() => {
            cloneHitchickerMutation.mutate(apiConfigurationId);
          }}
          onClose={() => setShowVerifyModal(false)}
        />
      )}
    </div>
  );
};
export default FlightsAPIConfigurations;
