import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchAdhocReservations({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/adhoc/`,
    {
      params,
    }
  );
}

export async function retrieveAdhocReservation({ reference }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/adhoc/${reference}/`
  );
}
export async function patchAddhocReservation({ reference, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/adhoc/${reference}/`,
    payload
  );
}
export function getAdhocReservationStats({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/adhoc/stats/`,
    { params }
  );
}
