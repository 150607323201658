import React from "react";
import Logs from "../../../../../components/Logs";
import { VALAQUENTA_API_URL } from "../../../../../api";

const UnififiLogsTab = (WrappedComponent) => {
  const Comp = (props) => {
    const actionsMapping = {
      add: {
        label: "Add",
        url: `${VALAQUENTA_API_URL}flightengine/api-config/unififi/`,
        method: "POST",
      },
      edit: {
        label: "Edit",
        url: `${VALAQUENTA_API_URL}flightengine/api-config/unififi/`,
        method: "PATCH",
      },
      delete: {
        label: "Delete",
        url: `${VALAQUENTA_API_URL}flightengine/api-config/unififi/`,
        method: "delete",
      },
      clone: {
        label: "Clone",
        url: `${VALAQUENTA_API_URL}flightengine/api-config/unififi/clone/`,
        method: "POST",
      },
    };

    return <WrappedComponent {...props} actionsMapping={actionsMapping} />;
  };
  return Comp;
};

export default UnififiLogsTab(Logs);
