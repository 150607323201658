import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  formSections,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import { statusMapping } from "../../common";
import Loader from "../../../../../components/Loader";
import {
  createWebSiteGenericSection,
  patchWebSiteGenericSections,
  retrieveWebSiteGenericSections,
} from "../../../../../api/DistributionSite/webSites/GenericSections";
import _ from "lodash";
import { DateTime } from "luxon";
import { genericRequestErrorHandler } from "../../../../../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import {
  handleValidationErrors,
  websiteLanguages,
} from "../../../../../helpers";
import QuilEditor from "../../../../../components/QuilEditor";
import WebsitesLanguages from "../../Components/WebsitesLanguages";
import TranslateButton from "../../Components/TranslateButton";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .transform((value) => (value === "" ? undefined : value))
    // .min(2, "Must be at least 2 characters")
    .max(100, "Must be less than 100 characters")
    .nullable()
    .default(""),

  header: yup.string().nullable().default(""),
  order: yup.number().nullable().default(0),
  product_category: yup
    .string()
    // .oneOf(
    //   [
    //     "ACC",
    //     "FL",
    //     "FER",
    //     "TRA",
    //     "TR",
    //     "TRF",
    //     "MI",
    //     "REST",
    //     "COO",
    //     "GEN",
    //     "AD",
    //     "ACT",
    //     "CAR",
    //   ],
    //   "Must be between Accommodation, Flights, Ferries, Trains, Transportation, Transfers, Add Ons, Restaurants, Coordinators, General Services, Ad hoc Land Services, Activity or Car Rental"
    // ),
    .default(""),
  path: yup.string().nullable().default(""),
  theme: yup.string().nullable().default(""),
  active_from: yup.date().nullable().default(null),
  active_to: yup.date().nullable().default(null),
  status: yup
    .string()
    .oneOf(["AC", "IN", "CA"], "Must be between Active, Inactive or Candidate")
    .default("AC"),
  page_type: yup
    .string()
    .oneOf(
      ["product", "subagent_registration", "icon_text_showcase"],
      "Must be between Product, Subagent Registration or Icon Text Showcase"
    ),
  // .isRequired("Must not be blanc")
  icon_texts: yup
    .array()
    .of(
      yup
        .array()
        .of(yup.string())
        .test("is-valid-urls", "Invalid URL in icon text", (value) => {
          // Check if any element in the array is not a valid URL
          return !value[2] || yup.string().url().isValidSync(value[2]);
        })
    )
    .default([["", "", ""]]),
  description: yup.string().nullable().default(""),
  description_de: yup.string().nullable().default(""),
  description_el: yup.string().nullable().default(""),
  description_es: yup.string().nullable().default(""),
  description_fr: yup.string().nullable().default(""),
  description_it: yup.string().nullable().default(""),
  description_ja: yup.string().nullable().default(""),
  description_nl: yup.string().nullable().default(""),
  description_pt: yup.string().nullable().default(""),
  description_th: yup.string().nullable().default(""),
  description_zh: yup.string().nullable().default(""),
  description_lt: yup.string().nullable().default(""),
  title: yup.string().nullable().default(""),
  title_de: yup.string().nullable().default(""),
  title_el: yup.string().nullable().default(""),
  title_es: yup.string().nullable().default(""),
  title_fr: yup.string().nullable().default(""),
  title_it: yup.string().nullable().default(""),
  title_ja: yup.string().nullable().default(""),
  title_nl: yup.string().nullable().default(""),
  title_pt: yup.string().nullable().default(""),
  title_th: yup.string().nullable().default(""),
  title_zh: yup.string().nullable().default(""),
  title_lt: yup.string().nullable().default(""),
  subtitle: yup.string().nullable().default(""),
  subtitle_de: yup.string().nullable().default(""),
  subtitle_el: yup.string().nullable().default(""),
  subtitle_es: yup.string().nullable().default(""),
  subtitle_fr: yup.string().nullable().default(""),
  subtitle_it: yup.string().nullable().default(""),
  subtitle_ja: yup.string().nullable().default(""),
  subtitle_nl: yup.string().nullable().default(""),
  subtitle_pt: yup.string().nullable().default(""),
  subtitle_th: yup.string().nullable().default(""),
  subtitle_zh: yup.string().nullable().default(""),
  subtitle_lt: yup.string().nullable().default(""),
});

const initialForm = {
  name: "",
  status: "",
  description: "",
  description_de: "",
  description_el: "",
  description_es: "",
  description_fr: "",
  description_it: "",
  description_ja: "",
  description_nl: "",
  description_pt: "",
  description_th: "",
  description_zh: "",
  description_lt: "",
  title: "",
  title_de: "",
  title_el: "",
  title_es: "",
  title_fr: "",
  title_it: "",
  title_ja: "",
  title_nl: "",
  title_pt: "",
  title_th: "",
  title_zh: "",
  title_lt: "",
  subtitle: "",
  subtitle_de: "",
  subtitle_el: "",
  subtitle_es: "",
  subtitle_fr: "",
  subtitle_it: "",
  subtitle_ja: "",
  subtitle_nl: "",
  subtitle_pt: "",
  subtitle_th: "",
  subtitle_zh: "",
  subtitle_lt: "",
  code: "",
  active_from: null,
  active_to: null,
  path: "",
  theme: "",
  header: "",
  product_category: "",
  order: "",
  icon_texts: [["", "", ""]],
  page_type: "",
};

const pageTypeMapping = {
  "": "-----",
  product: "Product",
  subagent_registration: "Subagent Registration",
  icon_text_showcase: "Icon Text Showcase",
};

const productCategoryMapping = {
  "": "-----",
  ACC: "Accommodation",
  FL: "Flights",
  FER: "Ferries",
  TRA: "Trains",
  TR: "Transportation",
  TRF: "Transfers",
  MI: "Add Ons",
  REST: "Restaurants",
  COO: "Coordinators",
  GEN: "General Services",
  AD: "Ad hoc Land Services",
  ACT: "Activitiy",
  CAR: "Car Rental",
};
const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditGenericSections: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    // display: "grid",
    gridTemplateRows: "max-content 1fr max-content",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  languages: {
    display: "grid",
    padding: variables.double_gap,
    overflow: "auto",
  },
  descriptionCardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    display: "grid",
    padding: "",
    paddingBottom: variables.normal_gap,
  },
  description: {
    // display: "grid",
    gap: variables.normal_gap,
    "& label": {
      fontSize: "small",
    },
  },
  languagesButton: {
    padding: variables.normal_gap,
    gap: variables.normal_gap,
    borderRadius: "5px",
    cursor: "pointer",
    fontWeight: "bold",
    transition: "background .s, color .3s",
    "&:hover": {
      background: variables.colors.background.darkHover,
      color: variables.colors.text.light,
    },
  },
  translateButton: {
    padding: variables.normal_gap,
    alignSelf: "center",
  },
  loader: { width: "100vw" },
  inputGroup: formInputGroup,
  iconTextContainer: {
    display: "grid",
    gridColumn: "span 2",
    gap: variables.half_gap,
  },
  formSectionHeader: formSections.sectionHeader,
  cardActions: { ...cardStyles.actions, gridColumn: "2 span" },

  [`@media ${variables.media.smallscreen}`]: {
    languages: {
      gridTemplateColumns: "1fr max-content",
      gap: variables.normal_gap,
    },
    cardCol2: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: variables.normal_gap,
    },
    cardCol3: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      gap: variables.normal_gap,
    },
  },
  [`@media ${variables.media.bigscreen}`]: {},
});

const EditGenericSections = ({ genericSetionId, websiteId, onClose }) => {
  let mode = genericSetionId ? "edit" : "add";
  const classes = styles();

  const [form, setForm] = useState(initialForm);
  // const [form, setForm] = useState(jsonSchema.cast({}));
  const [activeLanguage, setActiveLanguage] = useState("en");

  const queryClient = useQueryClient();

  const { data, isLoading: retrieveLoading } = useQuery({
    queryKey: ["RetrieveGenericSection", genericSetionId],
    queryFn: () => retrieveWebSiteGenericSections({ id: genericSetionId }),

    onSuccess: (data) => {
      setForm(_.get(data, "data", {}));
      // setForm(jsonSchema.cast(_.get(data, "data", {})));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    enabled: mode === "edit",
  });

  const addMutation = useMutation({
    mutationFn: ({ payload, website }) => {
      return createWebSiteGenericSection({
        payload: {
          ...payload,
          website,
        },
      });
    },
    onSuccess: () => {
      toast.success("New Generic Section added");
      queryClient.invalidateQueries("GenericSectionModal");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ payload, id }) => {
      return patchWebSiteGenericSections({
        id,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("Generic Section Info Updated");
      queryClient.invalidateQueries("GenericSectionModal");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ id, payload, website }) => {
    try {
      const preparedPayload = preparePayload(payload);
      if (!preparedPayload) {
        // in case of error
        return;
      }
      await jsonSchema.validate(preparedPayload, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload, website });
      } else {
        editMutation.mutate({ payload, id });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const preparePayload = (values) => {
    let payload = { ...values };
    if (payload.active_from) {
      payload["active_from"] = DateTime.fromISO(payload.active_from).toISO();
    }
    if (payload.active_to) {
      payload["active_to"] = DateTime.fromISO(payload.active_to).toISO();
    }
    if (payload.path && payload.path.includes(" ")) {
      toast.error("Path Must Not Contain Spaces");
      return null;
    }

    var icon_texts = [...payload.icon_texts];
    icon_texts = icon_texts.filter(
      (iconText) => !iconText.every((value) => value === "")
    );

    if (payload.page_type === "icon_text_showcase") {
      if (icon_texts.length < 1) {
        toast.error(
          "If the page type is Icon Text Showcase, you must add at least one icon text"
        );
        return null;
      }
    }

    payload["icon_texts"] = icon_texts;

    if (payload.page_type !== "icon_text_showcase") {
      payload["icon_texts"] = _.get(data, "data.icon_texts", []);
    }
    return payload;
  };

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  return (
    <div className={classes.EditGenericSections}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            {_.startCase(mode)} Generic Section
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <React.Fragment>
            <div className={classes.modalCardHeader}>
              {_.startCase(mode)} Generic Section
            </div>
            <div className={classes.modalCardBody}>
              <div className={classes.languages}>
                <div className={classes.languagesButtons}>
                  <WebsitesLanguages
                    activeLanguage={activeLanguage}
                    setActiveLanguage={setActiveLanguage}
                    languages={websiteLanguages}
                  />
                </div>
                <div className={classes.translateButton}>
                  <TranslateButton
                    targetLang={activeLanguage}
                    form={form}
                    setForm={setForm}
                  />
                </div>
              </div>
              <form
                onChange={(e) =>
                  setForm((p) => ({
                    ...p,
                    [e.target.name]: e.target.value,
                  }))
                }>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Basic Information</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol3}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" value={form.name} />
                      </div>

                      <div className={classes.inputGroup}>
                        <label htmlFor="title">Title</label>
                        {Object.entries(websiteLanguages).map(
                          ([key, label], idx) =>
                            key === activeLanguage ? (
                              <input
                                key={key}
                                value={
                                  key === "en"
                                    ? form.title
                                    : form[`title_${key}`]
                                }
                                onChange={(e) => {
                                  let newForm = {
                                    ...form,
                                    [key === "en" ? "title" : `title_${key}`]:
                                      e.target.value,
                                  };
                                  setForm(newForm);
                                }}
                              />
                            ) : null
                        )}
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="subtitle">Subtitle</label>
                        {Object.entries(websiteLanguages).map(
                          ([key, label], idx) =>
                            key === activeLanguage ? (
                              <input
                                key={key}
                                value={
                                  key === "en"
                                    ? form.subtitle
                                    : form[`subtitle_${key}`]
                                }
                                onChange={(e) => {
                                  let newForm = {
                                    ...form,
                                    [key === "en"
                                      ? "subtitle"
                                      : `subtitle_${key}`]: e.target.value,
                                  };
                                  setForm(newForm);
                                }}
                              />
                            ) : null
                        )}
                      </div>
                    </div>
                    <div className={classes.card}>
                      <div className={classes.descriptionCardBody}>
                        <div className={classes.description}>
                          <label htmlFor="description">Description</label>
                          {Object.entries(websiteLanguages).map(
                            ([key, label], idx) =>
                              key === activeLanguage ? (
                                <QuilEditor
                                  key={key}
                                  value={
                                    key === "en"
                                      ? form.description
                                      : form[`description_${key}`]
                                  }
                                  onChange={(value) => {
                                    let newForm = {
                                      ...form,
                                      [key === "en"
                                        ? "description"
                                        : `description_${key}`]: value,
                                    };
                                    setForm(newForm);
                                  }}
                                />
                              ) : null
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.cardCol3}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="header">Header</label>
                        <input type="text" name="header" value={form.header} />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="order">Order</label>
                        <input type="number" name="order" value={form.order} />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="product_category">
                          Product Category
                        </label>
                        <select
                          name="product_category"
                          value={form.product_category}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(productCategoryMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                    <div className={classes.cardCol2}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="name">Path</label>
                        <input type="text" name="path" value={form.path} />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="theme">Theme</label>
                        <input type="text" name="theme" value={form.theme} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Active Data</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol3}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="active_from">Active From</label>
                        <input
                          type="date"
                          name="active_from"
                          value={
                            form.active_from
                              ? DateTime.fromISO(form.active_from).toISODate()
                              : "N/A"
                          }
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="active_to">Active To</label>
                        <input
                          type="date"
                          name="active_to"
                          value={
                            form.active_to
                              ? DateTime.fromISO(form.active_to).toISODate()
                              : "N/A"
                          }
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="status">Status</label>
                        <select
                          name="status"
                          value={form.status}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(statusMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Page Type</div>
                  <div className={classes.cardBody}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="page_type">Page Type</label>
                      <select
                        name="page_type"
                        value={form.page_type}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(pageTypeMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    {form.page_type === "icon_text_showcase" &&
                      form.icon_texts.map((iconText, idx) => (
                        <React.Fragment key={idx}>
                          <div className={classes.iconTextContainer}>
                            <div className={classes.cardHeader}>
                              Icon Text {idx + 1}
                            </div>
                            <div className={classes.inputGroup}>
                              <label htmlFor="title">Title</label>
                              <input
                                value={iconText[0]}
                                onChange={(e) => {
                                  const newIconTexts = [...form.icon_texts];
                                  newIconTexts[idx] = [
                                    e.target.value,
                                    iconText[1],
                                    iconText[2],
                                  ];
                                  setForm({
                                    ...form,
                                    icon_texts: newIconTexts,
                                  });
                                }}
                              />
                            </div>
                            <div className={classes.inputGroup}>
                              <label htmlFor="description">Description</label>
                              <input
                                value={iconText[1]}
                                onChange={(e) => {
                                  const newIconTexts = [...form.icon_texts];
                                  newIconTexts[idx] = [
                                    iconText[0],
                                    e.target.value,
                                    iconText[2],
                                  ];
                                  setForm({
                                    ...form,
                                    icon_texts: newIconTexts,
                                  });
                                }}
                              />
                            </div>
                            <div className={classes.inputGroup}>
                              <label htmlFor="url">URL</label>
                              <input
                                value={iconText[2]}
                                onChange={(e) => {
                                  const newIconTexts = [...form.icon_texts];
                                  newIconTexts[idx] = [
                                    iconText[0],
                                    iconText[1],
                                    e.target.value,
                                  ];
                                  setForm({
                                    ...form,
                                    icon_texts: newIconTexts,
                                  });
                                }}
                              />
                            </div>
                            {form.icon_texts.length > 1 && ( // Conditionally render the button
                              <div className={classes.cardActions}>
                                <CustomButton
                                  appearance="ghost"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const newIconTexts = form.icon_texts.filter(
                                      (_, i) => i !== idx
                                    );
                                    setForm({
                                      ...form,
                                      icon_texts: newIconTexts,
                                    });
                                  }}>
                                  <strong>Remove Icon Text</strong>
                                </CustomButton>
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      ))}
                    {form.page_type === "icon_text_showcase" && (
                      <div className={classes.cardActions}>
                        <CustomButton
                          appearance="ghost"
                          onClick={(e) => {
                            e.preventDefault();
                            setForm({
                              ...form,
                              icon_texts: [...form.icon_texts, ["", "", ""]],
                            });
                          }}>
                          <strong>Add Icon Text</strong>
                        </CustomButton>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className={classes.modalCardActions}>
              <CustomButton appearance="ghost" onClick={onClose}>
                <strong>Cancel</strong>
              </CustomButton>
              <CustomButton
                appearance="primary"
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit({
                    id: genericSetionId,
                    payload: form,
                    website: websiteId,
                  });
                }}>
                <strong>Submit</strong>
              </CustomButton>
            </div>
          </React.Fragment>
        </div>
      )}
    </div>
  );
};
EditGenericSections.propTypes = {
  contactInfoId: PropTypes.number,
  websiteId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditGenericSections;
