import React from "react";
import Logs from "../../../../../components/Logs";
import { VALAQUENTA_API_URL } from "../../../../../api";

const UsersLogsTab = (WrappedComponent) => {
  const Comp = (props) => {
    const actionsMapping = {
      edit: {
        label: "Edit",
        url: `${VALAQUENTA_API_URL}/crm/users/`,
        method: "PATCH",
      },
      // add: {
      //   label: "Add",
      //   url: `${VALAQUENTA_API_URL}/crm/users/`,
      //   method: "PATCH",
      // },
    };

    return <WrappedComponent {...props} actionsMapping={actionsMapping} />;
  };
  return Comp;
};

export default UsersLogsTab(Logs);
