import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  retrieveCoordinatorReservation,
  patchCoordinatorReservation,
} from "../../../../../api/CRS/Coordinator";
import GuestCard from "../../../../../components/cards/GuestInfo";
import { DateTime } from "luxon";
import { Price } from "../../../../../components/Price";
import useReservationDetails from "../../../../../hooks";
import EditGuestModal from "../../../CommonCRSModals/EditGuestModal";
import EmailsCard from "../../../../../components/cards/emails";
import Loader from "../../../../../components/Loader";
import EditCRSTagModal from "../../../CommonCRSModals/EditCRSTagModal";
import { toast } from "react-toastify";
import ReservationEventsModal from "../../../ReservationEventsModal";
import { DocumentsCRSCard } from "../../../CommonCRSModals/DocumentsCRS/List";
import ReservationsStatusTag from "../../../../../components/Tags/ReservationsStatusTag";
import TabStrip from "../../../../../components/TabStrip";
import { CustomButton } from "../../../../../components/CustomButton";
import { genericRequestErrorHandler } from "../../../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import COOLogsTab from "./COOLogsTab";
import Receivables from "../../../CommonCRSModals/Receivables";
import { InternalLogs } from "../../../CommonCRSModals/InternalLogs";

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailCoordinatorModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridTemplateRows: "max-content max-content 1fr max-content",
    padding: "",
    overflowX: "hidden",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: { ...cardStyles.card, maxWidth: "100%" },
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    maxWidth: "100%",
    overflow: "auto",
    justifyContent: "start",
  },
  firstRow: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    padding: variables.normal_gap,
    gap: variables.normal_gap,
  },
  creatorCardBody: {
    extend: "cardBody",
    padding: [`calc(${variables.normal_gap} * 3.3)`, variables.normal_gap],
  },
  componentRow: {
    extend: "firstRow",
    gridTemplateColumns: "repeat(1, 1fr)",
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCardBody: {
      minWidth: "60rem",
    },
    firstRow: {
      gridTemplateColumns: "1fr 1fr",
      justifyItems: "center",
    },
  },
});
const DetailCoordinatorModal = ({ reference, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicInfo");

  const queryClient = useQueryClient();

  const menuTabs = {
    basicInfo: "Basic Info",
    reservationEvents: "Reservation Events",
    receivables: "Receivables",
    documents: "Documents",
    internalLogs: "Internal Logs",
    logs: "User Action Logs",
  };

  const {
    data: coordinator,
    guestEditMode,
    guestEditId,
    editCRSTagMode,
    reservationEventsModal,
    openGuestModal,
    closeGuestModal,
    openEditCRSTagModal,
    closeEditCRSTagModal,
    closeReservationEventsModal,
    // loading,
  } = useReservationDetails({
    reference,
    retrieveReservationData: retrieveCoordinatorReservation,
    onCloseDetails: onClose,
  });

  const basicDataCpl = [
    ["Reference", "reference"],
    ["Tags", null, _.get(coordinator, "data.tags", []).join(",")],
    ["Name", "name"],
    [
      "Travel Start",
      null,
      DateTime.fromISO(
        _.get(coordinator, "data.travel_start_date")
      ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
    ],
    [
      "Travel End",
      null,
      DateTime.fromISO(
        _.get(coordinator, "data.travel_start_date")
      ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
    ],
    ["Supplier", "supplier_name"],
    [
      "Original Service Price",
      null,
      <Price
        currency={_.get(coordinator, "data.currency")}
        price={_.get(coordinator, "data.original_service_price")}
      />,
    ],
    ["Market Type", "market_type"],
    ["Source Entity", "source_entity"],
    [
      "Created",
      null,
      DateTime.fromISO(_.get(coordinator, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    [
      "Status",
      null,
      <ReservationsStatusTag status={_.get(coordinator, "data.status")} />,
    ],
  ];
  const creatorDataCpl = [
    ["External Creator", "external_creator"],
    [
      "Creator",
      null,
      _.get(coordinator, "data.guests", []).map((des) => _.get(des, "creator")),
    ],
  ];

  const { mutate: onSubmitTags, isLoading: tagsLoading } = useMutation({
    mutationFn: (values) => {
      return patchCoordinatorReservation({ payload: values, reference });
    },
    onSuccess: () => {
      toast.success("Tags updated successfully");
      queryClient.invalidateQueries("coordinators");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  return (
    <div id="DetailCOOModal" className={classes.DetailCoordinatorModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Coordinator Details</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {_.isEmpty(coordinator) ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicInfo" && (
                <React.Fragment>
                  <div className={classes.firstRow}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        actions={[["Edit", () => openEditCRSTagModal()]]}
                        header={"Basic Data"}
                        cardInfo={basicDataCpl}
                        objectData={_.get(coordinator, "data")}
                      />
                    </div>
                    <div className={classes.creatorCardBody}>
                      <GenericBasicInfo
                        header="Creator Data"
                        cardInfo={creatorDataCpl}
                        objectData={_.get(coordinator, "data")}
                      />
                    </div>
                  </div>
                  <div className={classes.componentRow}>
                    <div className={classes.cardBody}>
                      <GuestCard
                        title={"Guest Informations"}
                        guests={_.get(coordinator, "data.guests", [])}
                        onEditGuest={(id) => {
                          openGuestModal(id);
                        }}
                        onCloseEdit={() => closeGuestModal()}
                      />
                      <EmailsCard
                        title={"Email Informations"}
                        market_type={_.get(coordinator, "data.market_type")}
                        reservation_id={_.get(coordinator, "data.reference")}
                        service_type="COO"
                        status={_.get(coordinator, "data.status")}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activeTab === "reservationEvents" && (
                <ReservationEventsModal
                  header="Reservation Events"
                  onClose={() => closeReservationEventsModal()}
                  events={_.get(coordinator, "data.reservation_events", [])}
                />
              )}
              {guestEditMode && !reservationEventsModal && !editCRSTagMode && (
                <EditGuestModal
                  onClose={closeGuestModal}
                  guestId={guestEditId}
                  queryKey={`accommodation_reservation_users_documents`}
                  reference={_.get(coordinator, "data.reference")}
                  sourceEntity={_.get(coordinator, "data.source_entity", "")}
                />
              )}
              {!guestEditMode && !reservationEventsModal && editCRSTagMode && (
                <EditCRSTagModal
                  header={"Coordinator"}
                  tagsLoading={tagsLoading}
                  tags={_.get(coordinator, "data.tags")}
                  onClose={closeEditCRSTagModal}
                  onSubmitTags={onSubmitTags}
                />
              )}
              {activeTab === "receivables" && (
                <Receivables
                  data={_.get(coordinator, "data.payment_links", [])}
                />
              )}
              {activeTab === "documents" && (
                <DocumentsCRSCard
                  title="Documents"
                  queryKey={`coordinator_reservation_document`}
                  showAddButton={true}
                  tags={[
                    `${_.get(coordinator, "data.reference")}_reservation_COO`,
                  ]}
                  reference={_.get(coordinator, "data.reference")}
                  sourceEntity={_.get(coordinator, "data.source_entity", "")}
                />
              )}
              {activeTab === "internalLogs" && (
                <InternalLogs
                  prebookData={_.get(coordinator, "data.prebook_data", {})}
                  bookData={_.get(coordinator, "data.post_booking_data", {})}
                />
              )}
              {activeTab === "logs" && <COOLogsTab />}
            </React.Fragment>
          )}
        </div>
        <div className={classes.cardActions}>
          <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailCoordinatorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default DetailCoordinatorModal;
