import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import { statusMapping } from "../../common";
import Loader from "../../../../../components/Loader";
import {
  createWebSiteTermAndCondition,
  patchWebSiteTermAndCondition,
  retrieveWebSiteTermAndCondition,
} from "../../../../../api/DistributionSite/webSites/TermsAndConditions";
import _ from "lodash";
import { DateTime } from "luxon";
import QuilEditor from "../../../../../components/QuilEditor";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import WebsitesLanguages from "../../Components/WebsitesLanguages";
import TranslateButton from "../../Components/TranslateButton";
import {
  handleValidationErrors,
  websiteLanguages,
} from "../../../../../helpers";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Must be at least 2 characters")
    .max(100, "Must be less than 100 characters"),
  description: yup.string().nullable().default(""),
  description_de: yup.string().nullable().default(""),
  description_el: yup.string().nullable().default(""),
  description_es: yup.string().nullable().default(""),
  description_fr: yup.string().nullable().default(""),
  description_it: yup.string().nullable().default(""),
  description_ja: yup.string().nullable().default(""),
  description_nl: yup.string().nullable().default(""),
  description_pt: yup.string().nullable().default(""),
  description_th: yup.string().nullable().default(""),
  description_zh: yup.string().nullable().default(""),
  description_ln: yup.string().nullable().default(""),
  status: yup
    .mixed()
    .oneOf(["AC", "IN", "CA"], "Must be between Active, Inactive or Candidate"),
  terms_cn: yup.array().of(
    yup.object().shape({
      title: yup.string().default(""),
      description: yup.string().default(""),
    })
  ),
  terms_de: yup.array().of(
    yup.object().shape({
      title: yup.string().default(""),
      description: yup.string().default(""),
    })
  ),
  terms_el: yup.array().of(
    yup.object().shape({
      title: yup.string().default(""),
      description: yup.string().default(""),
    })
  ),
  terms_en: yup.array().of(
    yup.object().shape({
      title: yup.string().default(""),
      description: yup.string().default(""),
    })
  ),
  terms_es: yup.array().of(
    yup.object().shape({
      title: yup.string().default(""),
      description: yup.string().default(""),
    })
  ),
  terms_fr: yup.array().of(
    yup.object().shape({
      title: yup.string().default(""),
      description: yup.string().default(""),
    })
  ),
  terms_it: yup.array().of(
    yup.object().shape({
      title: yup.string().default(""),
      description: yup.string().default(""),
    })
  ),
  terms_ja: yup.array().of(
    yup.object().shape({
      title: yup.string().default(""),
      description: yup.string().default(""),
    })
  ),
  terms_nl: yup.array().of(
    yup.object().shape({
      title: yup.string().default(""),
      description: yup.string().default(""),
    })
  ),
  terms_pt: yup.array().of(
    yup.object().shape({
      title: yup.string().default(""),
      description: yup.string().default(""),
    })
  ),
  terms_th: yup.array().of(
    yup.object().shape({
      title: yup.string().default(""),
      description: yup.string().default(""),
    })
  ),
  terms_lt: yup.array().of(
    yup.object().shape({
      title: yup.string().default(""),
      description: yup.string().default(""),
    })
  ),
});

const initialForm = {
  name: "",
  status: "",
  active_from: null,
  active_to: null,

  description_cn: "",
  description_de: "",
  description_el: "",
  description_en: "",
  description_es: "",
  description_fr: "",
  description_it: "",
  description_ja: "",
  description_nl: "",
  description_pt: "",
  description_th: "",
  description_lt: "",
  terms_cn: [{ title: "", description: "" }],
  terms_de: [{ title: "", description: "" }],
  terms_el: [{ title: "", description: "" }],
  terms_en: [{ title: "", description: "" }],
  terms_es: [{ title: "", description: "" }],
  terms_fr: [{ title: "", description: "" }],
  terms_it: [{ title: "", description: "" }],
  terms_ja: [{ title: "", description: "" }],
  terms_nl: [{ title: "", description: "" }],
  terms_pt: [{ title: "", description: "" }],
  terms_th: [{ title: "", description: "" }],
  terms_lt: [{ title: "", description: "" }],
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditTermsAndConditions: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
    overflow: "hidden",
    height: "100vh",
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,

    gap: variables.normal_gap,
  },
  languages: {
    display: "grid",
    padding: variables.double_gap,
  },
  languagesButtons: {
    height: "max-content",
    padding: variables.normal_gap,
    overflow: "auto",
  },
  translateButton: {
    padding: variables.normal_gap,
    alignSelf: "center",
  },
  inputGroup: formInputGroup,
  descriptionCardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    display: "grid",
    padding: "",
    paddingBottom: variables.normal_gap,
  },
  description: {
    gap: variables.normal_gap,
    "& label": {
      fontSize: "small",
    },
  },
  languagesButton: {
    padding: variables.normal_gap,
    gap: variables.normal_gap,
    borderRadius: "5px",
    cursor: "pointer",
    fontWeight: "bold",
    transition: "background .s, color .3s",
    "&:hover": {
      background: variables.colors.background.darkHover,
      color: variables.colors.text.light,
    },
  },
  cardBodyTerms: {
    ...cardStyles.body,
    display: "grid",
    gridTemplateRows: "repeat(3, 1fr)",
    gap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    languages: {
      gridTemplateColumns: "1fr max-content",
      gap: variables.normal_gap,
    },
    cardCol2: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: variables.normal_gap,
    },
    cardCol3: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      gap: variables.normal_gap,
    },
  },
});

const EditTermsAndConditions = ({ websiteId, id, onClose }) => {
  const classes = styles();

  const mode = id ? "edit" : "add";

  const [form, setForm] = useState(initialForm);
  const [activeLanguage, setActiveLanguage] = useState("en");
  const [activeTermsAndConditionLanguage, setActiveTermsAndConditionLanguage] =
    useState(["en"]);

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["RetrieveContactInfo", id],
    queryFn: () => retrieveWebSiteTermAndCondition({ id: id }),

    onSuccess: (data) => {
      setForm(_.get(data, "data", {}));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    enabled: mode === "edit",
  });

  const editMutation = useMutation({
    mutationFn: ({ payload, id }) => {
      return patchWebSiteTermAndCondition({
        id,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("Terms and Conditions Info Updated");
      queryClient.invalidateQueries("TermsAndConditionsList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const addMutation = useMutation({
    mutationFn: ({ payload, website }) => {
      return createWebSiteTermAndCondition({
        payload: {
          ...payload,
          website,
        },
      });
    },
    onSuccess: () => {
      toast.success("Terms and Conditions Added");
      queryClient.invalidateQueries("TermsAndConditionsList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ id, payload, website }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload, website });
      } else {
        editMutation.mutate({ id, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  return (
    <div className={classes.EditTermsAndConditions}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            {_.startCase(mode)} Terms And Conditions
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            {_.startCase(mode)} Terms And Conditions
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.languages}>
              <div className={classes.languagesButtons}>
                <WebsitesLanguages
                  activeLanguage={activeLanguage}
                  setActiveLanguage={setActiveLanguage}
                  languages={websiteLanguages}
                />
              </div>
              <div className={classes.translateButton}>
                <TranslateButton
                  targetLang={activeLanguage}
                  fields={["description", "terms"]}
                  subfields={{ questions: ["title", "description"] }}
                  form={form}
                  setForm={setForm}
                />
              </div>
            </div>
            <form
              onChange={(e) =>
                setForm((p) => ({
                  ...p,
                  [e.target.name]: e.target.value,
                }))
              }>
              <div className={classes.card}>
                <div className={classes.cardHeader}>
                  <h5>Basic Information</h5>
                </div>
                <div className={classes.cardBody}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name" defaultValue={form.name} />
                  </div>

                  <div className={classes.description}>
                    <label htmlFor="description">Description</label>

                    {Object.entries(websiteLanguages).map(([key, label], idx) =>
                      key === activeLanguage ? (
                        <QuilEditor
                          key={key}
                          value={form[`description_${key}`]}
                          onChange={(value) => {
                            let newForm = {
                              ...form,
                              [`description_${key}`]: value,
                            };
                            setForm(newForm);
                          }}
                        />
                      ) : null
                    )}
                  </div>
                </div>
              </div>

              <div className={classes.card}>
                <div className={classes.cardHeader}>
                  <h5>Active data</h5>
                </div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="active_from">Active From</label>
                      <input
                        type="date"
                        name="active_from"
                        value={
                          form.active_from
                            ? DateTime.fromISO(form.active_from).toISODate()
                            : "N/A"
                        }
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="active_to">Active To</label>
                      <input
                        type="date"
                        name="active_to"
                        value={
                          form.active_to
                            ? DateTime.fromISO(form.active_to).toISODate()
                            : "N/A"
                        }
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        value={form.status}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(statusMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {_.get(form, `terms_${activeTermsAndConditionLanguage}`).map(
                (term, termsIdx) => (
                  <div className={classes.card}>
                    <div className={classes.cardHeader}>
                      Terms and Conditions {termsIdx + 1}
                    </div>
                    <div className={classes.cardBodyTerms}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="title">Title</label>
                        <input
                          value={_.get(
                            form,
                            `terms_${activeLanguage}.${termsIdx}.title`
                          )}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              [`terms_${activeLanguage}`]: _.get(
                                form,
                                `terms_${activeLanguage}`
                              ).map((term, idx) => {
                                if (idx !== termsIdx) {
                                  return term;
                                }
                                return { ...term, title: e.target.value };
                              }),
                            });
                          }}
                        />
                      </div>
                      <div className={classes.description}>
                        <label htmlFor="description">Description</label>
                        <QuilEditor
                          value={_.get(
                            form,
                            `terms_${activeLanguage}.${termsIdx}.description`
                          )}
                          onChange={(value) => {
                            setForm({
                              ...form,
                              [`terms_${activeLanguage}`]: _.get(
                                form,
                                `terms_${activeLanguage}`
                              ).map((term, idx) => {
                                if (idx !== termsIdx) {
                                  return term;
                                }
                                return {
                                  ...term,
                                  description: value,
                                };
                              }),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.cardActions}>
                      {_.get(form, `terms_${activeLanguage}`).length - 1 ===
                      termsIdx ? (
                        <CustomButton
                          appearance="ghost"
                          onClick={(e) => {
                            e.preventDefault();
                            setForm({
                              ...form,
                              terms_en: [
                                ...form.terms_en,
                                { title: "", description: "" },
                              ],
                              terms_cn: [
                                ...form.terms_cn,
                                { title: "", description: "" },
                              ],
                              terms_de: [
                                ...form.terms_de,
                                { title: "", description: "" },
                              ],
                              terms_el: [
                                ...form.terms_el,
                                { title: "", description: "" },
                              ],
                              terms_es: [
                                ...form.terms_es,
                                { title: "", description: "" },
                              ],
                              terms_fr: [
                                ...form.terms_fr,
                                { title: "", description: "" },
                              ],
                              terms_it: [
                                ...form.terms_it,
                                { title: "", description: "" },
                              ],
                              terms_nl: [
                                ...form.terms_nl,
                                { title: "", description: "" },
                              ],
                              terms_pt: [
                                ...form.terms_pt,
                                { title: "", description: "" },
                              ],
                            });
                          }}>
                          <strong>Add Extra Term And Condition</strong>
                        </CustomButton>
                      ) : null}
                      {_.get(form, `terms_${activeLanguage}`).length > 0 &&
                        termsIdx > 0 && (
                          <CustomButton
                            appearance="primary"
                            onClick={(e) => {
                              e.preventDefault();
                              setForm({
                                ...form,
                                [`terms_${activeLanguage}`]: [
                                  ..._.get(
                                    form,
                                    `terms_${activeLanguage}`
                                  ).filter(
                                    (x, indexTerm) => termsIdx !== indexTerm
                                  ),
                                ],
                              });
                            }}>
                            <strong>Remove Terms And Conditions</strong>
                          </CustomButton>
                        )}
                    </div>
                  </div>
                )
              )}
            </form>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              appearance="primary"
              text="Submit"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  id: id,
                  payload: form,
                  website: websiteId,
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditTermsAndConditions.propTypes = {
  id: PropTypes.number,
  websiteId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditTermsAndConditions;
