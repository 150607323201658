import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../components/cards/GenericBasicInfo";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import { retrieveVesselAPIConfiguration } from "../../../../api/VesselEngine/ApiConfiguration/ApiConfiguration";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";
import Loader from "../../../../components/Loader";
import { DateTime } from "luxon";
import { CustomButton } from "../../../../components/CustomButton";
import TabStrip from "../../../../components/TabStrip";
import VesselConfigLogsTab from "./VesselConfigLogsTab";
import ReservationsStatusTag from "../../../../components/Tags/ReservationsStatusTag";

const trueFalseMapping = {
  true: "Yes",
  false: "No",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailVesselEngineModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardBody: cardStyles.body,
  row: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    row: {
      gridTemplateColumns: "2fr 1fr",
    },
  },
});

const DetailVesselEngineModal = ({ uid, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicData");

  const menuTabs = {
    basicData: "Basic Data",
    logs: "Logs",
  };

  const { data: vessel, isLoading: loading } = useQuery({
    queryKey: ["DetailAviateAPIConfigurationsModal", uid],
    queryFn: async () => {
      return await retrieveVesselAPIConfiguration({
        uid: uid,
      });
    },
    onError: (error) => genericRequestErrorHandler(error),
    refetchOnWindowFocus: false,
  });

  const basicDataCpl = [
    ["Uid", "uid"],
    ["Name", null, _.capitalize(_.get(vessel, "data.name"))],
    ["Market Type", "market_type"],
    ["Source Entity", "source_entity"],
    [
      "Is Global",
      null,
      _.get(trueFalseMapping, _.get(vessel, "data.is_global")),
    ],
    ["Is Own", null, _.get(trueFalseMapping, _.get(vessel, "data.is_own"))],
    [
      "Is Master Account",
      null,
      _.get(trueFalseMapping, _.get(vessel, "data.is_master_account")) || "N/A",
    ],
    ["Website Api Key", "website_api_key"],
    ["Environment", "environment"],
    ["Partner Code", "partner_code"],
    [
      "Status",
      null,
      <ReservationsStatusTag status={_.get(vessel, "data.status")} />,
    ],
  ];
  const creatorData = [
    [
      "Created",
      null,
      DateTime.fromISO(_.get(vessel, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ) || "N/A",
    ],
  ];
  const accessData = [
    ["Client Id", "client_id"],
    ["Client Secret", "client_secret"],
  ];
  return (
    <div className={classes.DetailVesselEngineModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>API Configuration Details</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicData" && (
                <React.Fragment>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Basic Data"}
                        cardInfo={basicDataCpl}
                        objectData={_.get(vessel, "data", {})}
                      />
                    </div>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header={"Creator Data"}
                        cardInfo={creatorData}
                        objectData={_.get(vessel, "data", {})}
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.cardBody}>
                      <GenericBasicInfo
                        header="Authorization Data"
                        cardInfo={accessData}
                        objectData={_.get(vessel, "data", {})}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activeTab === "logs" && <VesselConfigLogsTab />}
            </React.Fragment>
          )}
        </div>
        <div className={classes.modalCardActions}>
          <CustomButton appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailVesselEngineModal.propTypes = {
  uid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DetailVesselEngineModal;
