import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import { Price } from "../../../../../components/Price";
import { DateTime } from "luxon";
import GuestCard from "../../../../../components/cards/GuestInfo";
import useReservationDetails from "../../../../../hooks";
import EditGuestModal from "../../../CommonCRSModals/EditGuestModal";
import {
  patchFlightReservation,
  retrieveFlightReservation,
} from "../../../../../api/CRS/Flights";
import EmailsCard from "../../../../../components/cards/emails";
import Loader from "../../../../../components/Loader";
import EditCRSTagModal from "../../../CommonCRSModals/EditCRSTagModal";
import { toast } from "react-toastify";
import ReservationEventsModal from "../../../ReservationEventsModal";
import { DocumentsCRSCard } from "../../../CommonCRSModals/DocumentsCRS/List";
import LightServiceSpecification from "../lightServiceSpecifictions";
import ReservationsStatusTag from "../../../../../components/Tags/ReservationsStatusTag";
import TabStrip from "../../../../../components/TabStrip";
import { CustomButton } from "../../../../../components/CustomButton";
import FLLogsTab from "./FLLogsTab";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import Receivables from "../../../CommonCRSModals/Receivables";
import { InternalLogs } from "../../../CommonCRSModals/InternalLogs";

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailFlightModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    padding: variables.normal_gap,
    overflowX: "hidden",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: { ...cardStyles.card, maxWidth: "100%" },
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    maxWidth: "100%",
    // overflow: "auto",
    justifyItems: "start",
  },
  cardBodyCol2: {
    ...cardStyles.body,
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
    maxWidth: "100%",
    // overflow: "auto",
    justifyItems: "start",
  },
  creatorCardBody: {
    extend: "cardBody",
    overflow: "none",
    padding: [`calc(${variables.normal_gap} * 2.3)`, variables.normal_gap],
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCardBody: {
      minWidth: "60rem",
    },
    cardBodyCol2: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      justifyItems: "center",
    },
  },
});
const DetailFlightModal = ({ reference, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicInfo");

  const queryClient = useQueryClient();

  const menuTabs = {
    basicInfo: "Basic Info",
    reservationEvents: "Reservation Events",
    receivables: "Receivables",
    documents: "Documents",
    internalLogs: "Internal Logs",
    logs: "User Action Logs",
  };

  const {
    data: flight,
    guestEditMode,
    guestEditId,
    editCRSTagMode,
    reservationEventsModal,
    openGuestModal,
    closeGuestModal,
    openEditCRSTagModal,
    closeEditCRSTagModal,
    closeReservationEventsModal,
    // loading,
  } = useReservationDetails({
    reference,
    retrieveReservationData: retrieveFlightReservation,
    onCloseDetails: onClose,
  });

  const outboundItinerary = _.get(
    flight,
    "data.transportation_info.outbound_itinerary",
    []
  );
  const basicDataCpl = [
    ["Reference", "reference"],
    ["External Reference", "external_reference"],
    ["Record Locator", "record_locator"],
    ["Trip Mode", null, _.get(flight, "data.transportation_info.trip_mode")],
    [
      "Outbound Itinerary",
      null,
      outboundItinerary.map((destination) => destination.iata).join(", "),
    ],
    [
      "Outbound Operator",
      null,
      _.get(flight, "data.transportation_info.outbound_operating_airline.name"),
    ],
    [
      "Adults",
      null,
      _.get(flight, "data.prebook_data.search_data.adults", 0)
        ? _.get(flight, "data.prebook_data.search_data.adults")
        : "0",
    ],
    [
      "Children",
      null,
      _.get(flight, "data.prebook_data.search_data.children", 0)
        ? _.get(flight, "data.prebook_data.search_data.children")
        : "0",
    ],
    ["PCC Number", "pcc"],
    ["Queue Number", "queue_number"],
    ["Queue Booking Status", "queue_booking_status"],
    ["Tags", null, _.get(flight, "data.tags", []).join(",")],
    [
      "Original Service Price",
      null,
      <Price
        currency={_.get(flight, "data.currency")}
        price={_.get(flight, "data.original_service_price")}
      />,
    ],
    [
      "Market Type",
      null,
      _.get(flight, "data.transportation_info.market_type"),
    ],
    ["Source Entity", "source_entity"],
    [
      "Status",
      null,
      <ReservationsStatusTag status={_.get(flight, "data.status")} />,
    ],
  ];
  const creatorDataCpl = [
    [
      "Created",
      null,
      DateTime.fromISO(_.get(flight, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    ["External Creator", "external_creator"],
    [
      "Creator",
      null,
      _.get(flight, "data.guests", []).map((des) => _.get(des, "creator")),
    ],
  ];

  const { mutate: onSubmitTags, isLoading: tagsLoading } = useMutation({
    mutationFn: (values) => {
      return patchFlightReservation({ payload: values, reference });
    },
    onSuccess: () => {
      toast.success("Tags updated successfully");
      queryClient.invalidateQueries("flights");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  return (
    <div id="DetailFLModal" className={classes.DetailFlightModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Flight Details</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={classes.modalCardBody}>
          {_.isEmpty(flight) ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicInfo" && (
                <React.Fragment>
                  <div className={classes.card}>
                    <div className={classes.cardHeader}>
                      <span>Reservation Information</span>
                    </div>
                    <div className={classes.cardBodyCol2}>
                      <GenericBasicInfo
                        actions={[["Edit", () => openEditCRSTagModal()]]}
                        header={"Basic Data"}
                        cardInfo={basicDataCpl}
                        objectData={_.get(flight, "data")}
                      />
                      <div className={classes.creatorCardBody}>
                        <GenericBasicInfo
                          header="Creator Data"
                          cardInfo={creatorDataCpl}
                          objectData={_.get(flight, "data")}
                        />
                      </div>
                    </div>
                  </div>

                  <LightServiceSpecification
                    objectData={_.get(flight, "data", {})}
                  />
                  <GuestCard
                    title={"Guest Informations"}
                    guests={_.get(flight, "data.guests", [])}
                    onCloseEdit={() => closeGuestModal()}
                    onEditGuest={(id) => {
                      openGuestModal(id);
                    }}
                  />
                  <EmailsCard
                    title={"Email Informations"}
                    market_type={_.get(flight, "data.market_type")}
                    reservation_id={_.get(flight, "data.reference")}
                    service_type="AIR"
                    status={_.get(flight, "data.status")}
                  />
                </React.Fragment>
              )}
              {activeTab === "reservationEvents" && (
                <ReservationEventsModal
                  header="Reservation Events"
                  onClose={() => closeReservationEventsModal()}
                  events={_.get(flight, "data.reservation_events", [])}
                />
              )}
              {guestEditMode && !reservationEventsModal && !editCRSTagMode && (
                <EditGuestModal
                  onClose={closeGuestModal}
                  guestId={guestEditId}
                  queryKey={`flight_reservation_users_documents`}
                  reference={_.get(flight, "data.reference")}
                  sourceEntity={_.get(flight, "data.source_entity", "")}
                />
              )}
              {!guestEditMode && !reservationEventsModal && editCRSTagMode && (
                <EditCRSTagModal
                  header={"Flight"}
                  tags={_.get(flight, "data.tags")}
                  onClose={closeEditCRSTagModal}
                  onSubmitTags={onSubmitTags}
                />
              )}
              {activeTab === "receivables" && (
                <Receivables data={_.get(flight, "data.payment_links", [])} />
              )}
              {activeTab === "documents" && (
                <DocumentsCRSCard
                  title="Documents"
                  queryKey={`flight_reservation_document`}
                  showAddButton={true}
                  tags={[`${_.get(flight, "data.reference")}_reservation_FL`]}
                  reference={_.get(flight, "data.reference")}
                  sourceEntity={_.get(flight, "data.source_entity", "")}
                />
              )}
              {activeTab === "internalLogs" && (
                <InternalLogs
                  prebookData={_.get(flight, "data.prebook_data", {})}
                  bookData={_.get(flight, "data.post_booking_data", {})}
                />
              )}
              {activeTab === "logs" && <FLLogsTab />}
            </React.Fragment>
          )}
        </div>
        <div className={classes.cardActions}>
          <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailFlightModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default DetailFlightModal;
