import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  cardStyles,
  tableStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import _ from "lodash";
import { medWeekdayDatetimer } from "../../../../../helpers";
import { Button } from "../../../../../components/Button";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";

const styles = createUseStyles({
  ...cardStyles,
  UnpaidInvoicesSection: { ...cardStyles.card },
  header: {
    ...cardStyles.header,
    padding: variables.normal_gap,
    fontSize: "1.2rem",
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  tableContainer: {
    display: "block",
    maxHeight: "20rem",
    overflow: "auto",
    width: "100%",
  },
  table: {
    width: "100%",
  },
  thead: {
    position: "sticky",
    top: 0,
  },
  emptyInvoices: {
    color: "red",
    textAlign: "center",
    minHeight: "10rem",
    lineHeight: "10rem",
  },
  red: { color: "red" },
  actionCell: genericIndexesStyles.actionCell.mobile,
});
const UnpaidInvoicesSection = ({ unpaidInvoices }) => {
  const classes = styles();
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showUnpaidInvoicesModal, setShowUnpaidInvoicesModal] = useState(false);

  return (
    <div className={classes.UnpaidInvoicesSection}>
      <div className={classes.header}>
        <span>Unpaid Invoices</span>
      </div>
      <div className={classes.tableContainer}>
        {unpaidInvoices.length === 0 ? (
          <div className={classes.emptyInvoices}>
            <strong>No Unpaid Invoices</strong>
          </div>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Account Name</th>
                <th className={classes.headCell}>Customer Name</th>
                <th className={classes.headCell}>Amount Due</th>
                <th className={classes.headCell}>Amount Paid</th>
                <th className={classes.headCell}>Amount Remaining</th>
                <th className={classes.headCell}>Due Date</th>
                <th className={classes.headCell}>Created</th>
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {unpaidInvoices.map((invoice, idx) => (
                <tr key={invoice.id}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  <td className={classes.tableCell}>
                    {_.get(invoice, "account_name") || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(invoice, "customer_name") || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(invoice, "amount_due", false) !== false &&
                    _.get(invoice, "currency")
                      ? `${_.get(invoice, "currency")} ${_.get(
                          invoice,
                          "amount_due"
                        )}`
                      : "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(invoice, "amount_paid", false) !== false &&
                    _.get(invoice, "currency")
                      ? `${_.get(invoice, "currency")} ${_.get(
                          invoice,
                          "amount_paid"
                        )}`
                      : "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(invoice, "amount_remaining", false) !== false &&
                    _.get(invoice, "currency")
                      ? `${_.get(invoice, "currency")} ${_.get(
                          invoice,
                          "amount_remaining"
                        )}`
                      : "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(invoice, "due_date")
                      ? medWeekdayDatetimer(_.get(invoice, "due_date"))
                      : "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(invoice, "created")
                      ? medWeekdayDatetimer(_.get(invoice, "created"))
                      : "N/A"}
                  </td>
                  <td className={classes.actionCell}>
                    {invoice.invoice_pdf && (
                      <Button
                        text="Download PDF"
                        onClick={() => {
                          window.open(invoice.invoice_pdf, "_blank");
                        }}
                      />
                    )}
                    {_.get(invoice, "lines").length > 0 && (
                      <Button
                        text="See Details"
                        onClick={() => {
                          setSelectedInvoice(invoice);
                          setShowUnpaidInvoicesModal(true);
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {showUnpaidInvoicesModal && (
        <UnpaidInvoicesModal
          unpaidInvoice={selectedInvoice}
          onClose={() => setShowUnpaidInvoicesModal(false)}
        />
      )}
    </div>
  );
};
UnpaidInvoicesSection.propTypes = {
  websites: PropTypes.array.isRequired,
  emailConfigs: PropTypes.array.isRequired,
};
export default UnpaidInvoicesSection;

const UnpaidInvoicesModalStyles = createUseStyles({
  ...modalGenericStyles,
  UnpaidInvoicesModal: {
    ...modalGenericStyles.modal,
  },
  mainCard: {
    ...modalGenericStyles.card,
    width: "50rem",
  },
  mainCardBody: {
    ...modalGenericStyles.card,
    minHeight: "6rem",
  },
  card: {
    ...cardStyles.card,
    width: "100%",
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  tableContainer: {
    display: "block",
    maxHeight: "20rem",
    overflow: "auto",
    width: "100%",
  },
  table: {
    width: "100%",
  },
  thead: {
    position: "sticky",
    top: 0,
  },
  emptyLines: {
    color: "red",
    textAlign: "center",
    minHeight: "10rem",
    lineHeight: "10rem",
  },
});
const UnpaidInvoicesModal = ({ unpaidInvoice, onClose }) => {
  const classes = UnpaidInvoicesModalStyles();

  const invoiceDataCpl = [
    ["Account Name", null, _.get(unpaidInvoice, "account_name") || "N/A"],
    ["Customer Name", null, _.get(unpaidInvoice, "customer_name") || "N/A"],
    [
      "Amount Due",
      null,
      _.get(unpaidInvoice, "amount_due") !== null &&
      _.get(unpaidInvoice, "currency")
        ? `${_.get(unpaidInvoice, "currency")} ${
            _.get(unpaidInvoice, "amount_due") === 0
              ? "0"
              : _.get(unpaidInvoice, "amount_due")
          }`
        : "N/A",
    ],
    [
      "Amount Paid",
      null,
      _.get(unpaidInvoice, "amount_paid") !== null &&
      _.get(unpaidInvoice, "currency")
        ? `${_.get(unpaidInvoice, "currency")} ${
            _.get(unpaidInvoice, "amount_paid") === 0
              ? "0"
              : _.get(unpaidInvoice, "amount_paid")
          }`
        : "N/A",
    ],
    [
      "Amount Remaining",
      null,
      _.get(unpaidInvoice, "amount_remaining") !== null &&
      _.get(unpaidInvoice, "currency")
        ? `${_.get(unpaidInvoice, "currency")} ${
            _.get(unpaidInvoice, "amount_remaining") === 0
              ? "0"
              : _.get(unpaidInvoice, "amount_remaining")
          }`
        : "N/A",
    ],
    [
      "Due Date",
      null,
      _.get(unpaidInvoice, "due_date")
        ? medWeekdayDatetimer(_.get(unpaidInvoice, "due_date"))
        : "N/A",
    ],
    [
      "Created",
      null,
      _.get(unpaidInvoice, "created")
        ? medWeekdayDatetimer(_.get(unpaidInvoice, "created"))
        : "N/A",
    ],
  ];
  return (
    <div className={classes.UnpaidInvoicesModal}>
      <div className={classes.mainCard}>
        <div className={classes.cardHeader}>
          <span>Unpaid Invoice</span>
        </div>
        <div className={classes.mainCardBody}>
          <GenericBasicInfo
            header="Invoice Info"
            cardInfo={invoiceDataCpl}
            objectData={unpaidInvoice}
          />
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              <span>Invoice Lines</span>
            </div>
            <div className={classes.tableContainer}>
              {_.get(unpaidInvoice, "lines", []).length === 0 ? (
                <div className={classes.emptyLines}>
                  <strong>No Invoice Lines</strong>
                </div>
              ) : (
                <table className={classes.table}>
                  <thead className={classes.thead}>
                    <tr className={classes.headRow}>
                      <th className={classes.headCell}>#</th>
                      <th className={classes.headCell}>Quantity</th>
                      <th className={classes.headCell}>Description</th>
                      <th className={classes.headCell}>Amount</th>
                      <th className={classes.headCell}>Period From</th>
                      <th className={classes.headCell}>Period To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.get(unpaidInvoice, "lines", []).map((line, idx) => (
                      <tr key={line.id}>
                        <td className={classes.tableCell}>{idx + 1}</td>
                        <td className={classes.tableCell}>
                          {_.get(line, "quantity") === 0
                            ? "0"
                            : _.get(line, "quantity") || "N/A"}
                        </td>
                        <td className={classes.tableCell}>
                          {_.get(line, "description") || "N/A"}
                        </td>
                        <td className={classes.tableCell}>
                          {_.get(line, "amount") !== null &&
                          _.get(line, "currency")
                            ? `${_.get(line, "currency")} ${
                                _.get(line, "amount") === 0
                                  ? "0"
                                  : _.get(line, "amount")
                              }`
                            : "N/A"}
                        </td>
                        <td className={classes.tableCell}>
                          {_.get(line, "period_from")
                            ? medWeekdayDatetimer(_.get(line, "period_from"))
                            : "N/A"}
                        </td>
                        <td className={classes.tableCell}>
                          {_.get(line, "period_to")
                            ? medWeekdayDatetimer(_.get(line, "period_to"))
                            : "N/A"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <div className={classes.cardActions}>
          <Button text="Close" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};
UnpaidInvoicesModal.propTypes = {
  unpaidInvoice: PropTypes.object.isRequired,
};
