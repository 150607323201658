import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Navbar from "./Navbar";
import ControlStrip from "../../../../components/ControlStrip";
import Loader from "../../../../components/Loader";
import { handleApplyFilters, medWeekdayDatetimer } from "../../../../helpers";
import DetailRestaurantModal from "../RestaurantsModals/DetailRestaurantModal";
import {
  fetchRestaurantReservations,
  getRestaurantReservationStats,
} from "../../../../api/CRS/Restaurants";
import ReservationStats from "../../CommonCRSModals/ReservationStats/ReservationStats";
import { commonTableHeaders } from "../../helpers";
import { useQueries } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { genericRequestErrorHandler } from "../../../../api";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import { DateTime } from "luxon";
import { CustomButton } from "../../../../components/CustomButton";

const initialFilters = {
  limit: 20,
  page: 1,
  ordering: "",
  discending: "",
  reservation_date: "",
  name: "",
  status: "",
  destination_name: "",
  created__gte: "",
  created__lte: "",
  external_reference__icontains: "",
  reference: "",
  source_entity: "",
  network_search: "",
  market_type: "",
};
const navbarSchema = yup.object().shape({
  name__icontains: yup
    .string()
    .min(2, "Name must be more than 1 characters")
    .max(100, "Name must be less than 100 characters"),
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const restaurantStyles = createUseStyles({
  Restaurant: {
    ...genericIndexesStyles.indexes.mobile(),
    gridTemplateRows: "auto auto auto  1fr",
  },
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tablecell: tableStyles.body.cell,
  actionCell: {
    ...tableStyles.body.cell,
    // display: "grid",
    gap: variables.half_gap,
  },
  datesCell: { ...tableStyles.body.cell, width: "9rem" },
  statusCell: { ...tableStyles.body.cell, width: "7rem" },
  [`@media ${variables.media.smallscreen}`]: {
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});
const Restaurants = () => {
  const classes = restaurantStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [reference, setReference] = useState(null);

  const tableHeaders = commonTableHeaders({
    Name: (val) => val.name,
    Tags: (val) => _.get(val, "tags", []).join(","),
    Adults: (val) => val.adults,
    Children: (val) => val.children || "0",
    Pax: (val) => val.pax,
    Destination: (val) => val.destination_name,
    "Reservation Date": (val) => val.reservation_date,
    "Market Type": (val) => val.market_type,
  });
  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const results = useQueries({
    queries: [
      {
        queryKey: ["restaurants", filters],
        queryFn: async () => {
          let correctPayload = { ...filters };
          if (filters.created__lte) {
            correctPayload["created__lte"] = DateTime.fromISO(
              filters.created__lte
            ).toISO();
          }
          if (filters.created__gte) {
            correctPayload["created__gte"] = DateTime.fromISO(
              filters.created__gte
            ).toISO();
          }
          if (filters.reservation_date) {
            // set the time to 00:00:00'
            correctPayload["reservation_date"] = DateTime.fromISO(
              filters.reservation_date
            )
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .toISO({ includeOffset: false, suppressMilliseconds: true });
          }
          return await fetchRestaurantReservations({
            params: correctPayload,
          });
        },
        enabled: !showDetailModal,
        onError: (error) => genericRequestErrorHandler(error),
      },
      {
        queryKey: ["restaurantsStats", filters],
        queryFn: async () => {
          let correctPayload = { ...filters };
          if (filters.created__lte) {
            correctPayload["created__lte"] = DateTime.fromISO(
              filters.created__lte
            ).toISO();
          }
          if (filters.created__gte) {
            correctPayload["created__gte"] = DateTime.fromISO(
              filters.created__gte
            ).toISO();
          }
          if (filters.reservation_date) {
            correctPayload["reservation_date"] = DateTime.fromISO(
              filters.reservation_date
            ).toISO();
          }
          return await getRestaurantReservationStats({
            params: correctPayload,
          });
        },
        enabled: !showDetailModal,
      },
    ],
  });
  return (
    <div id="RESListPage" className={classes.Restaurant}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
      />
      <HamburgerMenu />
      {!_.get(results, "1.isLoading", true) &&
        _.get(results, "1.data", null) && (
          <ReservationStats
            title="Reservation Stats"
            stats={_.get(results, "1.data.data.stats_per_currency")}
          />
        )}
      <ControlStrip
        limit={_.get(filters, "limit")}
        page={filters.page}
        count={_.get(results, "0.data.data.count")}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {_.get(results, "0.isLoading", false) ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                {Object.keys(tableHeaders).map((value, idx) => (
                  <th className={classes.headCell} key={idx}>
                    {value}
                  </th>
                ))}
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.get(results, "0.data.data.results", []).map((val, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.tablecell}>{idx + 1}</td>
                  {Object.entries(tableHeaders).map((entry, ridx) => {
                    var cell = null;
                    const fn = entry[1];
                    switch (entry[0].toLowerCase()) {
                      case "status / booking mode":
                        cell = fn(val) || "N/A";
                        return (
                          <td className={classes.statusCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      case "reservation date":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      case "created":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      default:
                        cell = fn(val) || "N/A";
                        break;
                    }
                    return (
                      <td className={classes.tablecell} key={ridx}>
                        {cell}
                      </td>
                    );
                  })}
                  <td className={classes.actionCell}>
                    <CustomButton
                      id={`detailRESButton_${idx}`}
                      appearance="ghost"
                      onClick={() => {
                        setShowDetailModal(true);
                        setReference(val.reference);
                      }}>
                      <strong>Details</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showDetailModal && (
        <DetailRestaurantModal
          onClose={() => setShowDetailModal(false)}
          reference={reference}
        />
      )}
    </div>
  );
};

export default Restaurants;
