import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  modalGenericStyles,
  tabBtnsStyles,
  tabsStyles,
  variables,
} from "../../../../variables";
import GenericBasicInfo from "../../../../components/cards/GenericBasicInfo";
import _ from "lodash";
import Loader from "../../../../components/Loader";
import { useState } from "react";
import PromoLandingPages from "../../WebSites/PromoLandingPages/ListPromoLandingPages";
import ContactInfoModal from "../../WebSites/ContactInfo/ListContactInfo";
import GenericSectionsModal from "../../WebSites/GenericSections/ListGenericSections";
import HotelPrioritiesModal from "../../WebSites/HotelPriorities/ListHotelPriorities";
import LandingPageModal from "../../WebSites/LandingPages/ListLandingPages";
import MainPromosModal from "../../WebSites/MainPromos/ListMainPromos";
import TermsAndConditionsModal from "../../WebSites/TermsAndConditions/ListTermsAndConditions";
import { retrieveWebsite } from "../../../../api/DistributionSite/webSites";
import RestaurantPromos from "../../WebSites/RestaurantPromos/ListRestaurantPromos";
import AboutUs from "../../WebSites/AboutUs/ListAboutUs";
import ListPages from "../../WebSites/ListPages/ListListPages";
import FAQModal from "../../WebSites/FAQ/ListFAQ";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";
import { DateTime } from "luxon";
import { CustomButton } from "../../../../components/CustomButton";
import TabStrip from "../../../../components/TabStrip";
import DistributionSitesLogsTab from "./DistributionSitesLogsTab";

const trueFalseMapping = {
  true: "Yes",
  false: "No",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailWebSiteModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  row: {
    display: "grid",
    width: `100%`,
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    maxWidth: "100%",
    // overflow: "auto",
  },
  tabs: {
    ...tabsStyles,
    gridTemplateColumns: "repeat(4, 1fr)",
    overflow: "auto",
    // justifyItems:""
  },
  tabBtns: tabBtnsStyles,

  [`@media ${variables.media.smallscreen}`]: {
    cardBody: {
      maxHeight: "55rem",
    },
    tabs: {
      gridTemplateColumns: "repeat(6, 5rem)",
    },
    tabBtns: {
      fontSize: "small",
    },
    row: {
      display: "grid",
      // maxWidth: "100%",

      gridTemplateColumns: "repeat(2, 1fr)",
      // gridTemplateColumns: "2fr 1fr",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    // modalCard: { width: "55rem" },
    tabs: {
      gridTemplateColumns: "repeat(6, 6rem)",
    },
  },
});
const DetailWebSiteModal = ({ webSiteId, onClose }) => {
  const classes = styles();

  // const [webSite, setWebSite] = useState({});
  const [activeTab, setActiveTab] = useState("basicInfo");

  const { data, isLoading: retrieveLoading } = useQuery({
    queryKey: ["DetailWebSiteModal"],
    queryFn: () =>
      retrieveWebsite({
        uid: webSiteId,
      }),
    onError: (error) => {
      genericRequestErrorHandler(error);
      onClose();
    },
  });

  const basicDataCpl = [
    ["Name ", "name"],

    ["Market Type ", "market_type"],
    [
      "Authorization Type ",
      null,
      _.get(data, "data.results.private_website", "") === true
        ? _.get(data, "data.auth_type", "")
        : "-",
    ],
    ["Api Key", "api_key"],
    ["Product Category ", "product_category_display"],

    ["Product Categories", "product_categories_display"],
    ["Marketplace", "marketplace"],
    ["Cloudfront Id", "cloudfront_id"],
    ["S3 Bucket", "s3_bucket"],
    ["Production URL", "production_url"],
    ["Status ", "status_display"],
  ];

  const creatorData = [
    [
      "Created",
      null,
      DateTime.fromISO(_.get(data, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];
  const paymentsData = [
    ["Payment Type ", "payment_type_display"],
    ["Payment Supplier ", "payment_supplier_display"],
  ];
  const servicesData = [
    [
      "Accommodation",
      null,
      _.get(data, "data.accommodation") === true ? "Yes" : "No",
    ],
    [
      "Activities ",
      null,
      _.get(data, "data.activities") === true ? "Yes" : "No",
    ],
    ["Addons ", null, _.get(trueFalseMapping, _.get(data, "data.addons"))],
    ["Flights ", null, _.get(trueFalseMapping, _.get(data, "data.flights"))],
    [
      "Packages ",
      null,
      _.get(trueFalseMapping, _.get(data, "data.marketplace")),
    ],
    [
      "Restaurants ",
      null,
      _.get(trueFalseMapping, _.get(data, "data.packages")),
    ],
    [
      "Transfers ",
      null,
      _.get(trueFalseMapping, _.get(data, "data.transfers")),
    ],
    ["Service Order ", null, _.get(data, "data.service_order", []).join(",")],
  ];

  const menuTabs = {
    basicInfo: "Basic Info",
    promoLandingPages: "Promo Landing Pages",
    aboutUs: "About Us",
    restaurantPromos: "Restaurant Promos",
    contactInfo: "Contact Info",
    genericSections: "Generic Sections",
    hotelPriorities: "Hotel Priorities",
    landingPages: "Landing Pages",
    mainPromos: "Main Promos",
    termsAndConditions: "Terms And Conditions",
    listPages: "List Pages",
    FAQ: "FAQ",
    logs: "Logs",
  };

  return (
    <div className={classes.DetailWebSiteModal}>
      {retrieveLoading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>Web Site Details</span>
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>Web Site Details</span>
          </div>
          <TabStrip
            tabs={Object.entries(menuTabs)}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <div className={classes.modalCardBody}>
            {activeTab === "basicInfo" && (
              <React.Fragment>
                <div className={classes.row}>
                  <div className={classes.cardBody}>
                    <GenericBasicInfo
                      header={"Basic Data"}
                      cardInfo={basicDataCpl}
                      objectData={_.get(data, "data", {})}
                    />
                  </div>
                  <div className={classes.cardBody}>
                    <GenericBasicInfo
                      header={"Creator Data"}
                      cardInfo={creatorData}
                      objectData={_.get(data, "data", {})}
                    />
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.cardBody}>
                    <GenericBasicInfo
                      header={"Payments Data"}
                      cardInfo={paymentsData}
                      objectData={_.get(data, "data", {})}
                    />
                  </div>
                  <div className={classes.cardBody}>
                    <GenericBasicInfo
                      header={"Services Data"}
                      cardInfo={servicesData}
                      objectData={_.get(data, "data", {})}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
            {activeTab === "promoLandingPages" && (
              <PromoLandingPages websiteId={_.get(data, "data.id")} />
            )}
            {activeTab === "aboutUs" && (
              <AboutUs
                sourceEntity={_.get(data, "source_entity")}
                websiteId={_.get(data, "data.id")}
              />
            )}
            {activeTab === "restaurantPromos" && (
              <RestaurantPromos websiteId={_.get(data, "data.id")} />
            )}
            {activeTab === "contactInfo" && (
              <ContactInfoModal websiteId={_.get(data, "data.id")} />
            )}
            {activeTab === "genericSections" && (
              <GenericSectionsModal websiteId={_.get(data, "data.id")} />
            )}
            {activeTab === "hotelPriorities" && (
              <HotelPrioritiesModal
                websiteId={_.get(data, "data.id")}
                sourceEntity={_.get(data, "data.source_entity", "")}
                websiteApiKey={_.get(data, "data.api_key", "")}
              />
            )}
            {activeTab === "landingPages" && (
              <LandingPageModal websiteId={_.get(data, "data.id")} />
            )}
            {activeTab === "mainPromos" && (
              <MainPromosModal
                sourceEntity={_.get(data, "data.source_entity", "")}
                websiteId={_.get(data, "data.id")}
              />
            )}
            {activeTab === "termsAndConditions" && (
              <TermsAndConditionsModal websiteId={_.get(data, "data.id")} />
            )}
            {activeTab === "listPages" && (
              <ListPages websiteId={_.get(data, "data.id")} />
            )}
            {activeTab === "FAQ" && (
              <FAQModal websiteId={_.get(data, "data.id")} />
            )}
            {activeTab === "logs" && <DistributionSitesLogsTab />}
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Close</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
DetailWebSiteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default DetailWebSiteModal;
