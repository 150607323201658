import { createUseStyles } from "react-jss";
import QuilEditor from "../../../../../components/QuilEditor";
import PropTypes from "prop-types";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";

const statusOptions = [
  ["", "-----"],
  ["AC", "Active"],
  ["IN", "Inactive"],
  ["CA", "Candidate"],
];

const notesandStatusStyles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  NotesandStatus: {
    ...cardStyles.card,
    // display: "grid",
    gridGap: variables.normal_gap,
  },
  card: cardStyles.card,
  cardHeader: { ...cardStyles.header, fontSize: "large" },
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    gridTemplateColumns: "1fr",
  },
  textarea: {
    ...formInputGroup,
    height: "12rem",
    gap: variables.normal_gap,
    "& label": {
      fontSize: "small",
    },
  },
  inputGroupOneLine: {
    ...formInputGroup,
    gridColumn: "2 span",
    textAlign: "left",
    "& input, select": { width: "100%" },
  },
});

const NotesandStatus = ({ text, loading, form, setForm }) => {
  const classes = notesandStatusStyles();
  return (
    <div className={classes.NotesandStatus}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>{text}</div>
        <div className={classes.cardBody}>
          <div className={classes.textarea}>
            <QuilEditor
              name="description"
              value={form.description}
              onChange={(value) => {
                let newForm = {
                  ...form,
                  description: value,
                };
                setForm(newForm);
              }}
            />
          </div>
          <div className={classes.inputGroupOneLine}>
            <label>Status</label>
            <select
              value={form.status}
              onChange={(e) =>
                setForm((p) => ({ ...p, status: e.target.value }))
              }>
              {statusOptions.map(([value, label], idx) => (
                <option key={idx} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};
NotesandStatus.propTypes = {
  text: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
};
export default NotesandStatus;
