import React, { useState } from "react";
import _ from "lodash";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Navbar from "./navbar";
import ControlStrip from "../../../../components/ControlStrip";
import Loader from "../../../../components/Loader";
import { fetchGuestsCRM } from "../../../../api/CRM/guestCRM/guestCRM";
import DetailGuestCRMModal from "../guestCRMModals/DetailGuestCRMModal";
import { handleApplyFilters, medWeekdayDatetimer } from "../../../../helpers";
import { useQuery } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import { genericRequestErrorHandler } from "../../../../api";
import { CustomButton } from "../../../../components/CustomButton";

const initialFilters = {
  page_size: 20,
  page: 1,
  ordering: "",
  discending: "",
  email__icontains: "",
  ext_user_id__icontains: "",
  ext_username__icontains: "",
  first_name__icontains: "",
  last_name__icontains: "",
  gender: "",
  in_free_period: "",
  sourse_entity: "",
  has_sub: "",
  created__gte: "",
  created__lte: "",
  ext_user_id: "",
};

const navbarSchema = yup.object().shape({
  first_name__icontains: yup
    .string()
    .min(2, "First name must be more than 2 characters")
    .max(100, "First name: Must be less than 100 characters"),
  last_name__icontains: yup
    .string()
    .min(2, "Last name must be more than 2 characters")
    .max(100, "Last name must be less than 100 characters"),
  email__icontains: yup.string().email("Not a valid email"),
  // sourse_entity: yup
  //   .string()
  //   .matches(/^[0-9_a-zA-Z]+$/, "Invalid characters at Source Entity"),
  created: yup.date().max(new Date(), "Date cannot be in the future"),
});

const genderMapping = {
  MA: "Male",
  FE: "Female",
  OTH: "Other",
};

const guestCRMStyles = createUseStyles({
  GuestCRM: genericIndexesStyles.indexes.mobile(),
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  tablecell: tableStyles.body.cell,
  bodyRow: tableStyles.body.row,
  actionCell: genericIndexesStyles.actionCell.mobile,
  datesCell: { ...tableStyles.body.cell, minWidth: "9rem", width: "9rem" },
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      display: "grid",
      paddingTop: `calc(${variables.normal_gap}*1.1) !important`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0!important`,
    },
  },
});
const GuestCRM = () => {
  const classes = guestCRMStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [guestCRM, setGuestCRM] = useState(null);

  const tableHeaders = {
    "External User Id": (val) => val.ext_user_id,
    "First Name": (val) => val.first_name,
    "Last Name": (val) => val.last_name,
    "Source Entity": (val) => val.source_entity,
    Email: (val) => val.email,
    Gender: (val) => _.get(genderMapping, _.get(val, "gender")),
    "In Free Period": (val) =>
      _.get(val, "in_free_period") === true ? "Yes" : "No",
    "Is Complete": (val) =>
      _.get(val, "user_is_complete") === true ? "Yes" : "No",
    "Ext Username": (val) => val.ext_username,
    "Ext User Id": (val) => val.ext_user_id,
    Created: (val) => val.created,
    Status: (val) => val.status_display,
  };

  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data, isLoading: loading } = useQuery({
    queryKey: ["GuestCRM", filters],
    queryFn: async () => await fetchGuestsCRM({ params: filters }),
    onError: (error) => genericRequestErrorHandler(error),
    enabled: !showDetailModal && !showDetailModal,
  });
  return (
    <div className={classes.GuestCRM}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
      />
      <ControlStrip
        limit={filters.page_size}
        page={filters.page}
        count={_.get(data, "data.count", 0)}
        onChangeLimit={(page_size) =>
          setFilters({ ...filters, page_size, page: 1 })
        }
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      <HamburgerMenu />
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                {Object.keys(tableHeaders).map((value, idx) => (
                  <th className={classes.headCell} key={idx}>
                    {value}
                  </th>
                ))}
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.get(data, "data.results", []).map((val, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.tablecell}>{idx + 1}</td>
                  {Object.entries(tableHeaders).map((entry, ridx) => {
                    var cell = null;
                    const fn = entry[1];
                    switch (entry[0].toLowerCase()) {
                      case "created":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      default:
                        cell = fn(val) || "N/A";
                        break;
                    }
                    return (
                      <td className={classes.tablecell} key={ridx}>
                        {cell}
                      </td>
                    );
                  })}
                  <td className={classes.actionCell}>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        setGuestCRM(val.uuid);
                        setShowDetailModal(true);
                      }}>
                      <strong>Details</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showDetailModal && (
        <DetailGuestCRMModal
          uuid={guestCRM}
          onClose={() => setShowDetailModal(false)}
        />
      )}
    </div>
  );
};
export default GuestCRM;
