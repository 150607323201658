import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../../../components/cards/GenericBasicInfo";
import { genericRequestErrorHandler } from "../../../../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { deleteCarRentalVendor } from "../../../../../../api/Vendor/CarRental";
import { DateTime } from "luxon";
import Loader from "../../../../../../components/Loader";
import { CustomButton } from "../../../../../../components/CustomButton";

const statusOptions = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteCarRentalVendorModal: modalGenericStyles.modal,
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content  1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
});

const DeleteCarRentalVendorModal = ({ deletedId, data, onClose }) => {
  const classes = styles();
  const queryClient = useQueryClient();

  const basicDataCpl = [
    ["Id", "id"],
    ["Market_type", "market_type"],
    ["Member", "member"],
    [
      "CXL Strategy Daysahead",
      null,
      _.get(data, "cxl_strategy_daysahead", []) === 0
        ? "0"
        : _.get(data, "cxl_strategy_daysahead"),
    ],
    [
      "Created",
      null,
      DateTime.fromISO(_.get(data, "created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
    ["Status", null, _.get(statusOptions, _.get(data, "status", "") || "N/A")],
  ];

  const DeleteCarVendorMutation = useMutation(deleteCarRentalVendor, {
    onSuccess: () => {
      toast.success("Car Rental Vendor Deleted");
      onClose();
      queryClient.invalidateQueries("CarRentalVendors");
    },
    onError: (error) => genericRequestErrorHandler(error),
  });
  const onDelete = async (id) => {
    await DeleteCarVendorMutation.mutateAsync({ id });
  };
  return (
    <div
      id="DeleteCarRentalVendorModal"
      className={classes.DeleteCarRentalVendorModal}>
      <React.Fragment>
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>DELETE Car Rental Vendor </span>
          </div>
          {DeleteCarVendorMutation.isLoading ? (
            <div className={classes.modalCardBody}>
              {/* {DeleteCarVendorMutation.isLoading && <Loader onTop={true} />} */}
              <Loader />
            </div>
          ) : (
            <div className={classes.modalCardBody}>
              <div className={classes.card}>
                <div className={classes.cardBody}>
                  <GenericBasicInfo
                    header={"Basic Data"}
                    cardInfo={basicDataCpl}
                    objectData={data}
                  />
                </div>
              </div>
            </div>
          )}
          <div className={classes.cardActions}>
            <CustomButton
              id="DeleteCarRentalVendorModalCloseButton"
              appearance="ghost"
              onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="DeleteCarRentalVendorModalConfirmButton"
              appearance="primary"
              onClick={() => {
                onDelete(deletedId);
              }}>
              <strong>DELETE</strong>
            </CustomButton>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
DeleteCarRentalVendorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  deletedId: PropTypes.number.isRequired,
};
export default DeleteCarRentalVendorModal;
