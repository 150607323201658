import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { cardStyles, VerticalTableStyles, variables } from "../../../variables";
import { createUseStyles } from "react-jss";
import { CustomButton } from "../../CustomButton";

const styles = createUseStyles({
  ...cardStyles,
  GenericBasicInfo: ({ costumeStyles }) => {
    return {
      ...cardStyles.card,
      // justifySelf: "left",
      ..._.get(costumeStyles, "GenericBasicInfo", {}),
    };
  },
  header: ({ costumeStyles, actions }) => {
    return {
      ...cardStyles.header,
      gridTemplateColumns: actions ? "1fr max-content" : "1fr",

      ..._.get(costumeStyles, "header", {}),
    };
  },
  body: ({ costumeStyles }) => {
    return {
      display: "grid",
      // overflow: "auto",
      justifyContent: "center",
      alignItems: "center",
      ..._.get(costumeStyles, "body", {}),
    };
  },
  basicDatatable: ({ costumeStyles }) => {
    return {
      ...VerticalTableStyles.table,
      display: "grid",
      overflow: "auto",
      gridTemplateColumns: "auto max-content",
      padding: variables.normal_gap,
      ..._.get(costumeStyles, "basicDatatable", {}),
    };
  },
  actions: ({ costumeStyles }) => {
    return {
      ...cardStyles.actions,
      ..._.get(costumeStyles, "actions", {}),
    };
  },
  verticalHead: ({ costumeStyles }) => {
    return {
      ...VerticalTableStyles.head.row,
      width: "max-content",
      fontWeight: "bold",
      ..._.get(costumeStyles, "headRow", {}),
    };
  },
  verticalCell: ({ costumeStyles }) => {
    return {
      ...VerticalTableStyles.body.cell,
      textAlign: "start",
      borderBottom: `2px solid ${variables.colors.border.dark}`,
      maxWidth: "100%",
      // height: "2.5rem",
      height: "auto",
      // whiteSpace: "pre-wrap",
      // overflow: "auto",

      ..._.get(costumeStyles, "cell", {}),
    };
  },
  [`@media ${variables.media.smallscreen}`]: {},
  [`@media ${variables.media.bigscreen}`]: {},
});
const GenericBasicInfo = ({
  header,
  cardInfo,
  objectData,
  costumeStyles,
  actions,
}) => {
  const classes = styles({ actions: actions.length, costumeStyles });

  return (
    <div className={classes.GenericBasicInfo}>
      <div className={classes.header}>
        <span>{header}</span>
        {actions.length > 0 && (
          <div className={classes.actions}>
            {actions.map((action, idx) => (
              <CustomButton
                id={`GenericTableButton_${idx}`}
                className={classes.cardAction}
                appearance="ghost"
                key={idx}
                onClick={() => action[1]()}>
                {action[0]}
              </CustomButton>
            ))}
          </div>
        )}
      </div>
      <div className={classes.body}>
        <table className={classes.basicDatatable}>
          <tbody>
            {cardInfo.map((info, ridx) => {
              return (
                <tr key={ridx}>
                  <td className={classes.verticalHead}>{info[0]}</td>
                  <td className={classes.verticalCell}>
                    {_.get(objectData, info[1]) || info[2] || "N/A"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
GenericBasicInfo.defaultProps = {
  actions: [],
  costumeStyles: {},
};
GenericBasicInfo.propTypes = {
  header: PropTypes.string.isRequired,
  cardInfo: PropTypes.array.isRequired,
  actions: PropTypes.array,
  objectData: PropTypes.object.isRequired,
  costumeStyles: PropTypes.object,
};

export default GenericBasicInfo;
