import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import _ from "lodash";
const styles = createUseStyles({
  GenericButton: (props) => ({
    display: "grid",
    ..._.get(props, "Button", {}),
    width: "100%",
  }),
  button: (props) => ({
    display: "grid",
    fontWeight: props.isBold ? "bold" : "normal",
    ..._.get(props, "container", {}),
    cursor: "pointer",
  }),
});
export const Button = ({ text, isBold, isDisabled, onClick, customStyles }) => {
  const classes = styles({ isBold, customStyles });
  return (
    <div className={classes.GenericButton}>
      <button
        className={classes.button}
        onClick={onClick}
        disabled={isDisabled}>
        {text}
      </button>
    </div>
  );
};
Button.defaultProps = {
  text: "",
  isBold: false,
  isDisabled: false,
  customStyles: {},
};
Button.propTypes = {
  text: PropTypes.string.isRequired,
  isBold: PropTypes.bool,
  isDisabled: PropTypes.bool,
  customStyles: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};
