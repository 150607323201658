import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { cardStyles, tableStyles, variables } from "../../../../../variables";
import _ from "lodash";
import { medWeekdayDatetimer } from "../../../../../helpers";

export const srvTypeMapping = {
  acc: "Accommodation",
  act: "Activity",
  car: "Car Rental",
  coo: "Coordinator",
  fer: "Ferry",
  fl: "Flight",
  gen: "General Service",
  mi: "Addons",
  tra: "Train",
  trf: "Transfer",
  rest: "Restaurant",
};

const statusMapping = {
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

/**
 * @param {Array} vendors
 * @param {Object} apiConfigs
 * @returns {Array} vendorIds
 * @description
 * This function checks if the vendor has api configs created.
 * If not, it will return the vendor ids.
 */
const getUncreatedVendors = (vendors, apiConfigs) => {
  const vendorIds = [];
  if (vendors.length === 0) {
    return [];
  }
  const allApiConfigs = _.flatten(Object.values(apiConfigs));
  vendors.forEach((vendor) => {
    if (_.get(vendor, "config_uid", []).length === 0) {
      vendorIds.push(vendor.id);
      return;
    }
    const exist = allApiConfigs.filter((config) =>
      vendor.config_uid.includes(config.uid)
    );
    if (exist.length === 0) {
      vendorIds.push(vendor.id);
    }
  });
  return vendorIds;
};

const styles = createUseStyles({
  ...cardStyles,
  VendorsSection: { ...cardStyles.card, with: "100%" },
  header: {
    ...cardStyles.header,
    padding: variables.normal_gap,
    fontSize: "1.2rem",
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  tableContainer: {
    display: "block",
    maxHeight: "20rem",
    overflow: "auto",
    width: "100%",
  },
  table: {
    width: "100%",
  },
  thead: {
    position: "sticky",
    top: 0,
  },
  emptyVendors: {
    color: "red",
    textAlign: "center",
    minHeight: "10rem",
    lineHeight: "10rem",
  },
  red: {
    color: "red",
  },
});
const VendorsSection = ({ vendors, apiConfigs }) => {
  const classes = styles();

  const uncreatedVendors = getUncreatedVendors(vendors, apiConfigs);

  return (
    <div className={classes.VendorsSection}>
      <div className={classes.header}>
        <span>
          Vendors{" "}
          {uncreatedVendors.length > 0 && (
            <span className={classes.red}>
              {"(There are uncreated api configs)"}
            </span>
          )}
        </span>
      </div>
      <div className={classes.tableContainer}>
        {vendors.length === 0 ? (
          <div className={classes.emptyVendors}>
            <strong>No Vendors</strong>
          </div>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Vendor</th>
                <th className={classes.headCell}>Service Type</th>
                <th className={classes.headCell}>Name</th>
                <th className={classes.headCell}>Market Type</th>
                <th className={classes.headCell}>Status</th>
                <th className={classes.headCell}>Config Created</th>
                <th className={classes.headCell}>Created</th>
              </tr>
            </thead>
            <tbody>
              {vendors.map((vendor, idx) => (
                <tr key={vendor.id}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  <td className={classes.tableCell}>
                    {vendor.vendor ? vendor.vendor.split("__")[1] : "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {vendor.vendor
                      ? _.get(
                          srvTypeMapping,
                          _.get(vendor, "vendor").split("__")[0]
                        ) || "N/A"
                      : "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(vendor, "vendor_data.name") || "N/A"}
                  </td>
                  <td className={classes.tableCell}>{vendor.market_type}</td>
                  <td className={classes.tableCell}>
                    {_.get(statusMapping, vendor.status) || "N/A"}
                  </td>
                  <td className={classes.tableCell}>
                    {!uncreatedVendors.includes(vendor.id) ? (
                      "Yes"
                    ) : (
                      <span className={classes.red}>{"No"}</span>
                    )}
                  </td>
                  <td className={classes.tableCell}>
                    {_.get(vendor, "created")
                      ? medWeekdayDatetimer(_.get(vendor, "created"))
                      : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
VendorsSection.propTypes = {
  vendors: PropTypes.array.isRequired,
};
export default VendorsSection;
