import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import {
  cloneActivity,
  fetchActAPIConfigurations,
} from "../../../api/ActivitiesEngine/APIConfigurations";
import ControlStrip from "../../../components/ControlStrip";
import Loader from "../../../components/Loader";
import { handleApplyFilters, medWeekdayDatetimer } from "../../../helpers";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../variables";
import Navbar from "./Navbar";
import DetailActivitiesAPIConfigurationModal from "../ActivitiesEngineModals/DetailActivitiesAPIConfigurationModal";
import EditActivitiesAPIConfigurationModal from "../ActivitiesEngineModals/EditActivitiesAPIConfigurationModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HamburgerMenu from "../../../components/HamburgerMenu";
import { genericRequestErrorHandler } from "../../../api";
import { useListPageSidebar } from "../../../hooks";
import * as yup from "yup";
import DeleteActivitiesAPIConfigurationModal from "../ActivitiesEngineModals/DeleteActivitiesAPIConfigurationModal";
import { activitiesTrueFalseMapping } from "../common";
import { CustomButton } from "../../../components/CustomButton";
import VerifyModal from "../../../components/VerifyModal";
import ReservationsStatusTag from "../../../components/Tags/ReservationsStatusTag";

const initialFilters = {
  page_size: 20,
  page: 1,
  ordering: "",
  discending: "",
  name__icontains: "",
  created__gte: "",
  source_entity: "",
  market_type: "",
  created__lte: "",
  country_code: "",
  is_global: "",
  is_own: "",
};

const APIConfigurationStyles = createUseStyles({
  APIConfiguration: genericIndexesStyles.indexes.mobile,
  tableContainer: genericIndexesStyles.tableContainer.mobile,
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile(),
  datesCell: { ...tableStyles.body.cell, minWidth: "9rem", width: "9rem" },
  checkBox: tableStyles.checkBox,
  [`@media ${variables.media.smallscreen}`]: {
    // actionCell: {
    //   gridTemplateColumns: "repeat(2,1fr) ",
    //   padding: `calc(${variables.normal_gap} * 2.5) 0!important`,
    // },
    actionCell: genericIndexesStyles.actionCell.smallscreen(),
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const navbarSchema = yup.object().shape({
  name__icontains: yup
    .string()
    .min(2, "Name must be more than 1 characters")
    .max(100, "Name: Must be less than 100 characters"),
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const ActivitiesAPIConfigurations = () => {
  const classes = APIConfigurationStyles();

  const queryClient = useQueryClient();
  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [apiConfigurationUid, setApiConfigurationUid] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [activity, setActivity] = useState(null);

  const tableHeaders = {
    Name: (val) => _.capitalize(val.name),
    "Source Entity": (val) => val.source_entity,
    "Is Global": (val) =>
      _.get(activitiesTrueFalseMapping, _.get(val, "is_global")),
    "is Own": (val) => _.get(activitiesTrueFalseMapping, _.get(val, "is_own")),
    "Grand Type": (val) => val.grand_type,
    "X Musement Application": (val) => val.x_musement_application,
    "Market Type": (val) => val.market_type,
    Created: (val) => val.created,
    Status: (val) => <ReservationsStatusTag status={val.status} />,
  };

  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["ActivitiesAPIConfigurations", filters],
    queryFn: () => {
      return fetchActAPIConfigurations({
        params: { ...filters, page_size: filters.page_size },
      });
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const cloneActivityApiMutation = useMutation({
    mutationFn: (uid) => {
      return cloneActivity({ uid });
    },
    onSuccess: () => {
      toast.success("Cloned Activity Api Configuration added");
      setShowVerifyModal(false);
      queryClient.invalidateQueries("ActivitiesAPIConfigurations");
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });
  const onClone = (uid) => {
    setApiConfigurationUid(uid);
  };

  return (
    <div
      id="ACTEngineAPIConfigurationsListPage"
      className={classes.APIConfiguration}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setShowEditModal(true);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.page_size}
        page={filters.page}
        count={_.get(data, "data.count", 0)}
        onChangeLimit={(page_size) =>
          setFilters({ ...filters, page_size, page: 1 })
        }
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th key={idx} className={classes.headCell}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.get(data, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "created":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      return (
                        <td className={classes.datesCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    default:
                      cell = fn(val) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.tableCell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setApiConfigurationUid(val.uid);
                      setShowDetailModal(true);
                    }}>
                    <strong>Details</strong>
                  </CustomButton>
                  <CustomButton
                    id={`EditACTEngineButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setApiConfigurationUid(val.uid);
                      setShowEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      onClone(val.uid);
                      setShowVerifyModal(true);
                    }}>
                    <strong>Clone</strong>
                  </CustomButton>
                  <CustomButton
                    id={`DeleteACTEngineButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setActivity(val);
                      setApiConfigurationUid(val.uid);
                      setShowDeleteModal(true);
                    }}>
                    <strong>Delete</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDetailModal && (
        <DetailActivitiesAPIConfigurationModal
          uid={apiConfigurationUid}
          onClose={() => {
            setShowDetailModal(false);
            setApiConfigurationUid(null);
          }}
        />
      )}
      {showEditModal && (
        <EditActivitiesAPIConfigurationModal
          apiConfigurationUid={apiConfigurationUid}
          onClose={() => {
            setShowEditModal(false);
            setApiConfigurationUid(null);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteActivitiesAPIConfigurationModal
          data={activity}
          deletedUid={apiConfigurationUid}
          onClose={() => {
            setShowDeleteModal(false);
          }}
        />
      )}
      {showVerifyModal && (
        <VerifyModal
          // title=""
          // message=""
          isLoading={cloneActivityApiMutation.isLoading}
          onConfirm={() => {
            cloneActivityApiMutation.mutate(apiConfigurationUid);
          }}
          onClose={() => setShowVerifyModal(false)}
        />
      )}
    </div>
  );
};
export default ActivitiesAPIConfigurations;
