import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  modalGenericStyles,
  tableStyles,
  variables,
} from "../../../../../variables";
import {
  createAppUserGroup,
  deleteAppUserGroup,
  getAppUserGroups,
  newGetGroups,
  patchAppUserGroup,
} from "../../../../../api/CRM/Users/Users";
import { genericRequestErrorHandler } from "../../../../../api";
import _ from "lodash";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { CustomButton } from "../../../../../components/CustomButton";
import Loader from "../../../../../components/Loader";

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditPermission: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: variables.normal_gap,
  },
  availablePermissions: {
    // display: "grid",
    padding: variables.normal_gap,
  },
  availablePermissionContainer: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    padding: variables.half_gap,
    borderBottom: `2px solid ${variables.colors.base}`,
  },
  avPermissionlabel: {
    display: "grid",
    alignContent: "center",
    gap: variables.normal_gap,
  },
  avPermissionAdd: {
    ...cardStyles.actions,
  },
  userPermissions: {
    display: "grid",
    padding: variables.normal_gap,
    gap: variables.half_gap,
    gridAutoFlow: "row",
  },
  userPermission: {
    display: "grid",
    gridTemplateColumns: "5rem 1fr max-content",
    padding: variables.normal_gap,
    alignItems: "center",
    gap: variables.normal_gap,
    borderBottom: `2px solid ${variables.colors.base}`,
  },
  AppUserGroup: {
    display: "grid",
    gridAutoFlow: "column",
    gap: variables.normal_gap,
  },
  subGroupfieldName: { display: "grid", paddingLeft: variables.double_gap },
  checkBox: {
    ...tableStyles.checkBox,
  },
  cardActions: cardStyles.actions,
  [`@media ${variables.media.smallscreen}`]: {
    cardBody: {
      gridTemplateColumns: "1fr 2fr",
    },
  },
});

const EditPermission = ({ user, onClose }) => {
  const classes = styles();
  const queryClient = useQueryClient();
  const queries = useQueries({
    queries: [
      {
        queryKey: ["appUserGroups", user.username],
        queryFn: () => getAppUserGroups({ app_user: user.id }),
        onError: (error) => genericRequestErrorHandler(error),
      },
      {
        queryKey: "groups",
        queryFn: newGetGroups,
        onError: (error) => genericRequestErrorHandler(error),
      },
    ],
  });

  const appUserGroups = queries[0].data?.data?.results ?? [];
  const shortedAppUserGroups = _.sortBy(appUserGroups, (v) =>
    _.upperCase(v.group.name.split(".")?.[0])
  );
  const groupedAppUserGroups = _.groupBy(shortedAppUserGroups, (v) =>
    _.upperCase(v.group.name.split(".")?.[0])
  );

  const queryLoading = queries[0].isLoading;
  const permissionData = queries[1].data;
  const permissionLoading = queries[1].isLoading;

  const permissions = _.get(permissionData, "data.results", []).sort((g1, g2) =>
    g1.order < g2.order ? 1 : g1.order > g2.order ? -1 : 0
  );

  const addGroupMutation = useMutation({
    mutationFn: ({ permissionId, appUserID }) => {
      const defaultValues = {
        view: true,
        edit: true,
        execute: true,
      };

      return createAppUserGroup({
        group: permissionId,
        app_user: appUserID,
        ...defaultValues,
      });
    },
    onSuccess: () => {
      toast.success("New permission added ");
      queryClient.invalidateQueries(["appUserGroups", user.username]);
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const removeGroupMutation = useMutation({
    mutationFn: (id) => deleteAppUserGroup(id),
    onSuccess: () => {
      toast.success("Permission Removed");
      queryClient.invalidateQueries(["appUserGroups", user.username]);
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const mutateAppUserGroup = useMutation({
    mutationFn: ({ id, field, value }) =>
      patchAppUserGroup(id, { [field]: value }),
    onSuccess: () => {
      toast.success("Permissions updated");
      queryClient.invalidateQueries("appUserGroup");
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const booleanFields = ["view", "edit", "execute"];

  const isLoading =
    queryLoading ||
    permissionLoading ||
    addGroupMutation.isLoading ||
    removeGroupMutation.isLoading ||
    mutateAppUserGroup.isLoading;

  const unusedPermissions = permissions.filter(
    (permission) =>
      !appUserGroups.some(
        (appUserGroup) => appUserGroup.group.id === permission.id
      )
  );
  const sortedUnusedPermissions = unusedPermissions.sort((g1, g2) =>
    g1.name > g2.name ? 1 : g1.name < g2.name ? -1 : 0
  );

  const groupedUnusedPermissions = _.groupBy(sortedUnusedPermissions, (v) =>
    _.upperCase(v.name.split(".")?.[0])
  );

  return (
    <div id="EditPermission" className={classes.EditPermission}>
      <div className={classes.modalCard}>
        {isLoading ? (
          <React.Fragment>
            <div className={classes.modalCardHeader}>Edit Permission</div>
            <div className={classes.modalCardBody}>
              <Loader />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={classes.modalCardHeader}>Edit Permission</div>
            <div className={classes.modalCardBody}>
              <div className={classes.card}>
                <div className={classes.cardBody}>
                  <div className={classes.availablePermissions}>
                    <div className={classes.cardHeader}>
                      <strong>Available Permissions</strong>
                    </div>

                    {Object.values(groupedUnusedPermissions).map(
                      (permissionGroup, actIdx) => {
                        var mainGroup;
                        var subGroups = [];
                        permissionGroup.forEach((group) => {
                          if (group.name.split(".").length === 2) {
                            subGroups.push(group);
                          } else {
                            mainGroup = group;
                          }
                        });

                        if (mainGroup) {
                          return (
                            <div
                              className={classes.availablePermissionContainer}
                              key={`deact_${actIdx}`}>
                              <div className={classes.avPermissionlabel}>
                                <strong>
                                  {_.upperCase(mainGroup.name) || "N/A"}
                                </strong>
                              </div>
                              <CustomButton
                                id={`add_permission_${actIdx}`}
                                appearance="primary"
                                onClick={() =>
                                  addGroupMutation.mutate({
                                    permissionId: mainGroup.id,
                                    appUserID: user.id,
                                  })
                                }>
                                Add
                              </CustomButton>
                            </div>
                          );
                        }

                        return _.flatten(
                          subGroups.map((subGroup, idx) => {
                            return (
                              <div
                                className={classes.availablePermissionContainer}
                                key={`deact_${idx}`}>
                                <div className={classes.avPermissionlabel}>
                                  <strong>
                                    {_.upperCase(subGroup.name) || "N/A"}
                                  </strong>
                                </div>
                                <CustomButton
                                  id={`add_permission_${actIdx}`}
                                  appearance="primary"
                                  onClick={() =>
                                    addGroupMutation.mutate({
                                      permissionId: subGroup.id,
                                      appUserID: user.id,
                                    })
                                  }>
                                  Add
                                </CustomButton>
                              </div>
                            );
                          })
                        );
                      }
                    )}

                    {/* {sortedUnusedPermissions.map((permission, actIdx) => (
                      <div
                        className={classes.availablePermissionContainer}
                        key={`deact_${actIdx}`}>
                        <div className={classes.avPermissionlabel}>
                          <strong>
                            {_.upperCase(permission.name) || "N/A"}
                          </strong>
                        </div>
                        <div className={classes.avPermissionAdd}>
                          <CustomButton
                            id={`add_permission_${actIdx}`}
                            appearance="primary"
                            onClick={() =>
                              addGroupMutation.mutate({
                                permissionId: permission.id,
                                appUserID: user.id,
                              })
                            }>
                            Add
                          </CustomButton>
                        </div>
                      </div>
                    ))} */}
                  </div>
                  <div className={classes.userPermissions}>
                    <div className={classes.cardHeader}>
                      <strong>Enabled Permissions</strong>
                    </div>
                    {Object.values(groupedAppUserGroups).map(
                      (groupApUsrGR, actIdx) => {
                        var mainGroup;
                        var subGroups = [];
                        groupApUsrGR.forEach((group) => {
                          if (group.group.name.split(".").length === 2) {
                            subGroups.push(group);
                          } else {
                            mainGroup = group;
                          }
                        });

                        var result = [
                          <div
                            className={classes.userPermission}
                            key={`act_${actIdx}`}>
                            {subGroups.length === 0 ? (
                              <React.Fragment>
                                <CustomButton
                                  id={`remove_group_permission_${actIdx}`}
                                  appearance="ghost"
                                  onClick={() =>
                                    removeGroupMutation.mutate(mainGroup.id)
                                  }>
                                  Remove
                                </CustomButton>
                              </React.Fragment>
                            ) : (
                              <div />
                            )}
                            <div>
                              <strong>
                                {_.upperCase(
                                  mainGroup.group.name.split(".")[0]
                                )}
                              </strong>
                            </div>
                            {!queryLoading && (
                              <div className={classes.AppUserGroup}>
                                {booleanFields.map((field, index) => (
                                  <div
                                    key={`checkboxWrapper_${index}`}
                                    className={classes.checkbox}>
                                    <label htmlFor={`checkbox_${index}`}>
                                      {_.startCase(field)}
                                    </label>
                                    <input
                                      className={classes.checkBox}
                                      type="checkbox"
                                      id={`checkbox_group_${index}`}
                                      checked={mainGroup[field]}
                                      onChange={() =>
                                        mutateAppUserGroup.mutate({
                                          id: mainGroup.id,
                                          field: field,
                                          value: !mainGroup[field],
                                        })
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>,
                        ];
                        subGroups.forEach((subGroup, idx) => {
                          result.push(
                            <div
                              className={classes.userPermission}
                              key={`act_${actIdx}_${idx}`}>
                              <CustomButton
                                id={`remove_subgroup_permission_${actIdx}_${idx}`}
                                className="Button"
                                appearance="ghost"
                                onClick={() =>
                                  removeGroupMutation.mutate(subGroup.id)
                                }>
                                Remove
                              </CustomButton>
                              <div className={classes.subGroupfieldName}>
                                <strong>
                                  {_.upperCase(
                                    subGroup.group.name.split(".")[1]
                                  )}
                                </strong>
                              </div>
                              {!queryLoading && (
                                <div className={classes.AppUserGroup}>
                                  {booleanFields.map((field, index) => (
                                    <div
                                      key={`checkboxWrapper_${index}`}
                                      className={classes.checkbox}>
                                      <label htmlFor={`checkbox_${index}`}>
                                        {_.startCase(field)}
                                      </label>
                                      <input
                                        id={`checkbox_subgroup_${index}`}
                                        className={classes.checkBox}
                                        type="checkbox"
                                        checked={subGroup[field]}
                                        onChange={() =>
                                          mutateAppUserGroup.mutate({
                                            id: mainGroup.id,
                                            field: field,
                                            value: !mainGroup[field],
                                          })
                                        }
                                      />
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          );
                        });
                        return _.flatten(result);
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.modalCardActions}>
              <CustomButton
                id="cancelEditPermissionButton"
                appearance="ghost"
                onClick={onClose}>
                <strong>Cancel</strong>
              </CustomButton>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

EditPermission.propTypes = {
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditPermission;
