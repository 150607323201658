import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchFlights({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/vendors/`, {
    params,
  });
}

export function retrieveVendorFlight({ id }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/vendors/${id}/`);
}

export function patchFlight({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crm/vendors/${id}/`,
    payload
  );
}

export function createNewFlight({ payload }) {
  return loggingAxios.post(`${VALAQUENTA_API_URL}/crm/vendors/`, payload);
}

export function deleteVendorFlight({ id }) {
  return loggingAxios.delete(`${VALAQUENTA_API_URL}/crm/vendors/${id}/`);
}
