import axios from "axios";

class AuthorizedAxios {
  constructor() {
    this.instance = axios.create();
    this.jwt = null;

    // Add an interceptor for requests
    this.setLoggingRequestInterceptor();
  }

  setJWT(jwt) {
    this.jwt = jwt;
  }

  setLoggingRequestInterceptor() {
    if (!this.jwt) {
      return;
    }

    // Add an interceptor for requests
    this.instance.interceptors.request.use((request) => {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${this.jwt}`,
      };
      return request;
    });
  }

  // Proxy methods for common HTTP methods
  get(url, config) {
    return this.instance.get(url, config);
  }

  post(url, data, config) {
    return this.instance.post(url, data, config);
  }

  put(url, data, config) {
    return this.instance.put(url, data, config);
  }

  patch(url, data, config) {
    return this.instance.patch(url, data, config);
  }

  delete(url, config) {
    return this.instance.delete(url, config);
  }
}

export default AuthorizedAxios;
