import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import ControlStrip from "../../../../components/ControlStrip";
import Loader from "../../../../components/Loader";
import {
  countries,
  currencies,
  handleApplyFilters,
  medWeekdayDatetimer,
} from "../../../../helpers";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Navbar from "./Navbar";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import {
  cloneUnififiApiConfiguration,
  fetchUnififiAPIConfigurations,
} from "../../../../api/FlightEngine/Unififi";
import DetailUnififiAPIConfigurationsModal from "../UnififiModals/DetailsUnififiModal";
import EditUnififiApiConfigurationModal from "../UnififiModals/EditUnififiApiConfigurationModal";
import { toast } from "react-toastify";
import DeleteUnififiModal from "../UnififiModals/DeleteUnififiModal";
import { DateTime } from "luxon";
import { genericRequestErrorHandler } from "../../../../api";
import { CustomButton } from "../../../../components/CustomButton";
import VerifyModal from "../../../../components/VerifyModal";
import ReservationsStatusTag from "../../../../components/Tags/ReservationsStatusTag";

const enviromentOptions = {
  "": "-----",
  STAG: "Staging",
  PROD: "Production",
};

const initialFilters = {
  limit: 20,
  page: 1,
  ordering: "",
  discending: "",
  user__icontains: "",
  name__icontains: "",
  country_code: "",
  force_source_type: "",
  created__lte: "",
  created__gte: "",
  source_entity: "",
  api_url: "",
};
const navbarSchema = yup.object().shape({
  name__icontains: yup
    .string()
    .min(2, "Name must be more than 1 characters")
    .max(100, "Name must be less than 100 characters"),
  created__gte: yup.date().max(new Date(), "Date cannot be in the future"),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});
const trueFalseMapping = {
  true: "Yes",
  false: "No",
};

const styles = createUseStyles({
  APIConfiguration: genericIndexesStyles.indexes.mobile(),
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile(),
  checkBox: tableStyles.checkBox,
  datesCell: { ...tableStyles.body.cell, width: "9rem", minWidth: "9rem" },
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      gridTemplateColumns: "repeat(2,1fr) ",
      padding: `calc(${variables.normal_gap} * 2.5) ${variables.normal_gap}`,
    },
  },
});

const UnififiApiConfiguration = () => {
  const classes = styles();
  const queryClient = useQueryClient();
  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [apiConfigurationId, setApiConfigurationId] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [unififiApi, setUnififiApi] = useState({});

  const tableHeaders = {
    Name: (val) => val.name,
    "Market Type": (val) => val.market_type,
    "Source Entity": (val) => val.source_entity,
    Country: (val) => _.get(countries, _.get(val, "country_code")),
    Currency: (val) => _.get(currencies, _.get(val, "currency")),
    "Is Own": (val) => _.get(trueFalseMapping, _.get(val, "is_own", "")),
    "Is Global": (val) => _.get(trueFalseMapping, _.get(val, "is_global", "")),
    "Received From": (val) => _.capitalize(val.received_from),
    PCC: (val) => val.pcc,
    GDS: (val) => val.gds,
    "Api Url": (val) => val.api_url,
    "Max Searches": (val) => (val.max_searches === 0 ? "0" : val.max_searches),
    "Max Bookings": (val) => (val.max_bookings === 0 ? "0" : val.max_bookings),
    Environent: (val) => _.get(enviromentOptions, _.get(val, "environment")),
    Created: (val) => val.created,
    Status: (val) => <ReservationsStatusTag status={val.status} />,
  };
  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data: unififi, isLoading } = useQuery({
    queryKey: ["UnififiApiConfiguration", filters],
    queryFn: () => {
      let correctPayload = { ...filters };
      if (filters.created__lte) {
        correctPayload["created__lte"] = DateTime.fromISO(
          filters.created__lte
        ).toISO();
      }
      if (filters.created__gte) {
        correctPayload["created__gte"] = DateTime.fromISO(
          filters.created__gte
        ).toISO();
      }
      return fetchUnififiAPIConfigurations({
        params: correctPayload,
      });
    },
    enabled: !showDeleteModal && !showEditModal && !showDetailModal,
    onError: (error) => genericRequestErrorHandler(error),
  });

  const cloneUnififiApiMutation = useMutation({
    mutationFn: (uid) => {
      return cloneUnififiApiConfiguration({ uid });
    },
    onSuccess: () => {
      toast.success("Cloned Unififi Api Configuration added");
      setShowVerifyModal(false);
      queryClient.invalidateQueries("UnififiApiConfiguration");
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });
  const onClone = (uid) => {
    setApiConfigurationId(uid);
  };

  return (
    <div
      id="UnififiFlAPIConfigurationsListPage"
      className={classes.APIConfiguration}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setShowEditModal(true);
          setApiConfigurationId(null);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.limit}
        page={filters.page}
        count={_.get(unififi, "data.count", 0)}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        pageOptions={[20, 50, 100]}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th key={idx} className={classes.headCell}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.get(unififi, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "created":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      return (
                        <td className={classes.datesCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    default:
                      cell = fn(val) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.tableCell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setUnififiApi(val.uid);
                      setShowDetailModal(true);
                    }}>
                    <strong>Details</strong>
                  </CustomButton>
                  <CustomButton
                    id={`EditUnififiFlButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setApiConfigurationId(val.uid);
                      setShowEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      onClone(val.uid);
                      setShowVerifyModal(true);
                    }}>
                    <strong>Clone</strong>
                  </CustomButton>
                  <CustomButton
                    id={`DeleteUnififiFlButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setUnififiApi(val);
                      setApiConfigurationId(val.uid);
                      setShowDeleteModal(true);
                    }}>
                    <strong>Delete</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDetailModal && (
        <DetailUnififiAPIConfigurationsModal
          uid={unififiApi}
          onClose={() => {
            setShowDetailModal(false);
            setUnififiApi(null);
          }}
        />
      )}
      {showEditModal && (
        <EditUnififiApiConfigurationModal
          apiConfigurationId={apiConfigurationId}
          onClose={() => {
            setShowEditModal(false);
            setApiConfigurationId(null);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteUnififiModal
          data={unififiApi}
          deletedUid={apiConfigurationId}
          onClose={() => {
            setShowDeleteModal(false);
          }}
        />
      )}
      {showVerifyModal && (
        <VerifyModal
          // title=""
          // message=""
          isLoading={cloneUnififiApiMutation.isLoading}
          onConfirm={() => {
            cloneUnififiApiMutation.mutate(apiConfigurationId);
          }}
          onClose={() => setShowVerifyModal(false)}
        />
      )}
    </div>
  );
};
export default UnififiApiConfiguration;
