import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  variables,
  inputGroup,
} from "../../../../../variables";
import PropTypes from "prop-types";
import {
  createNewFlight,
  patchFlight,
  retrieveFlightAPIConfiguration,
} from "../../../../../api/FlightEngine/FlightAPIConfigurations";
import {
  convertStringToBoolean,
  convertStringToNumber,
  countries,
  currencies,
  handleValidationErrors,
} from "../../../../../helpers";
import { toast } from "react-toastify";
import Loader from "../../../../../components/Loader";
import _ from "lodash";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import EntityAutocomplete from "../../../../../components/EntityAutocomplete";
import { flightEngineTrueFalseMapping } from "../../../common";
import { genericRequestErrorHandler } from "../../../../../api";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  user: yup.string().nullable().default(""),
  name: yup
    .string()
    .min(2, "Name: Must be at least 2 characters")
    .max(100, "Name: Must be less than 100 characters")
    .nullable()
    .default(""),
  account_number: yup.string().nullable().default(null),
  force_source_type: yup
    .string()
    .default("")
    .required("This field may not be blanc"),
  market_type: yup
    .string()
    .nullable()
    .oneOf(["B2B", "B2C"], "Must be between B2B or B2C.")
    .default(""),
  source_entity: yup
    .string()
    .nullable()
    .required("This field may not be blanc.")
    .default(""),
  country_code: yup
    .string()
    .nullable()
    .min(1, "This field may not be blanc.")
    .default(""),
  currency: yup
    .string()
    .required("Currency: This field may not be blanc.")
    .default(""),
  pcc: yup.string().required("This field may not be blanc.").default(""),
  gds: yup
    .string()
    .nullable()
    .oneOf(
      ["1S", "1G", "1A", "1U", "F1"],
      "Must be between Sabre, Galileo, Amadeus, HH Unififi or Farelogix."
    ),
  is_global: yup.boolean().nullable().default(false),
  is_own: yup.boolean().nullable().default(false),
  is_master_account: yup.boolean().nullable().default(false),
  mcre_branch: yup.string().nullable().default(""),
  use_mcre: yup.boolean().nullable().default(false),
  website_api_key: yup.string().nullable().default(""),
  status: yup.string().nullable().default("AC"),
  queue: yup
    .string()
    .max(5, "Must be at most 5 characters.")
    .required("This field may not be blanc.")
    .default(""),
  environment: yup
    .string()
    .required("This field may not be blanc.")
    .default(""),
  received_from: yup.string().nullable().default(null),
  searches: yup.number().nullable().default(0),
  max_searches: yup.number().nullable().default(0),
  bookings: yup.number().nullable().default(0),
  max_bookings: yup.number().nullable().default(0),
});

const sourceTypeOptions = {
  "": "-----",
  0: "Default",
  1: "ForceGDS",
  2: "ForceIBE",
};

const gdsMapping = {
  "": "-----",
  "1S": "Sabre",
  F1: "Farelogix",
  "1G": "Galileo",
  "1A": "Amadeus",
  "1U": "HH Unififi",
};
const environmentMapping = {
  "": "-----",
  PROD: "Production",
  STAG: "Staging",
};
const marketTypeMapping = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditFlightAPIConfigurationModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: cardStyles.body,
  inputGroup: {
    ...formInputGroup,
    padding: `${variables.normal_gap} 0`,
  },
  entityAutoComplete: {
    ...inputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCard: {
      minWidth: "50rem",
    },
    cardCol2: {
      gridTemplateColumns: "1fr 1fr",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    cardCol4: {
      display: "grid",
      gridTemplateColumns: "repeat(4,1fr)",
      gap: variables.normal_gap,
    },
  },
});

const EditFlightAPIConfigurationModal = ({ apiConfigurationId, onClose }) => {
  const classes = styles();
  const mode = apiConfigurationId ? "edit" : "add";

  const [form, setForm] = useState(jsonSchema.cast({}));
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["EditFlightAPIConfigurationModal"],
    queryFn: () => {
      return retrieveFlightAPIConfiguration({
        uid: apiConfigurationId,
      });
    },
    onSuccess: (data) => {
      setForm(jsonSchema.cast(_.get(data, "data", {})));
      setAutocompleteValue(_.get(data, "data.source_entity", ""));
    },
    onError: (error) => {
      toast.error(error.message);
      onClose();
    },
    enabled: mode === "edit",
  });

  const handleInputChange = (e) => {
    e.preventDefault();
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const addMutation = useMutation({
    mutationFn: async ({ payload }) => {
      return createNewFlight({ payload });
    },
    onSuccess: () => {
      toast.success("New Hitchhicker added");
      queryClient.invalidateQueries("FlightsAPIConfigurations");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const editMutation = useMutation({
    mutationFn: async ({ uid, payload }) => {
      return patchFlight({
        uid,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("Hitchhicker Info Updated");
      queryClient.invalidateQueries("FlightsAPIConfigurations");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const boolFields = convertStringToBoolean({
    is_master_account: form.is_master_account,
    is_global: form.is_global,
    is_own: form.is_own,
    use_mcre: form.use_mcre,
  });

  const onSubmit = async ({ uid, payload }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload: { ...payload, ...boolFields } });
      } else {
        editMutation.mutate({ uid, payload: { ...payload, ...boolFields } });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;

  const numericFields = [
    "searches",
    "max_searches",
    "bookings",
    "max_bookings",
  ];
  const updatedNumericFields = convertStringToNumber(form, numericFields);

  return (
    <div
      id="addEditHitchhikerFlAPIConfigurationModal"
      className={classes.EditFlightAPIConfigurationModal}>
      <div className={classes.modalCard}>
        {loading && <Loader onTop={true} />}
        <div className={classes.modalCardHeader}>
          <span>{_.startCase(mode)} Hitchhicker Flight API Configuration</span>
        </div>
        <div className={classes.modalCardBody}>
          <form>
            <div className={classes.card}>
              <div className={classes.cardHeader}>Basic data</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol3}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="user">User</label>
                    <input
                      id="user"
                      type="text"
                      name="user"
                      value={form.user}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="name">Name</label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      value={_.capitalize(form.name)}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="account_number">Account Number</label>
                    <input
                      id="account_number"
                      type="text"
                      name="account_number"
                      value={form.account_number}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="force_source_type">Force Source Type</label>
                    <select
                      id="force_source_type"
                      name="force_source_type"
                      value={form.force_source_type}
                      onChange={handleInputChange}>
                      {Object.entries(sourceTypeOptions).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="market_type">Market Type</label>
                    <select
                      id="market_type"
                      name="market_type"
                      value={form.market_type}
                      onChange={handleInputChange}>
                      {Object.entries(marketTypeMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="source_entity">Source Entity</label>
                    <div className={classes.entityAutoComplete}>
                      <EntityAutocomplete
                        id="entityAutocompleteInputId"
                        subagents={false}
                        independentAgents={false}
                        branches={false}
                        value={autocompleteValue}
                        setValue={setAutocompleteValue}
                        onChange={(text) => {
                          if (text.length === 0) {
                            setForm((p) => ({
                              ...p,
                              source_entity: "",
                            }));
                            return;
                          }
                          const entityInfos = text.split("---");
                          const entityType = entityInfos[0];
                          const entityId = entityInfos[1];
                          setForm((p) => ({
                            ...p,
                            source_entity: `${entityId}___${entityType}`,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="country_code">Country</label>
                    <select
                      id="country_code"
                      name="country_code"
                      value={form.country_code}
                      onChange={handleInputChange}>
                      {Object.entries(countries).map(([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="currency">Currency</label>
                    <select
                      id="currency"
                      name="currency"
                      value={form.currency}
                      onChange={handleInputChange}>
                      {Object.entries(currencies).map(([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="pcc">PCC</label>
                    <input
                      id="pcc"
                      type="text"
                      name="pcc"
                      value={form.pcc}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="gds">GDS</label>
                    <select
                      id="gds"
                      name="gds"
                      value={form.gds}
                      onChange={handleInputChange}>
                      {Object.entries(gdsMapping).map(([value, label], idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={classes.inputGroup}>
                    <label htmlFor="is_global">Is Global</label>
                    <select
                      id="is_global"
                      name="is_global"
                      value={form.is_global}
                      onChange={handleInputChange}>
                      {Object.entries(flightEngineTrueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="is_own">Is Own</label>
                    <select
                      id="is_own"
                      name="is_own"
                      value={form.is_own}
                      onChange={handleInputChange}>
                      {Object.entries(flightEngineTrueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className={classes.cardCol2}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="mcre_branch">MCRE Branch</label>
                    <input
                      id="mcre_branch"
                      type="text"
                      name="mcre_branch"
                      value={form.mcre_branch}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="use_mcre">Use MCRE</label>
                    <select
                      id="use_mcre"
                      name="use_mcre"
                      value={form.use_mcre}
                      onChange={handleInputChange}>
                      {Object.entries(flightEngineTrueFalseMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Website Api Key</label>
                    <input
                      id="website_api_key"
                      type="text"
                      name="website_api_key"
                      value={form.website_api_key}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="queue">Queue</label>
                    <input
                      id="queue"
                      type="text"
                      name="queue"
                      value={form.queue}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="environment">Environment</label>
                    <select
                      id="environment"
                      name="environment"
                      value={form.environment}
                      onChange={handleInputChange}>
                      {Object.entries(environmentMapping).map(
                        ([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="name">Received From</label>
                    <input
                      id="received_from"
                      type="text"
                      name="received_from"
                      value={form.received_from}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.cardHeader}>Usage Data</div>
              <div className={classes.cardBody}>
                <div className={classes.cardCol4}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="searches">Searches</label>
                    <input
                      id="searches"
                      type="number"
                      name="searches"
                      value={form.searches}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="max_searches">Max Searches</label>
                    <input
                      id="max_searches"
                      type="number"
                      name="max_searches"
                      value={form.max_searches}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="bookings">Bookings</label>
                    <input
                      id="bookings"
                      type="number"
                      name="bookings"
                      value={form.bookings}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="max_bookings">Max Bookings</label>
                    <input
                      id="max_bookings"
                      type="number"
                      name="max_bookings"
                      value={form.max_bookings}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className={classes.cardActions}>
          <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            id="submit"
            appearance="primary"
            onClick={(e) => {
              e.preventDefault();
              onSubmit({
                uid: apiConfigurationId,
                payload: {
                  ...form,
                  ...updatedNumericFields,
                },
              });
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
EditFlightAPIConfigurationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditFlightAPIConfigurationModal;
