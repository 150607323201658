import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Auth0Provider
    domain="veritaseu.eu.auth0.com"
    clientId="LHXf4aKNaaq3uLm51NaSOJYucHhN6TmK"
    redirectUri={window.location.origin}
  >
    <App />
  </Auth0Provider>
);
