import axios from "axios";
import { VALAQUENTA_API_URL } from "../..";
import { loggingAxios } from "../../../App";

export async function retrieveCRSGuests({ id }) {
  return await axios.get(`${VALAQUENTA_API_URL}/crs/internal/guests/${id}/`);
}

export async function patchCRSGuest({ id, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crs/internal/guests/${id}/`,
    payload
  );
}
export async function getGuestsReservationStats({ params }) {
  return await axios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/guests/stats`,
    { params }
  );
}
