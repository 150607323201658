import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchVendorAccommodations({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/vendors/`, {
    params,
  });
}

export function retrieveVendorAccomodation({ id }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/vendors/${id}/`, {});
}

export function patchVendorAccommodation({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crm/vendors/${id}/`,
    payload
  );
}

export function createNewVendorAccommodation({ payload }) {
  return loggingAxios.post(`${VALAQUENTA_API_URL}/crm/vendors/`, payload, {});
}

export function deleteVendorAccomodation({ id }) {
  return loggingAxios.delete(`${VALAQUENTA_API_URL}/crm/vendors/${id}/`, {});
}
