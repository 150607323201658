import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";

const statusMapping = {
  CO: { label: "Confimed", color: "green" },
  PE: { label: "Pending", color: "purple" },
  FA: { label: "Failed", color: "red" },
  CA: { label: "Cancelled", color: "orange" },
  RJ: { label: "Rejected", color: "Red" },
  RQ: { label: "Failed", color: "Red" },
  EX: { label: "Expired", color: "red" },
  IS: { label: "Issued", color: "green" },
  BO: { label: "Booked", color: "green" },
  AC: { label: "Active", color: "green" },
  IN: { label: "Inactive", color: "orange" },
};

const styles = createUseStyles({
  ReservationsStatusTag: ({ status }) => ({
    display: "inline-block",
    padding: "4px 10px 4px 10px",
    backgroundColor: statusMapping[status]?.color || "grey",
    width: "fit-content",
    color: "white",
    borderRadius: "3px",
  }),
});

const ReservationsStatusTag = ({ status }) => {
  const classes = styles({ status });

  return (
    <div className={classes.ReservationsStatusTag}>
      <strong>{statusMapping[status]?.label || "N/A"}</strong>
    </div>
  );
};

ReservationsStatusTag.propTypes = {
  status: PropTypes.string,
};

export default ReservationsStatusTag;
