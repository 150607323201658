import { VALAQUENTA_API_URL } from "../../..";
import { authorizedAxios, loggingAxios } from "../../../../App";

export function fetchWebSiteGenericSections({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/descriptive-section-for-entity/`,
    { params }
  );
}

export function retrieveWebSiteGenericSections({ id }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/descriptive-section-for-entity/${id}/`
  );
}

export function patchWebSiteGenericSections({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/canaveral/descriptive-section-for-entity/${id}/`,
    payload
  );
}

export function createWebSiteGenericSection({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/canaveral/descriptive-section-for-entity/`,
    payload
  );
}

export async function deleteWebSiteGenericSection({ id }) {
  return await loggingAxios.delete(
    `${VALAQUENTA_API_URL}/canaveral/descriptive-section-for-entity/${id}/`
  );
}
