import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { deleteUnififiApiConfiguration } from "../../../../../api/FlightEngine/Unififi";
import { DateTime } from "luxon";
import _ from "lodash";
import Loader from "../../../../../components/Loader";
import { CustomButton } from "../../../../../components/CustomButton";
import { useAuthentication } from "../../../../../hooks";

const trueFalseMapping = {
  true: "Yes",
  False: "No",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteUnififiModal: modalGenericStyles.modal,
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardBody: cardStyles.body,
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    gridTemplateColumns: "repeat(2, max-content)",
  },
});

const DeleteUnififiModal = ({ deletedUid, data, onClose }) => {
  const classes = styles();
  const { getJWT } = useAuthentication();
  const queryClient = useQueryClient();

  const basicDataCpl = [
    ["Uid", "uid"],
    ["Name", null, _.capitalize(_.get(data, "name"))],
    ["Customer Id", "customer_id"],
    ["Source Entity", "source_entity"],
    ["Market Type", "market_type"],
    ["Currency", "currency"],
    ["GDS", "gds"],
    ["PCC", "pcc"],
    ["Is Global", null, _.get(trueFalseMapping, _.get(data, "is_global"))],
    ["Is Own", null, _.get(trueFalseMapping, _.get(data, "is_own"))],
    ["Environment", "environment"],
    [
      "Created",
      null,
      DateTime.fromISO(_.get(data, "created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];

  const deleteUnififiApiConfigurationMutation = useMutation(
    deleteUnififiApiConfiguration,
    {
      onSuccess: () => {
        toast.success("Unififi Api configuration Deleted");
        queryClient.invalidateQueries("UnififiApiConfiguration");
        onClose();
      },
      onError: (error) => genericRequestErrorHandler(error),
    }
  );

  const onDelete = async (uid) => {
    await deleteUnififiApiConfigurationMutation.mutateAsync({ uid });
  };
  return (
    <div id="DeleteUnififiFlModal" className={classes.DeleteUnififiModal}>
      <div className={classes.modalCard}>
        {deleteUnififiApiConfigurationMutation.isLoading && (
          <Loader onTop={true} />
        )}
        <div className={classes.modalCardHeader}>
          <span>DELETE Unififi Api Configuration</span>
        </div>
        <div className={classes.modalCardBody}>
          <div className={classes.card}>
            <div className={classes.cardBody}>
              <GenericBasicInfo
                header={"Are you sure you want to delete this?"}
                cardInfo={basicDataCpl}
                objectData={data}
              />
            </div>
          </div>
        </div>
        <div className={classes.cardActions}>
          <CustomButton
            id="DeleteUnififiFlModalCloseButton"
            appearance="ghost"
            onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            id="DeleteUnififiFlModalConfirmButton"
            appearance="primary"
            onClick={() => {
              onDelete(deletedUid);
            }}>
            <stong>DELETE</stong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DeleteUnififiModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  deletedUid: PropTypes.string.isRequired,
};
export default DeleteUnififiModal;
