import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";
export function fetchTrainsApiReservations({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/api-train/`,
    { params }
  );
}
export function retrieveTrainApiReservation({ reference }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/api-train/${reference}/`
  );
}
export async function patchTrainApiReservation({ reference, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/api-train/${reference}/`,
    payload
  );
}
export async function getTrainApiReservationStats({ params }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/api-train/stats/`,
    { params }
  );
}
