import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export async function fetchFERVendors({ params }) {
  return await authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/vendors/`, {
    params,
  });
}

export function retrieveFERVendors({ id }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/crm/vendors/${id}/`);
}

export async function patchFERVendors({ id, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crm/vendors/${id}/`,
    payload
  );
}

export async function createNewFERVendors({ payload }) {
  return await loggingAxios.post(`${VALAQUENTA_API_URL}/crm/vendors/`, payload);
}

export async function cloneFERVendors({ id }) {
  return await loggingAxios.post(
    `${VALAQUENTA_API_URL}/crm/vendors/clone/${id}/`
  );
}
export async function deleteFERVendor({ id }) {
  await loggingAxios.delete(`${VALAQUENTA_API_URL}/crm/vendors/${id}/`);
  return true;
}
