import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  GenericNavbarsStyles,
  inputGroup,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { onEnterDown } from "../../../../../helpers";
import { DateTime } from "luxon";
import EntityAutocomplete from "../../../../../components/EntityAutocomplete";
import { CustomButton } from "../../../../../components/CustomButton";

const statusOptions = [
  ["", "-----"],
  ["AC", "Active"],
  ["IN", "Inactive"],
  ["CA", "Candidate"],
];

const policyTypesMapping = {
  "": "-----",
  GEO: "Geo",
  O2O: "O2O",
  GRO: "Gro",
};
const navbarStyles = createUseStyles({
  Navbar: {
    ...GenericNavbarsStyles.navbars.mobile(),
    gridTemplateRows: "max-content max-content max-content max-content ",
  },
  label: { ...inputGroup },
  divider: GenericNavbarsStyles.divider.mobile(),
  filters: GenericNavbarsStyles.filters.mobile(),
  actions: GenericNavbarsStyles.actions.mobile(),
  inputGroup: {
    ...inputGroup,
    width: "100%",
    "& input, select": {
      ...inputGroup["& input, select"],
      width: "100%",
    },
  },
  inputGroupOneLine: {
    ...inputGroup,
    gridColumn: "2 span",
    width: "100%",
  },
  [`@media ${variables.media.smallscreen}`]: {
    Navbar: {
      ...GenericNavbarsStyles.navbars.smallscreen(),
      gridTemplateColumns: "repeat(2, max-content) 1fr max-content",
      gridTemplateRows: "max-content",
    },
    divider: GenericNavbarsStyles.divider.smallscreen(),
    filters: {
      ...GenericNavbarsStyles.filters.smallscreen(),
      gridTemplateColumns: "repeat(7, 1fr)",
    },
    actions: GenericNavbarsStyles.actions.smallscreen(),
    inputGroup: {
      ...inputGroup,
    },
    inputGroupOneLine: { ...inputGroup.bigscreen, gridColumn: "1 span" },
  },
  [`@media ${variables.media.retina}`]: {
    filters: {
      ...GenericNavbarsStyles.filters.smallscreen(),
      gridTemplateColumns: "repeat(6, 1fr)",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    filters: {
      gridTemplateColumns: "repeat(7, 1fr)",
    },
  },
});

const Navbar = ({ filters, initialFilters, onApplyFilters }) => {
  const classes = navbarStyles();

  const [localFilters, setLocalFilters] = useState(filters);
  const [autocompleteValue, setAutocompleteValue] = useState("");

  return (
    <div className={classes.Navbar}>
      <div className={classes.inputGroupOneLine}>
        <strong>Policies B2B</strong>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.filters}>
        <div className={classes.inputGroupOneLine}>
          <label>Policy Name</label>
          <input
            type="text"
            value={localFilters.name__icontains}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                name__icontains: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Source Entity</label>
          <EntityAutocomplete
            value={autocompleteValue}
            setValue={setAutocompleteValue}
            onChange={(text) => {
              if (text.length === 0) {
                setLocalFilters((p) => ({
                  ...p,
                  source_entity: "",
                }));
                return;
              }
              const entityInfos = text.split("---");
              const entityType = entityInfos[0];
              const entityId = entityInfos[1];
              setLocalFilters((p) => ({
                ...p,
                source_entity: `${entityId}___${entityType}`,
              }));
            }}
            onKeyDown={(e) =>
              onEnterDown(e, onApplyFilters, {
                ...localFilters,
                created__gte: DateTime.fromISO(
                  localFilters.created__gte
                ).toISO(),
                created__lte: DateTime.fromISO(
                  localFilters.created__lte
                ).toISO(),
              })
            }
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Policy Type</label>
          <select
            value={localFilters.policy_type}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, policy_type: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(policyTypesMapping).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Distribution Period From</label>
          <input
            type="date"
            value={localFilters.booking_window_from__gte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                booking_window_from__gte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Distribution Period To</label>
          <input
            type="date"
            value={localFilters.booking_window_to__lte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                booking_window_to__lte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Reservation Period From</label>
          <input
            type="date"
            value={localFilters.service_execution_from__gte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                service_execution_from__gte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Reservation Period To</label>
          <input
            type="date"
            value={localFilters.service_execution_to__lte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                service_execution_to__lte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Created After</label>
          <input
            type="date"
            value={localFilters.created__gte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                created__gte: e.target.value,
              }))
            }
            onKeyDown={(e) =>
              onEnterDown(e, onApplyFilters, {
                ...localFilters,
                created__gte: DateTime.fromISO(
                  localFilters.created__gte
                ).toISO(),
                created__lte: DateTime.fromISO(
                  localFilters.created__lte
                ).toISO(),
              })
            }
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Created Before</label>
          <input
            type="date"
            value={localFilters.created__lte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                created__lte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Status</label>
          <select
            value={localFilters.status}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, status: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {statusOptions.map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={classes.actions}>
        <CustomButton
          appearance="ghost"
          onClick={() => {
            setLocalFilters({
              ...initialFilters,
            });
            setAutocompleteValue("");
          }}>
          <strong>Reset</strong>
        </CustomButton>
        <CustomButton
          appearance="primary"
          onClick={() => {
            onApplyFilters(localFilters);
          }}>
          <strong>Apply Filters</strong>
        </CustomButton>
      </div>
    </div>
  );
};
Navbar.propTypes = {
  filters: PropTypes.object.isRequired,
  initialFilters: PropTypes.object.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
};

export default Navbar;
