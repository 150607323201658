import React from "react";
import PropTypes from "prop-types";
import {
  cardStyles,
  formInputGroup,
  formSections,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { createUseStyles } from "react-jss";
import { countries } from "../../../../../helpers";
import _ from "lodash";

const genderMapping = {
  "": "-----",
  MA: "Male",
  FE: "Female",
  OTH: "Other",
};

const styles = createUseStyles({
  ...cardStyles,
  Deliveries: { ...cardStyles.card },
  cardBody: {
    ...cardStyles.cardBody,
    display: "grid",
    padding: variables.normal_gap,
  },
  inputGroup: { ...formInputGroup },
  form: {
    display: "grid",
    // gridTemplateColumns: "1fr",
    gap: variables.normal_gap,
    // padding: variables.normal_gap,
    textAlign: "start",
  },
  cardHeader: cardStyles.header,
  // sectionHeader: { ...formSections.sectionHeader, textAlign: "center" },
  [`@media ${variables.media.smallscreen}`]: {
    form: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
});

const Deliveries = ({ form, onChange }) => {
  const classes = styles();

  const delivery = form.deliveries[0] || {};
  const handleApplyFilters = (e) => {
    onChange({
      ...form,
      deliveries: [
        { ..._.get(form, "deliveries.0", {}), [e.target.name]: e.target.value },
      ],
    });
  };

  return (
    <div className={classes.Deliveries}>
      <div className={classes.cardHeader}>Deliveries</div>
      <div className={classes.cardBody}>
        <form className={classes.form} onChange={(e) => handleApplyFilters(e)}>
          <div className={classes.inputGroup}>
            <label>Street</label>
            <input
              id="street"
              type="text"
              name="street"
              value={delivery.street}
              onChange={(e) => handleApplyFilters(e)}
            />
          </div>
          <div className={classes.inputGroup}>
            <label>Street Number</label>
            <input
              id="street_number"
              type="number"
              name="street_number"
              value={delivery.street_number}
              onChange={(e) => handleApplyFilters(e)}
            />
          </div>
          <div className={classes.inputGroup}>
            <label>Post Code</label>
            <input
              id="post_code"
              type="text"
              name="post_code"
              value={delivery.post_code}
              onChange={(e) => handleApplyFilters(e)}
            />
          </div>
          <div className={classes.inputGroup}>
            <label>Region</label>
            <input
              id="region"
              type="text"
              name="region"
              value={delivery.region}
              onChange={(e) => handleApplyFilters(e)}
            />
          </div>
          <div className={classes.inputGroup}>
            <label>Country Code</label>
            <select
              id="deliveries_country_code"
              name="country_code"
              value={delivery.country_code}
              onChange={(e) => handleApplyFilters(e)}>
              {Object.entries(countries).map(([key, label], idx) => (
                <option key={idx} value={key}>
                  {label}
                </option>
              ))}
            </select>
          </div>
          <div className={classes.inputGroup}>
            <label>City</label>
            <input
              id="city"
              type="text"
              name="city"
              value={delivery.city}
              onChange={(e) => handleApplyFilters(e)}
            />
          </div>
          <div className={classes.inputGroup}>
            <label>First Name</label>
            <input
              id="deliveries_first_name"
              type="text"
              name="first_name"
              value={delivery.first_name}
              onChange={(e) => handleApplyFilters(e)}
            />
          </div>
          <div className={classes.inputGroup}>
            <label>Last Name</label>
            <input
              id="deliveries_last_name"
              type="text"
              name="last_name"
              value={delivery.last_name}
              onChange={(e) => handleApplyFilters(e)}
            />
          </div>
          <div className={classes.inputGroup}>
            <label>Gender</label>
            <select
              id="deliveries_gender"
              name="gender"
              value={delivery.gender}
              onChange={(e) => handleApplyFilters(e)}>
              {Object.entries(genderMapping).map(([key, label], idx) => (
                <option key={idx} value={key}>
                  {label}
                </option>
              ))}
            </select>
          </div>
          <div className={classes.inputGroup}>
            <label>E-mail</label>
            <input
              id="deliveries_email"
              type="email"
              name="email"
              value={delivery.email}
              onChange={(e) => handleApplyFilters(e)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
export default Deliveries;
Deliveries.propTypes = {
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
