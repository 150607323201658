import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../../variables";
import Navbar from "./Navbar";
import Loader from "../../../../../components/Loader";
import ControlStrip from "../../../../../components/ControlStrip";
import _ from "lodash";
import {
  currencies,
  handleApplyFilters,
  medWeekdayDatetimer,
} from "../../../../../helpers";
import { useQuery } from "@tanstack/react-query";
import HamburgerMenu from "../../../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../../../hooks";
import * as yup from "yup";
import { DateTime } from "luxon";
import { genericRequestErrorHandler } from "../../../../../api";
import { fetchTRAVendors } from "../../../../../api/Vendor/Trains";
import DetailTrainVendorModal from "../TrainsModals/DetailTrainVendorModal";
import EditTrainVendorsModal from "../TrainsModals/EditTrainVendorsModal";
import DeleteTrainVendorModal from "../TrainsModals/DeleteTrainVendorModal";
import { CustomButton } from "../../../../../components/CustomButton";
import { Price } from "../../../../../components/Price";

const initialFilters = {
  limit: 20,
  page: 1,
  ordering: "",
  discending: "",
  member: "",
  market_type: "",
  creator__icontains: "",
  created__gte: "",
  created__lte: "",
  status: "",
  config_uid__contains: "",
  vendor: "",
};

const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const navbarSchema = yup.object().shape({
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const vendorsTrains = createUseStyles({
  VendorsTrains: genericIndexesStyles.indexes.mobile(),
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  thead: tableStyles.head,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
  datesCell: { ...tableStyles.body.cell, width: "14rem" },
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      paddingTop: `${variables.normal_gap}!important`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const VendorsTrains = () => {
  const classes = vendorsTrains();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [trainId, setTrainId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [train, setTrain] = useState(null);

  const tableHeaders = {
    Vendors: (val) => (val.vendor ? val.vendor.split("tra__")[1] : "N/A"),
    Member: (val) => _.get(val, "member"),
    "Market Type": (val) => _.get(val, "market_type"),
    "Config uid": (val) => _.get(val, "config_uid[0]"),
    Currency: (val) =>
      _.get(currencies, _.get(val, "vendor_data.currency") || "N/A"),
    "Notify Amount": (val) => (
      <Price currency={val.vendor_data.currency} price={val.notify_amount} />
    ),
    Username: (val) => _.get(val, "vendor_data.username"),
    "Application Key": (val) =>
      val.vendor_data ? _.get(val, "vendor_data.application_key") : "N/A",
    "Access Token": (val) =>
      val.vendor_data ? _.get(val, "vendor_data.access_token") : "N/A",
    "Api Url": (val) =>
      val.vendor_data ? _.get(val, "vendor_data.api_url") : "N/A",
    "Api Key": (val) =>
      val.vendor_data ? _.get(val, "vendor_data.api_key") : "N/A",
    Created: (val) => val.created || "N/A",
    Status: (val) => _.get(statusMapping, val.status) || "N/A",
  };

  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data, isLoading: loading } = useQuery({
    queryKey: ["VendorsTrains", filters],
    queryFn: () => {
      let correctPayload = { ...filters };
      if (filters.created__lte) {
        correctPayload["created__lte"] = DateTime.fromISO(
          filters.created__lte
        ).toISO();
      }
      if (filters.created__gte) {
        correctPayload["created__gte"] = DateTime.fromISO(
          filters.created__gte
        ).toISO();
      }
      return fetchTRAVendors({
        params: {
          ...correctPayload,
          limit: correctPayload.limit,
          vendor_type: "tra",
        },
      });
    },
    enabled: !showDetailModal && !showEditModal,
    onError: (error) => genericRequestErrorHandler(error),
  });
  return (
    <div id="TRAVendorsListPage" className={classes.VendorsTrains}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setTrainId(null);
          setShowEditModal(true);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.limit}
        page={filters.page}
        count={_.get(data, "data.count", 0)}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                {Object.keys(tableHeaders).map((value, idx) => (
                  <th className={classes.headCell} key={idx}>
                    {value}
                  </th>
                ))}
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody className={classes.tbody}>
              {_.get(data, "data.results", []).map((val, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  {Object.entries(tableHeaders).map((entry, ridx) => {
                    var cell = null;
                    const fn = entry[1];
                    switch (entry[0].toLowerCase()) {
                      case "created":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      default:
                        cell = fn(val) || "N/A";
                        break;
                    }
                    return (
                      <td className={classes.tableCell} key={ridx}>
                        {cell}
                      </td>
                    );
                  })}
                  <td className={classes.actionCell}>
                    <CustomButton
                      id={`detailTRAVendorButton_${idx}`}
                      appearance="ghost"
                      onClick={() => {
                        setTrainId(val.id);
                        setShowDetailModal(true);
                      }}>
                      <strong>Details</strong>
                    </CustomButton>
                    <CustomButton
                      id={`editTRAVendorButton_${idx}`}
                      appearance="ghost"
                      onClick={() => {
                        setTrainId(val.id);
                        setShowEditModal(true);
                      }}>
                      <strong>Edit</strong>
                    </CustomButton>
                    <CustomButton
                      id={`deleteTRAVendorButton_${idx}`}
                      appearance="ghost"
                      onClick={() => {
                        setTrain(val);
                        setTrainId(val.id);
                        setShowDeleteModal(true);
                      }}>
                      <strong>Delete</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showDetailModal && (
        <DetailTrainVendorModal
          id={trainId}
          onClose={() => {
            setShowDetailModal(false);
            setTrainId(null);
          }}
        />
      )}
      {showEditModal && (
        <EditTrainVendorsModal
          id={trainId}
          onClose={() => {
            setShowEditModal(false);
            setTrainId(null);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteTrainVendorModal
          data={train}
          deletedId={trainId}
          onClose={() => {
            setShowDeleteModal(false);
            setTrainId(null);
          }}
        />
      )}
    </div>
  );
};

export default VendorsTrains;
