import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  createNewSubAgent,
  patchSubAgent,
  retrieveSubAgent,
} from "../../../../../api/CRM/SubAgents/SubAgents";
import Loader from "../../../../../components/Loader";
import * as yup from "yup";
import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  convertStringToNumber,
  currencies,
  handleValidationErrors,
} from "../../../../../helpers";
import { CustomButton } from "../../../../../components/CustomButton";
import { genericRequestErrorHandler } from "../../../../../api";
import EntityAutocomplete from "../../../../../components/EntityAutocomplete";

const b2cVivaCredentialsType = {
  "": "-----",
  LV: "Live",
  DE: "Demo",
};

const currentYear = new Date().getFullYear();

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? undefined : value))
    .max(100, "Must be less than 100 characters")
    .default(""),
  member: yup.string().nullable().default(""),
  legal_title: yup.string().nullable().default(null),
  registration_code: yup.string().nullable().default(""),
  currency: yup.string().nullable().default(""),
  founding_year: yup
    .number()
    // .integer("A valid integer is required for founding year")
    // .positive("Must be a positive integer")
    .max(currentYear, `Cannot be greater than ${currentYear}`)
    .nullable()
    .default(0),
  mobile_phone: yup
    .string()
    .max(20, "Cannot be greater than 20 digits")
    .nullable()
    .default(""),
  phone: yup
    .string()
    .max(20, "Cannot be greater than 20 digits")
    .nullable()
    .default(""),
  tags: yup.array().of(yup.string()).default([""]),
  email: yup.string().email("Invalid email address").nullable().default(null),
  felloh_organisation: yup.string().nullable().default(""),
  felloh_public_key: yup.string().nullable().default(""),
  felloh_secret_key: yup.string().nullable().default(""),
  b2c_stripe_accepted_coupons: yup.string().nullable().default(""),
  b2c_stripe_api_public_key: yup.string().nullable().default(""),
  b2c_stripe_api_secret_key: yup.string().nullable().default(""),
  b2c_viva_api_key: yup.string().nullable().default(""),
  b2c_viva_credentials_type: yup.string().nullable().default(""),
  b2c_viva_merchant_id: yup.string().nullable().default(""),
  b2c_viva_source_code: yup.string().nullable().default(""),
  barclays_passphrase: yup.string().nullable().default(""),
  barclays_pspid: yup.string().nullable().default(""),
});

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditSubAgentModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content  1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  inputGroup: formInputGroup,
  entityAutoComplete: {
    ...formInputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  tagscardBody: {
    ...cardStyles.body,
    display: "grid",
    gridTemplateColumns: "1fr max-content",
  },
  tagsActions: {
    ...modalGenericStyles.cardActions,
    justifyContent: "start",
    height: "1.5rem",
    alignSelf: "end",
  },
  tags: {
    ...modalGenericStyles.card,
    display: "grid",
    gridColumn: "span 2",
    lineHeight: "5vh",
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardBody: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    cardCol2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    card: {
      minWidth: "50rem",
    },
  },
});
const EditSubAgentModal = ({ subAgentId, onClose }) => {
  const classes = styles();
  const mode = subAgentId ? "edit" : "add";

  const [form, setForm] = useState(jsonSchema.cast({}));
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const queryClient = useQueryClient();

  const { isLoading: isFetchLoading } = useQuery({
    queryKey: ["EditSubAgentModal"],
    queryFn: () => retrieveSubAgent({ id: subAgentId }),
    onSuccess: (data) => {
      const tags =
        (data?.data?.tags ?? []).length === 0 ? [""] : data.data.tags;
      setForm(jsonSchema.cast({ ..._.get(data, "data", {}), tags }));
      setAutocompleteValue(_.get(data, "data.member"));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    enabled: mode === "edit",
  });

  const addMutation = useMutation({
    mutationFn: ({ payload }) => {
      return createNewSubAgent({ payload });
    },
    onSuccess: () => {
      toast.success("New Subagent  added");
      queryClient.invalidateQueries("SubAgents");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const editMutation = useMutation({
    mutationFn: async ({ id, payload }) => {
      return patchSubAgent({ id, payload });
    },
    onSuccess: () => {
      toast.success("Subagent Info Updated");
      queryClient.invalidateQueries("SubAgents");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const handleInputChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const numericFields = ["founding_year"];
  const updatedNumericFields = convertStringToNumber(form, numericFields);

  const onSubmit = async ({ id, payload, formData }) => {
    try {
      const filteredTags = formData.tags.filter((tag) => tag !== "");
      console.log("Filtered Tags:", filteredTags);
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload: { ...payload, tags: filteredTags } });
      } else {
        editMutation.mutate({
          id,
          payload: { ...payload, tags: filteredTags },
        });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const loading =
    (mode === "edit" && isFetchLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;
  return (
    <div id="addEditSubagentModal" className={classes.EditSubAgentModal}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} Subagent</span>
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} Subagent</span>
          </div>
          <div className={classes.modalCardBody}>
            <form>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="name">Name</label>
                      <input
                        id="name"
                        type="text"
                        name="name"
                        value={_.capitalize(form.name)}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="member">Member</label>
                      <div className={classes.entityAutoComplete}>
                        <EntityAutocomplete
                          id="entityAutocompleteInputId"
                          subagents={false}
                          independentAgents={false}
                          branches={false}
                          value={autocompleteValue}
                          setValue={setAutocompleteValue}
                          onChange={(text) => {
                            if (text.length === 0) {
                              setForm((p) => ({
                                ...p,
                                member: "",
                              }));
                              return;
                            }
                            const entityInfos = text.split("---");
                            const entityId = entityInfos[1];
                            setForm((p) => ({
                              ...p,
                              member: `${entityId}`,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="legal_title">Legal Title</label>
                      <input
                        id="legal_title"
                        type="text"
                        name="legal_title"
                        value={_.capitalize(form.legal_title)}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="registration_code">
                        Registration Code
                      </label>
                      <input
                        id="registration_code"
                        type="text"
                        name="registration_code"
                        value={form.registration_code}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="currency">Currency</label>
                      <select
                        id="currency"
                        name="currency"
                        value={form.currency}
                        onChange={handleInputChange}>
                        {Object.entries(currencies).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="founding_year">Founding Year</label>
                      <input
                        id="founding_year"
                        type="number"
                        name="founding_year"
                        value={form.founding_year}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Contact Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="mobile_phone">Mobile Phone</label>
                      <input
                        id="mobile_phone"
                        type="text"
                        name="mobile_phone"
                        value={form.mobile_phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="phone">Phone</label>
                      <input
                        id="phone"
                        type="text"
                        name="phone"
                        value={form.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        value={form.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>
                  Payment Gateway Credentials
                </div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="felloh_organisation">
                        Felloh Organisation
                      </label>
                      <input
                        id="felloh_organisation"
                        type="text"
                        name="felloh_organisation"
                        value={form.felloh_organisation}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="felloh_public_key">
                        Felloh Public Key
                      </label>
                      <input
                        id="felloh_public_key"
                        type="text"
                        name="felloh_public_key"
                        value={form.felloh_public_key}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="felloh_secret_key">
                        Felloh Secret Key
                      </label>
                      <input
                        id="felloh_secret_key"
                        type="text"
                        name="felloh_secret_key"
                        value={form.felloh_secret_key}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_stripe_accepted_coupons">
                        B2C Stripe Accepted Coupons
                      </label>
                      <input
                        id="b2c_stripe_accepted_coupons"
                        type="text"
                        name="b2c_stripe_accepted_coupons"
                        value={form.b2c_stripe_accepted_coupons}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_stripe_api_public_key">
                        B2C Stripe Api Public Key
                      </label>
                      <input
                        id="b2c_stripe_api_public_key"
                        type="text"
                        name="b2c_stripe_api_public_key"
                        value={form.b2c_stripe_api_public_key}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_stripe_api_secret_key">
                        B2C Stripe Api Secret Key
                      </label>
                      <input
                        id="b2c_stripe_api_secret_key"
                        type="text"
                        name="b2c_stripe_api_secret_key"
                        value={form.b2c_stripe_api_secret_key}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_viva_api_key">B2C Viva Api Key</label>
                      <input
                        id="b2c_viva_api_key"
                        type="text"
                        name="b2c_viva_api_key"
                        value={form.b2c_viva_api_key}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_viva_credentials_type">
                        B2C Viva Credentials Type
                      </label>
                      <select
                        id="b2c_viva_credentials_type"
                        name="b2c_viva_credentials_type"
                        value={form.b2c_viva_credentials_type}
                        onChange={handleInputChange}>
                        {Object.entries(b2cVivaCredentialsType).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_viva_merchant_id">
                        B2C Viva Merchant Id
                      </label>
                      <input
                        id="b2c_viva_merchant_id"
                        type="text"
                        name="b2c_viva_merchant_id"
                        value={form.b2c_viva_merchant_id}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="b2c_viva_source_code">
                        B2C Viva Source Code
                      </label>
                      <input
                        id="b2c_viva_source_code"
                        type="text"
                        name="b2c_viva_source_code"
                        value={form.b2c_viva_source_code}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="barclays_passphrase">
                        barclays Pass Phrase
                      </label>
                      <input
                        id="barclays_passphrase"
                        type="text"
                        name="barclays_passphrase"
                        value={form.barclays_passphrase}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="barclays_pspid">Barclays PSPID</label>
                      <input
                        id="barclays_pspid"
                        type="text"
                        name="barclays_pspid"
                        value={form.barclays_pspid}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Tags</div>
                <div className={classes.tagscardBody}>
                  <div className={classes.inputGroup}>
                    <label htmlFor="tags">Tag</label>
                    <input
                      type="text"
                      value={_.get(form, "tags.0", "")}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          tags: [
                            ...form.tags.map((tag, idx) =>
                              idx === 0 ? e.target.value : tag
                            ),
                          ],
                        });
                      }}
                    />
                  </div>
                  <div className={classes.tagsActions}>
                    {(_.get(form, "tags", []).length !== 1 ||
                      _.get(form, "tags.0", "")) !== "" && (
                      <CustomButton
                        appearance="ghost"
                        onClick={(e) => {
                          e.preventDefault();
                          if (_.get(form, "tags", []).length > 1) {
                            let newTags = form.tags;
                            newTags.splice(0, 1);
                            setForm({ tags: [...newTags] });
                            return;
                          }
                          setForm({ ...form, tags: [""] });
                        }}>
                        <strong>Remove</strong>
                      </CustomButton>
                    )}

                    {_.get(form, "tags", [""]).length === 1 && (
                      <CustomButton
                        appearance="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({ tags: [...form.tags, ...[""]] });
                        }}>
                        <strong>Add Tag</strong>
                      </CustomButton>
                    )}
                  </div>
                </div>
              </div>
              {_.get(form, "tags").map(
                (tag, tagIdx) =>
                  tagIdx > 0 && (
                    <React.Fragment>
                      <div className={classes.card}>
                        <div className={classes.tagscardBody}>
                          <div className={classes.inputGroup}>
                            <label htmlFor="tags">Tag {tagIdx + 1}</label>
                            <input
                              id={`tags_${tagIdx}`}
                              type="text"
                              value={_.get(form, `tags.${tagIdx}`, "")}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  tags: [
                                    ...form.tags.map((tag, idx) =>
                                      idx === tagIdx ? e.target.value : tag
                                    ),
                                  ],
                                });
                              }}
                            />
                          </div>
                          <div className={classes.tagsActions}>
                            <CustomButton
                              id={`removeTagButton_${tagIdx}`}
                              appearance="ghost"
                              onClick={(e) => {
                                e.preventDefault();
                                if (_.get(form, "tags", []).length > 1) {
                                  let newTags = form.tags;
                                  newTags.splice(tagIdx, 1);
                                  setForm({ ...form, tags: [...newTags] });
                                  return;
                                }
                                setForm({ ...form, tags: [""] });
                              }}>
                              <strong>Remove</strong>
                            </CustomButton>
                            {_.get(form, "tags", []).length === tagIdx + 1 && (
                              <CustomButton
                                id={`addTagButton_${tagIdx}`}
                                appearance="primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setForm({
                                    ...form,
                                    tags: [...form.tags, ...[""]],
                                  });
                                }}>
                                <strong>Add Tag</strong>
                              </CustomButton>
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )
              )}
            </form>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="submit"
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  id: subAgentId,
                  payload: { ...form, ...updatedNumericFields },
                  formData: form,
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditSubAgentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditSubAgentModal;
