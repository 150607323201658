import React from "react";
import { createUseStyles } from "react-jss";
import _ from "lodash";
import PropTypes from "prop-types";
import { cardStyles, variables } from "../../../../../variables";

const reservationStatsStyles = createUseStyles({
  ...cardStyles,
  ReservationStats: {
    display: "grid",
    padding: variables.normal_gap,
    gap: variables.normal_gap,
    overflow: "auto",
  },
  stats: {
    display: "grid",
    padding: variables.normal_gap,
    gap: variables.normal_gap,
  },
  tbody: { textAlign: "center" },

  table: {
    borderSpacing: `${variables.normal_gap} ${variables.half_gap}`,
  },
});

const ReservationStats = ({ title, stats }) => {
  const classes = reservationStatsStyles();
  return (
    <div className={classes.ReservationStats}>
      <div className={classes.header}>{title}</div>
      <div className={classes.stats}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Currency</th>
              <th>Max Price</th>
              <th>Min Price</th>
              <th>Avg Price</th>
              <th>Total Reservations</th>
              <th>Transaction Fee:</th>
              <th>Total Value</th>
              <th>Fail Rates</th>
            </tr>
          </thead>
          <tbody className={classes.tbody}>
            {Object.entries(stats).map(([currency, currencyStats], idx) => {
              return (
                <tr key={idx}>
                  <td>{currency && currency !== "None" ? currency : "N/A"}</td>
                  <td>
                    {currency && currency !== "None"
                      ? Number(
                          _.get(currencyStats, "max_price")
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency,
                        })
                      : Number(_.get(currencyStats, "max_price"))}
                  </td>
                  <td>
                    {currency && currency !== "None"
                      ? Number(
                          _.get(currencyStats, "min_price")
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency,
                        })
                      : Number(_.get(currencyStats, "min_price"))}
                  </td>
                  <td>
                    {currency && currency !== "None"
                      ? Number(
                          _.get(currencyStats, "avg_price")
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency,
                        })
                      : Number(_.get(currencyStats, "avg_price"))}
                  </td>
                  <td>{_.get(currencyStats, "total_reservations")}</td>
                  <td>
                    {currency && currency !== "None"
                      ? Number(
                          _.get(currencyStats, "transaction_fee")
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency,
                        })
                      : Number(_.get(currencyStats, "transaction_fee"))}
                  </td>
                  <td>
                    {currency && currency !== "None"
                      ? Number(
                          _.get(currencyStats, "total_value")
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency,
                        })
                      : Number(_.get(currencyStats, "total_value"))}
                  </td>
                  <td>
                    {currency && currency !== "None"
                      ? Number(
                          _.get(currencyStats, "fail_success_percs.fail")
                        ).toLocaleString("en-US", {
                          style: "percent",
                        })
                      : Number(_.get(currencyStats, "fail_success_percs.fail"))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
ReservationStats.propTypes = {
  title: PropTypes.string.isRequired,
  stats: PropTypes.object.isRequired,
};
export default ReservationStats;
