import axios from "axios";
import _ from "lodash";
import { DateTime } from "luxon";

//========== CUSTOM AXIOS CLASS ==========//

/**
 * Logging Axios is a custom axios class that
 * after a method is called, it will send a log
 * to the tracking endpoint.
 */
class LoggingAxios {
  constructor() {
    // Create a default axios instance for logging
    // This instance will not have any interceptors
    this.defaultAxios = axios.create();

    this.instance = axios.create();
    // the elastic atractor runs on the staging environment of valaquenta only.
    this.logEndpoint = `https://9n837gy1ne.execute-api.ap-southeast-1.amazonaws.com/staging/elasticattractor/log/`;
    // this.index_name = "testingindex";
    this.index_name = "veritas";
    this.creator = "";
    this.descriptor = "";

    this.environmentMapping = {
      development: "DEV",
      production: "PRODUCTION",
      test: "TEST",
    };

    // Add an interceptor for requests
    this.setLoggingRequestInterceptor();

    // Add an interceptor for responses
    this.instance.interceptors.response.use((response) => {
      // Send logs to the tracking endpoint
      this.sendLogs(
        this.extractRequestData(response.config),
        this.extractResponseData(response)
      );

      return response;
    });
  }

  // Extract relevant request data
  extractRequestData(requestConfig) {
    return {
      method: requestConfig.method.toUpperCase(),
      url: requestConfig.url,
      request_headers: requestConfig.headers || {},
      request_params: requestConfig.params || {},
      request_body: requestConfig.data || {},
    };
  }

  // Extract relevant response data
  extractResponseData(response) {
    const now = DateTime.now().toISO();
    return {
      status: response.status,
      response_headers: response.headers || {},
      response_body: response.data || {},
      timestamp: now,
      elapsedTime: Date.now() - response.config.metadata.startTime,
      environment: _.get(this.environmentMapping, process.env.NODE_ENV),
    };
  }

  // Send logs to the tracking endpoint
  sendLogs(requestData, responseData) {
    const logData = {
      index_name: this.index_name,
      entry_body: {
        creator: this.creator,
        descriptor: this.descriptor,
        ...requestData,
        ...responseData,
      },
    };

    this.defaultAxios
      .post(this.logEndpoint, logData)
      .then(() => {
        console.log("Log data sent successfully.");
      })
      .catch((error) => {
        console.error("Error sending log data:", error);
      });
  }

  setCreator(creator) {
    this.creator = creator;
  }

  setDescriptor(descriptor) {
    this.descriptor = descriptor;
  }

  // Proxy methods for common HTTP methods
  get(url, config) {
    return this.instance.get(url, config);
  }

  post(url, data, config) {
    return this.instance.post(url, data, config);
  }

  put(url, data, config) {
    return this.instance.put(url, data, config);
  }

  patch(url, data, config) {
    return this.instance.patch(url, data, config);
  }

  delete(url, config) {
    return this.instance.delete(url, config);
  }

  setJWT(jwt) {
    this.jwt = jwt;
  }

  setLoggingRequestInterceptor() {
    if (!this.jwt) {
      return;
    }

    // Add an interceptor for requests
    this.instance.interceptors.request.use((request) => {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${this.jwt}`,
      };
      request.metadata = { startTime: Date.now() };
      return request;
    });
  }
}

export default LoggingAxios;
