import React from "react";
import { createUseStyles } from "react-jss";
import { cardStyles, variables } from "../../../variables";
import PropTypes from "prop-types";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

function logStyles(props) {
  return {
    maxHeight: "1000vh",
    width: "100%",
    overflow: "auto",
    height: "100%",
  };
}

function logHeaderStyles() {
  return {
    display: "inline-grid",
    gridTemplateColumns: "1fr max-content max-content",
    gridGap: variables.normal_gap,
    alignItems: "center",
  };
}

const commonSyntaxHighlighterProps = {
  language: "javascript",
  customStyle: {
    fontSize: "smaller",
    maxHeight: "1000px",
    overflow: "auto",
  },
};

const commonLogStyles = createUseStyles({
  CommonLog: (props) => logStyles(props),
  logHeader: logHeaderStyles(),
});

const CommonLog = ({ data }) => {
  const classes = commonLogStyles();

  return (
    <div className={classes.CommonLog}>
      <div>
        <SyntaxHighlighter {...commonSyntaxHighlighterProps}>
          {JSON.stringify(data ?? {}, null, 2)}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};
CommonLog.propTypes = {
  title: PropTypes.string,
  data: PropTypes.string.isRequired,
};

const propsDataLog = {
  language: "javascript",
  customStyle: {
    fontSize: "smaller",
    maxHeight: "15rem",
    overflow: "auto",
  },
};

const styles = createUseStyles({
  ...cardStyles,
  DataLog: { ...cardStyles.card, width: "100%" },
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    overflow: "auto",
    maxHeight: "22rem",
  },
});

export const DataLog = ({ title, data }) => {
  const classes = styles();

  return (
    <div className={classes.DataLog}>
      <div className={classes.cardHeader}>{title}</div>
      <div className={classes.cardBody}>
        <SyntaxHighlighter {...propsDataLog}>
          {JSON.stringify(data ?? {}, null, 2)}
        </SyntaxHighlighter>
        {/* <CommonLog data={data} /> */}
      </div>
    </div>
  );
};

DataLog.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
};
