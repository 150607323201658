import { VALAQUENTA_API_URL } from "../../..";
import { authorizedAxios, loggingAxios } from "../../../../App";

export function fetchWebSiteAboutUs({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/about-us-for-entity/`,
    {
      params,
    }
  );
}

export function retrieveWebSiteAboutUs({ id }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/about-us-for-entity/${id}/`
  );
}

export function patchWebSiteAboutUs({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/canaveral/about-us-for-entity/${id}/`,
    payload
  );
}

export function createWebSiteAboutUs({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/canaveral/about-us-for-entity/`,
    payload
  );
}

export function deleteWebSiteAboutUs({ id }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/canaveral/about-us-for-entity/${id}/`
  );
}
