import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { Button } from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import { modalGenericStyles } from "../../../../variables";
import { sendLogsToVendor } from "../../../../api/CRS/CommonActions/SendLogsToVendor";

const styles = createUseStyles({
  ...modalGenericStyles,
  SendLogsToVendorModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
    fontSize: "medium",
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    minHeight: "10rem",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
});
/**
 * The modal to send the logs of the reservations to the vendor.
 *
 * @param {string} props.serviceType - The type of service.
 * @param {string} props.reference - The reference for the logs.
 * @param {function} props.onClose - The function to close the modal.
 */
const SendLogsToVendorModal = ({ serviceType, reference, onClose }) => {
  const classes = styles();

  const sendLogsMutate = useMutation({
    mutationFn: ({ service_type, reference }) =>
      sendLogsToVendor({ payload: { service_type, reference } }),
    onError: (error) => {
      toast.error("Error sending logs to vendor");
    },
    onSuccess: () => {
      toast.success("The sending logs process has started.");
      onClose();
    },
  });

  return (
    <div className={classes.SendLogsToVendorModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <h2>Send logs to vendor</h2>
        </div>
        <div className={classes.modalCardBody}>
          {sendLogsMutate.isLoading ? (
            <Loader />
          ) : (
            <span>Are you sure you want to send the logs to the vendor?</span>
          )}
        </div>
        <div className={classes.modalCardActions}>
          <Button
            text="Cancel"
            onClick={() => {
              onClose();
            }}
          />
          <Button
            text="Submit"
            onClick={() => {
              sendLogsMutate.mutate({ service_type: serviceType, reference });
            }}
          />
        </div>
      </div>
    </div>
  );
};
SendLogsToVendorModal.protoTypes = {
  serviceType: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default SendLogsToVendorModal;
