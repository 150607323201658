import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { useAuthentication } from "../../hooks";
import { genericIndexesStyles, tableStyles, cardStyles } from "../../variables";
import _ from "lodash";
import * as yup from "yup";
import { CustomButton } from "../CustomButton";
import HttpCodeTag from "../Tags/HttpCodeTag";
import Loader from "../Loader";
import ControlStrip from "../ControlStrip";
import { postUserLogs } from "../../api/UserProfile";
import { handleApplyFilters } from "../../helpers";
import DetailLog from "./DetailLogModal";
import Navbar from "./Navbar";

const navbarSchema = yup.object().shape({});

const initialFilters = {
  page_size: 20,
  page: 1,
  ordering: "-created",
  action: "",
};

const preparePayload = ({ filters, creator, descriptor, actionsMapping }) => {
  var must = [
    {
      match_phrase: {
        creator: creator,
      },
    },
    {
      match_phrase: {
        descriptor: descriptor,
      },
    },
  ];
  if (_.get(actionsMapping, filters.action)) {
    const action = actionsMapping[filters.action];
    must.push({
      match_phrase: {
        url: action.url,
      },
    });
    must.push({
      match_phrase: {
        method: action.method,
      },
    });
  }

  let payload = {
    index_name: ["verita*"],
    elastic_query: {
      from: filters.page - 1,
      size: filters.page_size,
      sort: [
        {
          timestamp: {
            order: filters.ordering === "created" ? "asc" : "desc",
            // order: filters.ordering === "created" ? "desc" : "asc",
          },
        },
      ],
      query: {
        bool: {
          must,
        },
      },
    },
  };
  return payload;
};

const styles = createUseStyles({
  logs: {
    display: "grid",
    gridTemplateRows: "repeat(3, max-content)",
    maxWidth: "100%",
    overflow: "auto",
  },
  card: cardStyles.card,
  cardHeader: {
    ...cardStyles.header,
  },
  cardBody: {
    ...cardStyles.body,
    // display: "grid",
    // gridTemplateColumns: "max-content max-content",
    // justifyItems: "left",
    // fontWeight: "bold",
  },
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
});

const Logs = ({ actionsMapping }) => {
  const classes = styles();

  const [filters, setFilters] = useState(initialFilters);
  const [request, setRequest] = useState({});
  const [showDetailModal, setShowDetailModal] = useState(false);

  const { user } = useAuthentication({ info_only: true });

  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["logsList", filters],
    queryFn: async () =>
      postUserLogs({
        payload: preparePayload({
          filters,
          creator: user?.sub,
          descriptor: window.location.href,
          actionsMapping,
        }),
      }),
    retry: false,
  });

  const logs = _.get(data, "data.results", []);

  return (
    <div id="logsList" className={classes.logs}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        actionsMapping={actionsMapping}
      />
      <ControlStrip
        limit={filters.page_size}
        page={filters.page}
        count={_.get(data, "data.count")}
        onChangeLimit={(page_size) =>
          setFilters({ ...filters, page_size, page: 1 })
        }
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading ? (
        <div className={classes.card}>
          <div className={classes.cardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.card}>
          <div className={classes.cardBody}>
            <div className={classes.tableContainer}>
              <table className={classes.table}>
                <thead className={classes.thead}>
                  <tr className={classes.headRow}>
                    <th className={classes.headCell}>#</th>
                    <th className={classes.headCell}>Descriptor</th>
                    <th className={classes.headCell}>Method</th>
                    <th className={classes.headCell}>Elapsed Time</th>
                    <th className={classes.headCell}>Created</th>
                    <th className={classes.headCell}>Status</th>
                    <th className={classes.headCell}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((log, idx) => (
                    <tr className={classes.bodyRow} key={idx}>
                      <td className={classes.tableCell}>{idx + 1}</td>
                      <td className={classes.tableCell}>
                        {_.get(log, "_source.descriptor") || "N/A"}
                      </td>
                      <td className={classes.tableCell}>
                        {_.get(log, "_source.method", "") || "N/A"}
                      </td>
                      <td className={classes.tableCell}>
                        {_.get(log, "_source.elapsedTime", 0) / 1000} sec.
                      </td>
                      <td className={classes.tableCell}>
                        {new Date(
                          _.get(log, "_source.timestamp", 0)
                        ).toLocaleString() || "N/A"}
                      </td>
                      <td className={classes.tableCell}>
                        <HttpCodeTag
                          statusCode={_.get(log, "_source.status", "")}
                        />
                      </td>
                      <td className={classes.actionCell}>
                        <CustomButton
                          id={`detailButton_${idx}`}
                          appearance="ghost"
                          onClick={() => {
                            setShowDetailModal(true);
                            setRequest(log);
                          }}>
                          <strong>Details</strong>
                        </CustomButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {showDetailModal && (
        <DetailLog
          data={request}
          onClose={() => {
            setShowDetailModal(false);
            setRequest(null);
          }}
        />
      )}
    </div>
  );
};
Logs.defaultProps = {};
Logs.propTypes = {
  actionsMapping: PropTypes.object.isRequired,
};
export default Logs;
