import React, { useState } from "react";
import { inputGroup, variables } from "../../../../variables";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { currencies, onEnterDown } from "../../../../helpers";
import { CustomButton } from "../../../../components/CustomButton";

const entryTypeMapping = {
  "": "-----",
  CR: "Credit",
  DB: "Debit",
};

const productCategoryMapping = {
  "": "-----",
  ACC: "Accommodation",
  FL: "Flights",
  FER: "Ferries",
  TRA: "Trains",
  TR: "Transportation",
  TRF: "Transfers",
  MI: "Add Ons",
  REST: "Restaurants",
  COO: "Coordinators",
  GEN: "General Services",
  AD: "Ad hoc Land Services",
  ACT: "Activity",
  CAR: "Car Rental",
};

const navbarStyles = createUseStyles({
  Navbar: {
    display: "grid",
    gridAutoFlow: "row",
    padding: variables.normal_gap,
    textAlign: "center",
    background: variables.colors.base,
    paddingTop: "2rem",
    gridGap: variables.half_gap,
  },
  filters: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.half_gap,
    alignItems: "end",
    justifyItems: "center",
    padding: variables.normal_gap,
  },
  actions: { display: "grid", gridGap: variables.normal_gap },
  inputGroup: {
    ...inputGroup,
    width: "100%",
    gridColumn: "2 span",
    "& label": {
      fontWeight: "bold",
      color: "white !important",
    },
    "& input, select": {
      ...inputGroup["& input, select"],
      width: "10rem",
    },
  },
  inputGroupOneLine: {
    ...inputGroup,
    gridColumn: "2 span",
    width: "100%",
    "& label": {
      fontWeight: "bold",
      color: "white !important",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    Navbar: {
      gridTemplateColumns: "1fr max-content",
      alignItems: "center",
      overflow: "auto",
      justifyContent: "start",
      fontSize: "small",
      padding: variables.normal_gap,
      paddingTop: variables.half_gap,
      paddingBottom: variables.half_gap,
    },
    filters: {
      padding: variables.half_gap,
      alignItems: "end",
      paddingTop: variables.half_gap,
      paddingBottom: variables.half_gap,
      justifyItems: "start",
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    actions: {
      display: "grid",
      padding: variables.half_gap,
      alignItems: "end",
      gridAutoFlow: "column",
      gridGap: variables.normal_gap,
      height: "100%",
      boxSizing: "border-box",
    },
    inputGroup: {
      ...inputGroup.bigscreen,
      gridColumn: "1 span",
      "& input, select": {
        width: "10rem",
      },
    },
    inputGroupOneLine: { ...inputGroup.bigscreen, gridColumn: "1 span" },
  },
  [`@media ${variables.media.bigscreen}`]: {},
});
const Navbar = ({
  filters,
  initialFilters,
  currencyOptions,
  onApplyFilters,
}) => {
  const classes = navbarStyles();
  const [localFilters, setLocalFilters] = useState(filters);

  var curOptions = [];
  if (currencyOptions.length > 0) {
    currencyOptions.forEach((cur) => {
      curOptions.push([cur, currencies?.[cur] || cur]);
    });
  }
  return (
    <div className={classes.Navbar}>
      <div className={classes.filters}>
        <div className={classes.inputGroupOneLine}>
          <label>Product Type</label>
          <select
            id="filterProductTypeIcontains"
            value={localFilters.product_type__icontains}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                product_type__icontains: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(productCategoryMapping).map(
              ([value, label], idx) => (
                <option key={idx} value={value}>
                  {label}
                </option>
              )
            )}
          </select>
        </div>
        <div className={classes.inputGroupOneLine}>
          <label>Amount Less than</label>
          <input
            id="filterAmountLessThan"
            type="number"
            value={localFilters.amount__lte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                amount__lte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroupOneLine}>
          <label>Currency</label>
          <select
            id="filterCurrency"
            value={localFilters.currency}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                currency: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {curOptions.map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroupOneLine}>
          <label>Entry Type</label>
          <select
            id="filterEntryType"
            value={localFilters.entry_type}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                entry_type: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(entryTypeMapping).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroupOneLine}>
          <label>Tags</label>
          <input
            id="filterTags"
            type="text"
            value={localFilters.tags}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                tags: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroupOneLine}>
          <label>Created After</label>
          <input
            id="filterCreatedAfter"
            type="date"
            value={localFilters.created__gte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                created__gte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroupOneLine}>
          <label>Created Before</label>
          <input
            id="filterCreatedBefore"
            type="date"
            value={localFilters.created__lte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                created__lte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
      </div>
      <div className={classes.actions}>
        <CustomButton
          appearance="filterGhost"
          onClick={() => {
            setLocalFilters({
              ...initialFilters,
            });
          }}>
          <strong>Reset</strong>
        </CustomButton>
        <CustomButton
          id="ApplyCreditStatmentFiltersButton"
          appearance="filterPrimary"
          onClick={() => onApplyFilters(localFilters)}>
          <strong>Apply Filters</strong>
        </CustomButton>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  filters: PropTypes.object.isRequired,
  initialFilters: PropTypes.object.isRequired,
  currencyOptions: PropTypes.array.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
};
export default Navbar;
