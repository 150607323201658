import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import {
  cardStyles,
  formInputGroup,
  inputGroup,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../api";
import EntityAutocomplete from "../../../../components/EntityAutocomplete";
import {
  CreateNewRailEuropeAPIConfig,
  patchRailEuropeAPIConfig,
  retrieveRailEuropeAPIConfig,
} from "../../../../api/RailEuropeEngine";
import {
  APIConfigsStatusMapping,
  convertStringToBoolean,
  currencies,
  handleValidationErrors,
} from "../../../../helpers";
import { CustomButton } from "../../../../components/CustomButton";

const initialValues = {
  name: "",
  created: "",
  username: "",
  market_type: "",
  is_live: false,
  global_account: false,
  website_api_key: "",
  application_key: "",
  access_token: "",
  password: "",
  api_url: "",
  api_key: "",
  supplier: "",
  currency: "",
};

const supplierOptionsMapping = {
  "": "-----",
  raileurope: "Rail Europe",
};

export const marketTypeMapping = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};

const trueFalseMapping = {
  false: "No",
  true: "Yes",
};

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Name: Must be at least 2 characters")
    .max(100, "Name: Must be less than 100 characters")
    .nullable()
    .default(""),
  supplier: yup
    .string()
    .oneOf(["raileurope"], "Supplier: Must be Rail Europe")
    .nullable()
    .default(""),

  source_entity: yup.string().default(""),
  market_type: yup
    .string()
    .oneOf(["B2B", "B2C"], "Market Type: Must be between B2B or B2C")
    .nullable()
    .default(""),
  currency: yup.string().nullable().default(""),
  is_live: yup.boolean().nullable().default(false),
  global_account: yup.boolean().nullable().default(false),
  is_master_account: yup.boolean().nullable().default(false),
  website_api_key: yup.string().nullable().default(""),
  status: yup.string().nullable().default("AC"),
  username: yup.string().nullable().default(""),
  password: yup.string().nullable().default(""),
  application_key: yup.string().nullable().default(""),
  access_token: yup.string().nullable().default(""),
  api_url: yup.string().url("Invalid URL format").nullable().default(""),
  api_key: yup.string().nullable().default(""),
});

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditRailEuropeApiConfigModal: {
    ...modalGenericStyles.modal,
    zIndex: "990",
  },
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: cardStyles.body,
  inputGroup: { ...formInputGroup, padding: `${variables.normal_gap} 0` },
  entityAutoComplete: {
    ...inputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCard: {
      minWidth: "50rem",
    },
    cardBody: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    cardCol2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
});
const EditRailEuropeApiConfigModal = ({ apiConfigId, onClose }) => {
  const classes = styles();

  const mode = apiConfigId ? "edit" : "add";

  const queryClient = useQueryClient();

  // const [form, setForm] = useState(initialValues);
  const [form, setForm] = useState(jsonSchema.cast({}));
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["EditRailEuropeApiConfigModal"],
    queryFn: () => retrieveRailEuropeAPIConfig({ uid: apiConfigId }),
    enabled: mode === "edit",
    onSuccess: (data) => {
      // setForm(_.get(data, "data", {}));
      setForm(jsonSchema.cast(_.get(data, "data", {})));
      // setAutocompleteValue(_.get(data, "data.source_entity", ""));
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const handleInputChange = (e) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const editMutation = useMutation(
    async ({ uid, payload }) =>
      await patchRailEuropeAPIConfig({ uid, payload }),
    {
      onSuccess: () => {
        toast.success("Rail Europe Api Configuration Updated");
        queryClient.invalidateQueries("RailEuropeApiConfig");
        onClose();
      },
      onError: (error) => {
        genericRequestErrorHandler(error);
      },
    }
  );
  const addMutation = useMutation(
    async ({ payload }) => await CreateNewRailEuropeAPIConfig({ payload }),
    {
      onSuccess: () => {
        toast.success("Rail Europe Api Configuration Added");
        queryClient.invalidateQueries("RailEuropeApiConfig");
        onClose();
      },
      onError: (error) => {
        genericRequestErrorHandler(error);
      },
    }
  );

  const onSubmit = async ({ uid, payload }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload });
      } else {
        editMutation.mutate({ uid, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const boolFields = convertStringToBoolean({
    is_master_account: form.is_master_account,
    is_live: form.is_live,
    global_account: form.global_account,
  });

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;
  return (
    <div
      id="addEditRailEuropeEngineAPIConfigurationModal"
      className={classes.EditRailEuropeApiConfigModal}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} Rail Engine Api Configuration</span>
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          {loading && <Loader onTop={true} />}
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} Rail Engine Api Configuration</span>
          </div>
          <div className={classes.modalCardBody}>
            <form>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Data</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="name">Name</label>
                      <input
                        id="name"
                        type="text"
                        name="name"
                        value={_.capitalize(form.name)}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="supplier">Supplier</label>
                      <select
                        id="supplier"
                        name="supplier"
                        value={form.supplier}
                        onChange={handleInputChange}>
                        {Object.entries(supplierOptionsMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="source_entity">Source Entity</label>
                      <div className={classes.entityAutoComplete}>
                        <EntityAutocomplete
                          id="entityAutocompleteInputId"
                          subagents={false}
                          independentAgents={false}
                          branches={false}
                          value={autocompleteValue}
                          setValue={setAutocompleteValue}
                          onChange={(text) => {
                            if (text.length === 0) {
                              setForm((p) => ({
                                ...p,
                                source_entity: "",
                              }));
                              return;
                            }
                            const entityInfos = text.split("---");
                            const entityType = entityInfos[0];
                            const entityId = entityInfos[1];
                            setForm((p) => ({
                              ...p,
                              source_entity: `${entityId}___${entityType}`,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="market_type">Market Type</label>
                      <select
                        id="market_type"
                        name="market_type"
                        value={form.market_type}
                        onChange={handleInputChange}>
                        {Object.entries(marketTypeMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="currency">Currency</label>
                      <select
                        id="currency"
                        name="currency"
                        value={form.currency}
                        onChange={handleInputChange}>
                        {Object.entries(currencies).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    <div className={classes.inputGroup}>
                      <label htmlFor="is_live">Is Live</label>
                      <select
                        id="is_live"
                        name="is_live"
                        value={form.is_live}
                        onChange={handleInputChange}>
                        {Object.entries(trueFalseMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="global_account">Is Global</label>
                      <select
                        id="global_account"
                        name="global_account"
                        value={form.global_account}
                        onChange={handleInputChange}>
                        {Object.entries(trueFalseMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="is_master_account">
                        Is Master Account
                      </label>
                      <select
                        id="is_master_account"
                        name="is_master_account"
                        value={form.is_master_account}
                        onChange={handleInputChange}>
                        {Object.entries(trueFalseMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label>Website Api Key</label>
                      <input
                        id="website_api_key"
                        type="text"
                        name="website_api_key"
                        value={form.website_api_key}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="status">Status</label>
                      <select
                        id="status"
                        name="status"
                        value={form.status}
                        onChange={handleInputChange}>
                        {Object.entries(APIConfigsStatusMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>API Credentials</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="username">Username</label>
                      <input
                        id="username"
                        type="text"
                        name="username"
                        value={form.username}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="password">Password</label>
                      <input
                        id="password"
                        type="text"
                        name="password"
                        value={form.password}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="application_key">Application Key</label>
                      <input
                        id="application_key"
                        type="text"
                        name="application_key"
                        value={form.application_key}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="access_token">Access Token</label>
                      <input
                        id="access_token"
                        type="text"
                        name="access_token"
                        value={form.access_token}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="api_url">Api URL</label>
                      <input
                        id="api_url"
                        type="text"
                        name="api_url"
                        value={form.api_url}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="api_key">Api Key</label>
                      <input
                        id="api_key"
                        type="text"
                        name="api_key"
                        value={form.api_key}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className={classes.cardActions}>
            <CustomButton id="cancel" appearance="ghost" onClick={onClose}>
              <strong> Cancel</strong>
            </CustomButton>
            <CustomButton
              id="submit"
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  uid: apiConfigId,
                  payload: { ...form, ...boolFields },
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditRailEuropeApiConfigModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditRailEuropeApiConfigModal;
