import axios from "axios";
import { SISI_API_URL } from "../api";

async function subscribeToDeimos(payload) {
  try {
    const result = await axios.post(
      `${SISI_API_URL}/deimos/web-push/subscribe/`,
      payload
    );
    return result.data;
  } catch (error) {
    return null;
  }
}

const userAgentType = () => {
  if (window.ActiveXObject) {
    return "IE";
  } else if (document.getBoxObjectFor) {
    return "Firefox";
  } else if (window.MessageEvent && !document.getBoxObjectFor) {
    return "Chrome";
  } else if (window.opera) {
    return "Opera";
  } else if (window.openDatabase) {
    return "Safari";
  }
  return null;
};

function subscribeUserToPush(registration, publicKey) {
  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: publicKey,
  };

  if (!registration.pushManager) {
    return null;
  }

  return registration.pushManager
    .getSubscription()
    .then(async (subscription) => {
      if (subscription) {
        return subscription;
      }

      try {
        const subscription = await registration.pushManager.subscribe(
          subscribeOptions
        );
        return subscription;
      } catch (error) {
        return null;
      }
    });
}

export function registerToNotifications({ user_id, user_email }) {
  if (!("Notification" in window)) {
    return;
  }
  if (!("serviceWorker" in navigator)) {
    return;
  }

  navigator.serviceWorker
    .register("sw.js")
    .then(async (registration) => {
      const permission = await Notification.requestPermission();
      if (permission !== "granted") {
        return;
      }

      const res = await subscribeUserToPush(
        registration,
        "BNMzyKJspJX57ye5hPNY8TwdvP5rCWbfqqjQzk0bevd7lkp4BKvP_DmVNyp1KinSWw5Y8zIoQ-6GuxIosZ7Ba6U"
      );

      if (res) {
        const subscriptionData = JSON.parse(JSON.stringify(res));

        const payload = {
          auth: subscriptionData.keys.auth,
          endpoint: subscriptionData.endpoint,
          mode: "prod",
          browser: userAgentType(),
          user: user_id,
          email: user_email,
          scope: "veritas",
          p256dh: subscriptionData.keys.p256dh,
        };

        await subscribeToDeimos(payload);
      }
    })
    .catch((error) => {});
}
