import { useCallback, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { GlobalModalContext } from "./App";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "./api";
import { toast } from "react-toastify";

export function useAuthentication(
  { info_only = false } = { info_only: false }
) {
  const {
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getIdTokenClaims,
  } = useAuth0();

  // async function getJWT() {
  //   const tokenData = await getIdTokenClaims();
  //   return tokenData.__raw;
  // }

  async function getJWT() {
    const tokenData = await getIdTokenClaims();
    return tokenData?.__raw;
  }

  useEffect(() => {
    if (info_only) {
      return;
    }

    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated]);

  return { user, isAuthenticated, logout, loginWithRedirect, getJWT };
}

export const useListPageSidebar = () => {
  const { setOpenSidebar } = useContext(GlobalModalContext);

  useEffect(() => {
    setOpenSidebar(false);
  }, [setOpenSidebar]);
};

export const useWebSiteDetails = ({
  uid,
  retrieveWebSiteData,
  onCloseDetails,
}) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async () => {
    const result = await retrieveWebSiteData({
      uid,
    });
    setLoading(true);
    if (!result) {
      onCloseDetails();
      setLoading(false);
      return;
    }
    setData(result);
    setLoading(false);
  }, [onCloseDetails, uid]);
  useEffect(() => {
    if (!data) {
      onCloseDetails();
    }
    getData();
  }, []);
  return { loading, data };
};

const useReservationDetails = ({
  reference,
  retrieveReservationData,
  onCloseDetails,
}) => {
  const queryClient = useQueryClient();

  const [guestEditMode, setGuestEditMode] = useState(false);
  const [guestEditId, setGuestEditId] = useState(null);
  const [editCRSTagMode, setEditCRSTagMode] = useState(false);
  const [reservationEventsModal, setReservationEventsModal] = useState(false);

  const closeEditCRSTagModal = () => {
    setEditCRSTagMode(false);
    queryClient.invalidateQueries(["getDataCRSRetrieveModals"]);
  };
  const openEditCRSTagModal = () => {
    setEditCRSTagMode(true);
  };

  const openGuestModal = (id) => {
    setGuestEditId(id);
    setGuestEditMode(true);
  };
  const closeGuestModal = () => {
    setGuestEditMode(false);
    queryClient.invalidateQueries(["getDataCRSRetrieveModals"]);
    setGuestEditId(null);
  };

  const openReservationEventsModal = () => {
    setReservationEventsModal(true);
  };
  const closeReservationEventsModal = () => {
    setReservationEventsModal(false);
  };

  const { data, isLoading: loading } = useQuery({
    queryKey: ["getDataCRSRetrieveModals", reference],
    queryFn: () => retrieveReservationData({ reference }),
    onError: (error) => genericRequestErrorHandler(error),
  });

  return {
    loading,
    data,
    guestEditId,
    guestEditMode,
    editCRSTagMode,
    reservationEventsModal,
    openEditCRSTagModal,
    closeEditCRSTagModal,
    openGuestModal,
    closeGuestModal,
    openReservationEventsModal,
    closeReservationEventsModal,
  };
};
export default useReservationDetails;
