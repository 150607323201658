import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import {
  formInputGroup,
  modalGenericStyles,
  variables,
  inputGroup,
  cardStyles,
} from "../../../../variables";
import PropTypes from "prop-types";
import {
  createWebSite,
  patchWebSite,
  retrieveWebsite,
} from "../../../../api/DistributionSite/webSites";
import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import EntityAutocomplete from "../../../../components/EntityAutocomplete";
import { genericRequestErrorHandler } from "../../../../api";
import WebsitesLanguages from "../../WebSites/Components/WebsitesLanguages";
import TranslateButton from "../../WebSites/Components/TranslateButton";
import {
  convertStringToBoolean,
  handleValidationErrors,
  websiteLanguages,
} from "../../../../helpers";
import { jsonSchema } from "./form-jsonSchema";
import { JSEditor } from "../../../CRM/Members/helpers/JSEditor";
import { CustomButton } from "../../../../components/CustomButton";

const marketTypeMapping = {
  "": "-----",
  B2B: "B2B",
  B2C: "B2C",
};

const authTypeMapping = {
  "": "-----",
  auth0: "Auth0",
  external_auth0: "External Auth0",
  external: "External",
};

const productCategoriesMapping = {
  "": "-----",
  MI: "Add Ons",
  PKG: "Packages",
  BOTH: "Add Ons & Packages",
};

const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};
const trueFalseMapping = {
  "": "-----",
  false: "No",
  true: "Yes",
};
const servicesMapping = {
  accommodation: "ACC",
  activities: "ACT",
  flights: "FL",
  addons: "MI",
  transfers: "TRF",
  restaurants: "RES",
};
const servicesMappingReverse = {
  ACC: "Accommodation",
  ACT: "Activities",
  FL: "Flights",
  MI: "Addons",
  TRF: "Transfers",
  RES: "Restaurants",
};
const paymentSupplierMapping = {
  "": "-----",
  STR: "Stripe",
  BCR: "Barclays Pay",
  VIV: "Viva",
};
const paymentTypeMapping = {
  "": "-----",
  NAT: "Native",
  HOS: "Hosted",
  NO: "No Payment Required",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditWebSiteModal: { ...modalGenericStyles.modal },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  languages: {
    display: "grid",
    padding: variables.double_gap,
  },
  languagesButton: {
    padding: variables.normal_gap,
    gap: variables.normal_gap,
    borderRadius: "5px",
    cursor: "pointer",
    fontWeight: "bold",
    transition: "background .s, color .3s",
    "&:hover": {
      background: variables.colors.background.darkHover,
      color: variables.colors.text.light,
    },
  },
  translateButton: {
    padding: variables.normal_gap,
    alignSelf: "center",
  },
  inputGroup: formInputGroup,
  entityAutoComplete: {
    ...inputGroup,
    "& select, input": {
      boxSizing: "border-box",
      height: `calc(${variables.normal_gap} * 2.6)`,
      width: "100%",
    },
  },
  description: {
    gap: variables.normal_gap,
    "& label": {
      fontSize: "small",
    },
  },
  servicesCardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    display: "grid",
    padding: "",
    paddingBottom: variables.normal_gap,
  },
  trueServices: {
    display: "grid",
    gap: "8px",
  },
  serviceItem: {
    display: "grid",
    gridTemplateColumns: "10rem max-content",
    padding: variables.normal_gap,
    alignItems: "left",
    gap: variables.normal_gap,
    fontSize: "small",
  },
  serviceButtons: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: variables.normal_gap,
    justifyContent: "center",
  },
  upButton: {
    border: `solid 1px ${variables.colors.primary}`,
    borderRadius: "4px",
    cursor: "pointer",
  },
  downButton: {
    border: `solid 1px ${variables.colors.primary}`,
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "small",
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCard: {
      minWidth: "65rem",
    },
    languages: {
      gridTemplateColumns: "1fr max-content",
      gap: variables.normal_gap,
    },
    cardCol2: {
      gridTemplateColumns: "repeat(2 , 1fr)",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
});

const EditWebSiteModal = ({ webSiteId, onClose }) => {
  const classes = styles();
  const mode = webSiteId ? "edit" : "add";

  const [form, setForm] = useState(jsonSchema.cast({}));
  const [activeLanguage, setActiveLanguage] = useState("en");
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["EditDistributionWebsiteModal", webSiteId],
    queryFn: () => retrieveWebsite({ uid: webSiteId }),
    onSuccess: (data) => {
      setForm(jsonSchema.cast(_.get(data, "data", {}), { stripUnknown: true }));
      setAutocompleteValue(_.get(data, "data.source_entity"));
    },
    onError: (error) => {
      toast.error(error.message);
      onClose();
    },
    enabled: mode === "edit",
    refetchOnWindowFocus: false,
  });

  const addMutation = useMutation({
    mutationFn: ({ payload }) => {
      return createWebSite({ payload });
    },
    onSuccess: () => {
      toast.success("New Website added");
      queryClient.invalidateQueries("DistributionSites");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const editMutation = useMutation({
    mutationFn: async ({ uid, payload }) => {
      return patchWebSite({ uid, payload });
    },
    onSuccess: () => {
      toast.success("Website Info Updated");
      queryClient.invalidateQueries("DistributionSites");
      onClose();
    },
  });

  useEffect(() => {
    if (mode === "edit" && form.service_order.length === 0) {
      // Populate form.service_order with true values from specific services
      const trueServices = [
        "accommodation",
        "activities",
        "flights",
        "addons",
        "transfers",
        "restaurants",
      ];
      const initialServiceOrder = trueServices
        .filter((service) => form[service])
        .map((service) => servicesMapping[service]);

      setForm((p) => ({
        ...p,
        service_order: initialServiceOrder,
      }));
    }
  }, [mode, form]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm((p) => {
      const updatedForm = { ...p, [name]: value };

      // Update service_order array when a service is selected
      if (value === "true") {
        updatedForm.service_order = [...p.service_order, servicesMapping[name]];
      } else {
        // Remove service from service_order array when unselected
        updatedForm.service_order = p.service_order.filter(
          (service) => service !== servicesMapping[name]
        );
      }

      return updatedForm;
    });
  };

  const onSubmit = async ({ uid, payload }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });

      // Update payload with the mapped service_order
      const updatedPayload = {
        ...payload,
      };
      if (mode === "add") {
        addMutation.mutate({ payload: updatedPayload });
      } else {
        editMutation.mutate({ uid, payload: updatedPayload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const moveServiceUp = (idx) => {
    setForm((p) => {
      const updatedOrder = [...p.service_order];
      [updatedOrder[idx - 1], updatedOrder[idx]] = [
        updatedOrder[idx],
        updatedOrder[idx - 1],
      ];
      return { ...p, service_order: updatedOrder };
    });
  };

  const moveServiceDown = (idx) => {
    setForm((p) => {
      const updatedOrder = [...p.service_order];
      [updatedOrder[idx], updatedOrder[idx + 1]] = [
        updatedOrder[idx + 1],
        updatedOrder[idx],
      ];
      return { ...p, service_order: updatedOrder };
    });
  };

  const boolFields = convertStringToBoolean({
    private_website: form.private_website,
    packages: form.packages,
    marketplace: form.marketplace,
    accommodation: form.accommodation,
    activities: form.activities,
    flights: form.flights,
    addons: form.addons,
    transfers: form.transfers,
    restaurants: form.restaurants,
  });

  const loading =
    (mode === "edit" && retrieveLoading) ||
    addMutation.isLoading ||
    editMutation.isLoading;
  return (
    <div id="addEditDistributionSite" className={classes.EditWebSiteModal}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} Web Site</span>
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            <span>{_.startCase(mode)} Web Site</span>
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.languages}>
              <div className={classes.languagesButtons}>
                <WebsitesLanguages
                  activeLanguage={activeLanguage}
                  setActiveLanguage={setActiveLanguage}
                  languages={websiteLanguages}
                />
              </div>
              <div className={classes.translateButton}>
                <TranslateButton
                  targetLang={activeLanguage}
                  fields={[
                    "acc_label",
                    "act_label",
                    "car_label",
                    "fer_label",
                    "fl_label",
                    "trf_label",
                    "hotel_booking_terms_label",
                    // "",
                  ]}
                  form={form}
                  setForm={setForm}
                />
              </div>
            </div>
            <React.Fragment>
              <form onChange={handleInputChange}>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Basic Information</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol2}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="name">Name</label>
                        <input
                          id="name"
                          type="text"
                          name="name"
                          defaultValue={form.name}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="source_entity">Source Entity</label>
                        <div className={classes.entityAutoComplete}>
                          <EntityAutocomplete
                            id="entityAutocompleteInputId"
                            subagents={false}
                            independentAgents={false}
                            branches={false}
                            value={autocompleteValue}
                            setValue={setAutocompleteValue}
                            onChange={(text) => {
                              if (text.length === 0) {
                                setForm((p) => ({
                                  ...p,
                                  source_entity: "",
                                }));
                                return;
                              }
                              const entityInfos = text.split("---");
                              const entityType = entityInfos[0];
                              const entityId = entityInfos[1];
                              setForm((p) => ({
                                ...p,
                                source_entity: `${entityId}___${entityType}`,
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="production_url">Production URL</label>
                        <input
                          id="production_url"
                          type="text"
                          name="production_url"
                          defaultValue={form.production_url}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="staging_url">Staging URL</label>
                        <input
                          id="staging_url"
                          type="text"
                          name="staging_url"
                          defaultValue={form.staging_url}
                        />
                      </div>
                    </div>
                    <div className={classes.cardCol3}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="packages">Packages</label>
                        <select
                          id="packages"
                          name="packages"
                          value={form.packages}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(trueFalseMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="market_type">Market Type</label>
                        <select
                          id="market_type"
                          name="market_type"
                          value={form.market_type}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(marketTypeMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="private_website">Private Website</label>
                        <select
                          id="private_website"
                          name="private_website"
                          value={form.private_website}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(trueFalseMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="marketplace">Maketplace</label>
                        <select
                          id="marketplace"
                          name="marketplace"
                          value={form.marketplace}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(trueFalseMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="auth_type">Auth Type</label>
                        <select
                          id="auth_type"
                          name="auth_type"
                          value={form.auth_type}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(authTypeMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="product_categories">
                          Product Categories
                        </label>
                        <select
                          id="product_categories"
                          name="product_categories"
                          value={form.product_categories}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(productCategoriesMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Services Labels</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol3}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="acc_label">Accommodations Label</label>
                        {Object.entries(websiteLanguages).map(
                          ([key, label], idx) =>
                            key === activeLanguage ? (
                              <input
                                id={`acc_label_${key}`}
                                type="text"
                                key={key}
                                value={
                                  key === "en"
                                    ? form.acc_label
                                    : form[`acc_label_${key}`]
                                }
                                onChange={(e) => {
                                  const { value } = e.target;
                                  let newForm = {
                                    ...form,
                                    [key === "en"
                                      ? "acc_label"
                                      : `acc_label_${key}`]: value,
                                  };
                                  setForm(newForm);
                                }}
                              />
                            ) : null
                        )}
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="act_label_">Activities Label</label>
                        {Object.entries(websiteLanguages).map(
                          ([key, label], idx) =>
                            key === activeLanguage ? (
                              <input
                                id={`act_label_${key}`}
                                type="text"
                                key={key}
                                value={
                                  key === "en"
                                    ? form.act_label
                                    : form[`act_label_${key}`]
                                }
                                onChange={(e) => {
                                  const { value } = e.target;
                                  let newForm = {
                                    ...form,
                                    [key === "en"
                                      ? "act_label"
                                      : `act_label_${key}`]: value,
                                  };
                                  setForm(newForm);
                                }}
                              />
                            ) : null
                        )}
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="car_label_">Car Rental Label</label>
                        {Object.entries(websiteLanguages).map(
                          ([key, label], idx) =>
                            key === activeLanguage ? (
                              <input
                                id={`car_label_${key}`}
                                type="text"
                                key={key}
                                value={
                                  key === "en"
                                    ? form.car_label
                                    : form[`car_label_${key}`]
                                }
                                onChange={(e) => {
                                  const { value } = e.target;
                                  let newForm = {
                                    ...form,
                                    [key === "en"
                                      ? "car_label"
                                      : `car_label_${key}`]: value,
                                  };
                                  setForm(newForm);
                                }}
                              />
                            ) : null
                        )}
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="fer_label_">Ferries Label</label>
                        {Object.entries(websiteLanguages).map(
                          ([key, label], idx) =>
                            key === activeLanguage ? (
                              <input
                                id={`fer_label_${key}`}
                                type="text"
                                key={key}
                                value={
                                  key === "en"
                                    ? form.fer_label
                                    : form[`fer_label_${key}`]
                                }
                                onChange={(e) => {
                                  const { value } = e.target;
                                  let newForm = {
                                    ...form,
                                    [key === "en"
                                      ? "fer_label"
                                      : `fer_label_${key}`]: value,
                                  };
                                  setForm(newForm);
                                }}
                              />
                            ) : null
                        )}
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="fl_label_">Flights Label</label>
                        {Object.entries(websiteLanguages).map(
                          ([key, label], idx) =>
                            key === activeLanguage ? (
                              <input
                                id={`fl_label_${key}`}
                                type="text"
                                key={key}
                                value={
                                  key === "en"
                                    ? form.fl_label
                                    : form[`fl_label_${key}`]
                                }
                                onChange={(e) => {
                                  const { value } = e.target;
                                  let newForm = {
                                    ...form,
                                    [key === "en"
                                      ? "fl_label"
                                      : `fl_label_${key}`]: value,
                                  };
                                  setForm(newForm);
                                }}
                              />
                            ) : null
                        )}
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="tra_label">Trains Label</label>
                        {Object.entries(websiteLanguages).map(
                          ([key, label], idx) =>
                            key === activeLanguage ? (
                              <input
                                id={`tra_label_${key}`}
                                type="text"
                                key={key}
                                value={
                                  key === "en"
                                    ? form.tra_label
                                    : form[`tra_label_${key}`]
                                }
                                onChange={(e) => {
                                  const { value } = e.target;
                                  let newForm = {
                                    ...form,
                                    [key === "en"
                                      ? "tra_label"
                                      : `tra_label_${key}`]: value,
                                  };
                                  setForm(newForm);
                                }}
                              />
                            ) : null
                        )}
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="trf_label">Transfers Label</label>
                        {Object.entries(websiteLanguages).map(
                          ([key, label], idx) =>
                            key === activeLanguage ? (
                              <input
                                id={`trf_label_${key}`}
                                type="text"
                                key={key}
                                value={
                                  key === "en"
                                    ? form.trf_label
                                    : form[`trf_label_${key}`]
                                }
                                onChange={(e) => {
                                  const { value } = e.target;
                                  let newForm = {
                                    ...form,
                                    [key === "en"
                                      ? "trf_label"
                                      : `trf_label_${key}`]: value,
                                  };
                                  setForm(newForm);
                                }}
                              />
                            ) : null
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Payments</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol3}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="payment_supplier">
                          Payment Supplier
                        </label>
                        <select
                          id="payment_supplier"
                          name="payment_supplier"
                          value={form.payment_supplier}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(paymentSupplierMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="payment_type">Payment Type</label>
                        <select
                          id="payment_type"
                          name="payment_type"
                          value={form.payment_type}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(paymentTypeMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="status">Status</label>
                        <select
                          id="status"
                          name="status"
                          value={form.status}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(statusMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="s3_bucket">S3 Bucket</label>
                        <input
                          id="s3_bucket"
                          type="text"
                          name="s3_bucket"
                          defaultValue={form.s3_bucket}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Booking Terms</div>
                  <div className={classes.cardBody}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="hotel_booking_terms_label">
                        Hotel Booking Terms
                      </label>
                      {Object.entries(websiteLanguages).map(
                        ([key, label], idx) =>
                          key === activeLanguage ? (
                            <input
                              id={`hotel_booking_terms_label_${key}`}
                              key={key}
                              value={
                                key === "en"
                                  ? form.hotel_booking_terms_label
                                  : form[`hotel_booking_terms_label_${key}`]
                              }
                              onChange={(e) => {
                                let newForm = {
                                  ...form,
                                  [key === "en"
                                    ? "hotel_booking_terms_label"
                                    : `hotel_booking_terms_label_${key}`]:
                                    e.target.value,
                                };
                                setForm(newForm);
                              }}
                            />
                          ) : null
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Services</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol3}>
                      <div className={classes.inputGroup}>
                        <label htmlFor="accommodation">Accommodation</label>
                        <select
                          id="accommodation"
                          name="accommodation"
                          value={form.accommodation}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(trueFalseMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="activities">Activities</label>
                        <select
                          id="activities"
                          name="activities"
                          value={form.activities}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(trueFalseMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="flights">Flights</label>
                        <select
                          id="flights"
                          name="flights"
                          value={form.flights}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(trueFalseMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="addons">Addons</label>
                        <select
                          id="addons"
                          name="addons"
                          value={form.addons}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(trueFalseMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="transfers">Transfers</label>
                        <select
                          id="transfers"
                          name="transfers"
                          value={form.transfers}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(trueFalseMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.inputGroup}>
                        <label htmlFor="restaurants">Restaurants</label>
                        <select
                          id="restaurants"
                          name="restaurants"
                          value={form.restaurants}
                          onChange={() => {
                            return;
                          }}>
                          {Object.entries(trueFalseMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className={classes.card}>
                    <div className={classes.cardHeader}>Service Order</div>
                    <div className={classes.cardBody}>
                      <div className={classes.inputGroup}>
                        <div className={classes.trueServices}>
                          {form.service_order.map((service, idx) => (
                            <div key={idx} className={classes.serviceItem}>
                              <div className={classes.service}>
                                <span>
                                  {_.get(servicesMappingReverse, service) ||
                                    "N/A"}
                                </span>
                              </div>
                              <div className={classes.serviceButtons}>
                                <button
                                  id="upButton"
                                  className={classes.upButton}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    moveServiceUp(idx);
                                  }}
                                  disabled={idx === 0}>
                                  Up
                                </button>
                                <button
                                  id="downButton"
                                  className={classes.downButton}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    moveServiceDown(idx);
                                  }}
                                  disabled={
                                    idx === form.service_order.length - 1
                                  }>
                                  Down
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Color Selection</div>
                  <div className={classes.cardBody}>
                    {
                      <JSEditor
                        label="Colour Selection"
                        value={form.website_colors}
                        onChange={(newValue) => {
                          setForm((p) => ({
                            ...p,
                            website_colors: newValue,
                          }));
                        }}
                      />
                    }
                  </div>
                </div>
              </form>
            </React.Fragment>
          </div>
          <div className={classes.cardActions}>
            <CustomButton
              id="cancel"
              appearance="ghost"
              onClick={() => onClose()}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="submit"
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  uid: webSiteId,
                  payload: { ...form, ...boolFields },
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditWebSiteModal.propTypes = {
  webSiteId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditWebSiteModal;
