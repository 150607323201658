import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  cardStyles,
  tableStyles,
  variables,
  modalGenericStyles,
} from "../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { CustomButton } from "../../../../components/CustomButton";

function logStyles(props) {
  return {
    maxHeight: "1000vh",
    maxWidth: "100%",
  };
}

function logHeaderStyles() {
  return {
    display: "inline-grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: variables.normal_gap,
    alignItems: "center",
  };
}

const commonSyntaxHighlighterProps = {
  language: "javascript",
  customStyle: { fontSize: "smaller" },
};

const commonLogStyles = createUseStyles({
  CommonLog: (props) => logStyles(props),
  logHeader: logHeaderStyles(),
  cardHeader: cardStyles.header,
  CommonLogcardBody: {
    ...cardStyles.body,
    justifyItems: "left",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  CommonLogBody: {
    overflow: "auto",
    maxHeight: "20rem",
    maxWidth: "50rem",
  },
  cardActions: modalGenericStyles.cardActions,
  [`@media ${variables.media.smallscreen}`]: {
    CommonLogcardBody: {
      maxHeight: "100%",
      maxWidth: "100%",
    },
  },
});

const CommonLog = ({ title, data }) => {
  const classes = commonLogStyles();

  const [collapsed, setCollapsed] = useState(true);

  const timestamp = _.get(data, "reservation_events");

  return (
    <div id="commonLogList" className={classes.CommonLog}>
      <div className={classes.cardHeader}>
        <h5 className={classes.logHeader}>
          {title}
          <div className={classes.log}>
            <CustomButton
              id="expandCollapseButton"
              appearance="ghost"
              onClick={() => setCollapsed((p) => !p)}>
              <strong>{collapsed ? "Expand" : "Collapse"}</strong>
            </CustomButton>
          </div>
        </h5>
      </div>
      {!collapsed && (
        <div className={classes.CommonLogcardBody}>
          <div className={classes.CommonLogBody}>
            <SyntaxHighlighter {...commonSyntaxHighlighterProps}>
              {JSON.stringify(data ?? {}, null, 2)}
            </SyntaxHighlighter>
          </div>
        </div>
      )}
    </div>
  );
};
commonLogStyles.defaultProps = {
  index: 1,
};
CommonLog.propTypes = {
  title: PropTypes.string,
  index: PropTypes.number,
  data: PropTypes.object.isRequired,
};

const styles = createUseStyles({
  ...cardStyles,
  InternalLogs: {},
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
  logHeader: {
    display: "inline-grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: variables.normal_gap,
    alignItems: "center",
  },
  card: cardStyles.card,
  cardBody: { ...cardStyles.body, overflowY: "hidden" },
});

export const InternalLogs = ({ prebookData, bookData }) => {
  const classes = styles();

  return (
    <div id="internalLogsList" className={classes.InternalLogs}>
      <div className={classes.card}>
        <div className={classes.cardBody}>
          <CommonLog title="Prebook" data={prebookData} />
          {/* </div> */}
          {/* <div className={classes.card}> */}
          <CommonLog title="Book" data={bookData} />
        </div>
      </div>
    </div>
  );
};

InternalLogs.propTypes = {
  prebookData: PropTypes.object.isRequired,
  bookData: PropTypes.object.isRequired,
};
