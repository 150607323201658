import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  btnStyles,
  genericIndexesStyles,
  modalGenericStyles,
  tableStyles,
  variables,
  cardStyles,
} from "../../../../../variables";
import PropTypes from "prop-types";
import Loader from "../../../../../components/Loader";
import { DateTime } from "luxon";
import { statusMapping } from "../../common";
import _ from "lodash";
import { fetchWebSiteAboutUs } from "../../../../../api/DistributionSite/webSites/AboutUs";
import EditAboutUsModal from "../EditAboutUs";
import DeleteAboutUs from "../DeleteAboutUs";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  AboutUs: {
    ...cardStyles.card,
  },
  cardBody: {
    ...modalGenericStyles.cardBody,
    // minHeight: "30vh",
    // textAlign: "-webkit-center",
  },
  loader: { width: "100vw" },
  cardActions: {
    ...cardStyles.cardActions,
  },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: tableStyles,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: tableStyles.body.cell,
  btn: btnStyles.btn,
  actionCell: {
    ...genericIndexesStyles.actionCell.mobile(),
    padding: variables.double_gap,
  },
  add: {
    padding: "0.75rem",
    textAlign: "end",
  },
  addBtn: { ...btnStyles.btn, width: "4rem" },
  [`@media ${variables.media.smallscreen}`]: {
    loader: { width: "100%" },
  },
});

const AboutUs = ({ sourceEntity, websiteId }) => {
  const classes = styles();

  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [about, setAbout] = useState(null);

  const { data: aboutUs, isLoading } = useQuery({
    queryKey: ["AboutUs"],
    queryFn: async () =>
      await fetchWebSiteAboutUs({
        source_entity: sourceEntity,
        website: websiteId,
      }),
    onError: (error) => genericRequestErrorHandler(error),
  });

  return (
    <div className={classes.AboutUs}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          About Us
          <div className={classes.add}>
            <CustomButton
              appearance="ghost"
              onClick={() => {
                setEditMode(true);
                setEditId(null);
              }}>
              <strong>Add</strong>
            </CustomButton>
          </div>
        </div>

        <div className={classes.tableContainer}>
          {isLoading && <Loader onTop={true} />}
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Id</th>
                <th className={classes.headCell}>Name</th>
                <th className={classes.headCell}>Active From</th>
                <th className={classes.headCell}>Active To</th>
                <th className={classes.headCell}>Status</th>
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.get(aboutUs, "data.results", []).map((about, idx) => (
                <tr key={idx} className={classes.bodyRow}>
                  <td className={classes.cell}>{idx + 1}</td>
                  <td className={classes.cell}>{about.id}</td>
                  <td className={classes.cell}>{about.name || "N/A"}</td>

                  <td className={classes.cell}>
                    {about.active_from
                      ? DateTime.fromISO(about.active_from).toLocaleString(
                          DateTime.DATE_MED_WITH_WEEKDAY
                        )
                      : "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {about.active_to
                      ? DateTime.fromISO(about.active_to).toLocaleString(
                          DateTime.DATE_MED_WITH_WEEKDAY
                        )
                      : "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {_.get(statusMapping, _.get(about, "status")) || "N/A"}
                  </td>
                  <td className={classes.actionCell}>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        setEditId(about.id);
                        setEditMode(true);
                      }}>
                      <strong>Edit</strong>
                    </CustomButton>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        setAbout(about);
                        setDeleteId(about.id);
                        setDeleteModal(true);
                        // onDe><
                      }}>
                      <strong>Delete</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* </div> */}
      </div>
      {editMode && (
        <EditAboutUsModal
          aboutUsId={editId}
          sourceEntity={sourceEntity}
          onClose={() => {
            setEditMode(false);
            setEditId(null);
          }}
        />
      )}
      {deleteModal && (
        <DeleteAboutUs
          data={about}
          id={deleteId}
          onClose={() => {
            setDeleteModal(false);
          }}
        />
      )}
    </div>
  );
};
AboutUs.propTypes = {
  sourceEntity: PropTypes.string.isRequired,
  websiteId: PropTypes.number.isRequired,
};
export default AboutUs;
