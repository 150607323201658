import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  btnStyles,
  modalGenericStyles,
  tableStyles,
  variables,
  genericIndexesStyles,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { fetchWebSiteGenericSections } from "../../../../../api/DistributionSite/webSites/GenericSections";
import { DateTime } from "luxon";
import Loader from "../../../../../components/Loader";
import EditGenericSections from "../EditGenericSections";
import DeleteGenericSection from "../DeleteGenericSection";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import _ from "lodash";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  GenericSectionsModal: {
    ...modalGenericStyles.card,
  },
  loader: { width: "100vw" },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: tableStyles,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: tableStyles.body.cell,
  actionCell: {
    ...genericIndexesStyles.actionCell.mobile(),
  },
  btn: btnStyles.btn,
  add: {
    padding: "0.75rem",
    textAlign: "end",
  },
  addBtn: { ...btnStyles.btn, width: "4rem" },
  [`@media ${variables.media.smallscreen}`]: {},
});

const GenericSectionModal = ({ websiteId }) => {
  const classes = styles();

  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ["GenericSectionModal"],
    queryFn: async () =>
      await fetchWebSiteGenericSections({
        params: { website: websiteId },
        onError: (error) => genericRequestErrorHandler(error),
      }),
  });

  return (
    <div className={classes.GenericSectionModal}>
      <React.Fragment>
        {deleteModal && (
          <DeleteGenericSection
            id={deleteId}
            onClose={() => {
              setDeleteModal(false);
            }}
          />
        )}
        {!deleteModal && (
          <React.Fragment>
            <div className={classes.cardHeader}>
              Generic Section
              <div className={classes.add}>
                <CustomButton
                  appearance="ghost"
                  onClick={() => {
                    setShowEditModal(true);
                    setEditId(null);
                  }}>
                  <strong>Add</strong>
                </CustomButton>
              </div>
            </div>
            <div className={classes.tableContainer}>
              {isLoading ? (
                // <div className={classes.loader}>
                <Loader />
              ) : (
                // </div>
                <table className={classes.table}>
                  <thead className={classes.thead}>
                    <tr className={classes.headRow}>
                      <th className={classes.headCell}>#</th>
                      <th className={classes.headCell}>Id</th>
                      <th className={classes.headCell}>Name</th>
                      <th className={classes.headCell}>Title</th>
                      <th className={classes.headCell}>Path</th>
                      <th className={classes.headCell}>Theme</th>
                      <th className={classes.headCell}>Active From</th>
                      <th className={classes.headCell}>Active To</th>
                      <th className={classes.headCell}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.get(data, "data.results").map((genericSection, idx) => (
                      <tr key={idx} className={classes.bodyRow}>
                        <td className={classes.cell}>{idx + 1}</td>
                        <td className={classes.cell}>{genericSection.id}</td>
                        <td className={classes.cell}>
                          {genericSection.name || "N/A"}
                        </td>
                        <td className={classes.cell}>
                          {genericSection.title || "N/A"}
                        </td>
                        <td className={classes.cell}>
                          {genericSection.path || "N/A"}
                        </td>
                        <td className={classes.cell}>
                          {genericSection.theme || "N/A"}
                        </td>
                        <td className={classes.cell}>
                          {genericSection.active_from
                            ? DateTime.fromISO(
                                genericSection.active_from
                              ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                            : "N/A"}
                        </td>
                        <td className={classes.cell}>
                          {genericSection.active_to
                            ? DateTime.fromISO(
                                genericSection.active_to
                              ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                            : "N/A"}
                        </td>

                        <td className={classes.actionCell}>
                          <CustomButton
                            appearance="ghost"
                            onClick={() => {
                              setEditId(genericSection.id);
                              setShowEditModal(true);
                              // setModalMode("EDIT");
                            }}>
                            <strong>Edit</strong>
                          </CustomButton>
                          <CustomButton
                            appearance="ghost"
                            onClick={() => {
                              setDeleteId(genericSection.id);
                              setDeleteModal(true);
                            }}>
                            <strong>Delete</strong>
                          </CustomButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
      {showEditModal && (
        <EditGenericSections
          genericSetionId={editId}
          websiteId={websiteId}
          onClose={() => {
            setShowEditModal(false);
            setEditId(null);
          }}
        />
      )}
    </div>
  );
};
GenericSectionModal.propTypes = {
  websiteId: PropTypes.number.isRequired,
};
export default GenericSectionModal;
