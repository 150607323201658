import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../variables";
import _ from "lodash";
import Navbar from "./Navbar";
import ControlStrip from "../../../components/ControlStrip";
import { handleApplyFilters, medWeekdayDatetimer } from "../../../helpers";
import Loader from "../../../components/Loader";
import { CarRentalMarketTypeMapping } from "../common";
import {
  cloneCarEngineAPIConfigurations,
  fetchCarEngineAPIConfigurations,
} from "../../../api/CarRentalEngine/ApiConfig";
import { toast } from "react-toastify";
import DetailsApiConfigurationCarEngineModal from "../CarRentalApiConigurationModals/DetailsApiConfigurationCarEngineModal";
import EditApiConfigurationCarEngineModal from "../CarRentalApiConigurationModals/EditApiConfigurationCarEngineModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HamburgerMenu from "../../../components/HamburgerMenu";
import { genericRequestErrorHandler } from "../../../api";
import { useListPageSidebar } from "../../../hooks";
import * as yup from "yup";
import DeleteApiConfigurationCarEngineModal from "../CarRentalApiConigurationModals/DeleteApiConfigurationCarEngineModal";
import { CustomButton } from "../../../components/CustomButton";
import VerifyModal from "../../../components/VerifyModal";
import ReservationsStatusTag from "../../../components/Tags/ReservationsStatusTag";

export const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const initialFilters = {
  page_size: 20,
  page: 1,
  ordering: "",
  discending: "",
  name__icontains: "",
  username__icontains: "",
  password: "",
  application_key: "",
  access_token: "",
  api_url: "",
  api_key: "",
  market_type: "",
  supplier: "",
  source_entity: "",
  global_account: "",
  created__gte: "",
  created__lte: "",
};

const apiConfiguration = createUseStyles({
  ApiConfigurationCarRental: genericIndexesStyles.indexes.mobile(),
  tableContainer: {
    ...genericIndexesStyles.indexes.mobile(),
    overflow: "auto",
  },
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: { ...tableStyles.body.cell },
  actionCell: genericIndexesStyles.actionCell.mobile,
  checkBox: tableStyles.checkBox,
  datesCell: { ...tableStyles.body.cell, width: "9rem" },
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      gridTemplateColumns: "repeat(2,1fr) ",
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
    cell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const ApiConfigurationCarRental = () => {
  const classes = apiConfiguration();

  const queryClient = useQueryClient();
  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [apiConfigUid, setApiConfigUid] = useState(null);
  const [car, setCar] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);

  const tableHeaders = {
    Name: (val) => _.capitalize(_.get(val, "name")),
    Supplier: (val) => _.capitalize(_.get(val, "supplier")),
    "Market Type": (val) =>
      _.get(CarRentalMarketTypeMapping, _.get(val, "market_type")),
    "Source Entity": (val) => val.source_entity,
    "Is Live": (val) => (_.get(val, "is_live") === true ? "Yes" : "No"),
    "Is Global": (val) =>
      _.get(val, "global_account") === true ? "Yes" : "No",
    "Is Own": (val) => (_.get(val, "is_own") === true ? "Yes" : "No"),
    Created: (val) => val.created,
    Status: (val) => <ReservationsStatusTag status={val.status} />,
  };

  const navbarSchema = yup.object().shape({
    name__icontains: yup
      .string()
      .min(1, "Name must be more than 2 characters")
      .max(100, "Name: Must be less than 100 characters"),
    username__icontains: yup
      .string()
      .min(1, "Username must be more than 2 characters")
      .max(100, "Username must be less than 100 characters"),
    created__gte: yup.date(),
    created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
  });
  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };
  const { data, isLoading } = useQuery({
    queryKey: ["ApiConfigurationCarRental", filters],
    queryFn: async () =>
      await fetchCarEngineAPIConfigurations({
        params: filters,
      }),

    enabled: !showDetailsModal && !showEditModal,
    onError: (error) => genericRequestErrorHandler(error),
  });

  const cloneCarApiMutation = useMutation({
    mutationFn: (uid) => {
      return cloneCarEngineAPIConfigurations({ uid });
    },
    onSuccess: () => {
      toast.success("Cloned Rental Car added");
      queryClient.invalidateQueries("ApiConfigurationCarRental");
      setShowVerifyModal(false);
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
    refetchOnWindowFocus: false,
  });
  const onClone = (uid) => {
    setApiConfigUid(uid);
  };

  return (
    <div
      id="CAREngineAPIConfigurationsListPage"
      className={classes.ApiConfigurationCarRental}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setShowEditModal(true);
          setApiConfigUid(null);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.page_size}
        page={filters.page}
        count={_.get(data, "count")}
        onChangeLimit={(page_size) =>
          setFilters({ ...filters, page_size, page: 1 })
        }
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th key={idx} className={classes.headCell}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.get(data, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.cell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "created":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      return (
                        <td className={classes.datesCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    default:
                      cell = fn(val) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.cell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setApiConfigUid(val.uid);
                      setShowDetailsModal(true);
                    }}>
                    <strong>Details</strong>
                  </CustomButton>
                  <CustomButton
                    id={`EditCAREngineButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setApiConfigUid(val.uid);
                      setShowEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      onClone(val.uid);
                      setShowVerifyModal(true);
                    }}>
                    <strong>Clone</strong>
                  </CustomButton>
                  <CustomButton
                    id={`DeleteCAREngineButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setCar(val);
                      setApiConfigUid(val.uid);
                      setShowDeleteModal(true);
                    }}>
                    <strong>Delete</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDetailsModal && (
        <DetailsApiConfigurationCarEngineModal
          uid={apiConfigUid}
          onClose={() => {
            setShowDetailsModal(false);
            setApiConfigUid(null);
          }}
        />
      )}
      {showEditModal && (
        <EditApiConfigurationCarEngineModal
          apiConfigId={apiConfigUid}
          onClose={() => {
            setShowEditModal(false);
            setApiConfigUid(null);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteApiConfigurationCarEngineModal
          data={car}
          deletedUid={apiConfigUid}
          onClose={() => {
            setShowDeleteModal(false);
          }}
        />
      )}
      {showVerifyModal && (
        <VerifyModal
          // title=""
          // message=""
          isLoading={cloneCarApiMutation.isLoading}
          onConfirm={() => {
            cloneCarApiMutation.mutate(apiConfigUid);
          }}
          onClose={() => setShowVerifyModal(false)}
        />
      )}
    </div>
  );
};
export default ApiConfigurationCarRental;
