import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  tableStyles,
  variables,
  modalGenericStyles,
} from "../../../../../variables";
import _ from "lodash";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import { Button } from "../../../../../components/Button";

const getWarnings = (ledgers, groups_display) => {
  if (!groups_display.includes("has_b2c")) {
    return [];
  }
  const requiredLedgers = [
    "deposit_ledgers",
    "proforma_invoice_ledgers",
    "member_inbound_reservations_ledgers",
  ];
  let warnings = [];
  requiredLedgers.forEach((ledType) => {
    if (_.get(ledgers, ledType, []).length === 0) {
      warnings.push(`There are no ${ledType} ledgers`);
    }
  });
  return warnings;
};

const getLedgerRows = (ledgers) => {
  const rows = [];
  Object.keys(ledgers).forEach((key) => {
    const ledType = key.split("_ledgers")[0];
    _.get(ledgers, key, []).forEach((led) =>
      rows.push({
        type: ledType ? _.startCase(ledType) : "N/A",
        currency: led.currency || "N/A",
        description: led.description || "N/A",
      })
    );
  });
  return rows;
};

const styles = createUseStyles({
  ...cardStyles,
  LedgersSection: {
    ...cardStyles.card,
  },
  header: {
    ...cardStyles.header,
    padding: variables.normal_gap,
    marginTop: "2px",
    fontSize: "1.2rem",
  },
  detailSection: {
    display: "grid",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  tableContainer: {
    display: "block",
    maxHeight: "20rem",
    overflow: "auto",
    width: "100%",
  },
  table: {
    width: "100%",
  },
  thead: {
    position: "sticky",
    top: 0,
    width: "100%",
  },
  emptyLedgers: {
    color: "red",
    textAlign: "center",
    minHeight: "10rem",
    lineHeight: "10rem",
  },
  red: { color: "red" },
  [`@media ${variables.media.smallscreen}`]: {
    detailSection: {
      gridTemplateColumns: "1fr 2fr",
    },
  },
});
const LedgersSection = ({ ledgers, groups_display }) => {
  const classes = styles();

  const [showWarnings, setShowWarnings] = useState(false);

  const warnings = getWarnings(ledgers, groups_display);
  const thereAreWarnings = warnings.length > 0;

  const ledgersCpls = [
    [
      "Proforma Invoice Ledgers",
      null,
      _.get(ledgers, "proforma_invoice_ledgers", []).length === 0
        ? "0"
        : _.get(ledgers, "proforma_invoice_ledgers", []).length,
    ],
    [
      "Deposit Ledgers",
      null,
      _.get(ledgers, "deposit_ledgers", []).length === 0
        ? "0"
        : _.get(ledgers, "deposit_ledgers", []).length,
    ],
    [
      "Sub Entity Ledgers",
      null,
      _.get(ledgers, "sub_entity_ledgers", []).length === 0
        ? "0"
        : _.get(ledgers, "sub_entity_ledgers", []).length,
    ],
    [
      "Person Ledgers",
      null,
      _.get(ledgers, "person_ledgers", []).length === 0
        ? "0"
        : _.get(ledgers, "person_ledgers", []).length,
    ],
    [
      "B2C Transactions Ledgers",
      null,
      _.get(ledgers, "b2c_transactions_ledgers", []).length === 0
        ? "0"
        : _.get(ledgers, "b2c_transactions_ledgers", []).length,
    ],
    [
      "B2C Proforma Invoice Ledgers",
      null,
      _.get(ledgers, "b2c_proforma_invoice_ledgers", []).length === 0
        ? "0"
        : _.get(ledgers, "b2c_proforma_invoice_ledgers", []).length,
    ],
    [
      "Member Inbound Reservations Ledgers",
      null,
      _.get(ledgers, "member_inbound_reservations_ledgers", []).length === 0
        ? "0"
        : _.get(ledgers, "member_inbound_reservations_ledgers", []).length,
    ],
  ];

  const ledgerRows = getLedgerRows(ledgers);

  return (
    <div className={classes.LedgersSection}>
      <div className={classes.header}>
        <span>
          Ledgers{" "}
          {thereAreWarnings && (
            <span className={classes.red}>{"(There are warnings)"}</span>
          )}
        </span>
        {thereAreWarnings && (
          <Button text="Show Warnings" onClick={() => setShowWarnings(true)} />
        )}
      </div>
      <div className={classes.detailSection}>
        <GenericBasicInfo
          header="Total Ledgers"
          cardInfo={ledgersCpls}
          objectData={ledgers}
          costumeStyles={{ header: { fontSize: "1.2rem" } }}
        />
        <div className={classes.ledgersTable}>
          <div className={classes.header}>
            <span>Ledgers Entries</span>
          </div>
          <div className={classes.tableContainer}>
            {ledgerRows.length === 0 ? (
              <div className={classes.emptyLedgers}>
                <strong>No Ledgers</strong>
              </div>
            ) : (
              <table className={classes.table}>
                <thead className={classes.thead}>
                  <tr className={classes.headRow}>
                    <th className={classes.headCell}>#</th>
                    <th className={classes.headCell}>Type</th>
                    <th className={classes.headCell}>Currency</th>
                    <th className={classes.headCell}>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {ledgerRows.map((led, idx) => (
                    <tr key={led.id}>
                      <td className={classes.tableCell}>{idx + 1}</td>
                      <td className={classes.tableCell}>{led.type}</td>
                      <td className={classes.tableCell}>{led.currency}</td>
                      <td className={classes.tableCell}>{led.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      {showWarnings && (
        <WarningsModal
          warnings={warnings}
          onClose={() => setShowWarnings(false)}
        />
      )}
    </div>
  );
};
LedgersSection.propTypes = {
  ledgers: PropTypes.object.isRequired,
  groups_display: PropTypes.array.isRequired,
};
export default LedgersSection;

const warningModalStyles = createUseStyles({
  ...modalGenericStyles,
  WarningsModal: {
    ...modalGenericStyles.modal,
  },
  mainCard: {
    ...modalGenericStyles.card,
    width: "50rem",
  },
  mainCardBody: {
    ...modalGenericStyles.card,
    display: "grid",
    maxHeight: "30rem",
    gap: variables.normal_gap,
    overflow: "auto",
    justifyItems: "center",
  },
  card: {
    ...modalGenericStyles.card,
    width: "80%",
    minHeight: "10rem",
  },
  cardBody: {
    ...modalGenericStyles.card,
    minHeight: "6rem",
  },
});
const WarningsModal = ({ warnings, onClose }) => {
  const classes = warningModalStyles();
  return (
    <div className={classes.WarningsModal}>
      <div className={classes.mainCard}>
        <div className={classes.cardHeader}>
          <span>WARNINGS</span>
        </div>
        <div className={classes.mainCardBody}>
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              <span>Ledger Warnings</span>
            </div>
            <div className={classes.cardBody}>
              <ul>
                {warnings.map((warning, idx) => (
                  <li key={`${idx}_${warning}`}>{warning}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={classes.cardActions}>
          <Button text="Close" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};
WarningsModal.propTypes = {
  warnings: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};
