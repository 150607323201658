import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useState } from "react";
import {
  GenericNavbarsStyles,
  inputGroup,
  variables,
} from "../../../../variables";
import { countries, onEnterDown } from "../../../../helpers";
import DestinationsAutocomplete from "../../../../components/DestinationsAutocomplete";
import _ from "lodash";
import { CustomButton } from "../../../../components/CustomButton";

const reversedCountries = {};
Object.entries(countries).forEach(([key, value]) => {
  reversedCountries[value] = key;
});

const options = [
  ["", "-----"],
  ["1", "1 Day"],
  ["2", "2 Days"],
  ["3", "3 Days"],
  ["4", "4 Days"],
  ["5", "5 Days"],
  ["6", "6 Days"],
  ["7", "7 Days"],
];
const ratingMapping = {
  "": "-----",
  1: "1 Star",
  2: "2 Stars",
  3: "3 Stars",
  4: "4 Stars",
  5: "5 Stars",
};

const supplierMapping = {
  null: "-----",
  innstant: "Innstant",
  hotelbeds: "Hotelbeds",
  webhotelier: "Webhotelier",
  miki: "Miki",
  hotelston: "Hotelston",
};
const rotateTrueFalseOptions = [
  ["", "-----"],
  [false, "Yes"],
  [true, "No"],
];
const trueFalseMapping = [
  ["", "-----"],
  [true, "Yes"],
  [false, "No"],
];

const navbarStyles = createUseStyles({
  Navbar: {
    ...GenericNavbarsStyles.navbars.mobile(),
    gridTemplateRows: "max-content max-content max-content max-content ",
  },
  label: { ...inputGroup },
  divider: GenericNavbarsStyles.divider.mobile(),
  filters: GenericNavbarsStyles.filters.mobile(),
  actions: GenericNavbarsStyles.actions.mobile(),
  inputGroup: {
    ...inputGroup,
    width: "100%",
    "& input, select": {
      ...inputGroup["& input, select"],
      width: "100%",
    },
  },
  inputGroupOneLine: {
    ...inputGroup,
    gridColumn: "2 span",
    width: "100%",
  },
  [`@media ${variables.media.smallscreen}`]: {
    Navbar: {
      ...GenericNavbarsStyles.navbars.smallscreen(),
      gridTemplateColumns: "repeat(2, max-content) 1fr max-content",
      gridTemplateRows: "max-content",
      overflow: "unset",
    },
    divider: GenericNavbarsStyles.divider.smallscreen(),
    filters: {
      ...GenericNavbarsStyles.filters.smallscreen(),
      gridTemplateColumns: "repeat(7, 1fr)",
    },
    actions: GenericNavbarsStyles.actions.smallscreen(),
    inputGroup: {
      ...inputGroup,
    },
    inputGroupOneLine: { ...inputGroup.bigscreen, gridColumn: "1 span" },
  },
  [`@media ${variables.media.bigscreen}`]: {
    filters: {},
  },
});
const Navbar = ({ filters, initialFilters, onApplyFilters }) => {
  const [localFilters, setLocalFilters] = useState(filters);
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const classes = navbarStyles();

  // filling the values comming from the url
  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  return (
    <div className={classes.Navbar}>
      <div className={classes.inputGroupOneLine}>
        <strong>Mapper</strong>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.filters}>
        <div className={classes.inputGroup}>
          <label>Days Old</label>
          <select
            value={localFilters.days}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, days: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {options.map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Uid</label>
          <input
            type="text"
            value={localFilters.uid}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                uid: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Hotel Name</label>
          <input
            type="text"
            value={localFilters.name}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                name: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Supplier</label>
          <select
            value={localFilters.supplier}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                supplier: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(supplierMapping).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Rating</label>
          <select
            value={localFilters.rating}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, rating: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(ratingMapping).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Destinations</label>
          <DestinationsAutocomplete
            value={autocompleteValue}
            setValue={(value) => {
              setAutocompleteValue(value);
              if (!value) {
                setLocalFilters((p) => {
                  return {
                    ...p,
                    cms_destination: null,
                  };
                });
              }
            }}
            onChange={(text) => {
              if (text.length === 0) {
                setLocalFilters((p) => ({
                  ...p,
                  cms_destination: null,
                }));
                return;
              }

              const destinationInfos = text.split("___");
              const destinationId = destinationInfos[0];
              const destinationType = destinationInfos[1];
              // const destinationName = destinationInfos[2];
              const countryName = destinationInfos[3];
              const levelNumber = destinationType.replace("lvl", "");
              const foundCName = Object.keys(reversedCountries).find((cName) =>
                cName.toLowerCase().includes(countryName.toLowerCase())
              );
              setLocalFilters((p) => ({
                ...p,
                cms_destination: `${destinationId}___${levelNumber}`,
                country_code: _.get(reversedCountries, foundCName, ""),
              }));
              // if (text.length > 0) {
              //   onApplyFilters(localFilters);
              // }
            }}
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Country</label>
          <select
            value={localFilters.country_code}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, country_code: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(countries).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Manually Mapped</label>
          <select
            value={localFilters.manually_mapped}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                manually_mapped: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {trueFalseMapping.map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Mapped</label>
          <select
            value={localFilters.not_mapped}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, not_mapped: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {rotateTrueFalseOptions.map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Primary Hotel</label>
          <select
            value={localFilters.primary}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, primary: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {trueFalseMapping.map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Mapping Score More Than</label>
          <input
            type="number"
            value={localFilters.mapping_score_upper}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                mapping_score_upper: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Mapping Score Less Than</label>
          <input
            type="number"
            value={localFilters.mapping_score_lower}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                mapping_score_lower: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Edited</label>
          <input
            type="date"
            value={localFilters.edited}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                edited: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
      </div>
      <div className={classes.actions}>
        <CustomButton
          appearance="ghost"
          onClick={() => {
            setLocalFilters({
              ...initialFilters,
            });
            setAutocompleteValue("");
          }}>
          <strong>Reset</strong>
        </CustomButton>
        <CustomButton
          appearance="primary"
          onClick={() => onApplyFilters(localFilters)}>
          <strong>Apply Filters</strong>
        </CustomButton>
      </div>
    </div>
  );
};
Navbar.propTypes = {
  filters: PropTypes.object.isRequired,
  initialFilters: PropTypes.object.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
};

export default Navbar;
