import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { countries, handleValidationErrors } from "../../../../../helpers";
import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { patchHotel } from "../../../../../../src/api/accommodator/Hotels/Hotels";
import { retrieveHotel } from "../../../../../api/accommodator/Hotels/Hotels";
import Loader from "../../../../../components/Loader";
import * as yup from "yup";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Must be at least 2 characters")
    .max(100, " Must be less than 100 characters")
    .default(""),
  address: yup.string().nullable(),
  supplier: yup
    .string()
    .nullable()
    .oneOf(
      ["innstant", "hotelbeds", "webhotelier", "miki", "hotelston"],
      "Invalid supplier"
    )
    .default(""),
  country_code: yup.string().default(""),
  rating: yup
    .number()
    .nullable()
    .min(1, "must be greater than or equal to 1")
    .max(5, "must be less than or equal to 5")
    .default(0),
  custom_doc_score: yup
    .number()
    .nullable()
    .min(0, "must be greater than or equal to 0")
    .default(0),
  geodata: yup.object().shape({
    lat: yup
      .number()
      .nullable()
      .min(-90, "must be greater than or equal to -90")
      .max(90, "must be less than or equal to 90")
      .default(0),
    lng: yup
      .number()
      .nullable()
      .min(-180, "must be greater than or equal to -180")
      .max(180, "must be less than or equal to 180")
      .default(0),
  }),
});

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditMapperModal: modalGenericStyles.modal,
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    // gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: cardStyles.body,
  formItem: {
    ...formInputGroup,
    padding: `${variables.normal_gap} 0rem `,
  },
  cardActions: {
    ...modalGenericStyles.cardActions,
    gridColumn: "span 2",
  },
  [`@media ${variables.media.retina}`]: {
    modalCard: {
      minWidth: "50rem",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCard: {
      minWidth: "50rem",
    },
    cardCol2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    cardCol3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
});
const EditMapperModal = ({ onClose, pk }) => {
  const classes = styles();
  const [form, setForm] = useState(jsonSchema.cast({}));

  const queryClient = useQueryClient();

  const { isLoading: retrieveLoading } = useQuery({
    queryKey: ["RetrieveEditMapperModal", pk],
    queryFn: async () =>
      await retrieveHotel({
        pk: pk,
      }),
    onSuccess: (data) => {
      setForm(jsonSchema.cast(_.get(data, "data")));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const mutation = useMutation({
    mutationFn: ({ hotelPk, payload }) => {
      return patchHotel({
        hotelPk,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("Mapper info updated");
      queryClient.invalidateQueries("Mapper");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ hotelPk }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      let payload = { ...form };
      if (_.get(form, "geodata.lng")) {
        payload["lng"] = Number.parseFloat(_.get(form, "geodata.lng"));
      }
      if (_.get(form, "geodata.lat")) {
        payload["lat"] = Number.parseFloat(_.get(form, "geodata.lat"));
      }
      await mutation.mutate({ hotelPk, payload });
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const loading = retrieveLoading || mutation.isLoading;

  return (
    <div className={classes.EditMapperModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Edit Mapper Informations</span>
        </div>
        {loading ? (
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        ) : (
          <div className={classes.modalCardBody}>
            <form onSubmit={handleSubmit}>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.formItem}>
                      <label>Name</label>
                      <input
                        type="text"
                        value={form.name}
                        onChange={(e) =>
                          setForm((p) => ({ ...p, name: e.target.value }))
                        }
                      />
                    </div>
                    <div className={classes.formItem}>
                      <label>Address</label>
                      <input
                        type="text"
                        value={form.address}
                        onChange={(e) =>
                          setForm((p) => ({ ...p, address: e.target.value }))
                        }
                      />
                    </div>
                    <div className={classes.formItem}>
                      <label>Supplier</label>
                      <input
                        type="text"
                        value={form.supplier}
                        onChange={(e) =>
                          setForm((p) => ({ ...p, supplier: e.target.value }))
                        }
                      />
                    </div>
                    <div className={classes.formItem}>
                      <label>Country</label>
                      <select
                        value={form.country_code}
                        onChange={(e) =>
                          setForm((p) => ({
                            ...p,
                            country_code: e.target.value,
                          }))
                        }>
                        {Object.entries(countries).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className={classes.formItem}>
                      <label>Rating</label>
                      <input
                        type="number"
                        value={form.rating}
                        min={1}
                        max={5}
                        onChange={(e) =>
                          setForm((p) => ({ ...p, rating: e.target.value }))
                        }
                      />
                    </div>
                    <div className={classes.formItem}>
                      <label>Custom Doc Score</label>
                      <input
                        type="number"
                        value={form.custom_doc_score}
                        onChange={(e) =>
                          setForm((p) => ({
                            ...p,
                            custom_doc_score: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Geodata</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.formItem}>
                      <label>Lat</label>
                      <input
                        type="number"
                        value={_.get(form, "geodata.lat")}
                        onChange={(e) =>
                          setForm((p) => ({
                            ...p,
                            geodata: { ...p.geodata, lat: e.target.value },
                          }))
                        }
                      />
                    </div>
                    <div className={classes.formItem}>
                      <label>Lng</label>
                      <input
                        type="number"
                        value={_.get(form, "geodata.lng")}
                        onChange={(e) =>
                          setForm((p) => ({
                            ...p.geodata,
                            geodata: { ...p, lng: e.target.value },
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
        <div className={classes.modalCardActions}>
          <CustomButton appearance="ghost" text="Cancel" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            appearance="primary"
            onClick={(e) => {
              e.preventDefault();
              onSubmit({
                hotelPk: _.get(form, "pk"),
                payload: form,
              });
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
EditMapperModal.propTypes = {
  pk: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditMapperModal;
