import React from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
  cardStyles,
} from "../../../../variables";
import PropTypes from "prop-types";

const descriptionStyles = createUseStyles({
  ...cardStyles,
  Description: {
    display: "grid",
    gridTemplateRows: " 1fr",
    overflow: "hidden",
    position: "relative",
    padding: variables.normal_gap,
    width: "100%",
    alignSelf: "start",
  },
  header: { ...cardStyles.header },
  tableContainer: {
    ...genericIndexesStyles.tableContainer.mobile(),
    padding: variables.normal_gap,
  },
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
});

const Description = ({ description }) => {
  const classes = descriptionStyles();

  return (
    <div className={classes.Description}>
      <div className={classes.header}>Description</div>
      <div className={classes.tableContainer}>
        <div className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>Description</th>
            </tr>
          </thead>
          <tbody>
            {/* {data.map((dt, idx) => ( */}
            <tr className={classes.bodyRow}>
              <td className={classes.tableCell}>{description || "N/A"}</td>
            </tr>
            {/* ))} */}
          </tbody>
        </div>
      </div>
    </div>
  );
};
Description.propTypes = {
  description: PropTypes.string.isRequired,
};
export default Description;
