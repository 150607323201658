import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { modalGenericStyles, variables } from "../../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import Loader from "../../../../../components/Loader/index";
import {
  deleteWebSiteGenericSection,
  retrieveWebSiteGenericSections,
} from "../../../../../api/DistributionSite/webSites/GenericSections";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import _ from "lodash";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteGenericSection: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
  },
});
const DeleteGenericSection = ({ id, onClose }) => {
  const classes = styles();

  const queryClient = useQueryClient();

  const { data, isLoading: retrieveLoading } = useQuery({
    queryKey: ["retriveDeleteGenericSection", id],
    queryFn: () => retrieveWebSiteGenericSections({ id: id }),
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const basicDataCpl = [
    ["Id", "id"],
    ["Name", "name"],
  ];

  const deleteGeneralSection = useMutation({
    mutationFn: deleteWebSiteGenericSection,
    onSuccess: () => {
      toast.success("General Section Deleted");
      onClose();
      queryClient.invalidateQueries("GenericSectionModal");
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const onDelete = (id) => {
    deleteGeneralSection.mutate({ id });
  };

  const loading = retrieveLoading || deleteGeneralSection.isLoading;

  return (
    <div className={classes.DeleteGenericSection}>
      {loading ? (
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <span>DELETE Generic Section</span>
          </div>
          <div className={classes.cardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <span>DELETE Generic Section</span>
          </div>
          <div className={classes.cardBody}>
            <div className={classes.basicInfo}>
              <GenericBasicInfo
                header={"Are you sure you want to delete this?"}
                cardInfo={basicDataCpl}
                objectData={_.get(data, "data")}
              />
            </div>
          </div>
          <div className={classes.cardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton appearance="primary" onClick={() => onDelete(id)}>
              <strong>DELETE</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
DeleteGenericSection.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};
export default DeleteGenericSection;
