import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchCarEngineAPIConfigurations({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/carrentengine/api-config/`,
    {
      params,
    }
  );
}

export function retrieveCarEngineAPIConfigurations({ uid }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/carrentengine/api-config/${uid}/`,
    {}
  );
}

export function patchCarEngineAPIConfigurations({ uid, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/carrentengine/api-config/${uid}/`,
    payload
  );
}

export function createCarEngineAPIConfigurations({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/carrentengine/api-config/`,
    payload
  );
}

export function cloneCarEngineAPIConfigurations({ uid }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/carrentengine/api-config/clone/${uid}/`
  );
}

export function deleteCarEngineAPIConfigurations({ uid }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/carrentengine/api-config/${uid}/`
  );
}
