export const email_type_mapping = {
  "B2C---VO": "B2C confirmation voucher",
  VO: "Confirmation voucher",
  RF: "Reservation confirmation",
  RI: "Reservation information",
  RA: "Reservation amendment",
  RC: "Reservation cancellation",
  FA: "Reservation failed",
  OW: "Option warning",
  BR: "Bidding request",
  BO: "Bidding offer",
  QRR: "Quotation request",
  QO: "Quotation offer",
  QRA: "Quotation accepted",
  QRT: "Quotation rejected",
  QA: "Quotation assigned",
  RQ: "Quatation request",
};
// export const email_type_mapping = {
//   "B2C---VO": "b2c_confirmation_voucher",
//   VO: "confirmation_voucher",
//   RF: "reservation_confirmation",
//   RI: "reservation_information",
//   RA: "reservation_amendment",
//   RC: "reservation_cancellation",
//   FA: "reservation_failed",
//   OW: "option_warning",
//   BR: "bidding_request",
//   BO: "bidding_offer",
//   QRR: "quotation_request",
//   QO: "quotation_offer",
//   QRA: "quotation_accepted",
//   QRT: "quotation_rejected",
//   QA: "quotation_assigned",
//   RQ: "quatation_request",
// };

export const receiver_type_choices = {
  GU: "Guest",
  TR: "Travel entity",
  SU: "Supplier",
  EM: "Employee",
};

export const service_type_choices = {
  ACC: "Accommodation",
  FL: "Flight",
  ACT: "Activity",
  AIR: "Air_ticket",
  FER: "Ferry_ticket",
  TRF: "Transfer",
  TOU: "Tour",
  EXC: "Excursion",
  EVE: "Event",
  CAR: "Car_rental",
  PKG: "Travel_package",
  BID: "Bidding",
  QU: "Quotation",
  MI: "Add on",
  COO: "Coordinator",
  GEN: "General_service",
  TRA: "Train",
  RES: "Restaurant",
  COA: "Coach",
};

export const statusMapping = {
  "": "-----",
  CO: "Confirmed",
  RQ: "Request",
  CA: "Cancelled",
  RJ: "Rejected",
  PE: "Pending",
  FA: "Failed",
  EX: "Expired",
};

export const bookingModeMapping = {
  CO: "Confirm",
  RCO: "Reconfirm",
  RQ: "On Request",
};
export const truFalseMapping = {
  true: "Yes",
  false: "No",
};
