import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { modalGenericStyles, variables } from "../../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import Loader from "../../../../../components/Loader/index";
import { deleteWebSiteListPage } from "../../../../../api/DistributionSite/webSites/listPages";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { CustomButton } from "../../../../../components/CustomButton";
// import {
// deleteWebSiteListPage,
// retrieveWebSiteListPage,
// } from "../../../../../api/DistributionSite/webSites/";
// } from "../../../../api/DistributionSite/webSites/ListPages";

const basicDataCpl = [
  ["Id", "id"],
  ["Name", "name"],
  ["Title", "title"],
];

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteListPage: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
  },
});
const DeleteListPage = ({ id, data, onClose }) => {
  const classes = styles();

  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: ({ id }) =>
      deleteWebSiteListPage({
        id: id,
      }),
    onSuccess: () => {
      toast.success("List Page Deleted");
      queryClient.invalidateQueries("ListPagesList");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const onDelete = (id) => {
    deleteMutation.mutate({ id });
  };

  // const getListPage = useCallback(async () => {
  //   setLoading(true);
  //   const result = await retrieveWebSiteListPage({
  //     id: id,
  //   });
  //   if (!result) {
  //     setLoading(false);
  //     return;
  //   }
  //   setListPage(result);
  //   setLoading(false);
  // }, [id]);

  // useEffect(() => {
  //   if (id) {
  //     getListPage();
  //   }
  // }, [id, getListPage]);

  // const onDelete = async (id) => {
  //   setLoading(true);
  //   const result = await deleteWebSiteListPage({
  //     id,
  //   });
  //   if (result) {
  //     setLoading(false);
  //     toast.success("List Page Deleted");
  //     onClose();
  //   }
  //   setLoading(false);
  // };

  return (
    <div className={classes.DeleteListPage}>
      <div className={classes.card}>
        {deleteMutation.isLoading && <Loader onTop={true} />}
        <div className={classes.cardHeader}>
          <span>DELETE List Page</span>
        </div>
        <div className={classes.cardBody}>
          <div className={classes.basicInfo}>
            <GenericBasicInfo
              header={"Are you sure you want to delete this?"}
              cardInfo={basicDataCpl}
              objectData={data}
            />
          </div>
        </div>
        <div className={classes.cardActions}>
          <CustomButton appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton appearance="primary" onClick={() => onDelete(id)}>
            <strong>DELETE</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DeleteListPage.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DeleteListPage;
