import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  formInputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import { statusMapping } from "../../common";
import Loader from "../../../../../components/Loader";
import {
  createWebSiteMainPromo,
  patchWebSiteMainPromos,
  retrieveWebSiteMainPromos,
} from "../../../../../api/DistributionSite/webSites/MainPromos";
import _ from "lodash";
import {
  fetchAddonsQuickInfo,
  fetchPackagesQuickInfo,
  fetchWebSiteLandingPages,
} from "../../../../../api/DistributionSite/webSites/LandingPages";
import QuilEditor from "../../../../../components/QuilEditor";
import { DateTime } from "luxon";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import * as yup from "yup";
import { handleValidationErrors } from "../../../../../helpers";
import { CustomButton } from "../../../../../components/CustomButton";

const jsonSchema = yup.object().shape({
  landing_page: yup.string().required("This field is required"),
  max_promo_items: yup
    .number()
    .integer("A valid integer is required for max promo items")
    .nullable(),
  // .required("This field is required"),
  status: yup
    .mixed()
    .oneOf(["AC", "IN", "CA"], "Must be between Active, Inactive or Candidate"),
});

const initialForm = {
  name: "",
  status: "",
  title: "",
  active_from: null,
  active_to: null,
  landing_page: "",
  max_promo_items: 0,
  featured_addon: "",
  featured_package: "",
  package_references: [],
  hotel_references: [],
  references: [],
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditMainPromos: {
    ...modalGenericStyles.modal,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  loader: { width: "100vw" },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    display: "",
    // padding: `${variables.normal_gap} ${variables.normal_gap} ${variables.normal_gap} ${variables.normal_gap}  `,
    gap: variables.normal_gap,
  },
  inputGroup: {
    ...formInputGroup,
    "& select": {
      width: "100%",
    },
  },
  descriptionCardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    display: "",
    padding: "",
    paddingBottom: variables.normal_gap,
  },
  description: {
    gap: variables.normal_gap,
    "& label": {
      fontSize: "small",
    },
  },
  [`@media ${variables.media.smallscreen}`]: {
    inputGroup: formInputGroup,
    cardCol2: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: variables.normal_gap,
    },
    cardCol3: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      gap: variables.normal_gap,
    },
  },
});

const EditMainPromos = ({ id, sourceEntity, onClose, websiteId }) => {
  const classes = styles();
  const mode = id ? "edit" : "add";

  const [form, setForm] = useState(initialForm);

  const queryClient = useQueryClient();

  // QUERIES
  const queries = useQueries({
    queries: [
      {
        queryKey: ["RetrieveWebSiteMainPromos", id],
        queryFn: () => retrieveWebSiteMainPromos({ id: id }),
        enabled: mode === "edit",
        onSuccess: (data) => {
          setForm(_.get(data, "data", {}));
        },
      },
      {
        queryKey: ["AddonsInfo"],
        queryFn: () =>
          fetchAddonsQuickInfo({
            params: { source_entity: sourceEntity, limit: 100 },
          }),
      },
      {
        queryKey: ["AccPackagesInfo"],
        queryFn: () =>
          fetchPackagesQuickInfo({
            params: { source_entity: sourceEntity, required_services: "ACC" },
          }),
      },
      {
        queryKey: ["PackagesInfo"],
        queryFn: () =>
          fetchPackagesQuickInfo({ params: { source_entity: sourceEntity } }),
      },
      {
        queryKey: ["LandingPages"],
        queryFn: () =>
          fetchWebSiteLandingPages({
            params: { website: websiteId, source_entity: sourceEntity },
          }),
      },
    ],
  });

  // MUTATIONS
  const editMutation = useMutation({
    mutationFn: ({ payload, id }) => {
      return patchWebSiteMainPromos({
        id,
        payload,
      });
    },
    onSuccess: () => {
      toast.success("Main Promo Page Info Updated");
      queryClient.invalidateQueries("MainPromosList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const addMutation = useMutation({
    mutationFn: ({ payload, website }) => {
      return createWebSiteMainPromo({
        payload: {
          ...payload,
        },
      });
    },
    onSuccess: () => {
      toast.success("New Main Promo Page Added");
      queryClient.invalidateQueries("MainPromosList");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async ({ id, payload, website }) => {
    try {
      await jsonSchema.validate(form, { abortEarly: false });
      if (mode === "add") {
        addMutation.mutate({ payload, website });
      } else {
        editMutation.mutate({ id, payload });
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  // SETTING UP DATA
  const mainPromosQuery = queries[0];
  const addonsInfoQuery = queries[1];
  const pkgsQuery = queries[3];
  const accPackagesQuery = queries[2];
  const landingPagesQuery = queries[4];

  const loading =
    (mode === "edit" &&
      mainPromosQuery.isLoading &&
      landingPagesQuery.isLoading) ||
    addonsInfoQuery.isLoading ||
    addMutation.isLoading ||
    editMutation.isLoading;

  const addonsInfo = _.get(addonsInfoQuery, "data.data.results", []);
  const hotelPkgs = _.get(accPackagesQuery, "data.data.results", []).map(
    (dat) => ({
      value: dat.reference,
      label: `${dat.title} (${dat.reference})`,
    })
  );
  const pkgs = _.get(pkgsQuery, "data.data.results", []).map((dat) => ({
    value: dat.reference,
    label: `${dat.title} (${dat.reference})`,
  }));

  const addonOptions = [
    ...addonsInfo.map((datum) => [
      datum.reference,
      `${datum.title} (${datum.status === "AV" ? "available" : "unavailable"})`,
    ]),
  ];

  const landingPages = [
    { value: "", label: "-----" },
    ..._.get(landingPagesQuery, "data.data.results", []).map((lp) => ({
      value: lp.id,
      label: `${lp.title || "N/A"} (${_.get(statusMapping, lp.status)})`,
    })),
  ];

  return (
    <div className={classes.EditMainPromos}>
      {loading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            {_.startCase(mode)} Main Promos
          </div>
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.modalCardHeader}>
            {_.startCase(mode)} Main Promos
          </div>
          <div className={classes.modalCardBody}>
            <form
              onChange={(e) =>
                setForm((p) => ({
                  ...p,
                  [e.target.name]: e.target.value,
                }))
              }>
              <div className={classes.card}>
                <div className={classes.cardHeader}>Basic Information</div>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="landing_page">Landing Page</label>
                      <select
                        name="landing_page"
                        value={form.landing_page}
                        onChange={() => {
                          return;
                        }}
                        disabled={mode === "edit"}>
                        {landingPages.map((lp, idx) => (
                          <option key={idx} value={lp.value}>
                            {lp.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="name">Name</label>
                      <input type="text" name="name" defaultValue={form.name} />
                    </div>
                  </div>
                  <div className={classes.card}>
                    <div className={classes.descriptionCardBody}>
                      <div className={classes.description}>
                        <label htmlFor="description">Description</label>
                        <QuilEditor
                          type="text"
                          name="description"
                          value={form.description}
                          onChange={(value) => {
                            let newForm = {
                              ...form,
                              description: value,
                            };
                            setForm(newForm);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.cardCol2}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="max_promo_items">Max Promo Items</label>
                      <input
                        type="number"
                        name="max_promo_items"
                        defaultValue={form.max_promo_items}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="title">Title</label>
                      <input
                        type="text"
                        name="title"
                        defaultValue={form.title}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="hotel_references">Hotels</label>
                      <select
                        name="hotel_references"
                        value={form.hotel_references}
                        onChange={() => {
                          return;
                        }}>
                        <option value={""}>-----</option>
                        {hotelPkgs.map(({ value, label }, idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="package_references">Packages</label>
                      <select
                        name="package_references"
                        value={form.package_references}
                        onChange={() => {
                          return;
                        }}>
                        <option value={""}>-----</option>
                        {pkgs.map(({ value, label }, idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="references">Addons</label>
                      <select
                        name="references"
                        value={form.references}
                        onChange={() => {
                          return;
                        }}>
                        <option value={""}>-----</option>
                        {addonOptions.map(([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="featured_package">Featured Package</label>
                      <select
                        name="featured_package"
                        value={form.featured_package}
                        onChange={() => {
                          return;
                        }}>
                        <option value={""}>-----</option>
                        {pkgs.map(({ value, label }, idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="featured_addon">Featured Addon</label>
                      <select
                        name="featured_addon"
                        value={form.featured_addon}
                        onChange={() => {
                          return;
                        }}>
                        <option value={""}>-----</option>
                        {addonOptions.map(([value, label], idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <h5 className={classes.cardHeader}>Active Data</h5>
                <div className={classes.cardBody}>
                  <div className={classes.cardCol3}>
                    <div className={classes.inputGroup}>
                      <label htmlFor="active_from">Active From</label>
                      <input
                        type="date"
                        name="active_from"
                        value={
                          form.active_from
                            ? DateTime.fromISO(form.active_from).toISODate()
                            : "N/A"
                        }
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="active_to">Active To</label>
                      <input
                        type="date"
                        name="active_to"
                        value={
                          form.active_to
                            ? DateTime.fromISO(form.active_to).toISODate()
                            : "N/A"
                        }
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        value={form.status}
                        onChange={() => {
                          return;
                        }}>
                        {Object.entries(statusMapping).map(
                          ([value, label], idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className={classes.cardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit({
                  id: id,
                  payload: form,
                  website: websiteId,
                });
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
EditMainPromos.propTypes = {
  id: PropTypes.number,
  websiteId: PropTypes.number,
  sourceEntity: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default EditMainPromos;
