import React from "react";
import { createUseStyles } from "react-jss";
import {
  GenericNavbarsStyles,
  inputGroup,
  variables,
} from "../../../../variables";

const navbarStyles = createUseStyles({
  Navbar: {
    ...GenericNavbarsStyles.navbars.mobile(),
    // gridTemplateRows: "max-content max-content max-content max-content ",
  },
  label: { ...inputGroup },
  divider: GenericNavbarsStyles.divider.mobile(),
  filters: GenericNavbarsStyles.filters.mobile(),
  actions: GenericNavbarsStyles.actions.mobile(),
  inputGroup: {
    ...inputGroup,
    width: "100%",
    "& input, select": {
      ...inputGroup["& input, select"],
      width: "100%",
    },
  },
  inputGroupOneLine: {
    ...inputGroup,
    gridColumn: "2 span",
    width: "100%",
  },
  [`@media ${variables.media.smallscreen}`]: {
    Navbar: {
      ...GenericNavbarsStyles.navbars.smallscreen(),
      justifyContent: "start",
      gridTemplateColumns: "repeat(2, max-content) ",
      minHeight: "3rem",
    },
    divider: GenericNavbarsStyles.divider.smallscreen(),
    filters: {
      ...GenericNavbarsStyles.filters.smallscreen(),
      gridTemplateColumns: "repeat(6, 1fr)",
    },
    actions: GenericNavbarsStyles.actions.smallscreen(),
    inputGroup: {
      ...inputGroup,
    },
    inputGroupOneLine: { ...inputGroup.bigscreen, gridColumn: "1 span" },
  },
  [`@media ${variables.media.bigscreen}`]: {},
});
const Navbar = () => {
  const classes = navbarStyles();

  return (
    <div className={classes.Navbar}>
      <div className={classes.label}>
        <strong>Back Up Status</strong>
      </div>
      <div className={classes.divider}></div>
    </div>
  );
};
Navbar.propTypes = {};
export default Navbar;
