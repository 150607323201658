import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { modalGenericStyles, variables } from "../../../../variables";
import PropTypes from "prop-types";
import Loader from "../../../../components/Loader";
import { CustomButton } from "../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  SendEmailModal: {
    ...modalGenericStyles.modal,
  },
  cardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    alignContent: "center",
  },
});

const SendEmailModal = ({ title, message, onSubmit, onClose }) => {
  const classes = styles();
  const [loading] = useState(false);
  return (
    <div className={classes.SendEmailModal}>
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <span>{title}</span>
          </div>
          <div className={classes.cardBody}>{message}</div>
          <div className={classes.cardActions}>
            <CustomButton
              appearance="ghost"
              text="Cancel"
              onClick={() => onClose()}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              appearance="primary"
              text="Submit"
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
                onClose();
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
SendEmailModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default SendEmailModal;
