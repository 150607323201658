import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { cardStyles, tableStyles, variables } from "../../../../../variables";
import { medWeekdayDatetimer } from "../../../../../helpers";
import _ from "lodash";
import { srvTypeMapping } from "./VendorsSection";

const isOwnMapping = {
  acc__Config: "is_own",
  acc__Musement: "is_own",
  car__Config: "is_own",
  fl__CRS_Entry: "is_own",
  fl__Aviate: "is_own",
  fl__Unififi: "is_own",
  tra__Config: null,
  trf__Config: "is_own",
  fer__DirectFerries: "is_own",
};
const isGlobalMapping = {
  acc__Config: "global_account",
  acc__Musement: "is_global",
  car__Config: "global_account",
  fl__CRS_Entry: "is_global",
  fl__Aviate: "is_global",
  fl__Unififi: "is_global",
  tra__Config: "global_account",
  trf__Config: "global_account",
  fer__DirectFerries: "is_own",
};

const getConfigRows = (apiConfigs, classes) => {
  let rows = [];
  Object.entries(apiConfigs).forEach(([srvType_model, configs]) => {
    const srvType = srvType_model.split("__")[0];
    const model = srvType_model.split("__")[1];
    const isOwnKey = _.get(isOwnMapping, srvType_model);
    const isGlobalKey = _.get(isGlobalMapping, srvType_model);

    configs.forEach((config, idx) => {
      rows.push(
        <tr key={`${srvType_model}_${idx}`}>
          <td className={classes.tableCell}>{rows.length + 1}</td>

          <td className={classes.tableCell}>
            {_.get(srvTypeMapping, srvType) || "N/A"}
          </td>
          <td className={classes.tableCell}>{model || "N/A"}</td>

          <td className={classes.tableCell}>
            {_.get(config, "supplier") || "N/A"}
          </td>

          <td className={classes.tableCell}>
            {_.get(config, "name") || "N/A"}
          </td>

          <td className={classes.tableCell}>
            {_.get(config, "market_type") || "N/A"}
          </td>

          <td className={classes.tableCell}>
            {_.get(config, isOwnKey) === null ? (
              "N/A"
            ) : _.get(config, isOwnKey) ? (
              <span className={classes.green}>{"Yes"}</span>
            ) : (
              "No"
            )}
          </td>

          <td className={classes.tableCell}>
            {_.get(config, isGlobalKey) === null ? (
              "N/A"
            ) : _.get(config, isGlobalKey) ? (
              <span className={classes.green}>{"Yes"}</span>
            ) : (
              "No"
            )}
          </td>

          <td className={classes.tableCell}>
            {_.get(config, "created")
              ? medWeekdayDatetimer(_.get(config, "created"))
              : "N/A"}
          </td>
        </tr>
      );
    });
  });

  return rows;
};

const styles = createUseStyles({
  ...cardStyles,
  APIConfigsSection: { ...cardStyles.card },
  header: {
    ...cardStyles.header,
    padding: variables.normal_gap,
    fontSize: "1.2rem",
  },
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  tableContainer: {
    display: "block",
    maxHeight: "20rem",
    overflow: "auto",
    width: "100%",
  },
  table: {
    width: "100%",
  },
  thead: {
    position: "sticky",
    top: 0,
  },
  emptyConfigs: {
    color: "red",
    textAlign: "center",
    minHeight: "10rem",
    lineHeight: "10rem",
  },
  green: { color: "green" },
});
const APIConfigsSection = ({ apiConfigs }) => {
  const classes = styles();

  const totalApiConfigs = _.flatten(_.values(apiConfigs));

  return (
    <div className={classes.APIConfigsSection}>
      <div className={classes.header}>
        <span>API Configurations</span>
      </div>
      <div className={classes.tableContainer}>
        {totalApiConfigs.length === 0 ? (
          <div className={classes.emptyConfigs}>
            <strong>No API Configurations</strong>
          </div>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Service Type</th>
                <th className={classes.headCell}>Config Type</th>
                <th className={classes.headCell}>Supplier</th>
                <th className={classes.headCell}>Name</th>
                <th className={classes.headCell}>Market Type</th>
                <th className={classes.headCell}>Is Own</th>
                <th className={classes.headCell}>Is Global</th>
                <th className={classes.headCell}>Created</th>
              </tr>
            </thead>
            <tbody>{getConfigRows(apiConfigs, classes)}</tbody>
          </table>
        )}
      </div>
    </div>
  );
};
APIConfigsSection.propTypes = {
  apiConfigs: PropTypes.object.isRequired,
};
export default APIConfigsSection;
