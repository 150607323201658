import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  btnStyles,
  modalGenericStyles,
  tableStyles,
  variables,
  genericIndexesStyles,
} from "../../../../../variables";
import PropTypes from "prop-types";
import { fetchWebSiteListPages } from "../../../../../api/DistributionSite/webSites/listPages/index";
import { DateTime } from "luxon";
import { statusMapping } from "../../common";
import _ from "lodash";
import EditListPages from "../EditListPages";
import Loader from "../../../../../components/Loader";
import DeleteListPage from "../DeleteListPage";
import { useQuery } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../api";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  ListPages: {
    ...modalGenericStyles.card,
  },
  loader: { width: "100vw" },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: tableStyles,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  cell: tableStyles.body.cell,
  actionCell: {
    ...genericIndexesStyles.actionCell.mobile(),
  },
  btn: btnStyles.btn,
  add: {
    padding: "0.75rem",
    textAlign: "end",
  },
  addBtn: { ...btnStyles.btn, width: "4rem" },
  [`@media ${variables.media.smallscreen}`]: {
    loader: { width: "100%" },
  },
});

const ListPages = ({ websiteId }) => {
  const classes = styles();

  const [listPage, setListPage] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { data: listPages, isLoading } = useQuery({
    queryKey: ["ListPagesList"],
    queryFn: async () =>
      await fetchWebSiteListPages({
        params: { website: websiteId },
      }),
    onError: (error) => genericRequestErrorHandler(error),
  });

  return (
    <div className={classes.ListPages}>
      <div className={classes.cardHeader}>
        List Page
        <div className={classes.cardActions}>
          <CustomButton
            appearance="ghost"
            onClick={() => {
              setEditId(null);
              setEditModal(true);
            }}>
            <strong>Add</strong>
          </CustomButton>
        </div>
      </div>
      <div className={classes.tableContainer}>
        {isLoading ? (
          <div className={classes.loader}>
            <Loader />
          </div>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                <th className={classes.headCell}>Id</th>
                <th className={classes.headCell}>Name</th>
                <th className={classes.headCell}>Active From</th>
                <th className={classes.headCell}>Active To</th>
                <th className={classes.headCell}>Status</th>
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody className={classes.tbody}>
              {_.get(listPages, "data.results", []).map((list, idx) => (
                <tr key={idx} className={classes.bodyRow}>
                  <td className={classes.cell}>{idx + 1}</td>
                  <td className={classes.cell}>{list.id || "N/A"}</td>
                  <td className={classes.cell}>{list.name || "N/A"}</td>
                  <td className={classes.cell}>
                    {list.active_from
                      ? DateTime.fromISO(list.active_from).toLocaleString(
                          DateTime.DATE_MED_WITH_WEEKDAY
                        )
                      : "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {list.active_to
                      ? DateTime.fromISO(list.active_to).toLocaleString(
                          DateTime.DATE_MED_WITH_WEEKDAY
                        )
                      : "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {_.get(statusMapping, _.get(list, "status"))}
                  </td>
                  <td className={classes.actionCell}>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        setEditId(list.id);
                        setEditModal(true);
                      }}>
                      <strong>Edit</strong>
                    </CustomButton>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        setListPage(list);
                        setDeleteId(list.id);
                        setDeleteModal(true);
                      }}>
                      <strong>Delete</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {editModal && (
        <EditListPages
          websiteId={websiteId}
          id={editId}
          onClose={() => {
            setEditModal(false);
            setEditId(null);
          }}
        />
      )}
      {deleteModal && (
        <DeleteListPage
          id={deleteId}
          data={listPage}
          onClose={() => {
            setDeleteModal(false);
          }}
        />
      )}
    </div>
  );
};
ListPages.propTypes = {
  websiteId: PropTypes.number.isRequired,
};
export default ListPages;
