import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../components/cards/GenericBasicInfo";
import _ from "lodash";
import { genericRequestErrorHandler } from "../../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteRailEuropeAPIConfig } from "../../../../api/RailEuropeEngine";
import { currencies } from "../../../../helpers";
import Loader from "../../../../components/Loader";
import { CustomButton } from "../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  DeleteRailEuropeApiConfigModal: modalGenericStyles.modal,
  modalCard: modalGenericStyles.card,
  modalCardHeader: modalGenericStyles.cardHeader,
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "repeat(2, max-content)",
  },
  modalCardActions: modalGenericStyles.cardActions,
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: cardStyles.body,
  basicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    gridTemplateColumns: "repeat(2, max-content)",
  },
});

const DeleteRailEuropeApiConfigModal = ({ deletedUid, data, onClose }) => {
  const classes = styles();
  const queryClient = useQueryClient();
  const basicDataCpl = [
    ["Uid", "uid"],
    ["Name", null, _.capitalize(_.get(data, "name"))],
    ["Supplier", null, _.capitalize(_.get(data, "supplier"))],
    ["Source Entity", "source_entity"],
    ["Market Type", "market_type"],
    ["Currency", null, _.get(currencies, _.get(data, "currency"))],
  ];

  const DeleteApiConfig = useMutation(deleteRailEuropeAPIConfig, {
    onSuccess: () => {
      toast.success("Rail Europe Api Configuration Deleted");
      queryClient.invalidateQueries("RailEuropeApiConfig");
      onClose();
    },
    onError: (error) => genericRequestErrorHandler(error),
  });
  const onDelete = async (uid) => {
    await DeleteApiConfig.mutateAsync({ uid });
  };

  return (
    <div
      id="DeleteRailEuropeEngineModal"
      className={classes.DeleteRailEuropeApiConfigModal}>
      <React.Fragment>
        <div className={classes.modalCard}>
          {DeleteApiConfig.isLoading && <Loader onTop={true} />}
          <div className={classes.modalCardHeader}>
            <span>DELETE Rail Europe Api Configuration</span>
          </div>
          <div className={classes.modalCardBody}>
            <div className={classes.card}>
              <div className={classes.cardBody}>
                <GenericBasicInfo
                  header={"Are you sure you want to delete this?"}
                  cardInfo={basicDataCpl}
                  objectData={data}
                />
              </div>
            </div>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton
              id="DeleteRailEuropeEngineModalCloseButton"
              appearance="ghost"
              onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              id="DeleteRailEuropeEngineModalConfirmButton"
              appearance="primary"
              onClick={() => {
                onDelete(deletedUid);
              }}>
              <strong>DELETE</strong>
            </CustomButton>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
DeleteRailEuropeApiConfigModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  deletedUid: PropTypes.string.isRequired,
};
export default DeleteRailEuropeApiConfigModal;
