import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Navbar from "./Navbar";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import { fetchMembers } from "../../../../api/CRM/Members/Members";
import ControlStrip from "../../../../components/ControlStrip";
import InvoiceMemberModal from "../../CommonCRMModals/InvoiceModal/index";
import Loader from "../../../../components/Loader";
import {
  currencies,
  handleApplyFilters,
  medWeekdayDatetimer,
} from "../../../../helpers";
import DetailsMemberModal from "../MembersModals/DetailsMemberModal";
import EditMemberModal from "../MembersModals/EditMemberModal";
import { useQuery } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import { DateTime } from "luxon";
import { genericRequestErrorHandler } from "../../../../api";
import BirdsEyeModal from "../MembersModals/BirdsEyeModal";
import { CustomButton } from "../../../../components/CustomButton";

const initialFilters = {
  limit: 20,
  page: 1,
  name__icontains: "",
  mobile_phone__contains: "",
  email__icontains: "",
  created__gte: "",
  created__lte: "",
  currency__icontains: "",
  ordering: "",
  discending: "",
  legal_title__icontains: "",
  completeness__gte: "",
  id: null,
};

const navbarSchema = yup.object().shape({
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const memberStyles = createUseStyles({
  Members: genericIndexesStyles.indexes.mobile,
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
  checkBox: tableStyles.checkBox,
  dateCell: { ...tableStyles.body.cell, width: "9rem", minWidth: "9rem" },
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});
const Members = () => {
  const classes = memberStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showBirdsEyeModal, setShowBirdsEyeModal] = useState(false);
  const [memberId, setMemberId] = useState(null);
  const [checked, setChecked] = useState(null);

  const tableHeaders = {
    Name: (val) => _.capitalize(_.get(val, "name")),
    "Source Entity": (val) => `${_.get(val, "id")}___member`,
    "Legal Title": (val) => _.capitalize(_.get(val, "legal_title")),
    "Main Service": (val) => _.capitalize(_.get(val, "main_service")),
    Currency: (branch) => _.get(currencies, _.get(branch, "currency")),
    Email: (val) => val.email,
    Completeness: (val) => val.completeness,
    Created: (val) => val.created,
  };

  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["Members", filters],
    queryFn: () => {
      let correctPayload = { ...filters };
      if (filters.created__lte) {
        correctPayload["created__lte"] = DateTime.fromISO(
          filters.created__lte
        ).toISO();
      }
      if (filters.created__gte) {
        correctPayload["created__gte"] = DateTime.fromISO(
          filters.created__gte
        ).toISO();
      }
      return fetchMembers({
        params: correctPayload,
      });
    },
    enabled: !showDetailsModal && !showEditModal,
    onError: (error) => genericRequestErrorHandler(error),
  });

  return (
    <div id="membersListPage" className={classes.Members}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setShowEditModal(true);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.limit}
        page={filters.page}
        count={_.get(data, "data.count", 0)}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              {/* <th>
                  <input
                    className={classes.checkBox}
                    type="checkbox"
                    checked={
                      members.length > 0 && checked.length === members.length
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChecked([...members.map((member) => member.id)]);
                      } else {
                        setChecked([]);
                      }
                    }}
                  />
                </th> */}
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th className={classes.headCell} key={idx}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.get(data, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                {/* <td className={classes.tableCell}>
                    <input
                      type="checkbox"
                      className={classes.checkBox}
                      checked={checked.includes(val.id)}
                      disabled={
                        checked.length >= 10 && !checked.includes(val.id)
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setChecked([...checked, val.id]);
                        } else {
                          const newChecked = [
                            ...checked.filter((id) => id !== val.id),
                          ];
                          setChecked(newChecked);
                        }
                      }}
                    ></input>
                  </td> */}
                <td className={classes.tableCell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "created":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      return (
                        <td className={classes.dateCell} key={ridx}>
                          {cell}
                        </td>
                      );

                    default:
                      cell = fn(val) || "N/A";
                      break;
                  }
                  return (
                    <td className={classes.tableCell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    id={`DetailMemberButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setMemberId(val.id);
                      setShowDetailsModal(true);
                    }}>
                    <strong>Details</strong>
                  </CustomButton>
                  <CustomButton
                    id={`EditMemberButton_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      setMemberId(val.id);
                      setShowEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setMemberId(val.id);
                      setShowBirdsEyeModal(true);
                    }}>
                    <strong>Birds Eye View</strong>
                  </CustomButton>
                  {/* <Button
                      text="Clone"
                      onClick={async () => {
                        await onClone(val.uid);
                      }}
                    /> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showInvoiceModal && (
        <InvoiceMemberModal
          members={data.filter((member) => checked.includes(member.id))}
          onClose={() => {
            setShowInvoiceModal(false);
            setChecked([]);
          }}
        />
      )}
      {showDetailsModal && (
        <DetailsMemberModal
          memberId={memberId}
          onClose={() => setShowDetailsModal(false)}
        />
      )}
      {showEditModal && (
        <EditMemberModal
          memberId={memberId}
          onClose={() => {
            setShowEditModal(false);
            setMemberId(null);
          }}
        />
      )}
      {showBirdsEyeModal && (
        <BirdsEyeModal
          memberId={memberId}
          onClose={() => {
            setShowBirdsEyeModal(false);
            setMemberId(null);
          }}
        />
      )}
    </div>
  );
};
export default Members;
