import { Price } from "../../components/Price";
import ReservationsStatusTag from "../../components/Tags/ReservationsStatusTag";

export function commonTableHeaders(headers) {
  return {
    Reference: (val) => val.reference,
    "External Reference": (val) => val.external_reference,
    "Source Entity": (val) => val.source_entity,
    ...headers,
    "Original Service Price": (val) => (
      <Price currency={val.currency} price={val.original_service_price} />
    ),
    Created: (val) => val.created,
    Status: (val) => <ReservationsStatusTag status={val.status} />,
  };
}

export const networkSearchMapping = {
  "": "-----",
  false: "No",
  true: "Yes",
};
