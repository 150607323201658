import React from "react";
import { createUseStyles } from "react-jss";
import {
  btnStyles,
  cardStyles,
  tableStyles,
  variables,
} from "../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import { countries } from "../../../helpers";
import { CustomButton } from "../../CustomButton";

const guestIdentificationTypeMapping = {
  "": "-----",
  ID: "Identity Card",
  PA: "Passport",
};
const trueFalseMapping = {
  true: "Yes",
  false: "No",
};

const guestStyles = createUseStyles({
  ...cardStyles,
  GuestCard: { ...cardStyles.card, ...cardStyles.card, maxWidth: "100%" },
  tableContainer: {
    display: "grid",
    padding: variables.normal_gap,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: { ...tableStyles.table, maxWidth: "100%", overflow: "hidden" },
  thead: tableStyles.head,
  tbody: tableStyles.body,
  trow: tableStyles.body.row,
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  btn: btnStyles.btn,
});

const GuestCard = ({ title, guests, onEditGuest }) => {
  const classes = guestStyles();
  return (
    <div id="GuestCardList" className={classes.GuestCard}>
      <div className={classes.header}>
        <span>{title}</span>
      </div>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              <th className={classes.headCell}>Guest Type</th>
              <th className={classes.headCell}>Title</th>
              <th className={classes.headCell}>First Name</th>
              <th className={classes.headCell}>Last Name</th>
              <th className={classes.headCell}>Gender</th>
              <th className={classes.headCell}>Email</th>
              <th className={classes.headCell}>Phone</th>
              <th className={classes.headCell}>Id Type</th>
              <th className={classes.headCell}>Id Code</th>
              <th className={classes.headCell}>Id Expire Date</th>
              <th className={classes.headCell}>Date Of Birth</th>
              <th className={classes.headCell}>Nationality</th>
              <th className={classes.headCell}>Group Leader</th>
              <th className={classes.headCell}>Room Leader</th>
              <th className={classes.headCell}>Action</th>
            </tr>
          </thead>
          <tbody className={classes.tbody}>
            {(guests || []).map((guest, idx) => (
              <tr key={idx} className={classes.trow}>
                <td className={classes.tableCell}>{idx + 1}</td>
                <td className={classes.tableCell}>
                  {_.get(
                    guestIdentificationTypeMapping,
                    guest.identification_type
                  ) || "N/A"}
                </td>

                <td className={classes.tableCell}>
                  {guest.guest_title || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {guest.first_name || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {guest.last_name || "N/A"}
                </td>
                <td className={classes.tableCell}>{guest.gender || "N/A"}</td>
                <td className={classes.tableCell}>{guest.email || "N/A"}</td>
                <td className={classes.tableCell}>{guest.phone || "N/A"}</td>
                <td className={classes.tableCell}>
                  {guest.guest_type || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {guest.identification_code || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {guest.identification_expiry_date || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {guest.date_of_birth || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.get(countries, guest.nationality) || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {guest.group_leader === true ? "Yes" : "No" || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.get(trueFalseMapping, guest.room_leader) || "N/A"}
                </td>
                <td>
                  <CustomButton
                    id={`openEditGuest_${idx}`}
                    appearance="ghost"
                    onClick={() => {
                      onEditGuest(guest.id);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
GuestCard.propTypes = {
  title: PropTypes.string.isRequired,
  guests: PropTypes.array.isRequired,
  onEditGuest: PropTypes.func.isRequired,
};
export default GuestCard;
