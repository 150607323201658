import { VALAQUENTA_API_URL } from "..";
import { authorizedAxios } from "../../App";

export async function getMemberOverview(member_id) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/newspaper/member-overview/`,
    {
      params: { member_id },
    }
  );
}
