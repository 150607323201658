import React from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  cardStyles,
  tableStyles,
  variables,
} from "../../../../../variables";
import _ from "lodash";
import { PropTypes } from "prop-types";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import { DateTime, Duration } from "luxon";

const legsStyles = createUseStyles({
  ...cardStyles,
  Leg: { display: "grid" },
  card: {
    ...cardStyles.card,
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    gap: variables.normal_gap,
  },
  cardBody: { ...cardStyles.cardBody, alignSelf: "start", overflowX: "auto" },
  header: { ...cardStyles.header, gap: variables.normal_gap },
  tableContainer: {
    ...genericIndexesStyles.tableContainer.mobile(),
    padding: variables.normal_gap,
  },
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  tablecell: tableStyles.body.cell,
  [`@media ${variables.media.smallscreen}`]: {
    card: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
});

function getHumanizedFromDurationISO(input) {
  if (!input) {
    return "";
  }
  var output = "";
  var sum = 0;
  try {
    output = Duration.fromISO(input).toObject();
    sum = _.sum(Object.entries(output).map((entry) => entry[1]));
    output = _.flatten(
      Object.entries(output).map((entry) => [
        `${_.capitalize(entry[0])}: ${entry[1]}`,
      ])
    ).join(", ");
  } catch (error) {}
  return sum ? output : "";
}

const Leg = ({ legIdx, reservation }) => {
  const classes = legsStyles();

  const legType = legIdx === 0 ? "outbound" : "inbound";

  const leg = _.get(
    reservation,
    `service_data.raw_data.bookingItems.0.legs.${legIdx}`
  );
  const segments = _.get(leg, "segments", []);
  const basicDataCpl = [
    ["Origin", `${legType}_origin`],
    ["Destination", `${legType}_destination`],
    ["Origin Station", `${legType}_origin_station`],
    ["Destination Station", `${legType}_destination_station`],
    [
      "Departure",
      null,
      DateTime.fromISO(
        _.get(reservation, `${legType}_departure`)
      ).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY),
    ],
    [
      "Arrival",
      null,
      DateTime.fromISO(_.get(reservation, `${legType}_arrival`)).toLocaleString(
        DateTime.DATETIME_MED_WITH_WEEKDAY
      ),
    ],
    [
      "Duration",
      null,
      _.get(leg, `duration`)
        ? getHumanizedFromDurationISO(_.get(leg, "duration"))
        : "N/A",
    ],
  ];

  return (
    <div className={classes.Leg}>
      <div className={classes.card}>
        <div className={classes.cardBody}>
          {/* <div className={classes.basicInfoCardBody}> */}
          <div className={classes.legsInfo}>
            <GenericBasicInfo
              header={`${_.startCase(legType)} Leg`}
              cardInfo={basicDataCpl}
              objectData={reservation}
            />
          </div>
        </div>
        <div className={classes.cardBody}>
          <div className={classes.header}>{`${_.startCase(
            legType
          )} Segment`}</div>
          <div className={classes.tableContainer}>
            <div className={classes.table}>
              <thead className={classes.thead}>
                <tr className={classes.headRow}>
                  <th className={classes.headCell}>#</th>
                  <th className={classes.headCell}>Origin</th>
                  <th className={classes.headCell}>Destination</th>
                  <th className={classes.headCell}>Departure</th>
                  <th className={classes.headCell}>Arrival</th>
                  {/* <th className={classes.headCell}>Departure Arival</th> */}
                  <th className={classes.headCell}>Duration Time</th>
                  <th className={classes.headCell}>Carrier</th>
                </tr>
              </thead>
              <tbody>
                {segments.map((seg, idx) => (
                  <tr>
                    <td className={classes.tablecell}>{idx + 1}</td>
                    <td className={classes.tablecell}>
                      {_.get(seg, `origin.label`) || "N/A"}
                    </td>
                    <td className={classes.tablecell}>
                      {_.get(seg, `destination.label`) || "N/A"}
                    </td>
                    <td className={classes.tablecell}>
                      {seg.departure
                        ? DateTime.fromISO(seg.departure).toLocaleString(
                            DateTime.DATETIME_MED_WITH_WEEKDAY
                          )
                        : "N/A"}
                    </td>
                    <td className={classes.tablecell}>
                      {seg.departure
                        ? DateTime.fromISO(seg.arrival).toLocaleString(
                            DateTime.DATETIME_MED_WITH_WEEKDAY
                          )
                        : "N/A"}
                    </td>
                    {/* <td className={classes.tablecell}>
                  {_.get(seg, `${legsIdx}departureArrival`)|| "N/A"}
                </td> */}
                    <td className={classes.tablecell}>
                      {_.get(seg, `duration`) || "N/A"}
                    </td>
                    <td className={classes.tablecell}>
                      {_.get(seg, `operatingCarrier`) || "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Leg.propTypes = {
  legIdx: PropTypes.number.isRequired,
  reservation: PropTypes.object.isRequired,
};
export default Leg;
