import { VALAQUENTA_API_URL } from "../../..";
import { authorizedAxios, loggingAxios } from "../../../../App";

export function fetchWebSiteFAQ({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/faq-for-entity/`,
    {
      params,
    }
  );
}

export function retrieveWebSiteFAQ({ id }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/faq-for-entity/${id}/`
  );
}

export function patchWebSiteFAQ({ id, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/canaveral/faq-for-entity/${id}/`,
    payload
  );
}

export function createWebSiteFAQ({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/canaveral/faq-for-entity/`,
    payload
  );
}

export function deleteWebSiteFAQ({ id }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/canaveral/faq-for-entity/${id}/`
  );
}
