import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  cardStyles,
  formInputGroup,
  formSections,
  inputGroup,
  modalGenericStyles,
  variables,
} from "../../../../variables";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import _ from "lodash";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createDocuments } from "../../../../api/CRM/Documents";
import { CustomButton } from "../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  VendorsAddDocument: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    display: "grid",
    gap: variables.normal_gap,
  },
  inputGroup: {
    ...formInputGroup,
  },
  inputGroupOneLineBtn: {
    display: "grid",
    gridColumn: "span 2",
    gap: variables.half_gap,
    paddingBottom: variables.half_gap,
    justifyContent: "end",
    gridTemplateColumns: "repeat(2, auto)",
    width: "100%",
  },
  form: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: variables.half_gap,
    padding: variables.half_gap,
    maxWidth: "80vw",
    alignItems: "end",
  },
  formSectionHeader: formSections.sectionHeader,
  inputGroupOneLine: {
    ...inputGroup,
    gridColumn: "2 span",
    width: "100%",
    paddingTop: variables.normal_gap,
    justifyContent: "center",
  },
  cardActions: { width: "100%", textAlign: "end" },
  actionCell: genericIndexesStyles.actionCell.mobile,

  [`@media ${variables.media.smallscreen}`]: {
    cardBody: { justifyContent: "center" },
    cardActions: { ...cardStyles.actions, gridColumn: "2 span", width: "97%" },
  },
});

const VendorsAddDocument = ({ sourceEntity, queryKey, tags, onClose }) => {
  const classes = styles();
  const queryClient = useQueryClient();

  const [document, setDocument] = useState(null);

  const mutation = useMutation({
    mutationFn: () => {
      const formData = new FormData();
      formData.append("source_entity", sourceEntity);
      formData.append("file", document);
      tags.forEach((tg) => {
        formData.append("tags", tg);
      });
      return createDocuments({ payload: formData });
    },
    onSuccess: () => {
      toast.success("New Document(s) Added");
      queryClient.invalidateQueries(`${queryKey}`);
      onClose();
    },
    onError: (error) => toast.error(error.message),
  });

  return (
    <div className={classes.VendorsAddDocument}>
      {mutation.isLoading ? (
        <div className={classes.modalCard}>
          <div className={classes.modalCardBody}>
            <div className={classes.card}>
              <div className={classes.cardBody}></div>
              <Loader />
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.modalCard}>
          <div className={classes.cardHeader}>Add Document</div>
          <div className={classes.modalCardBody}>
            <div className={classes.card}>
              <div className={classes.cardBody}>
                <div className={classes.inputGroupOneLine}>
                  <label htmlFor="document">Document</label>
                  <input
                    type="file"
                    name="document"
                    accept=".pdf,.docx,.doc,.txt,.xls,.xlsx,.csv,.odp,.ods,.odt,.ppt,.pptx,.rtf,"
                    onChange={(e) => setDocument(e.target.files[0])}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.modalCardActions}>
            <CustomButton appearance="ghost" onClick={onClose}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton
              appearance="primary"
              onClick={(e) => {
                e.preventDefault();
                mutation.mutate();
              }}>
              <strong>Submit</strong>
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};
VendorsAddDocument.propTypes = {
  sourceEntity: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default VendorsAddDocument;
